import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
const { Sider } = Layout;

export const Wrapperlayout = styled(Layout)`
  & footer {
    background: ${(props) =>
      props.background ? props.background : "#f3f3f3"}!important;
    color: ${(props) =>
      props.background ? props.color || "#d9d9d94f" : "#292b2c"}!important;
  }
  & .ant-menu {
    height: 100vh;
    overflow-y: scroll;
  }
  .submenu-item .ant-menu-sub {
    height: auto;
  }
  & .footer-layout .name-company {
    color: ${(props) => (props.background ? "#fff" : "#292b2c")}!important;
  }
  @media (max-width: 480px) {
    & .ant-layout-content {
      margin: 10px !important;
    }
  }
`;
export const WrapperLanding = styled.div`
  & .nav-bar {
    min-height: 77px !important;
    background: linear-gradient(135deg, #492cdd 0%, #8c2cdd 100%) !important;
  }
  & .ant-layout-header {
    height: 77px;
    padding: 0 0px;
    line-height: 100px;
  }
  & .ant-affix .nav-bar {
    /* background:#FFF!important; */
  }
  .footer-container {
    background: #000000;
    padding: 14px;
  }
  @media (max-width: 360px) {
    .ant-layout-footer {
      padding: 22px 30px !important;
    }
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.45) !important;
  overflow-x: hidden !important;

  padding: 20px 5px 20px 5px;
  height: 64px;

  & .trigger {
    font-size: 20px !important;
    color: #fff !important;
    height: 32px !important;
    width: 32px !important;
  }
  & .trigger:hover {
    background: ${(props) =>
      props.background || "var(--color-primary)"}!important;
  }
  & .trigger i {
    vertical-align: text-top;
  }
  & .logo-content {
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
  }
`;
export const Copy = styled.p`
  text-align: center;
`;
export const SideBar = styled.div`
  max-width: 200px;
  background: ${(props) =>
    props.background ? "#00000070" : "var(--color-primary)"}!important;
  overflow-x: hidden !important;
  height: 100%;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
`;
export const SiderContainer = styled(Sider)`
  &.ant-layout-sider {
    background: ${(props) =>
      props.background || "var(--color-primary)"}!important;
  }
  &.ant-layout-sider-collapsed .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-submenu {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px !important;
  }

  &.logo-container:hover {
    transform: scale(1.5);
  }
  &.ant-layout-sider-collapsed .logo-container div {
    width: 64px !important;
  }
  &.ant-layout-sider-collapsed .ant-menu-item i {
    font-size: var(--font-size-medium);
  }
  & .ant-menu {
    background: transparent !important;
    color: var(--color-secundary) !important;
    border-right: 1px solid transparent !important;
  }
  & .ant-menu-submenu-arrow,
  & .ant-menu-submenu-arrow::after,
  & .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    color: var(--color-secundary) !important;
  }

  & .ant-menu-submenu-title:active,
  & .ant-menu-submenu-title:focus {
    background: transparent !important;
    color: var(--color-white) !important;
  }
  & .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent !important;
    color: var(--color-white) !important;
  }
  & .ant-menu-submenu-title > span {
    display: flex;
    align-items: center;
  }
  & .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-menu-inline-collapsed > .ant-menu-item-active {
    // background: yellow;
    height: 56px;
  }
  & .ant-menu-inline-collapsed > .ant-menu-submenu-active {
    // background: green;
    height: 51px;
  }
`;
export const HeaderContainer = styled.div`
  & h2 {
    color: #fff;
    margin-bottom: 0px;
  }
`;
export const WrapperlayoutBasic = styled.div`
  & .logo-container-basic {
    background: #fff;
    width: 100%;
    height: 64px;
  }
  & .container-logo {
    padding: 7px;
  }
  & .top-bar {
    padding: 4px !important;
  }
`;
