import React, { useEffect, useState } from "react";
import AdvancedForm from "../com/form/AdvancedForm";
import { Input, Button, Icon, Row, Col, message } from "antd";
import styled from "styled-components";
import { Link, navigate } from "@reach/router";
import Logo from "../../sources/images/logo-blue.svg";
/* Redux */
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
/* Services */
import { change_password, set_password } from "../../services";
import { LOGO_CLOSERX, LOGO_CLOSERXBLACK } from "../../constants";

const Form = styled(AdvancedForm)`
  width: 400px;
  background: #fff;
  padding: 40px 35px !important;
  border: 1px solid #ccc;

  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;

  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  & .item-form {
    padding: 5px 20px !important;
  }
  & .ant-form-item input:focus {
    box-shadow: none !important;
  }
  & .ant-form-item {
    padding-bottom: 5px !important;
    margin-bottom: 5px !important;
  }
  & .ant-form-item input {
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #ccc;

    padding: 0.625rem 1.1875rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
  }
  & .login-form-button {
    text-transform: uppercase;
    font-weight: bold;

    margin-bottom: 1.5rem !important;

    /* border: 1px solid transparent !important;
    padding: 0.625rem 1.1875rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: 2px !important; */
  }
`;
const HeadLine = styled.div`
  text-align: center;

  & h2 {
    font-size: 1.5rem;
  }
  & img {
    margin-bottom: 1.5rem !important;
  }
`;
const Footer = styled(Row)`
  & .ant-col {
    margin-bottom: 0.5rem !important;
  }
`;
const validateToNextPassword = (rule, value, callback) => {
  const { form } = this.props;
  if (value && this.state.confirmDirty) {
    form.validateFields(["confirm"], { force: true });
  }
  callback();
};
const compareToFirstPassword = (rule, value, callback) => {
  const { form } = this.props;
  console.log("::::::::", form);
  if (value && value !== form.getFieldValue("password")) {
    callback("Two passwords that you enter is inconsistent!");
  } else {
    callback();
  }
};
const SetPasswordForm = ({ token, ...props }) => {
  const [valuePassword, setValuePassword] = useState();
  const handleSubmit = (err, data) => {
    // return  navigate("/signin");
    set_password(data)
      .then((response) => {
        message.info(response.message);
        navigate("/signin");
        if (props.onSubmit) props.onSubmit(err, data);
      })
      .catch((err) => message.error(err.message));
  };
  useEffect(() => {
    return () => {};
  }, [props]);
  return (
    <Form
      onSubmit={handleSubmit}
      layout={"vertical"}
      autoSubmit={false}
      textAcceptButton="Create"
      footer={
        <Footer type="flex" justify="center" align="middle">
          <Col span={24}>
            <Button
              block
              size="large"
              style={{
                width: "100%",
                height: 40,
                padding: 5,
                margin: "0px auto",
                textAlign: "center",
              }}
              type="primary"
              htmlType="submit"
              className="login-form-submit"
            >
              Create
            </Button>
          </Col>
          {/* <Col span={24} align="center">
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                margin: 10
              }}
            >
              <Col span={24}>
                <span>Do u have an account?</span>{" "}
                <Link to="/signin">Signin</Link>
              </Col>
              <Col span={24}>
                <span>Don’t have an account? </span>{" "}
                <Link to="/signup">Signup</Link>
              </Col>
            </Row>
          </Col> */}
        </Footer>
      }
    >
      <>
        <HeadLine
          style={{
            width: "100%",
          }}
        >
          <div>
            <Link to="/">
              <img
                src={LOGO_CLOSERXBLACK}
                style={{
                  width: 100,
                }}
                alt=""
              />
            </Link>
          </div>
          <h2 className="login-title">
            <span>Create password</span>
          </h2>
        </HeadLine>
        <Input name="origin" initial={window.location.origin} type="hidden" />
        <Input name="token" type="hidden" initial={token} />
        <Input.Password
          xtype="password"
          size="large"
          style={{ width: "100%" }}
          type="password"
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Password"
          name="password"
          /*   validations={[
            { required: true, message: "Password is required" },
            // { min: 6, message: "The code must contain 4 characters" },
          ]} */
          //   pattern= "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
        />
        <Input.Password
          xtype="password"
          size="large"
          style={{ width: "100%" }}
          name="rp_password"
          type="password"
          reference={"password"}
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Repeat Password"
          //   validations={[{ required: true, message: "Password is required" }]}
        />
      </>
    </Form>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAuthentication: (user) =>
      dispatch({ type: actionTypes.AUTHENTICATION, user }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordForm);
