import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField } from "../com/form/";
import { Input, Row, InputNumber, Divider } from "antd";
import { Tooltip, Icon } from "antd";
/* 
StreetNumber
StreetDirPrefix
StreetName
UnitNumber
City
StateOrProvince
PostalCode
LivingArea
SubdivisionName
ParcelNumber
CountyOrParish
ZoningDescription

*/
const LocationInformation = ({ id, onReset, payloads, onChange, ...props }) => {
  const [PropertyType, setPropertyType] = useState("Residential");
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState();
  const formatter = value => {
    var cleaned = ("" + value).replace(/\D/g, "");
    //22-4444-333-4444
    var match = cleaned.match(/^(\d{2})(\d{4})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3] + "-" + match[4];
    }
    return null;
  };
  const setInitialValues = () => {
    let { record } = props;
    if (record) {
      setRecord(record);
      setPropertyType(record["PropertyType"]);
    }
  };
  useEffect(() => {
    let { loading } = props;
    setLoading(loading);
    return () => {};
  }, [props.loading]);
  useEffect(() => {
    setInitialValues();
    return () => {};
  }, [props.record]);
  return (
    <>
      {!loading && (
        <SimpleForm
          source="my-listings"
          onChange={onChange}
          initialValues={record}
          autoSubmit={false}
        >
          {/* 
          StreetNumber
          StreetDirPrefix
          StreetName
          UnitNumber
          StateOrProvince
          */}
          <Input
            flex={0.33}
            name="StreetNumber"
            /* placeholder="Street Number" */
            label="Street Number"
          />
          <Input
            flex={0.33}
            name="StreetDirPrefix"
            /* placeholder="Street Prefix" */
            label="Street Prefix"
          />
          <Input
            flex={0.33}
            name="StreetName"
            /* placeholder="Street Name" */
            label="Street Name"
          />
          <Input
            flex={0.33}
            name="UnitNumber"
            /* placeholder="Unit Number" */
            label="Unit Number"
          />
          <Input flex={0.33} name="City" label="City" />
          <Input
            flex={0.33}
            name="StateOrProvince"
            label="State Or Province"
            /* placeholder="State Or Province" */
          />
          <Input
            flex={0.2}
            name="PostalCode"
            label="Zip Code"
            /* placeholder="Zip Code" */
          />
          <Input
            flex={0.8}
            name="SubdivisionName"
            label="Subdivision"
            /* placeholder="Subdivision" */
          />
          <Input
            flex={0.33}
            name="CountyOrParish"
            label="County"
            /* placeholder="County" */
          />
          <InputNumber
            flex={0.33}
            name="LivingArea"
            label="Living Area"
            /* placeholder="Living Area" */
          />
          <InputNumber
            flex={0.33}
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            formatter={formatter}
            name="ParcelNumber"
            initial={undefined}
            label={
              <span>
                Parcel Number{" "}
                <Tooltip title="Example: 22-4444-333-4444">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
            /* placeholder="Parcel Number" */
          />
          <div type="hidden" />
          <InputNumber
            flex={0.5}
            name="Latitude"
            label="Latitude"
            /* placeholder="Latitude" */
          />
          <InputNumber
            flex={0.5}
            name="Longitude"
            label="Longitude"
            /* placeholder="Longitude" */
          />
          <Divider
            style={{
              margin: 0
            }}
            flex={1}
            type="horizontal"
            dashed
          />
          <Input.TextArea
            flex={1}
            name="ZoningDescription"
            label="Zoning Description"
            /* placeholder="Zoning Description" */
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </SimpleForm>
      )}
    </>
  );
};
export default LocationInformation;
