import React, { useState } from "react";
import { Upload, Icon, message, Button, Tooltip } from "antd";
import { URL_BASE_API } from "../../constants";
import styled from "styled-components";
const RoundedButton = styled(Button)`
  border-radius: 20px !important;
`;

/* 
 primaryKey: "lic_nbr",
 model: "agentsContacts"
*/
const FileUploader = ({
  primaryKey,
  buttonText,
  model,
  service,
  type,
  data,
  query,
  ...props
}) => {
  let options = {
    name: props.name || "uri",
    action: props.action || `${URL_BASE_API}/uploads`,
    data: {
      primaryKey,
      model,
      service,
      type,
      query,
      ...data,
    },
    headers: {
      authorization: localStorage.getItem("feathers-jwt"),
    },
    accept: props.accept
      ? Array.isArray(props.accept)
        ? props.accept.join(",")
        : props.accept
      : undefined,
    showUploadList: props.showUploadList || false,
    onChange: async (info) => {
      if (info.file.status !== "uploading") {
        console.log("info csv:::::::::::::", info.file, info.fileList);
      }
      setSubmitting(true);
      if (info.file.status === "done") {
        if (props.onSubmit) await props.onSubmit(info.file);
        setSubmitting(false);
        message.success(`${info.file.name} File Uploaded successfully`);
      } else if (info.file.status === "error") {
        setSubmitting(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const [submitting, setSubmitting] = useState(false);
  return (
    <>
      <Upload {...options}>
        <Tooltip placement="bottom" title="Import Csv">
          <RoundedButton
            loading={submitting}
            style={
              props.style || {
                background: "#1dbf73",
                border: "1px solid #1dbf73",
                borderRadius: "50%",
              }
            }
            type="primary"
            icon="upload"
          />
          {submitting ? "Uploading..." : buttonText}
        </Tooltip>
      </Upload>
    </>
  );
};

export default FileUploader;
