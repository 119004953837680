import styled from "styled-components";

export const Wrapper = styled.div`
  & .arrow-left {
    position: absolute;
    left: 20px;
    bottom: 94px;
  }
  & .arrow-right {
    position: absolute;
    right: 20px;
    bottom: 94px;
  }
  & .ant-btn-icon-only.ant-btn-lg {
    width: 60px !important;
    height: 60px !important;
    font-size: 24px !important;
  }

  & .slick-dots li button::before,
  .slick-dots li.slick-active button::before {
    content: none !important;
  }
`;
export const ItemWrapper = styled.div`
    display: flex!important;
    justify-content: center!important
    height: 74vh!important;
    margin-bottom:4px;
    
    & > div{
        max-width:1200px!important;
        overflow: hidden;
        margin:10px 0px;
    }

    & .slick-dots-bottom {
        bottom: 0px!important;
    }
    & .ant-carousel .slick-dots-bottom{
        bottom: 0px!important;
    }
    & .slick-dots-bottom{
        bottom: 0px!important;
    }
`;

export const ImageWrapper = styled.div`
  & img {
    object-fit: contain !important;
    max-width: 100%;
    height: auto;
    max-height: 600px !important;
  }
`;
export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100% !important;
    height: 100% !important;
    min-width: 640px;
    min-height: 360px;
  }
  & .btn-play {
    position: relative;
    display: contents;
    .btn {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 9999;
      width: 100%;
      min-height: 100%;
      display: flex;
      border-radius: 0px;
      justify-content: center;
      align-items: center;
      .ant-btn {
        .icon {
          font-size: 40px !important;
          // color: #fff !important;
        }
      }
    }
  }
`;
export const AudioWrapper = styled.div`
  & > audio {
    width: 100% !important;
    height: 100% !important;
    min-width: 640px;
    min-height: 360px;
  }
`;
