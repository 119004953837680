import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Activity } from "../../com";
import { WrapperActivities } from "./Styles";
import moment from "moment";
import { Widget } from "../../widgets";
const RecentActivities = (props) => {
  let date = moment();
  /* let start = date.startOf("day");
    let end = date.endOf("day"); */

  var start = new Date();
  start.setHours(0, 0, 0, 0);
  var end = new Date();
  end.setHours(23, 59, 59, 999);

  return (
    <WrapperActivities>
      <Row type="flex" align="middle" justify="space-between">
        <Col span={24}>
          <Activity
            defaultFilterValues={{
              createdAt: {
                $gte: new Date(start),
                $lt: new Date(end),
              },
            }}
            {...props}
            title={"Today Activities"}
          />
        </Col>
        <Col span={24} className="activity-past">
          <Activity
            defaultFilterValues={{
              createdAt: {
                $lt: new Date(start),
              },
            }}
            {...props}
            title="Past Activities"
          />
        </Col>
      </Row>
    </WrapperActivities>
  );
};
export default RecentActivities;
