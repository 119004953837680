import React, { useEffect, useState } from "react";
import { Wrapper } from "./Styles";
import Form from "./Form";

const FormPage = ({ slug, ...props }) => {
  const [form, setForm] = useState();
  useEffect(() => {
    if (props.form) setForm(props.form);
  }, [props.form]);
  console.log("backgroup:::::::::", form);
  return (
    <Wrapper backgroup={form && form.backgroup}>
      <Form form={form} />
    </Wrapper>
  );
};
export default FormPage;
