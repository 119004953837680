import { Avatar, Skeleton } from "antd";
import React from "react";
import { WrapperSkeleton } from "./Styles";

const SkeletonList = ({ count, width, height, avatar, loading, ...props }) => {
  return (
    <WrapperSkeleton>
      <div className="skeleton-loading">
        {Array.from({ length: count }).map((_, index) => (
          <div key={index} className="container-avatar">
            {avatar && <Avatar />}
            <div className="content">
              <div className="title">
                <div className="name" />
                <div className="sqft" />
              </div>
              <div className="section-title">
                <div className="section-tags">
                  <div className="tag" />
                  <div className="tag" />
                </div>
                {/* <div className="price" /> */}
              </div>

              <div className="section-date">
                <div className="date" />
                <div className="button" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </WrapperSkeleton>
  );
};
export default SkeletonList;
