import React, { useEffect, useState } from "react";
import { WrapperListTags } from "./Styles";
import { Button, List, Tag } from "antd";
const data = [
  {
    title: "dsds",
  },
];
const ListTags = ({ title, ...props }) => {
  const [record, setRecord] = useState([]);
  useEffect(() => {
    if (props.task) setRecord(props.task);
  }, [props.task]);
  return (
    <WrapperListTags>
      <div className="section-title">
        <span>{title}</span>
      </div>
      <div className="container-list">
        {record &&
          record.tags &&
          record.tags.map((it) => <Tag color={it.background}>{it.title}</Tag>)}
      </div>
    </WrapperListTags>
  );
};
export default ListTags;
