import styled from "styled-components";



export const Wrapper = styled.div`

       /*  background: #fff !important;
        padding: 1px 0px !important;
        border-radius: 27px !important; */


    & .ant-form {
        padding: 0px 20px !important;
        width: 100% !important;
    }
    & .arrow-container {
        padding: 0px 0px!important;
        width: 108%!important;
        left: -12px!important;
    }

`;