import styled from 'styled-components';
export const Wrapper = styled.div`
    & .ant-empty {
        margin: 0 8px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .empty-content.large{
        min-height: 350px!important;
    }
    & .empty-content.large .ant-empty-image{
        height:200px!important;
    }
    & .ant-empty-image{
        height: 86px!important;
    }
`;