import styled from "styled-components";
export const Wrapper = styled.div``;
export const WidgetWrapper = styled.div`
  /* min-height:250px; */

  & .ant-card {
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%) !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    border-radius: 10px !important;
  }
  & .ant-card-head {
    border-bottom: 0px !important;
    padding: 0px 24px 0px 8px !important;
  }
  & .ant-card-head-title {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    font-family: "NoirPro";
    font-weight: 400;
  }
  & .ant-card-body {
    padding-top: 0px !important;
    overflow: hidden !important;
  }
  & .widget-container .ant-card-body {
    padding: 24px 20px !important;
  }
  & .widget-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4px;
    ${({ background, color }) => {
      return `
                    background:${background || "#fff"}!important;
                    color:${color || "#fff"}!important;
                `;
    }}
    & .widget-body {
      margin: 4px 10px;
    }
    & .widget-body.layout-horizontal {
      display: flex;
    }
    & .widget-body.layout-horizontal .item h2 {
      margin-bottom: 0px;
    }
    & .widget-body.layout-horizontal .item {
      margin: 0px 4px;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }
  }
  & .drag-handle {
    ${({ background, color }) => `
        background:${background || "#fff"}!important;
        color:${color || "rgba(0,0,0,.75)"}!important;
    `}
  }
  & .ant-fullcalendar-selected-day .ant-fullcalendar-value {
    background: #1890ff !important;
    box-shadow: 0 0 0 1px #1890ff inset !important;
  }
  /* & .ant-fullcalendar{
        color:#FFF!important;
    }
    & .ant-fullcalendar-value {
        color: rgba(255, 255, 255, 0.75)!important;
        background: #43326b!important;
    }
    & .ant-fullcalendar-date:hover,
    & .ant-fullcalendar-value:hover
    {
        color: rgba(0, 0, 0, 0.25)!important;
    } */
`;
