import { GOONEWORLD_SITE } from ".";
export const actions = {
  update_tasks: "Update Task!",
  create_tasks: "Task Created",
  favorites: "Add to Favorites",
  remove_listing: "Dislike Listing",
  birthdays: "Birthday",
  reminders: "Reminder",
  sync_listing: "Sync Listing",
  notifications: "Common Notifications",
  assigned_to_task: "Assigned to Task",
  removed_to_task: "Assigned to Task",
};
export const tree = {
  update_tasks:
    "**{subject}** by **{user.first_name} {user.last_name}** Task updated ",
  assigned_to_task:
    "**{member.first_name} {member.last_name}** assigned to  task **{task.subject}** by **{user.first_name} {user.last_name}**",
  removed_to_task:
    "**{member.first_name} {member.last_name}** removed from task **{task.subject}** by **{user.first_name} {user.last_name}**",
  reminders: "This task is expiring **{task.subject}** at **{date}**",
  create_tasks:
    "Task Created **{subject}** by **{user.first_name} {user.last_name}**",
  favorites: `**{author.first_name}** **{author.last_name}** liked the property  
    [{property.Address}](${GOONEWORLD_SITE}/detail/{property._id}) 
           `,
  remove_listing: `**{author.first_name}** **{author.last_name}** disliked the property  
    [{property.Address}](${GOONEWORLD_SITE}/detail/{property._id}) 
           `,
  birthdays: `Birthday **{user.first_name} {user.last_name}**`,
  create_contacts: `Contact created [**{log.first_name} {log.last_name}** ](/dashboard/contacts/{log._id}) by **{user.first_name} {user.last_name}**`,
  update_contacts: `Contact updated [**{log.first_name} {log.last_name}** ](/dashboard/contacts/{log._id}) by **{user.first_name} {user.last_name}**`,
  create_post: `Post created {log.message} by **{user.first_name} {user.last_name}**`,
  update_post: `Post updated {log.message} by **{user.first_name} {user.last_name}**`,
  create_socials: `Connected to {log.social}  **{author.first_name}**`,
  sync_listing: `**Atention!** {log.message}`,
  notifications: `{log.message}`,
  /* Acelle logs [**{log.first_name} {log.last_name}** ](/dashboard/contacts/{log._id})*/
  list_updated: `The list "[**{data.name}**](/dashboard/lists/{data.id})" was updated!`,
  list_created: `The list "[**{data.name}**](/dashboard/lists/{data.id})" was created!`,
  subscriber_subscribed: `The subscriber "[**{data.email}**](/dashboard/subscribers/{data.id})" was subscribed!`,
  campaign_created: `A new campaign  "[**{data.name}**](/dashboard/campaigns/{data.id})" was created!`,
  campaign_updated: `The campaign  "[**{data.name}**](/dashboard/campaigns/{data.id})" was started!`,

  segment_updated: `The segment  "[**{data.name}**](/dashboard/lists/{data.id})" was created!`,
  sending_domain_created: `sending domain  "[**{data.name}**](/dashboard/lists/{data.id})" was created!`,
};
