import React, { useState, useEffect } from "react";
import { WrapperTaskList, WrapperItem, WrapperSkeleton } from "./Styles";
import {
  List,
  message,
  Avatar,
  Table,
  Button,
  Modal,
  Icon,
  Drawer,
  Skeleton,
  Tag,
  Tooltip,
} from "antd";
import { LogActions, getService } from "../../../services";
import { useSelector } from "react-redux";
import { URL_S3, URL_DEFAULT_AVATAR } from "../../../constants";
import * as messages from "../../../constants/messages";
import moment from "moment";
import MarkDown from "../../markdown/Markdown";
import { StatusField } from "../../com/fields";
import MemberGroup from "../../board/MemberGroup";
import { Empty } from "../../com/loader";
import TaksForm from "../../board/TaskForm";
import { MyModal } from "../../com";
import _ from "lodash";
import { navigate } from "@reach/router";

const SkeletonTable = ({ count, width, height, loading, ...props }) => {
  return (
    <WrapperSkeleton>
      <div className="skeleton-loading">
        {Array.from({ length: count }).map((_, index) => (
          <div key={index} className="container-avatar">
            <div className="content">
              <div className="tag" />
              <div className="name" />
              <div className="name" />
              <div className="date" />
            </div>
          </div>
        ))}
      </div>
    </WrapperSkeleton>
  );
};
const status = [
  {
    value: "active",
    text: "Active",
  },
  {
    value: "inactive",
    text: "Inactive",
  },
  {
    value: "accepted",
    text: "Accepted",
  },
  {
    value: "completed",
    text: "Completed",
  },
  {
    value: "in_progress",
    text: "In progress",
  },
  {
    value: "pending",
    text: "Pending",
  },
  {
    value: "canceled",
    text: "Canceled",
  },
];
const TaskTitle = ({ record, title, ...props }) => {
  return (
    <div className={`title-task ${record.status}`} {...props}>
      <h2>{title}</h2>
    </div>
  );
};

const TodoTask = ({ onClose, onLoad, ...props }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [refresh, setRefresh] = useState(false);
  const user = useSelector(({ user }) => user);
  const [visible, setVisible] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [record, setRecord] = useState({});
  const getData = () => {
    const service = getService("tasks");
    if (!filterDefaultValues) return;
    setLoading(true);
    service
      .find({
        query: {
          $sort: {
            createdAt: -1,
          },
          ...filterDefaultValues,
        },
      })
      .then(({ data, meta }) => {
        setDataSource(data);
        setLoading(false);
        setInitialized(true);
        if (onLoad) onLoad(data);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };
  const handleRemove = (id) => {
    Modal.confirm({
      content: "Are you sure you want to remove",
      onOk: () => {
        const service = getService("tasks");
        service
          .remove(id)
          .then((res) => {
            getData();
          })
          .catch((err) => {
            message.error(err.message);
          });
      },
    });
  };
  const updateTask = (id, payloads) => {
    const service = getService("tasks");
    if (id)
      service
        .patch(id, payloads)
        .then((res) => {
          message.success("Task updated successfully!");
          getData();
        })
        .catch((err) => {
          message.error(err.message);
        });
  };
  const handleOnCancel = () => {
    setVisible(false);
    setRecord(null);
    getData();
    // if (onClose) onClose();
  };
  const handleSelect = (record, checked) => {
    updateTask(record._id, {
      status: checked ? "completed" : "pending",
    });
  };
  const handleSubmit = () => {
    getData();
  };
  useEffect(() => {
    if (user) {
      LogActions.on("created", (data) => {
        let { log, user_id, action_type } = data;
        getData();
      });
    }
    return () => {};
  }, [user]);
  useEffect(() => {
    if (!_.isEmpty(filterDefaultValues)) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (typeof props.refresh != "undefined" && props.refresh !== refresh)
      setRefresh(props.refresh);
  }, [props.refresh]);
  useEffect(() => {
    if (
      props.refresh !== refresh &&
      !_.isEmpty(filterDefaultValues) &&
      !_.isEqual(props.filterDefaultValues, filterDefaultValues) &&
      initialized
    )
      getData();
  }, [refresh]);
  if (!initialized && loading)
    return (
      <SkeletonTable loading={!initialized} title={false} avatar count={6} />
    );
  return (
    <WrapperTaskList>
      <Empty image="/images/tasks.svg">
        <Table
          header={props.title}
          rowKey="_id"
          // loading={loading}
          showHeader={true}
          bordered={false}
          pagination={false}
          dataSource={dataSource}
          scroll={
            dataSource && dataSource.length > 0
              ? props.scroll || { y: 310, x: 1080 }
              : undefined
          }
          /*    rowSelection={{
            onSelect: handleSelect,
            columnWidth: 40,
            selectedRowKeys: dataSource
              .filter((it) => it.status === "completed")
              .map((it) => String(it._id)),
          }} */
          columns={[
            {
              title: "Actions",
              // width: 30,
              render: (value, record) => (
                <>
                  <Button
                    type="link"
                    icon="eye"
                    onClick={() => {
                      setRecord(record);
                      setVisible(true);
                    }}
                  />
                </>
              ),
            },
            {
              title: "Subject",
              dataIndex: "subject",
              // width: 60,
              render: (value, record) => (
                <TaskTitle
                  style={{
                    maxWidth: 250,
                  }}
                  title={value}
                  record={record}
                />
              ),
            },
            {
              title: "Contact",
              // width: 60,
              dataIndex: "contact_id",
              render: (value, record) => (
                <Tooltip placement="topLeft" title={"See Detail"}>
                  <div className="title-task  pointer">
                    <h2
                      className="contact-name"
                      onClick={() =>
                        navigate(
                          `/dashboard/${
                            record.contact && record.contact.type === "contact"
                              ? "contacts"
                              : "leads"
                          }/${value}`
                        )
                      }
                    >
                      {record &&
                        record.contact &&
                        `${record.contact.first_name} ${record.contact.last_name}`}
                    </h2>
                  </div>
                </Tooltip>
              ),
            },
            {
              title: "Status",
              // width: 40,
              dataIndex: "status",
              render: (value, record) => (
                <Tag
                  color={
                    value === "accepted"
                      ? "green"
                      : value === "pending"
                      ? "#2db7f5"
                      : "blue"
                  }
                  className={`tag-status ${value}`}
                >
                  {value}
                </Tag>
              ),
            },
            {
              title: "Members",
              // width: 40,
              dataIndex: "members_ids",
              render: (value, record) => (
                <MemberGroup
                  add={false}
                  members={record.members}
                  members_ids={value}
                  record={record}
                />
              ),
            },
            {
              title: "Created At",
              dataIndex: "createdAt",
              width: 160,
              render: (value, record) => (
                // {moment(item.updatedAt).format("DD-MM-YYYY  LT")}
                <span>
                  {moment(value || record.createdAt).format("DD-MM-YYYY  LT")}
                </span>
              ),
            },
            {
              title: "Updated At",
              dataIndex: "updatedAt",
              width: 160,
              render: (value, record) => (
                // {moment(item.updatedAt).format("DD-MM-YYYY  LT")}
                <span>
                  {moment(value || record.createdAt).format("DD-MM-YYYY  LT")}
                </span>
              ),
            },
            props.actions &&
              props.actions.remove && {
                render: (value, record) => (
                  <Button onClick={() => handleRemove(record._id)} type="link">
                    <Icon
                      type="delete"
                      theme="twoTone"
                      twoToneColor="#eb2f96"
                    />
                  </Button>
                ),
              },
          ].filter((item) => typeof item != "undefined")}
        />
      </Empty>
      <Drawer
        className="drawer-fit"
        closable={true}
        destroyOnClose
        onClose={handleOnCancel}
        visible={visible}
        width={700}
      >
        <TaksForm
          contact_id={props.record && props.record._id}
          id={record && record._id}
          onSubmit={handleSubmit}
          onClose={handleOnCancel}
        />
      </Drawer>
    </WrapperTaskList>
  );
};
export default TodoTask;
