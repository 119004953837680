export const APPLICATION_ID = "DT1FRH8HXE";
export const API_KEY = "a7ed77937679ddce485b17da4cd219f3";
// export const URL_BASE_API = "https://api-dev.gooneworld.com";
/* export const URL_BASE_API ="http://localhost:3030"; */
export const URL_BASE_API =
  window.location.hostname === "localhost"
    ? "http://localhost:3030"
    : "https://api-dev.gooneworld.com";

export const URL_AUTHENTICATION = "/authentication";
export const GOONEWORLD_SITE = "http://gooneworld.com";
export const URL_SITE = "http://closrx.com";

export const URL_S3 = "https://oneworld-listings-images.s3.amazonaws.com";
/* export const URL_S3_SERVER =
  window.location.hostname === "localhost"
    ? "http://localhost:3030"
    : "https://api-dev.gooneworld.com"; */
export const URL_S3_SERVER = URL_BASE_API;
export const SIGIN_S3 = URL_S3_SERVER + "/s3Client/sign";

export const URL_LISTING_FAVORITES =
  "https://81p8aypb40.execute-api.us-east-1.amazonaws.com/production";
export const BUCKET = "oneworld-listings-images";
export const ACCESS_KEY_ID = "AKIA6H2C57S5QKMT2ANV";
export const SECRET_ACCESS_KEY = "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";
export const PRIVACY_AND_POLICY = "https://closrx.com/privacy-policy";
export const TERMS_AND_CONDITIONS = "https://closrx.com/terms-and-conditions";

export const URL_CDN = "https://imagecdn1.gooneworld.com";
export const URL_BASE_ACELLE = "https://mcloserx.com";
export const ACELLE_API_TOKEN =
  "TCmEc0LKXOm0hhoYUWZwcRyom0BWfpqkE7L1nM9B6UEpYPnPhv2FAdq0MtRc";

export const VERSION = "1.0.6";
export const URL_DEFAULT_AVATAR = "/images/placeholder.jpg";
export const LOGO_CLOSERX = "/images/logoCloseRx.png";
export const DEFAULT_IMAGE = "/images/ubicacion.png";
export const LOGO_BLUECLOSERX = "/images/logoCloseblue.png";
export const LOGO_CLOSERXBLACK = "/images/logoCloseblue.png";
export const IMAGE_UPLOAD = "/images/pictures.svg";
export const IMAGE_BANNER = "/images/imagebanner.svg";
export const IMAGE_WORKING = "/images/Grupworking.svg";
export const IMAGE_MAC = "/images/Grupomac.png";
export const BACKGROUND_LINEAL = "/images/Grupobackground.svg";
// export const DEFAULT_IMAGE_BOARD = "/images/map_data.png";
export const DEFAULT_IMAGE_BOARD = "/images/person_process.png";
export const URL_LOGO_ICON = "/images/logo-icon.svg";
export const extensions = ["png", "jpg", "jpeg", "jfif"];
export const colors = [
  "transparent",
  "#00aecc",
  "#F17F66",
  "#86BFB9",
  "#E7C481",
  "#BEA7F1",
  "#828b91",
  "#24898E",
  "#93DA91",
  "#d29034",
  "#7D6F9B",
  "#8AA351",
  "#7EE2B8",
  "#88C393",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#F5CD47",
  "#F87168",
  "#C9372C",
  "#A7EEE2",
  "#999E9F",
  "#D804D8",
  "#D0F73D",
  "#3DF1F7",
  "#F72D73",
  "#F7802D",
  "#897E0A",
  "#25DF0D",
  "#ffea30",
  "#ffa117",
  "#BAC005",
  "#795548",
  "#707f8f",
  "#519839",
  "#b04632",
  "#00C36A",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#BDBF8D",

  /* "#4bbf6a",
  "#00aecc", */
];
export const colors_members = [
  "#0079bf",
  "#d29034",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#828b91",
];
export const colors_trello = [
  // "transparent",
  "#0079bf",
  "#d29034",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6a",
  "#00aecc",
  "#828b91",
];
export const colors_favro = [
  "transparent",
  "#6f63f4",
  "#2196f3",
  "#19c3d8",
  "#56b475",
  "#a9df4d",

  "#ffea30",
  "#ffa117",
  "#f5276d",
  "#795548",
  "#707f8f",
];
export const socials = {
  facebook: {
    text: "Facebook",
    name: "facebook",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "#3B5998",
    icon: "/images/facebook.svg",
  },
  instagram: {
    text: "Instagram",
    name: "instagram",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "rgb(232, 62, 140)",
    icon: "/images/instagram.svg",
  },
  twitter: {
    text: "Twitter",
    name: "twitter",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "rgb(0 0 0 / 80%)",
    icon: "/images/social-x.png",
  },
  linkedin: {
    text: "Linkedin",
    name: "linkedin",
    shape: "square",
    quantity: "15",
    colorText: "#FFF",
    background: "#0077B7",
    icon: "/images/linkedin.svg",
  },
};
export const default_documents = [
  {
    title: "Executed Contract",
    name: "executed_contract",
  },
  {
    title: "Listing Agreement",
    name: "listing_agreement",
  },
  {
    title: "Escrow Letter",
    name: "escrow_letter",
  },
  {
    title: "Escrow Checks",
    name: "escrow_checks",
  },
  {
    title: "Closing Statement",
    name: "closing_statement",
  },
  {
    title: "Cancellation Form",
    name: "cancellation_form",
  },
  {
    title: "Agency Commission Check",
    name: "agency_commission_check",
  },
  {
    title: "Agent Commission Check",
    name: "agent_commission_check",
  },
  {
    title: "Addendum 1",
    name: "addendum_1",
  },
  {
    title: "Addendum 2",
    name: "addendum_2",
  },
  {
    title: "Addendum 3",
    name: "addendum_3",
  },
];
export const status = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "inactive",
    name: "Inactive",
  },
  {
    id: "accepted",
    name: "Accepted",
  },
  {
    id: "completed",
    name: "Completed",
  },
  {
    id: "in_progress",
    name: "In progress",
  },
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "canceled",
    name: "Canceled",
  },
];
export const tasks_types = [
  "task",
  "opportunity",
  "lead",
  "project",
  "target",
  "contact",
  "quote",
  "property",
  "email",
  "bug",
  "project_task",
  "target",
  "case",
  "meeting",
  "online_meeting",
  "call",
  "contract",
  "invoice",
  "quote",
  "product",
  "property",
  "event",
];
export const listing_status = ["Active", "ActiveUnderContract", "Pending"];

export const permissions = [
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Broker",
    value: "broker",
  },
  {
    name: "Agent",
    value: "agent",
  },
];
