import React, { useState, useEffect } from "react";
import { Input } from "antd";
const NumberField = (props) => {
  return (
    <>
      <Input {...props}/>
    </>
  );
};

export default NumberField;
