import React from "react";
import { WrapperSkeletonWiget } from "./Styles";
import { Avatar } from "antd";

const SkeletonWidget = ({ count, width, height, avatar, ...props }) => {
  return (
    <WrapperSkeletonWiget>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="skeleton-loading"
          style={{ width: width, height: height }}
        >
          <div className="container-avatar">
            {avatar && <Avatar />}
            <div>
              <div className="number" />
              <div className="title" />
            </div>
          </div>
        </div>
      ))}
    </WrapperSkeletonWiget>
  );
};
export default SkeletonWidget;
