import React, { useState, useEffect } from "react";
import { WrapperForm } from "./Styles";
import { SimpleForm } from "../../components/com/form/";
import { Input, Divider, message } from "antd";
import S3Button from "../../components/com/form/S3Button";
import _ from "lodash";
import { PhoneField } from "../../components/com/fields";
import { getService } from "../../services";
import { URL_DEFAULT_AVATAR } from "../../constants";
const permissions = ["Admin", "Broker"];
const CompanyForm = ({ record, onSubmit, onChange, ...props }) => {
  const handleOnSubmit = async (error, res) => {
    if (error) return message.error(error.message);
    try {
      if (!record.company_id) {
        const service = getService("users");
        let company = await service.patch(record._id, {
          company_id: res._id,
        });
        if (onSubmit) onSubmit(company);
      }
    } catch (err) {
      message.error(err.message);
    }
  };
  return (
    <SimpleForm
      id={record && record.company_id}
      record={record}
      onChange={onChange}
      source="companies"
      onSubmit={handleOnSubmit}
      initialValues={record && record.company}
      autoSubmit={!record.company_id}
    >
      {record && record.company && (
        <S3Button
          className="flex-box centered"
          disabled={!permissions.includes(record.role)}
          flex={1}
          record={record}
          idComponent="btn-send"
          initial={record && record.company && record.company.logo}
          path="users"
          finalPath="picture"
          source="logo"
          name="logo"
          id={record._id}
        />
      )}
      <Divider
        dashed
        style={{
          margin: 0,
          padding: 0,

          lineHeight: 0,
        }}
        flex={1}
        type="horizontal"
      />
      <Input type="hidden" initial={record._id} name="user_id" />
      <Input type="hidden" initial="GOW" name="hostApp" />
      {
        <Input
          disabled={!permissions.includes(record.role)}
          flex={1}
          label="Company Name"
          placeholder="Company Name"
          name="name"
          initial={record && record.company && record.company.name}
        />
      }
      {
        <Input.TextArea
          flex={1}
          disabled={!permissions.includes(record.role)}
          label="Company Description"
          placeholder="Company Description"
          name="description"
          initial={record && record.company && record.company.description}
        />
      }

      {
        <PhoneField
          input={true}
          flex={1}
          disabled={!permissions.includes(record.role)}
          initial={record && record.company && record.company.phone}
          label="Phone"
          placeholder="Phone"
          name="phone"
        />
      }
      {
        <Input
          disabled={!permissions.includes(record.role)}
          label="Address"
          placeholder="Address"
          flex={1}
          name="address"
        />
      }
      {
        <Input
          disabled={!permissions.includes(record.role)}
          label="City"
          placeholder="City"
          flex={1}
          name="OfficeCity"
        />
      }
      {
        <Input
          disabled={!permissions.includes(record.role)}
          label="State"
          placeholder="State"
          name="OfficeStateOrProvince"
        />
      }
      {
        <Input
          disabled={!permissions.includes(record.role)}
          label="Zip Code"
          placeholder="Zip Code"
          name="OfficePostalCode"
        />
      }
    </SimpleForm>
  );
};
const CompanyInformation = ({ member, onChange, ...props }) => {
  const handleChange = _.debounce(onChange, 800, { maxWait: 800 });
  const [record, setRecord] = useState();
  const handleOnSubmit = (company) => {
    setRecord({ ...record, company_id: company._id, company });
  };
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  if (!record) return null;
  if (!record.company_id)
    return (
      <WrapperForm>
        <div>
          <span>Synchronize your company with the MLS</span>
        </div>
        <SimpleForm
          submitButtonText="Connect"
          record={record}
          onSubmit={(err, record) => {
            setRecord(record);
          }}
          source="sync-company-by-mlsid"
        >
          <Input
            flex={1}
            name="OfficeMlsId"
            placeholder="input your office MLS Id"
          />
        </SimpleForm>
        <div>
          <span>Or Configure Company</span>
        </div>
        <CompanyForm
          onChange={handleChange}
          onSubmit={handleOnSubmit}
          record={record}
        />
      </WrapperForm>
    );
  return (
    <WrapperForm>
      <SimpleForm record={record} onChange={handleChange} autoSubmit={false}>
        {record && record.company && (
          <S3Button
            className="flex-box centered"
            disabled={!permissions.includes(record.role)}
            flex={1}
            record={record}
            idComponent="btn-send"
            initial={record && record.company && record.company.logo}
            /* onChange={onChange} */
            path="users"
            finalPath="picture"
            source="logo"
            name="logo"
            id={record._id}
            defaultImage={URL_DEFAULT_AVATAR}
            itemType={"rounded"}
            size="large"
          />
        )}
        <Divider
          dashed
          style={{
            margin: 0,
            padding: 0,

            lineHeight: 0,
          }}
          flex={1}
          type="horizontal"
        />
        <Input type="hidden" initial="GOW" name="hostApp" />
        {record && record.company && (
          <Input
            disabled
            flex={1}
            label="Company Name"
            placeholder="Company Name"
            name="name"
            initial={record.company.name}
          />
        )}
        {record && record.company && (
          <Input.TextArea
            flex={1}
            disabled={!permissions.includes(record.role)}
            label="Company Description"
            placeholder="Company Description"
            name="description"
            initial={record.company.description}
          />
        )}

        {record && record.company && (
          <PhoneField
            input={true}
            flex={1}
            // disabled
            initial={record && record.company.phone}
            label="Phone"
            placeholder="Phone"
            name="phone"
          />
        )}
        {member && (
          <Input
            disabled
            label="Address"
            placeholder="Address"
            flex={1}
            name="address"
            initial={member.OfficeAddress1}
          />
        )}
        {member && (
          <Input
            disabled
            label="City"
            placeholder="City"
            flex={1}
            name="OfficeCity"
            initial={member.OfficeCity}
          />
        )}
        {member && (
          <Input
            disabled
            label="State"
            placeholder="State"
            name="OfficeStateOrProvince"
            initial={member.OfficeStateOrProvince}
          />
        )}
        {member && (
          <Input
            disabled
            label="Zip Code"
            placeholder="Zip Code"
            name="OfficePostalCode"
            initial={member.OfficePostalCode}
          />
        )}
        {member && member.OfficeCorporateLicense && (
          <Input
            disabled
            label="Company License # "
            placeholder="Company License # "
            flex={1}
            name="OfficeCorporateLicense"
            initial={member.OfficeCorporateLicense}
          />
        )}
        {record && record.company && (
          <Input
            disabled
            label="Mls Id"
            placeholder="Mls Id"
            flex={1}
            name="MlsId"
            initial={record.company.MlsId}
          />
        )}
      </SimpleForm>
    </WrapperForm>
  );
};
export default CompanyInformation;
