import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash/debounce";
/* Redux */
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import {
  Row,
  Col,
  Spin,
  List,
  Card,
  Icon,
  Button,
  message,
  Modal,
  Skeleton,
} from "antd";
import PropTypes from "prop-types";
import { getService } from "../../services/services";
import { Empty } from "../com/loader";
const URL_BASE = "http://gooneworld.com";
const { Meta } = Card;
const Container = styled.div`
  overflow: auto;
  padding: 8px 24px;
  min-height: 100vh;

  & .extra {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 4px;
  }
  & .extra > * {
    margin: 0px 4px !important;
  }
  & .btn-active,
  .btn-active:active,
  .btn-active:focus,
  .btn-active:hover {
    background: var(--board-color-blue) !important;
    color: #fff;
  }
`;
const Loader = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
`;

const ToolContainer = styled(Row)`
  margin: 10px 0px;

  border-bottom: 1px solid #cccccc4a;
  padding-bottom: 10px;
  box-shadow: 0px 4px 1px #cccccc14;

  background: #fff;
  padding: 8px;
  border-radius: 4px;
`;
const ListCard = ({
  source = "",
  column = 3,
  refinement,
  renderItem,
  data,
  title,
  layout = "horizontal",
  switchLayout = true,
  onUpdate,
  updated,
  actions = [],
  tools = [],
  extra,
  filters = [],
  toolStyle = {},
  ...props
}) => {
  const [dataSource, setDataSource] = useState(data || props.dataSource || []);
  const [service, setService] = useState();
  const [filterDefaultValues, setFilterDefaultValues] = useState(
    props.filterDefaultValues
  );
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(props.loading);
  const [refresh, setRefresh] = useState(props.refresh || false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(props.hasMore || false);
  const [itemLayout, setLayout] = useState(layout);

  useEffect(() => {
    setRefresh(props.refresh);
    if (props.refresh) {
      resetData();
    }
    if (!initialized) {
      getData();
      setInitialized(true);
    } else {
      getData();
    }
    return () => {};
  }, [props.refresh, props.loading]);
  useEffect(() => {
    if (typeof props.loading != "undefined") setLoading(props.loading);
  }, [props.loading]);
  const resetData = () => {
    if (props.onReload) props.onReload();
    setFilterDefaultValues({
      ...filterDefaultValues,
      limit: 10,
      page: 1,
    });
  };
  const defaultRenderItem = (item, index) => {
    return (
      <List.Item key={index}>
        <Skeleton avatar title={false} loading={loading} active>
          <Card
            hoverable
            actions={[
              <a target="_blank" href={`${URL_BASE}/${item.url}`}>
                <Icon type="eye" /> View Detail
              </a>,
              <Button type="link" onClick={() => handleDelete(item._id)}>
                <Icon type="delete" /> Delete
              </Button>,
            ]}
          >
            <Meta
              title={
                <>
                  <Icon size="large" type="home" />{" "}
                  {item.Slug ? (
                    <span>{item.Slug.replace(/\-/g, " ").toUpperCase()}</span>
                  ) : (
                    <span>{item.url}</span>
                  )}
                </>
              }
            />
          </Card>
        </Skeleton>
      </List.Item>
    );
  };
  const getData = () => {
    if (source !== "") {
      const service = getService(source);
      setService(service);
      setLoading(true);
      return service
        .find({
          query: {
            ...filterDefaultValues,
          },
        })
        .then(({ data }) => {
          setDataSource(data);
          setLoading(false);
          console.log("data save seacrh", data);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  const removeItem = (id) => {
    service
      .remove(id)
      .then((response) => {
        let data = dataSource.filter((it) => it._id !== id);
        setDataSource(data);
        message.info("Element removed!");
      })
      .catch((err) => console.log(err));
  };
  const handleLayout = () => {
    let layout = itemLayout == "horizontal" ? "vertical" : "horizontal";
    setLayout(layout);
    if (props.onLayout) props.onLayout(layout);
  };
  const handleReload = async () => {
    if (props.onReload) props.onReload();
    if (!loading) {
      await setFilterDefaultValues({
        ...filterDefaultValues,
        limit: 10,
        page: 1,
      });
    }
  };
  const handleInfiniteOnLoad = () => {
    if (props.onLoadMore) props.onLoadMore();
    setFilterDefaultValues({
      ...filterDefaultValues,
      limit: 10,
      page: page + 1,
    });
  };
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <Icon type="exclamation-circle" />,
      content: "Do you Want to delete the  record?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => removeItem(id),
    });
  };
  const onChange = async (value, { key }, it) => {
    console.log(value, it);
    let { name, source } = it.props;
    await setFilterDefaultValues({
      ...filterDefaultValues,
      [name || source || "search"]: value ? value : undefined,
      /* limit: 10,
      page: 1 */
    });
    if (it.onChange) it.onChange(value, key);
  };
  const handleChange = debounce(onChange, 800);
  useEffect(() => {
    setLayout(layout);
  }, [layout]);
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (props.data) setDataSource(props.data);
    if (props.dataSource) setDataSource(props.dataSource);
  }, [props.data, props.dataSource]);
  return (
    <>
      <ToolContainer
        type="flex"
        justify="space-between"
        align="middle"
        className="tool-container"
      >
        {(filters.length === 0 || title) && <Col>{title}</Col>}
        {filters.length > 0 && (
          <Row type="flex" justify="start" gutter={8} align="middle">
            {filters.map((it) => (
              <Col>
                {React.cloneElement(it, {
                  onChange: (value, e) => {
                    let { name, source } = it;
                    handleChange(
                      value,
                      { key: e ? e.key : name || source },
                      it
                    );
                  },
                  /* onChange: (value, e) =>
              handleChange(value, { key: e && e.key }, it), */
                })}
              </Col>
            ))}
          </Row>
        )}
        <Col>
          <Row type="flex" justify="end" align="middle" {...toolStyle}>
            {tools.map((it) => (
              <Col>{it}</Col>
            ))}
            <Col>
              <Button
                type="link"
                icon={loading ? "loading" : "reload"}
                onClick={handleReload}
              />
            </Col>
            {switchLayout && (
              <Col>
                <Button
                  type="link"
                  icon={itemLayout == "horizontal" ? "table" : "bars"}
                  onClick={handleLayout}
                />
              </Col>
            )}
          </Row>
        </Col>
      </ToolContainer>
      <Container className="container-wrapper">
        <InfiniteScroll
          {...props}
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <Empty size="large">
            <List
              itemLayout={itemLayout}
              loading={loading}
              header={
                <div className="head-list">
                  <div className="title"></div>
                  {extra && (
                    <div className="extra">
                      {React.Children.map(extra.props.children, (child) =>
                        React.cloneElement(child, {})
                      )}
                    </div>
                  )}
                </div>
              }
              size="large"
              grid={
                props.grid || layout == "horizontal"
                  ? { gutter: 8, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }
                  : { gutter: 8, xl: 3, lg: 1, md: 1, sm: 1, xs: 1 }
              }
              dataSource={dataSource}
              renderItem={renderItem || defaultRenderItem}
            >
              {loading && hasMore && (
                <Loader className="demo-loading-container">
                  <Spin />
                </Loader>
              )}
            </List>
          </Empty>
        </InfiniteScroll>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    updated: state.updated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (updated) => dispatch({ type: actionTypes.UPDATE_LIST, updated }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCard);
