import React, { useState, useEffect } from "react";
import { SimpleForm } from "../com/form/";
import {
  Checkbox,
  Icon,
  Input,
  Radio,
  Select,
  Divider,
  InputNumber,
} from "antd";
import { PhoneField } from "../com/fields";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
const { Option } = Select;
const roles = [
  {
    text: "User",
    value: "User",
  },
  {
    text: "Agent",
    value: "Agent",
  },
  {
    text: "Admin",
    value: "Admin",
  },
];
const UserForm = ({ source, role = true, id, ...props }) => {
  const user = useSelector(({ user }) => user);
  const [view, setView] = useState("signup");
  const [status, setStatus] = useState(id ? "active" : "inactive");
  return (
    <SimpleForm
      id={id}
      source={source || "users"}
      onSubmit={() => {
         navigate("/dashboard/users");
      }}
      textAcceptButton={id ? "UPDATE" : "CREATE"}
      // onSubmit={props.onSubmit}
    >
      {role && (
        <Select
          size="large"
          label="Choose a role for the user"
          name="role"
          placeholder="Choose a role for the user"
          style={{ width: "100%" }}
        >
          {roles.map((d) => (
            <Option key={d.value}>{d.text}</Option>
          ))}
        </Select>
      )}
      <Input type="hidden" name="company_id" initial={user && user.company_id} />
      <Input type="hidden" name="hostApp" initial="GOW" />
      <Input type="hidden" name="origin" initial={window.location.origin} />
      <Input
        size="large"
        flex={0.5}
        placeholder="First Name"
        label="First Name"
        name="first_name"
        validations={[{ required: true, message: "First Name is required" }]}
      />
      <Input
        size="large"
        flex={0.5}
        placeholder="Last Name"
        label="Last Name"
        name="last_name"
      />
      <Input
        size="large"
        flex={0.5}
        placeholder="Email"
        label="Email"
        type="email"
        name="email"
        validations={[{ required: true, message: "Mail is required" }]}
        prefix={<Icon size="large" type="mail" />}
      />
    {/*   <Input
        xtype="phonefield"
        input
        size="large"
        flex={0.5}
        placeholder="Phone"
        label="Phone"
        name="phone"
        prefix={<Icon size="large" type="phone" />}
      /> */}
       <PhoneField
          input
          validations={[{ max: 13, message: "Max value" }]}
          grid={{
            xxl: 12,
            xl: 8,
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24,
          }}
          size="large"
          flex={0.5}
          label="Phone"
          placeholder="Phone"
          name="phone"
        />
      <Divider dashed flex={1} />
      <Input
        size="large"
        flex={0.5}
        type="password"
        prefix={<Icon size="large" type="lock" />}
        placeholder="●●●●●●●●"
        label="Password"
        name="password"
      />
      <Input
        size="large"
        flex={0.5}
        reference={"password"}
        type="password"
        prefix={<Icon size="large" type="lock" />}
        placeholder="●●●●●●●●"
        label="Confirm Password"
        name="rp_password"
      />
      <Radio.Group
        label={
          status === "inactive"
            ? id
              ? "Inactive User"
              : "An email will be sent for account activation."
            : "Active user"
        }
        name="status"
        initial={status}
        flex={0.5}
        onChange={(name, value) => {
          setStatus(value);
        }}
        style={{
          textAlign: "center",
        }}
      >
        <Radio value={"active"}>Active</Radio>
        <Radio value={"inactive"}>Inactive</Radio>
      </Radio.Group>
    </SimpleForm>
  );
};
export default UserForm;
