import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField } from "../com/form/";
import { Input, Row, InputNumber } from "antd";
import { Switch } from "antd";
import { tax_information, terms_considered } from ".";
/* 
"ListPrice"
"BedroomsTotal"
"BathroomsFull"
"SpecialListingConditions"
"property_type_information"
"LotSizeArea"
"NumberOfUnitsTotal"
"StoriesTotal"
"YearBuilt"
*/
const GeneralInformation = ({ id, onReset, onChange, ...props }) => {
  const [PropertyType, setPropertyType] = useState("Residential");
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const handlePrice = (value, checked) => {
    if (checked && value) {
      setPropertyType(value);
      if (onChange) {
        onChange("PropertyType", value);
        if (onReset) onReset({ ...record, PropertyType: value });
      }
    }
  };
  const setInitialValues = () => {
    let { record } = props;
    if (record) {
      setRecord(record);
      setPropertyType(record["PropertyType"]);
    }
  };
  useEffect(() => {
    let { loading } = props;
    setLoading(loading);
    return () => {};
  }, [props.loading]);
  useEffect(() => {
    setInitialValues();
    return () => {};
  }, [props.record]);
  return (
    <>
      {!loading && (
        <SimpleForm
          source="my-listings"
          onChange={onChange}
          initialValues={record}
          autoSubmit={false}
        >
          {PropertyType}
          <Switch
            label="For Sale"
            checked={PropertyType === "Residential"}
            onChange={(field, value) => handlePrice("Residential", value)}
          />
          <Switch
            label="For Rent"
            checked={PropertyType === "ResidentialLease"}
            onChange={(field, value) => handlePrice("ResidentialLease", value)}
          />
          {
            <InputNumber
              flex={PropertyType === "Residential" ? 0.33 : 1}
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              label={`${
                PropertyType === "Residential" ? "Sale Price" : "Rental Price"
              }`}
              name="ListPrice"
            />
          }
          {PropertyType === "Residential" && (
            <InputNumber
              flex={0.33}
              /* formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              } */
              label={"Tax Amount"}
              /* placeholder={"Tax Amount"} */
              name="TaxAnnualAmount"
            />
          )}
          {PropertyType === "Residential" && (
            <InputNumber
              flex={0.33}
              /* formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              } */
              label={"Tax Year"}
              /* placeholder={"Tax Year"} */
              name="TaxYear"
            />
          )}
          {PropertyType === "Residential" && (
            <SelectField
              mode="multiple"
              choices={tax_information.map((it, index) => ({
                _id: it,
                name: it
              }))}
              label={"Tax Information"}
              /* placeholder={"Tax Information"} */
              name="TaxInformation"
            />
          )}
          {PropertyType === "Residential" && (
            <SelectField
              mode="multiple"
              choices={terms_considered.map((it, index) => ({
                _id: it,
                name: it
              }))}
              label={"Finance Terms"}
              /* placeholder={"Finance Terms"} */
              name="ListingTerms"
            />
          )}
        </SimpleForm>
      )}
    </>
  );
};
export default GeneralInformation;
