import { Card } from "antd";
import React, { useState, useEffect } from "react";
import { WidgetWrapper } from "./Styles";
const { Meta } = Card;
/* 
    color: #3f51b5!important;
    font-size: 16px;
    position: relative;
    font-family: 'NoirPro';
*/
const Widget = ({ children, title, ...props }) => {
    return (<WidgetWrapper>
        <Card
            title={title}
            {...props}>
            {children}
        </Card>
    </WidgetWrapper>);
}
export default Widget;