import React from "react";
import { DatePicker } from "antd";
const DateField = ({
  format = "DD-MM-YYYY",
  record,
  source,
  name,
  ...props
}) => {
  const handleCahnge = (value) => {
    if (props.onChange) {
      if (value != null) {
        if (props.onSelect) props.onSelect(value.format(format));
        return props.onChange(value.format(format));
      }
      props.onChange();
    }
  };
  return <DatePicker size="large" {...props} onChange={handleCahnge} />;
};
export default DateField;
