export const InteriorFeatures = [
  "AirFiltration",
  "DryBar",
  "Elevator",
  "Intercom",
  "FirstFloorEntry",
  "Atrium",
  "Attic",
  "BedroomonMainLevel",
  "BreakfastArea",
  "DiningArea",
  "EatinKitchen",
  "FrenchDoorDoors",
  "KitchenIsland",
  "Pantry",
  "PullDownAtticStairs",
  "UpperLevelMaster",
  "VaultedCeilings",
  "SeparateFormalDiningRoom",
  "EatinKitchen",
  "HighCeilings",
  "KitchenIsland",
  "KitchenDiningCombo",
  "PullDownAtticStairs",
  "SplitBedrooms",
  "WalkInClosets",
  "EntranceFoyer",
  "GardenTubRomanTub",
  "SplitBedrooms",
  "UpperLevelMaster",
  "Bar",
  "LivingDiningRoom",
  "Other",
  "MainLevelMaster",
  "ClosetCabinetry",
  "CentralVacuum",
  "HandicapAccess",
  "SecondFloorEntry",
  "Fireplace",
  "CustomMirrors",
  "Bar",
  "VaultedCeilings"
];
export const ParkingFeatures = [
  "Assigned",
  "Attached",
  "Carport",
  "Garage",
  "Guest",
  "GolfCartGarage",
  "OneSpace",
  "TwoSpaces",
  "OnStreet",
  "Valet",
  "GarageDoorOpener",
  "Underground",
  "Deeded"
];
export const LotFeatures = [
  "Agricultural",
  "Back Yard",
  "City Lot",
  "Cleared",
  "Corner Lot",
  "Cul-De-Sac",
  "Farm",
  "Front Yard",
  "Garden",
  "Greenbelt",
  "Interior Lot",
  "Irregular Lot",
  "Landscaped",
  "Pasture",
  "Paved",
  "Pie Shaped Lot",
  "Private",
  "Rectangular Lot",
  "Waterfront",
  "Wetlands",
  "Wooded"
];
export const PoolFeatures = [
  "None",
  "Pool",
  "Private",
  "Fenced",
  "AboveGround",
  "Heated",
  "CleaningSystem",
  "Community",
  "Gunite",
  "InGround",
  "Indoor",
  "PoolEquipment",
  "Other"
];
export const PetsAllowed = [
  "Yes",
  "No",
  "NoPetRestrictions",
  "PetRestrictions",
  "SizeLimit",
  "CatsOK",
  "DogsOK",
  "Conditional"
];
export const View = [
  "Bay",
  "Beach",
  "Canal",
  "City",
  "Downtown",
  "Garden",
  "Golf Course",
  "Lake",
  "Marina",
  "Mountain(s)",
  "Neighborhood",
  "Ocean",
  "Orchard",
  "Park / Greenbelt",
  "Pool",
  "River",
  "Trees / Woods",
  "Valley",
  "Water"
];
export const Aminities = [
  { text: "Barbecue", value: "Barbecue" },
  {
    text: "Basketball Court",
    value: "Basketball Court"
  },
  {
    text: "Beach Access",
    value: "Beach Access"
  },
  {
    text: "Boat Dock",
    value: "Boat Dock"
  },
  {
    text: "Boating",
    value: "Boating"
  },
  {
    text: "Boat Slip",
    value: "Boat Slip"
  },
  {
    text: "Cabana",
    value: "Cabana"
  },
  {
    text: "Cable TV",
    value: "Cable TV"
  },
  {
    text: "Clubhouse",
    value: "Clubhouse"
  },
  {
    text: "Coin Laundry",
    value: "Coin Laundry"
  },
  {
    text: "Concierge",
    value: "Concierge"
  },
  {
    text: "Dog Park",
    value: "Dog Park"
  },
  {
    text: "Electricity",
    value: "Electricity"
  },
  {
    text: "Elevator(s)",
    value: "Elevator(s)"
  },
  {
    text: "Exercise Course",
    value: "Exercise Course"
  },
  {
    text: "Fitness Center",
    value: "Fitness Center"
  },
  {
    text: "Game Room",
    value: "Game Room"
  },
  {
    text: "Gated",
    value: "Gated"
  },
  {
    text: "Golf Course",
    value: "Golf Course"
  },
  {
    text: "Hot Water",
    value: "Hot Water"
  },
  {
    text: "Indoor Pool",
    value: "Indoor Pool"
  },
  {
    text: "Landscaping",
    value: "Landscaping"
  },
  {
    text: "Laundry",
    value: "Laundry"
  },
  {
    text: "Marina",
    value: "Marina"
  },
  {
    text: "Meeting Room",
    value: "Meeting Room"
  },
  {
    text: "None",
    value: "None"
  },
  {
    text: "Park",
    value: "Park"
  },
  {
    text: "Parking",
    value: "Parking"
  },
  {
    text: "Party Room",
    value: "Party Room"
  },
  {
    text: "Picnic Area",
    value: "Picnic Area"
  },
  {
    text: "Playground",
    value: "Playground"
  },
  {
    text: "Pool",
    value: "Pool"
  },
  {
    text: "Recreation Facilities",
    value: "Recreation Facilities"
  },
  {
    text: "RV / Boat Storage",
    value: "RV / Boat Storage"
  },
  {
    text: "RV Parking",
    value: "RV Parking"
  },
  {
    text: "Sauna",
    value: "Sauna"
  },
  {
    text: "Security",
    value: "Security"
  },
  {
    text: "Spa / Hot Tub",
    value: "Spa / Hot Tub"
  },
  {
    text: "Tennis Court(s)",
    value: "Tennis Court(s)"
  },
  {
    text: "Trash",
    value: "Trash"
  },
  {
    text: "Water",
    value: "Water"
  }
];
export const construction_type = [
  "Aluminum Siding",
  "Concrete Block Construction",
  "Brick Exterior Construction",
  "Brick Veneer",
  "Block With Brick",
  "Construction",
  "Composition Shingle",
  "Pre-Cast Concrete Construction",
  "Elevated Construction",
  "Frame Construction",
  "Frame With Stucco",
  "Manufactured/Mobile Home",
  "Metal Construction",
  "Modular Construction",
  "New Construction",
  "Other Construction",
  "Piling Construction",
  "Pre-Fab Construction",
  "Precast Construction",
  "Siding-Asbestos",
  "Slab Construction",
  "Stone Exterior Construction",
  "Stucco Exterior Construction",
  "Under Construction",
  "Wood Siding"
];
export const roof_description = [
  "Aluminum Roof",
  "Bahama Roof",
  "Barrel Roof",
  "Built-Up Roof",
  "Composition Roll",
  "Concrete Roof",
  "Fiberglass Roof",
  "Flat Tile Roof",
  "Flat Roof With Facade Front",
  "Manufactured/Mobile Home",
  "Metal Roof",
  "Other Roof",
  "Roof Over",
  "Curved/S-Tile Roof",
  "Wood Shake Roof",
  "Shingle Roof",
  "Slate Roof",
  "Tar/Gravel Roof",
  "Wood Shingle Roof"
];
export const tax_information = [
  "Tax Reflects Agriculture Exemption",
  "Tax Reflects City & County Tax",
  "Tax Reflects County Only Tax",
  "Tax Reflects Disability Exemption",
  "Tax Reflects Homestead Tax",
  "New Construction",
  "Tax Reflects No Exemptions",
  "Tax Reflects No Homestead Tax",
  "Tax Reflects Other Tax Exemption",
  "Tax Reflects Widow Exemption"
];
export const property_information = [
  "Attached",
  "Detached",
  "Elevator",
  "Old Spanish",
  "Other",
  "Ranch",
  "Substantially Remodeled",
  "Stairs"
];
export const waterFrontFeatures = [
  "Bay Front",
  "Canal Front",
  "Creek Front",
  "Canal Width 1-80 Feet",
  "Canal Width 121 Feet Or More",
  "Canal Width 81-120 Feet",
  "Fixed Bridge(S)",
  "Intersecting Canals",
  "Intracoastal Front",
  "Lagoon Front",
  "Lake Front",
  "Lake Access",
  "Mangrove Front",
  "Navigable",
  "No Fixed Bridges",
  "Ocean Access",
  "Ocean Front",
  "One Fixed Bridge",
  "Other Waterfront",
  "Point Lot",
  "Pond Front",
  "Rip Rap",
  "River Front",
  "Seawall"
];
export const exterior_features = [
  "Balconies",
  "Barbeque",
  "Barn &/Or Stalls",
  "Built-In Grill",
  "Exterior Cat Walk",
  "Wood Decking",
  "Extra Building/Shed",
  "Fence",
  "Fruit Trees",
  "Greenhouse",
  "High Impact Doors",
  "Laundry Facility",
  "Exterior Lights",
  "Open Balcony",
  "Open Porch",
  "Other Exterior",
  "Outdoor Shower",
  "Patio",
  "Room For Pool",
  "Satellite Dish",
  "Screened Balcony",
  "Screened Patio/Porch",
  "Electric Shutters",
  "Hurricane Shutters",
  "Skylights",
  "Stables",
  "Tennis Court",
  "Tv Antenna",
  "Wraparound Porch"
];
export const flooring = [
  "Carpeted Floors",
  "Ceramic Floor",
  "Clay Floors",
  "Concrete Floors",
  "Marble/Slate Floors",
  "Other Floors",
  "Parquet Floors",
  "Slate",
  "Terrazzo Floors",
  "Tile Floors",
  "Vinyl Floors",
  "Wood Floors"
];
export const cooling = [
  "Air Purifier",
  "Attic Fan",
  "Ceiling Fans",
  "Central Cooling",
  "Electric Cooling",
  "Gas Cooling",
  "Humidistat",
  "No Cooling",
  "Other",
  "Paddle Fans",
  "Ridge Vent/Turbines",
  "Thermal Attic Fan",
  "Wall/Window Unit Cooling",
  "Zoned Cooling"
];
export const heating = [
  "Central Heat",
  "Electric Heat",
  "Gas Heat",
  "Heat Strip",
  "Heat Pump/Reverse Cycle",
  "No Heat",
  "Oil Heat",
  "Other",
  "Radiant Heat",
  "Cycle Unit",
  "Solar Heat",
  "Space Heater",
  "Wall Furnace",
  "Window/Wall",
  "Zoned Heat"
];
export const rent_includes = [
  "Cable Tv",
  "Electric",
  "Gas",
  "Hot Water",
  "Lawn Care",
  "None",
  "Other Rent Incl",
  "Pest Control",
  "Pool Maintenance",
  "Sewer",
  "Trash Removal",
  "Water"
];
export const terms_considered = [
  "Assumption",
  "Cash Only",
  "Conventional",
  "Exchange",
  "Fha",
  "Va",
  "FHA-Va Approved",
  "FHA 203K",
  "Lease Option",
  "Lease Purchase",
  "No Terms",
  "Other",
  "Owner Financing",
  "Owner Financing less 20K Down",
  "Owner Hold 2nd Mortgage",
  "Owtb Wrap",
  "Seller Will Pay Closing Costs",
  "Will Rent"
];
export const SecurityInformation = [
  "BuildingSecurity",
  "ClosedCircuitCameras",
  "ComplexFenced",
  "GatedCommunity",
  "KeyCardEntry",
  "LobbySecured",
  "FireSprinklerSystem",
  "SecurityGuard",
  "SmokeDetectors",
  "LobbySecured"
];
export const ShowingInformation = [
  "Appointment Only",
  "24 Hour Notice",
  "Alarm On",
  "Call Listing Agent",
  "List Agent Must Accompany",
  "Showing Assist",
  "See Broker Remarks",
  "Other Showing Instruction",
  "Elect Lockbox - Call List Agent",
  "Pet On Premises",
  "Gate Code",
  "No Sign"
];
export const sewer = ["Municipal Sewer", "Other Sewer", "Septic"];
export const special_conditions = [
  {
    text: "Short Sale",
    value: "ShortSale"
  },
  {
    text: "Listed AsIs",
    value: "ListedAsIs"
  },
  {
    text: "Corporate Listing",
    value: "CorporateListing"
  },
  {
    text: "Auction",
    value: "Auction"
  },
  {
    text: "Bankruptcy Property",
    value: "BankruptcyProperty"
  },
  {
    text: "HUD Owned",
    value: "HUDOwned"
  },
  {
    text: "In Foreclosure",
    value: "InForeclosure"
  },
  {
    text: "Notice Of Default",
    value: "NoticeOfDefault"
  },
  {
    text: "Probate Listing",
    value: "ProbateListing"
  },
  {
    text: "Real Estate Owned",
    value: "RealEstateOwned"
  },
  {
    text: "Standard",
    value: "Standard"
  },
  {
    text: "Third Party Approval",
    value: "ThirdPartyApproval"
  }
];
export const architectural_style = [
  {
    text: "Garden Home",
    value: "GardenHome"
  },
  {
    text: "Detached",
    value: "Detached"
  },
  {
    text: "One Story",
    value: "OneStory"
  },
  {
    text: "Two Story",
    value: "TwoStory"
  },
  {
    text: "Spanish Mediterranean",
    value: "SpanishMediterranean"
  },
  {
    text: "High Rise",
    value: "HighRise"
  },
  {
    text: "Other",
    value: "Other"
  }
];
