import React, { useState, useEffect } from "react";
import { WrapperAcelleMail } from "./Styles";
import {
  Checkbox,
  Icon,
  Input,
  Radio,
  Select,
  AutoComplete,
  Button,
  className,
  DatePicker,
  InputNumber,
  Col,
  Row,
  Divider,
  message,
  Tooltip,
  List,
  Skeleton,
  Spin,
} from "antd";
import { SimpleForm, SteperForm } from "../components/com/form/";
import styled from "styled-components";
import { SelectField } from "../components/com/fields";
import { getService } from "../services";
import PlanAcelleMail from "../components/plan-acelle-mail/PlanAcelleMail";
import { useSelector } from "react-redux";
import { Wrapper } from "./styes";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const timeZone = [
  {
    _id: "America/New_York",
    name: "America/New_York",
  },
  {
    _id: "America/Scoresbysund",
    name: "America/Scoresbysund",
  },
];
const Form = styled(SteperForm)``;

const PlanDetails = ({ onChange, ...props }) => {
  const [plan, setPlan] = useState();
  const [plan_id, setPlanId] = useState();
  const [plans, setPlans] = useState([]);
  const [selected_id, setSelectedId] = useState();
  const [plan_ids, setPlanIds] = useState([]);

  const user = useSelector(({ user }) => user);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [loading_plans, setLoadingPlans] = useState(true);
  const getPlan = async () => {
    setLoading(true);
    const service = getService("acelle-plans");
    let plan = await service.get(plan_id);
    plan["options"] = JSON.parse(plan["options"]);
    setPlan(plan);
    setLoading(false);
    setInitialized(true);
  };
  const getPlans = async () => {
    const service = getService("plans");
    setLoadingPlans(true);
    if (plan_ids.length > 0) {
      let plans = await service
        .find({
          query: {
            _id: { $in: plan_ids },
          },
        })
        .then(({ data }) => data);
      /*  plans = plans.map((plan) => {
      plan["options"] = JSON.parse(plan["options"]);
      return plan;
    }); */
      setPlans(plans);
      setLoadingPlans(false);
    }
  };
  const handleOnClick = (plan_id) => {
    setSelectedId(plan_id);
    setPlanId(plan_id);
    if (onChange) onChange(plan_id);
  };
  const renderPlans = (it) => {
    let primary = selected_id
      ? selected_id == it.plan_id
      : it.plan_id && it.plan_id == plan_id;
    return (
      <List.Item
        onClick={() => handleOnClick(it.plan_id)}
        className={`${primary ? "primary-plan" : ""}`}
        key={it._id}
        /* actions={[<div>{it.price}</div>]} */
      >
        <Skeleton
          loading={loading_plans}
          active
          title={{
            width: 40,
          }}
          paragraph={{
            rows: 2,
            //width: 50,
          }}
        >
          <List.Item.Meta
            title={it.name}
            description={
              <>
                <h2>{it.price} USD</h2>
                <span class="description-plan">{`${
                  primary ? "Current Plan" : `Use ${it.name} plan.`
                }`}</span>
              </>
            }
          />
        </Skeleton>
      </List.Item>
    );
  };
  useEffect(() => {
    if (
      typeof user != "undefined" &&
      user.plan_id &&
      !plan_ids.includes(user.plan_id)
    )
      setPlanIds((plan_ids) => [...plan_ids, user.plan_id]);
    if (
      typeof user != "undefined" &&
      user.company &&
      !plan_ids.includes(user.company.plan_id)
    )
      setPlanIds((plan_ids) => [...plan_ids, user.company.plan_id]);

    if (typeof user != "undefined" && user.plan) {
      setPlanId(user.plan.plan_id);
    } else if (
      typeof user != "undefined" &&
      user.company &&
      user.company.plan
    ) {
      setPlanId(user.company.plan.plan_id);
    }
  }, [user]);
  useEffect(() => {
    if (plan_id) getPlan();
  }, [plan_id]);
  useEffect(() => {
    getPlans();
  }, [plan_ids]);
  if (loading && !initialized)
    return (
      <div className="loader">
        <div className="loader-content">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  return (
    <Wrapper>
      <div>
        <h2>{plan.name}</h2>
      </div>
      <div>
        <article
          style={{
            margin: 10,
          }}
        >
          {plan.description}
        </article>
      </div>
      <div>
        <ul>
          <li>
            <span>Email Max: </span>
            <strong>{plan.options.email_max}</strong>
          </li>
          <li>
            <span>Subscriber Max: </span>
            <strong>{plan.options.subscriber_max}</strong>
          </li>
          <li>
            <span>Campaign Max: </span>
            <strong>{plan.options.campaign_max}</strong>
          </li>
        </ul>
      </div>
      <div>
        <List
          //loading={loading_plans}
          grid={{
            xxl: 1,
            xl: 1,
            sm: 1,
            md: 1,
            sm: 1,
            xs: 1,
          }}
          itemLayout="horizontal"
          dataSource={plans}
          renderItem={renderPlans}
        />
      </div>
    </Wrapper>
  );
};
const AcelleMarketing = ({ plugin_id, ...props }) => {
  const [record, setRecord] = useState({
    plan_id: 1,
  });
  const [steps, setStepes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState();
  const [options, setOptions] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  //URL_BASE_ACELLE
  const [completed, setCompleted] = useState(false);
  const [response, setResponse] = useState();
  const [done, setDone] = useState(false);
  const [current, setCurrent] = useState(0);
  const user = useSelector(({ user }) => user);
  const handleChange = (payloads) => {
    setRecord({
      ...record,
      ...payloads,
    });
  };
  const handleSelectPlan = (plan_id) => {
    //setCurrent(3);
    setRecord({
      ...record,
      plan_id,
    });
  };
  const handleSubmit = () => {
    if (record) {
      //connect-company-plugins
      const service = getService("connect-plugins");
      setSubmitting(true);
      if (!plugin_id) return message.error("Plugin is not defined");
      service
        .create({ plugin_id, ...record })
        .then((res) => {
          message.success("create successfully");
          //if (props.onSubmit) props.onSubmit(res);
          setResponse(res);
          setCompleted(true);
          setSubmitting(false);
        })
        .catch((err) => {
          message.error(err.message);
          setSubmitting(false);
        });
    }
  };
  const getCustomer = async () => {
    const service = getService("customers");

    let customer_id =
      user.customer_id || (user.company && user.company.customer_id);
    if (!customer_id) return setLoading(false);
    let customer = await service.get(customer_id);
    setCustomer(customer);
    setLoading(false);
  };
  useEffect(() => {
    let steps = [
      {
        title: "",
        content: [
          <div flex={1} className="">
            <h2 className="text-sm text-center">
              Supercharge your CRM with Acelle Mail Marketing integration!
            </h2>
            <article className="m-sm p-sm bg-gray-light">
              <div className="flex justify-center align-center gap-sm">
                <img
                  src="/images/acelle-mail.webp"
                  style={{
                    maxWidth: 164,
                  }}
                  alt=""
                />
              </div>
              <div>
                <span>
                  Unlock powerful email marketing capabilities and seamless
                  communication.
                </span>
                <br />
                <span>
                  Activate the plugin now to take your customer relationship
                  management to the next level
                </span>
              </div>
            </article>
          </div>,
        ],
      },
      {
        title: <h2>Plan Features</h2>,
        content: [
          <div flex={1}>
            {
              <PlanDetails onChange={handleSelectPlan} />
              /*  <PlanAcelleMail
                autoplay={false}
                reference={"acelle-plans"}
                onSelect={handleSelectPlan}
                selected={record && record.plan_id}
                filterDefaultValues={{
                  status: "active",
                }}
                validations={[
                  { required: true, message: "the plan is required" },
                ]}
              /> */
            }
          </div>,
        ],
      },
      {
        title: <h2>User Account</h2>,
        description: "Date users",
        content: [
          <Input
            size="large"
            flex={1}
            placeholder="First Name"
            name="first_name"
            initial={record.first_name}
            validations={[
              { required: true, message: "First Name is required" },
            ]}
          />,
          <Input
            size="large"
            flex={1}
            placeholder="Last Name"
            name="last_name"
            initial={record.last_name}
            validations={[{ required: true, message: "Last Name is required" }]}
          />,
          <Input.Password
            size="large"
            flex={1}
            type="password"
            xtype="password"
            prefix={<Icon size="large" type="lock" />}
            placeholder="Password"
            name="password"
            initial={record.password}
            validations={[{ required: true, message: "Password is required" }]}
          />,
          <Input.Password
            size="large"
            flex={1}
            reference={"password"}
            type="password"
            xtype="password"
            prefix={<Icon size="large" type="lock" />}
            placeholder="Confirm Password"
            name="rp_password"
            validations={[{ required: true, message: "Password is required" }]}
          />,
        ],
      },
      {
        title: <h2>Contact Information</h2>,
        // description: "Select Role",
        content: [
          <Input
            size="large"
            flex={1}
            placeholder="First Name"
            name="first_name"
            initial={record.first_name}
            validations={[
              { required: true, message: "First Name is required" },
            ]}
          />,
          <Input
            size="large"
            flex={1}
            placeholder="Last Name"
            name="last_name"
            initial={record.last_name}
            validations={[{ required: true, message: "Last Name is required" }]}
          />,
          <Input
            flex={1}
            name="company_name"
            size="large"
            placeholder="Company Name"
            initial={record.company_name}
            validations={[
              { required: true, message: "Company Name is required" },
            ]}
          />,
          <Input
            flex={1}
            size="large"
            name="email"
            initial={record.email}
            placeholder="Email"
            validations={[{ required: true, message: "Email is required" }]}
          />,
          <Input
            flex={1}
            size="large"
            name="phone"
            initial={record.phone}
            placeholder="Phone"
            validations={[{ required: true, message: "Phone is required" }]}
          />,
        ],
      },
      {
        title: <h2>Customer Information</h2>,
        content: [
          <SelectField
            flex={1}
            size="large"
            name="timezone"
            placeholder="Time zone"
            choices={timeZone}
            validations={[{ required: true, message: "Time Zone is required" }]}
          />,
          <SelectField
            flex={1}
            size="large"
            name="language_id"
            source="language_id"
            reference="acelle-languages"
            optionText={(value, record) => {
              return `${record.name || ""} `;
            }}
            optionValue="id"
            intial="EN"
            placeholder="Language"
            /* validations={[
                        { required: true, message: "Language is required" }
                    ]} */
          />,
        ],
      },
    ];
    setStepes((prev_steps) => [...steps]);
    return () => {};
  }, [record, options]);
  useEffect(() => {
    getCustomer();
    let plan_id;
    if (user && user.company && user.company.plan_id)
      plan_id = user.company.plan_id;
    if (user && user.plan) plan_id = user.plan.plan_id;
    setRecord({
      ...record,
      plan_id,
    });
  }, [user]);
  useEffect(() => {
    if (completed) {
      if (props.onSubmit) props.onSubmit(response);
      setCompleted(false);
    }
  }, [completed]);
  return (
    <WrapperAcelleMail>
      {
        <Row type="flex" justify="center" align="middle" gutter={10}>
          <Col span={24}>
            {loading && (
              <div className="loader">
                <div className="loader-content">
                  <Spin indicator={antIcon} />
                </div>
              </div>
            )}
            {!loading && !customer && (
              <Form
                textDone="SEND"
                onDone={handleSubmit}
                done={done}
                onChange={handleChange}
                current={current}
                steps={steps}
                /*   steps={steps.filter(
                      it => typeof it.type == "undefined" || it.type == record.role
                  )} */
              />
            )}
            {!loading && customer && (
              <div className="acelle-message">
                <h2 className="text-sm text-center">
                  You are connected to acelle with email:
                  <br />
                  {customer.email}
                </h2>
                <article className="m-sm p-sm bg-gray-light">
                  <div className="flex justify-center align-center gap-sm">
                    <img
                      src="/images/acelle-mail.webp"
                      style={{
                        maxWidth: 164,
                      }}
                      alt=""
                    />
                  </div>
                  <div>
                    You are already registered in <span>acelle</span>, please
                    login with your user account and password.
                  </div>
                </article>
                <div className="flex justify-center align-center mt-xl ml-xl">
                  <Button
                    href="https://www.mcloserx.com/login"
                    target="_blank"
                    className="flex"
                    size="large"
                    onClick={() => {
                      /* navigate(
                      `${location.pathname}?${qs.stringify({
                        step: 2,
                      })}`
                      ); */
                    }}
                    type="primary"
                    icon="arrow-right"
                  >
                    Go to Acelle!
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      }
    </WrapperAcelleMail>
  );
};
export default AcelleMarketing;
