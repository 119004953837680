import React, { useState, useEffect } from "react";
import { Avatar, Button, Col, List, message, Row } from "antd";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
import { getService } from "../../services";
import { SearchField } from "../../components/com/form/";
import { Wrapper } from "./Styles";
import { useSelector } from "react-redux";
import { IoPersonAddSharp } from "react-icons/io5";
import { useIntl } from "react-intl";
const AddMemberLists = ({ id, reference, source, title, ...props }) => {
  const intl = useIntl();
  const { txt_workflow } = intl.messages;
  const service = getService(reference || source);
  const [members, setMembers] = useState([]);
  const [members_ids, setMembersIds] = useState([]);
  const [payloads, setPayloads] = useState({});
  const [submiting, setSubmiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [board, setBoard] = useState();
  const user = useSelector(({ user }) => user);
  const getMembers = async () => {
    const service = getService("users");
    const boardService = getService(reference);
    let board = await boardService.get(id);
    let { members_ids } = board;
    if (members_ids.length === 0) return setMembers([]);
    setLoading(true);
    service
      .find({
        query: {
          _id: { $in: members_ids },
          $limit: 20,
          $skip: 0,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setMembers(data || []);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };
  const handleRemoveMember = (user_id) => {
    let member_exists = members.find((it) => it._id === user_id);
    if (!member_exists) {
      return message.warning("Member not exists!");
    }
    // const service = getService("boards");
    let members_ids = board.members_ids.filter((_id) => _id !== user_id);
    setSubmiting(true);
    setMembersIds(members_ids);
    service
      .patch(board._id, {
        members_ids,
      })
      .then((board) => {
        setSubmiting(false);
        setBoard(board);
        message.success("Updated Successfully!");
      })
      .catch((err) => {
        message.error(err.message);
        setSubmiting(false);
      });
  };
  const handleAddMember = () => {
    let { user_id } = payloads;
    if (user_id) {
      let member_exists = members.find((it) => it._id === user_id);
      if (member_exists) {
        return message.warning("Member already exists!");
      }
      // const service = getService("boards");
      setSubmiting(true);
      service
        .patch(board._id, {
          members_ids: [...board.members_ids, user_id],
        })
        .then((board) => {
          setSubmiting(false);
          setMembersIds(board.members_ids);
          setBoard(board);
          message.success("Member added successfully!");
        })
        .catch((err) => {
          message.error(err.message);
          setSubmiting(false);
        });
    }
  };
  const renderItem = (item) => {
    return (
      <List.Item
        actions={[
          <Button
            onClick={() => handleRemoveMember(item._id)}
            type="link"
            size="small"
            icon="delete"
          />,
        ]}
      >
        <List.Item.Meta
          avatar={
            <Avatar
              src={
                item.picture ? `${URL_S3}/${item.picture}` : URL_DEFAULT_AVATAR
              }
            />
          }
          title={
            <span>
              {item.first_name || ""} {item.last_name || ""}
            </span>
          }
        />
      </List.Item>
    );
  };
  const handleSelect = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };

  useEffect(() => {
    if (board) {
      if (board.team) {
        setMembersIds([...board.members_ids, ...board.team.members_ids]);
      } else {
        setMembersIds(board.members_ids || []);
      }
    }
  }, [board]);
  useEffect(() => {
    getMembers();
  }, [members_ids]);
  useEffect(() => {
    if (props.board) setBoard(props.board);
  }, [props.board]);
  return (
    <Wrapper className="container-members">
      <Row type="flex" align="middle" justify="center">
        <Col>
          <div className="section-members">
            <List
              header={
                <Row type="flex" align="center" justify="center">
                  <Col span={22}>
                    <div className="section-avatar">
                      <IoPersonAddSharp className="icon-avatar" />
                    </div>
                    <div className="section-title">
                      <h2>{title}</h2>
                    </div>
                    <div className="section-search">
                      <SearchField
                        allowClear
                        mode="multiple"
                        source="get-all-users"
                        name="user_id"
                        placeholder="Search Members"
                        onSelectItem={handleSelect}
                        defaultFilterValues={{
                          company_id: user && user.company_id,
                        }}
                        optionText={(value, record) =>
                          `${record.first_name || ""} ${record.last_name || ""}`
                        }
                      />
                    </div>
                  </Col>
                  <Col span={18}>
                    <div className="section-btn">
                      <Button
                        onClick={handleAddMember}
                        loading={submiting}
                        className="btn-invite-member"
                        block
                        type="primary"
                        // size="small"
                      >
                        Invite
                      </Button>
                    </div>
                  </Col>
                </Row>
              }
              renderItem={renderItem}
              loading={loading}
              dataSource={members}
              locale={{ emptyText: "No Members" }}
            />
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default AddMemberLists;
