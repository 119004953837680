import React from "react";
import { Layout, Row, Col } from "antd";
import styled from "styled-components";
import { LOGO_CLOSERX, BACKGROUND_LINEAL } from "../constants";
import { Link, navigate } from "@reach/router";

const { Content } = Layout;

const Container = styled.div`
  min-height: 100vh;

  & .container-img {
    padding: 2px;
    margin-top: 0px;
    padding-top: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 999999;
  }
  & .container-title-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .container-title-img img {
    height: 50px;
    width: 100x;
  }

  & .container-childdren {
    position: relative;
    z-index: 999999;
    background: transparent;
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
  }
  & .container-background {
    position: relative;
  }
  & .container-background::before {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 60px;
    background-image: url("/images/Grupobackground.svg");
    background-size: contain;
    background-position: center center;
  }
`;

const LayoutCanter = ({ children, showLogo = true, ...props }) => {
  const handleClick = () => {
    alert("");
    navigate("./");
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Content className="content-antd-background">
          <Container>
            <Row
              style={{ minHeight: "100vh" }}
              type="flex"
              justify="center"
              align="middle"
              className="container-childdren"
            >
              <Col>{children}</Col>
              <div className="container-background"></div>
            </Row>
          </Container>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutCanter;
