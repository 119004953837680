import React, { useState, useEffect } from "react";
import {
  List,
  Card,
  Avatar,
  Button,
  Menu,
  Icon,
  Dropdown,
  message,
  Row,
  Col,
  Modal,
  Switch,
  Tabs,
} from "antd";
import { URL_S3, URL_DEFAULT_AVATAR, listing_status } from "../../constants";
import { Router, navigate } from "@reach/router";
import { WrapperBoards, WrapperBoardList, WrapperCardBoard } from "./Styles";
import { Tools } from "../../views/boards/Styles";
import { useSelector } from "react-redux";
import { getCurrentUser, getService } from "../../services";
import { MyModal, ColorPicker } from "../com";
import TeamForm from "./TeamForm";
import CreateBoard from "./CreateBoard";
import { BoardForm } from "./";
import moment from "moment";
import qs from "qs";
import _, { remove } from "lodash";
import SearchField from "../com/form/SearchField";
import { GoProject } from "react-icons/go";
import NewBoardForm from "./NewBoardForm";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import DefaultCreateBoard from "./DefaultCreateBoard";
import * as actionTypes from "../../store/actions";
import { TabContext } from "./context/";
import { IoArchiveOutline } from "react-icons/io5";
import { IoReloadOutline } from "react-icons/io5";
import { FaRegCheckSquare } from "react-icons/fa";
const { TabPane } = Tabs;
const { Item } = List;
const { Meta } = Card;
const { confirm } = Modal;
const service = getService("boards");

const ItemCard = ({
  item,
  activeKey,
  board,
  onClickItemMenu,
  onNew,
  onRemove,
  Onsave,
  ...props
}) => {
  let {
    favorite_boards_ids,
    setFavoriteBoardsIds,
    refresh,
    setRefresh,
  } = useContext(TabContext);
  let { author, background, board_type, type, status } = item;
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  // const [favorite_boards_ids, setFavoriteBoardsIds] = useState([]);
  const [submithing, setSubmithing] = useState(false);
  const handleFavorite = (board_id) => {
    confirm({
      /* title: favorite_boards_ids.includes(board_id)
        ? "Removed Board From Favorites"
        : "Add Board To Favorites?", */
      content: (
        <div className="flex justify-center">
          {favorite_boards_ids.includes(board_id) ? (
            <span>Do you want to remove this board from favorites</span>
          ) : (
            <span>Do you want to add this board to favorites?</span>
          )}
        </div>
      ),
      // content: 'When clicked the OK button, this dialog will be closed after 1 second',
      onOk() {
        updateWorkflowFavorite(board_id);
      },
      onCancel() {},
    });
  };
  const updateWorkflowFavorite = async (board_id) => {
    console.log("board_id", board_id);
    // let { favorite_boards_ids } = user;
    favorite_boards_ids = favorite_boards_ids || [];
    let isFavorite = favorite_boards_ids.includes(board_id);
    if (isFavorite) {
      favorite_boards_ids = favorite_boards_ids.filter(
        (_id) => _id !== board_id
      );
    } else favorite_boards_ids = [...favorite_boards_ids, board_id];
    const service = getService("users");
    setSubmithing(true);
    try {
      setFavoriteBoardsIds(favorite_boards_ids);
      await service.patch(user._id, {
        favorite_boards_ids,
      });
      // setRefresh((refresh) => !refresh);
      // currentUser();
      setSubmithing(false);
      message.success(
        favorite_boards_ids.includes(item._id)
          ? "Added to favorites"
          : "Removed from favorites"
      );
    } catch (err) {
      message.error(err.message);
      setSubmithing(false);
    }
  };
  const currentUser = async () => {
    try {
      let res = await getCurrentUser();
      console.log("que es res", res);
      dispatch({
        type: actionTypes.AUTHENTICATION,
        user: res,
        accessToken: window.localStorage.getItem("feathers-jwt"),
      });
    } catch (err) {
      message.error(err.message);
    }
  };
  const handleonNew = () => {
    if (onNew) onNew();
  };
  const handleOnRemove = (board_id) => {
    if (onRemove) onRemove(board_id);
  };
  const MenuItems = ({ record, ...props }) => {
    const handleMenuClick = ({ key }) => {
      const service = getService("boards");
      switch (key) {
        case "archived":
          confirm({
            // title: "Archived?",
            content: (
              <div className="flex justify-center align-center">
                Are you sure you want to archive <br /> this board?
              </div>
            ),
            onOk() {
              // if (board) remove(board._id);
              if (item._id) {
                service
                  .patch(item._id, {
                    status: "archived",
                  })
                  .then((resp) => {
                    message.success("Board Archived");
                    currentUser();
                  })
                  .catch((err) => {
                    message.error(err.message);
                  });
              }
            },
          });
          break;
        case "activate":
          confirm({
            // title: "Activate?",
            content: (
              <div className="flex justify-center align-center">
                Are you sure you want to activate <br /> this board?
              </div>
            ),
            onOk() {
              if (item._id) {
                console.log("activate", item._id);
                service
                  .patch(item._id, {
                    status: "active",
                  })
                  .then((resp) => {
                    message.success("Board Reactivated");
                    currentUser();
                  })
                  .catch((err) => {
                    message.error(err.message);
                  });
              }
            },
          });
          break;
        case "delete":
          confirm({
            // title: "Delete?",
            content: (
              <div>
                This file will be permanently removed if you delete it. Please
                confirm if you wish to proceed.
              </div>
            ),
            onOk() {
              handleOnRemove(item._id);
            },
          });
          break;
        default:
          if (onClickItemMenu) onClickItemMenu(key, record);
          break;
      }
    };

    const handleColor = (background) => {
      if (Onsave)
        Onsave({
          ...record,
          background,
        });
    };
    return (
      <Menu className="menu-align menu-board-item" onClick={handleMenuClick}>
        {activeKey !== "archived" && (
          <Menu.Item key="edit">
            <span>
              <Icon type="edit" className="icon-global" />
              <span>Edit</span>
            </span>
          </Menu.Item>
        )}
        {activeKey !== "archived" && (
          <Menu.Item key="archived">
            <span>
              <IoArchiveOutline className="icon-global" />
              <span>Archive Board</span>
            </span>
          </Menu.Item>
        )}
        {activeKey === "archived" && (
          <Menu.Item key="activate">
            <span>
              <IoReloadOutline className="icon-global" />
              <span>ReActivate</span>
            </span>
          </Menu.Item>
        )}
        {activeKey === "archived" && (
          <Menu.Item key="delete">
            <span className="section-btn-delete">
              <Icon type="delete" className="btn-removed-red" />
              <span>Delete</span>
            </span>
          </Menu.Item>
        )}
        <Menu.Item
          disabled
          style={{
            minHeight: 280,
            overflow: "scroll",
            paddin: 4,
            background: "#FFF",
            cursor: "pointer",
          }}
        >
          <ColorPicker onChange={handleColor} />
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <Card
        style={{
          borderLeft: background && `4px solid ${background}`,
          background: background,
        }}
        extra={
          type !== "create" && author ? (
            <div className="section-extra">
              {board_type === "board" ? (
                <div>
                  {submithing && <Icon type="loading" />}

                  {/* <Button
                    className="btn-loading"
                    type="link"
                    loading={submithing}
                    onClick={() => handleFavorite(item._id)}
                  > */}
                  {!submithing && (
                    <Icon
                      type="star"
                      className="icon-favorite"
                      onClick={() => handleFavorite(item._id)}
                      theme={
                        favorite_boards_ids.includes(item._id)
                          ? "filled"
                          : undefined
                      }
                    />
                  )}
                  {/* </Button> */}
                </div>
              ) : (
                <div></div>
              )}
              <Dropdown
                /* onVisibleChange={(show) => {
                  setBoard(show ? item : null);
                }} */
                overlayClassName="menu-actions"
                overlay={<MenuItems record={item} />}
              >
                <Icon type="more" className="icon-card-board" />
              </Dropdown>
            </div>
          ) : (
            undefined
          )
        }
        className={`board-card ${board_type || ""} ${type || ""}`}
        hoverable
      >
        <div
          className="container-body-card"
          style={{
            padding: "2px",
          }}
          onClick={() => {
            if (type !== "create") navigate(`/dashboard/boards/${item._id}`);
            handleonNew();
          }}
        >
          {type !== "create" && (
            <div
              className="goproject"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2px",
              }}
            >
              <GoProject
                style={{
                  padding: "2px",
                  fontSize: "30px",
                }}
                color={
                  background
                    ? background !== "transparent"
                      ? "#fff"
                      : "rgb(0 0 0 / 65%)"
                    : "rgb(0 0 0 / 65%)"
                }
              />
            </div>
          )}
          {type !== "create" ? (
            <div className="section-title-board">
              <span>{item.name}</span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0px",
                padding: "2px",
              }}
            >
              <span className="flex justify-center">
                +<span className="title-add">Add {item.name}</span>
              </span>
            </div>
          )}
          {type !== "create" && (
            <div>
              {author && (
                <div className="author-container">
                  <div className="author-name-board">
                    <span className="author-name-board">
                      by:{" "}
                      {board_type === "template" &&
                      author &&
                      author.role === "Admin"
                        ? "Closerx"
                        : `${author && author.first_name} ${author &&
                            author.last_name}`}
                      {/*   by: {(author && author.first_name) || ""}{" "}
                          {(author && author.last_name) || ""} */}
                    </span>
                  </div>
                </div>
              )}
              {board_type === "board" && (
                <div className="date-container">
                  <span>{moment(item.createdAt).format("MM-DD-YYYY LT")}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

const BoardLists = ({
  team_id,
  onClickItemMenu,
  name,
  addButton = true,
  ...props
}) => {
  const user = useSelector(({ user }) => user);
  const { activeKey, tabs, ...context } = useContext(TabContext);
  const [board, setBoard] = useState();
  const [board_type, setBoardType] = useState("board");
  const [view, setView] = useState("default");
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [showBoard, setShowBoard] = useState(false);
  const [show_new_board, setShowNewBoard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getBoards = (query = {}) => {
    setLoading(true);
    if (board_type)
      service
        .find({
          query: {
            board_type,
            $or: [
              {
                user_id: user._id,
              },
              {
                members_id: { $in: [user._id] },
              },
            ],
            company_id: user.company_id,
            ...query,
            ...filterDefaultValues,
          },
        })
        .then(({ data }) => {
          if (addButton) {
            data = [
              {
                type: "create",
                name: board_type === "board" ? "Board" : "Template",
                // name: board_type,
              },
              ...data,
            ];
          }
          setDataSource(data);
          setLoading(false);
          if (!initialized) setInitialized(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
  };
  const onNew = () => {
    setView("new-project");
  };
  const handleSearch = (search) => {
    setFilterDefaultValues({
      search,
      board_type,
      company_id: user.company_id,
    });
  };
  const remove = (id) => {
    service
      .remove(id)
      .then((resp) => {
        getBoards({
          team_id,
        });
        setBoard(null);

        message.success("Board Deleted");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const renderItem = (item) => {
    let { background } = item;
    return (
      <WrapperCardBoard background={background}>
        <Item key={item._id}>
          <ItemCard
            item={item}
            activeKey={activeKey}
            board={board}
            onNew={onNew}
            onRemove={remove}
            Onsave={save}
            onClickItemMenu={onClickItemMenu}
            // favorite_boards_ids={favorite_boards_ids}
          />
        </Item>
      </WrapperCardBoard>
    );
  };
  const handleDefaultButton = () => {
    setView("default");
  };
  const handleOnClick = (key) => {
    if (props.onNew) props.onNew(key, key !== "board" ? "template" : undefined);
    // setKeyBoard( key);
  };

  const save = (payloads) => {
    if (payloads._id)
      service
        .patch(payloads._id, { ...payloads, board_type })
        .then((response) => {
          getBoards({
            team_id,
          });
          setBoard(null);
          message.success("Board Updated!");
        })
        .catch((err) => {
          message.error(err.message);
        });
  };

  const renderOptions = (item, index) => {
    return (
      <List.Item
        key={item.key}
        onClick={() => handleOnClick(item.key)}
        className={`item ${index === 0 ? "new-item" : ""}`}
      >
        <Card>
          <Card.Meta
            avatar={<Icon type={item.icon} />}
            title={item.title}
            description={item.description}
          />
        </Card>
      </List.Item>
    );
  };

  useEffect(() => {
    if (
      !_.isEmpty(props.filterDefaultValues) &&
      !_.isEqual(props.filterDefaultValues, filterDefaultValues)
    ) {
      setFilterDefaultValues(props.filterDefaultValues);
    }
  }, [props.filterDefaultValues]);

  useEffect(() => {
    if (props.board_type) setBoardType(props.board_type);
  }, [props.board_type]);
  useEffect(() => {
    if (!_.isEmpty(filterDefaultValues)) {
      getBoards();
    }
  }, [filterDefaultValues]);

  useEffect(() => {
    return () => {
      setView("default");
      setInitialized(false);
    };
  }, []);
  useEffect(() => {
    setMounted(activeKey === name);
  }, [activeKey]);
  useEffect(() => {
    if (context.refresh !== refresh && mounted) {
      setRefresh(context.refresh);
    }
  }, [context.refresh]);
  useEffect(() => {
    if (typeof refresh !== "undefined" && mounted) {
      getBoards();
    }
  }, [refresh]);
  useEffect(() => {
    return () => {
      if (mounted) setMounted(false);
      setFilterDefaultValues([]);
    };
  }, []);
  if (!mounted) return "No mounted";
  if (view === "new-project")
    return (
      <>
        <DefaultCreateBoard
          view={view}
          board_type={board_type}
          handleDefaultButton={handleDefaultButton}
          handleOnClick={handleOnClick}
        />
        {/* <WrapperBoardList className={`board-list ${view}`}>
        
        <div className="new-project-container">
          <div className="new-project-head">
            <h2>
              Create a{" "}
              <strong>
                New {board_type === "board" ? "Board" : "Template"}
              </strong>
            </h2>
          </div>
          <div className="new-project-list">
            <List
              grid={{
                gutter: 16,
                xxl: 2,
                xl: 2,
                lg: 2,
                md: 2,
                sm: 2,
                xs: 1,
              }}
              dataSource={[
                {
                  key: "board",
                  icon: "plus",
                  title:
                    board_type === "board" ? "Blank Board" : "New Template",
                  // description: "Starting over",
                },
                {
                  key: "template",
                  icon: "rocket",
                  title: "Board Template",
                  // description: "from the library",
                },
              ]}
              renderItem={renderOptions}
              footer={
                <div className="flex gap-lg item-centered justify-center">
                  <Button
                    onClick={() => {
                      setView("default");
                    }}
                    className="button-cancel"
                    size="small"
                    style={{
                      maxWidth: "36%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderColor: "#3c57b0",
                      fontWeight: "500",
                      borderRadius: "25px",
                      color: "#3c57b0",
                      height: "30px",
                    }}
                    block
                    type="ghost"
                    // icon="close"
                  >
                    Close
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      </WrapperBoardList> */}
      </>
    );
  if (view === "default")
    return (
      <WrapperBoardList className={`board-list ${view}`}>
        {initialized && (
          <Tools className="tools" justifyContent="space-between">
            {activeKey !== "archived" && (
              <div className="button-boards">
                {/*<Button
                  onClick={() => {
                    setView("new-project");
                  }}
                  type="primary"
                >
                  {board_type === "board" ? "+Add Board" : "+Add Template"}
                </Button>*/}
              </div>
            )}
            {activeKey === "archived" && (
              <div className="extra">
                <Button
                  className="btn-archived"
                  onClick={() => {
                    console.log(context);
                    context.setFilterDefaultValues({
                      ...context.filterDefaultValues,
                      $sort: {
                        createdAt: -1,
                      },
                      status: "archived",
                      board_type:
                        context.filterDefaultValues.board_type === "board"
                          ? "template"
                          : "board",
                    });
                  }}
                >
                  {context.filterDefaultValues.board_type === "board"
                    ? "See Archived Templates "
                    : "See Archived Boards "}
                </Button>
              </div>
            )}
            <div className="title-line">
              <SearchField
                allowClear
                placeholder="Search Boards"
                onSearch={handleSearch}
                defaultFilterValues={{
                  board_type,
                  status: "active",
                  company_id: user.company_id,
                  $or: [
                    {
                      user_id: user._id,
                    },
                    {
                      members_id: { $in: [user._id] },
                    },
                  ],
                }}
                source="boards"
              />
            </div>
          </Tools>
        )}
        <div className="section-list-board">
          <List
            style={{
              width: "100%",
            }}
            loading={loading}
            grid={{
              gutter: { xxl: 10, xl: 10, lg: 10, md: 8, xs: 8, sm: 8 },
              xs: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 6,
            }}
            dataSource={dataSource}
            renderItem={renderItem}
          />
        </div>
      </WrapperBoardList>
    );
};
const BoardList = ({
  mode = "default",
  type = "board",
  team_id,
  onSubmit,
  ...props
}) => {
  const theme = useSelector(({ theme }) => theme || {});
  const user = useSelector(({ user }) => user);
  const [dataSource, setDataSource] = useState(props.dataSource || []);
  const [teams, setTeams] = useState([]);
  const [show, setShow] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [board, setBoard] = useState();
  const [loading, setLoading] = useState(false);
  const [showBoard, setShowBoard] = useState(false);
  const [board_type, setBoardType] = useState("board");
  const [view, setView] = useState();
  const [show_new_board, setShowNewBoard] = useState(false);
  const [template, setTemplate] = useState({});
  const [record, setRecord] = useState();
  const [activeKey, setActiveKey] = useState("board");
  const [tabs, setTabs] = useState({});
  const [params, setParams] = useState({});
  const [favorite_boards_ids, setFavoriteBoardsIds] = useState();
  const [refresh, setRefresh] = useState(false);
  const onNew = (type, view) => {
    setView(view || type);
    //alert(`${board_type} ${type}`);
    //setBoardType(type);
    setShowNewBoard(true);
  };
  const handleOnClickItemMenu = (key, record) => {
    switch (key) {
      case "edit":
        if (record.board_type === "template") {
          setRecord(record);
          setView("board");
          setShowNewBoard(true);
        } else {
          navigate(`/dashboard/boards/${record._id}`);
        }
        break;
      default:
        break;
    }
  };
  const getTeams = () => {
    const service = getService("teams");
    let { company_id } = user;
    service
      .find({
        query: {
          $or: [
            {
              company_id,
            },
            {
              user_id: user._id,
            },
          ],
        },
      })
      .then(({ data }) => {
        setTeams(data);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onTeamSubmit = (type) => {
    getTeams();
    /*  getBoards({
      team_id,
    }); */
    setShow(false);
  };
  const handleMenuChange = (key, name) => {
    navigate(
      `${props.location.pathname}?${qs.stringify({
        [name]: key,
      })}`
    );
  };
  const handleSubmit = (err, data) => {
    if (err) return;
    if (data) {
      navigate(`/dashboard/boards/${data._id}`);
      /* getBoards({
        team_id,
      });
      setShowNewBoard(false); */
    }
  };
  const onTabChange = (board_type) => {
    board_type = board_type || activeKey;

    switch (board_type) {
      case "board":
        setBoardType("board");
        setFilterDefaultValues({
          ...filterDefaultValues,
          ...params,
          _id: { $nin: favorite_boards_ids },
          board_type,
          $sort: {
            createdAt: -1,
          },
          status: "active",
        });
        break;
      case "archived":
        setBoardType("board");
        setFilterDefaultValues({
          board_type: "board",
          $sort: {
            createdAt: -1,
          },
          status: "archived",
        });
        break;
      case "favorites":
        setBoardType("board");
        setFilterDefaultValues({
          ...params,
          board_type: "board",
          $sort: {
            createdAt: -1,
          },
          _id: { $in: favorite_boards_ids },
          status: "active",
        });
        break;
      default:
        setBoardType(board_type);
        setFilterDefaultValues({
          ...filterDefaultValues,
          board_type,
          $sort: {
            createdAt: -1,
          },
          status: "active",
        });
        break;
    }
  };
  const handleChangeType = (value) => {
    setBoardType(value ? "template" : "board");
    setFilterDefaultValues({
      ...filterDefaultValues,
      board_type: value ? "template" : "board",
    });
  };
  useEffect(() => {
    if (user) {
      setFavoriteBoardsIds(user.favorite_boards_ids || []);
    }
  }, [user]);
  useEffect(() => {
    if (
      favorite_boards_ids &&
      (activeKey === "board" || activeKey === "favorites")
    ) {
      let params = {};
      if (activeKey === "board")
        params = { _id: { $nin: favorite_boards_ids } };
      if (activeKey === "favorites")
        params = { _id: { $in: favorite_boards_ids } };
      setFilterDefaultValues({
        ...params,
        board_type: "board",
        $sort: {
          createdAt: -1,
        },
        status: "active",
      });

      // setMounted(true);
    }
  }, [favorite_boards_ids, activeKey]);

  useEffect(() => {
    if (props.location) {
      /* if (user) {
        if (team_id) {
          getBoards({
            ...filterDefaultValues,
            team_id
          });
        } else {
          let { search } = props.location;
          search = qs.parse(search.replace(/\?/, ""));
          console.log("Search:", search);
          getBoards({ ...filterDefaultValues, ...search, });
        }
      } */
    }
  }, [props.location, user]);
  useEffect(() => {
    if (user) getTeams();
  }, [props.search]);
  /*  useEffect(() => {
    if (typeof props.filterDefaultValues !== "undefined") {
      setFilterDefaultValues(props.filterDefaultValues);
    }
  }, [props.filterDefaultValues]); */

  return (
    <TabContext.Provider
      value={{
        activeKey,
        setTabs,
        tabs,
        setFilterDefaultValues,
        filterDefaultValues,
        favorite_boards_ids,
        setFavoriteBoardsIds,
        refresh,
        setRefresh,
      }}
    >
      <WrapperBoards background={theme.background}>
        <Row gutter={{ xl: 4, xxl: 16 }} type="flex" justify="start">
          {
            <Col span={24} className="container-tabs">
              <Tabs
                onChange={(board_type) => {
                  setActiveKey(board_type);
                  setTabs({
                    ...tabs,
                    [board_type]: true,
                  });
                  onTabChange(board_type);
                }}
                defaultActiveKey={board_type}
                activeKey={activeKey}
              >
                <TabPane tab={<span>Boards</span>} key="board">
                  {activeKey === "board" && (
                    <BoardLists
                      onNew={onNew}
                      board={board}
                      team_id={team_id}
                      board_type={board_type}
                      onClickItemMenu={handleOnClickItemMenu}
                      filterDefaultValues={filterDefaultValues}
                      name="board"
                    />
                  )}
                </TabPane>
                <TabPane tab={<span>Templates</span>} key="template">
                  {activeKey === "template" && (
                    <BoardLists
                      onNew={onNew}
                      board={board}
                      team_id={team_id}
                      board_type={board_type}
                      onClickItemMenu={handleOnClickItemMenu}
                      filterDefaultValues={filterDefaultValues}
                      name="template"
                    />
                  )}
                </TabPane>
                <TabPane tab={<span>Archived</span>} key="archived">
                  {activeKey === "archived" && (
                    <BoardLists
                      onNew={onNew}
                      board={board}
                      team_id={team_id}
                      board_type={board_type}
                      onClickItemMenu={handleOnClickItemMenu}
                      filterDefaultValues={filterDefaultValues}
                      name="archived"
                      addButton={false}
                    />
                  )}
                </TabPane>
                <TabPane
                  tab={
                    <span className="tab-title-board">
                      <Icon type="star" className="icon-favorite" /> Favorites
                    </span>
                  }
                  key="favorites"
                >
                  {activeKey === "favorites" &&
                    favorite_boards_ids.length > 0 && (
                      <BoardLists
                        onNew={onNew}
                        board={board}
                        team_id={team_id}
                        board_type={board_type}
                        onClickItemMenu={handleOnClickItemMenu}
                        filterDefaultValues={filterDefaultValues}
                        name="favorites"
                        addButton={false}
                      />
                    )}
                </TabPane>
              </Tabs>
            </Col>
          }
        </Row>
        {mode === "default" && (
          <MyModal
            title={"New Team"}
            onCancel={() => {
              setShow(false);
            }}
            visible={show}
            width={500}
          >
            <TeamForm onSubmit={onTeamSubmit} />
          </MyModal>
        )}
        {board && (
          <MyModal
            title={`Edit ${board.name}`}
            onCancel={() => {
              setShowBoard(false);
            }}
            visible={showBoard}
            width={500}
          >
            <CreateBoard
              id={board._id}
              // record={board}
              onSubmit={() => {
                setShowBoard(false);
              }}
            />
          </MyModal>
        )}
        {/* modal create board */}
        <MyModal
          title={
            board_type === "board"
              ? `${board_type.capitalize()}`
              : template.name
              ? `Project from ${template.name}`
              : "Project from Template"
          }
          onCancel={() => {
            setShowNewBoard(false);
            setRecord(null);
          }}
          visible={show_new_board}
          width={view === "board" ? "30%" : "92%"}
          height={view === "board" ? "700px" : "700px"}
        >
          {view === "board" ? (
            <CreateBoard
              id={board && board._id}
              record={record}
              board_type={(board && board.board_type) || board_type}
              onSubmit={handleSubmit}
            />
          ) : (
            <NewBoardForm
              board_type={board_type}
              record={record}
              view={view}
              onChangeTemplate={(template) => setTemplate(template)}
              onSubmit={handleSubmit}
              onCreateBlankTemplate={() => setView("board")}
              filterDefaultValues={{
                board_type: "template",
                status: "active",
                company_id: user && user.company_id,
                type: "all",
                $or: [
                  {
                    user_id: user._id,
                  },
                  {
                    members_ids: { $in: [user._id] },
                  },
                ],
              }}
            />
          )}
          {/* <BoardForm
          board_type={board_type}
          view={view}
          onChangeTemplate={(template) => setTemplate(template)}
          onSubmit={handleSubmit}
        /> */}
        </MyModal>
      </WrapperBoards>
    </TabContext.Provider>
  );
};
export default BoardList;
