import styled from "styled-components";

export const Wrapper = styled.div``;
export const BoardWrapper = styled.div`
  margin: 20px;
  width: 100vh !important;

  background: red !important;
`;
export const Tools = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-end"};
  align-items: center;
  padding: 6px 22px !important;

  & .ant-btn {
    margin: 0px 4px;
    display: flex !important;
    align-items: center !important;
  }
  & .switch-boards {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
  }
  & .switch-boards > * {
    margin: 0px 4px !important;
  }
  @media (max-width: 930px) {
    justify-content: flex-start !important;
  }
  @media (max-width: 770px) {
    display: block;
    & button {
      margin: 4px 0px !important;
      width: 100% !important;

      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
`;
