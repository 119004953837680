import React, { useEffect, useState, useRef } from "react";
import { WrapperForm, ListItemContainer } from "./Styles";
import { DinamicForm, SimpleForm, SearchField } from "../com/form/";
import { AvatarWrapper } from "../Styles";
import {
  Button,
  Row,
  Col,
  List,
  message,
  Card,
  Avatar,
  Input,
  Icon,
  Tooltip,
} from "antd";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
const { Item } = List;
const { Meta } = Card;
const WrapperItem = ({ input, children, ...props }) => {
  if (input) return <Form.Item {...props}>{children}</Form.Item>;
  return <AvatarWrapper {...props}>{children}</AvatarWrapper>;
};
const MemberList = (props) => {
  const [members, setMembers] = useState(props.members || []);
  useEffect(() => {
    setMembers(props.members);
  }, [props.members]);
  return (
    <WrapperItem xtype="fieldset" flex={1} {...props}>
      {members.map(({ _id, picture, email, first_name, last_name }) => (
        <div className="member-container">
          <Avatar
            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            src={`${URL_S3}/${picture}`}
          >
            {!picture ? first_name.substring(0, 1) : email.substring(0, 1)}
          </Avatar>
          <Tooltip title="Remove">
            <Button
              onClick={() => {
                if (props.form) {
                  props.onRemove(_id, props.form);
                }
              }}
              size="small"
              shape="circle"
              type="default"
              icon="close"
            />
          </Tooltip>
        </div>
      ))}
    </WrapperItem>
  );
};

const BoardForm = ({ board_type, ...props }) => {
  const [members_ids, setMemberIds] = useState([]);
  const [members, setMembers] = useState([]);
  const [template, setTemplate] = useState({});
  const user = useSelector(({ user }) => user);
  const onSubmit = (err, data) => {
    console.log(err, data);
    if (props.onSubmit) props.onSubmit();
  };

  const renderItem = (item, index) => {
    let { author } = item;
    return (
      <Item key={index}>
        <Card
          className={`${template._id == item._id ? "current-item" : ""}`}
          onClick={() => {
            setTemplate(item);
          }}
          hoverable
          size="small"
        >
          <Meta
            avatar={
              <Avatar
                src={
                  author.picture
                    ? `${URL_S3}/${author.picture}`
                    : URL_DEFAULT_AVATAR
                }
              />
            }
            title={item.name}
            description={
              <div>
                <div>
                  {author.first_name || ""} {author.last_name || ""}
                </div>
              </div>
            }
          />
        </Card>
      </Item>
    );
  };
  useEffect(() => {
    /* if (user)
            getData(); */
  }, [user]);
  if (!user) return null;
  return (
    <WrapperForm>
      <SimpleForm
        /* autoSubmit={false} */
        onChange={(e) => {
          console.log("CHANGE!", e);
        }}
        onSubmit={onSubmit}
        source="teams"
      >
        <Input type="hidden" name="company_id" initial={user.company_id} />
        <Input type="hidden" name="user_id" initial={user._id} />
        <Input
          size="large"
          flex={1}
          name="name"
          placeholder="Add team name"
          validations={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        />
        <MemberList
          onRemove={(_id, form) => {
            let new_members = members.filter((it) => it._id !== _id);
            let new_members_ids = new_members.map((it) => it._id);
            setMembers(new_members);
            setMemberIds(new_members_ids);
            form.setFieldsValue({
              members_ids: new_members_ids,
            });
          }}
          members={members}
        />
        <SearchField
          labelAlign={"left"}
          placeholder="Add members"
          name="member_id"
          source="get-all-users"
          flex={1}
          onSelectItem={(key, value, record, form) => {
            let new_values = [];
            let new_members = [];
            if (!members_ids.includes(value)) {
              new_values = [...members_ids, value];
              new_members = [...members, record];
            } else {
              new_values = members_ids.filter((it) => it !== value);
              new_members = members.filter((it) => it._id !== value);
            }
            if (form)
              form.setFieldsValue({
                members_ids: new_values,
                member_id: null,
              });
            setMembers(new_members);
            setMemberIds(new_values);
          }}
          defaultFilterValues={{
            company_id: user && user.company_id,
          }}
          optionText={(value, record) =>
            `${record.first_name || ""} ${record.last_name || ""} (${
              record.role
            })`
          }
        />
        <Input
          xtype="hiddenfield"
          flex={1}
          type="hidden"
          style={{
            height: 40,
            margin: 40,
          }}
          validations={[
            {
              required: true,
              message: "Members required",
            },
          ]}
          name="members_ids"
        />
      </SimpleForm>
    </WrapperForm>
  );
};
export default BoardForm;
