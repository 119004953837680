import styled from "styled-components";

export const WrapperPlanDetail = styled.div`
  min-height: 320px;
`;
export const WrapperPaymentForm = styled.div`
  & .stripe-field {
    .ant-form-item-control {
      background: #fff !important;
      padding: 0px 4px 0px 8px;
      border: 1px solid #ededed;
      border-radius: 8px;
      box-shadow: 4px 4px 4px #f7f7f7;
    }
    .ant-form-item-control:hover {
      background: #e5ecee !important;
    }
  }
  .ant-form-item input:hover {
    background: #e5ecee !important;
  }
  & .ant-form-item input {
    background: #fff !important;
    border: 1px solid #ededed !important;
    border-radius: 4px px !important;
    box-shadow: 4px 4px 4px #f7f7f7 !important;
  }

  & .card-details-label--stripe {
  }
  h3 {
    font-size: 16px !important;
    color: #000000d9 !important;
    margin-bottom: 0px !important;
  }
`;
export const Wrapper = styled.div`
  margin: 20px 10px;
  padding: 10px;
  & .ant-list-header {
    border-bottom: none !important;
    margin-bottom: 30px;
    text-align: center;
    color: var(--bg-gray-800);
    & .head-title {
    }
  }
  & .title-head {
    font-size: 18px !important;
  }
  & .ant-list-item {
    padding: 8px !important;
  }
  /* & .container-card {
    border-radius: 6px;
    padding: 18px;
    height: 280px;
    background: rgb(250, 173, 20) !important;
    color: rgb(255, 255, 255) !important;
  } */
`;
export const CardWrapper = styled.div`
  padding: 20px;
  border-radius: 12px;
  box-shadow: 3px 2px 3px #cccccc4f;
  background-color: #fff;
  background: #e7e8e96b;
  border: 1px solid #ebebef;
  height: 300px;
  width: 240px;

  &.current-plan {
    padding: 30px;
    background: linear-gradient(90deg,#021049,#1d37a2);
    color: #fff !important;
    height: 340px !important;
    width: 240px;
    border-radius: 16px !important;
    .plan {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      h4 {
        color: #fff !important;
      }
    }
    .plan-caption-name {
      text-transform: capitalize;
      h1 {
        color: #fff !important;
      }
    }
    .plan-description {
      height: 140px;
      margin-top: 11px;
    }
  }
  & > div {
    margin-bottom: 8px;
  }
  & .plan-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    .plan {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      h4 {
        margin: 0px !important;
        font-size: 16px !important;
      }
    }
    .plan-caption-name {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      h1 {
        font-weight: 600;
        margin: 0px !important;
      }
    }
  }
  & .plan-description {
    height: 110px;
    margin-top: 11px;
  }
  & .plan-price {
    font-size: 40px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .price-currency {
    }
    .price-amount {
    }
  }
  & .plan-action {
    .ant-btn-primary {
      background: #faad14 !important;
      color: #fff !important;
    }
  }
  & .plan-features {
  }

 /*  ${({ index, total }) =>
   index === 0
     ? `
    border-radius:16px!important;
    border-top-left-radius: 12px!important;
    border-bottom-left-radius: 12px!important;
    border-right: none!important;
    /* background:red; */
  `
     : index > 0 && index < total - 1
     ? `
      border-radius:0px!important;
      border-left: none!important;
      border-right: none!important;
      `
     : index === total - 1 &&
       `
        border-radius:0px!important;
        border-top-right-radius: 12px!important;
        border-bottom-right-radius: 12px!important;
        border-left: 0px!important;
      `} */
`;
