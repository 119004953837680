import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import uuid from "react-uuid";
import Column from "./Column";
import {
  BoardWrapper,
  Tools,
  TabPanelWrapper,
  BoardContainer,
  AddStageWrapper,
  WrapperFilter,
  WrapperFilterContainer,
  WrapperMemberBoard,
} from "./Styles";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../store/actions";
import { getService } from "../../services/";
import { MyModal, ColorPicker } from "../com";
import { Link, navigate } from "@reach/router";
import TaskForm from "./TaskForm";
import ColumnForm from "./ColumnForm";
import Title from "./TitleBoard";
import slugify from "react-slugify";
import { FiFilter } from "react-icons/fi";
import { SearchField, SimpleForm } from "../com/form/";
import {
  message,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Icon,
  Modal,
  Input,
  Empty,
  Tabs,
  Drawer,
  Popover,
  Skeleton,
  List,
  Checkbox,
  Avatar,
} from "antd";
import MyCalendar from "../dashboard/widgets/Calendar";
/* import MyCalendar from "../../components/com/calendar/Calendar"; */
import qs from "qs";
import { BoardMembers } from ".";
import { BoardContext } from "./context";
import { MainContext } from "../../context";
import { useContext } from "react";
import NewBoardForm from "./NewBoardForm";
import AddMembers from "./AddMembers";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
import TagTasks from "./TagTasks";
import AddMemberLists from "../add-members-list/AddMemberLists";
import { IoArchiveOutline } from "react-icons/io5";
import { FaRegCheckSquare } from "react-icons/fa";
const data = [
  {
    id: 1,
    title: "New",
    background: "#7EE2B8",
  },
  {
    id: 2,
    title: "close",
    background: "#F5CD47",
  },
  {
    id: 3,
    title: "asigned",
    background: "#F87168",
  },
  {
    id: 4,
    title: "pending",
    background: "#C9372C",
  },
  {
    id: 5,
    title: "Good",
    background: "#9F8FEF",
  },
];
const service = getService("boards");
const grid = 8;
const { confirm } = Modal;
const { TabPane } = Tabs;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

class InnerColumnList extends React.PureComponent {
  render() {
    let { column, columns, column_order, taskMap, index } = this.props;
    const tasks = column.taskIds.map((taskId) => taskMap[taskId]);
    return (
      <Column
        direction={this.props.direction || "vertical"}
        key={column.id}
        index={index}
        tasks={tasks}
        column={column}
        columns={columns}
        column_order={column_order}
        board_id={this.props.board_id}
        onSubmit={this.props.onSubmit}
        onMenuClick={this.props.onMenuClick}
        isDropDisabled={this.props.isDropDisabled}
      />
    );
  }
}
const AddStage = ({ background, isDragging, ...props }) => {
  let [view, setView] = useState("default");
  let [value, setValue] = useState();
  let [submitting, setSubmitting] = useState(false);
  const handleOnChange = (e) => {
    let { value } = e.target;
    setValue(value);
  };
  const onSubmit = async () => {
    try {
      setSubmitting(true);
      if (props.onSubmit) await props.onSubmit(value);
      setValue(null);
      setView("default");
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    console.log("---> isDragging: ", isDragging);
  }, [isDragging]);
  if (isDragging) return null;
  return (
    <AddStageWrapper background={background}>
      {view === "default" && (
        <Button
          className="button-stage"
          loading={submitting}
          onClick={() => setView("add")}
          type="link"
          block
          icon="plus"
        >
          {!submitting ? "Add Stage" : "Submitting"}
        </Button>
      )}
      {view === "add" && (
        <>
          <Input
            value={value}
            size="large"
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                onSubmit();
              }
            }}
            onBlur={() => {
              setView("default");
            }}
            onChange={handleOnChange}
            onPressEnter={() => {
              onSubmit();
            }}
            name="value"
            placeholder="New Stage"
          />
        </>
      )}
    </AddStageWrapper>
  );
};
const MenuFilter = ({ board, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [user, setUser] = useState({});
  const onChange = () => {};
  const handleSearch = (search) => {
    let params = {};
    if (search && search !== null) params["search"] = search;
    if (user.company_id) params["company_id"] = user.company_id;
    setFilterDefaultValues({
      ...params,
    });
  };
  const onSubmit = () => {
    // alert();
  };
  useEffect(() => {
    if (props.user && typeof props.user.company_id)
      setFilterDefaultValues({
        company_id: props.user.company_id,
      });
  }, [props.user]);
  useEffect(() => {
    if (props.user) setUser(props.user);
  }, [props.user]);
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  return (
    <WrapperFilterContainer>
      <div className="container">
        <div className="container-autocomplete">
          <SearchField
            allowClear
            placeholder="Search..."
            onSearch={handleSearch}
            source="task-tags"
          />
        </div>
        <div className="section-member">
          <div className="members-title">
            <span>Members</span>
          </div>
          <div className="container-section-list">
            <AddMembers
              search={false}
              task={board}
              user={user}
              height={200}
              checkView={true}
              onSubmit={onSubmit}
              filterDefaultValues={{
                ...filterDefaultValues,
              }}
            />
          </div>
        </div>
        <div className="section-list-tags">
          <div className="tags-title">
            <span>Tags</span>
          </div>
          <TagTasks
            task={board}
            user={user}
            searchVisible={false}
            button_new_tag={false}
            filterDefaultValues={{
              ...filterDefaultValues,
            }}
          />
        </div>
      </div>
    </WrapperFilterContainer>
  );
};
const Board = ({ id, board, board_type, ...props }) => {
  let { name } = board;
  let myRef = useRef(null);
  const task = useSelector(({ task }) => task);
  const theme = useSelector(({ theme }) => theme || {});
  const user = useSelector(({ user }) => user);
  const collapsedMenu = useSelector(({ collapsedMenu }) => collapsedMenu);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(props.columns || []);
  const [column_order, setColumnOrder] = useState(props.column_order || []);
  const [tasks, setTasks] = useState(props.tasks || []);
  const [current_task, setCurrentTask] = useState(null);
  const [view, setView] = useState("board");
  const [homeIndex, setHomeIndex] = useState(props.homeIndex || 1);
  const [opacity, setOpacity] = useState(1);
  const [isDragging, setDragging] = useState(false);
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [show_new_board, setShowNewBoard] = useState(false);
  const [template, setTemplate] = useState({});
  const [open, setOpen] = useState(false);

  const { changed, setChanged, setWithOutSave } = useContext(MainContext);
  const onDragEnd = (result) => {
    let { destination, source, draggableId, type } = result;
    setDragging(false);
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    if (type === "column") {
      const newColumnOrder = Array.from(column_order);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      if (props.onDragEnd) props.onDragEnd(newColumnOrder, type);

      sortColumns(newColumnOrder);
      return setColumnOrder(newColumnOrder);
    }
    let task = Object.values(tasks);
    task = task.find((it) => it.id === draggableId);
    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (start === finish) {
      let newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      if (props.onDragEnd)
        props.onDragEnd(task, type, {
          ...columns,
          [newColumn.id]: newColumn,
        });
      return setColumns({
        ...columns,
        [newColumn.id]: newColumn,
      });
    }
    //Mover a otra columna
    let startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);

    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    let finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };
    setColumns({
      ...columns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    });

    /* task = Object.values(tasks);
    task = task.find(it => (it.id == draggableId)) */

    if (type === "task" && task.column_id !== newFinish.id) {
      task["column_id"] = newFinish.id;
      /* alert(`Task: ${JSON.stringify(task)}`); */
      if (task && task._id) {
        saveTask(task);
      }
      /* dispatch({
        type: "ADD_TASK",
        task: task
      }); */
    }
    if (props.onDragEnd)
      props.onDragEnd(task, type, {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      });
  };
  const onDragStart = () => {
    setDragging(true);
  };
  const onSubmit = (payloads) => {
    if (props.onSubmit) {
      props.onSubmit(payloads);
    }
  };
  const onDragUpdate = (update) => {
    const { destination } = update;
    if (!destination) return;
    let destination_tasks = tasks.filter(
      (it) => it.current_list === destination.droppableId
    );
    const opacity = destination
      ? destination.index / destination_tasks.length
      : 0;
    console.log("update: ", destination, destination_tasks);
    setOpacity(opacity);
  };
  const saveTask = async (payloads) => {
    return new Promise(async (resolve, reject) => {
      const service = getService("tasks");
      try {
        /* Crear y Actualiar Task */
        let task;
        if (!payloads._id) {
          task = await service.create(payloads);
          setTasks({
            ...tasks,
            [task._id]: {
              id: task._id,
              ...task,
            },
          });
          let column = columns[payloads.column_id];
          column["taskIds"] = column["taskIds"] || [];
          column["taskIds"].push(task._id);
          setColumns({
            ...columns,
            [payloads.column_id]: column,
          });
          message.success(`Task created successfully!`);
        } else {
          task = await service.patch(payloads._id, payloads);
          message.success(`Task updated successfully!`);
        }
        resolve(task);
        setCurrentTask(task);
        dispatch({
          type: "ADD_TASK",
          task: null,
        });
      } catch (err) {
        console.log("ERROR:", err);
        message.error(err.message);
        reject(err);
      }
    });
  };
  const onRemove = async (_id) => {
    const service = getService("tasks");
    try {
      if (_id) {
        setSubmitting(true);
        await service.remove(_id);
        setSubmitting(false);
        onSubmit();
      }
    } catch (err) {
      setSubmitting(false);
      message.error(err.message);
    }
  };
  const removeTask = async (_id) => {
    confirm({
      content: (
        <div>
          This file will be permanently removed if you delete it. Please confirm
          if you wish to proceed.
        </div>
      ),
      onOk() {
        if (_id) onRemove(_id);
      },
    });
  };
  const scroll = () => {
    let scrollOffset = 280;
    let el = window.document.getElementById("droppable");
    if (el) el.scrollLeft += scrollOffset;
  };
  const onSubmitColumn = (title) => {
    // return console.log("title", title);
    const service = getService("boards");
    let slug = slugify(title);
    let new_column_order = [...column_order, slug];
    let newColumns = {
      ...columns,
      [slug]: {
        id: slug,
        title,
        taskIds: [],
      },
    };
    return new Promise((resolve, reject) => {
      setSubmitting(true);
      service
        .patch(id, {
          column_order: new_column_order,
          columns: Object.values(newColumns),
        })
        .then((response) => {
          setColumnOrder(new_column_order);
          setColumns(newColumns);
          scroll();
          resolve();
          setSubmitting(false);
          setShow(false);
          message.success("Column Added!");
        })
        .catch((err) => {
          setSubmitting(false);
          message.error(err.message);
          reject(err);
        });
    });
  };
  const sortColumns = (new_column_order) => {
    service
      .patch(id, {
        column_order: new_column_order,
      })
      .then((response) => {
        setColumnOrder(new_column_order);
        message.success("Column sorted!");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const removeColumns = (column_id) => {
    let new_column_order = column_order.filter((col) => col !== column_id);
    let newColumns = {
      ...columns,
    };
    delete newColumns[column_id];
    //return console.log(new_column_order, newColumns);
    setSubmitting(true);
    service
      .patch(id, {
        column_order: new_column_order,
        columns: Object.values(newColumns),
      })
      .then((response) => {
        setColumns(newColumns);
        setColumnOrder(new_column_order);
        message.success("Record Removed.");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        message.error(err.message);
      });
  };
  const statusBoard = (status) => {
    service
      .patch(id, {
        status,
      })
      .then((response) => {
        message.success("Projec Updated");
        navigate(`/dashboard/boards`);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const remove = (id) => {
    service
      .remove(id)
      .then((resp) => {
        navigate(`/dashboard/boards`);
        message.success("Record removed!");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const handleMenuClick = (key, column_id) => {
    switch (key) {
      case "edit":
        if (board.board_type === "template") {
          setShowNewBoard(true);
        }
        break;
      case "add":
        setShow(true);
        break;
      case "archived":
        confirm({
          // title: `Do you want to archive ${column_id ? "section" : "board"}?`,
          content: (
            <div className="flex justify-center align-center">
              Are you sure you want to archive <br />{" "}
              {`${column_id ? "section" : "board"}`}?
            </div>
          ),
          onOk() {
            if (column_id) {
              return removeColumns(column_id);
            }
            statusBoard(key);
          },
          onCancel() {},
        });
        break;
      case "active":
        confirm({
          content: (
            <div className="flex justify-center align-center">
              Are you sure you want to activate <br /> this board?
            </div>
          ),
          onOk() {
            statusBoard(key);
          },
          onCancel() {},
        });
        break;
      case "delete":
        confirm({
          content: (
            <div>
              This file will be permanently removed if you delete it. Please
              confirm if you wish to proceed.
            </div>
          ),
          onOk() {
            if (board) remove(board._id);
          },
        });
        break;
      case "members":
        setView("members");
        break;
      default:
        break;
    }
  };
  const updateName = (name, value) => {
    setSubmitting(true);
    service
      .patch(id, {
        name: value,
      })
      .then((response) => {
        message.success("Project Name Updated!");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        message.error(err.message);
      });
  };
  const save = (params) => {
    setSubmitting(true);
    service
      .patch(id, params)
      .then((response) => {
        dispatch({
          type: actionTypes.COLOR_THEME,
          theme: {
            background: response.background,
          },
        });
        setSubmitting(false);
        message.success("Project Updated!");
      })
      .catch((err) => {
        setSubmitting(false);
        message.error(err.message);
      });
  };
  const handleColor = (background) => {
    save({
      background,
    });
  };

  const MenuItems = (props) => (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      {board.board_type === "template" && (
        <Menu.Item key="edit">
          <span>
            <Icon type="edit" className="icon-global" /> <span>Edit</span>
          </span>
        </Menu.Item>
      )}
      {board.status === "archived" && (
        <Menu.Item key="active">
          <div>
            <span className="section-menu-archive">
              <FaRegCheckSquare className="icon-global" />
              Re-Activate
            </span>
          </div>
        </Menu.Item>
      )}
      {board.status !== "archived" && (
        <Menu.Item key="archived">
          <div>
            <span className="section-menu-archive">
              <IoArchiveOutline className="icon-global" /> Archive
            </span>
          </div>
        </Menu.Item>
      )}
      {board.status === "archived" && (
        <Menu.Item key="delete">
          <div>
            <span className="section-menu-archive">
              <Icon type="delete" className="btn-removed-red" /> Delete
            </span>
          </div>
        </Menu.Item>
      )}
      <Menu.Item
        className="item-vertical"
        disabled
        style={{
          background: "#FFF",
          cursor: "pointer",
        }}
      >
        <ColorPicker onChange={handleColor} />
      </Menu.Item>
    </Menu>
  );
  const handleSubmit = (err, data) => {
    if (err) return;
    if (data) {
      setShowNewBoard(false);
      if (props.onSubmit) props.onSubmit();
      // navigate(`/dashboard/boards/${data._id}`);
      /* getBoards({
        team_id,
      });
      */
    }
  };
  const onCloseTask = () => {};
  const preventClose = (e) => {
    /*    if (changed) {
      setWithOutSave(true);
      if (e) e.preventDefault();
      return setError("The changes you made may not be able to be saved.");
    } */
    navigate(`/dashboard/boards/${id}`);
  };
  useEffect(() => {
    if (task && !task._id) {
      let new_task = {
        ...task,
        board_id: id,
      };
      saveTask(new_task);
    }
  }, [task]);
  useEffect(() => {
    if (!initialized) {
      if (!collapsedMenu) dispatch({ type: actionTypes.COLLAPSE_MENU });
      dispatch({
        type: actionTypes.COLOR_THEME,
        theme: {
          color: board.color,
          background: board.background,
        },
      });
      setInitialized(true);
    }
    /* if (theme) alert("OK!" + JSON.stringify(theme)); */
  }, [theme]);
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.COLOR_THEME,
        theme: {},
      });
    };
  }, []);
  useEffect(() => {
    if (props.location && props.location.search) {
      let search = qs.parse(props.location.search.replace("?", ""));
      let { view } = search;
      setView(view);
    }
  }, [props.location]);
  useEffect(() => {
    if (typeof props.loading != "undefined") setLoading(props.loading);
  }, [props.loading]);
  if (!initialized) return null;
  return (
    <BoardContext.Provider
      value={{
        saveTask,
        current_task,
        setCurrentTask,
        removeTask,
        onSubmit,
        open,
        setOpen,
      }}
    >
      <BoardContainer
        type="flex"
        align={"middle"}
        background={theme.background} /* {...theme} */
      >
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 4,
          }}
        >
          <Title
            multiple={false}
            loading={submitting}
            onSubmit={updateName}
            {...board}
          />
          {board.property && (
            <Link
              className="property-link"
              to={`/dashboard/mylistings/${board.property._id}`}
            >{`${board.property.Address} ${board.property.ListingId}`}</Link>
          )}

          <Tools className="toolbar mb-0" /* {...theme} */>
            <Dropdown
              overlay={MenuItems}
              overlayClassName="menu-actions menu-vertical"
            >
              <Button
                className="button-personalize "
                type={
                  theme && theme.background !== "transparent"
                    ? "link"
                    : "primary"
                }
                icon="setting"
              >
                Palette
              </Button>
            </Dropdown>
          </Tools>
        </Col>
        <Col span={24}>
          {/* por aqui quede para cambiar el color del board */}
          <TabPanelWrapper background={theme.background}>
            <Tabs
              size="small"
              onChange={(view) => {
                setView(view);
              }}
              defaultActiveKey={view}
            >
              <TabPane tab={<span>Members</span>} key="members">
                {/* <BoardMembers id={id} board={board} /> */}
                <AddMemberLists
                  id={id}
                  board={board}
                  reference="boards"
                  title="Invite Members to Board"
                />
              </TabPane>
              <TabPane tab={<span>Board</span>} key="board">
                {column_order.length > 0 ? (
                  <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                  >
                    <Droppable
                      droppableId="all-columns"
                      direction="horizontal"
                      type="column"
                    >
                      {(provided) => (
                        <BoardWrapper
                          background={theme.background}
                          id="droppable"
                          opacity={opacity}
                          isDragging={isDragging}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {column_order.map((columnId, index) => {
                            const column = columns[columnId];
                            if (!column) return;
                            return (
                              <InnerColumnList
                                direction={props.direction || "vertical"}
                                key={column.id}
                                board_id={id}
                                index={index}
                                taskMap={tasks}
                                column={column}
                                columns={columns}
                                column_order={column_order}
                                onSubmit={onSubmit}
                                onMenuClick={handleMenuClick}
                                isDropDisabled={false}
                              />
                            );
                          })}
                          {!isDragging && (
                            <div className="add-column">
                              <AddStage
                                background={theme.background}
                                {...provided.droppableProps}
                                onSubmit={onSubmitColumn}
                              />
                            </div>
                          )}
                          {provided.placeholder}
                        </BoardWrapper>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <Col span={24}>
                    <Row
                      style={{
                        minHeight: "80vh",
                        padding: "20px",
                        margin: "12px",
                      }}
                      type="flex"
                      justify="center"
                      align="top"
                    >
                      <Col>
                        <Empty
                          image="/images/board.svg"
                          imageStyle={{
                            height: 180,
                          }}
                          description={
                            <div className="description-board-empty">
                              <span>Customize your Workspace.</span>
                            </div>
                          }
                        >
                          <Button
                            className="button-start-now"
                            onClick={() => handleMenuClick("add")}
                            size="large"
                            icon="plus"
                          >
                            Start Now
                          </Button>
                        </Empty>
                      </Col>
                    </Row>
                  </Col>
                )}
              </TabPane>
              <TabPane tab={<span>Calendar</span>} key="calendar">
                <MyCalendar
                  style={{
                    width: "100%",
                  }}
                  align="middle"
                  filterDefaultValues={{
                    board_id: id,
                  }}
                />
              </TabPane>
            </Tabs>
          </TabPanelWrapper>
        </Col>
        <Drawer
          className="drawer-fit"
          width={724}
          closable={true}
          destroyOnClose
          onClose={preventClose}
          visible={typeof props.task_id != "undefined"}
          // visible={open}
        >
          {props.task_id && (
            <TaskForm
              onItemActive={(key) => setActive(key == null)}
              id={props.task_id}
              closable
              onClose={preventClose}
              // onCloseTask={onCloseTask}
              {...props}
            />
          )}
        </Drawer>

        <MyModal
          title={"New Stages"}
          onCancel={() => setShow(false)}
          visible={show}
          width={500}
        >
          <ColumnForm onSubmit={onSubmitColumn} />
        </MyModal>
        <MyModal
          title={board && board.name.capitalize()}
          visible={show_new_board}
          onCancel={() => {
            setShowNewBoard(false);
          }}
          width={"30%"}
        >
          <NewBoardForm
            board_type={board.board_type}
            record={board}
            view={view}
            onChangeTemplate={(template) => setTemplate(template)}
            onSubmit={handleSubmit}
          />
        </MyModal>
      </BoardContainer>
    </BoardContext.Provider>
  );
};
export default Board;
