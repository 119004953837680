import React, { useEffect, useState } from "react";
import { WrapperCardProperties, WrapperProperties } from "./Styles";
import { Link, navigate } from "@reach/router";
import { Row, Col, Avatar, Card, List, Button, message, Tag, Icon } from "antd";
import { getService } from "../../services/services";
import {
  DEFAULT_IMAGE,
  GOONEWORLD_SITE,
  URL_PLACEHOLDER,
  URL_S3,
} from "../../constants/";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { money } from "../../utils/Helper";
import moment from "moment";
import { Empty } from "../com/loader/";
import SkeletonList from "./SkeletonList";
const CardProperties = ({
  t,
  item,
  reference,
  actions = {},
  allowSelect = true,
  extra,
  refinement,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const handlelike = () => {};
  const getData = () => {
    if (reference) {
      const service = getService(reference);
      setLoading(true);
      service
        .find({
          query: {
            $limit: 3,
            ...filterDefaultValues,
          },
        })
        .then(({ data }) => {
          setDataSource(data);
          setMounted(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };
  const defaultRenderItem = (record, index) => {
    let item = record;
    if (refinement) item = refinement(record);
    // let media =
    // item && item.Media && item.Media.length > 0 ? item.Media[0].url : null;
    let media = item && item.Media.map((it, index) => it.url);
    let primaryphoto = media.find((url) =>
      url.includes(item.ListingKeyNumeric)
    );
    console.log("primary", primaryphoto);
    // console.log("media", media);
    return (
      <WrapperCardProperties className="item-list">
        <List.Item.Meta
          avatar={
            <Avatar
              shape="square"
              size="large"
              src={primaryphoto}
              // src={`${URL_S3}/${item && item.ListingKeyNumeric}/0.jpg`}
            />
          }
          title={
            <div className="head-listing">
              <div className="head-title">
                <div className="address">
                  <h3>{item && item.UnparsedAddress}</h3>
                </div>
                <div className="price">
                  {item.ListPrice && <h2>{money(item.ListPrice)}</h2>}
                </div>
              </div>
              <div className="head-features">
                <div className="beds">
                  <span>
                    {item && item.BedroomsTotal} bd *{" "}
                    {item && item.BathroomsFull} ba
                  </span>
                </div>
                {/* {item.BedroomsTotal && (
                  <div className="beds">
                    <span>{item.BedroomsTotal}bd-</span>
                  </div>
                )}
                {item.BathroomsFull && (
                  <div className="baths">
                    <span>{item.BathroomsFull}ba</span>
                  </div>
                )} */}
                <div>
                  {item.LivingArea && (
                    <span className="area">
                      * {item.LivingArea} ft<sup>2</sup>
                    </span>
                  )}
                </div>
              </div>
              <div className="section-type">
                <span className="for-type">
                  {item.PropertyType == "Residential" ? "Sale" : "Rent"}
                </span>
                <span
                  className={`status ${item &&
                    item.StandardStatus &&
                    item.StandardStatus.toLowerCase()}`}
                >
                  {item &&
                    item.StandardStatus &&
                    item.StandardStatus.toLowerCase()}
                </span>
              </div>
            </div>
          }
          description={
            <div className="description">
              <div className="head-description">
                {item.OriginalEntryTimestamp && (
                  <div className="date">
                    {moment(item.OriginalEntryTimestamp).format(
                      "MM-DD-YYYY LT"
                    )}
                  </div>
                )}
                {item.City && (
                  <div className="detail">
                    {/* listings/details/Aventura/FL/Sale/2851-NE-183rd-St-2201E-Aventura-FL-33160/516598905 */}
                    <a
                      target="_blank"
                      href={`${GOONEWORLD_SITE}/detail/${item._id}`}
                    >
                      Details
                      <Icon type="arrow-right" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          }
        />
      </WrapperCardProperties>
    );
  };
  /*  useEffect(() => {
    if (props.dataSource)
      setDataSource(
        [
          ...props.dataSource,
          actions.create && {
            type: "empty",
          },
        ].filter((it) => typeof it != "undefined")
      );
  }, [props.dataSource]); */
  useEffect(() => {
    if (!reference) setLoading(props.loading);
  }, [props.loading]);
  useEffect(() => {
    if (filterDefaultValues) {
      console.log(
        "filterDefaultValues::::::::::::::::::::::::::",
        filterDefaultValues
      );
      getData();
    }
  }, [filterDefaultValues]);
  useEffect(() => {
    setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (props.refresh !== refresh) {
      setRefresh(props.refresh);
      getData();
    }
  }, [props.refresh]);
  if (loading)
    return <SkeletonList loading={loading} title={false} avatar count={3} />;
  return (
    <WrapperProperties>
      <Empty image="/images/sweet_home.svg">
        <List
          grid={{
            gutter: [8, 8],
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
          }}
          loading={loading}
          dataSource={dataSource}
          renderItem={props.renderItem || defaultRenderItem}
        />
      </Empty>
    </WrapperProperties>
  );
};
export default CardProperties;
