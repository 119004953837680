import React, { useState, useEffect } from "react";
import { message } from "antd";
import { URL_S3 } from "../../../constants";
import { IoMdDownload } from "react-icons/io";
import styled from "styled-components";
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .section-message {
    .container-imagen {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px;
      img {
        width: 170px !important;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .icon-message {
        font-size: 18px !important;
      }
      h3 {
        font-size: 14px !important;
        margin-bottom: 0px !important;
      }
    }
  }
`;
const Pdf = ({ viewer, path, name, type, ...props }) => {
  return (
    <>
      {type === "pdf" ? (
        <object
          data={`${URL_S3}/${path}`}
          type="application/pdf"
          width="80%"
          height="540px"
        />
      ) : (
        <Wrapper>
          <div className="flex justify-center align-center">
            <div className="section-message">
              <div className="container-imagen">
                <img src={"/images/download.svg"} />
              </div>
              <div className="title">
                <IoMdDownload className="icon-message" />
                <h3>Please download to see the document</h3>
              </div>
            </div>
          </div>
        </Wrapper>
      )}

      {/* <iframe src={`https://docs.google.com/gview?url=${URL_S3}/${path}&embedded=true`}
            style={{
                width: "80%",
                height: 600
            }}
            frameborder="0" /> */}
    </>
  );
};
export default Pdf;
