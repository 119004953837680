import React, { useState, useEffect } from "react";
import { List, Avatar, Button, Icon, Tooltip } from "antd";
import moment from "moment";
import { URL_DEFAULT_AVATAR, URL_S3, extensions } from "../../constants";
import { Tools, ItemWrapper, WrapperDocuments } from "./Styles";
import { Modal } from "../";
import FileViewer from "../fileviewer/FileViewer";
import { isMobile } from "react-device-detect";
import { MdDeleteOutline } from "react-icons/md";
import { RiDownload2Fill } from "react-icons/ri";
const { Item } = List;
const { Meta } = Item;

const DocumentItem = ({ onRemove, actions, extra, record = {}, ...props }) => {
  let { _id, name, path, createdAt, updatedAt, author = {} } = record;
  const [documents, setDocuments] = useState(props.documents || []);
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(props.index || 0);

  const handlePreview = () => {
    //if (extensions.includes(type))
    if (!isMobile) setVisible(true);
  };
  const ItemDocument = ({ record }) => {
    if (!record) return null;
    let { _id, name, path, createdAt, author = {} } = record;
    if (!path) return null;
    const type = path.substring(path.lastIndexOf(".") + 1, path.length);
    return (
      <WrapperDocuments>
        <Item
          style={{
            display: "block",
          }}
        >
          <Meta
            avatar={
              <Avatar
                className="avatar-view"
                onClick={handlePreview}
                shape="square"
                size="large"
                src={extensions.includes(type) && `${URL_S3}/${path}`}
              >
                {!extensions.includes(type) && <h3>{type}</h3>}
                <span className="quick-preview">Quick View</span>
              </Avatar>
            }
            title={
              <div className="section-title-document">
                <div>
                  <span>{name}</span>
                </div>
                <div className="section-buttons-actions">
                  {actions && actions.download && (
                    <Tooltip placement="top" title={"Download"}>
                      <Button type="link">
                        <a href={`${URL_S3}/${path}`} target="_blank">
                          <RiDownload2Fill className="icon-footer" />
                        </a>
                      </Button>
                    </Tooltip>
                  )}
                  {actions && actions.delete && (
                    <Tooltip placement="top" title={"Remove"}>
                      <Button
                        onClick={() => {
                          if (onRemove) onRemove(_id);
                        }}
                        type="link"
                      >
                        <MdDeleteOutline className="btn-removed-red" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            }
            description={
              <>
                <Tools>
                  <div className="date-upload">
                    <span>
                      {!updatedAt
                        ? `Added: ${moment(createdAt).format("MM-DD-YYYY LT")}`
                        : `Uploaded: ${moment(updatedAt).format(
                            "MM-DD-YYYY LT"
                          )}`}
                    </span>
                  </div>
                </Tools>
                <Tools
                  style={{
                    margin: 0,
                  }}
                >
                  {typeof extra == "object" &&
                    Array.isArray(extra) &&
                    extra.map((item) => {
                      if (item.render)
                        return React.cloneElement(item.render(record), {
                          onClick: () => {
                            if (item.onClick) item.onClick(record);
                          },
                        });
                      return (
                        <Button
                          onClick={() => {
                            if (item.onClick) item.onClick(record);
                          }}
                          type={"link"}
                        >
                          {item.type == "link" ? (
                            <a href={`${URL_S3}/${path}`} target="_blank">
                              <span>
                                <RiDownload2Fill /> Download
                              </span>
                            </a>
                          ) : (
                            <span>
                              {item.icon && <Icon type={item.icon} />}{" "}
                              {item.title}
                            </span>
                          )}
                        </Button>
                      );
                    })}
                </Tools>
              </>
            }
          />
        </Item>
      </WrapperDocuments>
    );
  };
  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);
  useEffect(() => {
    setIndex(index);
  }, [props.index]);
  return (
    <ItemWrapper>
      <ItemDocument record={record} />
      <Modal
        // transparent
        width={"80%"}
        style={{ top: 20 }}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={
          <>
            <>
              <ItemDocument record={documents[index]} {...documents[index]} />
            </>
          </>
        }
      >
        <FileViewer
          onChange={(index) => setIndex(index)}
          index={index}
          actions={actions}
          dataSource={documents}
        />
      </Modal>
    </ItemWrapper>
  );
};
export default DocumentItem;
