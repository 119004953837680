import React, { useState, useEffect } from "react";
import { Button, message, Select, Spin, Avatar, Tooltip, Icon } from "antd";
import { SelectGroupWrapper } from "./Styles";
import { getService } from "../../../services";
import _ from "lodash";
import { MdOutlinePersonAddAlt1 } from "react-icons/md";
import { URL_S3 } from "../../../constants";
const { Option } = Select;

const SelectGroupField = ({
  id,
  renderItem,
  renderOptions,
  reference,
  source,
  title,
  user,
  icon,
  onChange,
  name,
  maxItems = 4,
  autoLoad = false,
  optionValue = "_id",
  optionText = "name",
  onSubmit,
  ...props
}) => {
  const [record, setRecord] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [selectes, setSelectes] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [open, setOpen] = useState(false);
  const [editting, setEditting] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState({});
  const onUpdate = (members_ids) => {
    const service = getService("tasks");
    service
      .patch(record && record._id, {
        members_ids,
      })
      .then((resp) => {
        message.success("Members Updated Successfully!");
        if (onSubmit) onSubmit(resp);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const handleOnRemove = (item) => {
    let members_ids = selectes;
    let params = { company_id: user.company_id };
    if (!members_ids.includes(item._id)) {
      members_ids = [...members_ids, item._id];
    } else {
      members_ids = members_ids.filter((_id) => _id !== item._id);
    }
    params = {
      _id: {
        $in: [...members_ids],
      },
    };
    setSelectes(members_ids);
    onUpdate(members_ids);
  };
  const getData = async () => {
    if (reference) {
      try {
        const service = getService(reference);
        setLoading(true);
        setOpen(false);
        let { data } = await service.find({
          query: {
            ...filterDefaultValues,
          },
        });
        setDataSource(data);
        setLoading(false);
        if (!initialized) setInitialized(true);
      } catch (err) {
        setLoading(false);
        setInitialized(false);
        message.error(err.message);
      }
    }
  };
  const defaultRenderItem = (id, index) => {
    let item = dataSource.find(({ _id }) => id === _id);
    if (!item) return null;
    let { first_name, email, last_name, picture } = item;
    return (
      <div className="item-conatiner">
        <Avatar
          onClick={() => setEditting(true)}
          style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          src={`${URL_S3}/${picture}`}
          size={"large"}
        >
          {!picture
            ? `${first_name.substring(0, 1)}${last_name.substring(0, 1)}`
            : email.substring(0, 1)}
        </Avatar>
        <div className="item-remove">
          <Button
            onClick={() => handleOnRemove(item)}
            type="link"
            icon="close"
          />
        </div>
      </div>
    );
  };
  const defaultRenderOptions = (item, index) => {
    if (!item) return null;
    let { picture, first_name, last_name, email } = item;
    return (
      <Option value={item[optionValue]}>
        <div className="option-item">
          <Avatar
            onClick={() => setEditting(true)}
            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            src={`${URL_S3}/${picture}`}
            size={"small"}
          >
            {!picture
              ? first_name && first_name.substring(0, 1)
              : email && email.substring(0, 1)}
          </Avatar>
          <span>
            {first_name || ""} {last_name || ""}
          </span>
        </div>
      </Option>
    );
  };
  const handleChange = (value) => {
    setSelectes(value);
    if (onChange) onChange(value);
    console.log("onchange-Selectes: ", value);
  };
  const handleOnSelect = (value) => {
    if (selectes && selectes.includes(value)) {
      // let new_selectes = [];
      let new_selectes = selectes.filter((_id) => _id !== value);
      setSelectes(new_selectes);
      if (onChange) onChange(new_selectes);
    } else if (selectes) {
      let new_selectes = [...selectes, value];
      setSelectes(new_selectes);
      if (onChange) onChange(new_selectes);
    }

    // if (selectes.includes(value))
    //   return (new_selectes = selectes.filter((_id) => _id != value));
    // new_selectes = [...selectes, value];
    // setSelectes(new_selectes);
    // if (props.onChange) props.onChange(new_selectes);
  };
  const search = (value) => {
    let query = {};
    setOpen(false);
    if (value) {
      query["search"] = value;
      if (query["$or"]) delete query["$or"];
    } else {
      //query["$or"] = [{ _id: { $in: selectes } }];
      delete query["search"];
    }
    setFilterDefaultValues({
      ...filterDefaultValues,
      ...query,
    });
  };
  const handleOnSearch = _.debounce(search, 500, { maxWait: 800 });
  useEffect(() => {
    if (typeof props.dataSource != "undefined") {
      setDataSource(props.dataSource);
      setLoading(false);
    }
  }, [props.dataSource]);
  useEffect(() => {
    if (typeof props.selectes != "undefined") {
      setSelectes(props.selectes);
      setDefaultValue(props.selectes);
    }
  }, [props.selectes]);
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    setEditting(props.editting);
  }, [props.editting]);

  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (autoLoad) {
      getData();
    } else {
      if (!_.isEmpty(filterDefaultValues)) getData();
    }
  }, [filterDefaultValues]);
  useEffect(() => {
    if (typeof selectes != "undefined") {
      //if (props.onChange) props.onChange(selectes);
    }
  }, [selectes]);
  // if (loading) return null;
  return (
    <SelectGroupWrapper>
      {title && (
        <div className="section-title">
          {icon && <MdOutlinePersonAddAlt1 />}
          <span>{title}</span>
        </div>
      )}
      {editting && (
        <Select
          mode="multiple"
          //labelInValue
          loading={loading}
          dropdownClassName="select-group"
          defaultValue={defaultValue}
          placeholder="Select users"
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={false}
          //optionLabelProp="value"
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          onBlur={() => {
            setEditting(false);
          }}
          onChange={handleChange}
          style={{ width: "100%" }}
          className="select-group"
        >
          {dataSource.map(renderOptions || defaultRenderOptions)}
        </Select>
      )}
      {!editting && !loading && (
        <div className="item-group flex justify-start gap-xs align-centered">
          {selectes && selectes.length > 0 && (
            // <div className="items">
            //   {selectes.map(renderItem || defaultRenderItem)}
            // </div>
            <div className="items">
              {selectes.map((item, index) => (
                <div key={index}>
                  {renderItem ? renderItem(item) : defaultRenderItem(item)}
                </div>
              ))}
            </div>
          )}
          <div className="add-item">
            <Tooltip placement="topLeft" title="Select a member">
              <Button
                className="add-button"
                loading={props.submitting}
                onClick={() => setEditting(true)}
                icon="plus"
                type="link"
              />
              {/* {dataSource.length > 0 && <img className="add-item-avatar" src={"/images/usuario.png"} />} */}
            </Tooltip>
          </div>
        </div>
      )}
    </SelectGroupWrapper>
  );
};
export default SelectGroupField;
