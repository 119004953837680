import React from "react";
import { message } from "antd";
import { navigate } from "@reach/router";
import { algoliaClient, feathers as api, socket } from "../api";
import { useDispatch } from "react-redux";
import * as actionTypes from "../store/actions";
export const search = (queries) => {
  return new Promise((resolve, reject) => {
    algoliaClient.search(queries, (err, { results } = {}) => {
      if (err) return reject(err);
      resolve(results);
    });
  });
};

export const getService = (service_path, customHeaders = {}) => {
  let service = api.service(service_path);
  service = service.extend({
    headers: {
      ...service.headers,
      ...customHeaders,
    },
  });

  return {
    ...service,
    find: async (query, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          service
            .find(query, params)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.name == "NotAuthenticated") {
                message.error(`Session expired`);
                navigate("/signin");
              }
              reject(error);
            });
        } catch (err) {
          message.error(err.message);
          reject(err);
        }
      });
    },
    get: async (id, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          service
            .get(id, params)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.name == "NotAuthenticated") {
                message.error(`Session expired`);
                navigate("/signin");
              }
              reject(error);
            });
        } catch (err) {
          message.error(err.message);
          reject(err);
        }
      });
    },
    remove: async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          service
            .remove(id)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.name == "NotAuthenticated") {
                message.error(`Session expired`);
                navigate("/signin");
              }
              reject(error);
            });
        } catch (err) {
          message.error(err.message);
          reject(err);
        }
      });
    },
    patch: async (id, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          service
            .patch(id, params)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.name == "NotAuthenticated") {
                message.error(`Session expired`);
                navigate("/signin");
              }
              reject(error);
            });
        } catch (err) {
          message.error(err.message);
          reject(err);
        }
      });
    },
    create: async (params) => {
      return new Promise(async (resolve, reject) => {
        try {
          service
            .create(params)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.name == "NotAuthenticated") {
                message.error(`Session expired`);
                navigate("/signin");
              }
              reject(error);
            });
        } catch (err) {
          message.error(err.message);
          reject(err);
        }
      });
    },
  };
};
export const getSocket = (service) => socket.service(service);
export const savedListings = api.service("user-saved-listings");
export const userService = api.service("users");
export const recoveryPassword = api.service("recovery-password");
export const changePassword = api.service("change-password");
export const setPassword = api.service("set-password");
export const mylistings = api.service("my-listings");
export const reAuthenticate = api.reAuthenticate;
export const Logout = api.logout;
export const current = () => api.service("current-user").find({});
export const getCurrentUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let user = await current();
      const service = getService("customers");
      if (user.customer_id || (user.company && user.company.customer_id)) {
        let customer = await service.get(
          user.customer_id || (user.company && user.company.customer_id)
        );
        user["customer"] = customer;
      }
      // window.localStorage.setItem("feathers-jwt", accessToken);
      window.localStorage.setItem("user", JSON.stringify(user));
      resolve(user);
    } catch (err) {
      reject(err);
      message.error(JSON.stringify(err.message));
    }
  });
};

export const authenticate = ({ strategy, email, password, code, ...rest }) => {
  return api.authenticate({
    hostApp: "GOW",
    strategy,
    email,
    password,
    code,
    ...rest,
  });
};
export const tasks = socket.service("tasks");
export const LogActions = socket.service("log-actions");
export const Socials = socket.service("socials");
export const Connection = socket.service("connection");
