import React, { useState } from "react";
import { SimpleForm } from "../../components/com/form/";
import { Input } from "antd";
export const EmailConfirm = ({ MlsId, onSubmit, ...props }) => {
  const [message_error, setMessageError] = useState();
  const handleOnSubmit = (err, data) => {
    if (err) return setMessageError(err.message);
    if (onSubmit) onSubmit(err, data);
  };
  return (
    <>
      <SimpleForm
        source="confirm-mls-email"
        onSubmit={handleOnSubmit}
        submitButtonText={"Send"}
        submitButtonSize="large"
      >
        <div flex={1}>
          {!message_error && (
            <article>
              Please enter your email address to confirm your Mls account
            </article>
          )}
          {message_error && (
            <article className="text-red">{message_error}</article>
          )}
        </div>
        <Input name="MlsId" type="hidden" initial={MlsId} />
        <Input name="origin" type="hidden" initial={window.location.origin} />
        <Input
          size="large"
          type="email"
          placeholder="Email associated to Mls"
          flex={1}
          validations={[{ required: true, message: "Email is required" }]}
          name="email"
        />
      </SimpleForm>
    </>
  );
};
