import React, { useState, useEffect, Suspense } from "react";
import { Card, Icon, message, Spin, Skeleton } from "antd";
import { WrapperCard, WrapperSkeletonWiget } from "./Styles";
import { getService } from "../../../services";
import _ from "lodash";

const SkeletonWidget = ({
  count,
  width,
  height,
  avatar,
  loading,
  ...props
}) => {
  return (
    <WrapperSkeletonWiget>
      <Skeleton
        className="skeleton-widget-counter"
        loading={loading}
        active
        paragraph={{ rows: 2 }}
      ></Skeleton>
    </WrapperSkeletonWiget>
  );
};
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const { Meta } = Card;
const WidgetCounter = ({
  reference,
  title,
  optionValue = "count",
  description,
  count,
  ...props
}) => {
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      const service = getService(reference);
      setLoading(true);
      let response = await service.find({
        query: {
          ...filterDefaultValues,
          $limit: 5000000,
        },
      });
      if (response) setRecord(response);
      setLoading(false);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };
  const defaultRender = (record) => {
    return (
      <Card>
        <Meta
          title={title || ""}
          description={
            <>
              {!loading && record && <h2>{record[optionValue] || 0}</h2>}
              {loading && (
                <h2>
                  <Spin indicator={antIcon} size="small" />
                </h2>
              )}
              {description && (
                <span className="description">{description}</span>
              )}
            </>
          }
        />
      </Card>
    );
  };
  useEffect(() => {
    if (reference && filterDefaultValues) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  if (loading) return <SkeletonWidget loading={loading} />;
  return (
    <WrapperCard {...props}>
      {props.render ? props.render(record) : defaultRender(record)}
    </WrapperCard>
  );
};
export default WidgetCounter;
