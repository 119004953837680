import React, { useEffect, useState } from "react";
import { WraperDate, WrapperCalendar, WrapperForm } from "./Styles";
import { Calendar, Row, Col, Select, Radio, Button, message } from "antd";
import { SimpleForm } from "../../components/com/form/";
import {
  DateField,
  DateTimeField,
  SelectField,
} from "../../components/com/fields";
import { getService } from "../../services";
import _ from "lodash";
import moment from "moment";
/* 
'none',
'now',
'5 minutes before',
'10 minutes before',
'15 minutes before',
'1 hour before',
'2 hour before',
'1 days before',
'2 days before'

*/
const data = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "now",
    label: "Now",
  },
  {
    value: "5 minutes before",
    label: "5 Minutes",
  },
  {
    value: "10 minutes before",
    label: "10 minutes",
  },
  {
    value: "15 minutes before",
    label: "15 Minutes",
  },
  {
    value: "1 hour before",
    label: "1 Hour",
  },
  {
    value: "2 hour before",
    label: "2 Hours",
  },
  {
    value: "1 days before",
    label: "1 Day",
  },
  {
    value: "2 days before",
    label: "2 Days ",
  },
];

export const MyCalendar = ({
  id,
  name,
  form,
  format = "MM-DD-YYYY",
  time_format = "HH:mm",
  onChange,
  ...props
}) => {
  const [value, setValue] = useState();
  const onPanelChange = (value, mode) => {
    console.log(value, mode);
  };
  const handleOnChange = (value) => {
    setValue(value);
    if (onChange) onChange(value);
  };
  useEffect(() => {
    if (props.value) setValue(moment(props.value));
  }, [props.value]);
  return (
    <div style={{ width: 300, border: "0px solid #d9d9d9", borderRadius: 4 }}>
      <Calendar
        fullscreen={false}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          const current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
          }

          for (let index = start; index < end; index++) {
            monthOptions.push(
              <Select.Option className="month-item" key={`${index}`}>
                {months[index]}
              </Select.Option>
            );
          }
          const month = value.month();

          const year = value.year();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            );
          }
          return (
            <WrapperCalendar>
              <div style={{ padding: 10 }}>
                {/* <div style={{ marginBottom: "10px" }}>Custom header </div> */}
                <Row type="flex" justify="center">
                  <Col>
                    <div className="section-calendar">
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        onChange={(selectedMonth) => {
                          const newValue = value.clone();
                          newValue.month(parseInt(selectedMonth, 10));
                          onChange(newValue);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                  </Col>
                </Row>
              </div>
            </WrapperCalendar>
          );
        }}
        value={value || moment()}
        onPanelChange={onPanelChange}
        onChange={handleOnChange}
      />
    </div>
  );
};

const DateTaskForm = ({ id, reference, info, onSubmit, ...props }) => {
  const [payloads, setPayloads] = useState({});
  const [record, setRecord] = useState();
  const [date, setDate] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState();
  const handleOnChange = (field, value) => {
    console.log("value onchange", field, value);
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const handleOnSelect = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const handleonSave = () => {
    const service = getService(reference);
    console.log("save", id, payloads);
    setSubmitting(true);
    service
      .patch(id, payloads)
      .then((resp) => {
        message.success("Task Updated");
        setSubmitting(false);
        if (onSubmit) onSubmit();
      })
      .catch((err) => {
        message.error(err.message);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (!_.isEqual(props.record, record)) setRecord(props.record);
  }, [props.record]);
  return (
    <WrapperForm>
      <SimpleForm
        onMount={(form) => setForm(form)}
        initialValues={record}
        onChange={handleOnChange}
        autoSubmit={false}
        footer={
          <div>
            <Button type="primary" onClick={handleonSave}>
              SAVE
            </Button>
          </div>
        }
      >
        <MyCalendar name="end_date" flex={1} id={id} />
        <DateTimeField
          className="datetimefield"
          name="end_date"
          flex={1}
          onSelect={handleOnSelect}
          placeholder="Due Date"
          label="Due Date"
          initial={record && record.end_date}
        />
        <SelectField
          placeholder="Reminder"
          name="reminder"
          label="Reminder"
          optionText="label"
          optionValue="value"
          flex={1}
          choices={data}
        />
        <div flex={1}>
          <span>
            {info ? info : "Reminders will be sent to all members of this task"}
          </span>
        </div>
      </SimpleForm>
    </WrapperForm>
  );
};
export default DateTaskForm;
