import styled from "styled-components";

export const WrapperAcelleMail = styled.div`
  & .acelle-message {
    padding: 20px;
    min-height: 300px;
    font-family: "Poppins";
    & .ant-btn {
      display: flex !important;
    }
  }
  & .loader {
    text-align: center;
    & .loader-content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
    }
  }
  .ant-form-item {
    padding-bottom: 8px !important;
    margin-bottom: 11px !important;
  }
  & .ant-form {
    & .head-title {
      padding-top: 20px !important;
      background: transparent !important;
      padding-bottom: 10px;
      font-size: 16px !important;
      h2 {
        color: #262626e6 !important;
      }
    }
    & .ant-form-item {
      margin-bottom: 16px !important;
    }
    & .steps-action {
      margin-top: 10px;
      & .ant-btn {
        max-width: 32% !important;
      }
    }
  }
`;
