import React from 'react';
import { Layout } from "antd";
import styled from "styled-components";
import { navigate } from '@reach/router';
const { Content } = Layout;
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;

& .container-img{
  padding: 2px;
  margin-top: 0px;
  padding-top: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index:999999;
}
& .container-title-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
& .container-title-img img{
  height: 80px;
  width: 105px;
}

& .container-childdren {
   position: relative;
  //  z-index: 999999;
  background: transparent;
  margin: 0px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
& .container-background::before{
  content: " ";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("/images/Grupobackground.svg");
  background-size: contain;
  background-position: center center;
}

`;
const LayoutCenterPlan = ({ children, ...props }) => {
    const handleClick = () => {
        alert("")
        navigate("./")
      }
    return (
        <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <Content className="content-antd-background">
            <Container>
              <div className="container-childdren">
                {children}
              </div>
              {/* <div className="container-background"></div> */}
            </Container>
          </Content>
        </Layout>
      </Layout>
    )
}
export default LayoutCenterPlan;