import React, { useState, useEffect } from "react";
import CodeMirror from "react-codemirror";
//import htmlParser from "react-markdown/plugins/html-parser";
import { Wrapper, ToolBar } from "./Styles";
import { Icon, Button, Input, Modal } from "antd";
import { MarkdownWrapper } from "./Styles";
import { useSelector } from "react-redux";
/* import MarkDown from "./Markdown"; */
import { CommentInput } from "../comments";
import { useLocation, navigate, useParams } from "@reach/router";
import moment from "moment";
import qs from "querystring";
import { IoIosSend } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
require("codemirror/mode/markdown/markdown");
/* const parseHtml = htmlParser({
  isValidNode: (node) => node.type !== "script",
  processingInstructions: [
  ],
}); */
const { confirm } = Modal;
const HtmlView = ({ ...props }) => {
  const [record, setRecord] = useState();
  const [value, setValue] = useState();
  const [mentions, setMentions] = useState();
  const [raw_value, setRawValue] = useState();
  const handleOnClick = (item) => {
    alert(item._id);
  };
  useEffect(() => {
    if (props.value) setRawValue(props.value);
  }, [props.value]);
  useEffect(() => {
    if (typeof props.record != "undefined") setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (record) {
      let { mentions } = record;
      setMentions(mentions);
    }
  }, [record]);
  useEffect(() => {
    if (mentions) {
      mentions.forEach((mention) => {
        let {
          task_id,
          task: { board_id },
        } = record;
        let query = { task_id, board_id };
        setRawValue((current_value) =>
          current_value.replace(
            `@${mention._id}`,
            `<a>@${`${mention.first_name} ${mention.last_name}`}</a>`
          )
        );
      });
    }
  }, [mentions]);
  /* useEffect(() => {
    if (mentions) {
      mentions.forEach((mention) => {
        console.log("Mention: ", mention, record);
        let {
          task_id,
          task: { board_id },
        } = record;
        let query = { task_id, board_id };
        setRawValue((current_value) =>
          current_value.replace(
            `@${mention._id}`,
            `<a  href="#/profile/${mention._id}?${qs.stringify(
              query
            )}">@${mention.first_name || ""}</a>`
          )
        );
      });
    }
  }, [mentions]); */
  useEffect(() => {
    if (raw_value) {
      setValue(raw_value);
    }
  }, [raw_value]);
  return (
    <div className="html-view">
      <div dangerouslySetInnerHTML={{ __html: raw_value }} />
    </div>
  );
};
const MarkDownEditor = ({
  title,
  actions = {},
  autoReset = false,
  code = false,
  author,
  onMentions,
  momentitem,
  icon,
  ...props
}) => {
  const location = useLocation();
  const params = useParams();
  const [value, setValue] = useState(props.initialValue || "");
  const [record, setRecord] = useState();
  const [raw_value, setRawValue] = useState();
  const [edit, setEdit] = useState(props.edit || false);
  const user = useSelector(({ user }) => user);
  const handleOnChange = (e) => {
    let value = e;
    if (e && typeof e.target != "undefined") value = e.target.value;
    setValue(value);
    if (props.onChange) props.onChange(value);
  };
  const handleOnRawTextChange = (value) => {
    setRawValue(value);
  };
  const handleOnMentions = (mentions) => {
    if (onMentions) onMentions(mentions);
  };
  const isOwner = () => {
    return author && author._id === user._id;
  };
  useEffect(() => {
    if (props.value !== value) setValue(props.value);
  }, [props.value]);
  useEffect(() => {
    if (props.raw_value !== raw_value) setRawValue(props.raw_value);
  }, [props.raw_value]);
  useEffect(() => {
    if (typeof props.record != "undefined") setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (location && location.hash) {
      let _id = location.hash.replace(/#\/profile\//g, "");
      if (_id.includes("?")) {
        _id = _id.substring(0, _id.indexOf("?"));
        console.log("location _id: ", _id, record);
        if (_id) navigate(`/dashboard/profile/${_id}?${qs.stringify(params)}`);
      }
    }
  }, [location]);
  return (
    <Wrapper>
      {title && (
        <ToolBar>
          <ToolBar>
            <h2>
              {icon && <Icon type={icon} />} {title}
            </h2>
            {!edit && value && (
              <Button
                icon="edit"
                type="link"
                size="large"
                onClick={() => setEdit((edit) => !edit)}
              />
            )}
          </ToolBar>
        </ToolBar>
      )}
      {!value && !edit && (
        <Input.TextArea
          onFocus={() => setEdit(true)}
          className="head-input"
          autoSize={{ minRows: 3 }}
          placeholder={props.placeholder || "Input text"}
        />
      )}
      {edit ? (
        code ? (
          <CodeMirror
            mode="markdown"
            theme="default"
            autoFocus={true}
            value={value}
            onChange={handleOnChange}
            options={{
              mode: "markdown",
              readOnly: props.readOnly || false,
              theme: "monokai",
              lineNumbers: props.lineNumbers || false,
            }}
          />
        ) : (
          <CommentInput
            className="container-comment-input"
            onRawTextChange={handleOnRawTextChange}
            onMentions={handleOnMentions}
            value={value}
            editing={true}
            showAvatar={false}
            add={false}
            edit={edit}
          />
        )
      ) : (
        <MarkdownWrapper box={props.box}>
          <HtmlView flex={1} record={record} value={raw_value} />
          {/*  <MarkDown flex={1} source={value} /> */}
        </MarkdownWrapper>
      )}
      <div className="container-btn-comments">
        <div className="section-moments"></div>
        <div className="btn-action-task-comments">
          {actions.edit && !edit && isOwner() && (
            <Button
              icon="edit"
              type="link"
              size="small"
              onClick={() => setEdit((edit) => !edit)}
            />
          )}
          {actions.delete && !edit && isOwner() && (
            <Button
              className="btn-removed-red"
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  content:
                    "This file will be permanently removed if you delete it. Please confirm if you wish to proceed.",
                  onOk() {
                    if (props.onRemove) props.onRemove(props._id);
                  },
                  onCancel() {},
                });
              }}
            >
              <Icon type="delete" className="btn-color-red" />
              {/* <MdDeleteOutline className="btn-removed-red" /> */}
            </Button>
          )}
        </div>
      </div>
      <ToolBar>
        <ToolBar>
          <div className="section-edit-buttton">
            {edit && (
              <Button
                className="btn-edit"
                size="medium"
                type="link"
                onClick={() => {
                  if (props.onSubmit && raw_value) {
                    props.onSubmit(props.name, raw_value);
                  }
                  setEdit((edit) => !edit);
                  if (autoReset) {
                    if (props.form) props.form.resetFields();
                    setValue(null);
                  }
                }}
              >
                <IoIosSend />
              </Button>
            )}
            {edit && (
              <Button
                className="btn-close"
                size="medium"
                icon="close"
                type="link"
                onClick={() => {
                  if (autoReset) setValue(null);
                  setEdit((edit) => !edit);
                }}
              />
            )}
          </div>
        </ToolBar>
      </ToolBar>
    </Wrapper>
  );
};
export default MarkDownEditor;
