import React, { useEffect, useState } from "react";
import { Card, Button, Icon, Spin } from "antd";
import { Container } from "./Styles";
import { navigate } from "@reach/router";
const antIcon = <Icon type="loading" style={{ fontSize: 18 }} spin />;
const Box = ({ goBack, children, ...props }) => {
  const [show, setShow] = useState(props.show || false);
  const [loading, setLoading] = useState(false);
  const handleShowHidde = () => {
    if (props.xtype) setShow((show) => !show);
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (typeof props.loading != "undefined") setLoading(props.loading);
  }, [props.loading]);
  return (
    <Container
      xtype={props.xtype}
      className={"box-wrapper" || `${props.className}`}
      {...props}
    >
      <Card
        bordered={typeof props.xtype == "undefined"}
        className={`card-${show ? "opened" : "closed"}`}
        /* extra={props.title && <>
                <Button className="btn-show" icon={show ? "minus" : "plus"} type="link"
                    onClick={handleShowHidde} />
            </>} */
        title={
          props.title && (
            <div className="head-title" onClick={handleShowHidde}>
              {goBack && (
                <Button icon="arrow-left" type="link" onClick={handleGoBack} />
              )}
              {props.xtype && (
                <Button
                  style={{
                    width: 34,
                  }}
                  className="btn-show"
                  icon={show ? "up" : "down"}
                  type="link"

                  /* onClick={handleShowHidde} */
                />
              )}

              <div className="section-loading">
                <h3>{props.title}</h3>
                {loading && <Spin indicator={antIcon} />}
              </div>
            </div>
          )
        }
        extra={<>{props.extra}</>}
      >
        <>{show && children}</>
      </Card>
    </Container>
  );
};
Box.defaultProps = {
  goBack: false,
};
export default Box;
