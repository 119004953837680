import styled from "styled-components";
export const Wrapper = styled.div`
  background: #fff;
  margin-top: 2px;
  padding: 18px 20px;
  border-radius: 8px;
  width: 100%;
  min-height: 448px;
  box-shadow: 0 0.75rem 1.5rem #12263f14;
  border: 1px solid #e8e8e8;
  & .section-tag span.ant-tag {
    border-radius: 0px !important;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px !important;
    padding: 4px 10px !important;
    width: auto !important;
    color: #545454;
    background-color: #e8e8e8;
    height: 22px !important;
  }
  & .detail a {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
  & .detail a > * {
    margin: 0px 4px !important;
  }
  & span.ant-tag:hover {
    color: #038fde;
  }
  & span.ant-tag.active-tag:hover {
    color: #fff;
    background: #038fde;
    border-radius: 18px !important;
  }
  & .inactive {
    background: transparent !important;
  }
  & span.ant-tag.active-tag {
    color: #038fde;
    background: transparent;
    border: 1px solid #038fde !important;
    border-radius: 18px !important;
  }
  & .section-col {
    padding: 10px !important;
    display: flex;
    justify-content: space-between;
  }
  & .section-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .section-tag {
    padding: 2px !important;
  }
`;
