import React, { useEffect } from "react";
import { WrapperBoardForm, WrapperDescriptionTemplate } from "./Styles";
import SearchField from "../com/form/SearchField";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Icon,
  Input,
  List,
  Row,
  Tag,
  message,
} from "antd";
import BoardForm from "./BoardForm";
import { navigate } from "@reach/router";
import { useState } from "react";
import { getService } from "../../services";
import { useSelector } from "react-redux";
import CreateBoard from "./CreateBoard";
import { Avatar } from "@material-ui/core";
import {
  DEFAULT_IMAGE,
  DEFAULT_IMAGE_BOARD,
  URL_DEFAULT_AVATAR,
  URL_S3,
} from "../../constants";
import TagsCategoryBoard from "./TagsCategoryBoard";
import ListTemplate from "./ListTemplate";
import _ from "lodash";
const { Item } = List;
const tag = [{}, {}];
export const BoardListForm = ({
  board_type,
  onChangeTemplate,
  user,
  onCreateBlankTemplate,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState();
  const [boards, setBoards] = useState([]);
  const [template, setTemplate] = useState({});
  const [record, setRecord] = useState({});
  const [keep_tasks, setKeepTasks] = useState(true);
  const [submithing, setSubmithing] = useState(false);
  const [goback, setGoBack] = useState("default");
  const [category, setCategory] = useState();
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  // const [payloads, setPayloads] = useState();
  const handleClickItem = (item) => {
    setFilterDefaultValues({
      ...filterDefaultValues,
      category_ids: {
        $in: [item._id],
      },
    });
  };
  const handleOnClick = (item) => {
    setView("template-detail");
    setRecord(item);
    // setTemplate(item);
    // console.log(view);
  };
  const handleUseTemplate = (item) => {
    let id = item._id;

    let payloads = {
      board_type: board_type,
      company_id: user && user.company_id,
      user_id: user && user._id,
      template_id: id,
      name: `${item.name} - Copy`,
      keep_tasks: keep_tasks,
    };
    // return console.log("que template seleccione", payloads);
    const service = getService("boards");
    setSubmithing(true);
    service
      .create(payloads)
      .then((resp) => {
        message.success("Template Cloned");
        setSubmithing(false);
        navigate(
          `/dashboard/${board_type === "board" ? "boards" : "workflow"}/${
            resp._id
          }`
        );
      })
      .catch((err) => {
        message.error(err.message);
        setSubmithing(false);
      });
  };
  const handleOnClickNewTemplate = () => {
    if (onCreateBlankTemplate) onCreateBlankTemplate();
  };
  const handleOnClickTemplate = (id) => {
    let payloads = {
      board_type: board_type,
      company_id: user && user.company_id,
      user_id: user && user._id,
      template_id: id,
      name: `${template.name} - Copy`,
      [board_type === "template "
        ? "keep_tasks"
        : "keep_automatization"]: keep_tasks,
    };
    // return console.log("que template seleccione a", payloads);
    const service = getService("boards");
    setSubmithing(true);
    service
      .create(payloads)
      .then((resp) => {
        message.success("Template Cloned");
        setSubmithing(false);
        navigate(
          `/dashboard/${board_type === "board" ? "boards" : "workflow"}/${
            resp._id
          }`
        );
      })
      .catch((err) => {
        message.error(err.message);
        setSubmithing(false);
      });
  };
  const handleOnCheckeBox = (e) => {
    let { checked } = e.target;
    setKeepTasks((checked) => !checked);
    // console.log(checked)
  };
  const onSubmit = (err, data) => {
    console.log(err, data);
    if (props.onSubmit) props.onSubmit(err, data);
  };
  const handleGoBack = () => {
    setView("template");
    setFilterDefaultValues({
      ...filterDefaultValues,
      category_ids: undefined,
    });
  };
  const handleSearch = (search) => {
    console.log("::search::", search);
    let type =
      board_type === "board"
        ? "template"
        : board_type === "template"
        ? "template"
        : board_type === "workflow"
        ? "workflow_template"
        : "workflow_template";
    setFilterDefaultValues({
      search,
      board_type: type,
      status: "active",
      company_id: user && user.company_id,
    });
  };
  const getData = () => {
    const service = getService("boards");
    let { company_id } = user;
    setLoading(true);
    service
      .find({
        query: {
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setBoards(data);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };
  const renderItem = (item, index) => {
    let { author, background, image } = item;
    return (
      <WrapperBoardForm>
        <Item>
          <div>
            <Card
              style={{ width: 248 }}
              hoverable
              onClick={() => {
                setTemplate(item);
              }}
              cover={
                <img
                  className={`container-${
                    item && item.image ? "image-cover" : "cover-void"
                  } `}
                  alt="example"
                  src={
                    item.image
                      ? `${URL_S3}/${item && item.image}`
                      : DEFAULT_IMAGE_BOARD
                  }
                />
              }
            >
              {
                <div className="container-actions">
                  <div className="container-button">
                    <Button
                      className="btn-preview"
                      onClick={() => handleOnClick(item)}
                    >
                      Preview
                    </Button>
                  </div>
                  <div className="container-button">
                    <Button
                      className="btn-template"
                      onClick={() => handleUseTemplate(item)}
                    >
                      Use Template
                    </Button>
                  </div>
                </div>
              }
              {/* </div> */}
            </Card>
            <div className="section-name-board">
              <span>{item.name}</span>
            </div>
            <div className="card-avatar-company">
              <Avatar
                className="avatar-company"
                src={
                  item.author && item.author.picture
                    ? `${URL_S3}/${item.author.picture}`
                    : URL_DEFAULT_AVATAR
                }
                size="large"
              />
              <div className="card-company-name">
                <h4>
                  {item &&
                    item.author &&
                    `${item.author.first_name} ${item.author.last_name}`}
                </h4>
                {/* <h3>{item.name}</h3> */}
              </div>
            </div>
          </div>
        </Item>
      </WrapperBoardForm>
    );
  };

  useEffect(() => {
    if (template) onChangeTemplate(template);
  }, [template]);
  useEffect(() => {
    return () => {
      setBoards([]);
      setTemplate(null);
    };
  }, []);
  useEffect(() => {
    if (filterDefaultValues) getData();
    console.log("filter", filterDefaultValues);
  }, [filterDefaultValues]);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    setView(props.view);
  }, [props.view]);
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (
      record &&
      record.board_type === "template" &&
      record.category_ids &&
      record.category_ids.length > 0
    ) {
      setFilterDefaultValues({
        ...filterDefaultValues,
        category_ids: {
          $in: record && record.category_ids,
        },
      });
    }
  }, [record]);

  /*  useEffect(() => {
    setFilterDefaultValues({
      board_type,
      status: "active",
      company_id: user && user.company_id,
    });
  }, []); */
  return (
    <>
      {view === "template" && (
        <div className="container-new-board">
          <div>
            <SearchField
              allowClear
              placeholder="Search Templates"
              onSearch={handleSearch}
              defaultFilterValues={{
                board_type,
                status: "active",
                company_id: user.company_id,
                $or: [
                  {
                    user_id: user._id,
                  },
                  {
                    members_id: { $in: [user._id] },
                  },
                ],
              }}
              source="boards"
            />
          </div>
        </div>
      )}
      {view === "board" ? (
        <CreateBoard
          board_type={board_type}
          id={record._id}
          template_id={template._id}
          onSubmit={onSubmit}
          source="boards"
          {...record}
          name={
            record && record.board_type === "template"
              ? record.name
              : template.name
              ? `${template.name} - Copy`
              : null
          }
          /*  autoSubmit={false} */
          header={
            view === "template" &&
            view === "board" && (
              <Row
                style={{
                  margin: 0,
                  padding: 0,
                }}
                type="flex"
                justify="start"
                align="middle"
              >
                <Col>
                  {template.name && <h2>{template.name.capitalize()} </h2>}
                  {
                    <Button
                      type="link"
                      icon="arrow-left"
                      //   onClick={() => setView("template")}
                    >
                      Back to templates
                    </Button>
                  }
                </Col>
              </Row>
            )
          }
          fields={[
            {
              xtype: "hidden",
              name: "template_id",
              initial: template._id,
            },
            {
              xtype: "hidden",
              name: "board_type",
              initial: template._id ? "board" : board_type,
            },
            {
              name: "name",
              label: "Title",
              flex: 1,
              initial: template.name ? `${template.name} - Copy` : null,
              placeholder: "Add board title",
              validations: [{ required: true }],
            },
            board_type === "board" && {
              xtype: "selectfield",
              reference: "teams",
              name: "team_id",
              label: "Team",
              flex: 1,
              placeholder: "Select a Team",
            },
            /* {
              xtype: template._id ? "checkbox" : "hidden",
              name: "keep_tasks",
              text: "Keep Tasks",
              flex: 1,
              onChange: () => setKeepTasks((checked) => !checked),
              checked: keep_tasks,
              initial: true,
              placeholder: "Keep Tasks",
            }, */
          ]}
        />
      ) : view === "template" ? (
        <List
          style={{
            maxHeight: 450,
            overflowY: "auto",
            padding: "18px",
          }}
          grid={{ gutter: 8, xs: 1, md: 2, xl: 4, lg: 3, xxl: 4 }}
          loading={loading}
          renderItem={renderItem}
          dataSource={boards}
        />
      ) : (
        <WrapperDescriptionTemplate>
          <div className="container-descriptiom-template">
            <div className="section-descrition-template">
              <span onClick={handleGoBack}>
                <Icon type="arrow-left" /> Return to template list
              </span>
              {/*  <Button icon="arrow-left" type="link" onClick={handleGoBack}>
                Return to template list
              </Button> */}
            </div>
            <Row type="flex" justify="center" align="center" gutter={14}>
              <Col xl={12} className="col-section">
                <div>
                  <div className="section-name-template">
                    <h3>{record.name}</h3>
                  </div>
                  <div className="container-check">
                    <Checkbox
                      onChange={handleOnCheckeBox}
                      defaultValue={keep_tasks}
                      checked={keep_tasks}
                    >
                      <span className="section-checkbox">
                        {board_type !== "workflow_template"
                          ? "Set Keep Tasks"
                          : "Set Keep Automatizations"}
                      </span>
                    </Checkbox>
                  </div>
                  <div className="action-button-description">
                    <div>
                      <div className="button-template">
                        <Button
                          className="btn-template-designed"
                          loading={submithing}
                          onClick={() => handleOnClickTemplate(record._id)}
                        >
                          Use Predesigned template
                        </Button>
                      </div>
                      <div>
                        <Button
                          disabled={submithing}
                          className="btn-template-blank"
                          onClick={handleOnClickNewTemplate}
                        >
                          {board_type === "board" && "Create Blank Board"}
                          {board_type === "workflow" && "Create Blank Workflow"}
                          {board_type === "template" && "Create Blank Template"}
                          {board_type === "workflow_template" &&
                            "Create Blank Template"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <Col xl={22}>
                  <div className="container-section">
                    <div className="section-title-template">
                      <h3>About this template</h3>
                    </div>
                    <span>{record && record.description}</span>
                  </div>
                </Col>
                <Col xl={24}>
                  {
                    <div className="section-categories-render">
                      <div className="section-title-template">
                        <h3>Related categories</h3>
                      </div>
                      <List
                        style={{
                          maxHeight: 450,
                          overflowY: "auto",
                          padding: "18px",
                        }}
                        grid={{ gutter: 4, xs: 1, md: 1, xl: 2, lg: 2, xxl: 3 }}
                        loading={loading}
                        renderItem={renderItem}
                        dataSource={boards}
                      />
                    </div>
                  }
                </Col>
              </Col>
              <Col xl={12}>
                <div className="container-card-image">
                  <div className="container-img-description">
                    <img
                      className={`${
                        record.image ? "image-cover" : "image-void"
                      }`}
                      alt="example"
                      src={
                        record.image
                          ? `${URL_S3}/${record.image}`
                          : DEFAULT_IMAGE_BOARD
                      }
                    />
                  </div>
                  <div className="">
                    <div className="container-company-description">
                      <div className="section-company">
                        <Avatar
                          src={
                            record.author && record.author.picture
                              ? `${URL_S3}/${record.author.picture}`
                              : URL_DEFAULT_AVATAR
                          }
                          size="large"
                        />
                        <div className="company-name">
                          <h4>
                            {`${record &&
                              record.author &&
                              record.author.first_name} ${record &&
                              record.author &&
                              record.author.last_name}`}
                          </h4>
                        </div>
                      </div>
                      <div className="company-description">
                        <p>
                          {record && record.author && record.author.about_me}
                        </p>
                      </div>
                    </div>
                    <Divider />
                    <div className="section-tagCategory">
                      <TagsCategoryBoard
                        handleClickItem={handleClickItem}
                        record={record}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </WrapperDescriptionTemplate>
      )}
    </>
  );
};
const NewBoardForm = ({
  board_type,
  onChangeTemplate,
  view,
  onSubmit,
  onCreateBlankTemplate,
  ...props
}) => {
  const user = useSelector(({ user }) => user);
  const [record, setRecord] = useState();
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (props.filterDefaultValues)
      console.log("filter1", props.filterDefaultValues);
    setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  return (
    <WrapperBoardForm>
      <Row>
        <Col>
          <div>
            <BoardListForm
              view={view}
              user={user}
              record={record}
              board_type={board_type}
              filterDefaultValues={filterDefaultValues}
              onChangeTemplate={onChangeTemplate}
              onSubmit={onSubmit}
              onCreateBlankTemplate={onCreateBlankTemplate}
            />
          </div>
        </Col>
      </Row>
    </WrapperBoardForm>
  );
};
export default NewBoardForm;
