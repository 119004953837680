export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_AUTHENTICATE = 'SET_AUTHENTICATE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const START_LOADING_USER = 'START_LOADING_USER';
export const END_LOADING_USER = 'END_LOADING_USER';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';


export const ADD_TASK = "ADD_TASK";


export const UPDATE_TASKS = "UPDATE_TASKS";
export const CREATE_TASKS = "CREATE_TASKS";
export const COLOR_THEME = "COLOR_THEME";
export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";