import React from "react";
import { WrapperForm } from "./Styles";
import { SimpleForm } from "../../components/com/form/";
import { Input } from "antd";
import S3Button from "../../components/com/form/S3Button";

/* 
 {
      name: "current_password",
      label: "Enter Current Password",
      placeholder: "●●●●●●●●",
      type: "password"
    },
    {
      name: "new_password",
      label: "Enter New Password",
      placeholder: "●●●●●●●●",
      type: "password"
    }

*/
const ChangePasswordForm = ({ id, ...props }) => {
  return (
    <WrapperForm>
      <SimpleForm
        source="users"
        id={id}
        textAcceptButton="Update"
        autoSubmit={true}
      >
        <Input.Password
          name="password"
          flex={1}
          // placeholder="●●●●●●●●"
          label="Enter Current Password"
          placeholder="Enter Current Password"
          validations={[
            {
              required: true,
            },
          ]}
        />
        <Input.Password
          name="new_password"
          flex={1}
          // placeholder="●●●●●●●●"
          label="Enter New Password"
          placeholder="Enter New Password"
          validations={[
            {
              required: true,
            },
          ]}
        />
        <Input.Password
          flex={1}
          name="confirm_password"
          // placeholder="●●●●●●●●"
          reference="new_password"
          label="Repeat New Password"
          placeholder="Repeat New Password"
          validations={[
            {
              required: true,
            },
          ]}
        />
      </SimpleForm>
    </WrapperForm>
  );
};
export default ChangePasswordForm;
