import * as actionType from "./actions";
import { reAuthenticate } from "../services/services";
import { navigate } from "@reach/router";
const initialState = {
  collapsedMenu: false,
  updated: false,
  user:
    window.localStorage.getItem("user") &&
    window.localStorage.getItem("user") !== "undefined"
      ? JSON.parse(window.localStorage.getItem("user"))
      : null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.COLLAPSE_MENU:
      return {
        ...state,
        collapsedMenu: !state.collapsedMenu,
      };
      break;
    case actionType.UPDATE_LIST:
      return {
        ...state,
        updated: action.updated,
      };
      break;
    case actionType.CHAGE_PATH:
      return {
        ...state,
        pathname: action.pathname,
      };
      break;
    case actionType.CHAGE_PAGE:
      return {
        ...state,
        current: action.current,
      };
      break;
    case actionType.AUTHENTICATION:
      localStorage.setItem("user", JSON.stringify(action.user));
      return {
        ...state,
        user: action.user,
      };
      break;
    case actionType.AUTHENTICATION_FAIL:
      switch (action.error.code) {
        case 402:
          console.log("ERROR:::", action.error);
          navigate("/profile");
          break;
        default:
          navigate("/");
          break;
      }
      return {
        ...state,
        error: action.error,
      };
      break;
    case actionType.NOTIFICATION:
      return {
        ...state,
        notify: action.notify,
      };
      break;
    case actionType.ADD_TASK:
      return {
        ...state,
        task: action.task,
      };
      break;
    case actionType.UPDATE_TASKS:
      let { data } = action;
      // console.log("QUE VIEN AQUI!", action);
      return {
        ...state,
        task: data.log,
      };
      break;
    case actionType.COLOR_THEME:
      let { theme } = action;
      return {
        ...state,
        theme,
      };
      break;
    case actionType.RE_AUTHENTICATION:
      reAuthenticate()
        .then((response) => {
          console.log("Logins successfully!");
        })
        .catch((err) => console.log("Authentication fail!"));
      return {
        ...state,
        user: action.user,
      };
      break;

    default:
      return state;
  }
};

export default reducer;
