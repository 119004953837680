import { Button, Card, Icon, List } from "antd";
import React from "react";
import { WrapperBoardList } from "./Styles";
const { Item } = List;
const DefaultCreateBoard = ({
  view,
  board_type,
  handleOnClick,
  handleDefaultButton,
  ...props
}) => {
  const renderOptions = (item, index) => {
    return (
      <List.Item
        key={item.key}
        onClick={() => {
          if (handleOnClick) handleOnClick(item.key);
        }}
        className={`item ${index === 0 ? "new-item" : ""}`}
      >
        <Card>
          <Card.Meta
            avatar={<Icon type={item.icon} />}
            title={item.title}
            description={item.description}
          />
        </Card>
      </List.Item>
    );
  };
  return (
    <WrapperBoardList className={`board-list ${view}`}>
      <div className="new-project-container">
        <div className="new-project-head">
          <h2>
            Create a{" "}
            {board_type !== "workflow" ? (
              <strong>
                New {board_type === "board" ? "Board" : "Template"}
              </strong>
            ) : (
              <strong>New {board_type}</strong>
            )}
          </h2>
          {/* <p>How do you want to start?</p> */}
        </div>
        <div className="new-project-list">
          <List
            grid={{
              gutter: 16,
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 2,
              sm: 2,
              xs: 1,
            }}
            dataSource={[
              {
                key: "board",
                icon: "plus",
                title:
                  board_type === "board"
                    ? "Blank Board"
                    : "" ||
                      board_type === "template" ||
                      board_type === "workflow_template"
                    ? "New Template"
                    : "" || board_type === "workflow"
                    ? "Blank Workflow"
                    : "",
                // description: "Starting over",
              },
              {
                key: "template",
                icon: "rocket",
                title: "Templates",
                // description: "from the library",
              },
            ]}
            renderItem={renderOptions}
            footer={
              <div className="flex gap-lg item-centered justify-center">
                <Button
                  onClick={() => {
                    if (handleDefaultButton) handleDefaultButton();
                    // setView("default");
                  }}
                  className="button-cancel"
                  size="small"
                  style={{
                    maxWidth: "36%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: "#3c57b0",
                    fontWeight: "500",
                    borderRadius: "25px",
                    color: "#3c57b0",
                    height: "30px",
                  }}
                  block
                  type="ghost"
                  // icon="close"
                >
                  Close
                </Button>
              </div>
            }
          />
        </div>
      </div>
    </WrapperBoardList>
  );
};
export default DefaultCreateBoard;
