import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Container, TaskWrapper } from "./Styles";
import { navigate } from "@reach/router";
import {
  Avatar,
  Row,
  Col,
  Icon,
  Tag,
  Tooltip,
  Menu,
  Dropdown,
  Button,
  Input,
  List,
} from "antd";
import { URL_S3, URL_DEFAULT_AVATAR } from "../../constants";
import MemberGroup from "./MemberGroup";
import moment from "moment";
import { BoardContext, TaskContext } from "./context";
import { useContext } from "react";
import { StatusField } from "../com/fields";
import _ from "lodash";
const status = [
  {
    text: "Active",
    name: "active",
  },
  {
    text: "Inactive",
    name: "inactive",
  },
  {
    text: "Archived",
    name: "archived",
  },
  {
    text: "Accepted",
    name: "accepted",
  },
  {
    text: "Completed",
    name: "completed",
  },
  {
    text: "In Progress",
    name: "in_progress",
  },
  {
    text: "Pending",
    name: "pending",
  },
  {
    text: "Canceled",
    name: "canceled",
  },
];
const priorities = [
  {
    name: "low",
    text: "Low",
  },
  {
    name: "medium",
    text: "Medium",
  },
  {
    name: "high",
    text: "High",
  },
];
const data = [
  {
    color: "red",
    title: "asignado",
  },
  {
    color: "green",
    title: "cerrado",
  },
  {
    color: "black",
    title: "abierto",
  },
];
const TaskCard = ({ task, onMenuClick, board_id, id, ...props }) => {
  const [expired, setExpired] = useState();
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState();
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const [date_description, setDateDescription] = useState();
  const { saveTask, removeTask } = useContext(BoardContext);
  const handleMenuClick = (e) => {
    let { key } = e;
    setVisible(false);
    switch (key) {
      case "show":
        if (board_id) navigate(`/dashboard/boards/${board_id}/tasks/${id}`);
        break;
      case "archived":
        removeTask(task._id);
        break;
      case "edit":
        setEditing(true);
        break;
      default:
        if (onMenuClick) onMenuClick(e);
        break;
    }
  };
  const handleOnChange = (e) => {
    let { value } = e.target;
    setChanged(true);
    setValue(value);
  };
  const renderItem = (item) => {
    return (
      <>
        <Tag color={item.background}>{item.title}</Tag>
      </>
    );
  };
  const handleOnSubmit = async () => {
    setSubmitting(true);
    setEditing(false);
    await saveTask({
      ...task,
      subject: value,
    });
    setChanged(false);
    setSubmitting(false);
    setVisible(visible);
  };
  const handleOnShow = (visible) => {
    setVisible(visible);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">
        <Icon type="edit" className="icon-global" />
        Edit
      </Menu.Item>
      <Menu.Item key="show">
        <Icon type="eye" className="icon-global" />
        See Detail
      </Menu.Item>
      <Menu.Item key="archived">
        <Icon type="delete" className="btn-removed-red" />
        Remove
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    if (task) setValue(task.subject);
    if (task.date) {
      var date = moment(task.date);
      var now = moment();
      let date_description = date.format("D [of] MMM.");
      setExpired(now > date);
      setDateDescription(date_description);
    }
  }, [task]);
  useEffect(() => {
    return () => {
      setChanged(false);
      setEditing(false);
      setVisible(false);
    };
  }, []);
  return (
    <TaskWrapper className="task-container">
      <div
        onClick={
          !editing
            ? (e) => {
                e.preventDefault();
                if (board_id)
                  navigate(`/dashboard/boards/${board_id}/tasks/${id}`);
              }
            : undefined
        }
        className="task-content"
      >
        <div className="subject">
          {editing && (
            <Input
              ref={(el) => {
                if (el) el.focus();
              }}
              onBlur={() => {
                if (value) setEditing(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") handleOnSubmit();
              }}
              onPressEnter={() => {
                handleOnSubmit();
              }}
              onChange={handleOnChange}
              defaultValue={value}
              size="small"
              placeholder="Enter Task Title"
              autoSize={{ minRows: 4, maxRows: 5 }}
            />
          )}

          {task && task.tags && task.tags.length > 0 && (
            <div className="container-list">
              <List
                className="list-tags"
                grid={{ gutter: 4, sm: 2, xs: 2, md: 3, xl: 3, lg: 3, xxl: 2 }}
                //   loading={loading}
                itemLayout="vertical"
                renderItem={renderItem}
                // dataSource={data}
                dataSource={task.tags}
              />
            </div>
          )}
          {!editing && (
            <div
              onClick={() => setEditing(true)}
              className="task-subject w-full"
            >
              <span>{value}</span>
            </div>
          )}
        </div>
        <div className="tasks-tools">
          <Row
            className="list-tools item-centered"
            gutter={[8, 4]}
            type="flex"
            justify="start"
            align="middle"
          >
            {task && task.documents && task.documents.length > 0 && (
              <Col>
                <div className="item-list">
                  <Tooltip placement="bottom" title={"Attachts"}>
                    <Icon type="paper-clip" />{" "}
                    {task && task.documents && task.documents.length}
                  </Tooltip>
                </div>
              </Col>
            )}
            {task && task.comments && task.comments.length > 0 && (
              <Col>
                <div className="item-list">
                  <Tooltip placement="bottom" title={"Comments"}>
                    <Icon type="message" /> {task.comments.length}
                  </Tooltip>
                </div>
              </Col>
            )}
            {task && task.date && (
              <Col>
                <div className="item-list">
                  <Tooltip
                    placement="bottom"
                    title={
                      expired
                        ? `The deadline for this task has already expired.`
                        : `The deadline for this task is ${date_description}`
                    }
                    className="tag"
                    size="small"
                    color="#CCC"
                  >
                    <span className={`tag ${expired && "tag-expired"}`}>
                      <Icon type="clock-circle" /> {date_description}
                    </span>
                  </Tooltip>
                </div>
              </Col>
            )}
            {task && (
              <Col className="status-container" span={24}>
                <StatusField
                  choices={status}
                  optionValue="name"
                  optionText="text"
                  source="status"
                  record={task}
                />
                <StatusField
                  choices={priorities}
                  optionValue="name"
                  optionText="text"
                  source="priority"
                  record={task}
                />
              </Col>
            )}
            <Col span={22}>
              <div className="container-members">
                <MemberGroup
                  size="small"
                  flex={1}
                  add={false}
                  tooltip={true}
                  /* onChangeMember={handleChangeMember}
                onRemove={handleRemoveMember} */
                  record={task}
                  members_ids={
                    task &&
                    task.members_ids &&
                    task.members_ids.map((it) => it && it._id)
                  }
                  members={task.members}
                />
              </div>
              <div className="section-date-task">
                <span>{moment(task.createdAt).format("MM-DD-YYYY  LT")}</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {!editing && (
        <div className={!visible ? "task-menu" : "task-menu task-menu-open"}>
          <Dropdown
            visible={visible}
            onVisibleChange={handleOnShow}
            overlayClassName="menu-actions"
            trigger={["click"]}
            overlay={menu}
          >
            <Button
              className="flex item-centered justify-center gap-0 m-0"
              icon="ellipsis"
            />
          </Dropdown>
        </div>
      )}
    </TaskWrapper>
  );
};
const Task = ({ id, onMenuClick, board_id, ...props }) => {
  const [draggin, setDragging] = useState(false);
  const [task, setTask] = useState();
  let { current_task, setCurrentTask } = useContext(BoardContext);
  useEffect(() => {
    if (props.task) setTask(props.task);
  }, [props.task]);
  useEffect(() => {
    if (current_task && task) {
      if (task._id === current_task._id && !_.isEqual(current_task, task)) {
        setTask({ ...task, ...current_task });
      }
    }
  }, [current_task]);
  if (!task) return null;
  return (
    <Draggable
      draggableId={id}
      index={props.index}
      isDragDisabled={props.isDragDisabled}
    >
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          onMouseLeave={(event) => console.log("Mouse Up!")}
          isDragDisabled={props.isDragDisabled}
          onDrag={(event) => console.log(event)}
        >
          <TaskCard
            task={task}
            board_id={board_id}
            id={id}
            onMenuClick={onMenuClick}
          />
        </Container>
      )}
    </Draggable>
  );
};
export default Task;
