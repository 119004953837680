import React, { useEffect, useState } from "react";
import { WrapperForm, ListItemContainer } from "./Styles";
import { DinamicForm } from "../com/form/";
import { HeadLine } from "../Styles";
import { Button, Row, Col, List, message, Card, Avatar, Icon } from "antd";
import { getService } from "../../services/";
import { useSelector } from "react-redux";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
import CreateBoard from "./CreateBoard";
import { GoProject } from "react-icons/go";
import { GrCircleInformation } from "react-icons/gr";
const { Item } = List;
const { Meta } = Card;
const columns_template = [
  {
    id: "todo",
    title: "To do",
    taskIds: [],
  },
  {
    id: "in-progress",
    title: "In Progress",
    taskIds: [],
  },
  {
    id: "to-validate",
    title: "To Validate",
    taskIds: [],
  },
  {
    id: "done",
    title: "Done",
    taskIds: [],
  },
];
const BoardForm = ({ board_type, onChangeTemplate, ...props }) => {
  const [columns, setColumns] = useState(columns_template);
  const [boards, setBoards] = useState([]);
  const [keep_tasks, setKeepTasks] = useState(true);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(props.view);
  const [template, setTemplate] = useState({});
  const user = useSelector(({ user }) => user);
  const onSubmit = (err, data) => {
    console.log(err, data);
    if (props.onSubmit) props.onSubmit(err, data);
  };
  const getData = () => {
    const service = getService("boards");
    let { company_id } = user;
    setLoading(true);
    service
      .find({
        query: {
          board_type: "template",
          status: "active",
          company_id,
          type: "all",
          $or: [
            {
              user_id: user._id,
            },
            {
              members_ids: { $in: [user._id] },
            },
          ],
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setBoards(data);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };
  const renderItem = (item, index) => {
    let { author, background } = item;
    return (
      <Item key={index}>
        <Card
          className={`${template._id === item._id ? "current-item" : ""}`}
          onClick={() => {
            setTemplate(item);
          }}
          hoverable
          size="small"
        >
          <Meta
            avatar={
              <>
                <GoProject color={background || "var(--color-primary)"} />
              </>
            }
            title={item.name}
            description={
              <div className="description-text">
                {author && (
                  <div className="author-name">
                    <span
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Author:{" "}
                    </span>
                    <span>
                      {(author && author.first_name) || ""}{" "}
                      {(author && author.last_name) || ""}
                    </span>
                  </div>
                )}
              </div>
            }
          />
        </Card>
      </Item>
    );
  };
  useEffect(() => {
    if (user) getData();
  }, [user]);
  useEffect(() => {
    if (template) onChangeTemplate(template);
  }, [template]);
  useEffect(() => {
    return () => {
      setBoards([]);
      setTemplate(null);
    };
  }, []);
  return (
    <WrapperForm>
      {user && (
        <>
          {view === "board" ? (
            <CreateBoard
              board_type={ board_type}
              name={template.name ? `${template.name} - Copy` : null}
              template_id={template._id}
              onSubmit={onSubmit}
              source="boards"
              /*  autoSubmit={false} */
              header={
                view === "template" &&
                view === "board" && (
                  <Row
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                    type="flex"
                    justify="start"
                    align="middle"
                  >
                    <Col>
                      {template.name && <h2>{template.name.capitalize()} </h2>}
                      {
                        <Button
                          type="link"
                          icon="arrow-left"
                          onClick={() => setView("template")}
                        >
                          Back to templates
                        </Button>
                      }
                    </Col>
                  </Row>
                )
              }
              fields={[
                {
                  xtype: "hidden",
                  name: "template_id",
                  initial: template._id,
                },
                {
                  xtype: "hidden",
                  name: "board_type",
                  initial: template._id ? "board" : board_type,
                },
                {
                  name: "name",
                  label: "Title",
                  flex: 1,
                  initial: template.name ? `${template.name} - Copy` : null,
                  placeholder: "Add board title",
                  validations: [{ required: true }],
                },
                board_type === "board" && {
                  xtype: "selectfield",
                  reference: "teams",
                  name: "team_id",
                  label: "Team",
                  flex: 1,
                  placeholder: "Select a Team",
                },
                {
                  xtype: template._id ? "checkbox" : "hidden",
                  name: "keep_tasks",
                  text: "Keep Tasks",
                  flex: 1,
                  onChange: () => setKeepTasks((checked) => !checked),
                  checked: keep_tasks,
                  initial: true,
                  placeholder: "Keep Tasks",
                },
              ]}
            />
          ) : (
            <ListItemContainer background={template && template.background}>
             {/*  <div className="head-container">
                <p>Select a template to create a project from template</p>
              </div> */}
              <List
                style={{
                  maxHeight: 400,
                  overflowY: "auto",
                }}
                grid={{ gutter: 8, xs: 1, md: 2, xl: 4, lg: 4, xxl: 4 }}
                loading={loading}
                renderItem={renderItem}
                dataSource={boards}
              />
              <Row
                style={{
                  marginBottom: 10,
                }}
                type="flex"
                justify="center"
                align="middle"
              >
                <Col>
                  <Button
                    disabled={!template._id}
                    size="large"
                    type="primary"
                    onClick={() => setView("board")}
                  >
                    {!template._id ? "Continue" : "Continue"}
                    <Icon type="arrow-right" />
                  </Button>
                </Col>
              </Row>
            </ListItemContainer>
          )}
        </>
      )}
    </WrapperForm>
  );
};
export default BoardForm;
