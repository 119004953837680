import React from "react";
import { Button } from "antd";
import { LeftOutlined } from "../../../icons/";
import { Wrapper, WrapperHeadLine } from "./Styles";


export const HeadLine = ({
  onClick,
  className,
  title,
  goback = true,
  ...props
}) => {
  const handleOnclick = () => {
    if (onClick) onClick();
  };
  return (
    <WrapperHeadLine className={className}>
      <div className="headline-container">
        {goback && (
          <div className="gooback">
            <Button
              onClick={handleOnclick}
              icon={<LeftOutlined />}
              type="link"
            />
          </div>
        )}
        <div className="title">
          <h4>{title}</h4>
        </div>
      </div>
    </WrapperHeadLine>
  );
};
const ToolBar = ({ children, onClick, goback, title, ...props }) => {
  return (
    <Wrapper>
      <div className="container">
        <HeadLine
          className={`${goback ? "goback" : "without-goback"}`}
          title={title}
          goback={goback}
          onClick={onClick}
        />
        <div className="tool-content">{children}</div>
      </div>
    </Wrapper>
  );
};
export default ToolBar;
