import React, { useState, useEffect } from "react";
import { Mentions, message } from "antd";
import { getService } from "../../services";
import _ from "lodash";
const { Option } = Mentions;
const Mention = ({
  source,
  defaultFilterValues,
  optionText = "name",
  optionValue = "_id",
  members_ids = [],
  ...props
}) => {
  const [dataSource, setDataSource] = useState(props.dataSource || []);
  const [options, setOptions] = useState(props.options || []);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const service = getService(source);

  const getOptions = dataSource => {
    let options = dataSource.map(record => (
      <Option
        key={record[optionValue || "_id"]}
        /* value={record[optionValue]} */
        value={
          typeof optionText == "function"
            ? optionText(record)
            : record[optionText]
        }
      >
        {typeof optionText == "function"
          ? optionText(record)
          : record[optionText]}
      </Option>
    ));
    setOptions(options);
  };
  const getData = query => {
    if (service) {
      setLoading(true);
      service
        .find({
          query: {
            ...query,
            ...defaultFilterValues
          }
        })
        .then(({ data }) => {
          setDataSource(data);
          getOptions(data);
          setLoading(false);
        })
        .catch(err => {
          message.error(err.message);
          setLoading(false);
        });
    }
  };
  const handleOnSearch = value => {
    if (props.onSearch) {
      props.onSearch(value);
    }
    /* if (value && value != "@") {
      getData({
        search: value
      });
    }
    console.log("Search: ", value); */
  };
  const handleSearch = _.debounce(handleOnSearch, 800, { maxWait: 800 });
  useEffect(() => {
    if (!initialized) {
      getData({});
      setInitialized(true);
    }
  }, [dataSource]);
  /*  useEffect(() => {
    if (defaultFilterValues) getData();
  }, [defaultFilterValues]); */

  return (
    <div style={{ margin: "10px 0px" }}>
      <Mentions
        onSearch={handleSearch}
        onSelect={({ value }) => {
          if (props.onSearch) {
            props.onSearch(value);
          }
        }}
        loading={loading}
        style={{ width: "100%" }}
        {...props}
      >
        {options}
      </Mentions>
    </div>
  );
};
export default Mention;
