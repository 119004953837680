import React, { useState, useEffect } from "react";
import {
  List,
  Comment,
  Avatar,
  Popover,
  Popconfirm,
  Icon,
  Typography,
} from "antd";
import CommentBox from "./CommentBox";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
import MemberCard from "../board/MemberCard";
import { CommentWrapper, WrapperComments } from "./Styles";
import moment from "moment";

const { Title } = Typography;
const AvatarUser = ({ user, ...props }) => {
  if (!user) return null;
  return (
    user && (
      <Popover
        overlayClassName="popup"
        destroyTooltipOnHide={true}
        trigger="click"
        content={<MemberCard actions={false} {...user} />}
        title={props.title}
      >
        <Avatar
          style={{ color: "#f56a00", backgroundColor: "#EFECE9" }}
          src={`${URL_S3}/${user.picture}`}
        >
          {!user.picture
            ? `${user.first_name.substring(0, 1)}${user.last_name.substring(
                0,
                1
              )}`
            : user.email.substring(0, 1)}
        </Avatar>
      </Popover>
    )
  );
};
const CommentList = ({
  id,
  user,
  loading,
  onMentions,
  onRemove,
  onSubmit,
  ...props
}) => {
  const [comments = [], setComments] = useState([]);
  const handleSubmit = (field, value, id) => {
    console.log("Save: ", field, value, id);
    if (onSubmit) onSubmit(field, value, id);
  };

  useEffect(() => {
    if (props.comments) {
      setComments(props.comments);
    }
    return () => {};
  }, [props.comments]);
  return (
    <CommentWrapper>
      <List
        className="list-comments"
        rowKey={"_id"}
        loading={loading}
        dataSource={comments}
        /* header={`${comments.length} ${
          comments.length > 1 ? "comments" : "comment"
        }`} */
        itemLayout="horizontal"
        renderItem={(item, index) => {
          let value = item.message;
          if (item.mentions)
            item.mentions.forEach(({ _id, first_name, last_name, role }) => {
              value = value.replace(
                `@${_id}`,
                `@${first_name || ""} ${last_name || ""} ${
                  role !== "Agent" ? `(${role})` : ""
                }`
              );
            });
          return (
            <List.Item key={item._id}>
              <Comment
                avatar={
                  <AvatarUser
                    className="avatar-comments"
                    user={item.author}
                    {...item}
                  />
                }
                content={
                  <>
                    {item.author && (
                      <div className="section-autor-date">
                        <strong>
                          {item.author.first_name || ""}{" "}
                          {item.author.last_name || ""}
                        </strong>
                        <span>
                          {moment(item.createdAt).format("MM-DD-YYYY LT")}
                        </span>
                      </div>
                    )}
                    <CommentBox
                      id={id}
                      box
                      momentitem={item}
                      value={value}
                      author={item.author}
                      actions={{
                        edit: true,
                        delete: true,
                      }}
                      raw_value={item.message}
                      record={item}
                      onRemove={() => onRemove(item._id)}
                      onSubmit={handleSubmit}
                      onMentions={onMentions}
                    />
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    </CommentWrapper>
  );
};
const Comments = ({ id, onSubmit, onMentions, onRemove, ...props }) => {
  const user = useSelector(({ user }) => user);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (field, value, id) => {
    if (onSubmit) onSubmit(field, value, id);
  };
  const handleOnMentions = (mentions) => {
    if (onMentions) onMentions(mentions);
  };
  const handleRemove = (id) => {
    setComments(comments.filter((item) => item._id !== id));
    if (onRemove) onRemove(id);
    setLoading(true);
    let interval = setTimeout(function() {
      setLoading(false);
      clearTimeout(interval);
    }, 100);
  };
  useEffect(() => {
    if (props.comments) {
      setLoading(true);
      let interval = setTimeout(function() {
        setLoading(false);
        clearTimeout(interval);
      }, 100);
      setComments(props.comments);
    }
  }, [props.comments]);
  useEffect(() => {
    if (typeof props.loading != "undefined") setLoading(props.loading);
  }, [props.loading]);
  return (
    <WrapperComments className="comments-wrapper">
      {props.title && comments.length > 0 && (
        <h3>
          {props.icon && <Icon size="small" type={props.icon} />} {props.title}
        </h3>
      )}
      {comments.length > 0 && !loading && (
        <CommentList
          id={id}
          user={user}
          loading={loading}
          onSubmit={handleSubmit}
          onMentions={handleOnMentions}
          onRemove={handleRemove}
          comments={comments}
        />
      )}
    </WrapperComments>
  );
};
export default Comments;
