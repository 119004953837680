import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getService } from "../../../services";
import { useSelector } from "react-redux";

import { TaskWrapper, CalendarWrapper, EventWrapper } from "./Styles";
import { Icon, Button, Popover } from "antd";
import { MyModal } from "../../com";
import TaskForm from "../../board/TaskForm";

const format = "DD-MM-YYYY";
/* import "moment/locale/es";
moment.locale("es"); */
const localizer = momentLocalizer(moment);

const types = [
  "task",
  "opportunity",
  "project",
  "target",
  "quote",
  "property",
  "email",
  "bug",
  "project_task",
  "target",
  "case",
  "meeting",
  "online_meeting",
  "call",
  "contract",
  "invoice",
  "quote",
  "product",
];

const MyEvent = ({ event, ...props }) => {
  if (types.includes(event.type))
    return (
      <TaskWrapper>
        <Icon type="clock-circle" size="small" />{" "}
        <span>
          hola
          {event.title}
        </span>
      </TaskWrapper>
    );
  return (
    <>
      <Popover
        placement="topLeft"
        title={
          <>
            {event.first_name || ""} birthday 🎂{" "}
            {moment(event.birthday).format(format)}
          </>
        }
        content={
          <EventWrapper>
            <Icon type="calendar" size="small" />{" "}
            <span>
              <strong>
                {event.first_name || ""} {event.last_name || ""}
              </strong>{" "}
              birthday {moment(event.birthday).fromNow()}
            </span>
          </EventWrapper>
        }
        trigger="click"
      >
        <TaskWrapper>
          <Icon type="calendar" size="small" /> {event.first_name || ""}{" "}
          {event.last_name || ""}
        </TaskWrapper>
      </Popover>
    </>
  );
};
let components = {
  event: MyEvent, // used by each view (Month, Day, Week)
};
const MyCalendarDashboard = ({ ...props }) => {
  const user = useSelector(({ user }) => user);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [show, setShow] = useState(false);
  const [record, setRecord] = useState({});
  const [filterContacts, setFilterContacts] = useState();
  const [date, setDate] = useState(new Date());
  const handleEvent = (event) => {
    if (types.includes(event.type)) {
      setRecord(event);
      setShow(true);
    }
  };
  const getData = async () => {
    const taskService = getService("tasks");
    const contactService = getService("get-contact-birthdays");
    setLoading(true);
    const [tasks, contacts] = await Promise.all([
      taskService
        .find({
          query: {
            ...filterDefaultValues,
            $sort: {
              createdAt: -1,
            },
          },
        })
        .then(({ data }) => data),
      contactService
        .find({
          query: {
            ...filterContacts,
          },
        })
        .then(({ data }) => data),
    ]);
    setLoading(false);
    let data = [...tasks, ...contacts];
    data = data
      .filter((it) => typeof it != "undefined")
      .map((it) => {
        return {
          ...it,
          date: moment(
            it.birthday || it.start_date || it.date || it.createdAt
          ).toDate(),
          start: moment(it.birthday || it.start_date || it.createdAt).toDate(),
          end: moment(it.birthday || it.end_date || it.createdAt).toDate(),
          title: it.subject,
        };
      });
    setTasks(data);
    // setDataSource(data);
  };
  const handleOnChange = (date) => {
    setDate(date);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues, date]);
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues({
        ...filterDefaultValues,
        ...props.filterDefaultValues,
      });
  }, [props.filterDefaultValues]);
  return (
    <CalendarWrapper style={props.style}>
      <Calendar
        localizer={localizer}
        events={tasks}
        components={components}
        //defaultView="week"
        onSelectEvent={handleEvent}
        onNavigate={handleOnChange}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
      />
      <MyModal
        title="Edit Task"
        visible={show}
        width={"60%"}
        onCancel={() => {
          setShow(false);
          if (user && user._id) getData();
        }}
      >
        <TaskForm record={record} id={record._id} {...props} />
      </MyModal>
    </CalendarWrapper>
  );
};
export default MyCalendarDashboard;
