import React, { useState, useEffect, Suspense } from "react";
import { Row, List, Avatar, Col, Card, message, Skeleton, Button } from "antd";
import { Wrapper, WrapperListWidgets } from "./Styles";
import { navigate } from "@reach/router";
import { getService } from "../../services";
import SkeletonWidget from "./SkeletonWidget";
const { Meta } = Card;
const socials = {
  facebook: {
    text: "Facebook",
    name: "facebook",
    quantity: "15",
    colorText: "rgb(0 0 0 / 67%)",
    background: "#3B5998",
    icon: "/images/facebook.svg",
  },
  instagram: {
    text: "Instagram",
    name: "instagram",
    quantity: "15",
    colorText: "rgb(0 0 0 / 67%)",
    background: "rgb(232, 62, 140)",
    icon: "/images/instagram.svg",
  },
  twitter: {
    text: "Twitter",
    name: "twitter",
    quantity: "15",
    colorText: "rgb(0 0 0 / 67%)",
    background: "rgb(0 0 0 / 80%)",
    icon: "/images/social-x.png",
    // icon: "/images/twitter.svg",
  },
  linkedin: {
    text: "Linkedin",
    name: "linkedin",
    quantity: "15",
    colorText: "rgb(0 0 0 / 67%)",
    background: "#0077B7",
    icon: "/images/linkedin.svg",
  },
};

const SocialWidgetList = ({
  item,
  type = "full-color",
  refresh,
  filterDefaultValues,
  active,
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mounted, setMouted] = useState(false);
  const handleClick = (item) => {
    navigate(`/dashboard/social/${item.name}`);
  };
  const defaulRenderItem = (item) => {
    let key = Object.keys(item)[0];
    let social = socials[key];
    return (
      <WrapperListWidgets social={social}>
        <List.Item onClick={() => handleClick(social)} className="item-list">
          <Card
            className="container-cards"
            style={{
              border: `1px solid ${social.background}`,
            }}
            bordered={false}
          >
            <Meta
              avatar={
                <div
                  style={{
                    background:
                      type == "white-color" ? social.background : undefined,
                  }}
                  className="avatar-container"
                >
                  <Avatar
                    shape="circle"
                    src={social.icon}
                    style={{
                      background: social.background,
                    }}
                  />
                </div>
              }
              title={
                <div className="container-title-widgets">
                  <h4
                    style={{
                      color:
                        type == "full-color" ? social.colorText : "#535353",
                    }}
                  >
                    {item[key]}{" "}
                  </h4>
                  <p
                    style={{
                      color:
                        type == "full-color" ? social.colorText : "#535353",
                    }}
                  >
                    {`Post${item[key] > 1 ? "s" : ""}`}
                  </p>
                </div>
              }
            />
          </Card>
          {/* <Card
            className="container-cards"
            style={{
              // background: type == "full-color" ? social.background : undefined,
              border: `1px solid ${social.background}`,
            }}
            bordered={false}
          >
            <Meta
              avatar={
                <div
                  style={{
                    background:
                      type == "white-color" ? social.background : undefined,
                  }}
                  className="avatar-container"
                >
                  <Avatar
                    shape="circle"
                    src={social.icon}
                    style={{
                      background: social.background,
                    }}
                  />
                </div>
              }
              title={
                <div className="container-title-widgets">
                  <h4
                    style={{
                      color:
                        type == "full-color" ? social.colorText : "#535353",
                    }}
                  >
                    {item[key]}{" "}
                  </h4>
                  <p
                    style={{
                      color:
                        type == "full-color" ? social.colorText : "#535353",
                    }}
                  >
                    {`Post${item[key] > 1 ? "s" : ""}`}
                  </p>
                </div>
              }
            />
          </Card> */}
        </List.Item>
      </WrapperListWidgets>
    );
  };
  const getData = () => {
    const service = getService("posts-counts");
    setLoading(true);
    setMouted(true);
    service
      .find({
        query: {
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        setDataSource(data);
        setLoading(false);
        setMouted(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
        setMouted(false);
      });
  };
  useEffect(() => {
    if (filterDefaultValues || refresh) {
      getData();
    }
  }, [filterDefaultValues, refresh]);
  if (mounted)
    return <SkeletonWidget avatar count={4} width="200px" height="80px" />;
  return (
    <Wrapper className={type}>
      <div>
        <List
          // loading={loading}
          grid={{
            xl: 4,
            xxl: 4,
            lg: 3,
            md: active === "boards" ? 4 : 2,
            sm: 2,
            xs: 1,
            gutter: 8,
          }}
          itemLayout={props.itemLayout || "horizontal"}
          dataSource={dataSource}
          renderItem={props.renderItem || defaulRenderItem}
        />
      </div>
    </Wrapper>
  );
};
export default SocialWidgetList;
