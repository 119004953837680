import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  Input,
  List,
  Button,
  Icon,
  message,
  Skeleton,
  Menu,
  Dropdown,
  Progress,
  Modal,
} from "antd";
import { getService } from "../../services";
import {
  WrapperTaskCheckList,
  WrapperCheckDrop,
  WrapperEditor,
  WrapperMenuItem,
} from "./Styles";
import moment from "moment";
import { FiSend } from "react-icons/fi";
import { CgCloseO } from "react-icons/cg";
const { confirm } = Modal;
const ActionMenu = ({ onSelect, record, task_id, ...props }) => {
  const handleMenuClick = ({ key }) => {
    if (onSelect) onSelect(key, record);
  };
  return (
    <WrapperMenuItem>
      <Menu className="container-menu-actions" onClick={handleMenuClick}>
        <Menu.Item key="edit">
          <Icon type="edit" /> <span>Edit</span>
        </Menu.Item>
        <Menu.Item key="remove">
          <Icon type="delete" className="btn-color-red" />
          <span>Remove</span>
        </Menu.Item>
      </Menu>
    </WrapperMenuItem>
  );
};
const TaskEditor = ({
  data,
  task_id,
  onAdd,
  onSubmit,
  onClose,
  reference,
  onChange,
  checkeds,
  ...props
}) => {
  const [value, setValue] = useState();
  const [submiting, setSubmiting] = useState(false);
  const [add, setAdd] = useState();
  const [task, setTask] = useState();
  const handleSave = () => {
    if (!value) return;
    if (data && data.length === 10)
      return message.warning(
        <div className="flex justify-start align-center">
          <span>
            Maximum subtasks per section is 10.
            <br />
            To add more subtasks, please create
            <br /> a new section.
          </span>
        </div>
      );
    /* setAdd(false); */
    if (reference) {
      const service = getService(reference);
      setSubmiting(true);
      if (!task)
        return service
          .create({
            subject: value,
            task_id: task_id ? task_id : undefined,
          })
          .then((res) => {
            message.success("Subtask created successfully!");
            setSubmiting(false);
            setAdd(false);
            if (task) setTask(res);
            if (onSubmit) onSubmit();
          })
          .catch((err) => {
            setSubmiting(false);
            setAdd(false);
            setValue(null);
            message.error(err.message);
          });
      return service
        .patch(task._id, {
          subject: value,
          task_id: task_id ? task_id : undefined,
          updatedAt: new Date(),
        })
        .then((task) => {
          message.success("Subtask Updated!");
          setSubmiting(false);
          setAdd(false);
          setValue(null);
          setTask(task);
          if (onSubmit) onSubmit();
        })
        .catch((err) => {
          setSubmiting(false);
          message.error(err.message);
        });
    }
  };
  const handleShowAdd = () => {
    if (task && !add) {
      setAdd(true);
    }
  };
  const handleCheck = (e) => {
    if (onChange) onChange(e);
  };
  useEffect(() => {
    if (onAdd) onAdd(add);
  }, [add]);
  useEffect(() => {
    if (typeof props.edit !== "undefined");
    setAdd(props.edit);
  }, [props.edit]);
  useEffect(() => {
    setTask(props.task);
    // console.log("dataSource && dataSource.length > 0",props.task)
  }, [props.task]);
  return (
    <WrapperEditor>
      <div className="task-editor">
        <div className={`footer-list ${task ? "edit-task" : "new-task"}`}>
          <div className="subject-title" onClick={handleShowAdd}>
            {add && (
              <div className="section-input-text">
                <input
                  className="input-editor"
                  onChange={(e) => setValue(e.target.value)}
                  defaultValue={task && task.subject}
                  placeholder="Subtask Title"
                />
                <div className="section-btn">
                  <Button
                    className="btn-save-task"
                    loading={submiting}
                    onClick={handleSave}
                    type="link"
                  >
                    {!submiting && <FiSend />}
                  </Button>
                  <Button
                    onClick={() => {
                      if (onClose) onClose();
                      setAdd(false);
                    }}
                    type="link"
                  >
                    <CgCloseO />
                  </Button>
                </div>
              </div>
            )}
            {task && !add && (
              <div className="section-date-update">
                <span
                  className={`${
                    task && task.status === "completed"
                      ? "sub-task-completed"
                      : "sub-task-pending"
                  }`}
                >
                  {task && task.subject}
                </span>
                <div className="date-task">
                  <span>
                    Created:{" "}
                    {moment(task && task.createdAt).format("MM-DD-YYYY  LT")}
                  </span>
                  {task && task.updatedAt ? (
                    <span>
                      Mod:{" "}
                      {moment(task && task.updatedAt).format("MM-DD-YYYY  LT")}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!add && !task && (
          <div className="add-task-section">
            <Button
              className="btn-add-task"
              style={{
                float: "right",
              }}
              onClick={() => setAdd(true)}
              size="large"
              type="primary"
            >
              Add Subtask
            </Button>
          </div>
        )}
      </div>
    </WrapperEditor>
  );
};
const TaskCheckList = ({
  task,
  onRemove,
  filterDefaultValues,
  reference,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [progress, setProgress] = useState(0);
  const [edit, setEdit] = useState(false);
  const [item_selected, setItemSelected] = useState();
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState();
  const [adding, setAdding] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [checkeds, setCheckeds] = useState([]);
  const [select, setSelect] = useState();
  const [dataSource, setDataSource] = useState([]);
  let myRef = useRef(null);
  const getData = () => {
    if (reference) {
      setLoading(true);
      const service = getService(reference);
      service
        .find({
          query: {
            ...filterDefaultValues,
            task_id: task._id,
          },
        })
        .then(({ data }) => {
          setLoading(false);
          setItemSelected(null);
          setAdd(data.length === 0);
          setEdit(false);
          let checkeds = data.filter((it) => it.status === "completed");
          setCheckeds(checkeds.map((it) => it._id));
          let progress = 0;
          progress = Math.floor((checkeds.length / data.length) * 100);
          setProgress(progress);
          setDataSource(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const removeItem = (id) => {
    if (reference) {
      setSubmiting(true);
      const service = getService(reference);
      service
        .remove(id)
        .then((res) => {
          message.success("Subtask deleted successfully!");
          setSubmiting(false);
          getData();
        })
        .catch((err) => {
          setSubmiting(false);
          message.error(err.message);
        });
    }
  };
  const handleMenuClick = (key, item) => {
    if (!item) return;
    switch (key) {
      case "edit":
        setItemSelected(item);
        setVisible(true);
        break;
      case "remove":
        removeItem(item._id);
        break;
      default:
        break;
    }
  };
  const handleCheck = (e, item) => {
    let { checked } = e.target;
    setSelect(checked);
    // console.log("::::::::::::::::::checked:", checked);
    setCheckeds((checkeds) => {
      let new_checkeds = checkeds;
      if (checked) return [...new_checkeds, item._id];
      new_checkeds = new_checkeds.filter((it) => it !== item._id);
      return new_checkeds;
    });
    if (reference) {
      const service = getService(reference);
      service
        .patch(item._id, {
          status: checked ? "completed" : "pending",
          updatedAt: new Date(),
        })
        .then((data) => {
          getData();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  const renderItem = (record, index) => {
    return (
      <WrapperCheckDrop>
        <List.Item
          actions={
            !adding && [
              <Dropdown
                /* visible={open == record._id} */
                overlayClassName="menu-actions menu-dropdown-task"
                /* placement="bottomRight" */
                overlayStyle={{
                  width: 200,
                }}
                /* onBlur={() => {
                        setOpen(open => open == record._id ? null : open);
                    }} */
                overlay={
                  <ActionMenu
                    task_id={record.id}
                    record={record}
                    onSelect={(key, item) => {
                      handleMenuClick(key, item);
                      setOpen(null);
                    }}
                  />
                }
                trigger={["click", "hover"]}
              >
                <Button
                  className="btn-more-link"
                  onClick={() => {
                    setOpen((open) =>
                      open !== record._id ? record._id : null
                    );
                  }}
                  type="link"
                  size="large"
                  icon="more"
                />
              </Dropdown>,
            ]
          }
        >
          <Skeleton avatar title={false} loading={loading} active>
            <List.Item.Meta
              avatar={
                !adding && (
                  <div className="task-checked">
                    <Checkbox
                      defaultChecked={checkeds.includes(record._id)}
                      onClick={(e) => {
                        handleCheck(e, record);
                      }}
                    />
                  </div>
                )
              }
              title={
                <div className="task-subject">
                  <TaskEditor
                    onAdd={(add) => {
                      setAdding(add);
                    }}
                    onClose={() => {
                      setItemSelected(null);
                      setVisible(false);
                    }}
                    edit={item_selected && item_selected._id === record._id}
                    task={record}
                    task_id={task._id}
                    reference={reference}
                    onSubmit={getData}
                  />
                </div>
              }
            />
          </Skeleton>
        </List.Item>
      </WrapperCheckDrop>
    );
  };
  const handleOnRemove = (id) => {
    if (reference) {
      setSubmiting(true);
      const service = getService(reference);
      service
        .remove(id)
        .then((res) => {
          message.success("Task removed successfully!");
          if (onRemove) onRemove(id);
          setSubmiting(false);
          getData();
        })
        .catch((err) => {
          setSubmiting(false);
        });
    }
  };
  const handleRemove = (id) => {
    confirm({
      content: (
        <div>
          This file will be permanently removed if you delete it. Please confirm
          if you wish to proceed.
        </div>
      ),
      onOk() {
        handleOnRemove(id);
      },
      onCancel() {},
    });
  };
  useEffect(() => {
    if (props.dataSource) setDataSource(props.dataSource);
  }, [props.dataSource]);
  useEffect(() => {
    if (task) getData();
  }, [task]);
  useEffect(() => {
    if (myRef && myRef.current) myRef.current.focus();
  }, [myRef]);
  return (
    <WrapperTaskCheckList edit={edit}>
      <div className="head-check-list">
        <div
          className="head-container"
          onClick={() => {
            if (!edit) setEdit(true);
          }}
        >
          <div className="title">
            <Icon type="check-square" />
            <TaskEditor
              onClose={() => setEdit(false)}
              task={task}
              reference={reference}
              onSubmit={getData}
            />
          </div>
          {!edit && (
            <div className="action-lists">
              <Button
                className="btn-removed-red"
                onClick={() => handleRemove(task._id)}
                type="link"
              >
                <Icon type="delete" />
              </Button>
            </div>
          )}
        </div>
      </div>
      {
        <Progress
          className={`progress-${
            progress === 100 ? "completed" : "incompleted"
          }`}
          percent={progress}
        />
      }
      {dataSource && dataSource.length > 0 && (
        <List
          loading={loading}
          dataSource={dataSource}
          itemLayout="horizontal"
          renderItem={renderItem}
        />
      )}
      {
        <div
          className={`footer-list-bottom ${task ? "edit-task" : "new-task"}`}
        >
          <TaskEditor
            task_id={task && task._id}
            reference={reference}
            data={dataSource}
            onSubmit={getData}
          />
        </div>
      }
    </WrapperTaskCheckList>
  );
};
export default TaskCheckList;
