import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  Row,
  Col,
  Tooltip,
  Button,
  Modal,
  Divider,
  Icon,
} from "antd";
import { Wrapper, SearchBox, RoundedButton } from "./Styles";
import { exportToCsv, exportTableToPdf } from "../../../utils/Helper";
import { FileUploader } from "../";
import { getService } from "../../../services/";
import { Route, BrowserRouter } from "react-router-dom";
import { Link, navigate } from "@reach/router";
import { MyModal } from "../MyModal";
import Box from "../box/Box";
import uuid from "react-uuid";
import qs from "qs";
import { URL_BASE_API } from "../../../constants/";
import { Empty } from "../loader";
import { SearchField } from "../form/";
import _ from "lodash";
import SkeletonTable from "./SkeletonTable";
const Loader = () => <div>Loading...</div>;
const { confirm } = Modal;
const LIMIT = 20;
const defaultState = {
  pagination: {
    showSizeChanger: true,
    defaultCurrent: 1,
    current: 1,
    showTotal: (total) => {
      return `Total Records ${total}`;
    },
    defaultPageSize: LIMIT,
    pageSize: LIMIT,
    pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
  },
  actions: {
    edit: true,
    create: true,
    delete: true,
  },
};
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
const Grid = ({
  source,
  title,
  reference,
  newtitle = "Add New",
  mode = "default",
  report = {},
  initialValues = {},
  custom = false,
  edit,
  create,
  actions = defaultState.actions,
  columns,
  searchText,
  search = true,
  extra,
  extraFilters,
  exportCsv = true,
  export_Csv = true,
  importCsv = true,
  model,
  path,
  autoLoad = true,
  stripRow = true,
  refreshBotton = false,
  count,
  ...props
}) => {
  const idComponent = uuid();
  const myRef = useRef();
  const [pagination, setPagination] = useState(defaultState.pagination);
  const [filters, setFilters] = useState({});
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [sorter, setSorter] = useState(props.$sort || {});
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(props.loading || false);
  const [refresh, setRefresh] = useState(false);
  const [exporting, setExport] = useState(false);
  const [exporting_csv, setExportCsv] = useState(false);
  const [visible, setVisible] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [record, setRecord] = useState();
  const itemLayout = {
    gridCol: {
      xl: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 },
    },
    formCol: {
      xl: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 },
    },
  };
  const handleDelete = (id, record) => {
    if (!source && props.onDelete) {
      props.onDelete(id, record);
    }
    if (source && id) {
      confirm({
        content: (
          <div>
            <span>
              This file will be permanently removed if you delete it. Please
              confirm if you wish to proceed.
            </span>
          </div>
        ),
        onOk() {
          const service = getService(source);
          service
            .remove(id)
            .then((response) => {
              message.info("Record Deleted");
              getData();
            })
            .catch((err) => message.error(err.message));
        },
        onCancel() {},
      });
    }
  };
  const handleActions = ({ name, path }) => {
    if (name) {
      return navigate(
        `/${props.basePath || "dashboard"}${path || "/" + source}/${name}`
      );
    }
    if (path) {
      return navigate(`/${props.basePath || "dashboard"}${path}`);
    }
  };
  const handleSearch = (field, value, record) => {
    setFilterDefaultValues({
      ...filterDefaultValues,
      [field]: value,
    });
  };
  const handleOnChange = (paginator, new_filters, sorter) => {
    if (!_.isEqual(paginator, pagination)) {
      let { pageSize, current } = paginator;
      setFilterDefaultValues({
        ...filterDefaultValues,
        $limit: pageSize,
        $skip: (current - 1) * pageSize,
      });
      setPagination({
        ...pagination,
        pageSize,
        current,
      });
    } else if (sorter.field) {
      setFilterDefaultValues({
        ...filterDefaultValues,
        $sort: { [sorter.field]: sorter.order === "ascend" ? 1 : -1 },
      });
    }
  };

  const onChange = (field, value) => {
    if (props.onChange) return props.onChange(field, value);
    setFilterDefaultValues({
      ...filterDefaultValues,
      [field]: value,
    });
  };
  const handleExportCsv = () => {
    setExportCsv(true);
    let params = {
      query: {
        $sort: sorter,
        ...filters,
        ...filterDefaultValues,
        status: "template",
        $limit: 1,
      },
    };
    if (source) {
      const service = getService("export-csv");
      let headers = report.columns || columns || [];
      service
        .create({
          report: {
            name: props.reportName,
            service: source,
            columns: headers.filter((col) =>
              typeof col.export != "undefined" ? col.export : true
            ),
            ...report,
          },
          ...params,
        })
        .then(({ url, fileName }) => {
          let query = { url, fileName };
          fetch(`${URL_BASE_API}/signed-url?${qs.stringify(query)}`, {
            method: "GET",
            headers: new Headers({
              Authorization: localStorage.getItem("feathers-jwt"),
            }),
          })
            .then((response) => response.blob())
            .then((blob) => {
              setExportCsv(false);
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
            });
        });
    }
  };
  const handleExport = () => {
    setExport(true);
    let params = {
      query: {
        $sort: sorter,
        $limit: 500000,
        ...filters,
        ...filterDefaultValues,
      },
    };
    if (source) {
      const service = getService("export-excel");
      let headers = report.columns || columns || [];
      service
        .create({
          report: {
            name: props.reportName,
            service: source,
            columns: headers.filter((col) =>
              typeof col.export != "undefined" ? col.export : true
            ),
            ...report,
          },
          ...params,
        })
        .then(({ url, fileName }) => {
          let query = { url, fileName };
          fetch(`${URL_BASE_API}/signed-url?${qs.stringify(query)}`, {
            method: "GET",
            headers: new Headers({
              Authorization: localStorage.getItem("feathers-jwt"),
            }),
          })
            .then((response) => response.blob())
            .then((blob) => {
              setExport(false);
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
            });
        });
    }
  };
  const getData = async () => {
    let total = 0;
    try {
      const service = getService(reference || source);
      setLoading(true);
      let response = await service.find({
        query: {
          $limit: LIMIT,
          $skip: 0,
          $sort: { createdAt: -1 },
          ...filterDefaultValues,
        },
      });
      if (Array.isArray(response)) {
        total = response.length;
        setDataSource(response);
      } else if (response.data) {
        let { data, meta } = response;
        total = (meta && meta.TotalRecords) || response.total;
        setDataSource(data);
      }
      setPagination({
        ...pagination,
        total,
        current: pagination.current || 1,
      });
      setLoading(false);
      setInitialized(true);
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleOnSubmit = (err, data) => {
    if (!err && !record) setRecord(data);
    getData();
  };
  useEffect(() => {
    if (props.dataSource) {
      setLoading(false);
      setDataSource(props.dataSource);
    }
  }, [props.dataSource]);

  useEffect(() => {
    if (!_.isEqual(props.defaultFilterValues, filterDefaultValues)) {
      console.log("props.defaultFilterValues", props.defaultFilterValues);
      setFilterDefaultValues(props.defaultFilterValues);
    }
  }, [props.defaultFilterValues]);
  useEffect(() => {
    if (
      props.filterDefaultValues &&
      !_.isEqual(props.filterDefaultValues, filterDefaultValues)
    )
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);

  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (typeof refresh != "undefined" && filterDefaultValues && initialized)
      getData();
  }, [refresh]);
  useEffect(() => {
    if (typeof props.refresh != "undefined") setRefresh(props.refresh);
  }, [props.refresh]);
  // if (loading) return <SkeletonTable count={count} />;
  return (
    <Wrapper>
      <Empty>
        <Row gutter={4}>
          {visible && mode === "inner" && (
            <Col id={idComponent} {...itemLayout.formCol} span={24}>
              <div ref={myRef}>
                {record && record[props.rowKey || "_id"] && edit
                  ? React.cloneElement(edit, {
                      id: record[props.rowKey || "_id"],
                      initialValues,
                      redirect: false,
                      record,
                      successfullyCreatedText: props.successfullyCreatedText,
                      successfullyUpdatedText: props.successfullyUpdatedText,
                      onSubmit: handleOnSubmit,
                    })
                  : React.cloneElement(create, {
                      redirect: false,
                      initialValues,
                      successfullyCreatedText: props.successfullyCreatedText,
                      successfullyUpdatedText: props.successfullyUpdatedText,
                      onSubmit: handleOnSubmit,
                    })}
              </div>
            </Col>
          )}
          <Col {...itemLayout.gridCol} span={24}>
            <div show title={props.listTitle || title || undefined}>
              <Row
                className="grid-tools-container"
                style={{
                  margin: 2,
                }}
                gutter={16}
                type="flex"
                justify="space-between"
                align="middle"
              >
                <Col>
                  <Row
                    className="grid-tool-bar"
                    type="flex"
                    align="middle"
                    gutter={16}
                  >
                    {title && (
                      <Col className="grid-title">
                        <h3>{title}</h3>
                      </Col>
                    )}
                    {search && (
                      <Col>
                        <SearchField
                          className="section-search"
                          size="large"
                          mode="multiple"
                          /* source={source} */
                          defaultFilterValues={{
                            ...initialValues,
                            ...filterDefaultValues,
                          }}
                          name={props.searchOptionValue || "_id"}
                          placeholder={searchText || "Search..."}
                          /* onSelectItem={handleSearch} */
                          onChange={handleSearch}
                          allowClear
                          optionText={props.searchOptionText || "name"}
                        />
                      </Col>
                    )}
                    {props.filters && (
                      <Col>
                        {React.Children.map(
                          props.filters.props.children,
                          (it, index) => {
                            let { name } = it.props;
                            return (
                              <Col key={index}>
                                {React.cloneElement(it, {
                                  onChange: (e, value) => {
                                    value =
                                      e && e.target ? e.target.value : value;
                                    onChange(
                                      name || "field-" + index,
                                      value || e
                                    );
                                  },
                                })}
                              </Col>
                            );
                          }
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col className={"extra-container"}>
                  {extraFilters}
                  {export_Csv && (
                    <Tooltip
                      className="btn-inline"
                      placement="bottom"
                      title="Export Template Example"
                    >
                      <RoundedButton
                        className="section-roundedbutton"
                        loading={exporting_csv}
                        style={{
                          background: "#bfbfbf",
                          border: "1px solid #bfbfbf",
                        }}
                        icon="file-excel"
                        type="primary"
                        onClick={handleExportCsv}
                      />
                    </Tooltip>
                  )}
                  {exportCsv && (
                    <Tooltip
                      className="btn-inline"
                      placement="bottom"
                      title="Export Excel"
                    >
                      <RoundedButton
                        className="section-roundedbutton"
                        loading={exporting}
                        style={{
                          background: "#6c757dfc",
                          border: "1px solid #6c757dfc",
                        }}
                        icon="file-excel"
                        type="primary"
                        onClick={handleExport}
                      />
                    </Tooltip>
                  )}

                  {importCsv && model && (
                    <Tooltip
                      className="btn-inline"
                      placement="bottom"
                      title="Upload Csv"
                    >
                      <FileUploader
                        {...model}
                        onSubmit={getData}
                        loading={exporting}
                      />
                    </Tooltip>
                  )}
                  {typeof actions == "boolean"
                    ? actions
                    : create &&
                      actions.create && (
                        <Tooltip
                          className="btn-inline"
                          placement="bottom"
                          title={
                            props.addNewText ||
                            (!visible ? `${newtitle}` : "Cancel")
                          }
                        >
                          <RoundedButton
                            className="section-roundedbutton-plus"
                            icon={!visible ? "plus" : "close"}
                            type={!visible ? "primary" : "danger"}
                            onClick={() => {
                              setRecord(null);
                              setVisible((visible) => !visible);
                              /* window.scrollTo(0, myRef.current ? myRef.current.offsetTop : 0) */
                              /* window.location.hash = idComponent; */
                              if (mode === "default")
                                navigate(
                                  `/${props.basePath || "dashboard"}${path ||
                                    "/" + source}/create`,
                                  { replace: true }
                                );
                            }}
                          />
                        </Tooltip>
                      )}
                  {extra}
                  {refreshBotton && (
                    <Tooltip
                      className="btn-inline"
                      placement="bottom"
                      title="Refresh"
                    >
                      <Button
                        shape="circle"
                        icon="reload"
                        type="link"
                        onClick={() => getData()}
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
              {!loading ? (
                <Table
                  size="small"
                  childrenColumnName={props.childrenColumnName || "childrens"}
                  onChange={handleOnChange}
                  pagination={pagination}
                  rowKey={props.rowKey || "_id"}
                  loading={loading}
                  className={props.className}
                  showHeader={props.showHeader}
                  rowClassName={(record, index) => {
                    return stripRow && index % 2 !== 0 ? "row-strip" : "";
                  }}
                  columns={[
                    ...columns
                      .map((col) => ({
                        ...col,
                        width: col.width || 100,
                        align: col.align || "left",
                        display:
                          typeof col.display != "undefined"
                            ? col.display
                            : true,
                      }))
                      .filter((item) => item.display),

                    actions.show ||
                    actions.edit ||
                    actions.delete ||
                    actions.extra
                      ? {
                          type: "actions",
                          width: !actions.extra ? 100 : 150,
                          title: "Actions",
                          /* align: "center", */
                          fixed: props.fixed
                            ? typeof props.fixed == "boolean"
                              ? props.fixed
                                ? "right"
                                : false
                              : props.fixed
                            : "right",
                          render: (record) => {
                            return (
                              <Row
                                className="container-actions-grid"
                                type="flex"
                                justify="center"
                                align="middle"
                                gutter={4}
                              >
                                {edit && actions.edit && (
                                  <Col>
                                    <Tooltip
                                      className="btn-inline"
                                      placement="bottom"
                                      title="Edit"
                                    >
                                      <Button
                                        type="link"
                                        icon="edit"
                                        onClick={() => {
                                          setRecord(record);
                                          setVisible(true);
                                          if (props.onChange)
                                            props.onChange(record);
                                          if (mode === "default")
                                            navigate(
                                              `/${props.basePath ||
                                                "dashboard"}${path ||
                                                "/" + source}/${
                                                record[props.rowKey || "_id"]
                                              }`
                                            );
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                )}
                                {actions.delete && (
                                  <Col>
                                    <Tooltip
                                      className="btn-inline"
                                      placement="bottom"
                                      title="Delete"
                                    >
                                      <Button
                                        onClick={() =>
                                          handleDelete(
                                            record[props.rowKey || "_id"]
                                          )
                                        }
                                        type="link"
                                      >
                                        <Icon
                                          type="delete"
                                          theme="twoTone"
                                          twoToneColor="#eb2f96"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                )}
                                {actions.show && (
                                  <Col>
                                    <Tooltip
                                      className="btn-inline"
                                      placement="bottom"
                                      title="Show"
                                    >
                                      <Button
                                        onClick={() => {
                                          return navigate(
                                            `/${props.basePath || "dashboard"}${
                                              path
                                                ? path + "/show"
                                                : "/show" + source
                                            }/${record[props.rowKey || "_id"]}`
                                          );
                                        }}
                                        type="link"
                                      >
                                        <Icon
                                          type="eye"
                                          theme="twoTone"
                                          twoToneColor="#6610f2"
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                )}
                                {actions.extra && Array.isArray(actions.extra)
                                  ? actions.extra.map((item) => {
                                      return (
                                        <Col>
                                          <Button
                                            icon={item.icon}
                                            onClick={() =>
                                              handleActions(record)
                                            }
                                            type="dashed"
                                            shape="circle"
                                            {...item}
                                          />
                                        </Col>
                                      );
                                    })
                                  : actions.extra &&
                                    actions.extra.props &&
                                    React.Children.map(
                                      actions.extra.props.children,
                                      (child) => {
                                        let { render } = child.props;
                                        return (
                                          <Col>
                                            {render
                                              ? render(record)
                                              : React.cloneElement(child, {
                                                  record,
                                                  onClick: () => {
                                                    if (child.props.onClick) {
                                                      child.props.onClick(
                                                        record
                                                      );
                                                    }
                                                  },
                                                })}
                                          </Col>
                                        );
                                      }
                                    )}
                              </Row>
                            );
                          },
                        }
                      : undefined,
                  ].filter((it) => {
                    if (typeof it == "undefined") {
                      return false;
                    }
                    if (it.type === "actions" && typeof actions == "boolean") {
                      return actions;
                    }
                    return typeof it != "undefined";
                  })}
                  scroll={props.scroll || { x: 1500, y: "auto" }}
                  dataSource={dataSource}
                />
              ) : (
                <SkeletonTable count={count} />
              )}
            </div>
          </Col>
        </Row>
        {
          <MyModal
            title={props.id !== "create" ? "Edit" : "Create"}
            closabled
            onCancel={() => setVisible(false)}
            visible={visible && mode === "modal"}
          >
            {props.id !== "create" && edit
              ? React.cloneElement(edit, {
                  id: props.id,
                })
              : create}
          </MyModal>
        }
      </Empty>
    </Wrapper>
  );
};
export default Grid;
