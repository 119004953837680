import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";

import { PlanCard } from "./PlanCard";
import { Button, Col, List, Row, message } from "antd";
import { getService } from "../../services";
import getPlacements from "antd/lib/tooltip/placements";
import { useSelector } from "react-redux";
import { MyModal } from "../com";
import PaymentForm from "./PaymentForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import PlanDetail from "./PlanDetail";
import { navigate } from "@reach/router";
import PlanProfile from "../plan-profile/PlanProfile";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.addEventListener("load", () => {
      resolve({ successful: true });
    });
    script.addEventListener("error", (event) => {
      reject({ error: event });
    });
    document.head.appendChild(script);
  });
export const PaymentMethod = ({ ...props }) => {
  const servicePlan = getService("plans");
  const user = useSelector(({ user }) => user);
  const [dataSource, setDataSource] = useState([]);
  const [current, setCurrent] = useState();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [stripeLoaded, setStripeLoaded] = useState({});
  const handleOnChange = (current) => {
    setCurrent(current);
    setVisible(true);
  };
  const defaultRenderItems = (item, index) => {
    return (
      <List.Item key={item._id}>
        <PlanCard
          current={current}
          showButton={true}
          onChange={handleOnChange}
          record={item}
          index={index}
          total={dataSource.length}
        />
      </List.Item>
    );
  };
  const getPlans = async () => {
    let plan_id = user.plan_id || (user.company && user.company.plan_id);
    try {
      setLoading(true);

      let { data } = await servicePlan.find({
        query: {
          status: "active",
          $sort: { order: 1 },
        },
      });
      setDataSource(data);
      setLoading(false);
      if (data.length > 0) setCurrent(data[1]);
      if (plan_id)
        return setCurrent(dataSource.find((it) => it._id === plan_id));
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };
  const handleChange = (field, value) => {
    console.log("---> ", field, value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const result = await loadScript("https://js.stripe.com/v3/");
      setStripeLoaded(result);
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (user) getPlans();
  }, [user]);

  return stripeLoaded.successful ? (
    <>
      <Wrapper>
        <List
          header={
            <div className="head-title">
              <h2>Update Plan!</h2>
            </div>
          }
          dataSource={dataSource}
          loading={loading}
          grid={{
            gutter: 0,
            xxl: 3,
            xl: 3,
            lg: 3,
            md: 2,
            sm: 2,
            xs: 1,
          }}
          renderItem={defaultRenderItems}
        />
        <MyModal
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}
          width={"60%"}
        >
          <Row
            style={{
              margin: "0px",
            }}
            type="flex"
            justify="start"
            gutter={0}
            align="middle"
          >
            <Col
              style={{
                background: "#FFF",
                padding: 20,
              }}
              xxl={16}
              xl={16}
              lg={12}
              md={24}
              sm={24}
              xs={24}
            >
              <div className="title-head">
                <h2 className="text-md">Business Plan</h2>
              </div>
              <StripeProvider apiKey="pk_test_51NBlwQB7UvpvYbdyQc0Qh316Yeaym6qlYn67DdgCN6iho6RDhrZSwOJdKe6cF9qGrqMHIXeeaoJjgtbwqAF0bu2E00URINp0v1">
                <Elements>
                  <PaymentForm
                    plan={current}
                    onChange={handleChange}
                    onSubmit={() => {
                      setVisible(false);
                      navigate(`/dashboard/profile`);
                    }}
                    onMount={(form) => setForm(form)}
                  />
                </Elements>
              </StripeProvider>
            </Col>
            <Col
              style={{
                padding: 20,
              }}
              xxl={8}
              xl={8}
              lg={12}
              md={24}
              sm={24}
              xs={24}
            >
              <div className="container-cards">
                <PlanCard
                  current={current}
                  showButton={false}
                  // onChange={handleOnChange}
                  record={current}
                  // index={index}
                  total={dataSource.length}
                />
              </div>
              {/* <div className="container-card">
                <div className="title-head">
                  <h2 className="text-md">Plan Detail</h2>
                </div>
                <PlanDetail record={current} />
              </div> */}
            </Col>
          </Row>
        </MyModal>
      </Wrapper>
    </>
  ) : null;
};
