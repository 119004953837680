import { Avatar, Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { WrapperProfile } from "./Styles";
import { useSelector } from "react-redux";
import { LOGO_BLUECLOSERX, URL_S3 } from "../../../constants";
import { navigate } from "@reach/router";
const { Meta } = Card;
const Profile = ({ ...props }) => {
  let user = JSON.parse(window.localStorage.getItem("user")) || "";
  const [filterDeafaultValues, setFilterDefaultValues] = useState();
  let formattedPhone = user.phone;

  if (formattedPhone && formattedPhone.length > 9) {
    formattedPhone = formattedPhone.replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      "$1 ($2) $3 $4"
    );
  }
  /*  useEffect(()=>{
if(filterDeafaultValues)getUser()
  },[filterDeafaultValues])
  useEffect(() => {
    if (props.filterDeafaultValues)
      setFilterDefaultValues(props.filterDeafaultValues);
  }, [props.filterDeafaultValues]); */
  console.log("qu es user", user);
  return (
    <WrapperProfile>
      <Card hoverable>
        <Meta
          title={
            <div className="section-title">
              <div className="title-start">
                <h4>Welcome Back !</h4>
                <div className="name-company">
                  <span>Closerx Dashboard</span>
                  <div className="section-img">
                    <img src="/images/profile.png" />
                  </div>
                </div>
              </div>
              <div className="section-avatar">
                <div className="avatar-void">
                  <Avatar
                    size="large"
                    src={`${URL_S3}/${
                      user && user.picture ? user.picture : LOGO_BLUECLOSERX
                    }`}
                  />
                </div>
              </div>
            </div>
          }
          description={
            <div className="section-description">
              <div className="name-profile">
                <div className="section-name">
                  <h3>
                    {`${user && user.first_name} ${user && user.last_name}`}
                  </h3>
                  <div>
                    <span>{user && user.position}</span>
                  </div>
                  <div className="job-name">
                    {user.phone && <span>{formattedPhone}</span>}
                    <div>
                      <a>{user && user.email}</a>
                    </div>
                  </div>
                </div>
                <div className="container-user">
                  <div className="section-info">
                    <div className="info">
                      <div className="role">
                        <span>{user.role}</span>
                      </div>
                      <div className="role-title">
                        <h4>Role</h4>
                      </div>
                    </div>
                    <div className="info">
                      <div className="status">
                        <span>{user.status}</span>
                      </div>
                      <div className="status-title">
                        <h4>Status</h4>
                      </div>
                    </div>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => navigate("dashboard/profile")}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
            </div>
          }
        />
      </Card>
    </WrapperProfile>
  );
};
export default Profile;
