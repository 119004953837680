import React from 'react';
import { Card, Avatar, Icon, Button } from "antd";
import { Tools } from '../Styles';
import { MemberCardWrapper } from './Styles';
import { URL_S3, URL_DEFAULT_AVATAR } from '../../constants';
const { Meta } = Card;
const MemberCard = ({ _id, actions, onRemove, ...props }) => {

    return (<MemberCardWrapper>
        <Card
            actions={typeof actions != "undefined" ?
                typeof actions == "boolean" ?
                    actions : actions : [
                    <>
                        <Button type="link" onClick={() => onRemove(_id)}>Remove from task</Button>
                    </>
                ]}
        >
            <Meta
                avatar={<Avatar
                    size="large"
                    src={props.picture ? `${URL_S3}/${props.picture}` : URL_DEFAULT_AVATAR} />}
                title={<Tools>
                    <span>{props.first_name || ""} {props.last_name || ""}</span>
                </Tools>}
                description={<Tools>
                    {props.email && <span>@{props.email.substring(0, props.email.indexOf("@"))}</span>}
                </Tools>}
            />
        </Card>
    </MemberCardWrapper>)
}
export default MemberCard;