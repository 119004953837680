import React, { useEffect, useState } from "react";
import { Wrapper, WrapperProfile } from "./styles";
import { Steps, Icon, Button, message, Row, Col } from "antd";
import { navigate, useLocation } from "@reach/router";
import { CompleteProfile } from "./CompleteProfile";
import { CompanyInformation } from "./CompanyInformation";
import { ProfileContext } from "./ProfileContext";
import { getService } from "../../services";
import Loader from "../../components/loader";
import qs from "querystring";
import { useSelector } from "react-redux";
import { PaymentMethod } from "../../components";
const { Step } = Steps;
const steps = [
  {
    title: "Complete Profile",
    service: "users",
    icon: <Icon type="user" />,
    content: <CompleteProfile />,
  },
  {
    title: "Company Information",
    service: "companies",
    icon: <Icon type="solution" />,
    content: <CompanyInformation />,
  },
  {
    title: "Plans",
    icon: <Icon type="project" />,
    content: <PaymentMethod />,
  },
];
const UserStepsForm = ({ ...props }) => {
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const [service, setService] = useState("users");
  const [submitting, setSubmitting] = useState(false);
  const [payloads, setPayloads] = useState({});
  const [form, setForm] = useState();

  const handleOnChange = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const onMount = (form) => {
    setForm(form);
  };
  const next = () => {
    if (!form)
      return navigate(
        `${location.pathname}?${qs.stringify({ step: current + 1 })}`
      );
    if (form)
      form.validateFields((errors, values) => {
        if (!errors) {
          save();
        }
      });
  };
  const save = async () => {
    let resource = steps[current].service;
    if (resource) {
      let { _id } = payloads;
      let service = getService(resource);
      setSubmitting(true);
      if (_id) {
        await service.patch(_id, payloads);
      } else {
        await service.create(payloads);
      }
      setSubmitting(false);
      //setCurrent((current) => current + 1);
      navigate(`${location.pathname}?${qs.stringify({ step: current + 1 })}`);
      setPayloads({});
      console.log("----> ", service, payloads);
    }
  };
  const prev = () => {
    //setCurrent((current) => current - 1);
    if (current > 0)
      navigate(`${location.pathname}?${qs.stringify({ step: current - 1 })}`);
  };
  useEffect(() => {
    if (location) {
      let { search } = location;
      let query = qs.parse(search.replace("?", ""));
      let { step } = query;
      setForm(null);
      if (current != step) setCurrent(parseInt(step || current));
    }
  }, [location.search]);
  useEffect(() => {
    if (props.step) {
      setCurrent(props.step);
      navigate(`${location.pathname}?${qs.stringify({ step: props.step })}`);
    }
  }, [props.step]);
  return (
    <WrapperProfile>
      <ProfileContext.Provider
        value={{
          save,
          setService,
          service,
          submitting,
          setPayloads,
          payloads,
        }}
      >
        <Steps>
          {steps.map((step, index) => (
            <Step
              status={current === index}
              initial={current}
              disabled={current !== index}
              {...step}
            />
          ))}
        </Steps>
        <div className="steps-content">
          <Row type="flex" justify="center" align="middle" gutter={4}>
            <Col
              xxl={16}
              xl={current === steps.length - 1 ? 24 : 18}
              lg={20}
              md={20}
              sm={22}
              xs={24}
            >
              {typeof steps[current].content != "string"
                ? React.cloneElement(steps[current].content, {
                    onChange: handleOnChange,
                    onMount,
                    initialValues: payloads,
                  })
                : steps[current].content}
            </Col>
          </Row>
        </div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button className="button-nex"loading={submitting} type="primary" onClick={() => next()}>
              {submitting ? "Save" : "Next"}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
            className="button-nex"
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Plans
            </Button>
          )}
          {!submitting && current > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </ProfileContext.Provider>
    </WrapperProfile>
  );
};
export const OnBoarding = ({ token, ...props }) => {
  const [view, setView] = useState("default");
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const user = useSelector(({ user }) => user);
  const syncMlsCompany = async () => {
    try {
      const service = getService("sync-emls-company");
      let res = await service.create({
        token,
      });
      message.info(res.message);
      setSubmitting(false);
      navigate(`/profile${res.step ? `?step=${res.step}` : ""}`);
    } catch (error) {
      message.error(error.message);
      setSubmitting(false);
    }
  };
  useEffect(() => {
    if (user && user.step) setStep(user.step);
    //setCurrent(parseInt(user.step || current));
  }, [user]);
  useEffect(() => {
    if (token) syncMlsCompany();
  }, [token]);
  useEffect(() => {
    if (location) {
      let { search } = location;
      let query = qs.parse(search.replace("?", ""));
      let { step } = query;
      if (step) setView("steps");
    }
  }, [location]);
  useEffect(() => {
    if (step > 0 && !token) setView("steps");
  }, [step]);
  if (submitting) return <Loader />;
  return (
    <Wrapper>
      {view === "steps" && <UserStepsForm step={step} {...props} />}
      {view === "default" && (
        <div>
          <div className="flex justify-space-between gap-16">
            <div>
              <h2 className="text-lg">Welcome to ClosRX! </h2>
              <p>
                Our user-friendly platform helps businesses streamline sales and
                improve customer engagement. Our customizable features make it
                easy to manage contacts, track leads, and automate sales. Let us
                help you grow your business and take it to the next level.
              </p>
            </div>
            <div>
              <img
                src="/images/fill_form.svg"
                style={{
                  maxWidth: 400,
                }}
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-center align-center gap-8 m-lg">
            <Button
              onClick={() => setView("steps")}
              className="primary-btn"
              type="primary"
              size="large"
            >
              Start Now!
            </Button>
            <Button
              onClick={() => navigate("/dashboard")}
              className="secundary-btn"
              type="default"
              size="large"
            >
              Skip
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
};
