import React, { useState, useEffect } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/javascript/javascript";
import "codemirror/theme/yonce.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/markdown/markdown";
import "./theme/vscode-dark.css";
import { WrapperCodeEditor } from "./Styles";
import beautify from "json-beautify";
import _ from "lodash";
const defaultOptions = {
  lineNumbers: true,
  theme: "yonce",
  mode: {
    name: "javascript",
    json: true,
    statementIndent: 4,
  },
  lineNumbers: true,
  lineWrapping: true,
  indentWithTabs: true,
  tabSize: 4,
};
const CodeField = ({ options, source, name, style, ...props }) => {
  const [code, setCode] = useState();
  const [record, setRecord] = useState();
  const [initialized, setInitialized] = useState(false);
  const updateCode = (code) => {
    setCode(code);
    if (props.onChange) {
      props.onChange(code);
    }
  };
  useEffect(() => {
    if (props.code) {
      setInitialized(true);
      setCode(props.code);
    }
  }, [props.code]);
  useEffect(() => {
    /* alert(props.record[source || name]) */
    if (props.record) {
      setRecord(props.record);
    }
  }, [props.record]);
  useEffect(() => {
    if (!_.isEmpty(record)) {
      try {
        if (!record[source || name]) throw new Error("not record");
        let code = beautify(JSON.parse(record[source || name]), null, 4, 100);
        setCode(code);
        setInitialized(true);
      } catch (err) {
        setCode(
          `/*${record[source || name] || "Please input your code here."}*/`
        );
        setInitialized(true);
      }
    }
  }, [record]);
  if (!initialized) return null;
  return (
    <WrapperCodeEditor
      style={{
        height: props.height || 200,
        minWidth: props.minWidth || 240,
        overflowY: "auto",
        ...style,
      }}
    >
      <CodeMirror
        options={options || defaultOptions}
        autoFocus
        value={code}
        onChange={updateCode}
      />
    </WrapperCodeEditor>
  );
};
export default CodeField;
