import React, { useEffect, useState } from "react";
import { Icon, Breadcrumb } from "antd";
import { Link } from "@reach/router";
import styled from "styled-components";
import { AiOutlineHome } from "react-icons/ai";

const BreadcrumbContainer = styled(Breadcrumb)`
  color: #fff !important;
  & span,
  a {
    color: #fff !important;
  }
  &.ant-breadcrumb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon-home {
      font-size: 20px;
      padding-bottom: 4px;
    }
    & > span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
export const BreadCrumb = (props) => {
  const [pathname, setPathname] = useState();
  useEffect(() => {
    let { location, onChangePath, pathname } = props;
    if (location && location.pathname) {
      setPathname(location.pathname.split("/"));
      if (onChangePath) onChangePath(location.pathname);
    }
    //console.log("Change!", pathname);
    return () => {};
  }, [props.location]);
  return (
    <BreadcrumbContainer style={{ color: "#fff" }}>
      {pathname &&
        pathname.map((item, index) => (
          <Breadcrumb.Item className="item-breadcrumb" key={index}>
            <div>
              {index > 0 ? (
                <Link
                  style={
                    {
                      /* textTransform: "capitalize" */
                    }
                  }
                  to={`${pathname.slice(0, index + 1).join("/")}`}
                >
                  {item}
                </Link>
              ) : (
                <Link to="/">{<AiOutlineHome className="icon-home"/>}</Link>
              )}
            </div>
          </Breadcrumb.Item>
        ))}
    </BreadcrumbContainer>
  );
};
