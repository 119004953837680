import React, { useState, useEffect } from "react";
import { message } from "antd";
import { URL_S3 } from "../../../constants";
import { ImageWrapper } from "../Styles";
const extensions = ['png', 'jpg', 'jpeg', 'pdf', 'mp4'];
const Image = ({ path, name, type, ...props }) => {
    return (<ImageWrapper>
        <img alt={name} src={`${URL_S3}/${path}`} />
    </ImageWrapper>)
}
export default Image;