import styled from "styled-components";
export const WrapperForm = styled.div`
  & .tools-label > *{
    margin-right:4px;
  }
  & .tools-label{
    display: flex;
    justify-content:center;
    align-items: center;
  }
`;
export const Wrapper = styled.div`
  & .property-container .ant-col {
    margin: 0px 0px 4px 0px !important;
  }
  & .ant-form-item-label > label {
    color: var(--color-blue-dark)!important;
  }
`;
export const BoxContainer = styled.div`
  & .ant-card-body {
    padding: 10px !important;
  }
  & .ant-card-bordered {
    border: 1px solid #e8e8e8 !important;
    border-radius: 6px !important;
  }
  & .ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 10px !important;
    color: #535353;
    font-weight: 500;
    font-size: 16px;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 6px 6px 0 0;
  }
  & .ant-card-head-title {
    font-weight: 500 !important;
    font-size: 18px !important;
    color: var(--primary) !important;
  }
`;
