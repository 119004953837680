import React from "react";
import ColumnLayout from "./ColumnLayout";

const RightLayout = ({ children, ...props }) => {
  return (
    <>
      <ColumnLayout {...props} layout="rightlayout">
        {children}
      </ColumnLayout>
    </>
  );
};
export default RightLayout;
