import React from "react";
import { Empty, Button, ConfigProvider } from "antd";
import { Wrapper } from "./Styles";

const EmptyComponent = ({
  styles = {},
  size,
  showAdd = false,
  description,
  image,
  buttonText = "Start added",
  ...props
}) => {
  const defaultRender = () => {
    return (
      <Empty
        className={`empty-content ${size || "default"}`}
        // image={image || "/images/carpeta.png"}
        // image={Empty.PRESENTED_IMAGE_SIMPLE}
        // image={"/images/carpeta.png"}
        image={image || "/images/folderundraw.svg"}
        imageStyle={{
          height: 100,
          ...styles,
        }}
        description={<span>{description}</span>}
      >
        {showAdd && <Button type="link">{buttonText}</Button>}
      </Empty>
    );
  };
  return (
    <Wrapper size={size}>
      <ConfigProvider renderEmpty={props.renderEmpty || defaultRender}>
        {props.children}
      </ConfigProvider>
    </Wrapper>
  );
};
export default EmptyComponent;
