import React, { useState, useEffect } from "react";
import { Wrapper, WrapperItem, WrapperSkeleton } from "./Styles";
import { List, message, Avatar, Skeleton } from "antd";
import { LogActions, getService } from "../../../services";
import { useSelector } from "react-redux";
import {
  URL_S3,
  URL_DEFAULT_AVATAR,
  GOONEWORLD_SITE,
} from "../../../constants";
import * as messages from "../../../constants/messages";
import moment from "moment";
import MarkDown from "../../markdown/Markdown";
import MemberGroup from "../../board/MemberGroup";
import { Empty } from "../loader";
import { Link, navigate } from "@reach/router";
import { MyModal } from "../MyModal";
import TaskForm from "../../board/TaskForm";
const SkeletonActivities = ({ loading, title, avatar, count, ...props }) => {
  return (
    <WrapperSkeleton>
      <div className="skeleton-loading">
        <div className="title" />
        {Array.from({ length: count }).map((_, index) => (
          <div key={index} className="container-avatar">
            {avatar && <Avatar />}
            <div className="description" />
          </div>
        ))}
      </div>
    </WrapperSkeleton>
  );
};
const Activity = ({
  defaultFilterValues,
  views = false,
  allowSelect = false,
  onSelect,
  title,
  onload,
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
  const [visible_task, setVisibleTask] = useState();
  const [task, setTask] = useState();
  const [pending_notifications, setPendingNotifications] = useState(0);
  const user = useSelector(({ user }) => user);
  const getData = () => {
    const service = getService("log-actions");
    setLoading(true);
    service
      .find({
        query: {
          $or: [
            {
              user_id: user._id,
            },
            {
              members_ids: { $in: [user._id] },
            },
          ],
          ...defaultFilterValues,
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then(({ data, meta }) => {
        data.forEach((it) => {
          let { viewed_by_ids } = it;
          let viewed = viewed_by_ids.includes(user._id);
          if (!viewed) setPendingNotifications((pending) => pending + 1);
        });
        setDataSource(data);
        setLoading(false);
        if (onload) onload(data);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };
  const updateLog = (id, params) => {
    setPendingNotifications(0);
    const service = getService("log-actions");
    return service
      .patch(id, params)
      .then((res) => {
        getData();
      })
      .catch((err) => message.error(err.message));
  };
  const handleSelect = async (item) => {
    let { log, action_type, viewed_by_ids, reference_id } = item;
    if (item) {
      setSelected(item);
      if (onSelect) onSelect(item);
      if (views && !viewed_by_ids.includes(user._id))
        await updateLog(item._id, {
          viewed_by_ids: [...viewed_by_ids, user._id],
        });
      switch (action_type) {
        case "update_tasks":
          setTask(log);
          setVisibleTask(true);
          break;
        case "create_tasks":
          setTask(log);
          setVisibleTask(true);
          break;
        case "favorites":
          if (log.property)
            window.open(
              `${GOONEWORLD_SITE}/detail/${log.property._id}`,
              "_blank"
            );
          break;
        case "remove_listing":
          if (log.property)
            window.open(
              `${GOONEWORLD_SITE}/detail/${log.property._id}`,
              "_blank"
            );
          break;
        case "reminders":
          let { task } = log;
          task["date"] = moment(task.date).format("YYYY-MM-DD HH:mm:ss");
          setTask(task);
          setVisibleTask(true);
          break;
        case "birthdays":
          //dashboard/contacts/6009f7ec9961bf52d05b0939
          navigate(`/dashboard/contacts/${reference_id}`);
          break;
      }
    }
  };
  const renderItem = (item, index) => {
    let { action_type, viewed_by_ids } = item;
    let author = item.log.author || item.log.user;
    if (item.log.social)
      author = item.log.social_network && item.log.social_network.account;
    let isSelected = allowSelect
      ? selected && selected._id == item._id
      : allowSelect;
    let viewed = viewed_by_ids.includes(user._id);
    let { log } = item;
    if (log.date) {
      log["date"] = moment(log.date).format("MM-DD-YYYY HH:mm:ss");
    }
    return (
      <WrapperItem className="wrapper-item">
        <List.Item
          className={`item ${isSelected ? "item-selected" : ""} ${
            views ? (viewed ? "item-viewed" : "item-not-viewed") : ""
          }`}
          onClick={() => {
            handleSelect(item);
          }}
        >
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  size="medium"
                  src={
                    item.log && item.log.social
                      ? (author && author.picture) || URL_DEFAULT_AVATAR
                      : action_type == "birthdays"
                      ? "/images/birthday-cake.svg"
                      : author && author.picture
                      ? `${URL_S3}/${author.picture}`
                      : URL_DEFAULT_AVATAR
                  }
                />
              }
              title={
                <span>
                  {author &&
                    (author.first_name ? (
                      <strong>
                        {author.first_name || ""} {author.last_name || ""}
                      </strong>
                    ) : (
                      <strong>
                        {author.email.substring(0, author.email.indexOf("@"))}
                      </strong>
                    ))}{" "}
                  <span className="moment-date">
                    {moment(item.createdAt).fromNow()}
                  </span>
                </span>
              }
              description={
                <div>
                  <span>
                    {messages.tree[item.action_type] && (
                      <MarkDown
                        source={messages.tree[item.action_type].format({
                          ...log,
                          author: author,
                        })}
                      />
                    )}
                  </span>
                  {index == 0 && item.type == "task" && (
                    <MemberGroup
                      add={false}
                      members={item.members}
                      members_ids={item.members_ids}
                      record={item}
                    />
                  )}
                </div>
              }
            />
          </List.Item>
        </List.Item>
      </WrapperItem>
    );
  };
  useEffect(() => {
    if (user) {
      LogActions.on("created", (data) => {
        let { log, user_id, action_type } = data;
        getData();
      });
      getData();
    }
    return () => {};
  }, [user]);
  if (loading)
    return (
      <SkeletonActivities loading={loading} title={false} avatar count={8} />
    );
  return (
    <Wrapper className="wrapper-list">
      <Empty>
        <List
          header={
            !views
              ? title
              : pending_notifications > 0 && (
                  <div className="pending-notifications">
                    <h3>
                      Pending Notifications (
                      <span>{pending_notifications}</span>)
                    </h3>
                  </div>
                )
          }
          loading={loading}
          renderItem={renderItem}
          dataSource={dataSource}
        />
      </Empty>
      <MyModal
        title="Edit Task"
        onCancel={() => {
          setVisibleTask(false);
          setTask(null);
        }}
        visible={visible_task}
        width={"60%"}
      >
        <TaskForm
          id={task && task._id}
          onSubmit={() => {
            setVisibleTask(false);
          }}
        />
      </MyModal>
    </Wrapper>
  );
};
export default Activity;
