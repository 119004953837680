import styled from "styled-components";

export const Wrapper = styled.div`
    & .arrow-container {
        justify-content: space-between;
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        top: calc(50% - 20px);
        left: -2px;
        padding: 0px 16px;
    }
    & .arrow-container button[disabled] i {
        background: #efefefc2!important;
        width: 30px;
        border-radius: 50%;
        color: red!important;
    }
    & .carousel-container {
        display: block;
        position: relative;
    }
    & .arrow-container i{
        font-size: 30px!important;
    }
    & .ant-carousel {
        width: 250px!important;
        margin: 0px auto!important;
    }
    & .ant-carousel .slick-slide {
        text-align: center;
        width: 250px;
        height: 392px;
        line-height: 153px;
        background: #fafafa00!important;
        overflow: hidden;
    }
    & .ant-carousel .slick-slide h3 {
        color: #fff;
    }
    .ant-carousel .slick-dots li {
        margin-top: 5px!important;
        margin-bottom: 10px!important;
    }
    & .slick-dots li button:before {
        font-size: 12px!important;
        line-height: 2px!important;
        width: 25px!important;
        height: 25px!important;
    }
    & .ant-carousel .slick-dots-bottom{
        bottom: 6px !important;
    }

/* codigo cards */

    & .ant-card {
        width: 100%;
        height: 374px;
    }
    & .ant-card-bordered {
        border: 1px solid #e8e8e8;
        box-shadow: 2px 2px 2px #ccc;
        width: 100%!important;
    }
    & .section-price {
        // font-size: 27px;
        margin-top: 14px;
        display: flex;
        align-items: center;
    }
    & .section-quantity {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 6px;
    }   
    & .anticon svg {
        display: inline-block;
        color: #0000009e;
    }
    & .section-quantity span{
        margin-left: 6px;
        color: #0000009e;
    }
    & .section-description span{
        color: #000000ad!important;
    }
    & .section-description {
        margin-top: 24px;
        font-size: 13px;
        font-family: 'Poppins';
    }
    & .section-plan {
        font-size: 23px;
        display: flex;
        justify-content: flex-start;
    }
    & .section-plan strong{
        color: #0000009e;
    }
    & .section-price span{
        display: flex;
        align-items: center;
        color: #7c7e7ee8;
    }
    & .section-price strong{
        font-family: Poppins;
        margin-right: 6px
        font-size: 31px;
    }
    & .section-sending {
        margin-top: 17px;
        background: #e8e8e8;
        border-radius: 6px;
    }
    & .sending {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
    }
    & .section-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        margin-top: 13px;
    }
    & .section-footer .ant-btn-primary{
        width: 100%!important;
    }

    /* select default */

    & .item-selected.ant-card-bordered {
        background: transparent linear-gradient(90deg, #021049 0%, #1D37A2 100%) 0% 0% no-repeat padding-box!important;
    }
    & .item-selected .section-price strong {
        color: #e8e8e8!important;
    }
    & .item-selected .section-price > span{
        color: #e8e8e8!important;
    }
    & .item-selected .section-plan strong {
        color: #e8e8e8!important;
    }
    
    & .item-selected .section-description span {
        color: #e8e8e8!important;
    }
    .item-selected .ant-btn-primary{
        background: #fff;
        color: var(--color-primary);
    }


    & .item-selected .section-quantity span{
        color: #e8e8e8!important;
    }
    & .item-selected .anticon svg{
        color: #e8e8e8!important;
    }
    & .item-card.item-selected .section-sending {
        margin-top: 17px;
         background: none!important;
        border-radius: 6px;
        color: #e8e8e8!important;
    }

`;