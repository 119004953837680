import styled from "styled-components";
export const Wrapper = styled.div`
  width: 100%;
  min-height: 420px;

  & .ant-list-items {
    // background: #fff;
    padding: 10px;
  }
  & .ant-list-item {
    padding: 8px !important;
  }
  & .section-members {
    color: #433281;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 15%);
    padding: 36px 24px 36px;
    margin: 10px 2px 40px;
    background: #e8e8e840 !important;
    width: 340px;
    .section-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      .icon-avatar {
        color: #5b6370b5 !important;
        font-size: 38px;
      }
    }
    .section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 18px;
      text-align: center;
      h2 {
        color:#676767 !important
        font-size: 24px;
        font-weight: 600;
      }
    }
    .section-btn {
      padding: 34px 24px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // transition: transform 0.3s ease-in-out;
      .btn-invite-member {
        text-align: center !important;
        border-radius: 18px;
        width: 112px!important;
      }
    }
    .section-search {
      padding: 2px 26px!important;
      .container-search {
        width: 100% !important;
      }
    }
  }
  & .ant-list-split .ant-list-header {
    border-bottom: 0px !important;
  }
`;
