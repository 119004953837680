import React, { useEffect, useState, useRef } from "react";
import { WrapperForm, ListItemContainer } from "./Styles";
import {
  DinamicForm,
  SimpleForm,
  SearchField,
  FileUpload,
  /* SelectField */
} from "../com/form/";
import { SelectField } from "../com/fields/";
import { AvatarWrapper } from "../Styles";
import {
  Button,
  Row,
  Col,
  List,
  message,
  Card,
  Avatar,
  Input,
  Icon,
  Tooltip,
  Checkbox,
} from "antd";
import { useSelector } from "react-redux";
import {
  IMAGE_BANNER,
  IMAGE_UPLOAD,
  URL_DEFAULT_AVATAR,
  URL_S3,
} from "../../constants";
import { getService } from "../../services/";
import MemberGroup from "./MemberGroup";
import S3Button from "../com/form/S3Button";
import { Documents } from "..";
import CategoryList from "./CategoryList";
const { Item } = List;
const { Meta } = Card;
const { TextArea } = Input;
const MemberList = (props) => {
  const [members, setMembers] = useState(props.members || []);
  useEffect(() => {
    setMembers(props.members);
  }, [props.members]);
  return (
    <AvatarWrapper xtype="fieldset" flex={1} {...props}>
      {members.map(({ _id, picture, email, first_name, last_name }) => (
        <div className="container">
          <Avatar
            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            src={`${URL_S3}/${picture}`}
          >
            {!picture ? first_name.substring(0, 1) : email.substring(0, 1)}
          </Avatar>
          <Tooltip title="Remove">
            <Button
              onClick={() => {
                if (props.form) {
                  props.onRemove(_id, props.form);
                }
              }}
              size="small"
              shape="circle"
              type="default"
              icon="close"
            />
          </Tooltip>
        </div>
      ))}
    </AvatarWrapper>
  );
};
const BoardForm = ({
  board_type,
  template_id,
  name,
  description,
  ...props
}) => {
  const [members_ids, setMemberIds] = useState([]);
  const [members, setMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [form, setForm] = useState(null);
  const [as_template, setAsTemplate] = useState(false);
  const [keep_tasks, setKeepTasks] = useState(true);
  const [id, SetId] = useState();
  const [record, setRecord] = useState();
  const user = useSelector(({ user }) => user);

  const onSubmit = (err, data) => {
    console.log("data boards", err, data);
    if (props.onSubmit) props.onSubmit(err, data);
  };
  const getTeams = () => {
    const service = getService("teams");
    let { company_id } = user;
    service
      .find({
        query: {
          $or: [
            {
              company_id,
            },
            {
              user_id: user._id,
            },
          ],
        },
      })
      .then(({ data }) => {
        setTeams(data);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const getMembers = () => {
    let { record } = props;
    if (record) {
      setMembers(record.members);
      setMemberIds(record.members_ids);
    }
  };
  const handleChangeMember = (id, member) => {
    let new_members_ids = Array.from(members_ids);
    let new_members = Array.from(members);
    if (!new_members_ids.includes(id)) {
      new_members_ids.push(id);
      new_members.push(member);
    } else {
      new_members_ids.splice(new_members_ids.indexOf(id), 1);
      new_members.splice(
        new_members.findIndex((it) => it._id == id),
        1
      );
    }
    /* updateMembers(new_members_ids); */
    form.setFieldsValue({
      members_ids: new_members_ids,
    });
    setMembers(new_members);
    setMemberIds(new_members_ids);
  };
  const handleRemoveMember = (id) => {
    let new_members_ids = Array.from(members_ids);
    let new_members = Array.from(members);

    new_members_ids.splice(new_members_ids.indexOf(id), 1);
    new_members.splice(
      new_members.findIndex((it) => it._id == id),
      1
    );
    /* updateMembers(new_members_ids); */
    form.setFieldsValue({
      members_ids: new_members_ids,
    });
    setMembers(new_members);
    setMemberIds(new_members_ids);
  };
  const handleChange = (field, value) => {
    /*  setRecord({
      ...record,
      [field]: value,
    }); */
  };

  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (props.id) SetId(props.id);
  }, [props.id]);
  if (!user) return null;
  return (
    <WrapperForm>
      <SimpleForm
        id={id}
        onChange={handleChange}
        initialValues={{
          board_type,
        }}
        onMount={(form) => setForm(form)}
        onSubmit={onSubmit}
        source="boards"
      >
        <Input type="hidden" name="company_id" initial={user.company_id} />
        <Input type="hidden" name="user_id" initial={user._id} />
        <Input type="hidden" name="board_type" initial={board_type} />
        <Input type="hidden" name="template_id" initial={template_id} />
        <Input
          type="hidden"
          name="board_type"
          initial={as_template ? "template" : "board"}
        />

        {(board_type === "template" || board_type === "workflow_template") && (
          <S3Button
            // label="Upload Cover"
            flex={1}
            className="section-image"
            defaultImage={IMAGE_UPLOAD}
            // defaultImage={"/images/galeria-de-imagenes.png"}
            remove
            // record={record}
            idComponent="btn-send"
            /* onChange={onChange} */
            path="boards"
            finalPath="picture"
            // initial={image}
            source="picture"
            name="image"
            // id={record && record._id}
          />
        )}
        <Input
          size="large"
          flex={1}
          name="name"
          label={"Name"}
          placeholder={"Write a Name"}
          initial={name}
          validations={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
          maxLength={20}
        />
        {(board_type === "template" || board_type === "workflow_template") && (
          <TextArea
            flex={1}
            label="Description"
            name="description"
            initial={description}
            placeholder="Description"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        )}
        {(board_type === "template" || board_type === "workflow_template") && (
          <SelectField
            flex={1}
            mode="multiple"
            label="Categories"
            name="category_ids"
            reference="board-categories"
            // optionValue="name"
            // defaultValue={}
            // defaultValues={}
            placeholder="Categories"
            /*  optionText={(value, record) =>
              console.log("--------------- valeu record", value, record)
            } */
            // onSelect={handleOnselect}
            // choices={status}
          />
        )}

        {members_ids && members_ids.length > 0 && (
          <MemberGroup
            flex={1}
            title="Members"
            onChangeMember={handleChangeMember}
            onRemove={handleRemoveMember}
            record={props.record}
            members_ids={members_ids}
            members={members}
          />
        )}
        {/* <SearchField
          labelAlign={"left"}
          placeholder="Add Members"
          label="Add Members"
          name="member_id"
          source="get-all-users"
          flex={1}
          onSelectItem={(key, value, record, form) => {
            let new_values = [];
            let new_members = [];
            if (!members_ids.includes(value)) {
              new_values = [...members_ids, value];
              new_members = [...members, record];
            } else {
              new_values = members_ids.filter((it) => it !== value);
              new_members = members.filter((it) => it._id !== value);
            }
            if (form)
              form.setFieldsValue({
                members_ids: new_values,
                member_id: null,
              });
            setMembers(new_members);
            setMemberIds(new_values);
          }}
          defaultFilterValues={{
            company_id: user && user.company_id,
          }}
          optionText={(value, record) =>
            `${record.first_name || ""} ${record.last_name || ""} (${
              record.role
            })`
          }
        /> */}
        {/*  {
          <SelectField
            labelAlign={"left"}
            label="Add team"
            placeholder="Add Teams"
            reference="teams"
            name="team_id"
            flex={1}
            filterDefaultValues={{
              company_id: user && user.company_id,
            }}
            //optionText={(value, record) => (`${record.first_name || ""} ${record.last_name || ""} (${record.role})`)}
          />
        } */}
        {/*   {user &&
          user.modules &&
          typeof user.modules.find(({ name }) => name === "realestate") !=
            "undefined" && (
            <SearchField
              flex={1}
              label="Search property"
              placeholder={"Search MLS#"}
              name="property_id"
              source="properties"
              optionText={(value, record) =>
                `${record.Address || ""} ${record.Listing ||
                  ""} (${record.PostalCode || ""})`
              }
              defaultFilterValues={{
                company_id: user && user.company_id,
              }}
            />
          )} */}
        {template_id && (
          <Checkbox
            name="keep_tasks"
            placeholder="Keep Tasks"
            initial={true}
            flex={1}
            onChange={() => setKeepTasks((checked) => !checked)}
            checked={keep_tasks}
            xtype={"checkbox"}
          >
            <span>Keep Tasks</span>
          </Checkbox>
        )}
        {!template_id && board_type === "board" && (
          <Checkbox
            name="board_type_check"
            placeholder="Keep Tasks"
            initial={true}
            flex={1}
            onChange={() => setAsTemplate((checked) => !checked)}
            checked={as_template}
            xtype={"checkbox"}
          >
            <span>Save Template</span>
          </Checkbox>
        )}
        <Input
          xtype="hiddenfield"
          flex={1}
          type="hidden"
          style={{
            height: 40,
            margin: 40,
          }}
          validations={[
            {
              required: false,
              message: "Members required",
            },
          ]}
          name="members_ids"
        />
      </SimpleForm>
    </WrapperForm>
  );
};
export default BoardForm;
