import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Popover,
  Avatar,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  message,
} from "antd";
import { MemberGroupWrapper } from "./Styles";
import MemberCard from "./MemberCard";
import MemberList from "./MemberList";
import { URL_S3, colors_members } from "../../constants";
import MenuModal from "./MenuModal";
import { useSelector } from "react-redux";
import { getService } from "../../services";

const MemberGroup = ({
  onRemove,
  title,
  size = "medium",
  add = true,
  source,
  reference,
  tooltip = false,
  record = {},
  ...props
}) => {
  let { board = {} } = record;
  const [members, setMembers] = useState(props.members || []);
  const [members_ids, setMemberIds] = useState(props.members_ids || []);

  let popover = useRef(null);
  const user = useSelector(({ user }) => user);
  const onVisibleChange = (visible) => {
    if (visible && popover.current) popover.current.focus();
  };
  const handleOnRemove = (id) => {
    let new_members = members.filter((it) => it._id != id);
    let new_member_ids = Array.from(members_ids);
    new_member_ids.splice(
      new_member_ids.findIndex((it) => it == id),
      1
    );
    setMembers(new_members);
    setMemberIds(new_member_ids);
    update({
      members_ids: new_member_ids,
    });
  };
  const update = (payloads) => {
    if (reference && record && record._id) {
      const service = getService(reference);
      service
        .patch(record._id, payloads)
        .then((res) => {
          message.success("Member removed!");
          if (onRemove) return onRemove(res._id, res);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  const handleRemove = (id) => {
    console.log("Remove: ", id);
    if (!reference) if (onRemove) return onRemove(id);
    handleOnRemove(id);
  };
  const MembersMenu = () => {
    return (
      <MenuModal closable={false} title="Members">
        <Menu.Item>
          <span>
            <MemberList
              title={"Members"}
              onChange={props.onChangeMember}
              members={members}
              add={add}
              members_ids={members_ids}
              defaultFilterValues={{
                _id: {
                  $in: board
                    ? board.members_ids
                    : members_ids
                    ? members_ids
                    : undefined,
                },
                company_id: user.company_id,
              }}
              placeholder="Search Members Ex. @Name"
              {...record}
            />
          </span>
        </Menu.Item>
      </MenuModal>
    );
  };
  useEffect(() => {
    if (props.members) {
      setMembers(props.members);
      setMemberIds(props.members.map((it) => it._id));
    }
  }, [props.members]);
  useEffect(() => {
    if (props.members_ids) {
      setMemberIds(props.members_ids);
    }
  }, [props.members_ids]);

  if (!user || members.length == 0) return null;
  return (
    <div
      className="group-container"
      style={{
        marginTop: 20,
      }}
    >
      {members && members.length > 0 && title && <h2>{title}</h2>}
      <MemberGroupWrapper xtype="fieldset" flex={1} {...props}>
        {members &&
          members.length > 0 &&
          members.map((item, index) => {
            let { _id, picture, email, first_name, last_name } = item;
            let backgroundColor = "#ccc";
            if (item) {
              if (index >= 1) {
                const randomIndex = Math.floor(
                  Math.random() * colors_members.length
                );
                backgroundColor = colors_members[randomIndex];
              }
            }
            return (
              <div className="member-container">
                {tooltip ? (
                  <Tooltip
                    placement="bottom"
                    title={`${first_name || ""} ${last_name || ""}`}
                  >
                    <div className="container-avatar-member">
                      <Avatar
                        style={{
                          // color: "#f56a00",
                          backgroundColor: backgroundColor,
                        }}
                        src={`${URL_S3}/${picture}`}
                        size={size}
                      >
                        {!picture
                          ? `${first_name.substring(0, 1)}${last_name.substring(
                              0,
                              1
                            )}`
                          : email.substring(0, 1)}
                      </Avatar>
                    </div>
                  </Tooltip>
                ) : (
                  <Popover
                    overlayClassName="popup"
                    destroyTooltipOnHide={true}
                    trigger="click"
                    content={<MemberCard onRemove={handleRemove} {...item} />}
                    title={props.title}
                    ref={(el) => (popover = el)}
                    onVisibleChange={onVisibleChange}
                  >
                    <Avatar
                      style={{ backgroundColor: backgroundColor }}
                      src={`${URL_S3}/${picture}`}
                      size={size}
                    >
                      {!picture
                        ? first_name.substring(0, 1)
                        : email.substring(0, 1)}
                    </Avatar>
                  </Popover>
                )}
              </div>
            );
          })}
        {add && members && members.length > 0 && (
          <Dropdown
            key="members"
            overlay={MembersMenu}
            trigger={["click"]}
            overlayClassName="menu-box"
          >
            <Avatar icon="plus" />
          </Dropdown>
        )}
      </MemberGroupWrapper>
    </div>
  );
};
export default MemberGroup;
