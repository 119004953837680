import React from "react";
import Profile from "../users/Profile";
import { BasicLayout } from "../../layouts";
import { OnBoarding } from "./onBoarding";

const EternalProfile = (props) => {
  return (
    <>
      <BasicLayout {...props}>
        <OnBoarding {...props}/>
      </BasicLayout>
    </>
  );
};
export default EternalProfile;
