import React, { useRef, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { getService } from "../../services";
import _ from "lodash";
// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";

// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/hr";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/help";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
import bootstrapUI from "!!raw-loader!./assets/bootstrap.min.css";
import customStyle from "!!raw-loader!./assets/custom.css";
import { message } from "antd";
import { URL_BASE_ACELLE } from "../../constants";


const HTMLEditor = ({ id, name, source, onInit, onSelectionChange, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [record, setRecord] = useState({});
  const [content, setContent] = useState('');
  const [htmlBase, setHtmlBase] = useState();
  const [html, setHtml] = useState();
  const [selector, setSelector] = useState();
  const editorRef = useRef(null);

  const onChange = (content) => {
    if (!initialized) {
      setInitialized(true);
    } else {
      /*
      var parser = new DOMParser();
      var str = htmlBase.replace();
      var doc = parser.parseFromString(str, "text/html");
      doc.body.innerHTML = content;
      let regex = new RegExp(URL_BASE, "g");
       content = `<!DOCTYPE html>
    <html lang="en">
    ${doc.documentElement.innerHTML}
    </html>`
        .replace(regex, "")
        .replace(new RegExp("<p>&nbsp;</p>", "g"), ""); */
      if (props.onChange) props.onChange(content);
    }
  };
  const onNodeChange = (e) => {
    /* console.log("E:", e); */
    setSelector(e);
  };
  const handleChange = _.debounce(onChange, 3000, { maxWait: 3000 });

  useEffect(() => {
    /* if (record.content && !initialized) {
      var parser = new DOMParser();
      var str = record.content;
      var doc = parser.parseFromString(str, "text/html");
      doc.body.innerHTML = "";
      setHtmlBase(`<!DOCTYPE html>
      <html lang="en">
      ${doc.documentElement.innerHTML}
      </html>`);
    } */
    if (record) {
      setContent(record[name || source] || "");
    }
  }, [record]);
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (props.content) setContent(props.content);
  }, [props.content]);
  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);
  /* if (loading) return "Loading..."; */
  return (
    <div>
      {(
        <Editor
          onInit={(evt, editor) => {
            editorRef.current = editor;
            if (onInit) onInit(editor);
          }}
          onEditorChange={handleChange}
          onNodeChange={(e) => {
            /* let selection = e.target.selection;
            let bm = selection.getBookmark();
            if (bm) onNodeChange(bm); */
            /*
            tinymce.activeEditor.selection.moveToBookmark(bm);
            console.log("Node:", selection.getBookmark()); */
            /* var bm = tinymce.activeEditor.selection.getBookmark();
            // Restore the selection bookmark
            tinymce.activeEditor.selection.moveToBookmark(bm); */
          }}
          onBlur={(e) => {
            var bm = tinymce.activeEditor.selection.getBookmark();
            /* tinymce.activeEditor.execCommand(
              "mceInsertContent",
              false,
              "some text"
            );
            tinymce.activeEditor.selection.moveToBookmark(bm); */
            /* e.target.selection.moveToBookmark(bm); */
            /* var bm = tinymce.activeEditor.selection.getBookmark(); */
            // Restore the selection bookmark
            /* e.target.selection.moveToBookmark(selector); */
          }}
          onSelectionChange={(e) => {
            if (e) {
              /* console.log(editorRef.current.activeEditor) */
              // Stores a bookmark of the current selection
              /* var bm = tinymce.activeEditor.selection.getBookmark();
              
              tinymce.activeEditor.setContent(
                tinymce.activeEditor.getContent() + "Some new content"
              );

              // Restore the selection bookmark
              tinymce.activeEditor.selection.moveToBookmark(bm); */
              /* setSelector(e);
              if (onSelectionChange) onSelectionChange(e); */
            }
            /* console.log("Selection: ", e); */
          }}
          initialValue={`${content && content.replace(
            /\/assets\//g,
            `${URL_BASE_ACELLE}/assets/`
          )}`}
          init={{
            height: props.height || 250,
            menubar: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "forecolor backcolor" +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            color_map: [
              "#BFEDD2",
              "Light Green",
              "#FBEEB8",
              "Light Yellow",
              "#F8CAC6",
              "Light Red",
              "#ECCAFA",
              "Light Purple",
              "#C2E0F4",
              "Light Blue",

              "#2DC26B",
              "Green",
              "#F1C40F",
              "Yellow",
              "#E03E2D",
              "Red",
              "#B96AD9",
              "Purple",
              "#3598DB",
              "Blue",

              "#169179",
              "Dark Turquoise",
              "#E67E23",
              "Orange",
              "#BA372A",
              "Dark Red",
              "#843FA1",
              "Dark Purple",
              "#236FA1",
              "Dark Blue",

              "#ECF0F1",
              "Light Gray",
              "#CED4D9",
              "Medium Gray",
              "#95A5A6",
              "Gray",
              "#7E8C8D",
              "Dark Gray",
              "#34495E",
              "Navy Blue",

              "#000000",
              "Black",
              "#ffffff",
              "White",
            ],
            skin: false,
            content_css: true,
            automatic_uploads: true,
            images_upload_url: "postAcceptor.php",
            images_reuse_filename: true,
            content_style: [
              bootstrapUI,
              customStyle,
              contentCss,
              contentUiCss,
            ].join("\n"),
          }}
        />
      )}
    </div>
  );
};
export default HTMLEditor;
