import styled from "styled-components";

export const WrapperPlanDetail = styled.div`
  margin: 0px;
  background: #fff;
  height: 341px;
  border-radius: 6px;
  width: 100%;
  box-shadow: 2px 2px 2px #ccc;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .container-card {
    width: 220px;
    padding: 30px;
    border-radius: 12px !important;
    color: rgb(255, 255, 255) !important;
    padding: 8px;
    background: linear-gradient(90deg,#021049,#1d37a2);
    // height: 264px !important;
  }
  & .plan-name {
    padding: 4px 14px 2px 20px;
    h3 {
      color: #fff !important;
      margin-bottom: 0px !important;
    }
  }
  & .plan-price {
    font-size: 22px;
    font-weight: 500;
  }
  & .container-upgradeplan .ant-btn-primary {
    background: rgb(250, 173, 20) !important;
    color: rgb(255, 255, 255) !important;
    font-size: 14px !importan;
  }
  & .plan-price-container {
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }
  & .container-plan-description {
    font-size: 14px;
  }
`;