import styled from "styled-components";
export const TaskWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  & .anticon {
    margin: 0px 4px;
  }
`;
export const EventWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  & .anticon {
    margin: 0px 4px;
  }
`;
export const CalendarWrapper = styled.div`
  background: #fff !important;
  padding: 10px 20px;
`;

export const WrapperCalendar = styled.div`
  .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }
`;
export const WrapperEvents = styled.div`
  & .section-footer {
    .ant-btn {
      border-radius: 18px !important;
      border-color: var(--primary-button);
      color: var(--primary-button);
      :hover {
        background: var(--primary-button);
        color: #fff;
      }
    }
  }
  & .ant-form-item input,
  .ant-select-selection {
    height: 32px !important;
  }
  & .ant-select-lg .ant-select-selection--single {
    min-height: 32px !important;
  }
  & .ant-select-selection__placeholder {
    line-height: 22px !important;
  }
  & .ant-select-lg .ant-select-selection__rendered {
    line-height: 24px !important;
  }
`;
export const Wrapper = styled.div`
  min-height: 300px !important;
  & .container-car {
    height: 548px;
  }
  & .ant-card-body {
    padding: 10px !important;
  }
  & .section-events {
    h4 {
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      padding: 4px 0px;
    }
  }
  & .ant-list-header {
    padding-top: 45px !important;
    border-bottom: 0px !important;
  }
  & .ant-list-header h2 {
    color: rgba(0, 0, 0, 0.75) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-bottom: 0px !important;

    display: flex;
    justify-content: start;
    align-items: center;
  }
  & .ant-list-header h2 > * {
    margin: 0px 4px !important;
  }
  & .ant-list-header i {
    font-size: var(--font-size-medium) !important;
  }
  & .description {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  & .ant-card-bordered {
    border: 1px solid #e8e8e870 !important;
    border-radius: 8px;
    box-shadow: 0 0 10px 10px rgb(0 0 0 / 3%) !important;
    /*  margin: 10px !important;
    margin-top: 20px !important; */
  }
  & .ant-list-items {
    max-height: 258px !important;
    margin-bottom: 10px !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  & li.ant-list-item {
    padding: 0px !important;
  }
  & .ant-empty {
    min-height: 236px !important;
  }
  & .container-information {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .container-task-birthday {
    width: 100%;
    .task-color,
    .birthday-color {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      margin: 6px 0px;
      width: 100%;
    }
    & .task-color {
      background: #ef6a6a;
      color: #fff;
    }
    & .birthday-color {
      background: #4fc38f;
      color: #fff;
    }
    & .meetings-color {
      background: #50a5f1;
      color: #fff;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      margin: 6px 0px;
      width: 100%;
    }
  }
  & .date-cell {
    padding-top: 6px;
    .list-tag {
      padding: 0px;

      .item-subject-month-task {
        background: #ef6a6a;
        color: #fff;
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
      .item-subject-task {
        background: #ef6a6a;
        color: #fff;
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 88px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }

      .item-subject-month-event {
        background: #50a5f1;
        color: #fff;
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 166px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
      .item-subject-month-meeting {
        background: #50a5f1;
        color: #fff;
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 166px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
      .item-subject-meeting {
        background: #50a5f1;
        color: #fff;
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 88px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
      .item-subject-event {
        background: #50a5f1;
        color: #fff;
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 88px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
      .birthday-tag {
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 88px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
      .birthday-tag-month {
        font-size: 9px !important;
        width: 100%;
        border-radius: 2px !important;
        font-weight: 300;
        margin: 4px 0px;
        span {
          max-width: 88px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 4px;
      }
    }
  }
  & .ant-fullcalendar-month-select {
    margin-left: 0px !important;
  }
  & .ant-select-selection {
    display: block;
    background: #4458b8 !important;
    color: #fff !important;
    border-color: var(--primary-button) !important;
    border-right-color: #4458b8 !important;
    border-radius: 0px;
    display: block;
    background: #4458b8 !important;
    color: #fff !important;
    span.ant-select-arrow {
      color: #fff !important;
    }
  }
  & .create-events {
    margin: 20px 0px;
    .ant-btn {
      border-color: var(--primary-button) !important;
      color: var(--primary-button) !important;

      :hover {
        background: var(--primary-button) !important;
        color: #fff;
      }
    }
  }
  & .section-title-modal {
    h3 {
      font-size: 14px !important;
    }
  }
  & .container-card-create {
    width: 100%;
    background: #556ee6;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    padding: 18px;
    .ant-card-body {
      p {
        margin-bottom: 0px !important;
      }
    }
  }
  @media (min-width: 992px) {
    .subject h3 {
      max-width: 120px !important;
    }
  }
  @media (min-width: 768px) {
    .subject h3 {
      max-width: 94px !important;
    }
  }
`;
export const WrapperItem = styled.div`
  width: 100%;
 padding: 0px; !important;
 margin-top: 16px;
  cursor: pointer;
  & .subject {
    h3 {
      font-size: 11px;
      margin-bottom: 0px !important;
      max-width: 152px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  & .date {
    .ant-tag {
      font-weight: 500 !important;
      margin-right: 0px !important;
      background: #cccccc63;
      border-color: #cccccc63;
      color: #938f8f;
      :hover {
        background: #4fc38f !important;
        color: #fff !important;
      }
    }
  }

  // old
  &.item-shadow {
    background: #f6f7fe !important;
  }
  & .ant-list-item-meta-title {
    font-size: 12px !important;
    text-transform: capitalize;
  }
  & .ant-list-item-meta-avatar {
    margin-right: 6px !important;
  }
  & .ant-avatar-sm {
    overflow: visible !important;
    width: 18px!important;
    height: 18px!important;
  }
  & .date {
    display: block;
    width: 100%;
  }
  & .group-container {
    margin-top: 0px !important;
  }
  & .item-date {
    border-radius: 30px !important;
    padding: 0px 4px !important;
    background: #ccc;
    color: rgba(0, 0, 0, 0.75);

    display: flex;
    justify-content: start;
    align-items: center;
  }
  & .item-date i {
    margin-right: 4px !important;
  }
  & .item-date.expire-item {
    background: #e6e6e6 !important;
    color: #ef6a6a;
    font-size: 10px;
    transition-property: all;
    transition-duration: 0.05s;
  }
  & .item-date-task {
    background: #ef6a6a;
    color: #fff!important;
    color: #ef6a6a;
    font-size: 10px;
    border-radius: 8px;
    padding: 0px 8px;
    min-width: 40 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition-property: all;
    transition-duration: 0.05s;
  }
  & .item-date-event {
    background: #50a5f1;
    color: #fff!important;
    color: #ef6a6a;
    font-size: 10px;
    border-radius: 8px;
    padding: 0px 8px;
    min-width: 40 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition-property: all;
    transition-duration: 0.05s;
  }
  & .item-date-meeting {
    background: #50a5f1;
    color: #fff!important;
    color: #ef6a6a;
    font-size: 10px;
    border-radius: 8px;
    padding: 0px 8px;
    min-width: 40 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition-property: all;
    transition-duration: 0.05s;
  }
  & .date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;
    font-size: 14px !important;
  }
  & span.item-date.expire-item:hover {
    background: #ef6a6a !important;
    color: #fff;
    transform: scale(1.04);
  }
  & .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  & .meta-description-birhtday{

    .ant-list-item-meta-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .ant-list-item-meta-description {
        padding: 0px 6px;
     }
    }
    .date{
      .tag-item{
        color: #fff!important;
        color: #fff !important;
        font-size: 10px!important;
    
      }
    }
  }
`;
