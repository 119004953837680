import image from "./Image"
import iframe from "./Iframe"
import video from "./Video"
import audio from "./Audio"

export {
    iframe,
    iframe as pdf,
    video as mp4,
    video as webm,
    audio as wav,
    audio as mp3,
    image as png,
    image as jpg,
    image as jfif,
    image as jpeg,
}