import React, { useEffect } from "react";
import { Layout, Divider, AutoComplete, Icon, Input, Col, Row } from "antd";
import TopBar from "./TopBar";

import styled from "styled-components";

import SideMenu from "../menu/SideMenu";
import store from "../../redux/stores";
/* import actions from "../../redux/actions/search_actions"; */

import { search } from "../../services";
import { navigate, Link } from "@reach/router";

const Search = styled(AutoComplete)`
  border-color: transparent !important;

  border-radius: 12px !important;
  border-width: 0px !important;
  border-right-width: 0px !important;
  /* border-bottom: 2px solid #ccc !important; */

  font-size: 20px !important;

  background-color: #03a0b6 !important;
  & .ant-select-selection {
    width: 350px;
  }
  & .ant-input,
  .ant-input:hover,
  .ant-select-selection,
  &.ant-select-focused .ant-select-selection .ant-input:hover,
  &.ant-select-focused .ant-select-selection .ant-input:focus {
    border-right-width: 0px !important;
    box-shadow: none !important;

    border-width: 0px !important;
    border-radius: 12px !important;
    border: 0px !important;
    box-shadow: none !important;
    border-right-width: 0px !important;
    color: var(--color-white) !important;
    background-color: rgba(0, 0, 0, 0.01) !important;

    font-size: var(--font-size-small) !important;
  }

  & .ant-input-suffix {
    color: var(--color-white) !important;
  }
`;
/* const { setSearchParam } = actions; */
const Logo = require("../../sources/images/logo.svg");
const { Header } = Layout;

const LogoContainer = styled.div`
  width: auto;
  max-width: 80px;
  height: 50px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  margin-right: 20px;

  & .logo {
    background-size: 100% 100% !important;
    height: 100% !important;
    width: 80px;
    padding: 10px 0px;
    /*  margin: 0px 10px 0px 0px; */
    background-repeat: no-repeat !important;
    transition: all 0.25s ease;
  }
  & .logo:hover {
    transform: scale(1.08);
  }
`;

const Navigation = styled(Header)`
  background-color: var(--color-primary) !important;
  z-index: 1;
  width: 100%;
  padding: 0px !important;
  /* box-shadow: 0px 0 10px #00000029; */
  padding-left: 45px;
  height: auto !important;
  display: flex;
  align-items: center;
`;

const Container = styled(Row)`
  background-color: var(--color-primary) !important;
  border-radius: 0 0 17px 17px;
  width: 100%;

  padding: 4px 0px;

  box-shadow: 0px 0 10px #00000029 !important;
`;
const SearchContainer = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  float: right;
  height: 100%;
`;
const NavBar = ({ children, ...props }) => {
  useEffect(() => {
    store.subscribe(function() {
      alert(JSON.stringify(store.getState()));
    });
    search("");
    return () => {};
  }, []);
  const search = (value) => {};
  const onSearch = (value) => {
    search(value);
  };

  const handleOnClik = () => {};
  return (
    <Navigation>
      <Container type="flex" justify="center" align="stretch" gutter={8}>
        <Col span={24}>
          <Row
            type="flex"
            justify="space-between"
            gutter={[{ xs: 8, sm: 16, md: 24, lg: 32, xl: 16, xxl: 16 }, 20]}
          >
            <Col>
              <LogoContainer className="logo-container">
                <Link
                  to="/"
                  className="logo"
                  style={{
                    background: `url(${Logo})`,
                  }}
                />{" "}
              </LogoContainer>
              <SearchContainer>
                <Search size="large" placeholder="Qué estás buscando?">
                  <Input
                    suffix={
                      <Icon type="search" className="certain-category-icon" />
                    }
                  />
                </Search>
              </SearchContainer>
            </Col>
            <Col align="right">
              <SideMenu onClik={handleOnClik} />
            </Col>
          </Row>
        </Col>
      </Container>
    </Navigation>
  );
};

export default NavBar;
