import styled from "styled-components";
export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  & svg {
    font-size: 18px;
  }
  li.ant-menu-item {
    background: #f7f6f6 !important;
  }
  & .btn-dropdown {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }
  & .option-selected {
    background: #f3f3f3;
    text-transform: capitalize;
    font-size: 14px;

    padding: 0px 8px;
    border-radius: 20px;
    border: 1px solid #bdbdbd66;
    box-shadow: 1px 3px 3px #cccccc5e;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
`;
