const routes = [
  {
    path: "/",
    name: "dashboard",
    //component: Dashboard,
  },
  {
    path: "/my-favorites",
    name: "favorites",
    //component: Favorites,
  },
  {
    path: "/saved-search",
    name: "saved-search",
    //component: MySavedListings,
  },
  {
    path: "/mylistings",
    name: "mylistings",
    edit: {
      path: "/mylistings/:id",
      name: "listings",
      //component: Listing,
    },
    create: {
      path: "/mylistings/create",
      name: "listings",
      //component: Listing,
    },
    //component: MyListings,
  },

  {
    path: "/users",
    name: "users",
    permissions: ["admin"],
    //component: Users,
    edit: {
      path: "/users/:id",
      name: "userform",
      //component: UserForm,
    },
    create: {
      path: "/users/create",
      name: "userform",
      //component: UserForm,
    },
  },
  {
    path: "/approved-listings",
    name: "approved-listings",
    permissions: ["admin"],
    //component: ApproveListings,
  },
  {
    path: "/company-request",
    name: "company-request",
    //component: CompanyRequest,
  },
  {
    path: "/plans",
    name: "plans",
    //component: Plans,
    edit: {
      path: "/plans/:id",
      name: "Plans",
      //component: PlansForm,
    },
    create: {
      path: "/plans/create",
      name: "Plans",
      //component: PlansForm,
    },
  },
  {
    path: "/campaigns",
    name: "campaigns",
    //component: Campaigns,
    edit: {
      path: "/campaigns/:id",
      name: "campaigns",
      //component: CampaignsForm,
    },
    create: {
      path: "/campaigns/create",
      name: "campaigns",
      //component: CampaignsForm,
    },
  },
  {
    path: "/lists",
    name: "email-lists",
    //component: EmailLists,
    edit: {
      path: "/lists/:id",
      name: "EmailLists",
      //component: EmailListForm,
    },
    create: {
      path: "/lists/create",
      name: "EmailLists",
      //component: EmailListForm,
    },
  },
  {
    path: "/mail-templates",

    name: "mail-templates",
    //component: TemplatesAcelle,

    edit: {
      path: "/mail-templates/:id",
      name: "TemplatesAcelle",
      //component: TemplatesAcelleForm,
    },
    create: {
      path: "/mail-templates/create",
      name: "TemplatesAcelle",
      //component: TemplatesAcelleForm,
    },
  },
  {
    path: "/subscribers",
    name: "subscribers",
    //component: SubscribersList,
    edit: {
      path: "/subscribers/:id",
      name: "EmailLists",
      //component: SubscribersListForm,
    },
    create: {
      path: "/subscribers/create",
      name: "EmailLists",
      //component: SubscribersListForm,
    },
  },

  {
    path: "/social",
    name: "socials",
    permissions: ["admin", "broker", "agent"],
    //component: SocialWidgets,
    edit: {
      path: "/social/:social",
      name: "social",
      //component: Socials,
    },
  },

  {
    path: "/agents",
    name: "agents",
    permissions: ["admin", "broker"],
    //component: Agents,
    edit: {
      path: "/agents/:id",
      name: "agent",
      //component: AgentForm,
    },
    create: {
      path: "/agents/create",
      name: "agent",
      //component: AgentForm,
    },
  },
  {
    path: "/contacts",
    name: "contacts",
    //component: Contacts,
    edit: {
      path: "/contacts/:id",
      name: "contact",
      //component: ContactForm,
    },
    create: {
      path: "/contacts/create",
      name: "contact",
      //component: ContactForm,
    },
  },
  {
    path: "/foreclosures",
    name: "foreclosures",
    edit: {
      path: "/foreclosures/:id",
      name: "foreclosure",
    },
    create: {
      path: "/foreclosures/create",
      name: "foreclosure",
    },
  },
  {
    path: "/tasks",
    name: "tasks",
    edit: {
      path: "/tasks/:id",
      name: "tasks",
      //component: TaskForm,
    },
    create: {
      path: "/tasks/create",
      name: "tasks",
      //component: TaskForm,
    },
    //component: Tasks,
  },
  {
    path: "/histories",
    name: "histories",
    edit: {
      path: "/histories/:id",
      name: "historie",
      //component: HistoryForm,
    },
    create: {
      path: "/histories/create",
      name: "historie",
      //component: HistoryForm,
    },
    //component: Histories,
  },
  {
    path: "/leads",
    name: "leads",
    edit: {
      path: "/leads/:id",
      name: "lead",
      //component: LeadsForm,
    },
    create: {
      path: "/leads/create",
      name: "lead",
      //component: LeadsForm,
    },
    //component: Leads,
  },
  {
    path: "/accounts",
    name: "accounts",
    edit: {
      path: "/accounts/:id",
      name: "Account",
      //component: AccountForm,
    },
    create: {
      path: "/accounts/create",
      name: "Account",
      //component: AccountForm,
    },
    //component: Accounts,
  },
  {
    path: "/agents-contacts",
    name: "agents-contacts",
    permissions: ["admin"],
    //component: ContactsAgents,
    edit: {
      path: "/agents-contacts/:id",
      name: "agents-contact",
      //component: ContactsAgentsForm,
    },
  },
  {
    path: "/loan-officers",
    name: "loan-officers",
    permissions: ["admin"],
    //component: LoanOfficers,
    edit: {
      path: "/loan-officers/:id",
      name: "Loan Officers",
      //component: LoanOfficersForm,
    },
  },
  {
    path: "/expired-listings",
    name: "expired-listings",
    permissions: ["admin"],
    //component: ExpiredListings,
  },
  {
    path: "/log-actions",
    name: "log-actions",
    permissions: ["admin"],
    //component: LogActions,
  },
  {
    path: "/log-posts",
    name: "log-posts",
    permissions: ["admin"],
    //component: LogPost,
  },
  {
    path: "/workflow",
    name: "workflow",
    //component: Workflow,
    edit: {
      path: "/workflow/:board_id/lead/:contact_id",
      name: "workflow",
      //component: Workflow,
    },
  },
  {
    path: "/forms",
    name: "forms",
    // component: Form,
    show: {
      path: "/forms/:form_id/section/:section_id",
      name: "forms",
      // component: Form,
    },
    /* edit: {
      path: "/forms/:form_id",
      name: "forms",
      component: Form,
    }, */
  },

  /*  {

    path: "/site-builder",
    name: "site-builder",
    //component: SiteBuilder,
    edit: {
      path: "/site-builder/:page_id",
      name: "site-builder",
      //component: SiteBuilder,
    },
  },  */
  {
    path: "/boards",
    name: "boards",
    //component: Boards,
    edit: {
      path: "/boards/:id/tasks/:task_id",
      name: "boards",
      //component: Boards,
    },
  },
  {
    path: "/boards/:id/tasks/:task_id",
    name: "boards",
    //component: Boards,
  },
  {
    path: "/boards/:id/tasks",
    name: "boards",
    //component: Boards,
  },
  {
    path: "/events",
    name: "events",

    //component: Events,
  },
  {
    path: "/teams",
    name: "teams",
    //component: Teams,
    edit: {
      path: "/teams/:id",
      name: "team",
      //component: Team,
    },
  },

  {
    path: "/teams/:id",
    name: "teams",
    //component: Team,
  },
  {
    path: "/sales",
    name: "sales",
    //component: Sales,
    edit: {
      path: "/sales/:id",
      name: "sales",
      //component: SalesDetail,
    },
  },
  {
    path: "/test",
    //component: Test,
  },
  {
    path: "/owners",
    name: "owners",
    permissions: ["admin"],
    //component: Owners,
    edit: {
      path: "/owners/:id",
      name: "owner",
      //component: OwnerForm,
    },
    create: {
      path: "/owners/create",
      name: "owner",
      //component: LeadsForm,
    },
  },
  {
    path: "/members",
    name: "members",
    permissions: ["admin"],
    //component: Members,
  },
  {
    path: "/statistics",
    name: "statistics",
    permissions: ["admin"],
    //component: Statistics,
  },
  {
    path: "/subcriptions",
    name: "subcriptions",
    permissions: ["admin"],
    //component: Subscription,
    edit: {
      path: "/subcriptions/:id",
      name: "Subscription",
      //component: SubscriptionForm,
    },
    create: {
      path: "/subcriptions/:id",
      name: "Subscription",
      //component: SubscriptionForm,
    },
  },
  {
    path: "/plugins",
    name: "plugins",
    permissions: ["admin"],
    //component: Plugins,
    edit: {
      path: "/plugins/:id",
      name: "Plugins",
      //component: PluginsForm,
    },
    create: {
      path: "/plugins/:id",
      name: "Plugins",
      //component: PluginsForm,
    },
  },
  {
    path: "/plugin-plans",
    name: "plugin-plans",
    permissions: ["admin"],
    //component: PluginPlans,
    edit: {
      path: "/plugin-plans/:id",
      name: "Plugin Plans",
      //component: PluginsPlanForm,
    },
    create: {
      path: "/plugin-plans/:id",
      name: "Plugin Plans",
      //component: PluginsPlanForm,
    },
  },
  {
    path: "/modules",
    name: "modules",
    permissions: ["admin"],
    //component: Modules,
    /* edit: {
      path: "/modules/:id",
      name: "Modules",
      //component: ModuleForm,
    }, */
    edit: {
      path: "/modules/:id",
      name: "Module",
      //component: ModuleForm,
    },
  },
  {
    path: "/business-plans",
    name: "business-plans",
    permissions: ["admin"],
    //component: BusinessPlans,
    edit: {
      path: "/business-plans/:id",
      // name: "businesplanFormshow",
      name: "businessplansform",
      //component: BusinessPlansForm,
    },
    /*  edit: {
      path: "/business-plans/show/:id",
      name: "businesplanFormshow",
      //component: BusinesPlanFormShow,
    }, */
    create: {
      path: "/business-plans/create",
      name: "businessplansform",
      //component: BusinessPlansForm,
    },
  },
  {
    path: "/tags",
    name: "tags",
    permissions: ["admin"],
    //component: Tags,
    edit: {
      path: "/tags/:id",
      name: "Tags",
      //component: TagsForm,
    },
    create: {
      path: "/tags/:id",
      name: "Tags",
      //component: TagsForm,
    },
  },
  {
    path: "/customer",
    name: "customer",
    permissions: ["admin"],
  },
  {
    path: "/customer-services",
    name: "customerservices",
  },
];
export default routes;
