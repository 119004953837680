import React, { useState, useEffect } from "react";



const Notfound = ({ ...props }) => {
    return (
        <>
            <div>
                <span>Not Found</span>
            </div>
        </>
    )
}
export default Notfound;