import React, { useState, useEffect } from "react";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { Wrapper } from "./Styles";
import _ from "lodash";
import { getService } from "../../../services";
const MenuItem = ({ optionValue, optionText, onItemSelected, ...props }) => {
  const [choices, setChoices] = useState([]);
  const [selected, setSelected] = useState();
  const [record, setRecord] = useState();
  const [initialValue, setInitialValue] = useState();
  const [initialized, setInitialized] = useState(false);
  const handleOnclick = ({ key }) => {
    let record = choices.find((item) => item[optionValue] == key);
    setSelected(key);
    setRecord(record);
    if (onItemSelected) onItemSelected({ key }, record);
  };
  useEffect(() => {
    if (typeof props.choices) setChoices(props.choices);
  }, [props.choices]);
  useEffect(() => {
    if (!_.isEqual(props.record, record)) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (initialValue) setSelected(initialValue);
    if (record) setSelected(record[optionValue] || initialValue);
    if (!record) setSelected(null);
  }, [record, initialValue]);
  useEffect(() => {
    if (selected) setSelected(record[optionValue]);
  }, [selected]);
  useEffect(() => {
    if (!initialized) {
      if (props.initialValue) setInitialValue(props.initialValue);
      setInitialized(true);
    }
  }, [props.initialValue]);
  useEffect(() => {
    return () => {
      setSelected(null);
      setRecord(null);
    };
  }, []);
  if (!initialized) return null;
  return (
    <Menu
      defaultSelectedKeys={[initialValue]}
      selectedKeys={[selected]}
      onClick={(e) => handleOnclick(e)}
    >
      {choices.map((it) => (
        <Menu.Item key={it[optionValue]}>{it[optionText]}</Menu.Item>
      ))}
    </Menu>
  );
};
const MenuOption = ({
  reference,
  source,
  icon,
  autoLoad = true,
  children,
  buttonText,
  toolTipText,
  buttonType,
  initialValue,
  trigger = "click",
  placement = "bottomCenter",
  toolTipPlacement = "bottomCenter",
  optionValue = "_id",
  optionText = "name",
  onChange,
  style,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState();
  const [optionSelectedText, setOptionSelectedText] = useState();
  const [choices, setChoices] = useState([]);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const handleMenuItemClick = ({ key }, record) => {
    setRecord(record);
    if (record) setOptionSelectedText(record[optionText]);
    if (onChange) onChange(source, key, record);
  };
  const getData = async () => {
    try {
      if (reference) {
        const service = getService(reference);
        setLoading(true);
        let { data } = await service.find({
          query: {
            ...filterDefaultValues,
          },
        });
        setLoading(false);
        setChoices(data);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (typeof props.choices) setChoices(props.choices);
  }, [props.choices]);
  useEffect(() => {
    if (choices && choices.length) setLoading(!choices.length > 0);
  }, [choices]);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (autoLoad) getData();
    else if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (!_.isEqual(props.record, record)) setRecord(props.record);
  }, [props.record]);
  return (
    <Wrapper>
      {optionSelectedText && (
        <div className="option-selected">
          <span> {optionSelectedText}</span>
          <Button
            type="link"
            icon="close"
            onClick={() => {
              setOptionSelectedText(null);
              setRecord(null);
              if (onChange) onChange(source);
            }}
          />
        </div>
      )}
      <Dropdown
        trigger={[trigger]}
        placement={placement}
        overlayClassName={"menu-options"}
        overlay={
          <MenuItem
            record={record}
            optionValue={optionValue}
            optionText={optionText}
            choices={choices}
            initialValue={initialValue}
            onItemSelected={handleMenuItemClick}
          />
        }
      >
        <Tooltip placement="top" title={toolTipText}>
          <Button
            style={style}
            shape={props.shape}
            className="btn-dropdown"
            loading={loading}
            type={buttonType || "link"}
            icon={typeof icon == "string" ? icon : undefined}
          >
            {!optionSelectedText &&
              icon &&
              typeof icon != "string" &&
              !loading && <span>{React.cloneElement(icon)}</span>}
            {buttonText && !loading && !optionSelectedText && (
              <span>{buttonText}</span>
            )}
          </Button>
        </Tooltip>
      </Dropdown>
    </Wrapper>
  );
};
export default MenuOption;
