import React from "react";
import LayoutCenter from "../../../layouts/layout_center";
import SetPasswordForm from "../../../components/authentication/SetPasswordForm";

const SetPassword = ({ token, ...props }) => {
  return (
    <LayoutCenter showLogo={false}>
      <SetPasswordForm token={token} />
    </LayoutCenter>
  );
};

export default SetPassword;
