import React, { useState, useCallback, useEffect } from "react";
import { Wrapper, Tools, PhotoWrapper } from "./Styles";
import { Row, Col, Button, Icon, Avatar, List } from "antd";
import Gallery from "react-photo-gallery";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { URL_CDN, URL_S3, extensions } from "../../constants";
import { Modal } from "../../components/";
import FileViewer from "../fileviewer/FileViewer";
import moment from "moment";
const { Item } = List;
const { Meta } = Item;
const MAX_PHOTOS = 8;
const imgWithClick = { cursor: "pointer" };
const actions = { delete: true, download: true };
const defaultFormats = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "video/mp4",
  "video/webm",
];
const Media = ({
  index,
  onClick,
  photos,
  onRemove,
  onSelectItem,
  record,
  photo,
  margin,
  direction,
  draggable = true,
  top,
  left,
}) => {
  const [ext, setExt] = useState();
  const [type, setType] = useState();
  const imgStyle = { margin: margin };
  let { src } = photo;
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = (photo) => {
    if (onSelectItem) onSelectItem(photo);
  };
  const handleRemove = (item) => {
    /* alert(JSON.stringify(item.photo.pathname)); */
    if (onRemove) onRemove(item.photo.pathname);
  };
  useEffect(() => {
    if (src) {
      let ext = src.substring(src.lastIndexOf(".") + 1, src.lastIndexOf("?"));
      setExt(ext);
      defaultFormats.forEach((it) => {
        let ext_type = it.replace(/(video\/)/, "").replace(/(image\/)/, "");
        let type = it.replace(`/${ext_type}`, "");
        if (ext_type === ext) {
          setType(type);
        }
      });
    }
  }, [src]);
  return (
    <PhotoWrapper
      draggable={draggable}
      className={`image-container ${
        photos.length > 1 ? "photos-container" : "single-photo-container"
      }`}
    >
      <div className="tools">
        {draggable && (
          <Button
            onClick={() => handleRemove(record)}
            icon="close"
            type="link"
          />
        )}
      </div>
      <div className="image">
        {ext && type == "image" && (
          <img
            onClick={() => handleClick(photo)}
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            {...photo}
            alt="img"
          />
        )}
        {ext && type == "video" && (
          <video
            width="100%"
            controls
            autoplay
            onClick={() => handleClick(photo)}
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
          >
            <source
              src={photo.src.replace(URL_CDN, URL_S3)}
              type={`video/${ext}`}
            />
            Your browser does not support the video tag.
          </video>
        )}
        {!draggable && photos.length > 4 && index == photos.length - 1 - 4 && (
          <div onClick={() => handleClick(photo)} className="more-photos">
            <h3>+{photos.length - 4}</h3>
            <span>more</span>
          </div>
        )}
      </div>
    </PhotoWrapper>
  );
};

const Mygallery = ({
  draggable = true,
  size = "default",
  source,
  record,
  max_photos = MAX_PHOTOS,
  name,
  ...props
}) => {
  const [photos, setPhotos] = useState([]);
  const [index, setIndex] = useState();
  const [visible, setVisible] = useState(false);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let new_photos = arrayMove(photos, oldIndex, newIndex);
    let value = new_photos.map((it) => it.src.replace(`${URL_CDN}/`, ""));
    if (props.onChange) props.onChange(value);
    setPhotos(new_photos);
  };
  const handleRemove = (url) => {
    let new_photos = photos.filter((it) => it.src != url);
    if (props.onRemove)
      props.onRemove(
        source || name,
        new_photos.map((it) => it.src.replace(`${URL_CDN}/`, ""))
      );
    setPhotos(new_photos);
  };
  const SortableMedia = SortableElement((item) => (
    <Media onRemove={handleRemove} photos={photos} {...item} />
  ));
  const SortableGallery = SortableContainer(({ items, size }) => (
    <Wrapper
      className="my-gallery-draggable"
      size={size}
      photosCount={items.length}
    >
      <Gallery
        photos={items.map((item) => ({
          ...item,
          pathname: item.src,
          src: `${item.src}?width=400&height=400`,
        }))}
        renderImage={(item) => (
          <SortableMedia draggable={draggable} record={item} {...item} />
        )}
      />
    </Wrapper>
  ));
  const imageRenderer = (item) => {
    return (
      <Media
        onSelectItem={(photo) => {
          setVisible(true);
          setIndex(item.index);
        }}
        onRemove={handleRemove}
        draggable={draggable}
        photos={photos}
        {...item}
      />
    );
  };
  useEffect(() => {
    if (props.photos) {
      let photos = props.photos.map((image, index) => ({
        src: `${URL_CDN}/${image}`,
        width: index == 0 ? 4 : 1,
        height: index == 0 ? 3 : 1,
      }));
      setPhotos(photos.filter((it, index) => index < max_photos));
    }
  }, [props.photos]);
  useEffect(() => {
    if (record) {
      if (record[source || name]) {
        let photos = record[source || name].map((image, index) => ({
          src: `${URL_CDN}/${image}`,
          width: index == 0 ? 4 : 1,
          height: index == 0 ? 3 : 1,
        }));
        setPhotos(photos.filter((it, index) => index < max_photos));
      }
    }
  }, [record]);
  if (draggable)
    return (
      <SortableGallery
        size={size}
        items={photos}
        onSortEnd={onSortEnd}
        axis={photos.length > 1 ? "xy" : undefined}
      />
    );
  return (
    <Wrapper className="my-gallery" size={size} photosCount={photos.length}>
      <Row type="flex" aligntop="center" justify="center" gutter="10">
        <Col xl={24}>
          <Gallery
            photos={photos
              .filter((it, index) => {
                if (!draggable && index > 3) return false;
                return true;
              })
              .map((item) => ({
                ...item,
                index,
                src: `${item.src}?width=400&height=400`,
              }))}
            renderImage={imageRenderer}
          />
        </Col>
      </Row>
      <Modal
        transparent
        width={"100%"}
        style={{ top: 0, height: "100vh" }}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {
          <FileViewer
            onChange={(index) => setIndex(index)}
            index={index}
            actions={{ delete: true, download: true }}
            record={record}
            dataSource={photos.map((it) => ({
              ...it,
              path: it.src.replace("https://imagecdn.gooneworld.com/", ""),
              name: it.src.replace("https://imagecdn.gooneworld.com/", ""),
            }))}
          />
        }
      </Modal>
    </Wrapper>
  );
};
export default Mygallery;
