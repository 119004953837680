import React, { useState, useEffect } from "react";
import { Wrapper } from "./Styles";
import { Row, Col, Tag, Tabs, Button, Tooltip } from "antd";
import CardProperties from "../../card/CardProperties";
import { useSelector } from "react-redux";
import { listing_status } from "../../../constants";
import { getService } from "../../../services";

const { TabPane } = Tabs;
const ListCardProperties = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [statistics, setStatistics] = useState({});
  const [selected, setSelected] = useState("offfice-listings");
  const user = useSelector(({ user }) => user);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const handleTagClick = (key) => {
    setSelected(key);
  };
  const setFilters = (key) => {
    let { company } = user;
    switch (key) {
      case "my-listings":
        if (user) {
          setFilterDefaultValues({
            StandardStatus: { $in: listing_status },
            MemberMlsId: user.MemberMlsId,
          });
        }
        break;
      case "offfice-listings":
        setFilterDefaultValues({
          StandardStatus: { $in: listing_status },
          $or: [
            {
              ListOfficeMlsId:
                user.MlsId ||
                user.OfficeMlsId ||
                (user.company && user.company.MlsId) ||
                (user.company && user.company.OfficeMlsId),
            },
          ],
        });
        break;
      default:
        break;
    }
  };
  const handleSyncListings = () => {
    const service = getService("update-listing-company");
    setLoading(true);
    service
      .find({})
      .then(({ data }) => {
        setLoading(false);
        setRefresh((refresh) => !refresh);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (selected) setFilters(selected);
  }, [selected]);
  console.log("que tengo de mls user:::", user);
  if (!user) return "Loading...";
  return (
    <Wrapper>
      <Row
        className="section-container-contanier"
        type="flex"
        justify="space-between"
        gutter={[24, 24]}
      >
        <Col span={24}>
          <Row
            gutter={[16, 8]}
            type="flex"
            justify="space-between"
            align="middle"
          >
            {/* <Col xl={4} xs={24} md={24} className="contaner-section">
              <div className="section-title">
                <span>Properties</span>
              </div>
            </Col> */}
            <Col xl={24} xs={24} md={24} className="section-tag">
              <div>
                <div className="section-tag">
                  <Tag
                    className={
                      selected === "offfice-listings"
                        ? "active-tag"
                        : "inactive"
                    }
                    onClick={() => handleTagClick("offfice-listings")}
                  >
                    Office Listings
                  </Tag>
                  <Tag
                    className={
                      selected === "my-listings" ? "active-tag" : "inactive"
                    }
                    onClick={() => handleTagClick("my-listings")}
                  >
                    My Listings
                  </Tag>
                </div>
              </div>
              <div>
                <Tooltip title="Sync Listings">
                  <Button
                    loading={loading}
                    onClick={handleSyncListings}
                    icon="sync"
                    type="link"
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="container-listcard">
            <CardProperties
              refresh={refresh}
              reference="properties"
              filterDefaultValues={filterDefaultValues}
            />
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default ListCardProperties;
