import React, { useState, useEffect } from "react";
import { Wrapper } from "./Styles";
import {
  Checkbox,
  Icon,
  Input,
  Radio,
  Select,
  AutoComplete,
  Button,
  DatePicker,
  InputNumber,
  Col,
  Row,
  Divider,
  message,
  Tooltip,
} from "antd";
import styled from "styled-components";
import * as actionTypes from "../../store/actions";
// import { SelectField } from '../components/com/fields';
// import { getService } from '../services';
import PlanAcelleMail from "../plan-acelle-mail/PlanAcelleMail";
import SteperForm from "../com/form/SteperForm";
import {
  getService,
  current as Current,
  reAuthenticate,
  connect,
} from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "@reach/router";

const timeZone = [
  {
    _id: "America/New_York",
    name: "America/New_York",
  },
  {
    _id: "America/Scoresbysund",
    name: "America/Scoresbysund",
  },
];
const Form = styled(SteperForm)``;
const StepsProfile = ({ plugin_id, error, ...props }) => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [record, setRecord] = useState({});
  const [payloads, setPayloads] = useState({});
  const [steps, setStepes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const [done, setDone] = useState(false);
  const [current, setCurrent] = useState(0);

  const handleChange = (record) => {
    setPayloads({
      ...payloads,
      ...record,
    });
  };
  const handleSelectPlan = (plan_id) => {
    setCurrent(1);
    setPayloads({
      ...payloads,
      plan_id,
    });
  };
  const handleSubmit = async () => {
    if (payloads) {
      try {
        let service = getService("upgrade-subscription");
        await service.create(payloads);
        message.success("Subcription upgraded!");
        reAuthenticate().then(({ user, accessToken }) => {
          /* Socket Authentication */
          Current()
            .then((response) => {
              dispatch({
                type: actionTypes.AUTHENTICATION,
                user: response,
              });
              navigate("/dashboard");
            })
            .catch((error) => {
              dispatch({
                type: actionTypes.AUTHENTICATION_FAIL,
                error,
              });
              message.error(error.message);
              /* navigate("/"); */
            });
        });
      } catch (err) {
        message.error(err.message);
      }
    }
  };
  useEffect(() => {
    let steps = [
      {
        title: <h2>Select Plan</h2>,
        content: [
          <div flex={1}>
            <div className="section-show-all">
              {error && <span>{error.message}</span>}
              <span>, please upgrade your plan subsciption</span>
            </div>
            <PlanAcelleMail
              flex={1}
              optionValue="_id"
              autoplay={false}
              reference={"plans"}
              onSelect={handleSelectPlan}
              selected={current}
              filterDefaultValues={{
                status: "active",
                price: { $ne: 0 },
                $sort: { order: 1 },
              }}
              validations={[
                { required: true, message: "the plan is required" },
              ]}
            />
          </div>,
        ],
      },
      {
        title: <h2>Payment Information</h2>,
        // description: "Select Role",
        content: [
          <Input
            size="large"
            flex={1}
            placeholder="First Name"
            name="first_name"
            initial={record.first_name}
          />,
          <Input
            size="large"
            flex={1}
            placeholder="Last Name"
            name="last_name"
            initial={record.last_name}
            // validations={[{ required: true, message: "Last Name is required" }]}
          />,
          <Input
            flex={1}
            name="company_name"
            size="large"
            placeholder="Company Name"
            initial={record.company_name}
          />,
        ],
      },
    ];
    setStepes((prev_steps) => [...steps]);
    return () => {};
  }, [record, options]);
  return (
    <Wrapper>
      <Row type="flex" justify="center" align="middle" gutter={10}>
        <Col xl={24} lg={12} md={12} xs={24}>
          <Form
            textDone="SEND"
            onDone={handleSubmit}
            done={done}
            onChange={handleChange}
            current={current}
            steps={steps}
            /*   steps={steps.filter(
                          it => typeof it.type == "undefined" || it.type == record.role
                      )} */
          />
        </Col>
      </Row>
    </Wrapper>
  );
};
export default StepsProfile;
