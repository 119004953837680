import React, { useState, useEffect } from "react";
import { /* Row, Col,  */ Container } from "reactstrap";
import { Row, Col, message, Icon, Card } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { Wrapper, WrapperWidgetCard } from "./Styles";
import { Widget } from "../widgets/";

import LastActivities from "./widgets/LastActivities";
import LastContacts from "./widgets/LastContacts";
/* import Calendar from "./widgets/Calendar"; */
import Calendar from "../com/calendar/Calendar";
import CountWidget from "./widgets/CountWidget";
import { useSelector } from "react-redux";
import LogWidget from "./widgets/LogWidget";
import ListWidget from "./widgets/ListWidget";
import { MyModal } from "../com";
import TaskForm from "../board/TaskForm";
import WidgetCounter from "./widgets/WidgetCounter";
import { getService } from "../../services";
import RecentActivities from "./widgets/RecentActivities";
import ListCardProperties from "./listcar-Properties/ListCardProperties";
import TaskList from "./widgets/TaskList";
import SocialWidgetList from "../social-widgets/SocialWidgetList";
import Profile from "./widgets/Profile";
import SocialWidget from "./widgets/SocialWidget";
import CalendarDashboard from "../com/calendar/CalendarDashboard";
import MyCalendar from "./widgets/Calendar";
import MyCalendarDashboard from "../com/calendar/MyCalendarDashboard";
import StatisticsDashboard from "./widgets/StatisticsDashboard";
import { status } from "../../constants";
import WidgetCounterList from "./widgets/WidgetCounterList";
const { Meta } = Card;
const Dashboard = (props) => {
  const user = useSelector(({ user }) => user);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [statistics, setStatistics] = useState({});
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [label_widget, setLabelWidget] = useState("24_hours");
  const handleOnChange = (value) => {
    console.log("que cambio:::::::::afuera::::::::::::::::", value);
    setFilterDefaultValues({
      date_listings: value,
    });
    setLabelWidget(value);
  };

  if (!user) return null;
  return (
    <Wrapper>
      {user && user.company_id && user.company && user.company.MlsId && (
        <Row
          type="flex"
          justify="space-between"
          align="center"
          gutter={12}
          style={{
            marginBottom: "8px",
          }}
        >
          <Col xxl={6} xl={8} lg={10} md={12} xs={24}>
            <Profile />
            <SocialWidget
              type="white-color"
              filterDefaultValues={{
                $and: [{ user_id: user._id }, { company_id: user.company_id }],
              }}
            />
          </Col>
          <Col xxl={18} xl={16} lg={14} md={12} xs={24}>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              xs={24}
              className="col-widget"
            >
              <WidgetCounterList onChange={handleOnChange} />
            </Col>
            <Col xs={24} xxl={6} xl={6} lg={6} md={12} className="col-widget">
              <WidgetCounter
                borderColor="#fa8c16"
                reference="property-microservices"
                filterDefaultValues={{
                  ...filterDefaultValues,
                  key: "actives_for_sale",
                }}
                title="Properties for Sale"
                description={
                  label_widget === "24_hours"
                    ? "Actives For Sale Today"
                    : "Actives For Sales"
                }
                icon="home"
                count={statistics.actives_for_sale}
              />
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} xs={24} className="col-widget">
              <WidgetCounter
                loading={loading}
                borderColor="#20c997"
                title="Properties for Rent"
                description={
                  label_widget === "24_hours"
                    ? "Actives For Rent Today"
                    : "Actives For Rent"
                }
                icon="home"
                reference="property-microservices"
                filterDefaultValues={{
                  ...filterDefaultValues,
                  key: "actives_for_rent",
                }}
                count={statistics.actives_for_rent}
              />
            </Col>
            <Col xs={24} xxl={6} xl={6} lg={6} md={12} className="col-widget">
              <WidgetCounter
                loading={loading}
                borderColor="#038fde "
                title="Pending Properties"
                icon="home"
                description={
                  label_widget === "24_hours" ? "Pending Today" : "Pending"
                }
                reference="property-microservices"
                filterDefaultValues={{
                  ...filterDefaultValues,
                  key: "pendings_today",
                }}
                count={statistics.pendings_today}
              />
            </Col>
            <Col
              xs={24}
              xxl={6}
              xl={6}
              lg={6}
              md={12}
              className="col-widget-last"
            >
              <WidgetCounter
                loading={loading}
                borderColor="#eb2f96"
                icon="home"
                title="Closed Properties"
                description={
                  label_widget === "24_hours" ? "Closed Today" : "Closed"
                }
                reference="property-microservices"
                filterDefaultValues={{
                  ...filterDefaultValues,
                  key: "closes_month",
                }}
                count={statistics.closes_month}
              />
            </Col>
            {user && user.company_id && user.company && user.company.MlsId && (
              <Col xl={24} xs={24} md={24}>
                <ListCardProperties />
              </Col>
            )}
          </Col>
        </Row>
      )}
      <Row
        type="flex"
        justify="space-between"
        align="center"
        gutter={12}
        style={{
          marginBottom: "8px",
        }}
      >
        <Col xxl={24} xl={24} lg={24} xs={24} md={24}>
          <Col xxl={16} xl={16} lg={16} md={14} xs={24} className="task-col">
            <TaskList title="My Tasks" type={"my-tasks"} {...props} />
            <StatisticsDashboard />
          </Col>
          <Col xxl={8} xl={8} lg={8} md={10} xs={24}>
            <RecentActivities title="Today" {...props} />
          </Col>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="center"
        gutter={12}
        style={{
          marginBottom: "18px",
        }}
      >
        <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
          <CalendarDashboard title="Events" />
        </Col>
      </Row>
      <MyModal
        title="Edit Task"
        visible={visible}
        width={"60%"}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <TaskForm record={record} id={record._id} {...props} />
      </MyModal>
    </Wrapper>
  );
};
export default Dashboard;
