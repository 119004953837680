import React, { useState, useEffect } from "react";
import ListCard from "../../components/list/ListCard";
import { Row, Col, Card, List, Icon, Button, Skeleton, message } from "antd";
import styled from "styled-components";
import Uploader from "../../components/com/Uploader";
import { getService } from "../../services/services";
import { URL_S3 } from "../../constants";
import _ from "lodash";
const { Meta } = Card;
const CardContainer = styled(List.Item)`
  overflow: hidden;
  & .ant-card {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03) !important;
    border-radius: 10px;
    border: 1px solid #e8e8e85e !important;
    overflow: hidden;
  }
  & .ant-card-actions {
    background: white !important;
    border-top: 0px !important;

    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.4) 70%,
      rgba(0, 0, 0, 0) 100%
    ) !important;

    position: absolute;
    display: block;
    width: 100%;
    bottom: 0px;
    height: 60px;
    padding: 10px;
  }
  & .ant-card-actions > li {
    margin: 4px 0 !important;
  }
  & .ant-card-body {
    padding: 0px !important;
    overflow: hidden;
  }
  & .ant-card-meta-title {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  & .ant-card-meta-title .ant-btn span {
    white-space: nowrap;
    max-width: 85%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  & .ant-card-meta-title a {
    padding: 0px 4px !important;
    text-align: left !important;
    color: var(--gray) !important;
  }

  & .ant-card-meta-title i {
    padding: 8px;
    border-radius: 50%;
    background: var(--pink);
    color: white;
  }
  & .ant-card-meta-title {
    padding: 4px;
    background: #fafafa;
    border-radius: 20px;
    /*  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08); */
    margin: 4px;
    color: #40a9ff;
  }
  & .ant-input {
    border-radius: 20px !important;
    /* background: #fafafa !important;
    border: 1px solid #cccccc5c !important;
    box-shadow: 0px 3px 4px rgba(215, 215, 215, 0.8) !important; */
  }
  & .ant-card-cover {
    border-radius: 10px !important;
    overflow: hidden;
  }
  & .ant-card-cover > div {
    height: 300px !important;
    background-size: cover !important;
    background-position: center !important;
  }
`;
const Container = styled.div`
  padding: 20px;
  height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
`;
const handleDelete = () => {};
const handleEdit = () => {};
const GalleryListing = ({
  id,
  ListingKeyNumeric,
  Media = [],
  loading = false,
  onChange,
  ...props
}) => {
  const [media, setMedia] = useState([]);

  let actions = [
    <Button type="link" onClick={item => handleDelete(item._id)}>
      <Icon type="delete" /> Delete
    </Button>,
    <Button type="link" onClick={item => handleEdit(item)}>
      <Icon type="edit" /> Edit
    </Button>
  ];

  const UpdateListing = files => {
    const service = getService("my-listings");

    service
      .patch(id, {
        Media: files.map(it => ({
          url: it.key,
          index: it.index
        }))
      })
      .then(response => {
        message.info("updated!");
        if (onChange)
          onChange(
            "Media",
            files.map(it => ({
              url: it.key,
              index: it.index
            }))
          );
      })
      .catch(err => message.error(err.message));
  };

  const handleOnChange = ({ key, ...file }) => {
    delete file.url;
    let files = media;
    file["uid"] = `${files.length}`;
    file["key"] = key;
    file["url"] = file.location;
    file["status"] = file.status === 204 ? "done" : "error";
    files.push(file);

    UpdateListing(files);
    setMedia(prevFiles => {
      return [...files];
    });
  };
  const handleOnDelete = ({ key, ...file }) => {
    let files = media.filter(it => it.status !== "deleted");
    UpdateListing(files);
    setMedia(prevFiles => {
      return [...files];
    });
  };
  const renderItem = ({ type = "card", ...item }, index) => (
    <CardContainer key={index}>
      {type === "card" ? (
        <Skeleton avatar title={false} loading={loading} active>
          <Card
            bordered={false}
            actions={actions}
            cover={
              <div
                style={{
                  background: `url(${item.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover!important"
                }}
              />
            }
          />
        </Skeleton>
      ) : (
        <Card>{item.render(item)}</Card>
      )}
    </CardContainer>
  );

  useEffect(() => {
    if (Media && Media.length > 0) {
      console.log("MEDIA: ", Media);
      setMedia(
        Media.map((it, index) => {
          if (!it) return null;
          return {
            ...it,
            uid: `${index}`,
            key: it.url,
            url: it.MediaURL ? it.MediaURL : `${URL_S3}/${it.url}`,
            status: "done"
          };
        }).filter(it => typeof it != "undefined")
      );
    }
  }, [Media]);
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={22}>
        <Uploader
          onChange={handleOnChange}
          onDelete={handleOnDelete}
          path={ListingKeyNumeric}
          fileList={media}
        />
      </Col>
    </Row>
  );
};

export default GalleryListing;
