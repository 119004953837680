import styled from "styled-components";

export const WrapperForm = styled.div`
  .ant-form {
    padding: 20px;
    border: 1px solid #cccccc40;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #cccccc30;
  }
  .footer-botton {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn {
      border-radius: 16px;
      background: #fff !important;
      color: var(--primary-button) !important;
      :hover {
        color: #fff !important;
        background: var(--primary-button) !important;
      }
    }
  }
`;
export const WrapperFormSteps = styled.div`
  & h2 {
    margin-bottom: 4px;
    text-align: center;
    font-size: 24px;
  }
  & .ant-form {
    margin: 10px 0px !important;
  }
  & .form-fields {
    min-height: 250px !important;
  }
  & .ant-row.footer-advanced-form div:last-child {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
