import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Icon,
  Dropdown,
  Menu,
  message,
  Badge,
  Checkbox,
  notification,
  Button,
  Avatar,
} from "antd";
import styled from "styled-components";
/* Redux */
import { connect, useSelector } from "react-redux";
import * as actionTypes from "../../store/actions";
import { BreadCrumb } from "../breadcrumb/BreadCrumb";
import { navigate, Link } from "@reach/router";
import { Logout, getCurrentUser } from "../../services/services";
import {
  HeadContainer,
  WrapperForm,
  AboutWrapper,
  WrapperCheck,
} from "./Styles";
import { Activity, MyModal } from "../com";
import { LogActions, getService } from "../../services/";
import {
  URL_LOGO_ICON,
  GOONEWORLD_SITE,
  LOGO_BLUECLOSERX,
  VERSION,
  LOGO_CLOSERXBLACK,
  URL_S3_SERVER,
  URL_DEFAULT_AVATAR,
  URL_S3,
} from "../../constants/";
import moment from "moment";
import TaskForm from "../board/TaskForm";
import { SimpleForm } from "../../components/com/form/";
import Logo from "../../sources/images/logo-blue.svg";
import * as messages from "../../constants/messages";
import ifvisible from "ifvisible.js";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
const { Header } = Layout;
const TopHeader = styled(Header)`
  & .trigger {
    font-size: var(--font-size-medium);
    line-height: var(--font-size-medium);
    padding: 14px 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  & .trigger:hover {
    /* color: #1890ff; */
  }
  & .item-menu {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .ant-dropdown-trigger i.anticon {
    font-size: 20px !important;
  }
  & .ant-dropdown-trigger .ant-badge-dot {
    top: 10px !important;
    right: 10px !important;
  }
  & .ant-dropdown-trigger {
    cursor: pointer;
    border-radius: 50% !important;
  }
  & .ant-dropdown-trigger:hover {
    background: #e8e8e82e;
  }
  /* & .primary-menu-item:hover {
    color: #fff !important;
    background: var(--primary);
  } */
  & .section-language.ant-dropdown-trigger.item-menu {
    text-transform: capitalize;
  }

  @keyframes moveIcon {
    0% {
      transform: scaleX(1);
    }
    10%,
    20% {
      transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
    }
    30%,
    50%,
    70%,
    90% {
      transform: scaleX(1) rotate(10deg);
    }
    40%,
    60%,
    80% {
      transform: rotate(-10deg);
    }
    100% {
      transform: scaleX(1);s
    }
  }

  .moving-icon {
    animation: moveIcon 1.5s ease infinite;
    // animation: moveIcon 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Animación con curva de temporización */
  }
`;

const CheckGroup = ({ name, initial, defaultValue, ...props }) => {
  const handleChange = (value) => {
    if (props.onChange) props.onChange(value);
  };
  return (
    <WrapperCheck>
      <div className="section-checkgroup">
        <Checkbox.Group
          options={Object.keys(messages.actions).map((key) => ({
            label: messages.actions[key],
            value: key,
          }))}
          onChange={handleChange}
          {...props}
        />
      </div>
    </WrapperCheck>
  );
};
const TopBar = ({
  withLogo = false,
  topTitle = true,
  collapsedMenu,
  ...props
}) => {
  let user = JSON.parse(window.localStorage.getItem("user")) || "";
  // const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible_task, setVisibleTask] = useState(false);
  const [visible_about, setVisibleAbout] = useState(false);
  const [open, setOpen] = useState(false);
  const [task, setTask] = useState();
  const intl = useIntl();
  const { txt_topbar } = intl.messages;
  const isPageHidden = () => {
    return (
      document.hidden ||
      document.msHidden ||
      document.webkitHidden ||
      document.mozHidden
    );
  };
  const updateLog = (id, params) => {
    const service = getService("log-actions");
    service
      .patch(id, params)
      .then((res) => {})
      .catch((err) => message.error(err.message));
  };
  const createNotification = (params) => {
    let { _id, action_type, viewed_by_ids, reference_id, log } = params;
    let author = log.user;
    if (
      (author && user._id != author._id) ||
      (params.members_ids &&
        params.members_ids.includes(user._id) &&
        !viewed_by_ids.includes(user._id))
    ) {
      let img = "/images/logo-color.svg";
      let title = messages.actions[action_type] || "Notification";
      log["date"] = moment(log.date).format("YYYY-MM-DD HH:mm:ss");
      let text = messages.tree[action_type].format({
        ...log,
        author: author,
      });
      if (ifvisible.now("hidden")) {
        let notification = new Notification(title, {
          tag: title,
          body: text.replace(/\*/g, ""),
          icon: `${window.location.origin}${URL_LOGO_ICON}`,
          // timeout in milliseconds
          //showDuration: 3500,
        });
        notification.onclick = function() {
          updateLog(_id, {
            viewed_by_ids: [...viewed_by_ids, user._id],
          });
          window.focus();
          switch (action_type) {
            case "update_tasks":
              setTask(log);
              setVisibleTask(true);
              break;
            case "create_tasks":
              setTask(log);
              setVisibleTask(true);
              break;
            case "favorites":
              //${GOONEWORLD_SITE}/detail/{property._id}
              if (log.property)
                window.open(
                  `${GOONEWORLD_SITE}/detail/${log.property._id}`,
                  "_blank"
                );
              break;
            case "remove_listing":
              if (log.property)
                window.open(
                  `${GOONEWORLD_SITE}/detail/${log.property._id}`,
                  "_blank"
                );
              break;
            case "birthdays":
              navigate(`/dashboard/contacts/${reference_id}`);
              break;
            case "reminders":
              let { task } = log;
              task["date"] = moment(task.date).format("YYYY-MM-DD HH:mm:ss");
              setTask(task);
              setVisibleTask(true);
              break;
            default:
              break;
          }
        };
        setTimeout(notification.close.bind(notification), 5000);
        document.addEventListener("visibilitychange", function() {
          if (document.visibilityState === "visible") {
            // The tab has become visible so clear the now-stale Notification.
            notification.close();
          } else {
          }
        });
      }
    }
  };
  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };
  const openNotification = ({
    message,
    description,
    placement,
    type,
    ...rest
  }) => {
    notification[type || "info"]({
      message,
      description,
      placement: placement || "topRight",
      ...rest,
    });
  };
  const handlePermission = (permission) => {
    // Whatever the user answers, we make sure Chrome stores the information
    if (!("permission" in Notification)) {
      Notification.permission = permission;
    }
    // set the button to shown or hidden, depending on what the user answers
    if (
      Notification.permission === "denied" ||
      Notification.permission === "default"
    ) {
      if (Notification.permission === "denied" && !TopBar.Notificated) {
        openNotification({
          type: "warning",
          message: "Attention",
          description: (
            <div>
              Browser Notification is Disabled.
              <br />
              Activate Notifications.
            </div>
          ),
          duration: 5,
          style: {
            width: 300,
            marginLeft: "calc(50% - 150px)",
          },
        });
        TopBar.Notificated = true;
      }
    } else {
    }
  };

  const handleUpdate = (language) => {
    const service = getService("users");
    service
      .patch(user._id, {
        language,
      })
      .then((res) => {
        message.success("Language changed");
        currentUser();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const currentUser = async () => {
    try {
      let res = await getCurrentUser();
      console.log("top bar", res);
      dispatch({
        type: actionTypes.AUTHENTICATION,
        user: res,
        accessToken: window.localStorage.getItem("feathers-jwt"),
      });
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleOnClickTraslate = ({ key }) => {
    switch (key) {
      case "es":
        handleUpdate("es");
        break;
      case "en":
        handleUpdate("en");
        break;
      default:
        break;
    }
  };
  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "logout":
        Logout()
          .then((response) => {
            localStorage.clear();
            navigate("/");
          })
          .catch((err) => message.error(err.message));
        break;
      case "profile":
        navigate(`/dashboard/profile`);
        break;
      case "settings":
        setVisible(true);
        break;
      case "version":
        setVisibleAbout(true);
        break;

      default:
        navigate(`/dashboard/profile/${key}`);
        break;
    }
  };
  const handleSelect = (item) => {
    setOpen(false);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="profile" className="container-menu-drodown">
        <span className="item-menu">
          {txt_topbar.my_profile} ({user.role})
        </span>
      </Menu.Item>
      <Menu.Item key="plugins" className="container-menu-drodown">
        <Icon size="large" type="api" /> {txt_topbar.labelPlugins}
      </Menu.Item>
      <Menu.Item key="settings" className="container-menu-drodown">
        <Icon size="large" type="setting" /> {txt_topbar.labelSettings}
      </Menu.Item>
      <Menu.Item key="logout" className="container-menu-drodown">
        <Icon size="large" type="logout" /> {txt_topbar.closeSession}
      </Menu.Item>
      <Menu.Item key="version" className="container-menu-drodown">
        <Icon type="question" />
        {txt_topbar.about}
      </Menu.Item>
    </Menu>
  );
  const menuTraslate = (
    <Menu onClick={handleOnClickTraslate}>
      <Menu.Item key="es" className="container-menu-drodown">
        <span className="item-menu">Spanish</span>
      </Menu.Item>
      <Menu.Item key="en" className="container-menu-drodown">
        <span className="item-menu">English</span>
      </Menu.Item>
    </Menu>
  );
  const menuActivity = (
    <Menu /* onClick={handleMenuClick} */>
      <Menu.Item
        style={{
          cursor: "default",
        }}
        disabled
        key="profile"
      >
        <span>
          <Activity onSelect={handleSelect} views />
        </span>
      </Menu.Item>
    </Menu>
  );
  const handleSubmit = (err, data) => {
    if (err) return message.error(err.message);
    localStorage.setItem("user", JSON.stringify(data));
  };
  useEffect(() => {
    if (user) {
      if (!"Notification" in window) {
        message.warning("This browser does not support notifications.");
      } else {
        if (user.notifications && user.notifications.length > 0) {
          if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
              handlePermission(permission);
            });
          } else {
            Notification.requestPermission(function(permission) {
              handlePermission(permission);
            });
          }
        }
      }
    }
    LogActions.on("created", (data) => {
      let { log, user_id, action_type } = data;
      /* let user = JSON.parse(localStorage.user); */
      if (
        user &&
        user.notifications &&
        user.notifications.includes(action_type)
      ) {
        setShowNotification(true);
        createNotification(data);
      }
      /* setTimeout(() => {
        setShowNotification(false);
      }, 3000); */
    });
    return () => {};
  }, [user, collapsedMenu]);
  if (!user) return null;
  return (
    <TopHeader
      className="top-bar"
      style={{
        color: props.background ? props.color || "#FFF" : "var(--gray)",
        background: props.background
          ? "rgba(0, 0, 0, 0.3)"
          : `var(--color-white)`,
        padding: "0px 20px 0px 0px",
        height: 64,
      }}
    >
      <Row align="middle" type="flex" justify="space-between">
        {
          <Col xl={14} lg={14} md={12} sm={12} xs={12}>
            <HeadContainer {...props.theme}>
              {topTitle && <h2>Dashboard</h2>}
              {withLogo && (
                <Link to="/" className="logo">
                  <div
                    style={{
                      // background: `url(${Logo})`,
                      background: `url(${
                        user.company && user.company.logo
                          ? `${URL_S3}/${user.company.logo}`
                          : LOGO_BLUECLOSERX
                      })`,
                      backgroundRepeat: "no-repeat",
                      height: 55,
                      backgroundSize: "100% 100%",
                      width: 120,
                    }}
                  />
                  {user.company.logo}
                </Link>
              )}
              {!isMobile && <BreadCrumb {...props.theme} {...props} />}
            </HeadContainer>
          </Col>
        }
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
          <Row type="flex" align="middle" justify="end" gutter={[4, 0]}>
            <Col>
              <Dropdown
                visible={open}
                placement="bottomLeft"
                className="item-menu"
                overlayClassName="full-overlay"
                overlay={menuActivity}
                onVisibleChange={(visible) => {
                  setOpen(visible);
                  if (visible) setShowNotification(false);
                }}
                overlayStyle={{
                  width: 350,
                }}
              >
                <Badge
                  dot={showNotification}
                  className="primary-menu-item icon-container"
                >
                  <Icon type="bell" className="moving-icon" />
                </Badge>
              </Dropdown>
            </Col>
            <Col>
              <span className="item-menu">
                {user.first_name
                  ? user.first_name
                  : user.email
                  ? user.email.substr(0, user.email.indexOf("@"))
                  : ""}{" "}
              </span>
            </Col>
            <Col>
              <Dropdown className="item-menu" overlay={menu}>
                <span>
                  <Avatar
                    src={
                      user.picture
                        ? `${URL_S3}/${user.picture}`
                        : URL_DEFAULT_AVATAR
                    }
                    shape="circle"
                    size="large"
                  />
                </span>
              </Dropdown>
            </Col>
            {/*<Col>
              <Dropdown className="item-menu" overlay={menuTraslate}>
                {user && (
                  <span className="section-language">{user.language}</span>
                )}
              </Dropdown>
                </Col>*/}
          </Row>
        </Col>
      </Row>
      <MyModal
        title={txt_topbar.modal.settingNotifications}
        width={350}
        visible={visible}
        style={{
          width: 350,
        }}
        onCancel={() => setVisible(false)}
      >
        <WrapperForm>
          <SimpleForm id={user._id} source="users" onSubmit={handleSubmit}>
            <CheckGroup
              flex={1}
              initial={user.notifications}
              name="notifications"
            />
          </SimpleForm>
        </WrapperForm>
      </MyModal>
      <MyModal
        title="Edit Task"
        onCancel={() => {
          setVisibleTask(false);
          setTask(null);
        }}
        visible={visible_task}
        width={"60%"}
      >
        <TaskForm
          id={task && task._id}
          onSubmit={() => {
            setVisibleTask(false);
          }}
        />
      </MyModal>
      <MyModal
        // title="About"
        width={450}
        visible={visible_about}
        onCancel={() => setVisibleAbout(false)}
      >
        <AboutWrapper>
          <div className="about-container">
            <div className="about-header">
              <div className="about-title">
                <h2>🎉 Introducing 🚀</h2>
              </div>
              <div className="about-img">
                <img src={LOGO_CLOSERXBLACK} alt="" />
              </div>
            </div>
            <div className="about-content">
              <article>
                We're thrilled to release closrx! Discover more today!
              </article>
            </div>
            <div className="about-footer">
              <Button type="link">Version {VERSION}</Button>
            </div>
          </div>
        </AboutWrapper>
      </MyModal>
    </TopHeader>
  );
};

/* Inject reducers */

const mapStateToProps = (state) => {
  return {
    collapsedMenu: state.collapsedMenu,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
