import HomeLayout from "./home_layout";
import BlogLayout from "./blog_layout";
import AdminLayout from "./admin_layout";
import BuilderSiteLayout from "./buildersiteLayout";
import layoutCenterPlan from "./layoutCenterPlan";
import BasicLayout from "./basic_layout";
export * from "./form-page-layouts/";
export {
  HomeLayout,
  BlogLayout,
  AdminLayout,
  layoutCenterPlan,
  BuilderSiteLayout,
  BasicLayout,
};
