import React, { useState, useEffect } from "react";
import { Wrapper, BoxWrapper, WrapperPlanDetail } from "./Styles";
import { Row, Col, Card, message, Button, Tabs, Divider } from "antd";
import PersonalInformation from "./PersonalInformation";
import ChangePasswordForm from "./ChangePasswordForm";
import CompanyInformation from "./CompanyInformation";
import PlugingForm from "../../components/plugins/PlugingForm";
import { getService } from "../../services";
import PlanAcelleMail from "../../components/plan-acelle-mail/PlanAcelleMail";
import StepsProfile from "../../components/steper-profile/StepsProfile";
import UserProfileActivity from "./UserProfileActivity";
import UserProfile from "./UserProfile";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actions";
import PlanProfile from "../../components/plan-profile/PlanProfile";
import { PlanCard } from "../../components/payment/PlanCard";
import { useIntl } from "react-intl";
const { TabPane } = Tabs;
let allow_roles = ["admin", "broker"];
const Box = (props) => (
  <BoxWrapper {...props}>
    <Card title={props.title}>{props.children}</Card>
  </BoxWrapper>
);
const Profile = ({ id, ...props }) => {
  const dispatch = useDispatch();
  let { location } = props;
  const user = useSelector(({ user }) => user);
  const error = useSelector(({ error }) => error);
  const [member, setMember] = useState(null);
  const [record, setRecord] = useState(null);
  const [show_all_plugins, setShowAllPlugins] = useState(true);
  const intl = useIntl();
  const { txt_profile } = intl.messages;
  const getData = async () => {
    const service = getService("users");
    if (user) {
      const data = await service.get(user._id, {
        query: {
          $populate: ["member", "company"],
        },
      });
      let { member, ...rest } = data;
      setRecord(rest);
      console.log("::service::", rest);
      if (member) {
        setMember(member);
        console.log("data: ", data);
      }
    }
  };
  const handleChange = (field, value) => {
    console.log("::::::::::::field, value::::::::::::::::", field, value);
    const service = getService("users");
    service
      .patch(user._id, {
        [field]: value,
      })
      .then((res) => {
        message.success("Profile Updated!");
        console.log("field, value: ", field, value);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const handleshowclick = () => {
    setShowAllPlugins((show) => !show);
  };

  const handleOnChange = () => {
    dispatch({
      type: actionTypes.AUTHENTICATION,
      user: { ...user, step: 2 },
      accessToken: localStorage.getItem("feathers-jwt"),
    });
    navigate(`/profile?step=${2}`);
  };
  const handleChangeCompany = (field, value) => {
    // return console.log("::::::field, value::::", field, value);
    const service = getService("companies");
    if (user.company_id)
      service
        .patch(user.company_id, {
          [field]: value,
        })
        .then((res) => {
          message.success("Company Updated!");
          console.log("Value: ", field, value);
        })
        .catch((err) => {
          message.error(err.message);
        });
  };
  useEffect(() => {
    getData();
  }, [user]);

  if (id)
    return (
      <Wrapper>
        <Row gutter={8} type="flex" justify="center" align="middle">
          <Col className="profile-container" span={22}>
            <Row gutter={8} type="flex" justify="center" align="top">
              <Col className="profile-content" xl={16} lg={24} sm={24} xs={24}>
                <Tabs defaultActiveKey="activity">
                  <TabPane tab={"Activity"} key="activity">
                    <UserProfileActivity id={id} />
                  </TabPane>
                  {/* <TabPane tab={"Cards"} key="cards">
                    <UserProfile id={id} />
                  </TabPane> */}
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>
    );
  return (
    <Wrapper>
      <Row gutter={8} type="flex" justify="center" align="top">
        <Col span={22}>
          <Col xl={8} lg={24} sm={24} xs={24}>
            {record && (
              <Box className="box-centered" title="Personal Information">
                <PersonalInformation
                  user={user}
                  onChange={handleChange}
                  member={member}
                  record={record}
                  {...props}
                />
              </Box>
            )}
          </Col>
          <Col xl={8} lg={24} sm={24} xs={24}>
            {error &&
              error.code === 402 &&
              user &&
              allow_roles.includes(user.role.toLowerCase()) && (
                <Box
                  title={
                    <div className="section-title">
                      <span>Upgrade Subscription</span>
                    </div>
                  }
                >
                  <StepsProfile error={error} />
                </Box>
              )}
            {record && (
              <Box className="box-centered" title="Company Information">
                <CompanyInformation
                  use={user}
                  onChange={handleChangeCompany}
                  member={member}
                  record={record}
                  {...props}
                />
              </Box>
            )}
          </Col>
          <Col xl={8} lg={24} sm={24} xs={24}>
            {record && (
              <Box className="box-centered" title="Change Password">
                <ChangePasswordForm id={record._id} {...props} />
              </Box>
            )}
            {record && (
              <Box className="box-centered" title="Change Plan">
                <div className="container-plan-card">
                  <PlanCard
                    current_plan={user.plan_id}
                    showButton={true}
                    onChange={handleOnChange}
                    record={user.plan}
                  />
                </div>
              </Box>
            )}
          </Col>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default Profile;
