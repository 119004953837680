import { Button, Icon, message } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getService } from "../../../services";
import { HeadLine, Wrapper } from "./Styles";
import moment from "moment";
import { MyModal } from "../../com";
import { Link } from "@reach/router";
const LastContacts = ({ className, defaultFilterValues = {}, basePath, icon, title, ...props }) => {
    const service = getService("contacts");
    const user = useSelector(({ user }) => (user));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [record, setRecord] = useState({});
    const getData = () => {
        setLoading(true);
        service.find({
            query: {
                user_id: user._id,
                ...defaultFilterValues,
                $sort: {
                    createdAt: -1
                },
                $limit: 4
            }
        })
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
    }
    const handleShow = (record) => {
        setRecord(record);
        setShow(true);
    }
    useEffect(() => {
        if (user && user._id)
            getData();
    }, [user]);
    return (<Wrapper>
        <HeadLine color="#FFF">
            {<Icon type={icon || "team"} size="large" />}{" "}
            {<span>{title || "Last Contacts"}</span>}
        </HeadLine>
        <div className={`item-list ${className || ""}`}>
            {data.map(contact => (<div className="item-container contacts-container">
                <div className="item">
                    <Icon type="user" />{" "}
                    <span className="item-text">{contact.first_name || ""} {contact.last_name || ""}</span>
                </div>
                <div className="item item-footer">
                    <div className="item-text item-date">
                        <Icon type="clock-circle" size="small" />
                        {moment(contact.createdAt).fromNow()}</div>
                    <div className="item-tools">
                        <Link
                            to={`${basePath || "/dashboard/contacts/"}${contact._id}`}
                            /* onClick={() => handleShow(contact)} */
                            type="link">
                            <Icon size="small" type="eye" />
                        </Link>
                    </div>
                </div>
            </div>))}
            <div
                className="footer-items" >
                <Link
                    to={`${basePath || "/dashboard/contacts/"}`}
                    type="link">Show all</Link>
            </div>
        </div>
        <MyModal
            title="Edit contact"
            visible={show}
            width={"60%"}
            onCancel={() => {
                setShow(false);
                if (user && user._id)
                    getData();
            }}
        >
            {JSON.stringify(record)}
        </MyModal>
    </Wrapper>)
}
export default LastContacts;