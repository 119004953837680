import React, { useState } from "react";
import { Card } from "antd";
import { SelectField } from "../../com/fields";
import { WrapperListWidget } from "../Styles";
const type = [
  {
    value: "1_hour",
    text: "1 Hour",
  },
  {
    value: "24_hours",
    text: "24 Hours",
  },
  {
    value: "7_days",
    text: "7 Days",
  },
  {
    value: "last_week",
    text: "Last Week",
  },
  {
    value: "one_month_ago",
    text: "One Month Ago",
  },
  {
    value: "one_year",
    text: "One Year",
  },
  {
    value: "two_year",
    text: "Two Year",
  },
];
const WidgetCounterList = ({ onChange, ...props }) => {
  const [label_widget, setLabelWidget] = useState("24 Hours");
  const handleOnChange = (value) => {
    if (onChange) onChange(value);
  };
  return (
    <WrapperListWidget>
      <Card>
        <div className="container-body-card">
          <div className="marque">
            <marquee crollamount="3">
              <span>
                <strong>Welcome Back!</strong> {""}
                <img className="img-banner" src={"/images/logoCloseblue.png"} />
              </span>
            </marquee>
          </div>
          <SelectField
            choices={type}
            optionValue="value"
            placeholder={label_widget}
            optionText="text"
            onChange={handleOnChange}
          />
        </div>
      </Card>
    </WrapperListWidget>
  );
};
export default WidgetCounterList;
