import React from "react";
import { Carousel } from "../../components/com/";
const PropertyGallery = ({ dataSource, ...props }) => {
  return (
    <>
      <Carousel dataSource={dataSource || []} />
    </>
  );
};
export default PropertyGallery;
