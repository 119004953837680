import { Button, Checkbox, Divider, Input } from "antd";
import React, { useContext, useState } from "react";
import { ProfileContext } from "./ProfileContext";
import { SimpleForm } from "../../components/com/form/";
import { WrapperForm } from "./styles";
import { MyModal } from "../../components/com";
import { EmailConfirm } from "./EmailConfirm";
import { useSelector } from "react-redux";
import { navigate, useLocation } from "@reach/router";
import qs from "querystring";
export const CompanyInformation = ({ onChange, ...props }) => {
  const location = useLocation();
  const user = useSelector((data) => data.user);
  const [form, setForm] = useState();
  const [showMlsId, setShowMlsId] = useState(false);
  const [showConfirmMls, setShowConfirmMls] = useState(false);
  const [MlsId, setMlsId] = useState();
  const { payloads, setPayloads, setService } = useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const handleOnchange = (e) => {
    let { value } = e.target;
    setMlsId(value);
  };
  if (!user) return null;
  return (
    <WrapperForm>
      <SimpleForm
        style={{
          maxHeight: payloads.email ? 400 : 200,
        }}
        /* onMount={(form) => setForm(form)} */
        autoSubmit={false}
        /* title={
          <div>
            <h2>Company Information</h2>
            <article>
              Please configure your company information to ensure accuracy and
              improve our communication with you.
            </article>
          </div>
        } */
        // footer={<ToolNavigation activeKey={activeKey} form={form} record={payloads} onNavigate={onNavigate} activeKey={activeKey} />}
        onChange={onChange}
      >
        <div flex={1}>
          {!showConfirmMls && !user.OfficeMlsId && (
            <div>
              <h2 className="text-lg">Connect Company</h2>
              <article>
                {user.role === "Broker" || user.role === "Admin"
                  ? `Connect with multiple listing services (MLS) and increase your
                business's reach with our seamless integration.`
                  : `Connect with us and gain access to our extensive list of office listings. We offer MLS integration, making it easy for you to view all of our available properties from one convenient location. `}
              </article>
            </div>
          )}

          {!showConfirmMls && !user.OfficeMlsId && (
            <div className="flex justify-center align-center gap-4 section-mls">
              {showMlsId && (
                <Input
                  flex={0.8}
                  value={MlsId}
                  onChange={handleOnchange}
                  size="large"
                  placeholder={
                    user.role === "Broker" || user.role === "Admin"
                      ? "Enter your OfficeId"
                      : "Enter Company MlsId#"
                  }
                  validations={[
                    { required: showMlsId, message: "MlsId is required" },
                  ]}
                  name="MlsId"
                />
              )}
              {showMlsId && (
                <Button
                  flex={0.25}
                  loading={open}
                  onClick={() => {
                    if (MlsId) setOpen(true);
                  }}
                  type="primary"
                  icon="link"
                >
                  Connect
                </Button>
              )}
              <Button
                onClick={() => setShowMlsId((show) => !show)}
                type={showMlsId ? "link" : "primary"}
                flex={showMlsId ? 0.05 : 1}
              >
                {showMlsId ? "No Thanks" : "Connect with MLS"}
              </Button>
            </div>
          )}

          {!showConfirmMls && user.OfficeMlsId && (
            <div>
              <h2 className="text-lg">
                You are connected to <br /> {user.company.name}
              </h2>
              <article className="m-lg p-lg bg-gray-light flex justify-start align-center gap-sm">
                <div>
                  <img
                    src="/images/real-estate.png"
                    style={{
                      maxWidth: 64,
                    }}
                    alt=""
                  />
                </div>
                <div>
                  {user.role === "Broker" || user.role === "Admin"
                    ? `Connect with multiple listing services (MLS) and increase your
                business's reach with our seamless integration.`
                    : `Connect with us and gain access to our extensive list of office listings. We offer MLS integration, making it easy for you to view all of our available properties from one convenient location. `}
                </div>
              </article>
              <div className="flex justify-start align-center mt-xl ml-xl">
                <Button
                  flex={0.25}
                  onClick={() => {
                    navigate(
                      `${location.pathname}?${qs.stringify({
                        step: 2,
                      })}`
                    );
                  }}
                  type="primary"
                  icon="arrow-right"
                >
                  Start Now!
                </Button>
              </div>
            </div>
          )}
        </div>
        <div flex={1}>
          {showConfirmMls && (
            <div className="gap-4 m-lg">
              <h2 className="text-lg">Email confirmation required. </h2>
              <article className="m-lg p-lg bg-gray-light flex justify-start align-center gap-sm">
                <div>
                  <img
                    src="/images/email.png"
                    style={{
                      maxWidth: 64,
                    }}
                    alt=""
                  />
                </div>
                <div>
                  Please check your inbox for an email from us and follow the
                  instructions to confirm your MLS account.
                </div>
              </article>
              <p className="text-center align-center">
                <span className="">We send an email to: </span>
                <span className="link text-primary ml-md">
                  {payloads.email}
                </span>
              </p>
              <div className="flex justify-end align-center mr-xl">
                <Button
                  flex={0.25}
                  onClick={() => {
                    setShowConfirmMls(false);
                    setShowMlsId(true);
                  }}
                  type="primary"
                  icon="retweet"
                >
                  Resend
                </Button>
              </div>
            </div>
          )}
        </div>
        <MyModal
          className="my-modal my-modal-white"
          width={400}
          onCancel={() => setOpen(false)}
          title={<h2 className="text-md text-primary">Email Confirmation</h2>}
          visible={open}
        >
          <EmailConfirm
            onSubmit={(error, data) => {
              console.log("payloads:::: ", data);
              setPayloads(data);
              setOpen(false);
              setShowConfirmMls(true);
            }}
            MlsId={MlsId}
          />
        </MyModal>
      </SimpleForm>
    </WrapperForm>
  );
};
