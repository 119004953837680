import React, { useState, useEffect } from "react";
import {
  Avatar,
  Calendar,
  Card,
  Col,
  Icon,
  List,
  message,
  Row,
  Badge,
  Button,
  Tag,
} from "antd";
import { Wrapper, WrapperItem } from "./Styles";
import { getService } from "../../../services";
import { StatusField } from "../fields";
import { useSelector } from "react-redux";
import { status, tasks_types } from "../../../constants";
import { MyModal } from "../MyModal";
import TaskForm from "../../board/TaskForm";
import MemberGroup from "../../board/MemberGroup";
import { Empty } from "../loader";
import { moment } from "../../../utils/";
import { FaBirthdayCake } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import NewEvent from "./NewEvent";

const CalendarDashboard = ({ header, reference, title, ...props }) => {
  const user = useSelector(({ user }) => user);
  const [open, setOpen] = useState(true);
  const [mode, setMode] = useState("month");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [view, setView] = useState(false);
  const [date, setDate] = useState(new Date());
  const [task, setTask] = useState();
  const [event, setEvent] = useState();
  const [initialized, setInitialized] = useState(false);
  const [item_value, setItemValue] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState(
    props.filterDefaultValues
  );
  const [filterContacts, setFilterContacts] = useState();
  const [dataSource, setDataSource] = useState([]);
  const handleTaskSubmit = () => {
    getData();
  };
  const handleClickTask = (item) => {
    if (tasks_types.includes(item.type)) {
      if (item.type != "event") {
        setTask(item);
        setVisible(true);
      } else if (item.type === "event") {
        setView(true);
        setEvent(item);
      }
    }
  };
  const renderItem = (item, index) => {
    setItemValue(item);
    return (
      <WrapperItem
        className={`${index % 2 === 0 ? "item-normal" : "item-shadow"}`}
      >
        <List.Item onClick={() => handleClickTask(item)}>
          {tasks_types.includes(item.type) ? (
            <List.Item.Meta
              title={
                <div className="title-container">
                  <div className="subject">
                    <h3>{item.subject}</h3>
                  </div>
                  <div className="date">
                    <span className={`item-date-${item.type}`}>
                      {item.date && <Icon type="clock-circle" />}
                      {item.date ? "Due" : ""}{" "}
                      {item.date
                        ? moment(item.date).format("DD-MM-YYYY LT")
                        : moment(item.createdAt).fromNow()}
                    </span>
                  </div>
                </div>
              }
            />
          ) : (
            <List.Item.Meta
              className="meta-description-birhtday"
              avatar={<Avatar size="small" src="/images/birthday-cake.svg" />}
              title={`${item.first_name} ${item.last_name}`}
              description={
                <div className="description">
                  <div className="date">
                    {moment(item.birthday).format("MM-DD") ===
                    moment().format("MM-DD") ? (
                      <Tag className="birthday-tag">Birthday</Tag>
                    ) : (
                      <Tag className="tag-item" color="#4fc38f">
                        {moment(item.birthday).format("ddd, MMM DD ")}
                      </Tag>
                    )}
                  </div>
                </div>
              }
            />
          )}
        </List.Item>
      </WrapperItem>
    );
  };
  const onPanelChange = (date, mode) => {
    setDate(date);
    setMode(mode);
    filterDate(date, mode);
    if (!open) setOpen(true);
  };
  const handleOnSelect = (e) => {
    // console.log("!", e);
  };
  const filterDate = (date, _mode) => {
    let calendar_mode = _mode || mode;
    let start = moment(date).startOf("day");
    let end = moment(date).endOf("day");
    if (calendar_mode == "year") {
      start = moment(date).startOf("month");
      end = moment(date).endOf("month");
      setFilterContacts({
        date: date.format("MM-DD-YYYY"),
        mode: calendar_mode,
      });
      setFilterDefaultValues({
        ...filterDefaultValues,
        $and: [
          {
            $or: [
              {
                date: {
                  $gte: moment(start).format("MM-DD-YYYY HH:mm:ss"),
                  $lte: moment(end).format("MM-DD-YYYY HH:mm:ss"),
                },
              },
              {
                createdAt: {
                  $gte: moment(start).format("MM-DD-YYYY HH:mm:ss"),
                  $lte: moment(end).format("MM-DD-YYYY HH:mm:ss"),
                },
              },
            ],
          },
        ],
        $or: [
          {
            user_id: user._id,
          },
          {
            company_id: user.company_id,
          },
          {
            members_ids: { $in: [user._id] },
          },
        ],
      });
    } else {
      setFilterContacts({
        day: Number(date.format("DD")),
        month: Number(date.format("MM")),
        mode: calendar_mode,
      });
      setFilterDefaultValues({
        ...filterDefaultValues,
        $and: [
          {
            $or: [
              {
                date: {
                  $gte: moment(start).format("YYYY-MM-DD HH:mm:ss"),
                  $lte: moment(end).format("YYYY-MM-DD HH:mm:ss"),
                },
              },
              {
                createdAt: {
                  $gte: moment(start).format("YYYY-MM-DD HH:mm:ss"),
                  $lte: moment(end).format("YYYY-MM-DD HH:mm:ss"),
                },
              },
            ],
          },
        ],
        $or: [
          {
            user_id: user._id,
          },
          {
            company_id: user.company_id,
          },
          {
            members_ids: { $in: [user._id] },
          },
        ],
      });
    }
  };
  const handleOnChange = (date) => {
    setDate(date);
    filterDate(date);
    if (!open) setOpen(true);
  };
  const monthCellRender = (value) => {
    const date = moment(value).format("MM");
    const dataForDate = dataSource.filter(
      (item) => moment(item.date).format("MM") === date
    );
    return (
      <div className="date-cell">
        {dataForDate.map((item, index) => (
          <div key={index} className="list-tag">
            {tasks_types.includes(item.type) ? (
              <Tag className={`item-subject-month-${item.type}`}>
                <span>{item.subject}</span>
              </Tag>
            ) : (
              <Tag color={"#4fc38f"} className="birthday-tag-month">
                <FaBirthdayCake />
                <span>{item.first_name}</span>
              </Tag>
            )}
          </div>
        ))}
      </div>
    );
  };
  const dateCellRender = (value) => {
    // Obtener la fecha en formato YYYY-MM-DD
    const date = moment(value).format("MM-DD-YYYY");
    const dateBirhtday = moment(value).format("MM-DD");
    // Filtrar los elementos de dataSource que corresponden a la fecha actual
    const dataForDate = dataSource.filter(
      (item) => moment(item.date).format("MM-DD") === dateBirhtday
      // moment(item.birthday).format("MM-DD") === dateBirhtday
    );
    // console.log("dataForDatedataForDa", dataForDate, dataSource);
    return (
      <div className="date-cell">
        {dataForDate.map((item, index) => (
          <div key={index} className="list-tag">
            {tasks_types.includes(item.type) ? (
              <Tag
                // color={"#EF6A6A"}
                className={`item-subject-${item.type}`}
              >
                <span>{item.subject}</span>
              </Tag>
            ) : (
              <Tag color={"#4fc38f"} className="birthday-tag">
                <FaBirthdayCake />
                <span>{item.first_name}</span>
              </Tag>
            )}
          </div>
        ))}
      </div>
    );
  };
  const getData = async () => {
    const taskService = getService("tasks");
    const contactService = getService("get-contact-birthdays");
    const eventService = getService("events");
    setLoading(true);
    const [tasks, contacts, events] = await Promise.all([
      taskService
        .find({
          query: {
            ...filterDefaultValues,
            $sort: {
              createdAt: -1,
            },
          },
        })
        .then(({ data }) => data),
      eventService
        .find({
          query: {
            user_id: user && user._id,
            company_id: user && user.company_id,
            ...filterDefaultValues,
            $sort: {
              createdAt: -1,
            },
          },
        })
        .then(({ data }) => data),
      contactService
        .find({
          query: {
            ...filterContacts,
          },
        })
        .then(({ data }) => data),
    ]);
    setLoading(false);
    let data = [...tasks, ...contacts, ...events];
    data = data
      .filter((it) => typeof it != "undefined")
      .map((it) => {
        return {
          ...it,
          date: moment(
            it.birthday || it.start_date || it.date || it.createdAt
          ).toDate(),
          start: moment(it.birthday || it.start_date || it.createdAt).toDate(),
          end: moment(it.birthday || it.end_date || it.createdAt).toDate(),
          title: it.subject,
        };
      });
    setDataSource(data);
  };
  const handleNewEvent = () => {
    setView((view) => !view);
  };
  useEffect(() => {
    if (props.filterDefaultValues) {
      setFilterDefaultValues(props.filterDefaultValues);
    }
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (filterDefaultValues || filterContacts) {
      getData();
    }
  }, [filterDefaultValues, filterContacts]);
  useEffect(() => {
    if (!initialized) {
      filterDate(moment());
      setOpen(true);
      setInitialized(true);
    }
  }, []);
  return (
    <Wrapper style={props.style}>
      <Row
        gutter={[14, 14]}
        type="flex"
        justify="space-between"
        align={props.align || "top"}
      >
        {open && (
          <Col xxl={8} xl={8} lg={8} md={10} xs={24}>
            <Card hoverable className="container-car">
              <div className="section-events">
                <h4>
                  <FaRegCalendarAlt />
                  {title}
                </h4>
              </div>
              <div className="create-events">
                <Card
                  className="container-card-create"
                  onClick={handleNewEvent}
                >
                  <p>
                    <GoPlus />
                    Create New event
                  </p>
                </Card>
              </div>
              <div className="container-information">
                <div className="container-task-birthday">
                  <div className="task-color">Task</div>
                  <div className="birthday-color">Birthday</div>
                  <div className="meetings-color">Meetings</div>
                </div>
              </div>
              <Empty image="/images/schedule-empty.svg">
                <List
                  header={header}
                  loading={loading}
                  dataSource={dataSource}
                  renderItem={renderItem}
                />
              </Empty>
            </Card>
          </Col>
        )}
        <Col xxl={16} xl={16} lg={16} md={14} xs={24}>
          <Card hoverable>
            <Calendar
              dateCellRender={dateCellRender}
              onPanelChange={onPanelChange}
              onSelect={handleOnSelect}
              onChange={handleOnChange}
              monthCellRender={monthCellRender}
              fullscreen={true}
            />
          </Card>
        </Col>
      </Row>
      <MyModal
        title="Edit Task"
        onCancel={() => {
          setVisible(false);
          setTask(null);
          getData();
        }}
        visible={visible}
        width={"60%"}
      >
        <TaskForm id={task && task._id} onSubmit={handleTaskSubmit} />
      </MyModal>
      <MyModal
        title={
          <div className="flex justify-start align-end gap-4">
            <FaRegCalendarAlt />
            <h3
              style={{
                fontSize: "12px",
                marginBottom: "0px",
              }}
            >
              New Event
            </h3>
          </div>
        }
        onCancel={() => {
          setView(false);
          setEvent(null);
          getData();
        }}
        visible={view}
        width={"440px"}
      >
        <NewEvent
          user={user}
          event={event}
          reference="events"
          onSubmit={() => {
            getData();
            setView(false);
            setEvent(null);
          }}
        />
      </MyModal>
    </Wrapper>
  );
};
export default CalendarDashboard;
