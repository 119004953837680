import React from "react";
import { Icon } from "antd";
/* 
 LayoutOutlined,
  RightOutlined,
  MenuOutlined,
  PictureOutlined,
  TableOutlined,
  StarOutlined
*/
export const LayoutOutlined = () => <Icon type="layout" />;
export const RightOutlined = () => <Icon type="right" />;
export const LeftOutlined = () => <Icon type="left" />;
export const TableOutlined = () => <Icon type="table" />;
export const StarOutlined = () => <Icon type="star" />;
export const BgColorsOutlined = () => <Icon type="bg-colors" />;
export const FontColorsOutlined = () => <Icon type="font-size" />;
export const TrademarkOutlined = () => <Icon type="trademark" />;
export const MenuOutlined = () => <Icon type="menu" />;
export const PictureOutlined = () => <Icon type="picture" />;
