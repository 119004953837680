import styled from "styled-components";

export const Wrapper = styled.div`
  & .headline-container {
    padding: 8px 0px;
  }
  & .container {
    background: white;
    padding: 0px;
    margin: 4px;
    box-shadow: 3px 2px 2px #cccccc6b;
    border-radius: 4px;
    min-height: 500px;
  }
  & .tool-content {
    padding: 10px;
  }
`;
export const WrapperHeadLine = styled.div`
  & .headline-container {
    display: flex;
    justify-content: flex-start;
    aling-items: center;
    gap: 4px;
    h4 {
      margin-bottom: 0px;
    }
  }
  &.without-goback {
    .headline-container {
      padding-left: 35px !important;
    }
  }
  & .title h4 {
    font-size: 16px;
    font-weight: 500;
    color: #070707ab !important;
    padding: 2px 0px;
  }
`;
