import styled from "styled-components";

export const WrapperListWidget = styled.div`
  & .ant-card {
    border-radius: 8px;
    box-shadow: 0 0.75rem 1.5rem #12263f14;
    border: 1px solid #e8e8e8;
    .ant-card-body {
      padding: 8px 12px !important;
    }
    .container-body-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      .marque {
        // background: red;
        width: 100%;
      }
    }
  }
  & .ant-select-selection__rendered {
    line-height: 34px !important;
  }
  & .ant-select-selection--single {
    border-radius: 18px !important;
    border-color: var(--primary-button)!important;
  }
  & .ant-select-selection
    border-color: var(--primary-button);
  }
  & .ant-select-selection__placeholder{
    color: var(--primary-button)!important;
  }
  & .ant-select-arrow{
    color: var(--primary-button)!important;
  }
  & .ant-select-selection-selected-value{
    color: var(--primary-button)!important;
  }
  & .ant-select-clear-icon{
    .ant-btn{
      left: -5px!important;
      top: -6px!important;
   }
  }
  & .img-banner{
    height: 32px!important;
  }
`;
export const WrapperWidgetCard = styled.div`
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: ${({ background }) => background || "#FFF"};
`;
export const Wrapper = styled.div`
  & .widget-container {
    border: 1px solid #cccccc6b;
    border-radius: 4px;
  }
  & .drag-handle {
    margin: 0px 4px;
    padding: 0px 4px;
    color: #aaaaaa;
    cursor: pointer;
    display: block;
  }
  & .container {
    margin: 10px;
  }
  & .box {
    margin: 4px 10px;
    background: red;
    height: auto;
  }
  & .col-widget {
    padding: 8px 4px !important;
    padding-right: 4px !important;
  }
  & .col-widget-last {
    padding: 8px 4px !important;
  }
  & .calendar-widget {
    padding: 0px !important;
  }
  & .ant-fullcalendar-date {
    height: 70px !important;
    .ant-fullcalendar-content {
      height: 40px !important;
    }
  }
  & .task-col {
    padding-left: 0px !important;
  }
  & .general-statistics {
    margin-top: 10px;
  }
`;
