import styled from "styled-components";

export const WrapperTheme = styled.div`
  & .title {
    h3 {
      font-size: 16px;
      padding: 8px 0px;
    }
  }
  .container-button {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: ${({ color }) =>
      color ? `1px solid ${color}` : "rgb(0 0 0 / 65%)"} !important;
  }

  & .sketch-picker {
    box-shadow: none !important;
  }
`;
export const Wrapper = styled.div`
  & .ant-menu-item {
    background: #fff !important;
  }
  & .ant-list {
    background: #e5e5e526 !important;
    border-radius: 4px !important;
    margin-top: 0px !important;
    padding: 4px !important;
  }
  & .item-label {
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-start;
    align-item: center;
    font-size: 13px;
    padding: 2px 6px;
    color: var(--color-text);
    font-weight: 500;
  }
  & .ant-list-item {
    margin-bottom: 4px !important;
    cursor: pointer;
    .color-container {
      transition-property: all;
      transition-duration: 0.2s;
      opacity: 1;
    }
    .color-container:hover {
      transform: scale(1.08);
      opacity: 0.8;
    }
  }
  & .item-label > span {
    max-height: 24px !important;
    color: rgb(0 0 0 / 45%);
  }
  .container-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 8px;
    .ant-btn {
      padding: 0px !important;
    }
  }
`;
export const ItemWrapper = styled.div`
  margin: 0px;
  & .color-container {
    height: 30px;
    width: 30px;
    margin: 0px !important;
    padding: 4px;
    // border: 4px solid #13000014;
    box-shadow: 1px 1px 1px #ccc;
    background: ${(props) => props.color};
    border-radius: 50%;
  }

  & .color-background {
    background: ${(props) => props.color};
  }
`;
