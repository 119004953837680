import styled from "styled-components";
export const WrapperComments = styled.div`
  margin-bottom: 14px;
`;
export const WrapperCommentInput = styled.div`
  & .ant-avatar {
    color: rgb(245, 106, 0) !important;
    background-color: rgb(239 236 233) !important;
  }
  & .section-comments {
    width: 440px;
    display: flex;
    gap: 6px;
  }
  & .ant-comment-avatar {
    margin-right: 0px !important;
  }

  & .ant-form .ant-form-item-children .ant-input {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  & .section-comments .ant-input {
    box-shadow: 0px 1px 1px 0px #091e4205 !important;
    border-radius: 8px !important;
  }
  & .ant-comment-inner {
    gap: 4px !important;
    padding: 3px 20px !important;
    align-items: center;
    // background: #e8e8e854;
  }
  & .container-text-area {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & .button-save-message {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: end;
    .ant-btn {
      padding: 0px !important;
    }
    .icon-sent-comment {
      font-size: 20px !important;
    }
  }
  & .content-inputs {
    .input-placeholder {
      background-color: red !important;
    }
  }
  & .ant-comment-content-author {
    margin-bottom: 0px !important;
    display: none !important;
  }
  & .ant-comment-content-detail {
    .input-comments {
      background: #ededed82;
      border-radius: 4px;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 10px;
      span {
        color: #c5c0c0 !important;
      }
    }
  }
`;
export const WrapperCommentBox = styled.div`
  & .ant-form-item-children div:first-child > div:first-child {
    /* background: #efefef; */
    padding: 0px;
    border-radius: 12px;
    box-shadow: none !important;
    border: 0px !important;
    border-bottom: 0px !important;
  }
`;
export const CommentWrapper = styled.div`
  & .ant-list.ant-list-split {
    height: 200px;
    overflow-y: scroll;
  }
  .ant-row.footer-advanced-form div:last-child {
    margin-top: 0px !important;
    width: 100% !important;
  }
  & .ant-comment-inner {
    padding: 0px !important;
  }
  & .ant-list-header {
    margin-bottom: 10px !important;
  }
  & .ant-btn-link {
    color: rgb(52 58 64 / 0.6) !important;
  }
  & .modal-content .ant-form {
    margin-bottom: 0px !important;
  }
  & .ant-list-split .ant-list-item {
    border-bottom: 0px !important;
    // background: #e8e8e863;
    margin-bottom: 6px;
    border-radius: 8px;
  }
  & .ant-list-item {
    padding: 4px 6px !important;
  }
  & .ant-list-items {
    // background: #e8e8e859 !important;
    & .ant-comment {
      border-top: 1px solid #cccccc6e !important;
    }
    & .item-form {
      padding: 4px 0px !important;
    }
    & .form-fields {
      margin-left: 0px !important;
    }
    & .footer-advanced-form {
      display: none !important;
    }
    & .ant-form-item {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }
  }
  & .section-autor-date {
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    align-items: baseline;
    strong {
      font-size: 12px !important;
    }
    span {
      font-size: 10px !important;
      color: #ccc;
    }
  }
  .ant-comment-avatar {
    margin-right: 4px !important;
  }

  // & .list-comments .ant-list-item:nth-child(odd) {
  //   background-color: #ffffff; /* Color de fondo para elementos impares */
  // }

  // & .list-comments .ant-list-item:nth-child(even) {
  //   background-color: #f0f0f0; /* Color de fondo para elementos pares */
  // }
`;
