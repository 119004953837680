import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField } from "../com/form/";
import { Checkbox, Row, Col, Switch } from "antd";
import { Aminities, heating, cooling, sewer } from ".";
/* 
Heating
Cooling
Sewer
CableTvExpense
*/
const UtilitiesInformation = ({ id, onReset, onChange, ...props }) => {
  const [PropertyType, setPropertyType] = useState("Residential");
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const setInitialValues = () => {
    let { record } = props;
    if (record) {
      setRecord(record);
      setPropertyType(record["PropertyType"]);
    }
  };
  useEffect(() => {
    let { loading } = props;
    setLoading(loading);
    return () => {};
  }, [props.loading]);
  useEffect(() => {
    setInitialValues();
    return () => {};
  }, [props.record]);

  return (
    <>
      {!loading && (
        <SimpleForm
          source="my-listings"
          onChange={onChange}
          initialValues={record}
          autoSubmit={false}
        >
          <Switch name="CableTvExpense" label={"Cable Available?"} />
          <Switch name="CoolingYN" label={"Cooling?"} />
          {
            <SelectField
              name="Heating"
              label="Heating Description"
             /*  placeholder="Heating Description" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={heating.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="Cooling"
              label="Cooling Description"
              /* placeholder="Cooling Description" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={cooling.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="Sewer"
              label="Sewer Description"
              /* placeholder="Sewer Description" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={sewer.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
        </SimpleForm>
      )}
    </>
  );
};
export default UtilitiesInformation;
