import React, { useState, useEffect, useRef } from "react";
import { Input, AutoComplete, message } from "antd";
import styled from "styled-components";
import { getService } from "../../../services/";
import _ from "lodash";
const Wrapper = styled(AutoComplete)`
  & .ant-select-selection {
    background: transparent !important;
  }
  & .ant-input {
    border-radius: 20px !important;
    box-shadow: 3px 3px 3px rgba(224, 224, 224, 0.4) !important;
    border: 1px solid rgba(224, 224, 224, 0.8) !important;
    background: #fff !important;
  }
  & .ant-input-lg {
    font-size: 14px !important;
  }
  & .ant-select-search__field__wrap input {
    background: #e5ecee !important;
  }
  & .ant-select-selection__clear {
    display: none !important;
  }
`;
const SearchField = ({
  placeholder,
  choices,
  record,
  initial,
  defaultValue,
  defaultFilterValues = {},
  form,
  optionText = "name",
  optionValue = "_id",
  searchKey = "search",
  name,
  source,
  onData,
  onReset,
  onChange,
  ...props
}) => {
  const [data, setData] = useState([]);
  const service = getService(source || "");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [initialized, setInitialized] = useState(false);

  const getInitialValue = (_id) => {
    if (choices) {
      setLoading(false);
      return setData(choices || []);
    }
    if (source) {
      setLoading(true);
      if (!initial && !_id) {
        return setLoading(false);
      }
      service
        .get(initial || _id)
        .then((response) => {
          setLoading(false);
          setData([response]);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const handleOnSearch = (field, value) => {
    setValue(value);
    if (onChange && form) return onChange(value);
    if (onChange) onChange(searchKey, value);
  };
  const handleOnChange = (value) => {
    if (onChange && form) onChange(value ? value : null);
  };
  const search = (value) => {
    if (!value && onReset) return onReset();
    if (handleOnSearch && !source) handleOnSearch(searchKey, value);
    if (value && source) {
      setLoading(true);
      service
        .find({
          query: {
            [searchKey]: value,
            ...defaultFilterValues,
          },
        })
        .then(({ data }) => {
          setLoading(false);
          if (onData) onData(data);
          if (typeof data === "object" && Array.isArray(data)) {
            setData(data);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const handleSearch = _.debounce(search, 1000, { maxWait: 1000 });
  let myRef = useRef();
  const { Option } = AutoComplete;
  useEffect(() => {
    if (!initialized) {
      let value = props["data-__meta"]
        ? props["data-__meta"].initialValue
        : null;
      if (typeof value != "undefined") {
        getInitialValue(value);
        setInitialized(true);
      }
    }
  }, [props["data-__meta"]]);
  return (
    <>
      <Wrapper
        xtype="search"
        className="container-search"
        dropdownClassName={
          props.className || "certain-category-search-dropdown"
        }
        backfill
        notFoundContent={null}
        /*  dropdownMatchSelectWidth={500} */
        style={{ width: 300, ...props.style }}
        onSearch={handleSearch}
        onBlur={() => {
          if (form) {
            form.setFieldsValue({
              [name]: value,
            });
          }
        }}
        onSelect={(value) => {
          console.log(">>> Value: ", value);
          setValue(value);
          if (props.onSelectItem) {
            let record = data.find((item) => item._id === value);
            props.onSelectItem(name, value, record, form);
          }
        }}
        ref={myRef}
        dataSource={data.map((it) =>
          props.renderItem ? (
            props.renderItem(it)
          ) : (
            <Option key={it[optionValue]}>
              {typeof optionText == "function"
                ? optionText(it[optionValue], it)
                : it[optionText]}
            </Option>
          )
        )}
        onChange={handleOnChange}
        {...props}
      >
        <Input.Search
          className="search-input"
          loading={loading}
          value={value}
          size="large"
          style={{
            fontSize: 14,
          }}
          allowClear={props.allowClear}
          placeholder={placeholder || "Search"}
        />
      </Wrapper>
    </>
  );
};
export default SearchField;
