import React from "react";
import { WrapperForm } from "./Styles";
import { SimpleForm } from "../../components/com/form/";
import { Input, Row, Col, Divider } from "antd";
import S3Button from "../../components/com/form/S3Button";
import { PhoneField } from "../../components/com/fields";
import moment from "moment";
import _ from "lodash";
import { URL_DEFAULT_AVATAR } from "../../constants";
import * as actionTypes from "../../store/actions";
import { useDispatch } from "react-redux";
const format = "DD-MM-YYYY LT";
const { TextArea } = Input;
const PersonalInformation = ({ member, user, record, onChange, ...props }) => {
  const dispatch = useDispatch();
  const handleChange = _.debounce(onChange, 800, { maxWait: 800 });
  const handleChangeUser = (field, value) => {
    console.log("#######profile######", field, value);
  };
  const onCurrentUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    // dispatch({
    //   type: actionTypes.AUTHENTICATION,
    //   user,
    // });
  };
  const onSubmit = (err, data, form) => {
    console.log("#######onSubmit######", err, data, form);

    onCurrentUser(data);
  };
  return (
    <WrapperForm>
      <SimpleForm
        /*  id={record._id}
        source="users" */
        /* initialValues={record} */
        id={user._id}
        source="users"
        onSubmit={onSubmit}
        onChange={handleChangeUser}
        // autoSubmit={true}
      >
        <S3Button
          flex={1}
          remove
          record={record}
          idComponent="btn-send"
          className="flex-box centered"
          initial={record && record.picture}
          /* onChange={onChange} */
          path="users"
          finalPath="picture"
          source="picture"
          name="picture"
          id={record && record._id}
          defaultImage={URL_DEFAULT_AVATAR}
          itemType={"rounded"}
          size="large"
        />
        <Divider
          dashed
          style={{
            margin: 0,
            padding: 0,
            lineHeight: 0,
          }}
          flex={1}
          type="horizontal"
        />
        <Input type="hidden" initial="GOW" name="hostApp" />
        <Input
          flex={1}
          initial={record && record.first_name}
          label="First Name"
          placeholder="First Name"
          name="first_name"
        />
        <Input
          flex={1}
          initial={record && record.last_name}
          label="Last Name"
          placeholder="Last Name"
          name="last_name"
        />
        <Input
          flex={1}
          disabled
          initial={record && record.email}
          type="email"
          label="Email"
          placeholder="Email"
          name="email"
        />
        <PhoneField
          label="Phone"
          placeholder="Phone"
          name="phone"
          flex={1}
          input={true}
          // onChange={onChange}
          // initial={record && record.phone}
          validations={[{ max: 13, message: "Max value" }]}
        />
        <Input
          name="position"
          label="Position"
          initial={record && record.position}
          placeholder="Position"
          flex={1}
          validations={[{ message: "Position is required" }]}
        />
        <Divider
          style={{
            margin: 0,
            padding: 0,
            lineHeight: 0,
          }}
          flex={1}
          type="horizontal"
        />
        <TextArea
          // value={value}
          // onChange={this.onChange}
          flex={1}
          label="Write a brief description of yourself"
          name="about_me"
          initial={record && record.about_me}
          placeholder="About me"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        {member && (
          <Input
            disabled
            flex={1}
            initial={member && member.MemberAddress1}
            label="Address"
            placeholder="Address"
            name="MemberAddress1"
          />
        )}
        {member && (
          <Input
            disabled
            flex={1}
            initial={member && member.MemberCity}
            label="City"
            placeholder="City"
            name="MemberCity"
          />
        )}
        {member && (
          <Input
            flex={1}
            disabled
            initial={member && member.MemberStateOrProvince}
            label="State"
            placeholder="State"
            name="MemberStateOrProvince"
          />
        )}
        {member && (
          <Input
            flex={1}
            disabled
            initial={member && member.MemberPostalCode}
            label="Zip Code"
            placeholder="Zip Code"
            name="MemberPostalCode"
          />
        )}
        {member && (
          <Input
            disabled
            initial={member && member.MemberMlsId}
            label="License #"
            placeholder="License #"
            flex={1}
            name="MemberMlsId"
          />
        )}
        {member && (
          <Input
            disabled
            initial={member && member.MemberAOR}
            label="MLS Member Region"
            placeholder="MLS Member Region"
            flex={1}
            name="MemberAOR"
          />
        )}
        {member && (
          <Input
            disabled
            initial={
              member &&
              member.ModificationTimestamp &&
              moment(member.ModificationTimestamp).format(format)
            }
            label="Last Modification Date"
            placeholder="Last Modification Date"
            flex={1}
            name="ModificationTimestamp"
          />
        )}
        {member && (
          <Input
            disabled
            initial={
              member &&
              member.LastLoginTimeStamp &&
              moment(member.LastLoginTimeStamp).format(format)
            }
            label="Last Login"
            placeholder="Last Login"
            flex={1}
            name="LastLoginTimestamp"
          />
        )}
      </SimpleForm>
    </WrapperForm>
  );
};
export default PersonalInformation;
