import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Wrapper, BoxContainer } from "./Styles";
import {
  Row,
  Col,
  Card,
  message,
  Alert,
  Affix,
  Button,
  Layout,
  Empty,
  Spin,
} from "antd";
import { getService } from "../../services";
import GeneralInformation from "./GeneralInformation";
import FinantialInformation from "./FinantialInformation";
import LocationInformation from "./LocationInformation";
import AditionalInformation from "./AditionalInformation";
import AminitiesInformation from "./AminitiesInformation";
import UtilitiesInformation from "./UtilitiesInformation";
import PropertyGallery from "./PropertyGallery";
import GalleryListings from "./GalleryListing";
import { navigate } from "@reach/router";

const { Header } = Layout;
const Box = ({ title, children, ...props }) => {
  return (
    <BoxContainer {...props}>
      <Card title={title}>{children}</Card>
    </BoxContainer>
  );
};
const ListingForm = ({ id, ...props }) => {
  const [payloads, setPayloads] = useState({
    PropertyType: "Residential",
  });
  const [record, setRecord] = useState();
  const [error, setError] = useState();
  const [notification, setNotification] = useState({
    type: "warning",
  });
  const service = getService("my-listings");
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(null);
  const [generalform, setGeneralForm] = useState(null);
  const [span, setSpan] = useState();
  const onChange = (field, value, _form, errors) => {
    setError(typeof errors != undefined);
    if (errors) {
      return setNotification({
        type: "warning",
        message: "Please verify the property information.",
      });
    }
    if (generalform) {
      generalform.validateFieldsAndScroll((errors, values) => {
        setError(!!errors);
        if (errors) {
          return setNotification({
            type: "warning",
            message: "Please verify the property information.",
          });
        }
        setError(payloads.ListingType !== "GOW");
        /* if (payloads.ListingType != "GOW") {
          return setNotification({
            type: "warning",
            message: "This property cannot be modified."
          });
        } */
        let params = {};
        if (field === "Latitude" || field === "Longitude") {
          params = {
            ...params,
            GeoCode: {
              ...payloads.GeoCode,
              [field.toLowerCase()]: value,
            },
          };
        }
        params = {
          ...payloads,
          ...params,
          [field]: value,
        };
        setPayloads(params);
        save(params);
        /* onChange(field, value, form, errors); */
      });
    }
  };
  const handleOnChange = _.debounce(onChange, 1000, { maxWait: 1000 });
  const getData = () => {
    if (!id || id === "create") return setLoading(false);
    if (id) {
      const service = getService("properties");
      setLoading(true);
      service
        .get(id)
        .then((record) => {
          if (record) {
            console.log(">>> LISTING: ", record);
            setPayloads(record);
            setRecord(record);
          }
          setLoading(false);
        })
        .catch((err) => {
          navigate(`/dashboard/mylistings`);
          message.error(err.message);
        });
    }
  };
  const save = async (payloads) => {
    console.log("-------estoy en el save---payloads--------", payloads);
    generalform.validateFields((errors, values) => {
      if (!errors) {
        service
          .create({
            ListingType: "GOW",
            ...payloads,
          })
          .then((resp) => {
            message.success("Property Created!");
            navigate(`/dashboard/mylistings/${resp._id}`);
          })
          .catch((err) => {
            message.error(err.message);
          });
        if (id) {
          return service
            .patch(id, payloads)
            .then((resp) => {
              message.success("Property Updated!");
            })
            .catch((err) => {
              message.error(err.message);
            });
        }
      }
    });
  };
  const handleOnMountGeneralInformation = (form) => {
    setGeneralForm(form);
  };
  const handleReset = (data) => {
    setLoading((loading) => !loading);
    setRecord({ ...record, ...data });
    setPayloads({ ...record, ...data });
    setLoading(false);
  };
  useEffect(() => {
    setSpan(
      id
        ? {
            xl: 8,
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24,
          }
        : {
            xl: 12,
            lg: 12,
            md: 24,
            sm: 24,
            xs: 24,
          }
    );
    getData();
    return () => {};
  }, [id]);
  return (
    <Wrapper>
      <Row type="flex" justify="center" align="middle">
        <Col xxl={22} xl={22} lg={24} md={24} sm={24}>
          <Row
            className="property-container"
            gutter={4}
            type="flex"
            justify="space-between"
            align="top"
          >
            {id && (
              <Col xl={8} md={24} lg={24} sm={24} xs={24}>
                <Row gutter={10}>
                  {payloads && payloads.Media && payloads.Media.length > 0 && (
                    <Col>
                      <PropertyGallery dataSource={payloads.Media} />
                    </Col>
                  )}
                  {payloads && payloads.ListingType === "GOW" && (
                    <Col>
                      <Box title="Gallery">
                        <GalleryListings
                          onChange={handleOnChange}
                          id={id}
                          Media={payloads.Media}
                          /* data={[]} */
                          {...record}
                        />
                      </Box>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
            <Col {...span}>
              <Row gutter={10}>
                <Col>
                  <Box title="Finantial Information">
                    <FinantialInformation
                      id={id}
                      loading={loading}
                      record={record}
                      payloads={payloads}
                      onReset={handleReset}
                      onChange={handleOnChange}
                    />
                  </Box>
                </Col>
                <Col>
                  <Box title="General Information">
                    <GeneralInformation
                      id={id}
                      loading={loading}
                      record={record}
                      onMount={handleOnMountGeneralInformation}
                      onReset={handleReset}
                      onChange={handleOnChange}
                    />
                  </Box>
                </Col>
                <Col>
                  <Box title="Location">
                    <LocationInformation
                      id={id}
                      loading={loading}
                      record={record}
                      payloads={payloads}
                      onReset={handleReset}
                      onChange={handleOnChange}
                    />
                  </Box>
                </Col>
              </Row>
            </Col>
            <Col {...span}>
              <Row gutter={10}>
                <Col>
                  <Box title="Amenities">
                    <AminitiesInformation
                      id={id}
                      loading={loading}
                      record={record}
                      payloads={payloads}
                      onReset={handleReset}
                      onChange={handleOnChange}
                    />
                  </Box>
                </Col>
                <Col>
                  <Box title="Aditional Information">
                    <AditionalInformation
                      id={id}
                      loading={loading}
                      record={record}
                      payloads={payloads}
                      onReset={handleReset}
                      onChange={handleOnChange}
                    />
                  </Box>
                </Col>
                <Col>
                  <Box title="Utilities">
                    <UtilitiesInformation
                      id={id}
                      loading={loading}
                      record={record}
                      payloads={payloads}
                      onReset={handleReset}
                      onChange={handleOnChange}
                    />
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          {error && (
            <Affix offsetBottom={10}>
              <Row type="flex" justify="center" align="middle">
                <Col>
                  <Alert
                    onClose={() => setError(false)}
                    showIcon
                    closable
                    {...notification}
                  />
                </Col>
              </Row>
            </Affix>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};
export default ListingForm;
