import momentTZ from "moment-timezone";
const moment = (date) => momentTZ(date).tz("America/New_York");
export { moment };
export const COLORS = [
  {
    background: "#f5276d",
    color: "#fff",
  },
  {
    background: "#ffa117",
    color: "#fff",
  },
  {
    background: "#2196f3",
    color: "#fff",
  },
  {
    background: "#6f63f4",
    color: "#fff",
  },
  {
    background: "#56b475",
    color: "#fff",
  },
  {
    background: "#795548",
    color: "#fff",
  },
  {
    background: "#795548",
    color: "#fff",
  },
  {
    background: "#ffea30",
    color: "#172533",
  },
  {
    background: "#d4d9dd",
    color: "#172533",
  },
  {
    background: "#19c3d8",
    color: "#fff",
  },
];

export const generateDinamicFieldValue = (field, value, arrayKeys = []) => {
  let temp = {};
  if (Array.isArray(field)) {
    field.reduce((prev, fieldName, index) => {
      if (index == field.length - 1) {
        if (Array.isArray(prev)) {
          if (typeof value == "object") {
            prev.push(value);
          } else {
            prev.push({ [fieldName]: value });
          }
        } else
          prev[fieldName] =
            typeof value == "object" ? { ...prev[fieldName], ...value } : value;
      } else {
        prev[fieldName] = arrayKeys.includes(fieldName) ? [] : {};
      }
      console.log(prev[fieldName], temp);
      return prev[fieldName];
    }, temp);
  }
  return temp;
};
