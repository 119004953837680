import styled from "styled-components";

export const Wrapper = styled.div`
& .container {
    min-height: 500px;
    background: white;
    border-radius: 4px;
    box-shadow: 3px 2px 2px #cccccc6b;
    padding:10px!important;
    margin:4px;
  }
`;
