import React, { useEffect, useState } from "react";
import * as fields_types from ".";
const DinamicField = ({ xtype, ...props }) => {
  const [record, setRecord] = useState();
  const [options, setOptions] = useState();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (props.options) setOptions(props.options);
  }, [props.options]);
  useEffect(() => {
    if (options) setMounted(true);
  }, [options]);
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  if (!mounted) return null;
  return React.createElement(fields_types[xtype] || fields_types["textfield"], {
    ...props,
    record,
    ...options,
  });
};

export default DinamicField;
