import React from "react";
import styled from "styled-components";
import { Input, Button } from "antd";
const { Search } = Input;
export const SearchBox = styled(Search)`
  /* box-shadow: 3px 3px 3px rgba(0,0,0,0.03) !important */
  border-radius: 20px !important;
  & input {
    border-radius: 20px !important;
  }
`;

export const WrapperSkeleton = styled.div`
  padding: 4px 16px 0px;
  background: #fff;
  // height: 100vh;
  border-radius: 8px;
  & .skeleton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .container-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }
  & .skeleton-loading {
    width: 288px;
    height: 30px;
    background-color: #f0f0f0e3;
    margin: 10px 0;
    border-radius: 16px;
    animation: skeleton-loading 1s infinite ease-in-out; /* Animación */
  }

  @keyframes skeleton-loading {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    50% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  & .skeleton-select {
    width: 104px;
    height: 22px;
    background-color: #f3f3f3;
    margin: 10px 0;
    border-radius: 16px;
    animation: skeleton-select 1s infinite ease-in-out; /* Animación */
  }
  & .skeleton-row {
    width: 100%;
    height: 48px;
    background-color: #f3f3f3;
    margin: 4px 0;
    animation: skeleton-select 1s infinite ease-in-out; /* Animación */
  }

  @keyframes skeleton-select {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    50% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  & .skeleton-select-rounded {
    width: 34px;
    height: 34px;
    background-color: #f0f0f0e3;
    margin: 10px 0;
    border-radius: 50%;
    animation: skeleton-select-rounded 1s infinite ease-in-out; /* Animación */
  }
  & .skeleton-avatar-rounded {
    width: 46px;
    height: 44px;
    background-color: #f0f0f0e3;
    margin: 0px;
    border-radius: 50%;
    animation: skeleton-select-rounded 1s infinite ease-in-out; /* Animación */
  }

  @keyframes skeleton-select-rounded {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    50% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  & .avatar-table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  & .skeleton-table {
    width: 100%;
    height: 54px;
    border: 1px solid #f0f0f0;
    margin: 4px 0px 0px;
    border-radius: 6px;
    animation: skeleton-table 1s infinite ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 6px 20px;
    background: #e8e8e869;
  }
  & .skeleton-table-body {
    width: 100%;
    // height: 80%;
    border: 1px solid #f0f0f0;
    margin: 0px;
    border-radius: 6px;
    animation: skeleton-table 1s infinite ease-in-out;
    padding: 6px 6px;
  }
  @keyframes skeleton-table {
    0% {
      opacity: 0.5;
    }
    40% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
export const Wrapper = styled.div`
  background: #fff !important;
  border-radius: 4px !important;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03) !important;
  border: 1px solid #e8e8e85e !important;
  & .ant-table {
    border-radius: 4px !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05) !important;
  }
  & table.ant-table-fixed {
    margin: 0px !important;
  }
  & .extra-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    .ant-btn {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      /* background: #fff;
      color: var(--primary-button); */
      padding: 0 4px !important;
    }
  }

  & .grid-tool-bar {
    margin: 8px 4px 0px 0px !important;
  }
  & .grid-title h3 {
    font-size: 20px !important;
    margin-bottom: 0px !important;
  }
  & .section-roundedbutton {
    margin: 0px !important;
  }
  & .ant-table-header {
    height: 56px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    background: #f3f3f3 !important;
  }
  & .ant-table-fixed-right {
    box-shadow: -2px 0 6px -4px rgba(0, 0, 0, 0.15) !important;

    .ant-table-header {
      height: 56px;
      background-color: #ab2323;
      padding-bottom: 0px !important;
    }
  }

  & .ant-table-thead > tr {
    height: auto;
  }
  & .ant-table-wrapper {
    padding: 6px 12px;
    background: #f5f3f3b0 !important;
  }
  & .ant-table-thead > tr > th {
    border-bottom: 1px solid #e8e8e803 !important;
    padding: 14px 6px !important;
  }
  & .grid-tools-container {
    border-bottom: 0pximportant;
  }
`;

export const RoundedButton = styled(Button)`
  border-radius: 20px !important;
  padding: 4px 8px !important;
  margin: 0px !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;
