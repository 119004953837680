import { Button, Icon, message } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getService } from "../../../services";
import { HeadLine, Wrapper } from "./Styles";
import moment from "moment";
import { MyModal } from "../../com";
import { Link } from "@reach/router";
const ListWidget = ({ className, defaultFilterValues = {}, source, showAll = false, reference, basePath, icon, iconItem, title, ...props }) => {
    const service = getService(reference);
    const user = useSelector(({ user }) => (user));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [record, setRecord] = useState({});
    const getData = () => {
        setLoading(true);
        service.find({
            query: {
                user_id: user._id,
                ...defaultFilterValues,
                $sort: {
                    createdAt: -1
                },
                $limit: 4
            }
        })
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
    }
    const handleShow = (record) => {
        setRecord(record);
        setShow(true);
    }
    const handleClick = (item) => {
        if (props.onClick)
            props.onClick(item);
    }
    const renderItem = (item, index) => {
        if (props.renderItem) return props.renderItem(item, index);
        return (<div className="item-container">
            <div className="item">
                <div className="item-tools">
                    {<Link
                        to={!props.onClick ? `${basePath || `/dashboard/${reference}/${item._id}`}` : "#"}
                        onClick={() => handleClick(item)}
                        type="link">
                        <span className="item-text">
                            <Icon type={iconItem || "user"} />{" "}
                            {item[source || "name"] || ""}</span>
                        {<Icon type={"eye"} size="small" />}
                    </Link>}
                </div>
            </div>
        </div>);
    }
    useEffect(() => {
        if (user && user._id)
            getData();
    }, [user]);
    return (<Wrapper showAll={showAll}>
        <HeadLine color="#FFF">
            {<Icon type={icon || "team"} size="large" />}{" "}
            {<span>{title || "Last Contacts"}</span>}

        </HeadLine>
        <div className={`item-list list-widget ${className || "item-100"}`}>
            {data.map(renderItem)}
            {showAll && data.length > 0 &&
                <div className="footer-items">
                    <Link
                        to={`${basePath || `/dashboard/${reference}/`}`}
                        type="link">Show all</Link>
                </div>
            }
        </div>
        <MyModal
            title="Edit contact"
            visible={show}
            width={"60%"}
            onCancel={() => {
                setShow(false);
                if (user && user._id)
                    getData();
            }}
        >
            {JSON.stringify(record)}
        </MyModal>
    </Wrapper>)
}
export default ListWidget;