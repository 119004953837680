import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Divider, Input, Row } from "antd";
import moment from "moment";
import _ from "lodash";
import { SimpleForm } from "../../components/com/form/";
import { PhoneField } from "../../components/com/fields";
import { getService } from "../../services";
import { useSelector } from "react-redux";
import { WrapperForm } from "./styles";
import { ProfileContext } from "./ProfileContext";

const format = "DD-MM-YYYY H:m:s";
const PersonalInformation = ({
  member,
  record,
  initialValues,
  onChange,
  ...props
}) => {
  const { setPayloads, setService } = useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState();
  const onMount = (form) => {
    setForm(form);
  };
  useEffect(() => {
    if (form && props.onMount) {
      setService("users");
      props.onMount(form);
    }
  }, [form]);
  useEffect(() => {
    if (record) setPayloads(record);
  }, [record]);
  return (
    <WrapperForm>
      <SimpleForm
        initialValues={initialValues}
        title={
          <>
            <div>
              <h2 className="font-lg">Complete Personal Information</h2>
              <article>
                Please complete your profile information to help us personalize
                our recommendations and services to your needs. We value your
                privacy and will keep your information confidential. Thank you.
              </article>
            </div>
          </>
        }
        onMount={onMount}
        onChange={onChange}
        autoSubmit={false}
      >
        <Input type="hidden" initial="GOW" name="hostApp" />
        <Input
          validations={[{ required: true, message: "First Name is required" }]}
          initial={record && record.first_name}
          label="First Name"
          placeholder="First Name"
          name="first_name"
        />
        <Input
          initial={record && record.last_name}
          label="Last Name"
          placeholder="Last Name"
          name="last_name"
        />
         <PhoneField
          input
          validations={[{ max: 13, message: "Max value" }]}
          grid={{
            xxl: 12,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24,
          }}
          size="large"
          flex={0.5}
          placeholder="Phone"
          label="Phone"
          name="phone"
        />
        <Input
          flex={0.5}
          disabled
          initial={record && record.email}
          type="email"
          label="Email"
          validations={[{ required: true, message: "Email is required" }]}
          placeholder="Email"
          name="email"
        />
      </SimpleForm>
    </WrapperForm>
  );
};

export const CompleteProfile = ({
  onChange,
  initialValues,
  onMount,
  ...props
}) => {
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(true);
  const user = useSelector((data) => data.user);
  const getUser = async () => {
    const service = getService("users");
    const record = await service.get(user._id);
    setRecord(record);
    setLoading(false);
  };

  useEffect(() => {
    if (user) getUser();
  }, [user]);
  if (loading) return "loading...";
  return (
    <PersonalInformation
      initialValues={initialValues}
      onMount={onMount}
      onChange={onChange}
      record={record}
    />
  );
};
