import React, { useState } from "react";
import { colors } from "../../../constants/";
import { Button, List } from "antd";
import { Wrapper, ItemWrapper } from "./Styles";

const ColorPicker = ({ onChange, title, ...props }) => {
  const [showAll, setShowAll] = useState(false);

  // Número de colores que quieres mostrar inicialmente
  const initialColorsToShow = 6;

  // Maneja la lógica para mostrar todos o solo algunos colores
  const colorsToShow = showAll ? colors : colors.slice(0, initialColorsToShow);

  const handleColorSelect = (item) => {
    if (onChange) {
      onChange(item);
    }
  };

  const renderItem = (item) => (
    <List.Item>
      <ItemWrapper onClick={() => handleColorSelect(item)} color={item}>
        <div className="color-container">
          <div className="color-picker" />
          <div className="color-background" />
        </div>
      </ItemWrapper>
    </List.Item>
  );

  return (
    <Wrapper>
      {title && (
        <div className="item-label">
          <span>{title}</span>
        </div>
      )}
      <div className="container-list-color">
        <div className="container-button">
          <Button type="link" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Show Less" : "Show All"}
          </Button>
        </div>
        <List
          style={{ maxWidth: 220 }}
          gutter={4}
          grid={{
            gutter: 4,
            xl: 5,
            lg: 5,
            md: 5,
            sm: 5,
            xs: 4,
          }}
          dataSource={colorsToShow}
          renderItem={renderItem}
        />
      </div>
    </Wrapper>
  );
};

export default ColorPicker;
