import { Avatar, Button, Card, List, Skeleton, message } from "antd";
import React, { useEffect, useState } from "react";
import { WrapperSkeletonSocial, WrapperSocialWidget } from "./Styles";
import { useSelector } from "react-redux";
import { LOGO_BLUECLOSERX, URL_S3 } from "../../../constants";
import { getService } from "../../../services";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { navigate } from "@reach/router";
const { Meta } = Card;
const socials = {
  facebook: {
    text: "Facebook",
    name: "facebook",
    quantity: "15",
    colorText: "#FFF",
    background: "#3B5998",
    icon: <FaFacebook />,
    // icon: "/images/facebook.svg",
  },
  instagram: {
    text: "Instagram",
    name: "instagram",
    quantity: "15",
    colorText: "#FFF",
    background: "#e83e8c",
    icon: <FaInstagram />,
    // icon: "/images/instagram.svg",
  },
  twitter: {
    text: "Twitter",
    name: "twitter",
    quantity: "15",
    colorText: "#FFF",
    background: "rgb(0 0 0 / 80%)",
    icon: <RiTwitterXFill />,
    // icon: "/images/twitter.svg",
  },
  linkedin: {
    text: "Linkedin",
    name: "linkedin",
    quantity: "15",
    colorText: "#FFF",
    background: "#0077B7",
    icon: <FaLinkedin />,
    // icon: "/images/linkedin.svg",
  },
};
const SkeletonSocial = ({ loading, count = 4, children, ...props }) => {
  return (
    <WrapperSkeletonSocial>
      {loading ? (
        <Card>
          <div className="section-title">
            <div className="container-avatar">
              <div className="flex justify-center align-center">
                <Avatar size="large" />
              </div>
              <div className="flex justify-center">
                <div className="name-social" />
              </div>
              <div className="title-description" />
            </div>
          </div>
          <div className="section-descriptions">
            {Array.from({ length: count }).map((_, index) => (
              <div key={index} className="container-avatar">
                <div className="flex justify-center align-center">
                  <Avatar
                    size="small"
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                </div>
                <div className="flex justify-center align-center">
                  <div>
                    <div className="social-text" />
                    <div className="flex justify-center align-center">
                      <div className="section-item-key" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      ) : (
        <div>{children}</div>
      )}
    </WrapperSkeletonSocial>
  );
};
const SocialWidget = ({ type, ...props }) => {
  const [dataSource, setDataSource] = useState([]);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [loading, setLoading] = useState(false);
  const [max_value_item, setMaxValueItem] = useState();
  const [payloads, setPayload] = useState();
  const [mounted, setMounted] = useState(false);
  const getData = () => {
    // return setLoading(true);
    const service = getService("posts-counts");
    setLoading(true);
    service
      .find({
        query: {
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        setDataSource(data);
        const majorElement = data.reduce((maxItem, currentItem) => {
          const maxValue = Object.values(maxItem)[0]; // Valor numérico del elemento más grande hasta ahora
          const currentValue = Object.values(currentItem)[0]; // Valor numérico del elemento actual
          // Comparamos los valores numéricos y mantenemos el primer elemento como el más alto
          return currentValue > maxValue ? currentItem : maxItem;
        }, data[0]); // Iniciamos con el primer elemento como el valor más alto
        setMaxValueItem(majorElement);
        setLoading(false);
        setMounted(true);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const defaulRenderItem = (item) => {
    let key = Object.keys(item)[0];
    let social = socials[key];
    return (
      <>
        <div className="container-social">
          <div>
            <div className="container-avatar">
              <Avatar
                className="flex justify-center align-center"
                // size="small"
                icon={social.icon}
                style={{
                  background:
                    type == "white-color" ? social.background : undefined,
                }}
              />
            </div>

            <div className="social-text">{social.text}</div>
            <div className="section-item-key">
              <span>{item[key]}</span>
            </div>
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (max_value_item) {
      let socialMedia = Object.keys(max_value_item)[0];
      let social = socials[socialMedia];
      let quantity = Object.values(max_value_item)[0];
      setPayload({
        socialMedia,
        quantity,
        social,
      });
    }
  }, [max_value_item]);
  if (!mounted) return <SkeletonSocial loading={!mounted} />;
  return (
    <WrapperSocialWidget>
      <Card hoverable onClick={() => navigate("/dashboard/social")}>
        <Meta
          title={
            <div className="section-title">
              <div>
                <Avatar
                  size="large"
                  icon={payloads && payloads.social && payloads.social.icon}
                  style={{
                    background:
                      type == "white-color"
                        ? payloads &&
                          payloads.social &&
                          payloads.social.background
                        : undefined,
                  }}
                />
                <div className="name-social">
                  <h3>
                    {payloads && payloads.socialMedia} -{" "}
                    <span>{payloads && payloads.quantity} Post</span>
                  </h3>
                </div>
                <div className="title-description">
                  <p>
                    Your Top Social Network Now <br />
                    Total Posts
                  </p>
                </div>
              </div>
            </div>
          }
          description={
            <div className="section-descriptionss">
              <List
                // loading={loading}
                grid={{
                  xxl: 4,
                  xl: 4,
                  lg: 4,
                  md: 4,
                  sm: 4,
                  xs: 4,
                  gutter: { xl: 4, xxl: 4, xs: 4, sm: 8, lg: 8 },
                }}
                // itemLayout={"horizontal"}
                dataSource={dataSource}
                renderItem={defaulRenderItem}
              />
            </div>
          }
        />
      </Card>
    </WrapperSocialWidget>
  );
};
export default SocialWidget;
