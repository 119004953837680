import { Avatar, Button, Card, List, message } from "antd";
import React, { useState } from "react";
import {
  DEFAULT_IMAGE_BOARD,
  URL_DEFAULT_AVATAR,
  URL_S3,
} from "../../constants";
import { WrapperBoardForm } from "./Styles";
import { getService } from "../../services";
import { navigate } from "@reach/router";
const { Item } = List;
const data = [
  {
    author: {
      id: 1,
      name: "Prueba cinco",
      picture: null,
      image: null,
      first_name: "wilfrido",
      last_name: "corbacho",
    },
  },
];
const ListTemplate = ({ board_type, user, keep_tasks, ...props }) => {
  const [template, setTemplate] = useState({});
  const [submithing, setSubmithing] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnClick = (item) => {
    if (props.handleOnClick) props.handleOnClick(item);
    /*  setView("template-detail");
        setRecord(item); */
    // setTemplate(item);
    // console.log(view);
  };
  const handleUseTemplate = (item) => {
    let id = item._id;
    let payloads = {
      board_type: board_type,
      company_id: user && user.company_id,
      user_id: user && user._id,
      template_id: id,
      name: `${item.name} - Copy`,
      keep_tasks: keep_tasks,
    };
     return console.log("que template seleccione", payloads);
    const service = getService("boards");
    setSubmithing(true);
    service
      .create(payloads)
      .then((resp) => {
        message.success("Template Cloned");
        setSubmithing(false);
        navigate(`/dashboard/boards/${resp._id}`);
      })
      .catch((err) => {
        message.error(err.message);
        setSubmithing(false);
      });
  };
  const renderItem = (item, index) => {
    let { author, background, image } = item;
    return (
      <WrapperBoardForm>
        <Item>
          <div>
            <Card
              style={{ width: 254 }}
              hoverable
              onClick={() => {
                setTemplate(item);
              }}
              cover={
                <img
                  className={`container-${
                    item && item.image ? "image-cover" : "cover-void"
                  } `}
                  alt="example"
                  src={
                    item.image
                      ? `${URL_S3}/${item && item.image}`
                      : DEFAULT_IMAGE_BOARD
                  }
                />
              }
            >
              {
                <div className="container-actions">
                  <div className="container-button">
                    <Button
                      className="btn-preview"
                      onClick={() => handleOnClick(item)}
                    >
                      Preview
                    </Button>
                  </div>
                  <div className="container-button">
                    <Button
                      className="btn-template"
                      onClick={() => handleUseTemplate(item)}
                    >
                      Use Template
                    </Button>
                  </div>
                </div>
              }
              {/* </div> */}
            </Card>
            <div className="card-avatar-company">
              <Avatar
                className="avatar-company"
                src={
                  item.author && item.author.picture
                    ? `${URL_S3}/${item.author.picture}`
                    : URL_DEFAULT_AVATAR
                }
                size="large"
              />
              <div className="card-company-name">
                <h4>
                  {item &&
                    item.author &&
                    `${item.author.first_name} ${item.author.last_name}`}
                </h4>
                {/* <h3>{item.name}</h3> */}
              </div>
            </div>
            <div className="section-name-board">
              <span>{item.name}</span>
            </div>
          </div>
        </Item>
      </WrapperBoardForm>
    );
  };
  return (
    <>
      <List
        style={{
          maxHeight: 450,
          overflowY: "auto",
          padding: "18px",
        }}
        grid={{ gutter: 8, xs: 1, md: 1, xl: 2, lg: 2, xxl: 3 }}
        loading={loading}
        renderItem={renderItem}
        dataSource={data}
      />
    </>
  );
};
export default ListTemplate;
