import { Avatar, Icon, List, message, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { getService } from "../../services";
import Loader from "../../components/loader";
import * as messages from "../../constants/messages";
import { UserProfileWrapper } from "./Styles";
import { useLocation } from "@reach/router";
import qs from "querystring";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
import MarkDown from "../../components/markdown/Markdown";
import moment from "moment";
const UserProfileActivity = ({ id, ...props }) => {
  const location = useLocation();
  const [record, setRecord] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const getUserProfile = async () => {
    const service = getService("users");
    try {
      setLoading(true);
      const profile = await service.get(id);
      setRecord(profile);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };
  const getUserActivity = async () => {
    try {
      let params = qs.parse(location.search);
      let query = { user_id: id };
      if (params) {
        let { task_id, board_id } = params;
        query = { reference_id: task_id };
      }
      const service = getService("log-actions");
      let { data } = await service.find({
        query: {
          ...query,
          user_id: id,
          $sort: {
            createdAt: -1,
          },
        },
      });
      setDataSource(data);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
    }
  };
  const renderItem = (item, index) => {
    let { log, action_type } = item;
    let author = log.author || log.user;
    return (
      <List.Item key={item._id}>
        <List.Item.Meta
          avatar={
            <Avatar
              size="medium"
              src={
                item.log && item.log.social
                  ? (author && author.picture) || URL_DEFAULT_AVATAR
                  : action_type == "birthdays"
                  ? "/images/birthday-cake.svg"
                  : author && author.picture
                  ? `${URL_S3}/${author.picture}`
                  : URL_DEFAULT_AVATAR
              }
            />
          }
          title={
            <span>
              {author &&
                (author.first_name ? (
                  <strong>
                    {author.first_name || ""} {author.last_name || ""}
                  </strong>
                ) : (
                  <strong>
                    {author.email.substring(0, author.email.indexOf("@"))}
                  </strong>
                ))}{" "}
              <span>{moment(item.createdAt).fromNow()}</span>
            </span>
          }
          description={
            <MarkDown
              source={messages.tree[item.action_type].format({
                ...log,
                author: author,
              })}
            />
          }
        />
      </List.Item>
    );
  };
  useEffect(() => {
    if (location && location.search) getUserActivity();
  }, [location]);
  useEffect(() => {
    if (id) getUserProfile();
  }, [id]);
  if (loading) return <Loader />;
  return (
    <UserProfileWrapper>
      <div>
        <div className="head-profile">
          <List.Item.Meta
            title={`${(record && record.first_name) || ""} ${(record &&
              record.last_name) ||
              ""}`}
            description={`@${record &&
              record.email.substring(0, record.email.indexOf("@"))}`}
            avatar={
              <Avatar
                size="large"
                src={
                  record && record.picture
                    ? `${URL_S3}/${record.picture}`
                    : URL_DEFAULT_AVATAR
                }
              />
            }
          />
        </div>
        <List
          header={
            <div>
              <h3>
                <Icon type="unordered-list" /> <span>Activity</span>
              </h3>
            </div>
          }
          renderItem={renderItem}
          loading={loading}
          dataSource={dataSource}
        />
      </div>
    </UserProfileWrapper>
  );
};

export default UserProfileActivity;
