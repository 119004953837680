import styled from "styled-components";
export const WrapperPost = styled.div`
  .ant-card {
    border-radius: 8px;
    .ant-card-body {
      padding: 4px 16px !important;
    }
    .ant-card-head-title {
      padding: 8px 0 !important;
    }
    .ant-card-head {
      padding: 0 10px !important;
      border-bottom: 0px solid #e8e8e8 !important;
      border-radius: 2px 2px 0 0;
    }
  }
  & .footer-actions {
    .ant-btn {
      color: var(--color-black-linkedin) !important;
      width: 144px !important;
      height: 32px!important;
      :hover {
        background: #e8e8e88a !important;
      }
    }
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 2px 2px;
    .icon-text {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
  }
  & .date {
    line-height: 0.8;
    span {
      font-size: 10px !important;
      color: rgba(0, 0, 0, 0.45) !important;
    }
  }
  & .description {
    span {
      font-size: 12px !important;
      color: #404040ed !important;
    }
  }
  & .title-first {
    h3 {
      font-size: 14px !important;
      margin-bottom: 0px !important;
    }
  }
  & .title-content {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    align-items: center;
  }
  & .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    align-items: center;
    padding: 4px;
  }
  & .container-media {
    flex: 1 1 calc(33.333% - 16px); /* Ajusta el tamaño de las columnas */
    box-sizing: border-box;
    img {
      display: block;
      width: ${({ count_media }) => (count_media > 1 ? "100%" : "100%")};
      height: ${({ count_media }) => (count_media > 1 ? "90px" : "100%")};
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .count-like-and-count-comments {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px 4px;
    border-bottom: 1px solid #cccccc9e;
    .show-comments {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      :hover {
        text-decoration: underline;
      }
    }
    .count-length {
      font-size: 14px !important;
    }
    .coments-text {
      font-size: 11px !important;
      color: rgb(0 0 0 / 0.6) !important;
      :hover {
        color: var(--primary-button) !important;
      }
    }
    .like-icon-total {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
  }
  @media (min-width: 512px) and (max-width: 767px) {
    .container-media img {
      // width: ${({ count_media }) => (count_media > 1 ? "228px" : "100%")};
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .container-media img {
      // width: ${({ count_media }) => (count_media > 1 ? "142px" : "100%")};
    }
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    .container-media img {
      // width: ${({ count_media }) => (count_media > 1 ? "150px" : "100%")};
    }
  }
  @media (min-width: 1200px) and (max-width: 1920px) {
    .container-media img {
      // width: ${({ count_media }) => (count_media > 1 ? "150px" : "100%")};
    }
  }
`;
export const WrapperPostList = styled.div`
  & .list-ant {
    height: 630px;
    padding: 0px 1px;
    overflow-y: scroll;
  }
  & .empty-list {
    .ant-empty-image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .ant-list-empty-text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh !important;
  }
  .ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 10px !important;
  }
`;

export const Box = styled.div`
  padding: 16px !important;
  box-shadow: 0 0.75rem 1.5rem #12263f14 !important;
  border: 1px solid #e8e8e8cf !important;
  border-radius: 8px !important;
  overflow-y: auto;
  background: #ffff !important;
  margin: 10px 0px 1px;
`;
export const WrapperListPost = styled.div`
  & .col-my-accounts {
    padding: 6px;
  }
  & .menu-list {
    height: 543px;
    overflow-y: scroll;
  }
  & .box-accounts {
    height: 640px;
    overflow-y: unset !important;
  }
  & .list-post {
    padding: 10px 0px;
  }
  & .title {
    padding: 4px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    .view-title-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2px;
      h3 {
        margin-bottom: 0px !important;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  & .section-title-account {
    display: flex;
    justify-content: flex-start;
    gap: 0px;
    align-items: center;
    text-align: center;
    padding: 4px;
    cursor: pointer;
    font-weight: 500;
    text-transform: capitalize;
    .item-name {
      font-size: 12px !important;
    }
  }
  & .ant-divider-inner-text {
    color: #f5222d;
    padding: 0 8px !important;
    font-size: 16px !importan;
  }

  & .account-social {
    :hover {
      background: #9f9d9236;
      border-radius: 4px;
    }
  }
  & .account-social.active {
    background: #9f9d9236;
    border-radius: 4px;
    .item-name {
      color: var(--primary-button) !important;
    }
  }
  .list-card-post {
    padding: 7px 4px !important;
  }
  .sub-avatar {
    position: relative;
    right: 12px;
    top: 10px;
  }
  .name-socials {
    .ant-divider {
      margin: 2px 0 !important;
    }
  }
  .account-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .name-social-account {
    width: 116px;
    display: flex;
    justify-content: flex-start;
    .item-name {
      display: inline-block;
      max-width: 116px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .content-avatar-social {
    width: 58px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    .box-accounts {
      height: auto;
    }
  }
`;
export const WrapperSkeletonWiget = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px;
  margin-bottom: 4px;
  .ant-skeleton-content {
    width: 100%;
    height: 100px !important;
    margin-top: 2px;
    background: #f2f2f2;
    border-radius: 8px;
    background: #d7d0d026;
    padding: 0px 10px;
    .ant-skeleton-title {
      margin-bottom: 0px !important;
    }
  }
  .container-avatar {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
  }
  .ant-avatar {
    width: 42px !important;
    height: 42px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }

  & .skeleton-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d7d0d026;
    margin: 0px;
    border-radius: 8px;
    animation: skeleton-loading 1s infinite ease-in-out;
    border: 1px solid #ccc;
  }
  & .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    margin: 0px;
    border-radius: 2px;
    margin: 4px;
    animation: number 1s infinite ease-in-out;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    margin: 0px;
    border-radius: 2px;
    margin: 4px;
    animation: title 1s infinite ease-in-out;
  }

  @keyframes ant-avatar {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    40% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes title {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes number {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
`;
export const WrapperListWidgets = styled.div`
  & .ant-card {
    // min-width: 148px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #cccc;
  }
  & .ant-list-split .ant-list-item {
    border-bottom: 0px solid #e8e8e8 !important;
  }
  & .ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 8px !important;
  }
  & .ant-list-items {
    display: flex !important;
    justify-content: space-between !important;
  }

  & .container-title-widgets {
    line-height: 1 !important;
  }

  & .container-title-widgets p,
  .container-title-widgets h4 {
    margin-bottom: 0px !important;
    text-align: center;
  }
  & .container-title-widgets p {
    font-size: 12px !important;
  }

  & .item-list {
    min-height: 0px !important;
    margin-bottom: 4px !important;
  }
  & .ant-card-body {
    padding: 10px;
    zoom: 1;
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .ant-card-meta {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .container-title-widgets h4 {
    font-family: "NoirPro", sans-serif !important;
    font-weight: 500 !important;
    font-size: 28px !important;
  }
  & .ant-card-meta-avatar {
    padding-right: 8px !important;
  }
  & .ant-avatar > img {
    padding: 8px !important;
  }
  &.white-color .avatar-container {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  & .ant-card.container-cards {
    transition-property: all;
    transition-duration: 0.07s;
    cursor: pointer;
    opacity: 1;
  }

  & .ant-card.container-cards:hover {
    // transform: scale(1.06);
    // opacity: 0.75;
    background: ${({ social }) =>
      social && social.background ? social.background : "rgb(72, 155, 240)"};
    .ant-avatar > img {
      padding: 4px !important;
    }
    .avatar-container {
      .ant-avatar {
        background: transparent !important;
      }
    }
    .container-title-widgets {
      h4 {
        color: #fff !important;
      }
      p {
        color: #fff !important;
      }
    }
  }
`;
export const Wrapper = styled.div``;
export const WrapperRecentSocial = styled.div`
  min-height: 570px !important;
  & .ant-list-split .ant-list-item {
    border-bottom: 0px solid #e8e8e8 !important;
  }
  & .ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 0px !important;
  }
  & .container-recent-title h2 {
    font-size: 16px !important;
    color: #545454 !important;
    margin-bottom: 0px !important;
  }
  & .recent-name h2 {
    font-size: 14px !important;
    color: #262626 !important;
    margin-bottom: 0px;
  }
  & .recent-description {
    line-height: 16px !important;
    font-size: 14px !important;
    color: #595959 !important;
    margin-bottom: 0px !important;
  }
  & .recent-description p,
  .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 0px !important;
  }
  & .container-subtitle {
    padding: 10px 0px;
  }
`;
export const WrapperListAccounts = styled.div``;
export const WrapperListTask = styled.div`
  & .ant-list-items {
    max-height: 606px !important;
    overflow-y: auto !important;
  }
  & .ant-list-item {
    padding: 4px 0 !important;
    align-items: baseline !important;
  }
  & .ant-list-split .ant-list-item {
    border-bottom: 1px solid #e8e8e8 !important;
  }
  .empty-list {
    padding: 68px;
    margin: 16px;
    .ant-empty-image {
      height: 126px !important;
    }
  }
  .menu-filters {
    display: flex;
    justify-content: end;
    padding: 2px 6px 14px;
    align-items: center;
    gap: 4px;
    .ant-calendar-picker{
        width: 150px!important;
    }
    .ant-select-lg.ant-select.ant-select-enabled.ant-select-allow-clear {
      min-width: 140px !important;
    }
    .ant-select-selection {
      // border: 1px solid var(--primary-button) !important;
      border-top-width: 1.02px;
      border-radius: 18px;
    }
    .ant-select-selection--single {
      height: 30px !important;
    }
    .ant-select-selection__rendered {
      line-height: 30px !important;
    }
    .ant-calendar-picker-input.ant-input {
      border-radius: 18px !important;
    }
  }
  .ant-select-clear-icon {
    .ant-btn.ant-btn-default.ant-btn-circle.ant-btn-sm.ant-btn-icon-only {
      left: -3px !important;
      top: -2px !important;
      .anticon {
        line-height: 0 !important;
        font-size: 10px !important;
        color: #fff !important;
        
      }
        :hover{
         background: #9e9e9e!important
        }
    }
    .ant-btn-circle.ant-btn-sm {
      min-width: 18px !important;
      border-radius: 50% !important;
      border-color: #b8b4b4 !important;
    }
    .ant-btn-icon-only.ant-btn-sm {
      width: 18px !important;
      height: 17px !important;
      padding: 0;
      font-size: 14px;
      border-radius: 4px;
      background: #b8b4b4 !important
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const WrapperTabs = styled.div`
  padding: 8px 2px;
  .item-tabs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    padding: 6px;
    cursor: pointer;
    margin: 4px;
    :hover {
      background: #ebe8e8;
      border-radius: 2px;
    }
  }
  .all,
  .schedule,
  .boards {
    background: #ebe8e8;
    border-radius: 2px;
  }

  .ant-tabs .ant-tabs-left-bar {
    float: left;
    margin-right: -1px;
    margin-bottom: 0;
    border: 1px solid #e8e8e8;
    background: #fff;
    // height: 100vh;
    border-radius: 8px 0px 0px 8px;
    width: 180px;
    padding: 10px;
  }
  & .ant-tabs .ant-tabs-left-content {
    padding-left: 0px !important;
    border-left: 0px !important;
    .ant-tabs-tabpane {
      /*  background: #fff;
      margin-left: 6px;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #e8e8e8; */
      padding: 0px 0px 0px 8px;
      height: 100vh;
    }
  }
  & .ant-tabs-tabpane-inactive {
    // margin: -1px 4px!important;
    display: none !important;
  }
  & .create-post-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 72px;
    .ant-btn {
      height: 44px !important;
      border-radius: 28px !important;
    }
  }
  .section-img-cover {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 122px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1920px) {
    .box-menu {
      height: 644px;
    }
    .all-post-box {
      height: 642px;
    }
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    .box-menu {
      height: 714px;
    }
    .all-post-box {
      height: 642px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .all-post-box {
      height: 568px;
    }
    .row-menu {
      justify-content: center;
    }
  }
`;
export const WrapperSocialList = styled.div`
  & .ant-checkbox-wrapper {
    margin-right: 11px;
  }
  & .container-description-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
  }
  & .container-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .avatar-container {
    flex: ${({ type }) => (type == "programed" ? ".55" : ".7")};
  }
  & .container-details {
    flex: ${({ type }) => (type == "programed" ? ".45" : ".3")};
  }
  & .container-colors {
    margin-right: 10px;
    display: flex;
  }
  /* section-colores */
  & .container-color-green {
    contain: "";
    background: #52c41a;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin-right: 4px;
  }
  & .container-color-red {
    contain: "";
    background: red;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin-right: 4px;
  }
  & span.ant-tag {
    background: #d9d9d9a6;
  }
  & .container-avatar-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .ant-avatar-image {
    margin-right: 0px !important;
  }
  & .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    section-name-account {
      min-width: 160px;
    }
    .section-name-account-programed {
      span {
        display: inline-block;
        max-width: 116px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .container-date {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      cursor: pointer;
      span {
        font-size: 10px !important;
      }
    }
  }
  & .section-tag-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  & .ant-list-item-action {
    margin-left: 12px !important;
    .ant-btn {
      padding: 4px !important;
    }
  }
  & .ant-tag {
    height: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .section-tag-date {
    .published {
      .ant-tag {
        background-color: var(--bg-color-active) !important;
        border-color: var(--bg-color-active) !important;
        color: #fff;
      }
    }
    .programed {
      .ant-tag {
        background-color: #d87d35 !important;
        border-color: #d87d35 !important;
        color: #fff;
      }
    }
    .pending {
      .ant-tag {
        background-color: var(--bg-color-inactive) !important;
        border-color: var(--bg-color-inactive) !important;
        color: #fff;
      }
    }
  }
`;
