import React, { useEffect, useState } from "react";
import { CardWrapper } from "./styles";
import { Button, Icon } from "antd";
import { useSelector } from "react-redux";

export const PlanCard = ({
  total,
  index,
  onChange,
  showButton,
  current_plan,
  ...props
}) => {
  const user = useSelector((data) => data.user);
  const [record, setRecord] = useState();
  const [options, setOptions] = useState();
  const [current, setCurrent] = useState();
  const handleOnClick = () => {
    if (onChange) onChange(record);
  };
  useEffect(() => {
    if (typeof props.record != "undefined") setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    // console.log(":::::currents:::", props.current_plan);

    if (typeof props.current != "undefined") setCurrent(props.current);
  }, [props.current]);
  useEffect(() => {
    try {
      if (record) {
        let options = JSON.parse(record.options);
        setOptions(options);
      }
    } catch (error) {
      console.log("Options invalid");
    }
  }, [record]);
  if (!record) return null;
  return (
    <CardWrapper
      className={`${
        current && current._id === record._id
          ? "current-plan"
          : "" || current_plan
          ? "current-plan"
          : ""
      }`}
      index={index}
      total={total}
    >
      <div className="plan-caption">
        <div className="section-plan">
          <div className="plan">
            <h4>
              {record && record.frequency_type} Plan / {record && record.name}
            </h4>
          </div>
          <div className="plan-caption-name">
            <div className="plan-price">
              <div className="price-amount">
                {record && record.price === 0
                  ? "Free"
                  : `${"$"}${record.price}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="plan-description">
        <div
          className="plan-description-content"
          dangerouslySetInnerHTML={{
            __html:
              (record && record.descriptions) || (record && record.description),
          }}
        />
      </div>
      <div className="plan-action">
        <div>
          {showButton && (
            <Button
              block
              onClick={handleOnClick}
              type={
                current && current._id === record._id
                  ? "primary"
                  : "" || current_plan
                  ? "primary"
                  : "ghost"
              }
              size="large"
            >
              Upgrade
            </Button>
          )}
        </div>
      </div>
    </CardWrapper>
  );
};
