import React, { useState, useEffect, useRef } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { ADD_TASK } from "../../constants/actionTypes";
import uuid from "react-uuid";
import _ from "lodash";
import Task from "./Task";
import {
  Title,
  TaskList,
  WrapperColumn,
  FooterTask,
  AddTaskForm,
  Tools,
} from "./Styles";
import { Button, Input, Menu, Dropdown, Icon, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saveRef } from "rc-menu/lib/SubPopupMenu";
import { getService } from "../../services";
import slugify from "react-slugify";

const grid = 8;

const TaskAdd = ({ background, tasks, droppableId, onSubmit, ...props }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState("default");
  const [value, setValue] = useState();
  const [changed, setChanged] = useState(false);
  const [editing, setEditing] = useState(true);
  const [payloads, setPayloads] = useState({
    column_id: droppableId,
  });
  let myRef = useRef(null);
  const handleTitle = (e) => {
    let { value } = e.target;
    setValue(value);
    setChanged(true);
    setPayloads({
      ...payloads,
      ["subject"]: value,
    });
  };
  const handleMenuClick = (key) => {
    switch (key) {
      case "edit":
        setEditing(true);
        if (myRef.current) myRef.current.focus();
        break;
      case "delete":
        setEditing(true);
        setValue();
        setView("default");
        break;
      default:
        break;
    }
  };
  const MenuItems = (props) => (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="edit">
        <span>
          <Icon type={"edit"} />
          <span>{"Edit Task Name"}</span>
        </span>
      </Menu.Item>
      {/* <Menu.Item key="show">
        <span>
          <Icon type="eye" />
          <span> Show Task Detail</span>
        </span>
      </Menu.Item> */}
      <Menu.Item className="text-red" key="delete">
        <span>
          <Icon type="delete" />
          <span>Remove Task</span>
        </span>
      </Menu.Item>
    </Menu>
  );
  const handleOnSubmit = () => {
    if (onSubmit && changed) {
      dispatch({
        type: ADD_TASK,
        task: payloads,
      });
      onSubmit(payloads);
      setChanged(false);
    }
  };
  useEffect(() => {
    if (myRef.current) console.log("Input: ", myRef.current);
  }, [myRef.current]);
  useEffect(() => {
    if (props.view) setView(props.view);
  }, [props.view]);
  useEffect(() => {
    return () => {
      setChanged(false);
      setEditing(true);
    };
  }, []);
  return (
    <FooterTask background={background}>
      {view === "default" && (
        <div>
          <Button
            onClick={() => setView("task-add")}
            block
            type="link"
            icon="plus"
          >
            Add Task
          </Button>
          {tasks.length > 0 && (
            <div>
              <span style={{ fontSize: "12px" }}>
                Total Tasks {tasks.length}
              </span>
            </div>
          )}
        </div>
      )}
      {view === "task-add" && (
        <AddTaskForm
          className={`flex justify-start item-centered gap-sm ${value && !editing ? "item-task" : ""
            }`}
        >
          {editing && (
            <Input
              ref={(el) => {
                if (el) el.focus();
                myRef = el;
              }}
              onBlur={() => {
                if (value) setEditing(false);
                /* if (changed) return handleOnSubmit(); */
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") handleOnSubmit();
              }}
              onPressEnter={() => {
                handleOnSubmit();
              }}
              onChange={handleTitle}
              defaultValue={value}
              size="small"
              placeholder="Enter Task Title"
              autoSize={{ minRows: 4, maxRows: 5 }}
            />
          )}
          {!editing && (
            <div
              onClick={() => setEditing(true)}
              className="task-subject w-full"
            >
              <span>{value}</span>
            </div>
          )}
          <Tools className="mb-0">
            <Dropdown
              trigger={["click"]}
              overlay={MenuItems}
              overlayClassName="menu-actions"
            >
              <Button className="btn-menu" type="default" icon="ellipsis" />
            </Dropdown>
          </Tools>
          {/* <Tools>
            <Button
              type="primary"
              onClick={() => {
                setView("default");
                setRecord({
                  ...record,
                  ["subject"]: null,
                });
                dispatch({
                  type: ADD_TASK,
                  task: record,
                });
                if (onSubmit) onSubmit(record);
              }}
            >
              Add new task
            </Button>
            <Button
              type="link"
              icon="close"
              onClick={() => setView("default")}
            />
          </Tools> */}
        </AddTaskForm>
      )}
    </FooterTask>
  );
};
class InnerTaskList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.tasks === this.props.tasks) {
      return false;
    }
    return true;
  }
  render() {
    return (
      <>
        {this.props.tasks.map((task, index) => {
          if (!task) return;
          return (
            <Task
              {...task}
              task={task}
              key={task.id}
              column_id={this.props.id}
              onMenuClick={this.props.onMenuClick}
              board_id={this.props.board_id}
              index={index}
            />
          );
        })}
      </>
    );
  }
}
const Column = ({
  renderItem,
  onSubmit,
  board_id,
  onMenuClick,
  column,
  columns,
  column_order,
  type,
  ...props
}) => {
  const theme = useSelector(({ theme }) => theme || {});
  const [tasks, setTasks] = useState(props.tasks || []);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [changed, setChanged] = useState(false);
  const [value, setValue] = useState(column.title);
  const [view, setView] = useState();
  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "edit":
        setEditing((editing) => !editing);
        break;
      default:
        if (onMenuClick) onMenuClick(key, column.id);
        break;
    }
  };
  const handleTaskMenuClick = ({ key }) => { };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">
        <Icon type={!editing ? "edit" : "close"} className="icon-global" />
        {!editing ? "Rename Stage" : "Cancel"}
      </Menu.Item>
      <Menu.Item key="add">
        <Icon type="plus" className="icon-global" />
        Add Stage
      </Menu.Item>
      <Menu.Item key="archived">
        <Icon type="delete" className="btn-removed-red" />
        Remove Section
      </Menu.Item>
    </Menu>
  );
  const save = () => {
    const service = getService("boards");
    if (value && !submitting && changed) {
      let slug = slugify(column.title);
      slug = slug.toLowerCase();
      let new_column_order = Array.from(column_order);
      let new_slug = slugify(value);
      new_slug = new_slug.toLowerCase();
      new_column_order.splice(
        new_column_order.findIndex((col) => col == slug),
        1,
        new_slug
      );
      columns[column.id] = {
        ...columns[column.id],
        title: value,
        id: new_slug,
      };
      setEditing(false);
      setSubmitting(true);
      service
        .patch(board_id, {
          columns: Object.values(columns),
          column_id: column.id,
          column_slug: new_slug,
          column_order: new_column_order,
        })
        .then((resp) => {
          message.success("Column Updated!");
          setSubmitting(false);
          setChanged(false);
          if (onSubmit) onSubmit();
        })
        .catch((err) => {
          message.error(err.message);
          setChanged(false);
          setSubmitting(false);
        });
    }
  };
  const handleTitle = (e) => {
    setChanged(true);
    setValue(e.target.value);
  };
  useEffect(() => {
    setTasks(props.tasks);
  }, [props.tasks]);
  useEffect(() => {
    return () => {
      setEditing(false);
      setChanged(false);
    };
  }, []);
  return (
    <Draggable
      isDragDisabled={submitting}
      draggableId={column.id}
      index={props.index}
    >
      {(provided) => (
        <WrapperColumn
          background={theme.background}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Tools
            style={{
              height: 48,
            }}
            className="column-actions flex item-centered justify-spacebetween gap-sm"
          >
            <Title
              background={theme.background}
              className="w-full"
              onDoubleClick={() => {
                if (!editing) setEditing(true);
              }}
              onClick={() => {
                if (!editing) setEditing(true);
              }}
              isDragDisabled={props.isDragDisabled}
              {...provided.dragHandleProps}
            >
              {!editing ? (
                <div className="title-container">
                  <h3>{value || column.title}</h3>
                </div>
              ) : (
                <Input
                  ref={(input) => {
                    if (input) input.focus();
                  }}
                  onBlur={() => {
                    setEditing(false);
                    if (changed) return save();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") save();
                  }}
                  onPressEnter={() => {
                    save();
                  }}
                  onChange={handleTitle}
                  defaultValue={value || column.title}
                  size="large"
                  placeholder="Title"
                />
              )}
            </Title>
            <Tools className="flex item-centered justify-start gap-0 mb-0">
              {
                <Button
                  className="flex item-centered justify-center gap-0 m-0"
                  type="link"
                  icon="plus"
                  loading={submitting}
                  onClick={() => setView("task-add")}
                />
              }
              {
                <Dropdown
                  disabled={submitting}
                  overlayClassName="menu-actions"
                  trigger={["click"]}
                  overlay={menu}
                >
                  <Button
                    className="flex item-centered justify-center gap-0 m-0"
                    icon="ellipsis"
                  />
                </Dropdown>
              }
            </Tools>
          </Tools>
          <Droppable
            droppableId={column.id}
            //type="task"
            style={{
              position: "relative",
            }}
            type={props.type || "task"}
            /* type={column.id === "column-4" ? "done" : "active"} */
            /* isDropDisabled={props.isDropDisabled}*/
            isDropDisabled={props.isDropDisabled}
            direction={props.direction || "vertical"}
          >
            {(provided, snapshot) => (
              <div
                className={`section-columns-${tasks.length > 4 ? "maxtask" : "mintask"
                  }`}
              >
                <TaskList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  <InnerTaskList
                    onSubmit={onSubmit}
                    onMenuClick={handleTaskMenuClick}
                    board_id={board_id}
                    id={column.id}
                    tasks={tasks}
                  />
                  {provided.placeholder}
                </TaskList>
              </div>
            )}
          </Droppable>
          {!provided.draggableProps.isDragging && (
            <>
              <TaskAdd
                background={theme.background}
                view={view}
                onSubmit={onSubmit}
                droppableId={column.id}
                tasks={tasks}
              />
            </>
          )}
        </WrapperColumn>
      )}
    </Draggable>
  );
};
export default Column;
