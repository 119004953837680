import React, { useState, useEffect, useMemo } from "react";
import { Modal, List, Card, Icon, Avatar, Button, message } from "antd";
import { Wrapper } from "./Styles";
import { getService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { MyModal } from "../../components/com";
import _ from "lodash";
import { navigate } from "@reach/router";
import Plugin from "../../plugins/Plugin";
import * as actionTypes from "../../store/actions";

const { Meta } = Card;
const data = [
  {
    id: 1,
    type: "deployment-unit",
    title: "CRM integration",
    description: "See the technologies of your leads",
  },
  {
    id: 2,
    type: "mail",
    title: "Email verification",
    description: "Improve delivery and mailing list quality.",
  },
  {
    id: 3,
    type: "code",
    title: "API access",
    description: "Instant and real-time technology lookups.",
  },
  {
    id: 4,
    type: "safety-certificate",
    title: "Security recon",
    description: "Reveal web technologies and version numbers.",
  },
  {
    id: 5,
    type: "rise",
    title: "Custom reports",
    description: "Create lists of websites and contacts",
  },
  {
    id: 6,
    type: "bell",
    title: "Website monitoring",
    description: "Monitor website technology changes.",
  },
];
const Plugins = ({ reference, ...props }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const [selected, setSelected] = useState(true);
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const [plugins_ids, setPluginsIds] = useState([]);
  const [plugin, setPlugin] = useState({});
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [submitting, setSubmitting] = useState({});
  const [visible, setVisible] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const handleOnClick = async (plugin_id, item) => {
    let { plugin } = item;
    setPlugin(plugin);
    if (!plugins_ids.includes(plugin_id) && plugin && plugin.name) {
      setVisible(true);
    } else {
      try {
        const service = getService("connect-company-plugins");
        setSubmitting({
          [plugin_id]: true,
        });
        let { plugins_ids, company, ...rest } = await service.create({
          plugin_id,
        });
        let new_user = { ...user, company, ...rest.user, plugins_ids };
        console.log("---->>>>>>>> plugins_ids: ", plugins_ids);
        setPluginsIds(plugins_ids);
        updateCurrentUser(new_user);
        setSubmitting({
          [plugin_id]: false,
        });
      } catch (err) {
        message.error(err.message);
        setSubmitting({
          [plugin_id]: false,
        });
      }
    }
  };
  const getPlugins = () => {
    if (reference) {
      setLoading(true);
      const service = getService(reference);
      service
        .find({
          query: {
            ...filterDefaultValues,
          },
        })
        .then(({ data }) => {
          setDataSource(data);
          setLoading(false);
          setInitialized(true);
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    }
  };
  const updateCurrentUser = (user) => {
    window.localStorage.setItem("user", JSON.stringify(user));
    dispatch({
      type: actionTypes.AUTHENTICATION,
      user,
      accessToken: localStorage.getItem("feathers-jwt"),
    });
  };
  const renderItems = (item) => {
    let ifExists = plugins_ids.includes(item.plugin_id);
    return (
      <List.Item key={item._id}>
        <Card
          title={
            <div>
              <div className="section-icon">
                <Avatar
                  icon={
                    item.plugin &&
                    item.plugin.type &&
                    item.plugin.type.toLowerCase()
                  }
                />
              </div>
              <div className="sections-title">
                <strong>{item && item.plugin && item.plugin.title}</strong>
              </div>
            </div>
          }
        >
          <Meta
            description={
              <div>
                <div className="section-description">
                  <span>{item && item.plugin && item.plugin.description}</span>
                </div>
              </div>
            }
          />
          {user &&
            (user.role === "Broker" ||
              user.role === "Admin" ||
              user.role === "Agent") && (
              <div className="section-buttons">
                <Button
                  disabled={user.role === "Agent" && !user.plan_id}
                  className="section-button"
                  onClick={() => handleOnClick(item.plugin_id, item)}
                  loading={submitting[item.plugin_id]}
                  type={ifExists ? "danger" : "primary"}
                  icon={ifExists ? "disconnect" : "link"}
                >
                  {ifExists ? "Disconnect" : "Connect"}
                </Button>
              </div>
            )}
        </Card>
      </List.Item>
    );
  };

  useEffect(() => {
    if (filterDefaultValues && Object.keys(filterDefaultValues).length > 0)
      getPlugins();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (user && user.company && !initialized) {
      setCompany(user.company);

      let plugins_ids =
        typeof user.plan_id != "undefined"
          ? user.plugins_ids || []
          : (user.company && user.company.plugins_ids) || [];
      console.log(
        ">>>>>>>>>>>>>>>>>>>>>>>>>> company.plugins_ids: ",
        user.company.plugins_ids,
        user.plugins_ids,
        plugins_ids
      );
      setPluginsIds(plugins_ids);
    }
  }, [user]);
  return (
    <Wrapper>
      <div>
        <List
          grid={{
            gutter: 8,
            xxl: 6,
            xl: 4,
            lg: 4,
            sm: 2,
            md: 2,
            xs: 1,
          }}
          loading={loading}
          dataSource={dataSource}
          renderItem={renderItems}
        />
      </div>
      <MyModal
        width={500}
        className="modal-plugins"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {plugin.name && (
          <Plugin
            onSubmit={({ plugins_ids, company, ...rest }) => {
              let new_user = { ...user, company, ...rest.user, plugins_ids };
              updateCurrentUser(new_user);
              setPluginsIds(plugins_ids);
              setVisible(false);
              //getPlugins();
            }}
            plugin_id={plugin && plugin._id}
            xtype={plugin.name.toLowerCase()}
          />
        )}
      </MyModal>
    </Wrapper>
  );
};
export default Plugins;
