import React, { useEffect, useRef } from "react";
import { Layout, Row, Col, Affix } from "antd";
import { styled } from "@material-ui/core";
import { NavBar } from "../components/header";
import MyFooter from "../components/Footer";

const { Content } = Layout;
const Container = styled(Content)`
  min-height: 200px !important;
`;

const HomeLayout = ({ children, ref }) => {
  const MyRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      console.log(MyRef.current);
    }, 2000);
    return () => {};
  }, []);

  return (
    <Layout style={{ minHeight: "100vh", background: "red" }}>
      <Layout>
        <Affix offsetTop={0.01}>
          <div>
            <NavBar />
          </div>
        </Affix>
        <Container
          style={{
            minHeight: 200
          }}
          ref={MyRef}
        >
          {children}
        </Container>
        <MyFooter />
      </Layout>
    </Layout>
  );
};

export default HomeLayout;
