import React, { useState, useEffect } from "react";
import { Button, Divider, Icon, Input, message, Select } from "antd";
import { getService } from "../../../services";
import _ from "lodash";
import { useRef } from "react";
const { Option } = Select;
const defaultActions = {
  create: false,
};
const SelectField = ({
  optionValue = "_id",
  notFoundContent = "Not Found",
  optionText = "name",
  searchKey = "search",
  defaultValue,
  defaultValues = {},
  onBlur,
  actions = defaultActions,
  onSelect,
  minWidth = 150,
  resource,
  reference,
  source,
  record,
  form,
  placeholder,
  name,
  onChange,
  onSearch,
  mode,
  autoLoad = true,
  ...props
}) => {
  const [initialized, setInitialized] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [initialValue, setInitialValue] = useState();
  const [choices, setChoices] = useState(props.choices || []);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  let myRef = useRef(null);
  const getInitialValue = (data) => {
    let dataSource = data || choices;
    let item;
    if (choices) {
      /* if (autoSelect)
                item = dataSource.find((item, index) => (index === 0)); */
      if (record && record[source || name])
        item = dataSource.find((item, index) => {
          return (
            source && record && item[optionValue] === record[source || name]
          );
        });
      if (item) {
        setValue(item[optionValue]);
        setInitialValue(item[optionValue]);
      }
    }
  };
  const getData = async () => {
    if (resource || reference) {
      const service = getService(resource || reference);
      let token = localStorage.getItem("feathers-jwt");
      /* if (!token) return; */
      return service
        .find({
          query: {
            $limit: 500000,
            ...filterDefaultValues,
          },
        })
        .then((res) => {
          let data = Array.isArray(res) ? res : res.data;
          setChoices(data);
          getInitialValue(data);
          setLoading(false);
          if (props.onLoad) props.onLoad(data);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };
  const addItem = () => {
    if ((resource || reference) && value) {
      const service = getService(reference || resource);
      setSubmitting(true);
      service
        .create({
          [source || name]: value,
          ...defaultValues,
        })
        .then(async (res) => {
          message.success("Registro agregado con éxito!");
          if (value) setInitialValue(value);
          setOpen(false);
          setSubmitting(false);
          await getData();
          if (onSelect) {
            onSelect(res[optionValue], res);
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  useEffect(() => {
    if (record && record[source || name])
      setSelectedItems(record[source || name]);
  }, [record]);
  useEffect(() => {
    if (typeof props.refresh != "undefined" && props.refresh !== refresh) {
      setRefresh(props.refresh);
    }
  }, [props.refresh]);
  /* useEffect(() => {
    getData();
  }, [filterDefaultValues]); */
  useEffect(() => {
    if (typeof refresh !== "undefined" && filterDefaultValues) getData();
  }, [refresh, filterDefaultValues]);
  useEffect(() => {
    if (typeof autoLoad != "undefined" && autoLoad && !filterDefaultValues)
      getData();
  }, [autoLoad]);
  useEffect(() => {
    if (defaultValue) {
      setInitialValue(defaultValue || 1);
    }
    /* return () => {
            setInitialValue(null);
        } */
  }, [defaultValue]);
  useEffect(() => {
    if (typeof props.choices != "undefined") setChoices(props.choices);
  }, [props.choices]);
  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);
  const onNameChange = (e) => {
    let { value } = e.target;
    setValue(value);
  };
  const handleOnChange = (value) => {
    if (mode === "multiple") setSelectedItems(value);
    setValue(value);
    if (onChange) onChange(value);
    console.log(">>>>>> Value: ", value);
  };
  const handleOnSelect = (value, eOpts) => {
    const input = myRef.current;
    if (input) input.blur();
    if (value && props.mode !== "multiple") setValue(value);
    if (choices) {
      let item = choices.find((item) => item && item[optionValue] === value);
      setOpen(false);
      if (onSelect && item) onSelect(value, item, form);
    }
  };
  const handleOnClear = () => {
    setInitialValue(null);
    if (props.onClear) props.onClear();
  };
  const search = (value) => {
    if (value) {
      setFilterDefaultValues({
        ...filterDefaultValues,
        [searchKey]: value,
      });
    }
    if (onSearch) onSearch(value);
  };

  const handleOnSearch = _.debounce(search, 400, { maxWait: 800 });
  const handleSelect = _.debounce(handleOnSelect, 100);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  if (submitting) return <span>Loading...</span>;
  return (
    <Select
      {...props}
      mode={mode}
      notFoundContent={notFoundContent}
      ref={myRef}
      value={mode === "multiple" ? selectedItems : choices && value}
      style={{ minWidth, ...props.style }}
      initial={initialValue}
      defaultValue={defaultValue}
      loading={loading}
      showSearch
      size={props.size || "large"}
      placeholder={placeholder || "Select Option"}
      optionFilterProp="children"
      onChange={handleOnChange}
      onSelect={handleSelect}
      onSearch={handleOnSearch}
      allowClear={props.allowClear || true}
      filterOption={(input, option) => {
        let optionText = option.props.children.toLowerCase();
        return optionText.indexOf(input.toLowerCase()) >= 0;
      }}
      onBlur={() => {
        setOpen(false);
      }}
      onFocus={() => {
        setOpen(true);
      }}
      open={open}
      clearIcon={
        <span>
          {
            <Button
              onClick={handleOnClear}
              style={{
                margin: 0,
                padding: 0,
                position: "absolute",
                left: -10,
                top: -4,
              }}
              size="small"
              shape="circle"
              icon="close"
              type="default"
            />
          }
        </span>
      }
      name={name || source}
      dropdownRender={
        actions.create
          ? (menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                >
                  <Input
                    style={{ flex: "auto" }}
                    value={value}
                    onBlur={onBlur}
                    placeholder={props.addPlaceholder || placeholder}
                    onChange={onNameChange}
                  />
                  <a
                    style={{
                      flex: "none",
                      padding: "8px",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={addItem}
                  >
                    <Icon type="plus" /> Agregar
                  </a>
                </div>
              </div>
            )
          : undefined
      }
    >
      {choices &&
        choices.map((it, index) => {
          if (!it) return null;
          return (
            <Option key={index} value={it && it[optionValue || "_id"]}>
              {typeof optionText == "function"
                ? optionText(it[optionValue || "_id"], it)
                : it[optionText || "name"]}
            </Option>
          );
        })}
    </Select>
  );
};

export default SelectField;
