import React, { useState, useEffect } from "react";
import { Comment, Avatar, Button } from "antd";
import { MarkDown } from "..";
import { SimpleForm } from "../com/form/";
import { WrapperCommentBox } from "./Styles";
const CommentBox = ({
  id,
  momentitem,
  onSubmit,
  onMentions,
  onRemove,
  ...props
}) => {
  const [value, setValue] = useState(props.value);
  const [raw_value, setRawValue] = useState(props.raw_value);
  const [record, setRecord] = useState();
  const handleSubmit = (field, value) => {
    if (onSubmit) onSubmit(field, value, record && record._id);
    //setValue(null);
  };
  const handleOnMentions = (mentions) => {
    if (onMentions) onMentions(mentions);
    //setValue(null);
  };
  useEffect(() => {
    if (typeof props.record != "undefined") setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (typeof props.raw_value != "undefined") setRawValue(props.raw_value);
  }, [props.raw_value]);
  return (
    <WrapperCommentBox>
      <MarkDown
        id={id}
        momentitem={momentitem}
        autoReset={props.autoReset}
        box={props.box}
        actions={props.actions}
        onSubmit={handleSubmit}
        onMentions={handleOnMentions}
        onRemove={onRemove}
        name="message"
        title={props.title}
        icon={props.icon}
        placeholder="Add Comment"
        flex={1}
        initialValue={value}
        {...props}
        raw_value={raw_value}
        record={record}
        /* initial={"# Heading\n\nSome **bold** and _italic_ text\nBy [Jed Watson](https://github.com/JedWatson)"} */
      />
    </WrapperCommentBox>
  );
};
export default CommentBox;
