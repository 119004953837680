import React, { useEffect, useState, useRef } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import { Router, createHistory, navigate, Redirect } from "@reach/router";
import Loadeable from "react-loadable";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "codemirror/lib/codemirror.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { socket } from "./api/";
import * as messages from "./constants/messages";
import NotFound from "./views/NotFound";
/* import { retryLoginAndGetCurrentUser } from "./actions/auth"; */
import qs from "qs";
/* Redux Store */
import { store } from "./store";
import { Provider, useDispatch, useSelector } from "react-redux";
/* Redux */
import { connect } from "react-redux";
import * as actionTypes from "./store/actions";

import Loader from "./components/loader";
/* Fonts */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
/* Import Services */
import {
  reAuthenticate,
  current,
  tasks,
  LogActions,
  getService,
  Socials,
} from "./services/services";
import { message, notification } from "antd";
import Markdown from "./components/markdown/Markdown";
import { Dashboard } from "./components";
import { MyModal } from "./components/com";
import TaskForm from "./components/board/TaskForm";
import { GOONEWORLD_SITE } from "./constants";
import Profile from "./views/users/Profile";
import EternalProfile from "./views/external-profile/EternalProfile";
import SetPassword from "./views/authentication/set-password/SetPassword";
import * as all_languages from "./lang/";
import FormPages from "./views/form-pages/FormPages";
/* import Home from "./views/home/Home"; */
library.add(fab, fas, faCheckSquare, faCoffee);
/* Import Components */
const DashboardLayout = Loadeable({
  loader: () => import("./layouts/dashboard_layout"),
  loading: Loader,
});
/* const LayoutCloserx = Loadeable({
  loader: () => import("./layouts/landigpage_layout"),
  loading: Loader,
}); */
const MainLayout = Loadeable({
  loader: () => import("./layouts/admin_layout"),
  loading: Loader,
});
const Home = Loadeable({
  loader: () => import("./views/home/Home"),
  loading: Loader,
});
const SignIn = Loadeable({
  loader: () => import("./views/authentication/signin/SignIn"),
  loading: Loader,
});
const SignUp = Loadeable({
  loader: () => import("./views/authentication/signup/Signup"),
  loading: Loader,
});
const ResetPassword = Loadeable({
  loader: () => import("./views/authentication/reset-password/ResetPassword"),
  loading: Loader,
});
const ChangePassword = Loadeable({
  loader: () => import("./views/authentication/change-password/ChangePassword"),
  loading: Loader,
});
const Test = Loadeable({
  loader: () => import("./views/test/Test"),
  loading: Loader,
});
const Confirmation = Loadeable({
  loader: () => import("./views/authentication/confirmation/Confirmation"),
  loading: Loader,
});
const SharedDocuments = Loadeable({
  loader: () => import("./views/shared-documents/SharedDocuments"),
  loading: Loader,
});
const Connect = Loadeable({
  loader: () => import("./views/connect/Connect"),
  loading: Loader,
});
const TermsAndConditions = Loadeable({
  loader: () => import("./views/term/TermsAndConditions"),
  loading: Loader,
});
const UserProfile = Loadeable({
  loader: () => import("./views/user-profile/UserProfile"),
  loading: Loader,
});
const PrivacityPolicy = Loadeable({
  loader: () => import("./views/privacity-policy/PrivacityPolicy"),
  loading: Loader,
});
String.prototype.capitalize = function() {
  let str = this.toString();
  let regex = /(\s[\w])+/g;
  let match = str.match(regex);
  if (match) {
    match.forEach((key) => {
      if (key) str = str.replace(key, key.toUpperCase());
    });
  }
  if (str[0]) return str[0].toUpperCase() + str.slice(1, str.length);
};
String.prototype.format = function() {
  let str = this;
  for (let key in arguments) {
    let value = arguments[key];
    if (typeof value == "object") {
      let match = str.match(/\{(\w+\.?\w+)\}/g);
      if (match) {
        match.forEach((k) => {
          k = k.replace(/\{|\}/g, "");
          let val = k.split(".").reduce((prev, current) => {
            return prev[current] ? prev[current] : prev;
          }, value);
          str = str.replace("{" + k + "}", val);
        });
      }
    } else {
      str = str.replace("{" + key + "}", value);
    }
  }
  return str;
};
library.add(fab, fas, faCheckSquare, faCoffee);

const Root = styled.div`
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;

  @font-face {
    font-family: "Poppins";
    src: url("/fonts/poppins/Poppins-Light.tff");
    font-weight: normal;
  }
  @font-face {
    font-family: "Poppins";
    src: url("/fonts/poppins/Poppins-Bold.tff");
    font-weight: bold;
  }

  html,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  * {
    font-family: "Poppins";
  }
`;
let history = createHistory(window);
store.subscribe(function() {});
const App = ({ onCurrentUser, ...props }) => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [default_language] = useState("en");
  const [language, setLanguage] = useState(default_language);
  const [languages, setLanguages] = useState();
  const [task, setTask] = useState();
  const [visible_task, setVisibleTask] = useState();
  const updateLog = (id, params) => {
    const service = getService("log-actions");
    service
      .patch(id, params)
      .then((res) => {})
      .catch((err) => message.error(err.message));
  };
  const openNotification = ({
    title = "Notification",
    message,
    record,
    placement = "topRight",
  }) => {
    let { _id, action_type, reference_id, viewed_by_ids, log } = record;
    notification.info({
      message: `Notification`,
      duration: 10,
      description: <Markdown source={message} />,
      placement,
      onClick: () => {
        updateLog(_id, {
          viewed_by_ids: [...viewed_by_ids, user && user._id],
        });
        notification.destroy();
        switch (action_type) {
          case "update_tasks":
            setTask(log);
            setVisibleTask(true);
            break;
          case "create_tasks":
            setTask(log);
            setVisibleTask(true);
            break;
          case "favorites":
            if (log.property)
              window.open(
                `${GOONEWORLD_SITE}/detail/${log.property._id}`,
                "_blank"
              );
            break;
          case "remove_listing":
            if (log.property)
              window.open(
                `${GOONEWORLD_SITE}/detail/${log.property._id}`,
                "_blank"
              );
            break;
          case "birthdays":
            navigate(`/dashboard/contacts/${reference_id}`);
            break;
          case "reminders":
            let { task } = log;
            task["date"] = moment(task.date).format("YYYY-MM-DD HH:mm:ss");
            setTask(task);
            setVisibleTask(true);
            break;
          default:
            break;
        }
      },
    });
  };
  const connect = ({ strategy = "jwt", accessToken, ...rest }) => {
    return socket.authenticate({
      strategy,
      accessToken,
      ...rest,
    });
  };
  useEffect(() => {
    let { search, pathname } = history.location;
    let params = qs.parse(search.replace("?", ""));
    let { access_token, token, code } = params;
    if (code) return;
    if (access_token || token) {
      if (token && pathname === "/")
        return navigate(`/shared-documents/${token}`);
      localStorage.setItem("feathers-jwt", access_token || token);
    }
    reAuthenticate()
      .then(({ user, accessToken }) => {
        /* Socket Authentication */
        connect({
          accessToken,
        });

        current()
          .then(async (user) => {
            const service = getService("customers");
            if (
              user.customer_id ||
              (user.company && user.company.customer_id)
            ) {
              let customer = await service.get(
                user.customer_id || (user.company && user.company.customer_id)
              );
              user["customer"] = customer;
            }
            onCurrentUser(user);
            let { pathname } = history.location;
            if (pathname === "/") navigate("/dashboard");
          })
          .catch((error) => {
            onCurrentUser(user);
            dispatch({
              type: actionTypes.AUTHENTICATION_FAIL,
              error,
            });
            message.error(error.message);
            /* navigate("/"); */
          });
      })
      .catch((err) => {
        let { pathname } = history.location;
        /* if (err && err.code == 401 && !pathname.includes("signin"))
          navigate("/"); */
      });

    return () => {};
  }, []);
  useEffect(() => {
    LogActions.on("created", (data) => {
      let { log, user_id, action_type } = data;
      const user = JSON.parse(localStorage.getItem("user"));
      if (action_type && messages.actions[action_type]) {
        if (log) log["date"] = moment(log.date).format("YYYY-MM-DD HH:mm:ss");
        dispatch({
          type: action_type.toUpperCase(),
          data,
        });
        if (
          user &&
          user_id !== user._id &&
          user.notifications.includes(action_type)
        ) {
          openNotification({
            title: messages.actions[action_type],
            message: messages.tree[action_type].format(log || data),
            record: data,
          });
        }
      }
    });
  }, []);
  useEffect(() => {
    if (user) {
      let { language, company } = user;
      language = language || (company && company.language) || default_language;
      setLanguage(language);
    }
  }, [user]);
  useEffect(() => {
    let userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage) {
      let [language] = userLanguage.split("-");
      setLanguage(language);
    }
  }, []);
  useEffect(() => {
    if (language)
      setLanguages(all_languages[language] || all_languages[default_language]);
  }, [language]);
  const myRef = useRef(null);
  const ScrollToTop = ({ children, location }) => {
    useEffect(
      () => window.scrollTo(0, myRef.current ? myRef.current.offsetTop : 0),
      [location.pathname]
    );
    return children;
  };

  return (
    <IntlProvider
      messages={languages}
      locale={language}
      defaultLocale={default_language}
    >
      <Provider store={store}>
        <Root>
          <Router primary={false}>
            <ScrollToTop path="/">
              <Connect path="/connection" />
              <Connect path="/connection/:social" />
              <FormPages path="/forms/:slug" />
              <SignIn path="/signin" />
              <SetPassword path="set-password/:token" />
              <TermsAndConditions path="/terms-and-conditions" />
              <PrivacityPolicy path="/privacy-policy" />
              <SignIn path="/confirm-email/:token" />
              <SignUp path="/signup" />
              <UserProfile path="/user-profile" />
              <SharedDocuments path="/shared-documents/:token" />
              <ResetPassword path="/reset-password" />
              <ChangePassword path="/change_password/:token" />
              <Confirmation path="/code-confirmation" />
              <Home path="/" />
              <EternalProfile path="/profile" />
              <EternalProfile path="/profile/:token" />
              <Test path="/test" />
              <DashboardLayout path="/dashboard/*" />
              {/* <Profile path="/profile" /> */}
              {<NotFound default />}
            </ScrollToTop>
          </Router>
          <MyModal
            title="Edit Task"
            onCancel={() => {
              setVisibleTask(false);
              setTask(null);
            }}
            visible={visible_task}
            width={"60%"}
          >
            <TaskForm
              id={task && task._id}
              onSubmit={() => {
                setVisibleTask(false);
              }}
            />
          </MyModal>
        </Root>
      </Provider>
    </IntlProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onCurrentUser: (user) => {
      dispatch({
        type: actionTypes.AUTHENTICATION,
        user,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
