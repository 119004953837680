import React, { useEffect, useState } from "react";
import { WrapperCategory } from "./Styles";
import { AutoComplete, Input, message } from "antd";
import { getService } from "../../services";
import _ from "lodash";
import { SelectField } from "../com/fields/";
const CategoryList = ({
  optionText = "text",
  optionValue = "value",
  label,
  reference,
  ...props
}) => {
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState(false);

  const handleOnSelect = () => {};
  const handleonSearch = (value) => {
    console.log(":::onSearch::::", value);
    // getData();
  };
  const getData = () => {
    const service = getService(reference);
    setLoading(true);
    service
      .find({
        query: {
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };
  const onSearch = _.debounce(handleonSearch, 1000, {
    maxWait: 3000,
  });
  /*  useEffect(() => {
    getData();
  }, []); */
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  return (
    <WrapperCategory>
      {/*  <AutoComplete
        className="container-autocomplete"
        allowClear
        backfill
        dataSource={dataSource}
        style={{ width: "100%" }}
        onSelect={handleOnSelect}
        optionLabelProp={optionText}
        onSearch={onSearch}
        filterOption={(inputValue, option) => {
          let { children } = option.props;
          return (
            children.props &&
            children.props.children &&
            children.props.children.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
      /> */}
      <SelectField
        flex={1}
        label="Categories"
        name="category_ids"
        reference="board-categories"
        // optionValue="name"
        placeholder="Categories"
        // optionText={(value, record) => console.log("categories", value, record)}
        // onSelect={handleOnselect}
        // choices={status}
      />
    </WrapperCategory>
  );
};
export default CategoryList;
