import styled from "styled-components";
import { style } from "dom-helpers";
export const UserProfileWrapper = styled.div`
  & .head-profile {
    /* background: #f4f5f7; */
    padding: 20px 4px;
    margin: 10px 0px;
    .ant-avatar-image {
      background: #fafafa !important;
    }
    .ant-list-item-meta-title {
      color: var(--color-primary) !important;
    }
  }
  & .ant-list-item {
    padding: 0px 10px !important;
  }
  & .ant-list-item-meta {
    padding: 0px 10px !important;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  & .ant-list-items {
    padding: 4px 20px !important;
  }
  & .ant-list-header {
    border-bottom: 0px !important;
    h3 {
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      gap: 4px;
      align-items: center;
    }
  }
`;
export const BoxWrapper = styled.div`
  margin: 4px !important;
  & .ant-card-body {
    padding: 8px !important;
    padding-top: 0px !important;
  }
  & .ant-card-head {
    min-height: auto !important;
    padding: 4px !important;
    border-bottom: 0px !important;
  }
  & .ant-card-head-title {
    padding: 4px !important;
    font-size: 20px;
    font-weight: 500;
  }
  & .ant-card-bordered {
    border: 1px solid #e8e8e8b3 !important;
    border-radius: 8px !important;
    box-shadow: 2px 2px 2px #cccccc59 !important;
  }
`;
export const WrapperForm = styled.div`
  .item-form {
    padding: 2px 4px !important;
    .ant-form-vertical .ant-form-item-label {
      padding: 0px !important;
    }
  }
`;
export const Wrapper = styled.div`
  & .mr-2 {
    display: flex;
    justify-content: center;
  }
  & .section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .profile-container {
    background: #fff !important;
    border-radius: 8px;
    box-shadow: 2px 2px 3px #cccccc42;
    padding: 20px 10px;
  }
  & .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  & .container-upgradeplan {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin: 6px 4px;
  }
  & .container-plan-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const WrapperPlanDetail = styled.div`
  margin: 0px;
  background: #fff;
  height: 341px;
  border-radius: 6px;
  width: 100%;
  box-shadow: 2px 2px 2px #ccc;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .container-card {
    width: 220px;
    padding: 30px;
    border-radius: 12px !important;
    color: rgb(255, 255, 255) !important;
    padding: 8px;
    background: var(--color-primary) !important;
    // height: 264px !important;
  }
  & .plan-name {
    padding: 4px 14px 2px 20px;
    h3 {
      color: #fff !important;
      margin-bottom: 0px !important;
    }
  }
  & .plan-price {
    font-size: 22px;
    font-weight: 500;
  }
  & .container-upgradeplan .ant-btn-primary {
    background: rgb(250, 173, 20) !important;
    color: rgb(255, 255, 255) !important;
    font-size: 14px !importan;
  }
  & .plan-price-container {
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }
  & .container-plan-description {
    font-size: 14px;
  }
`;
