import React, { useState, useEffect } from "react";
import { Button, Tabs } from "antd";
import { WrapperTaskList } from "./Styles";
import TodoTask from "./TodoTask";
import { useSelector } from "react-redux";
import _ from "lodash";
const { TabPane } = Tabs;
const TaskList = ({ onSwich, onChangeType, showAllTasks = true, ...props }) => {
  const user = useSelector(({ user }) => user);
  const [type, setType] = useState();
  const [loading, setLoading] = useState(true);
  const [filterDefaultValues, setDefaultFilterValues] = useState();
  const handleChange = (key) => {
    // setDefaultFilterValues(null);
    setType(key);
  };
  const handleOnLoad = (data) => {
    console.log("tiene data", data.length > 0);
  };
  useEffect(() => {
    if (onSwich) return onSwich(type);
    let params = {};
    switch (type) {
      case "my-tasks":
        if (
          user.role.toLowerCase() === "admin" ||
          user.role.toLowerCase() === "broker"
        ) {
          if (user.company_id) {
            params = {
              company_id: user.company_id,
              $or: [
                {
                  user_id: user._id,
                },
                {
                  members_ids: {
                    $in: [user._id],
                  },
                },
              ],
            };
          }
        } else {
          params = {
            user_id: user._id,
          };
        }
        break;
      case "all-tasks":
        if (user.company_id)
          params = {
            company_id: user.company_id,
            members_ids: {
              $in: [user._id],
            },
          };
        break;
      default:
        break;
    }
    if (!_.isEqual(params, filterDefaultValues)) {
      if (onChangeType) onChangeType(type, params);
      setDefaultFilterValues(params);
    }
  }, [type]);
  useEffect(() => {
    if (!_.isEqual(props.filterDefaultValues, filterDefaultValues))
      setDefaultFilterValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (props.type) setType(props.type);
  }, [props.type]);
  if (!user) return "Loading...";
  return (
    <WrapperTaskList className={props.className || "task-list-default"}>
      <Tabs defaultActiveKey="my-tasks" onChange={handleChange}>
        <TabPane tab="My Tasks" key="my-tasks">
          <TodoTask
            refresh={props.refresh}
            autoLoad={true}
            actions={props.actions}
            scroll={props.scroll}
            filterDefaultValues={filterDefaultValues}
            type={type}
            onLoad={handleOnLoad}
          />
        </TabPane>
        {showAllTasks && (
          <TabPane tab="All Tasks" key="all-tasks">
            <TodoTask
              refresh={props.refresh}
              autoLoad={false}
              actions={props.actions}
              scroll={props.scroll}
              // filterDefaultValues={filterDefaultValues}
            />
          </TabPane>
        )}
      </Tabs>
    </WrapperTaskList>
  );
};
export default TaskList;
