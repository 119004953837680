import React, { Component } from "react";
import { Form, Row, Col, Button, Divider } from "antd";
import "./form.css";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
const footerStyle = {
  marginTop: 20,
};
const formatter = (value) => {
  var cleaned = ("" + value).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
const formatterPhone = (value) => {
  var cleaned = ("" + value).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
const Tools = styled.div`
  display: flex;
  & .ant-btn {
    margin: 0px 4px;
  }
`;
const HeadTitle = styled.div`
  h2 {
    font-size: 24px !important;
    text-align: center !important;
    color: #ccc;
  }
`;
class AdvancedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      record: {},
      formLayout: "vertical",
    };
    let { delay } = this.props;
    this.handleOnChange = _.debounce(this.handleChange, delay || 1000, {
      maxWait: delay || 3000,
    });
  }

  handleSubmit = (e) => {
    const { initialValues } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (typeof this.props.onSubmit !== "undefined") {
        this.props.onSubmit(
          err,
          { ...initialValues, ...values },
          this.props.form,
          e
        );
        //if (!err) this.props.form.resetFields();
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };
  handleChange = (key, value) => {
    if (this.props.onChange) this.props.onChange(key, value, this.props.form);
    let values = this.props.form.getFieldsValue();
    this.props.form.setFieldsValue({
      ...values,
      [key]: value,
    });
  };
  checkdate = (rule, value, callback) => {
    if (value) {
      callback();
      return;
    }
    callback("Date is required!");
  };
  // To generate mock Form.Item
  getFields() {
    const me = this;
    try {
      const { getFieldDecorator } = this.props.form;
      const { children, initialValues } = this.props;
      let childrens = [];
      let { formLayout, record } = this.state;
      formLayout = this.props.formLayout || formLayout;
      //console.log("formLayout:: ",formLayout);
      const formItemLayout =
        formLayout === "vertical"
          ? {
              span: 12,
            }
          : null;
      if (typeof children !== "undefined") {
        try {
          let { props } = children;
          let childNodes = props.children || [];
          childrens = React.Children.map(childNodes, (child, index) => {
            if (!child) return child;
            let {
              name,
              label,
              labelAlign,
              help,
              required,
              message,
              validations,
              style,
              initial,
              initialValue,
              onChange,
              type,
              error_password,
              xtype,
              next_reference,
              flex,
              className,
              format,
              mode,
              feedback = false,
              onRender,
              grid = {},
            } = child.props;
            name = typeof name === "undefined" ? `field_${index}` : name;
            style = style || {};
            /* InitialValue Map */
            let { latName = "lat", lngName = "lng" } = child.props;
            let { form } = me.props;
            if (xtype === "map") {
              let values = form.getFieldsValue([latName, lngName]);
              initialValue = Object.values(values);
            }
            if (initialValues) initialValue = initialValues[name];
            // console.log(">>>>>initialValues:", initialValues)
            if (xtype === "date" && initialValues) {
              if (initialValues[name])
                initialValue = moment(initialValues[name]);
            }
            validations = validations || [
              {
                required: required || this.props.allRequired || false,
                message: message || `${label} is required`,
              },
            ];

            if (next_reference) {
              if (validations.filter((it) => it.validator).length === 0) {
                validations.push({
                  validator: (rule, value, callback) => {
                    if (value && value !== form.getFieldValue(next_reference)) {
                      callback(
                        error_password ||
                          "Two passwords that you enter is inconsistent!"
                      );
                    } else {
                      callback();
                    }
                  },
                });
              }
            }
            /* validations.push({
            validator: async (rule, value) => {
              var mediumRegex = /([A-Z]{0,1}[\w]+){9,9}/g;
              if (mediumRegex.test(value)) {
                //console.log("green");
              } else {
                throw new Error(
                  value ? "Badly formed!!" : "The is required"
                );
              }
            }
          }); */
            if (
              type === "email" &&
              typeof validations.find(({ type }) => type === "email") ===
                "undefined"
            )
              validations.push({
                type: "email",
                message: "The input is not valid E-mail!",
              });

            if (xtype === "password") {
              if (validations.filter((it) => it.validator).length === 0) {
                validations.push({
                  validator: async (rule, value) => {
                    var mediumRegex = new RegExp(
                      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                    );
                    if (mediumRegex.test(value)) {
                      //console.log("green");
                    } else {
                      throw new Error(
                        value
                          ? "Badly formed password!!"
                          : "The password is required"
                      );
                    }
                  },
                });
              }
            }
            if (xtype === "url") {
              if (validations.filter((it) => it.validator).length === 0) {
                validations.push({
                  validator: async (rule, value) => {
                    var mediumRegex = new RegExp(
                      "^((ftp|http|https)://)?www.([A-z]+).([A-z]{2,})"
                    );
                    if (mediumRegex.test(value)) {
                      //console.log("green");
                    } else {
                      throw new Error(
                        value ? "Badly formed url!!" : "The url is required"
                      );
                    }
                  },
                });
              }
            }
            if (xtype === "phonefield") {
              // let value = e.target.value;
              console.log(" formdinamic");
            }
            delete child.value;
            if (type === "hidden")
              style = {
                width: "100%",
                height: 0,
                margin: 0,
                padding: 0,
              };
            let { xxl, xl, lg, md, sm, xs } = grid;
            if (flex <= 1) style["width"] = `${flex * 100}%`;
            if (type !== "hidden" && (xxl || xl || lg || md || sm || xs)) {
              delete style.width;
              delete formItemLayout.span;
            }
            if (!child.type || typeof child == "undefined") {
              return null;
            }
            if (xtype === "fieldset")
              return React.cloneElement(child, {
                style: { width: "100%" },
                trigger: "focus",
                record,
                onBlur: (e) => {
                  if (xtype === "capitalize") {
                    let value = e.target && e.target.value;
                    form.setFieldsValue({
                      [name]:
                        typeof value == "string" ? value.capitalize() : value,
                    });
                  }
                  if (xtype === "phonefield") {
                    let value = e.target.value;
                    console.log("--------------------------------->", value);
                    form.setFieldsValue({
                      [name]:
                        typeof value == "string"
                          ? formatterPhone(value)
                          : value,
                    });
                  }
                },
                form,
              });
            return (
              <Col
                {...formItemLayout}
                key={name || `field-${index}`}
                style={{ padding: 5, display: "inline-block", ...style }}
                className={`${
                  typeof className !== "undefined"
                    ? "item-form " + className
                    : "item-form"
                } ${type === "hidden" ? "item-hidden" : ""} ${
                  xtype === "fieldset" ? "field-set" : ""
                }`}
                xxl={xxl}
                xl={xl}
                lg={lg}
                md={md}
                sm={sm}
                xs={xs}
              >
                <Form.Item
                  /* className={className} */
                  label={label}
                  labelAlign={labelAlign || "right"}
                  hasFeedback={next_reference != null}
                  help={help}
                >
                  {getFieldDecorator(name || `field-${index}`, {
                    initialValue: initialValue || initial,
                    /*  trigger: "focus", */
                    validateTrigger: "onChange",
                    /* trigger: "dateChange", */
                    valuePropName: "value",

                    rules: validations,

                    onChange: function(key, value) {
                      let defaultKey = key;
                      let defaultValue = value;
                      let { form } = me.props;

                      if (xtype === "map") {
                        /*  let { latName = "lat", lngName = "lng" } = child.props; */
                        value = key;
                        let { lng, lat } = value;
                        form.setFieldsValue({ [lngName]: lng });
                        form.setFieldsValue({ [latName]: lat });
                      }

                      if (value)
                        if (typeof value === "object") {
                          /* value = key; --> SE MOVIO PARA ABAJO */
                          if (Array.isArray(key)) {
                            value = key;
                            value = value.join(",");
                          }
                          /* if (Array.isArray(value)) {
                          if (mode === "multiple") {
                            console.log("VALUE:: ", key, value);
                            value = value.map(item => {
                              let { props } = item;
                              return props.value;
                            });
                          }
                          value = value.join(",");
                        } else {
                          value = key;
                        } */
                        }

                      if (typeof key === "object") {
                        if (key) if ("target" in key) value = key.target.value;
                        if (Array.isArray(key)) value = key;
                      } else {
                        if (key !== name) value = key;
                      }
                      if (
                        Array.isArray(key) &&
                        Array.isArray(value) &&
                        _.isEqual(key, value)
                      ) {
                        key = defaultKey;
                        value = defaultValue;
                      }
                      if (xtype === "checkbox") {
                        if (key.target) {
                          let { checked } = key.target;
                          value = checked;
                        } else {
                          value = key;
                        }
                      }
                      if (xtype === "checkbox-group") {
                        value = key;
                      }

                      if (xtype !== "fieldset") {
                        me.handleOnChange(name, value);

                        if (typeof onChange !== "undefined")
                          onChange(name, value, form);
                      } else {
                        let { name, checked } = key.target;
                        /* me.handleChange(name, value); */
                        if (typeof onChange !== "undefined") {
                          onChange(name, value || checked, form);
                        }
                      }
                    },
                  })(
                    React.cloneElement(child, {
                      style: { width: "100%" },
                      trigger: "focus",
                      record,
                      onBlur: (e) => {
                        if (xtype === "capitalize") {
                          let value = e.target && e.target.value;
                          form.setFieldsValue({
                            [name]:
                              typeof value == "string"
                                ? value.capitalize()
                                : value,
                          });
                        }
                        if (xtype === "phonefield") {
                          let value = e.target.value;
                          console.log(
                            "--------------------------------->",
                            value
                          );
                          form.setFieldsValue({
                            [name]:
                              typeof value == "string"
                                ? formatterPhone(value)
                                : value,
                          });
                        }
                      },
                      form,
                    })
                  )}
                </Form.Item>
              </Col>
            );
          });
        } catch (err) {
          console.log("ERROR: ", err);
        }
      }
      return childrens;
    } catch (error) {}
  }

  componentDidMount() {
    let { formLayout } = this.props;
    if (typeof formLayout !== "undefined")
      this.setState({
        formLayout,
      });

    if (this.props.onMount) this.props.onMount(this.props.form);
  }
  componentWillUnmount() {
    this.props.form.resetFields();
  }
  componentWillReceiveProps(nexProps) {
    let { initialValues, record } = nexProps;
    this.setState({
      record,
    });
    //console.log("?????????? RECORD::", record)
  }
  render() {
    const { formLayout } = this.state;
    let {
      footer,
      showFooter = true,
      title,
      titleStyle,
      className,
      style,
      autoSubmit = true,
      textAcceptButton = "Save",
      submitButtonText,
      //this.props. = false,
      initialValues,
      actions,
      form,
    } = this.props;
    return (
      <Form
        className={className || "advanced-form"}
        layout={formLayout}
        onSubmit={this.handleSubmit}
        style={style || { margin: 20 }}
      >
        {title && <HeadTitle className="head-title">{title}</HeadTitle>}
        <Row className="form-fields" gutter={24}>
          {this.getFields()}
        </Row>
        {(autoSubmit || footer) && showFooter && (
          <Row
            className={
              typeof footer !== "undefined" ? "footer-advanced-form" : null
            }
          >
            {autoSubmit ? (
              <>
                <Row type="flex" justify="center">
                  <Col span={24}>
                    <Divider
                      style={{
                        width: "100%",
                        margin: "20px 0px 20px 0px",
                      }}
                    />
                  </Col>
                  <Col span={24} align="center">
                    <div className="section-button-advanseform">
                      <Button
                        type="primary"
                        disabled={this.props.disabled}
                        loading={this.props.submitting}
                        size={this.props.submitButtonSize || "large"}
                        htmlType="submit"
                        className="login-form-button"
                        block
                      >
                        {submitButtonText || textAcceptButton}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row
                  style={footerStyle}
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <Col span={24}>{footer}</Col>
                  <Col span={24}>
                    {!autoSubmit && (
                      <Divider
                        style={{
                          width: "100%",
                          margin: "20px 0px 20px 0px",
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <Row
                style={footerStyle}
                type="flex"
                justify="center"
                align="middle"
              >
                <Col span={24}>{footer}</Col>
              </Row>
            )}
          </Row>
        )}
        {actions && (
          <Tools
            className="form-actions"
            style={{
              margin: "10px 4px",
            }}
          >
            {actions &&
              actions.props &&
              React.Children.map(actions.props.children, (child, index) => {
                let { onClick } = child.props;
                return React.cloneElement(child, {
                  onClick: (e) => {
                    if (onClick) onClick(e, form);
                  },
                });
              })}
          </Tools>
        )}
      </Form>
    );
  }
}
const WrappedAdvancedForm = Form.create({ name: "search_form" })(AdvancedForm);

export default WrappedAdvancedForm;
