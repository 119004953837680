import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField } from "../com/form/";
import { Checkbox, Row, Col, Input } from "antd";
import { Aminities } from ".";
/* 
"BedroomsTotal"
"BathroomsFull"
"SpecialListingConditions"
"property_type_information"
"LotSizeArea"
"NumberOfUnitsTotal"
"StoriesTotal"
"YearBuilt"

*/
const AminitiesInformation = ({ id, onReset, onChange, ...props }) => {
  const [PropertyType, setPropertyType] = useState("Residential");
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const setInitialValues = () => {
    let { record } = props;
    if (record) {
      setRecord(record);
      setPropertyType(record["PropertyType"]);
    }
  };
  const handleChange = (field, value, form) => {
    if (onChange && form) {
      form.validateFieldsAndScroll((errors, values) => {
        onChange(field, value, form, errors);
      });
    }
  };
  useEffect(() => {
    let { loading } = props;
    setLoading(loading);
    return () => {};
  }, [props.loading]);
  useEffect(() => {
    setInitialValues();
    return () => {};
  }, [props.record]);

  return (
    <>
      {!loading && (
        <SimpleForm
          source="my-listings"
          onChange={handleChange}
          initialValues={record}
          autoSubmit={false}
        >
          {
            <Checkbox.Group
              xtype="checkbox-group"
              name="AssociationAmenities"
              style={{ width: "100%" }}
            >
              <Row>
                {Aminities.map(item => (
                  <Col span={12}>
                    <Checkbox value={item.value}>{item.text}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          }
        </SimpleForm>
      )}
    </>
  );
};
export default AminitiesInformation;
