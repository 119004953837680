import React, { useState, useEffect, useRef } from 'react';
import { Button, Menu, Popover, Avatar } from "antd";
import { MenuWrapper } from './Styles';
import { Tools } from '../Styles';

const MenuModal = ({ children, closable = true, title, onClose, ...props }) => {
    return (<MenuWrapper className="menu-modal">
        <Menu>
            <Menu.Item disabled>
                <Tools>
                    {title && <h3>{title}</h3>}
                    {closable && <Button key="clsoe" onClick={onClose} icon="close" type="link" />}
                </Tools>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item>
                <span>
                    {children}
                </span>
            </Menu.Item>
        </Menu>
    </MenuWrapper>)
}
export default MenuModal;