import styled from "styled-components";
import { List } from "antd";
export const WrapperSkeleton = styled.div`
  min-height: 360px;
  background: #d7d0d026;
  border-radius: 6px;
  padding: 2px;
  margin-bottom: 2px;
  & .container-avatar {
    display: flex;
    align-items: align-items;
    gap: 18px;
    padding: 6px 2px 18px;
    border-bottom: 1px solid #f2f2f2;
  }
  & .ant-avatar {
    width: 120px !important;
    height: 90px !important;
    border-radius: 8px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }
  & .content {
    width: 100%;
    .section-title {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .section-tags {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 4px;
        .tag {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 20px;
          background: linear-gradient(
            90deg,
            #f2f2f2 25%,
            #e6e6e6 37%,
            #f2f2f2 63%
          );
          background-size: 400% 100%;
          margin: 4px 2px 8px;
          border-radius: 18px;
          animation: tag 1s infinite ease-in-out;
        }
      }
      .price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15%;
        height: 20px;
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        background-size: 400% 100%;
        margin: 4px;
        border-radius: 2px;
        animation: price 1s infinite ease-in-out;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
   
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 20px;
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        background-size: 400% 100%;
        margin: 4px;
        border-radius: 2px;
        animation: price 1s infinite ease-in-out;
      }
      .sqft {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%;
        height: 20px;
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        background-size: 400% 100%;
        margin: 4px;
        border-radius: 2px;
        animation: price 1s infinite ease-in-out;
      }
    }
    .section-date {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24%;
        height: 18px;
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        background-size: 400% 100%;
        margin: 4px;
        border-radius: 2px;
        animation: price 1s infinite ease-in-out;
      }
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24%;
        height: 18px;
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        );
        background-size: 400% 100%;
        margin: 4px;
        border-radius: 2px;
        animation: price 1s infinite ease-in-out;ss
      }
    }
  }

  & .description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    margin: 0px;
    border-radius: 2px;
    margin: 8px;
    animation: description 1s infinite ease-in-out; /* Animación */
  }

  @keyframes ant-avatar {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    40% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes tag {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes price {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes description {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes number {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
`;
export const WrapperProperties = styled.div`
  & .ant-spin-container .ant-row .ant-col {
    border-bottom: 1px solid #cccccc7a !important;
    padding-bottom: 0x !important;
    margin-bottom: 8px !important;
  }
  & .ant-spin-container .ant-row .ant-col:last-child {
    border-bottom: 0 !important;
  }
`;
export const WrapperCardProperties = styled(List.Item)`
    & .ant-avatar{
        border-radius: 10px !important;
        min-width: 90px !important;
        min-height: 84px !important;
    }
  
    & .head-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h2{
          font-size: 18px !important;
          margin-bottom: 0px !important;
          line-height: 1 !important;
          padding-right: 6px;
        }
        .address {
            h3{
                font-size: 16px!important;
                margin-bottom: 0px !important;
            }
            span.ant-tag::after {
                content:null!important;
                display:none!important
            }
            
        }
      }
    
   & .head-listing{
    .section-type {
      display: flex;
      gap: 6px;
      justify-content: flex-start;
      .for-type {
        background: var(--board-color-blue) !important;
        color: #fff;
        min-width: 62px;
        display: flex;
        justify-content: center;
        border-radius: 18px;
        font-size: 12px;
        height: 20px;
        align-items: center;
      }
        .status{
          text-transform: capitalize;
        }
          .pending {
            background: var(--yellow)!important;
            color: #fff;
            border-radius: 18px;
            min-width: 66px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            height: 20px;
            align-items: center;
          }
          .active {
            background: var(--board-color-green) !important;
            color: #fff;
            border-radius: 18px;
            min-width: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            height: 20px;
            align-items: center;
          }
    }
        .head-features > div {
            margin: 0px 2px;
            span{
                font-size:14px!important;
            }
        }
        .head-features {
            display: flex;
            justify-content: start;
            align-items: center;
        }
        .price {
            text-align: right!important;
            span{
                rgba(0,0,0,.45)!important;
            }
        }
        .area{
            font-size:10px!important;
        }
   }
   
    & .description{
         .head-description {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date{
                font-size:10px!important;
            }
        }
        .detail{
            font-size:12px;
        }
    }
`;
export const Wrapper = styled.div`

    & .ant-carousel .slick-slide {
        min-height: 410px!important;
    }
    & .ant-carousel .slick-list .slick-slide.slick-active {
        height:410px!important;
        padding: 2px
    }
    
    & .ant-card{
        background: #F9F2FF 0% 0% no-repeat padding-box!important;
        box-shadow: 4px 4px 8px #0000001f!important;
        border-radius: 8px!important;
        opacity: 1!important;
        margin: 0px 4px!important;
        overflow:hidden;
    }
    & .ant-card-body {
        padding: 14px 16px 16px 16px!important;
        overflow:hidden;
    }
    & .cardtitle{
        display: flex;
        justify-content: start;
        align-items: center;
    }
    & .cardtitle h2{
        font-size: 24px;
        font-family: 'Jost';
        font-weight: 600;
        letter-spacing: 0px;
        margin: 8px 0px;
        text-transform: capitalize;

        white-space: normal!important;
        white-space: normal!important;
        line-height: 1!important
        text-align:left!important;
        margin-bottom: 0px;
    }
    & .ant-card-meta-description {
        margin: 2px 1px!important;
        display: flex;
    }

   /*  & .ant-col.ant-col{
    padding-top: 22px !important; 
    } */
    & .ant-carousel .slick-slide {
        text-align: center;
        background: #e2e4e808;
        line-height: 160px;
        overflow: hidden;   
    }
    & .ant-carousel .slick-slide h3 {
        color: #fff; 
    }
    & .ant-card-cover img{
        transition: transform .4s,-webkit-transform .4s;
        opacity:.8;
    }
    & .ant-card-cover:hover img{
        transform: scale(1.2);
    }
    & .ant-card-cover {
        height: auto !important;
        overflow: hidden;
        border-radius: 8px 8px 0px 0px!important;
        overflow:hidden;
    }
    & .ant-carousel .slick-slide .cover img {
        height: 297px
    }
    &  .ant-card-cover .cover img {
        object-fit: cover;
    }
    /* & .ant-card-meta-detail {
        display: table !important;
    } */
    & .style-footer {
        display: flex;
        font-family: 'Jost';
        font-weight: 600;
        font-size: 11px;
        color:#AD42FF;
    }
    & .style-price {
        display: flex;
        font-family: 'Jost';
        font-weight: 600;
        font-size: 22px;
        color: var(--color1);   
    }
    & .style-button {
        display: flex;
        justify-content: flex-end;
        margin-top: -34px;
    }
    & .ant-btn-icon-only {
        align-content: center!important;
        display: inline-grid!important;
    }

    & .tools{
        position: absolute;
        right: 0;
        top: 0;
        padding: 4px;
        margin: 2px;
    }
    & .button-show .ant-btn{

        color: var(--color-primary)!important;
        background-color: #fff;
        border-color: var(--color-primary)!important;
        font-weight: 600;
        font-family: 'Jost';
        padding: 4px 20px;
        height: 40px;
        text-align: center;

    }
    & .button-show{
        text-align:center;
        margin-bottom:20px!important;
        margin-top:16px!important;
    }
    & .ant-carousel .slick-dots li.slick-active button {
        background: var(--color-secundary)!important;
        box-shadow: 2px 2px 2px #be5cff!important;
        border: 1px solid var(--color-secundary)!important;
    }
    & .ant-carousel .slick-dots li button {
        width: 14px!important;
        height: 14px!important;
        opacity: 1!important;
        border-radius: 50%!important;

        background: #e8e8e8!important;
        box-shadow: 2px 2px 2px rgb(0 0 0/ 15%)!important;
        border: 1px solid rgb(0 0 0 / 15%)!important;
    }
    
 
`;
export const WrapperMyCard = styled.div`
  /* fvargas */
  & .card-title-container > div {
    margin: 8px 0px 0px 0px !important;
  }
  & .ant-card {
    background: #f9f2ff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 46px #00000040 !important;
    border-radius: 5px !important;
    width: 340px;
    border: 1px solid #a734ff1a !important;
  }

  & .ant-card-body {
    padding-bottom: 0px !important;
  }
  & .ant-card .card-title h2 {
    line-height: 1 !important;
    white-space: normal !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
  }
  & .ant-tag {
    cursor: pointer !important;
    border: 0px !important;
    border-radius: 28px !important;
    padding: 8px !important;
    min-width: 80px;
    text-align: center;
    font-size: 14px !important;
    background: #f4e6ff 0% 0% no-repeat padding-box !important;
    color: var(--color-primary) !important;
    font-weight: 600;
    font-size: 16px !important;
    text-transform: capitalize !important;
  }
  & .card-city .ant-avatar {
    margin-right: 4px !important;
  }
  & .card-city .ant-avatar > img {
    object-fit: contain !important;
  }
  & .card-city .avatar {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  /* description */
  & .card-description-container {
    margin: 20px 0px;
  }
  & .card-description-container .card-label span {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-secundary) !important;
  }
  & .card-description-container .card-value span {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-primary) !important;
  }
  & .card-price-container {
    margin: 4px 0px;
  }
  & .ant-card-actions {
    border-top: 0px !important;
    background: #f9f2ff 0% 0% no-repeat padding-box !important;
    border-radius: 0px 0px 8px 8px !important;
  }
  & .btn-add-to-reservation {
    width: 60% !important;
  }
  & .btn-add-to-reservation span {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  /* fvargas */
`;
export const WrapperCardsPack = styled.div`
  /*  & .ant-card-cover img{
    transition: transform .4s,-webkit-transform .4s;
    opacity:.8;
}
& .ant-card-cover:hover img{
    transform: scale(1.2);
} */

  & .ant-list {
    width: fit-content !important;
  }
  /*  & .ant-list-item-meta-avatar img{
        transition: transform .4s,-webkit-transform .4s;
    }
    & .ant-list-item-meta-avatar:hover img{
        transform: scale(1.2);
    } */

  /* responsi */
  @media (max-width: 576px) {
    .ant-list-item-meta-avatar img {
      max-width: 100px;
      max-height: 100px;
    }
    .ant-list-item-meta-content {
      display: block;
      padding-top: 10px !important;
      margin-top: 10px !important;
    }
    .ant-list-item-meta {
      border-radius: 9px !important;
      max-width: 244px;
    }
  }
  /* sm */
  @media (max-width: 768px) {
    .ant-list-item-meta-avatar img {
      max-width: 114px;
      max-height: 114px;
    }
    .ant-list-item-meta-content {
      padding-top: 10px !important;
      margin-top: 17px !important;
    }
    .ant-list-item-meta {
      border-radius: 9px !important;
      max-width: 255px;
    }
  }

  & .ant-list-item-meta-avatar img {
    width: 140px !important;
    height: 135px !important;
    object-fit: cover;
  }
  & .ant-list-item-meta-avatar {
    margin-right: 20px !important;
    margin-left: 6px !important;
  }

  & .ant-list-item-meta {
    border-radius: 9px !important;
    background: #bfbfbf1f !important;
  }
  & .ant-list * {
    border-radius: 5px !important;
    cursor: pointer !important;
  }
  & .ant-spin-container {
    padding-top: 21px;
  }
  & .ant-list-item {
    display: flex !important;
    max-width: 100%;
    margin-bottom: 40px !important;
    padding-top: 10px !important;
    padding-bottom: 4px !important;
    border-bottom: none;
  }
  & .ant-list-item-meta-content {
    display: block;
    padding-top: 20px;
    margin-top: 20px;
  }
  & .ant-list-item-meta-title {
    margin-bottom: -9px;
  }
`;
export const WrapperCard = styled.div`
/* wil site inmuebles */

    & .ant-card-cover{
        height: 208px !important
    }
    
    & .ant-card {
        min-height: 330px!important;
    }
    & .ant-btn .anticon {
        color: red;
    }
    & .section-avatar {
        display: flex;
        justify-content: space-between;
       
    }
    .ant-card-meta-detail > div:not(:last-child) {
        margin-bottom: -5px;
    }
    & .sections-description {
        display: flex;
        background: #bfbfbf4a;
        border-radius: 50px;
        min-width: 110px;
    
    }
    & .sections-description-rooms {
        display: flex;
        background: #bfbfbf4a;
        border-radius: 50px;
        min-width: 110px;
        margin-left: 10px;
    }
    & .container-descriptions {
        margin-left: 10px;
       
        display: flex;
        align-items: center;
    }
    &  .avatar-bath .styles-bath > img {
        background: #dc3545;;
        border-radius: 50%;
    }
    &  .avatar-rooms .styles-rooms > img {
        background: #faad14d9;
        border-radius: 50%;
    }
    & .container-bath{
            margin-left: 10px;
    }



     & .price-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    & .container-city {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    & .container-neighborhood{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        
    }
    & .container-neighborhood >span{
        font-size: 28px;
        color:var(--color-primary);
    }
    & .footer-address {
        display: flex;
    }
    & .footer-address >span {
        font-size: 21px;
        font-weight: 500;
    }



    /* Card fvargas */
      
  
    & .ant-skeleton {
        display: block;
        width: 100%;
        background: #FFF!important;
        padding: 0px 0px 10px 0px;
        min-height: ${({ loggedIn }) => (loggedIn ? "540px" : "430px")}
        background: #F9F2FF 0% 0% no-repeat padding-box!important;
        box-shadow: 4px 4px 8px #0000001f!important;
        border-radius: 8px!important;
        opacity: 1!important;
        margin: 0px 4px!important;
    }
    & .ant-skeleton-content .ant-skeleton-paragraph > li {
        width: 100%;
        height: 20px;
    }
    & .ant-skeleton-content {
        display: block;
        width: 230px;
        padding: 0px 10px;
    }
    & h3.ant-skeleton-title {
        height: 30px;
    }
    & .ant-skeleton-header .ant-skeleton-avatar-lg {
        width: 100%;
        height: 320px;
        border-radius: 8px 8px 0px 0px!important;
    }
    & .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
        margin-top: 10px;
    }
    & .ant-skeleton-header {
        display: block!important;
        padding-right: 0;
        width: 100%!important;
        padding: 0!important;
        margin: 0px;
    }
    & .ant-card {
        min-height:${({ loggedIn }) => (loggedIn ? "540px" : "520px")};
    }
    & .ant-card-cover {
        cursor: pointer;
    }
    ${({ photos }) =>
      photos == 0
        ? `.ant-card-cover .cover img {
        object-fit: contain!important;
        /* padding: 50px; */
    }`
        : ""}
    & .categories span {
        margin-left: 7px;
        margin-right: 5px;
    }
    & .categories {
        text-align: left;
    /*  background: #670eac1a; */
        display: flex;
        margin-left: -6px;
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 500;
        width: max-content;
        border-radius: 10px;
        letter-spacing: 0.15px;
        color: #A734FF;
        opacity: 1;
    }
    & .ant-avatar-lg{
        width: 27px!important;
        height: 27px!important;
    }
    & .ant-avatar-sm {
        width: 16px;
        height: 16px;
        line-height: 24px;
        border-radius: 50%;
        margin-left: -2px;
    }
    & .category {
        font-size: 17px;
        font-family: 'Jost';
        font-weight: 500;
        letter-spacing: 0px;
        color: #444444;
        opacity: 1;
        text-transform: capitalize;
    }
    & .buttonShow {
        display: flex;
        justify-content: center;
        padding-top: 16px;
        align-items: center;
        
    }
    & .ant-btn:hover, .ant-btn:focus {
        color: #ffff!important;
    }
    & .label-price{
        font-size:16px;
        color: var(--color-primary);
        font-family: 'NoirPro';
        font-weight: 400;
        text-align: left;
    }
    & .price{
        font-size:24px;
        color: var(--color-primary);
        font-family: 'NoirPro';
        font-weight: 600;
    }

    .ant-card-actions {
        bottom: 0;
        position: absolute;
        width: 100%;
        background: var(--color-gray-light)!important;
    }
    & .btn-add-to-reservation{
        margin:0px 4px;
        width:80%!important;
    }
  
    
    & .footer {
        display: flex;
     /*    justify-content: space-between; */
        align-items: flex-end;
    }
`;
export const WrapperEmpty = styled.div`
  & .empty {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px dashed #a734ff;
    border-radius: 5px;
    opacity: 1;
    height: 517px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .title-empty {
    font-family: "Jost";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    align-items: center;
    width: 274px;
  }
`;
export const WrapperCardEntity = styled.div`
  /* fvargas */
  & .card-image {
    background-size: cover !important;
    height: 200px !important;
    background-repeat: no-repeat !important;
    width: 100% !important;
    background-position: center !important;
  }
  & .ant-card-actions {
    border-top: 0px !important;
    background: #f9f2ff 0% 0% no-repeat padding-box !important;
    border-radius: 0px 0px 8px 8px !important;
  }
  & .ant-card-body {
    padding: 16px 0px 0px 0px !important;
  }
  & .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px !important;
  }
  & .image-container .logo {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  & .image-container .logo .ant-avatar {
    background: #ffff;
  }
  & .image-container {
    padding: 0px !important;
    height: 200px !important;
    overflow: hidden !important;
  }
  & .image-container.empty {
    padding: 0px 10px 0px 10px !important;
  }
  & .image-container.empty .card-image {
    background-size: contain !important;
    padding: 10px !important;
    background: var(--color-secundary);
    border-radius: 8px;
  }
  & .location {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .location .ant-avatar > img {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  & .location > span:first-child {
    margin-right: 4px !important;
  }
  & .location > div:not(:first-child) {
    margin-right: 8px;
  }

  & .ant-card-cover .image-container.empty img.card-image {
    transition: none !important;
  }
  & .ant-card-cover:hover .image-container.empty img.card-image {
    transform: none !important;
  }
  & .ant-card-cover .image-container img.card-image {
    transition: transform 0.4s, -webkit-transform 0.4s;
    opacity: 0.8;
  }
  & .ant-card-cover:hover .image-container img.card-image {
    transform: scale(1.2);
  }
  & .section-title h2 {
    margin-bottom: 0px !important;
  }
  /* fvargas */
  & .slick-list {
    max-height: 300px !important;
  }
  & .ant-card {
    background: none !important;
    /*  box-shadow: 4px 4px 8px #0000001f!important; */
    box-shadow: none !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    margin: 0px 4px !important;
    overflow: hidden;
    border: 0px !important;
    cursor: pointer !important;
  }

  & .ant-card-cover {
    max-height: 200px !important;
    overflow: hidden;
    border-radius: 8px !important;
    overflow: hidden;
  }

  & .section-city {
    margin-bottom: -4px !important;
  }
`;
export const WrapperCarousel = styled.div`
  & .ant-carousel {
    max-height: 300px !important;
  }
`;
export const WraperCarouselListing = styled.div`
  .ant-carousel .slick-slide {
    text-align: center;
    height: 470px;
    line-height: 160px;
    background: #212529b3;
    overflow: hidden;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
`;
export const WrapperListing = styled.div``;
export const WrapperContentListing = styled.div``;
