import React, { useState, useEffect, Suspense } from "react";
import { Layout, Menu, Icon, Row, Col } from "antd";
import { Link, navigate } from "@reach/router";
import { Router } from "@reach/router";
import styled from "styled-components";
import { Footer } from "@bootstrap-styled/v4";
import NotFound from "../views/NotFound";
import Loader from "../components/loader";
/* Redux */
import { connect, useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../store/actions";
/* Import Components */
import SideMenu from "../components/menu/SideMenu";
import { TopBar } from "../components/header";
import routes from "../routes";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";
import { allowAccess } from "../utils/Helper";
import Loadeable from "react-loadable";
import PrivateRoute from "../utils/PrivateRoute";
import { URL_S3, LOGO_BLUECLOSERX } from "../constants";
const { Header, Sider, Content } = Layout;
const EditListing = Loadeable({
  loader: () => import("../views/mylistings/Listing"),
  loading: Loader,
});
const Profile = Loadeable({
  loader: () => import("../views/users/Profile"),
  loading: Loader,
});
const { SubMenu } = Menu;
const Logo = require("../sources/images/logo.svg");

const LogoContainer = styled.div`
  padding: 10px;
  background: var(--blue);
  overflow-x: hidden !important;
`;
const Copy = styled.p`
  text-align: center;
`;
const SideBar = styled.div`
  max-width: 212px;

  background: #fff !important;
  overflow-x: hidden !important;

  height: 100%;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
`;
const SiderContainer = styled(Sider)`
  &.ant-layout-sider {
    background: #fff !important;
  }
  & .sider-container {
    min-width: 214 px !important;
  }
  &.ant-layout-sider-collapsed .ant-menu-item,
  .ant-layout-sider-collapsed .ant-menu-submenu {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px !important;
  }
  &.logo-container:hover {
    transform: scale(1.5);
  }
  &.ant-layout-sider-collapsed .logo-container div {
    width: 64px !important;
  }
  &.ant-layout-sider-collapsed .ant-menu-item i {
    font-size: var(--font-size-medium);
  }
`;

const HeaderContainer = styled.div`
  & h2 {
    color: #fff;
    margin-bottom: 0px;
  }
`;
const AdminLayout = ({ children, collapsedMenu, ...props }) => {
  const [menu, setMenu] = useState([]);
  const user = useSelector(({ user }) => user);
  useEffect(() => {
    if (user && routes && user.modules) {
      let { modules } = user;
      routes.forEach((it) => {
        /* Add Edit Route */
        if (typeof it.edit == "undefined") {
          routes.push({
            ...it,
            path: `${it.path}/:id`,
          });
        } else {
          routes.push(it.edit);
        }
        /* Add Create Route */
        if (typeof it.create == "undefined") {
          routes.push({
            ...it,
            path: `${it.path}/:id`,
          });
        } else {
          routes.push(it.create);
        }
        //console.log("Route:", it.path)
      });
      setMenu(
        routes.map((route, index) => {
          return allowAccess(route.permissions) ? (
            <route.component
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
            />
          ) : null;
        })
      );
    }
    return () => {};
  }, [user]);
  return (
    <Layout
      className="main-layout"
      style={{ minHeight: "100vh", background: "red" }}
    >
      <SiderContainer
        className="sider-container"
        trigger={null}
        collapsible
        collapsed={collapsedMenu}
      >
        <LogoContainer className="logo-container">
          <Link to="/" className="logo">
            <div
              style={{
                background: `url(${
                  user.company && user.company.logo
                    ? `${URL_S3}/${user.company.logo}`
                    : LOGO_BLUECLOSERX
                })`,
                backgroundRepeat: "no-repeat",
                height: 30,
                backgroundSize: "100% 100%",
                width: 120,
              }}
            />
          </Link>
        </LogoContainer>
        <SideBar>{<SideMenu />}</SideBar>
      </SiderContainer>
      <Layout
        style={{
          marginBottom: -50,
        }}
      >
        <TopBar />
        <Header style={{ color: "#fff", height: 150, padding: "0 20px" }}>
          <HeaderContainer
            style={{
              padding: "18px 0px 0px 0px",
            }}
          >
            <h2>{(props.current && props.current.title) || "Dashboard"}</h2>
          </HeaderContainer>
          <BreadCrumb {...props} />
        </Header>
        <Content
          style={{
            padding: 24,
            background: "#fff",
            minHeight: 280,
            margin: "-50px 24px 10px 24px",
            boxShadow: "0 2px 10px -1px rgba(69,90,100,0.3)",
            /* transform: "translate(0px, -50px)", */
            borderRadius: "calc(0.25rem - 0px) calc(0.25rem - 0px) 0 0",
          }}
        >
          <Suspense fallback={<Loader />}>
            <Router>
              {menu}
              <Profile path="/profile" />
              <EditListing path="/mylistings/:id" />
              {<NotFound default />}
            </Router>
          </Suspense>
        </Content>
        <Footer>
          <Copy>GoOnewWorld © 2020</Copy>
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    collapsedMenu: state.collapsedMenu,
    user: state.user,
    pathname: state.pathname,
    current: state.current,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChangePath: (pathname) =>
      dispatch({ type: actionTypes.CHAGE_PATH, pathname }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
