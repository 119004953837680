import React, { useEffect, useState } from "react";
import FormPage from "../../components/form-page/FormPage";
import * as layouts from "../../layouts/form-page-layouts/";
import { getService } from "../../services";
import { message } from "antd";
import Loader from "../../components/loader/";
const Layout = ({ layout, ...props }) => {
  return React.createElement(layouts[layout || "centerlayout"], {
    layout,
    ...props,
  });
};
const FormPages = ({ slug, ...props }) => {
  const [form, setForm] = useState();
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const getForm = async () => {
    try {
      const service = getService("forms");
      let form = await service
        .find({
          query: {
            slug,
          },
        })

        .then(({ data }) => data[0]);
      setLoading(false);
      if (form) {
        setForm(form);
        setMounted(true);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) getForm();
  }, [slug]);
  if (loading) return <Loader {...form} />;
  return (
    <Layout {...form}>
      <FormPage {...props} form={form} />
    </Layout>
  );
};
export default FormPages;
