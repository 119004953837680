import {
  Button,
  Input,
  message,
  Comment,
  Avatar,
  Mentions,
  Spin,
  Mention,
  Icon,
  Row,
  Col,
} from "antd";
import { FiSend } from "react-icons/fi";
import React, { useState, useEffect } from "react";
import { WrapperCommentInput } from "./Styles";
import { TaskContext } from "../../components/board/context/";
import { useContext } from "react";
import { getService } from "../../services";
import _ from "lodash";
import { useSelector } from "react-redux";
import { URL_S3 } from "../../constants";
const { Option, getMentions } = Mentions;
const { TextArea } = Input;
const CommentInput = ({
  id,
  onMentions,
  action = true,
  showAvatar = true,
  add = true,
  edit,
  ...props
}) => {
  const { getComments } = useContext(TaskContext);
  const [editing, setEditing] = useState(false);
  const [mentions_ids, setMentionsIds] = useState([]);
  const [mentions, setMentions] = useState({});
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState();
  const [raw_value, setRawValue] = useState();
  const [record, setRecord] = useState();
  const user = useSelector(({ user }) => user);
  const [searchText, setSearchText] = useState("");
  const onSubmit = async () => {
    const service = getService("comments");
    try {
      if (id) {
        setSubmitting(true);
        if (add && value)
          await service.create({
            task_id: id,
            message: raw_value,
            mentions_ids,
          });
        setSubmitting(false);
        setValue(null);
        setChanged(false);
        setEditing(false);
        getComments();
      } else if (!id) {
        message.warning("Tasks need a title to get started.");
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleOnChange = (value) => {
    console.log("escribiendo", value);
    setSearchText(value);
    setChanged(true);
    setValue(value);

    //if (!add && props.onChange) props.onChange(value);
  };
  const handleOnSearch = (search) => {
    let query = {};
    if (search) query["search"] = search;
    const service = getService("tasks", {
      query: {
        company_id: user.company_id,
        $limit: 10,
        ...query,
      },
    });
    setLoading(true);
    service
      .get(id)
      .then((resp) => {
        setOptions(resp.members);
        setLoading(false);
        // console.log("mention-data:::::::::::", new_options);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };
  /* const search = async (search) => {
    const service = getService("users");
    let query = {};
    if (search) query["search"] = search;
    if (!loading) {
      setLoading(true);
      let { data } = await service.find({
        query: {
          company_id: user.company_id,
          $limit: 10,
          ...query,
        },
      });
      console.log("data user", data);
      // setOptions(data);
      setLoading(false);
    }
  }; */
  // const handleOnSearch = _.debounce(search, 500, { maxWait: 1000 });
  const handleOnSelect = ({ value, children }) => {
    let name = children.join("");
    if (!mentions_ids.includes(value)) {
      setMentions({
        ...mentions,
        [value]: name,
      });
      setMentionsIds((mentions_ids) => [...mentions_ids, value]);
      if (onMentions) onMentions([...mentions_ids, value]);
    }
    setValue((current_value) => current_value.replace(value, name));
  };
  useEffect(() => {
    return () => {
      setChanged(false);
    };
  }, []);
  useEffect(() => {
    if (mentions) {
      Object.keys(mentions).forEach((key) => {
        setValue((current_value) =>
          current_value.replace(`@${key}`, `@${mentions[key]}`)
        );
      });
    }
  }, [mentions]);
  useEffect(() => {
    if (typeof props.editing != "undefined") setEditing(props.editing);
  }, [props.editing]);
  useEffect(() => {
    if (typeof props.value != "undefined") setValue(props.value);
  }, [props.value]);
  useEffect(() => {
    if (value) {
      let message = value;
      Object.keys(mentions).forEach((key) => {
        let val = mentions[key];
        message = message.replace(`@${val}`, `@${key}`);
      });
      setRawValue(message);
      if (props.onRawTextChange) props.onRawTextChange(message);
    }
    //if (typeof props.value != "undefined") setValue(props.value);
  }, [value]);
  return (
    <WrapperCommentInput>
      {editing}
      <Comment
        className="coments-container"
        avatar={
          showAvatar && (
            <Avatar
              className="avatar-comment-write"
              src={`${URL_S3}/${user.picture}`}
            >
              {!user.picture
                ? `${user.first_name.substring(0, 1)}${user.last_name.substring(
                    0,
                    1
                  )}`
                : user.email.substring(0, 1)}
            </Avatar>
          )
        }
        content={
          <div className="content-inputs">
            {!editing && !changed && (
              <div className="input-comments" onClick={() => setEditing(true)}>
                <span>Add Comment</span>
              </div>
            )}
            {(editing || changed) && (
              <div className="container-text-area" style={{ width: "100%" }}>
                <Mentions
                  ref={(el) => {
                    if (el) el.focus();
                  }}
                  value={value}
                  onChange={handleOnChange}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  //autoSize={{ minRows: 4 }}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  notFoundContent={
                    loading ? (
                      <Spin size="small" />
                    ) : (
                      `No Record "${searchText}"`
                    )
                  }
                  onBlur={() => setEditing(false)}
                  placeholder="Add Comment"
                  loading={loading}
                >
                  {options.map((it) => (
                    <Option value={it._id}>
                      {it.first_name} {it.last_name}
                    </Option>
                  ))}
                </Mentions>
                {!edit && (
                  <div className="button-save-message">
                    <Button type="link" loading={submitting} onClick={onSubmit}>
                      <FiSend className="icon-sent-comment" />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
    </WrapperCommentInput>
  );
};

export default CommentInput;
