import React, { useState, useEffect } from "react";
import Markdown from 'react-markdown';
//import htmlParser from 'react-markdown/plugins/html-parser';
require('codemirror/mode/markdown/markdown');
/* const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: []
}); */
const MarkDown = (props) => {
    return (<Markdown
        flex={1}
        escapeHtml={false}
        /* astPlugins={[parseHtml]} */
        source={props.value}
        {...props}
    />)
}
export default MarkDown;