import React, { useState, useEffect } from "react";
import { SimpleForm, FileUpload } from "../com/form/";
import {
  Table,
  Icon,
  message,
  ConfigProvider,
  Empty,
  Button,
  Tooltip,
  Row,
  Col,
  Menu,
  Dropdown,
  List,
} from "antd";
import { getService } from "../../services/";
import { URL_S3 } from "../../constants/";
import styled from "styled-components";
import { async } from "q";
import DocumentItem from "../board/DocumentItem";
import Uploader from "../com/Uploader";
const MyTable = styled(Table)`
  & .ant-table {
    box-shadow: none !important;
    border: 0px !important;
  }
  & .ant-table-placeholder {
    border-top: 0px !important;
    border-bottom: 0px !important;
  }
`;
const Wrapper = styled.div`
  & label {
    color: #fff !important;
  }
  & .section-title-attch {
    h4 {
    }
  }
  & .container-list .ant-list.ant-list-split {
    max-height: 360px;
    overflow: scroll;
  }
  & .container-list {
    padding-top: 8px !important;
    .ant-list-item-meta {
      height: 54px !important;
    }
  }
`;
const formats = [
  // Images
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/svg+xml",
  "image/webp",

  // Documents
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/plain",
  "text/txt",

  // Audio
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",

  // Video
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/x-ms-wmv",
];
const defaultActions = {
  delete: true,
  download: true,
};
const documentService = getService("documents");
const AgentDocuments = ({
  id,
  // filterDefaultValues,
  source,
  reference,
  dataSource,
  record,
  onRemove,
  actions = defaultActions,
  foreclosure = false,
  extra,
  onSubmit,
  ...props
}) => {
  const [documents, setDocuments] = useState([]);
  const [media, setMedia] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState();

  const getDocuments = () => {
    if (dataSource) {
      return setDocuments(dataSource);
    }
    documentService
      .find({
        query: {
          [source]: id,
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        setDocuments(data);
        if (onSubmit) {
          const ids = data.map((item) => item._id);
          return onSubmit(ids);
        }
      })
      .catch((err) => message.error(err.message));
  };
  const onRemoveDocument = async (id) => {
    documentService
      .remove(id)
      .then((response) => {
        message.info("Record removed!");
        getDocuments();
        console.log("elimidad::::::", response);
      })
      .catch((err) => message.error(err.message));
  };
  const renderItem = (record, index) => {
    return (
      <DocumentItem
        index={index}
        record={record}
        actions={actions}
        extra={extra}
        key={record._id}
        onRemove={onRemoveDocument}
        documents={documents}
        {...record}
      />
    );
  };

  const save = async (file) => {
    console.log("que es file", file.name);
    const service = getService("documents");
    /*  let name = file.name.split("/");
    name = name[name.length - 1]; */
    let payload = {
      name: file.name,
      path: file.key,
      [foreclosure ? "foreclosure_id" : "contact_id"]: id,
    };
    setSubmitting(true);
    console.log("return paylodas documents", payload);
    try {
      let resp = await service.create(payload);
      // if (onSubmit) onSubmit(resp);
      getDocuments();
      if (resp) setSubmitting(false);
    } catch (error) {
      message.error(error.message);
      setSubmitting(false);
    }
  };
  const handleOnChange = ({ key, ...file }) => {
    delete file.url;
    let files = media;
    file["uid"] = `${files.length}`;
    file["key"] = key;
    file["url"] = file.location;
    file["status"] = file.status === 204 ? "done" : "error";
    files.push(file);
    // return console.log("file", file);
    save(file);
    setMedia((prevFiles) => {
      return [...files];
    });
  };
  const handleOnDelete = ({ key, ...file }) => {
    let files = media.filter((it) => it.status !== "deleted");
    save(files);
    setMedia((prevFiles) => {
      return [...files];
    });
  };
  useEffect(() => {
    if (filterDefaultValues) getDocuments(id);
    return () => {};
  }, [id && filterDefaultValues]);
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  return (
    <Wrapper className="wrapper-documents">
      <div>
        <Uploader
          onChange={handleOnChange}
          onDelete={handleOnDelete}
          path={`documents/${id}`}
          fileList={media}
          formats={formats}
          autoIncrement={false}
          showSupportFormat={false}
          showUploadList={false}
          submitting={submitting}
        />
      </div>
      {documents && documents.length > 0 && (
        <div className="container-list">
          <List dataSource={documents} renderItem={renderItem} />
        </div>
      )}
    </Wrapper>
  );
};
export default AgentDocuments;
