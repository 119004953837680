import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField, SteperForm } from "../com/form/";
import { Input, Row, InputNumber, Divider, Icon, Tooltip } from "antd";
import { special_conditions, property_information } from ".";
import { WrapperForm } from "./Styles";
/* 
"BedroomsTotal"
"BathroomsFull"
"SpecialListingConditions"
"property_type_information"
"LotSizeArea"
"NumberOfUnitsTotal"
"StoriesTotal"
"YearBuilt"

*/
const GeneralInformation = ({ id, onReset, onMount, onChange, ...props }) => {
  const [PropertyType, setPropertyType] = useState("Residential");
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const setInitialValues = () => {
    let { record } = props;
    if (record) {
      setRecord(record);
      setPropertyType(record["PropertyType"]);
    }
  };
  /* const handleOnChange = (field, value, form) => {
    if (onChange && form) {
      form.validateFieldsAndScroll((errors, values) => {
        onChange(field, value, form, errors);
      });
    }
  }; */
  const PropertySubTypes =
    PropertyType === "Residential"
      ? [
          {
            text: "Home",
            value: "SingleFamilyResidence",
          },
          {
            text: "Condominium",
            value: "Condominium",
          },
          {
            text: "Townhouse",
            value: "Townhouse",
          },

          {
            text: "Duplex",
            value: "Duplex",
          },
          {
            text: "Triplex",
            value: "Triplex",
          },
          {
            text: "Quadruplex",
            value: "Quadruplex",
          },
          {
            text: "Land",
            value: "UnimprovedLand",
          },
          {
            text: "BoatSlip",
            value: "BoatSlip",
          },
          {
            text: "Manufactured",
            value: "Manufactured",
          },
          {
            text: "Other",
            value: "Other",
          },
        ]
      : [
          {
            text: "Single Family Home",
            value: "SingleFamilyResidence",
          },
          {
            text: "Apartment",
            value: "Apartment",
          },
          {
            text: "Townhouse",
            value: "Townhouse",
          },
          {
            text: "StockCooperative",
            value: "StockCooperative",
          },
          {
            text: "MultiFamily",
            value: "MultiFamily",
          },
          {
            text: "Duplex",
            value: "Duplex",
          },
          {
            text: "Other",
            value: "Other",
          },
        ];

  useEffect(() => {
    let { loading } = props;
    setLoading(loading);
    return () => {};
  }, [props.loading]);
  useEffect(() => {
    setInitialValues();
    return () => {};
  }, [props.record]);
  return (
    <WrapperForm>
      {!loading && (
        <SimpleForm
          source="my-listings"
          onChange={onChange}
          onMount={onMount}
          initialValues={record}
          autoSubmit={false}
        >
          <Input
            flex={1}
            name="ListingId"
            placeholder="Listing Id #"
            label={
              <div className="tools-label">
                <span>Listing Id # </span>
                <Tooltip title="Example: A10000000">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </div>
            }
            validations={[
              {
                required: true,
                message: "Please enter MLS ID",
              },
              {
                validator: async (rule, value) => {
                  var regex = /([A-Z]{0,1}[\w]+){9,9}/g;
                  if (!regex.test(value)) {
                    throw new Error(
                      value ? "MLS Badly formed!" : "MLS is required"
                    );
                  }
                },
              },
            ]}
          />
          <InputNumber
            min={0}
            name="LotSizeArea"
            /* placeholder="Living Area" */
            label="Living Area"
          />

          <SelectField
            name="PropertySubType"
            mode="multiple"
            label="Property Type"
            optionText="text"
            optionValue="value"
            choices={PropertySubTypes}
          />
          <SelectField
            flex={0.33}
            name="BedroomsTotal"
            label="Beds"
            choices={Array.apply(null, { length: 11 }).map((it, index) => ({
              _id: index,
              name: index,
            }))}
          />
          <SelectField
            flex={0.33}
            name="BathroomsFull"
            label="Baths"
            choices={Array.apply(null, { length: 11 }).map((it, index) => ({
              _id: index,
              name: index,
            }))}
          />
          <SelectField
            flex={0.33}
            name="GarageSpaces"
            label="Garage Spaces"
            choices={Array.apply(null, { length: 11 }).map((it, index) => ({
              _id: index,
              name: index,
            }))}
          />
          <InputNumber
            flex={0.33}
            min={0}
            name="NumberOfUnitsTotal"
            /* placeholder="Units" */
            label="Units"
          />
          <InputNumber
            flex={0.33}
            min={0}
            name="StoriesTotal"
            /* placeholder="Stories" */
            label="Stories"
          />
          <InputNumber
            flex={0.33}
            min={0}
            name="YearBuilt"
            /* placeholder="Year Built" */
            label="Year Built"
          />
          <Divider
            style={{
              margin: 0,
            }}
            flex={1}
            type="horizontal"
            dashed
          />
          {PropertyType === "Residential" && (
            <SelectField
              name="SpecialListingConditions"
              label="Special Listing Conditions"
              /* placeholder="Special Listing Conditions" */
              optionText="text"
              optionValue="value"
              choices={special_conditions}
            />
          )}
          <SelectField
            name="property_type_information"
            label="Property Information"
            /* placeholder="Property Information" */
            mode="multiple"
            choices={property_information.map((it, index) => ({
              name: it,
              _id: it,
            }))}
          />
          <Input.TextArea
            flex={1}
            label="Description"
            /* placeholder="Description" */
            name="LongDescription"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </SimpleForm>
      )}
    </WrapperForm>
  );
};
export default GeneralInformation;
