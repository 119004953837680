import React, { useEffect, useRef, useState } from "react";
import { WrapperDescription, MarkdownWrapper, WrapperMark } from "./Styles";
import { Button, Icon, Input, message } from "antd";
import { getService } from "../../services";
import { EditorMarkDown } from "../editor/EditorMarkDown";
import CodeMirror from "react-codemirror";
import { SimpleForm } from "../com/form/";
import { useLocation, useParams, navigate } from "@reach/router";
import { useSelector } from "react-redux";
import MarkDown from "../markdown/Markdown";
import { FiSend } from "react-icons/fi";
import { CgCloseO } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";
const { TextArea } = Input;

export const MarkDownEditor = ({
  title,
  actions = {},
  autoReset = false,
  code = false,
  author,
  onMentions,
  momentitem,
  placeholder,
  onEdit,
  icon,
  ...props
}) => {
  let MyRef = useRef(null);
  const location = useLocation();
  const params = useParams();
  const [value, setValue] = useState(props.initialValue || "");
  const [record, setRecord] = useState();
  const [raw_value, setRawValue] = useState();
  const [edit, setEdit] = useState(false);
  const user = useSelector(({ user }) => user);
  const handleOnChange = (e) => {
    let value = e;
    if (e && typeof e.target != "undefined") value = e.target.value;
    setValue(value);
    if (props.onChange) props.onChange(value);
    // console.log("Changed::::: ", value);
  };
  const handleOnRawTextChange = (value) => {
    console.log("Raw Value:: ", value);
    setRawValue(value);
  };
  const handleOnMentions = (mentions) => {
    if (onMentions) onMentions(mentions);
  };
  const isOwner = () => {
    return author && author._id === user._id;
  };
  useEffect(() => {
    if (props.value !== value) setValue(props.value);
  }, [props.value]);
  useEffect(() => {
    if (props.raw_value !== raw_value) setRawValue(props.raw_value);
  }, [props.raw_value]);
  useEffect(() => {
    let { name } = props;
    if (record && record[name]) setValue(record[name]);
  }, [record]);
  useEffect(() => {
    if (typeof props.record != "undefined") setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (typeof props.edit != "undefined") {
      setEdit(props.edit);
    }
  }, [props.edit]);
  return (
    <WrapperMark className="MarkDownEditor-descritionwidth: 100%;" edit={edit}>
      {!edit && (
        <MarkdownWrapper
          className="markdown-wrapper"
          onBlur={() => setEdit(false)}
          onClick={() => {
            if (onEdit) onEdit((edit) => !edit);
            setEdit(true);
          }}
        >
          {!value && (
            <div className="section-placeholder">
              <span>{placeholder}</span>
            </div>
          )}
          <MarkDown flex={1} source={value} />
        </MarkdownWrapper>
      )}
      {edit && (
        <div>
          <CodeMirror
            id="markdown"
            mode="markdown"
            theme="default"
            autoFocus={true}
            value={value}
            ref={(el) => (MyRef = el)}
            onChange={handleOnChange}
            closeOnBlur
            options={{
              mode: "markdown",
              readOnly: props.readOnly || false,
              theme: "monokai",
              lineNumbers: props.lineNumbers || false,
            }}
          />
        </div>
      )}
    </WrapperMark>
  );
};
const DescriptionText = ({
  reference,
  placeholder,
  allowClear,
  filterDefaultValues,
  on_description = true,
  task_id,
  ...props
}) => {
  const intl = useIntl();
  const { txt_boards } = intl.messages;
  const [record, setRecord] = useState();
  const [payloads, setPayloads] = useState({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const handleOnChange = (description) => {
    setPayloads({
      ...payloads,
      description,
    });
  };
  const handleView = () => {
    setEdit((edit) => !edit);
  };
  const handleOnSave = () => {
    if (!task_id) return message.warning("Tasks need a title to get started.");
    if (
      !payloads ||
      !payloads.description ||
      payloads.description.length === 0
    ) {
      return message.warning("Write a description");
    }
    const service = getService(reference);
    setEdit(false);
    setView(true);
    if (record._id) {
      service
        .patch(record._id, payloads)
        .then((resp) => {
          message.info("Descriptions Update");
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    } else {
      setLoading(true);
      service
        .create(payloads)
        .then((resp) => {
          message.info("Descriptions create");
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (
      record &&
      record.description &&
      record.description.length &&
      record.description.length > 0
    )
      setView(true);
  }, [record]);
  useEffect(() => {
    if (props.record) {
      setRecord(props.record);
    }
  }, [props.record]);
  useEffect(() => {
    if (props.task) {
      setRecord(props.task);
    }
  }, [props.task]);
  return (
    <WrapperDescription>
      <div className="container-description">
        {on_description && (
          <div className="section-title">
            <h3>
              <Icon type="align-right" />
              <span>Description</span>
            </h3>
            {!edit && (
              <Button type="link" onClick={handleView}>
                {/* {!view ? "Edit" : "Close"} */}
                Edit
              </Button>
            )}
          </div>
        )}
        <div className="">
          {(view || edit) && (
            <MarkDownEditor
              className="MarkDownEditor-descrition"
              code
              placeholder="Add Description"
              name="description"
              record={record}
              edit={edit}
              onEdit={(edit) => setEdit(edit)}
              autoReset={props.autoReset}
              onChange={(value) => handleOnChange(value)}
            />
          )}

          {edit && (
            <div className="footer-buttons-mark">
              <Button
                className="btn-primary-save"
                type="link"
                onClick={handleOnSave}
              >
                Save
                {/* <FiSend className="icon-description" /> */}
              </Button>
              <Button
                className="btn-cancel-markdown"
                type="link"
                onClick={() => {
                  setEdit(false);
                  setPayloads(null);
                }}
              >
                Cancel
                {/* <IoMdClose className="icon-description-close" /> */}
              </Button>
            </div>
          )}
        </div>
      </div>
    </WrapperDescription>
  );
};
export default DescriptionText;
