import React, { useEffect, useState } from "react";
import { WrapperAddMembers, WrapperListMember } from "./Styles";
import { SearchField } from "../com/form/";
import { getService } from "../../services";
import { message, List, Avatar, Button, Skeleton, Checkbox } from "antd";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
import { Height } from "@material-ui/icons";
import { isEqual } from "lodash";
const AddMembers = ({
  id,
  search = true,
  reference,
  referencePatch,
  height,
  onSubmitMember,
  onSubmit,
  checkView = false,
  user,
  ...props
}) => {
  const [search_value, setSearchValue] = useState();
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [dataSource, setDataSource] = useState();
  const [payloads, setPayloads] = useState();
  const [record, setRecord] = useState();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [all_clear, setAllClear] = useState(false);
  const [selectes, setSelectes] = useState([]);
  const [recordMembers, setRecordMembers] = useState([]);
  const handleSearch = (search) => {
    if (!search) {
      setFilterDefaultValues({
        status: "active",
        company_id: user && user.company_id,
      });
      // userDefault(record);
    } else {
      console.log("que es search:::::::::", search);
      let params = {};
      if (search && search !== null) params["search"] = search;
      if (user.company_id) params["company_id"] = user.company_id;
      setFilterDefaultValues({
        ...params,
        // filterDefaultValues,
        status: "active",
      });
    }
  };

  const handleOnClick = (item) => {
    let members_ids = selectes;
    let params = { company_id: user.company_id };
    if (!members_ids.includes(item._id)) {
      members_ids = [...members_ids, item._id];
    } else {
      members_ids = members_ids.filter((_id) => _id !== item._id);
    }
    params = {
      _id: {
        $in: [...members_ids],
      },
    };
    setSelectes(members_ids);
    // setFilterDefaultValues(params);
    if (onSubmitMember) return onSubmitMember(members_ids);
    // if (props.onSubmit) return props.onSubmit();
    if (id) {
      const service = getService(referencePatch);
      setSubmitting(true);
      console.log(";;::::::antes de :::::::::::::", members_ids, "respu");
      service
        .patch(id, {
          members_ids,
        })
        .then((resp) => {
          message.success("Members Updated Successfully!");
          setSubmitting(false);
          if (onSubmit) onSubmit(resp);
          console.log("::despues de :::::::::::", members_ids, "respu", resp);
        })
        .catch((err) => {
          message.error(err.message);
          setSubmitting(false);
        });
    }
  };
  const renderItem = (item) => {
    let isChecked = selectes && selectes.includes(item._id);
    return (
      <WrapperListMember>
        <List.Item key={item._id} className="list-item-member">
          <Skeleton
            avatar
            title={false}
            paragraph={{ rows: 1 }}
            loading={loading || submitting}
            active
          >
            <List.Item.Meta
              disabled={submitting}
              onClick={() => handleOnClick(item)}
              avatar={
                <div className="avatar-check">
                  <Avatar
                    src={
                      item && item.picture
                        ? `${URL_S3}/${item && item.picture}`
                        : URL_DEFAULT_AVATAR
                    }
                  />
                </div>
              }
              title={
                <div className="flex justify-between align-center">
                  <span>
                    {`${item && item.first_name} ${item && item.last_name}`}
                  </span>
                  {checkView && (
                    <Checkbox
                      checked={isChecked}
                      onChange={() => handleOnClick(item)}
                    />
                  )}
                </div>
              }
            />
          </Skeleton>
        </List.Item>
      </WrapperListMember>
    );
  };
  const getData = () => {
    const service = getService(reference);
    setLoading(true);
    service
      .find({
        query: {
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        const filterdata = data.filter((it) => it._id !== user._id); //quita el usuario que esta en sesion de la lista
        setDataSource(filterdata);
        setLoading(false);
        setInitialized(true);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    return () => {
      setRecord(null);
    };
  }, []);
  useEffect(() => {
    if (record && user && !initialized) {
      let params = { company_id: user.company_id, status: "active" };
      if (record.members_ids && record.members_ids.length > 0) {
        setSelectes(record["members_ids"]);
        params = {
          _id: {
            $in: [...record.members_ids],
          },
        };
      }
      setFilterDefaultValues(params);
    }
    setSelectes(record && record["members_ids"]);
  }, [record]);
  useEffect(() => {
    if (typeof filterDefaultValues != "undefined") getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (props.task) {
      setRecord(props.task);
    }
  }, [props.task]);
  useEffect(() => {
    if (props.filterDefaultValues) {
      setFilterDefaultValues(props.filterDefaultValues);
    }
  }, [props.filterDefaultValues]);

  return (
    <WrapperAddMembers>
      <div>
        {search && (
          <div className="container-autocomplete">
            <SearchField
              style={{ width: "284px" }}
              allowClear
              placeholder="Search Tags"
              onSearch={handleSearch}
              source="task-tags"
            />
          </div>
        )}
        <div className="container-list-member">
          <List
            className="section-list"
            style={{
              maxHeight: height,
              overflowY: "scroll",
            }}
            grid={{ gutter: 4, xs: 1, md: 1, xl: 1, lg: 1, xxl: 1 }}
            // loading={loading}
            itemLayout="horizontal"
            renderItem={renderItem}
            dataSource={dataSource}
          />
        </div>
      </div>
    </WrapperAddMembers>
  );
};
export default AddMembers;
