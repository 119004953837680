import styled from "styled-components";
export const WrapperForm = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  .head-title {
    margin: 10px 0px;
    background: #fff !important;
    h2 {
      color: var(--primary) !important;
      font-style: normal !important;
    }
  }
  & .section-mls {
    padding: 14px 0px !important;
    margin: 14px 0px !important;
  }
`;
export const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 2px 2px 3px #ccc;
  background: #fff;
  padding: 20px;
  margin: 10px;
  .steps-content {
    padding: 10px !important;
    min-height: 300px;
  }
  .ant-steps-item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  }
`;

export const WrapperProfile = styled.div`
  & .steps-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    & .button-nex {
      width: 94px !important;
    }
  }
`;
