import styled from "styled-components";
export const Wrapper = styled.div`

    &.my-gallery-draggable{
        min-height:${({ photosCount, size }) => {
          if (photosCount == 0) return "100px;";
          switch (size) {
            case "default":
              return "150px;";
              break;
            case "medium":
              return "400px;";
              break;
            case "large":
              return "600px;";
              break;
            case "small":
              return "400px;";
              break;
            default:
              return "150px;";
              break;
          }
        }}
    }
    & .react-photo-gallery--gallery img {
        max-width: ${({ photosCount }) =>
          photosCount > 1 ? "calc(100% / 2 - 4px);" : "calc(100% - 4px);"}
        max-height:${({ size, photosCount }) => {
          if (photosCount == 1) return "100%;";
          switch (size) {
            case "default":
              return "150px;";
              break;
            case "medium":
              return "400px;";
              break;
            case "large":
              return "600px;";
              break;
            case "small":
              return "100px;";
              break;
            default:
              return "150px;";
              break;
          }
        }}
    }
    & .react-photo-gallery--gallery .image-container {
        position:relative;
        min-height:100px!important;
        max-width: ${({ photosCount }) =>
          photosCount > 1
            ? "calc(100% / 2 - 4px)!important;"
            : "calc(100% - 4px)!important;"}
        max-height:${({ size, photosCount }) => {
          if (photosCount == 1) return "100%;";
          switch (size) {
            case "default":
              return "150px;";
              break;
            case "medium":
              return "400px;";
              break;
            case "large":
              return "600px;";
              break;
            case "small":
              return "100px;";
              break;
            default:
              return "150px;";
              break;
          }
        }}
    }
    & .tools{
        position:absolute;
        width:100%!important;
        z-index: 99!important;
        /* display: none!important; */
        justify-content:flex-end!important;
        align-items:center!important;
        background: transparent;
        border-bottom: 0px!important;
        padding: 0px;
        margin:0px;
        top:0px;
        left:0px;
        box-shadow: none!important;
        transition-property: all;
        transition-duration:.05s;
        opacity:.2;
    }
    & .react-photo-gallery--gallery .image-container:hover .tools{
        opacity:1;
        display:flex!important;
    }
    & .react-photo-gallery--gallery .image-container img{
        max-width:calc(100% - 4px)!important;
        margin-bottom:4px;
    }
    & .image {
        position: relative;
    }
    & .more-photos {
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        color: #FFF;
        line-height: 0px;
    }
    & .more-photos h3, .more-photos span{
        font-size:24px!important;
        color: #FFF!important;
        text-align: center;
        /* margin-bottom: 0px!important; */
    }
    & .more-photos h3{
        font-size:44px!important;
        margin-bottom: 0px!important;
    }
`;
export const Tools = styled.div``;
export const ItemWrapper = styled.div``;
export const PhotoWrapper = styled.div`
  cursor: pointer;
`;
