import React, { Component, useEffect, useState } from "react";
import S3Uploader from "react-s3-uploader";
import { Button, Dropdown, Icon, Menu, Modal, Progress, message } from "antd";
import styled from "styled-components";
import uuid from "react-uuid";
import * as viewers from "../../fileviewer/viewers/index";
import {
  IMAGE_BANNER,
  URL_DEFAULT_AVATAR,
  URL_S3,
  URL_S3_SERVER,
} from "../../../constants";
const Logo = require("../../../sources/images/avatar.svg");
const Wrapper = styled.label`
  overflow: hidden;
  height: 100px !important;
  width: 100px;
  display: block;
  border-radius: 50%;
  background-color: #fff !important;
  padding: 20px 30px;
  color: #fff;
  font-size: 34px;
  object-fit: contain;
  /* position: absolute; */
  margin: 0px auto;
  text-align: center;
  /* left: calc(45% - 25px); */
  top: 10px;
  border: 2px solid #e6f7ff;

  background-size: cover !important;

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  /* transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */

  i {
    z-index: 2000;
    /* background: #0dacfb; */
    padding: 10px;
    border-radius: 50%;

    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    display: none;
    font-size: 24px !important;
    color: #fff !important;
    width: 60px;
    height: 60px;
    position: absolute;
    left: 20px;
    top: 20px;
    padding: 15px;
  }
  .action-container {
    position: absolute;
    right: 34px;
    top: -24px;
    i {
      width: 30px;
      height: 30px;
      padding: 4px;
      background: transparent !important;
      color: #dc3545 !important;
      font-size: 20px !important;
      right: 15px;
      top: 15px;
    }
  }
  & .section-title {
    color: #565454;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &.flat,
  &.video {
    position: relative;
    .anticon {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 9999;
      width: 100%;
      background: #0000001c;
      min-height: 100%;
      display: flex;
      border-radius: 0px;
      justify-content: center;
      align-items: center;
    }

    & > div {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      div {
        /*  height: 100%;
        width: 100%; */
      }
    }
    img {
      // object-fit: cover !important;
      object-position: center center;
    }
  }
  &.video {
    .anticon {
      display: none !important;
    }
  }
  &.rounded {
    border-radius: 50% !important;
  }
`;

const Container = styled.div`
  // width: 100%;
  margin: 10px !important;
  display: block;
  position: relative !important;

  & .action-container {
    position: absolute;
    display: none !important;
    top: -15px;
    right: -15px;
    .ant-dropdown-trigger {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  &:hover,
  :active,
  :focus {
    .action-container {
      display: block !important;
    }
  }

  .ant-progress {
    /*  position: absolute !important;
    left: 0px !important;
    top: 0px !important; */
  }
  .s3Button {
    display: none;
  }
  .ant-progress-circle .ant-progress-text {
    color: #fff !important;
    z-index: 1 !important;
    font-weight: 600;
  }
  span.ant-progress-text::before {
    content: " ";
    height: 100px;
    width: 100px;
    background: rgb(0 0 0 / 25%);
    position: absolute;
    left: 0px;
    top: -40px;
    z-index: -1;
  }
  & .content:hover i {
    display: block;
  }

  & .wrapper::before {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  & .content {
    position: relative !important;
  }
  & .content:hover .wrapper::before {
    content: " ";
    height: 100%;
    width: 100%;
    background: var(--primary);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    opacity: ${({ disabled }) => (disabled ? 0 : 0.5)};
    padding: 10px !important;
    border: 0px;
  }
  .ant-progress-circle .ant-progress-inner {
    margin: 10px !important;
    ${({ image }) =>
      `background: url(${image ? URL_S3 + "/" + image : URL_DEFAULT_AVATAR});
      background-size: contain;
      `}
  }
`;
const MenuAction = ({ onItemClick, ...props }) => (
  <Menu onClick={onItemClick}>
    <Menu.Item key="remove">
      <span>
        <Icon size="large" type="close" /> Remove
      </span>
    </Menu.Item>
  </Menu>
);
class S3Button extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    progress: null,
    image: null,
    uploading: false,
    // title: null,
    idComponent: uuid(),
    remove: false,
    onChange: null,
    playing: false,
    itemType: "rounded",
  };

  componentWillReceiveProps({
    record,
    source,
    name,
    value,
    title,
    icon,
    initial,
    defaultImage,
    className,
    itemType,
    controls,
    ...props
  }) {
    let image = value || (record && record[name || source]);
    //if (record) image = record && record[name || source];
    this.setState({
      image: image || initial,
      itemType,
      record,
    });
  }
  componentDidMount() {
    let { record, source, name, remove, initial, value } = this.props;
    let image = value || record ? record[name || source] : null;
    this.setState({
      image: image || initial,
      remove,
      record,
      id: this.props.id || uuid(),
    });
  }
  onUploadStart = (file, next) => {
    /*  let { name, allowTypes, allowAll } = this.props;
    allowTypes = allowTypes || ["application/pdf"];
    allowAll = typeof allowAll != "undefined" ? allowAll : true;
    if (allowTypes.indexOf(file.type) === -1 && !allowAll)
      return message.error("File type is not allowed!"); */
    this.setState({ name_file: file.name, uploading: true });
    next(file);
  };

  onSignedUrl = (...props) => {};

  onUploadProgress = (progress, ...props) => {
    this.setState({ progress });
  };

  onUploadError = (error) => {
    this.setState({
      uploading: false,
    });
  };
  renderItem = (image) => {
    const type = image.substring(image.lastIndexOf(".") + 1, image.length);
    let viewer = viewers[type] || viewers["pdf"];
    console.log("ue es viewer:::::::::::::::::", viewer, type);
    // if (this.props.renderItem) return this.props.renderItem(image);

    return (
      <>
        <div>
          {React.createElement(viewer, {
            playing: this.state.playing,
            path: image,
            controls: this.controls,
            onPlay: () =>
              this.setState({ ...this.state, playing: !this.state.playing }),
          })}
        </div>
      </>
    );
  };
  onUploadFinish = (urls) => {
    let { onChange, id } = this.props;
    let image = urls.fileKey;
    this.setState({
      uploading: false,
      image,
    });
    if (onChange) onChange(image, id);
  };
  handleOnClick = ({ key }) => {
    let { onRemove, onChange, id } = this.props;
    switch (key) {
      case "remove":
        if (onRemove)
          return Modal.confirm({
            content: `Do you want to remove picture?`,
            onOk: onRemove,
          });
        break;
      default:
        break;
    }
  };

  render() {
    let { file = {}, match, label, source } = this.props;
    const { image, id, uploading, progress, record, itemType } = this.state;

    if (file.name) return <div className="s3Button mr-2">{file.name}</div>;
    return (
      <Container
        disabled={this.props.disabled}
        image={image}
        className={`mr-2 ${this.props.className}`}
      >
        <div className={`content ${itemType}`}>
          {uploading && (
            <Progress
              status="active"
              type={itemType === "rounded" ? "circle" : "line"}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              width={100}
              percent={100}
            />
          )}

          {!uploading && (
            <Wrapper
              htmlFor={this.state.idComponent}
              className={`wrapper ${itemType}`}
              variant="outlined"
              disabled={this.props.disabled}
              color="primary"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover!important",
                background:
                  itemType === "rounded" &&
                  `url(${
                    image
                      ? URL_S3 + "/" + image
                      : this.props.defaultImage
                      ? this.props.defaultImage
                      : " "
                  })`,
              }}
            >
              {!uploading && !this.props.disabled && (
                <Icon size="large" type="edit" className="section-edit" />
              )}
              {this.props.title && !image && (
                <div className="section-title">{this.props.title}</div>
              )}
              {image &&
                typeof image !== "undefined" &&
                itemType !== "rounded" &&
                this.renderItem(image)}
            </Wrapper>
          )}
        </div>
        <label className="s3Button">
          <S3Uploader
            id={this.state.idComponent}
            signingUrl="/s3Client/sign"
            signingUrlMethod="GET"
            accept={this.props.allowTypes || "*/*"}
            disabled={this.props.disabled}
            s3path={`${this.props.path}/${id}/${this.props.finalPath}/`}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            server={URL_S3_SERVER}
            // inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            className="s3-uploader"
            style={{ visibility: "hidden" }}
          />
        </label>
        {this.state.remove && image && (
          <div className="action-container">
            <Dropdown
              overlayClassName="menu-actions"
              overlay={<MenuAction onItemClick={this.handleOnClick} />}
              trigger={["hover"]}
            >
              <Button className="section-delete" size="large" type="link">
                <Icon type="caret-down" />
              </Button>
            </Dropdown>
          </div>
        )}
      </Container>
    );
  }
}
const FileUpload = ({
  className,
  title,
  icon,
  defaultImage,
  controls,
  ...props
}) => {
  const [record, setRecord] = useState();
  const [value, setValue] = useState();
  const handleOnRemove = () => {
    let { onChange, onRemove, id } = props;
    setValue(null);
    if (onRemove) return onRemove("", id);
    if (onChange) onChange("", id);
  };
  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);
  useEffect(() => {
    if (props.record) {
      setRecord(props.record);
    }
  }, [props.record]);
  useEffect(() => {
    let { source, name } = props;
    if (record) {
      let value = record[source || name];
      if (value) {
        setValue(value);
      }
    }
  }, [record]);
  /* useEffect(() => {
  }, [value]); */
  return (
    <div className={`file-upload ${className}`}>
      <S3Button
        {...props}
        value={value}
        initial={value}
        title={title}
        icon={icon}
        defaultImage={defaultImage}
        controls={controls}
        onRemove={handleOnRemove}
      />
    </div>
  );
};
export default FileUpload;
