import React, { useState, useEffect } from "react";
import { SimpleForm } from "./";
import { Steps, Button, message } from "antd";
import { WrapperFormSteps } from "./Styles";
import _ from "lodash";
import { getService } from "../../../services";
const { Step } = Steps;
const FormSteps = ({
  children,
  onStep,
  onDone,
  title,
  onSubmit,
  source,
  ...props
}) => {
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState(1);
  const [form, setForm] = useState();
  const [valid, setValid] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [payloads, setPayloads] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [isDone, setIsDone] = useState(false);
  const next = async () => {
    try {
      await isValid();
      setCurrent((current) => current + 1);
    } catch (err) {}
  };
  const prev = async () => {
    try {
      /* await isValid(); */
      setCurrent((current) => current - 1);
    } catch (err) {}
  };
  const handleOnChange = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const handleOnSubmit = () => {
    if (source) {
      /* if (onSubmit) return onSubmit(); */
      const service = getService(source);
      setSubmitting(true);
      console.log("payloads: ", payloads);
      service
        .create(payloads)
        .then((res) => {
          setSubmitting(false);
          if (onSubmit) onSubmit(res);
        })
        .catch((err) => {
          setSubmitting(false);
          message.error(err.message);
        });
    }
  };
  const isValid = async () => {
    return new Promise((resolve, reject) => {
      form.validateFields((errors, values) => {
        setValid(!errors);
        if (!errors) {
          resolve();
        } else {
          reject(errors);
        }
      });
    });
  };
  useEffect(() => {
    if (children) setSteps(React.Children.toArray(children));
  }, [children]);
  useEffect(() => {
    if (!_.isEqual(props.defaultValues, defaultValues))
      setDefaultValues(props.defaultValues);
  }, [props.defaultValues]);
  useEffect(() => {
    if (defaultValues) {
      setPayloads({
        ...payloads,
        ...defaultValues,
      });
    }
  }, [defaultValues]);
  useEffect(() => {
    setIsDone(current === steps.length - 1);
    if (onStep)
      onStep(current, current === steps.length - 1, steps.length, steps);
  }, [current]);
  useEffect(() => {
    if (isDone && onDone) onDone();
  }, [isDone]);
  if (steps.length == 0) return "No Steps";
  return (
    <WrapperFormSteps>
      {title && <h2>{title}</h2>}
      <Steps
        current={current}
        onChange={async (step) => {
          try {
            if (step > current) await isValid();
            setCurrent(step);
          } catch (err) {}
        }}
        {...props.options}
      >
        {React.Children.map(
          children,
          (child, index) => child && <Step key={index} {...child.props} />
        )}
      </Steps>
      <SimpleForm
        onChange={handleOnChange}
        autoSubmit={false}
        onMount={(form) => setForm(form)}
        footer={
          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button
                /* disabled={!valid} */
                type="primary"
                onClick={() => next()}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                loading={submitting}
                type="primary"
                onClick={handleOnSubmit}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        }
      >
        {steps[current] &&
          steps[current].props &&
          React.Children.count(steps[current].props.children) > 0 &&
          React.Children.map(steps[current].props.children, (child) => {
            if (child && child.props) {
              let { name, source, target } = child.props;
              return React.cloneElement(child, {
                initial: payloads[name || source],
              });
            }
          })}
      </SimpleForm>
    </WrapperFormSteps>
  );
};

export default FormSteps;
