import React from "react";
import { Wrapper } from "./Styles";

const Canva = (props) => {
  // const { topbar } = intl.messages;
  return (
    <Wrapper>
      <div className="container">
        canva
        <div>
          <h2>Hola</h2>
        </div>
      </div>
    </Wrapper>
  );
};
export default Canva;
