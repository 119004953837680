import { Button, List, message } from "antd";
import React, { useEffect, useState } from "react";
import { getService } from "../../services";
import { WrapperTagCategory } from "./Styles";
const { Item } = List;
const TagsCategoryBoard = ({ reference, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [dataSource, setDataSource] = useState();
  const [record, setRecord] = useState();

  const handleClick = (item) => {
    /*  setCategory(item);
    console.log(category); */
    if (props.handleClickItem) props.handleClickItem(item);
  };
  const renderItem = (item) => {
    return (
      <WrapperTagCategory>
        <Item>
          <Button
            onClick={() => handleClick(item)}
            className="button-tag"
            size="large"
          >
            {item.name}
          </Button>
        </Item>
      </WrapperTagCategory>
    );
  };

  const getData = () => {
    const service = getService(reference);
    service
      .get({})
      .then(() => {
        setLoading(false);
        setDataSource();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };
  /*  useEffect(() => {
    getData();
  }, []); */
  useEffect(() => {
    if (props.record) {
      setRecord(props.record);
      // getData()
    }
  }, [props.record]);
  return (
    <>
      <List
        style={{
          maxHeight: 450,
          overflowY: "auto",
          padding: "18px",
        }}
        grid={{ gutter: 8, xs: 1, md: 2, xl: 4, lg: 3, xxl: 4 }}
        loading={loading}
        renderItem={renderItem}
        dataSource={record && record.categories}
      />
    </>
  );
};
export default TagsCategoryBoard;
