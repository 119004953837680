import { Input, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import { SimpleForm } from "../../components/com/form/";
import { getService } from "../../services";
import { Box } from "../com";
import { Wrapper } from "./Styles";
const SubmitButton = ({ onSubmit, id, form, ...props }) => {
  const handleSubmit = () => {
    if (form) {
      form.validateFields((errors, values) => {
        if (!errors) onSubmit(errors, values);
      });
    }
  };
  return (
    <>
      <Button
        type="primary"
        /* className="btn-green" */
        size="large"
        onClick={() => handleSubmit()}
      >
        {id ? "Update Social Networks" : "Save Social Networks"}
      </Button>
    </>
  );
};
const SocialNetworksForm = ({ source = "contacts", contact, ...props }) => {
  const service = getService(source);
  const [record, setRecord] = useState();
  const [contact_id, setContactId] = useState();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const save = (data) => {
    const service = getService(source);
    setSubmitting(true);
    if (contact_id) {
      service
        .patch(contact_id, data)
        .then((res) => {
          message.success("Update");
          setSubmitting(false);
          setSubmitted(true);
          getData();
        })
        .catch((err) => {
          message.error(err.message);
          setSubmitting(false);
        });
    } else if (!contact_id) {
      service
        .create(data)
        .then((res) => {
          message.success("Social network created");
          setSubmitting(false);
        })
        .catch((err) => {
          message.error(err.message);
          setSubmitting(false);
        });
    }
  };
  const handleChange = (field, value, form) => {
    let data = { [field]: value };
    setRecord({
      ...record,
      [field]: value,
    });
    save(data);
  };
  const handleSubmit = (err, data) => {
    setLoading(true);
    if (contact_id)
      return service
        .patch(contact_id, data)
        .then((record) => {
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.message);
        });
    service
      .create(data)
      .then((record) => {
        setContactId(record._id);
        service
          .patch(contact._id, {
            contact_id: record._id,
          })
          .then((record) => {
            setLoading(false);
          })
          .catch((err) => {
            message.error(err.message);
          });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const getData = () => {
    setLoading(true);
    service
      .get(contact_id)
      .then((record) => {
        setRecord(record);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  useEffect(() => {
    if (contact_id && contact_id !== "create") {
      getData();
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (props.id) setContactId(props.id);
  }, [props.id]);
  if (loading) return "Loading...";
  return (
    <>
      <SimpleForm
        id={contact_id}
        initialValues={record}
        onSubmit={handleSubmit}
        onChange={handleChange}
        autoSubmit={false}
        delay={3000}
        source={source}
        onMount={(form) => setForm(form)}
        /*  footer={
          <>
            <SubmitButton id={contact_id} form={form} onSubmit={handleSubmit} />
          </>
        } */
      >
        <Input
          name="website"
          grid={{
            xxl: 12,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24,
          }}
          label="Website"
          placeholder="Website"
        />
        <Input
          name="facebook"
          grid={{
            xxl: 12,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24,
          }}
          label="Facebook"
          placeholder="Facebook"
        />
        <Input
          name="twitter"
          grid={{
            xxl: 12,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24,
          }}
          label="Twitter"
          placeholder="Twitter"
        />
        <Input
          name="lindken"
          grid={{
            xxl: 12,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24,
          }}
          label="Lindken"
          placeholder="Lindken"
        />
      </SimpleForm>
    </>
  );
};
export default SocialNetworksForm;
