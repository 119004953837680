import styled from "styled-components";
import { Tag } from "antd";
export const WrapperBackground = styled.div`
& .wrapper {
  width: 100% !important;
  border-radius: 18px;
  background-color: #fff !important;
  border: 2px dashed #d9cfcf;
}
& .video {
  height: 214px !important;
}
& .picture-container{
  display:flex
  justify-content: center;
  align-items: center;
  width: 100%!important;
}
& .s3-logo {
  .wrapper{
    background-repeat: no-repeat!important;
    background-size: contain!important;
    background-position: center!important;
  }
}
& .s3-image{
  .wrapper{
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center!important;
  }
}

& .content:hover .wrapper::before {
  content: " ";
  height: 0px!important;
  width: 100%;
  background: none!important;
  position: relative!important;
  left: 0;
  top: 0;
  border-radius: 0px!important;
  opacity: 0!important;
  padding: 0px !important;
  border: 0px;
}
& .container-title-s3{
  .title{
    line-height: 10px;
    span{
      font-size: 14px!important;
      color: #cbc3c3;!important;
    }

  }
 .s3-icon{
  .icon{
    font-size: 32px!important;
    color: #cbc3c3;!important;
  }
 }
}
& .action-container {
  position: absolute;
  top: 4px!important;
  right: -5px!important 
}
& .content:hover i {
  display: none!important;
}
& .content.flat:hover i {
  display: flex!important;
}
& .content.flat i {
  display: none!important;
}

`;
export const TagWrapper = styled.div`
  cursor: pointer;
  border-radius: 10px !important;
  line-height: 8px !important;
  font-size: 14px !important;
  text-transform: none !important;

  font-family: "HK Grotesk";
  padding: 6px 0px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  & .ant-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & .ant-tag::after {
    content: none !important;
  }
  &.completed .ant-tag {
    background: var(--teal) !important;
    border: var(--teal) !important;
    color: #fff !important;
  }
  &.accepted .ant-tag {
    background-color: var(--board-color-blue) !important;
    border-color: var(--board-color-blue) !important;
    color: #fff !important;
  }
  &.pending .ant-tag {
    background-color: var(--yellow) !important;
    border-color: var(--yellow) !important;
    color: #fff !important;
  }

  &.new .ant-tag {
    background-color: var(--teal) !important;
    border-color: var(--teal) !important;
    color: #fff !important;
  }
  &.hold .ant-tag {
    background-color: #d87d35 !important;
    border-color: #d87d35 !important;
    color: #fff !important;
  }

  &.assigned .ant-tag {
    background-color: var(--bg-color-pink) !important;
    border-color: var(--border-color-pink) !important;
    color: var(--text-color-pink) !important;
  }
  &.follow_up .ant-tag {
    background-color: #d44b42 !important;
    border-color: #d44b42 !important;
    color: #ffffff !important;
  }

  &.in_process .ant-tag {
    background-color: #313f72 !important;
    border-color: #313f72 !important;
    color: #fff !important;
  }
  &.pre_qualification .ant-tag {
    background-color: #6f63f4 !important;
    border-color: #6f63f4 !important;
    color: #fff !important;
  }
  &.converted .ant-tag {
    background-color: #bc7809 !important;
    border-color: #bc7809 !important;
    color: #fff !important;
  }
  &.recycled .ant-tag {
    background-color: #a295a7 !important;
    border-color: #a295a7 !important;
    color: #fff !important;
  }
  &.dead .ant-tag {
    background-color: #4094a1 !important;
    border-color: #4094a1 !important;
    color: #fff !important;
  }

  &.quality .ant-tag,
  &.medium .ant-tag {
    background-color: #af7708 !important;
    border-color: #af7708 !important;
    color: #fff !important;
  }

  &.poorquality .ant-tag {
    background-color: var(--gray-dark) !important;
    border-color: var(--gray-dark) !important;
    color: #fff !important;
  }
  &.cold .ant-tag,
  &.low .ant-tag {
    background-color: var(--board-color-blue) !important;
    border-color: var(--board-color-blue) !important;
    color: #fff !important;
  }
  &.hot .ant-tag {
    background-color: var(--danger) !important;
    border-color: var(--danger) !important;
    color: #fff !important;
  }
  &.warm .ant-tag,
  &.high .ant-tag {
    background-color: var(--color-orange) !important;
    border-color: var(--color-orange) !important;
    color: #fff !important;
  }

  &.poor .ant-tag,
  &.archived ant-tag {
    background-color: var(--gray-dark-1) !important;
    border-color: var(--gray-dark-1) !important;
    color: #fff !important;
  }

  &.inactive .ant-tag {
    background-color: var(--danger) !important;
    border-color: var(--danger) !important;
    color: #fff !important;
  }
`;
export const WrapperFileField = styled.div``;
export const WrapperJSONField = styled.div`
  & > ul.list-container {
    background: #e3e3e3;
    padding: 8px;
  }
  & ul.list-container {
    background: #f3f3f3;
    padding: 8px;
    border: 1px solid #f0f0f0;
  }
  & .field-container {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    align-items: center;
  }
  & .item-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background: #fbfbfb;
    box-shadow: 2px 2px 3px #cccccc45;
    margin: 4px 0px;
    padding: 4px;
    border: 1px solid #e9e6e6;
    border-radius: 4px;
  }
  & .handle-drag:hover {
    background: #f2f0f0 !important;
  }
  & .handle-drag {
    padding: 4px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: bold;
    border-radius: 4px;
    color: #9e9e9e;
    background: transparent !important;
  }
  & .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
  }
  & .list-title h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 16px;
    margin-bottom: 0px;
    color: var(--color-primary);
  }
  & .list-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background: #e3e3e3;
    border-bottom: 2px solid #e3e3e3;
    border-radius: 4px 4px 0px 0px;
    max-height: 40px;
    color: var(--color-primary);
    font-size: 16px;
  }
  & .drag-and-drop-item .ant-select {
    min-width: 100px !important;
  }
  & .drag-and-drop-item .item-form {
    padding: 0px 4px !important;
  }
  & .drag-and-drop-item .item-form .ant-form-item {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  & .drag-and-drop-item .form-fields {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  & .drag-and-drop-item .footer-advanced-form {
    display: none !important;
  }
`;
export const WrapperCodeEditor = styled.div``;
export const StatusWrapper = styled.div`
  /* Select */
  font-family: "Poppins" !important;
  & .ant-select-selection--single {
    height: 24px;
    cursor: pointer;
    border-radius: 30px;
    line-height: 24px !important;
  }
  & .ant-select-selection__rendered {
    line-height: 22px !important;
  }
  & .ant-tag {
    font-family: "Poppins" !important;
    font-weight: 100 !important;
    text-transform: capitalize !important;
    width: 100% !important;
  }
  & .ant-select ant-select-enabled {
    min-width: fit-content !important;
  }
  & .status-field:hover {
    .action {
      display: block !important;
    }
  }
  & .status-field {
    .ant-tag {
      /* min-width: 100px !important; */
    }
    .action {
      display: none !important;
    }
  }
`;
export const WrapperPhoneFiel = styled.div`
  & .container-code-input {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  & .container-code-input,
  .section-inputnumber {
    background: rgb(229, 236, 238) !important;
    border-radius: 12px !important;
    height: 36px;
  }
  & .ant-select-selection--single {
    position: relative;
    height: 34px !important;
  }
  & .section-inputnumber {
    height: 36px !important;
    font-size: 14px !important;
    display: contents !important;
  }
  & .container-select {
    width: 74px !important;
  }
  & .ant-input-group-addon {
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: rgb(229, 236, 238) !important;
    border: 0px solid #d9d9d9;
    border-radius: 12px !important;
  }
  span.ant-input-wrapper.ant-input-group {
    background: rgb(229, 236, 238) !important;
    border-radius: 12px !important;
  }
  & .ant-select-selection__rendered {
    margin-left: 4px !important;
    margin-right: 2px !important;
  }
  & .container-autocomplete {
    width: 100% !important;
  }
`;
export const SelectGroupWrapper = styled.div`
  & .section-title {
    padding: 6px 10px;
    span {
      font-size: 12px;
      font-weight: 700;
      color: var(--color-text);
      text-overflow: ellipsis;
    }
    .anticon svg {
      font-size: 18px;
    }
  }
  & .items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;

    & .item-conatiner {
      position: relative;
      margin: 0px -4px;
      cursor: pointer;
      & .ant-avatar {
        width: 34px !important;
        height: 34px !important;
        line-height: 34px !important;
      }
      .item-remove {
        display: none;
        position: absolute;
        right: -2px;
        top: 0px;
        z-index: 9999;
        .ant-btn:hover {
          color: #000;
          background: #fff;
        }
        .ant-btn {
          border-radius: 50%;
          background: #000;
          color: #fff;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15px;
          height: 15px;
          font-size: 10px;
        }
      }
    }
    & .item-conatiner:hover {
      .item-remove {
        display: block !important;
      }
    }
  }
  & .add-item-avatar {
    width: 26px !important;
  }
`;
