import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import styled from "styled-components";
import {
  LogoContainer,
  SiderContainer,
  SideBar,
  Wrapperlayout,
  WrapperlayoutBasic,
  Copy,
} from "./Styles";
import { LOGO_CLOSERX, BACKGROUND_LINEAL } from "../constants";
import { navigate, Router, Link } from "@reach/router";
import { Footer } from "@bootstrap-styled/v4";
import { TopBar } from "../components/header";
import { useSelector } from "react-redux";
/* const Logo = require("../sources/images/LogoBlack.svg"); */
const { Header, Sider, Content } = Layout;
const Container = styled.div`
  min-height: 100vh;

  & .container-img {
    padding: 2px;
    margin-top: 0px;
    padding-top: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 999999;
  }
  & .container-title-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .container-title-img img {
    height: 80px;
    width: 105px;
  }

  & .container-childdren {
    position: relative;
    z-index: 999999;
    background: transparent;
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .container-background::before {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("/images/Grupobackground.svg");
    background-size: contain;
    background-position: center center;
  }
`;

const BasicLayout = ({ children, showLogo = true, ...props }) => {
  const theme = useSelector(({ theme }) => theme);
  const [theme_color, setTheme] = useState({});

  useEffect(() => {
    if (theme) setTheme(theme);
    return () => {};
  }, [theme]);
  return (
    <WrapperlayoutBasic
      className="main-layout"
      style={{ minHeight: "100vh" }}
      {...theme_color}
    >
      <Layout
        style={{
          background: "#f3f3f3",
        }}
      >
        <Row>
          {showLogo && (
            <Col span={3}>
              <div className="logo-container-basic">
                <Link to="/" className="logo">
                  <div className="container-logo">
                    <div
                      style={{
                        background: `url(/images/LogoBlack.svg)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 100%",
                        height: 52,
                        // width: 120,
                      }}
                    />
                  </div>
                </Link>
              </div>
            </Col>
          )}
          <Col span={21}>
            <TopBar
              theme={theme}
              title={"Welcome to ClosRx"}
              {...theme_color}
              {...props}
            />
          </Col>
        </Row>
        <Content
          style={{
            padding: "16px 16px 0",
            background: theme_color.background || "#f3f3f3",
            minHeight: 280,
          }}
        >
          <div>
            <Row
              className="flex item-center justify-center"
              justify="center"
              align="middle"
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: "100vh",
              }}
              gutter={4}
            >
              <Col xxl={14} xl={16} lg={20} md={22} sm={22} xs={24}>
                {children}
              </Col>
            </Row>
          </div>
        </Content>
        <Footer>
          <Copy>GoOnewWorld © 2020</Copy>
        </Footer>
      </Layout>
    </WrapperlayoutBasic>
  );
};

export default BasicLayout;
