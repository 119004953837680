import CenterLayout from "./CenterLayout";
import LeftLayout from "./LeftLayout";
import RightLayout from "./RightLayout";

export {
  CenterLayout,
  LeftLayout,
  RightLayout,
  CenterLayout as centerlayout,
  LeftLayout as leftlayout,
  RightLayout as rightlayout,
};
