import React, { useState, useEffect } from 'react';
import { Menu, Icon, Button, Row, Col } from 'antd';
import { MenuWrapper } from "./Styles";
import qs from "qs";
import { getService } from '../../services';
import { navigate, Link } from '@reach/router';
const { SubMenu } = Menu;
const SideMenu = ({ onNew, teams = [], id, ...props }) => {
    const [current, setCurrent] = useState(id ? "team_id" : 'board');
    const [open_key, setOpenkey] = useState(id ? "team_id" : "");
    const [initialized, setInitialized] = useState(false);
    const handleClick = ({ key, item }) => {
        let { props } = item;
        let { name } = props;
        setCurrent(key);
        /* if (onMenuItemChange) {
            onMenuItemChange(key, name);
        } */
    }
    /* const onMenuItemChange = (key, name) => {
        navigate(`/dashboard/boards?${qs.stringify({
            [name]: key
        })}`);
    } */
    useEffect(() => {
        if (props.location) {
            let { search } = props.location;
            if (!initialized) {
                search = qs.parse(search.replace(/\?/, ""));
                let current = Object.values(search)[0];
                let key_open = Object.keys(search)[0];
                if (current) {
                    setCurrent(current);
                    setOpenkey(key_open);
                } else {
                    if (id)
                        setCurrent(id);
                }
                setInitialized(true);
            }
        }
    }, []);
    //if (!initialized) return null;
    return (<MenuWrapper>
        <Menu
            onClick={handleClick}
            style={{ width: "100%" }}
            defaultSelectedKeys={[current]}
            overflowedIndicator={null}
            collapsed={false}
            defaultOpenKeys={[open_key]}
            selectedKeys={current}
            mode="inline"
        >
            <Menu.Item name="board_type" key="board">
                <span>
                    <Link to={`/dashboard/boards?${qs.stringify({
                        board_type: "board"
                    })}`}>
                        <Icon type="layout" />
                        <span>Boards</span>
                    </Link>
                </span>
            </Menu.Item>
            <Menu.Item name="board_type" key="template">
                <span>
                    <Link to={`/dashboard/boards?${qs.stringify({
                        board_type: "template"
                    })}`}>
                        <Icon type="appstore" />
                        <span>Templates</span>
                    </Link>
                </span>
            </Menu.Item>
            <SubMenu
                collapsed={false}
                key="team_id"
                onTitleClick={({ domEvent }) => {
                    domEvent.preventDefault();
                    return false
                }}
                title={
                    <span>
                        <Row type="flex" justify="space-between">
                            <Col>
                                <Icon type="team" />
                                <span>Teams</span></Col>
                            <Col>
                                <Button
                                    className="btn-add"
                                    icon="plus"
                                    size="small"
                                    type="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onNew("team");
                                    }} />
                            </Col>
                        </Row>
                    </span>
                }
            >
                {teams.map(item => {
                    return (<Menu.Item name="team_id" key={item._id}>
                        <span>
                            <Link to={`/dashboard/teams/${item._id}`}>
                                <Icon type="number" />
                                {item.name}
                            </Link>
                        </span>
                    </Menu.Item>)
                })}
                <Menu.Divider />
            </SubMenu>

        </Menu>
    </MenuWrapper>
    )
}
export default SideMenu;