import React, { useState, useEffect, useRef } from "react";
import { Button, message } from "antd";
import { URL_S3 } from "../../../constants";
import { FaRegCirclePlay } from "react-icons/fa6";
import { VideoWrapper } from "../Styles";
import { FaRegPauseCircle } from "react-icons/fa";
const Video = ({ _id, path, name, allowTypes, controls, ...props }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  return (
    <VideoWrapper>
      <div>
        <video ref={videoRef} controls width="64%" height="auto">
          <source src={`${URL_S3}/${path}`} controls type={allowTypes} />
          Your browser does not support the video tag.
        </video>
        <div className="btn-play">
          <div className="btn">
            <Button onClick={togglePlayPause} type="link">
              {isPlaying ? (
                <FaRegPauseCircle className="icon" />
              ) : (
                <FaRegCirclePlay className="icon" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </VideoWrapper>
  );
};
export default Video;
