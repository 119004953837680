import React, { useEffect, useState } from "react";
import { Button, Input, message } from "antd";
import { WrapperCheckTask } from "./Styles";
import { SimpleForm } from "../com/form/";
import { getService } from "../../services";
const CheckTaskList = ({ reference, ...props }) => {
  const [task, setTask] = useState();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState();

  const handleOnChange = (field, value) => {
    // setChanged(true);
    console.log("value ssubtak", field, value);
  };
  const onSave = (values) => {
    const service = getService(reference);
    setLoading(true);
    service
      .create(values)
      .then((resp) => {
        message.success("Sub task created");
        setLoading(false);
        if (props.onSubmit) props.onSubmit(resp);
        form.resetFields();
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };
  const handleOnClick = () => {
    if (form) {
      form.validateFields((errors, values) => {
        if (!errors) {
          onSave(values);
        }
      });
    }
  };
  useEffect(() => {
    if (props.task) setTask(props.task);
  }, [props.task]);
  return (
    <WrapperCheckTask>
      <SimpleForm
        // source="tasks"
        onMount={(form) => setForm(form)}
        autoSubmit={false}
        onChange={handleOnChange}
        footer={
          <div className="container-footer">
            <Button
              loading={loading}
              type="primary"
              className="btn-add"
              onClick={handleOnClick}
              size="large"
            >
              Add
            </Button>
          </div>
        }
      >
        <Input
          name="task_id"
          // value={task._id}
          initial={task && task._id}
          type="hidden"
        />
        <Input
          flex={1}
          // label="Title"
          placeholder="Title"
          name="subject"
          validations={[{ required: true, message: "Title is required" }]}
        />
      </SimpleForm>
    </WrapperCheckTask>
  );
};
export default CheckTaskList;
