import React, { useEffect, useState } from "react";
import { WrapperForm, ListItemContainer } from "./Styles";
import { DinamicForm } from "../com/form/";
import { HeadLine } from "../Styles";
import { Button, Row, Col, List, message, Card, Avatar, Icon } from "antd";
import { getService } from "../../services/";
import { useSelector } from "react-redux";
import { URL_DEFAULT_AVATAR, URL_S3 } from "../../constants";
const { Item } = List;
const { Meta } = Card;
const columns_template = [
  {
    id: "todo",
    title: "To do",
    taskIds: [],
  },
  {
    id: "in-progress",
    title: "In Progress",
    taskIds: [],
  },
  {
    id: "to-validate",
    title: "To Validate",
    taskIds: [],
  },
  {
    id: "done",
    title: "Done",
    taskIds: [],
  },
];
const fields = [];
const ColumnForm = ({ board_type, ...props }) => {
  const [columns, setColumns] = useState(columns_template);
  const [boards, setBoards] = useState([]);
  const [keep_tasks, setKeepTasks] = useState(true);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(board_type);
  const [template, setTemplate] = useState({});
  const user = useSelector(({ user }) => user);
  const onSubmit = (err, data) => {
    if (!err) {
      if (props.onSubmit) props.onSubmit(data["name"]);
    }
  };
  useEffect(() => {
    /* if (user)
            getData(); */
  }, [user]);
  return (
    <WrapperForm>
      {user && (
        <DinamicForm
          onSubmit={onSubmit}
          autoSubmit={false}
          // source="boards"
          header={
            board_type === "template" &&
            view === "board" && (
              <Row
                style={{
                  margin: 0,
                  padding: 0,
                }}
                type="flex"
                justify="start"
                align="middle"
              >
                <Col>
                  {template.name && <h2>{template.name.capitalize()} </h2>}
                  {
                    <Button
                      type="link"
                      icon="arrow-left"
                      onClick={() => setView("template")}
                    >
                      Back to templates
                    </Button>
                  }
                </Col>
              </Row>
            )
          }
          footer={
            <Row
              style={{
                marginBottom: 10,
              }}
              type="flex"
              justify="center"
              align="middle"
            >
              <Col>
                <div className="section-add-stage">
                  <Button size="large" type="primary" htmlType="submit">
                    Add Stage
                  </Button>
                </div>
              </Col>
            </Row>
          }
          fields={[
            {
              name: "name",
              label: "Title",
              flex: 1,
              placeholder: "Add stage title",
              validations: [{ required: true }],
            },
          ]}
        />
      )}
    </WrapperForm>
  );
};
export default ColumnForm;
