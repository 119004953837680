import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { marketingValidator } from "./validators";
import qs from "qs";
import { GrTask } from "react-icons/gr";
import { GoGraph } from "react-icons/go";
const HeadSetIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "headset"]}
  />
);
const CardIDIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "id-card-alt"]}
  />
);
const UsersIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "users"]}
  />
);
const TaskIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "tasks"]}
  />
);
const CampaignIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "campground"]}
  />
);
const HistoryIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "envelope-open-text"]}
  />
);
const LeadsIcon = (
  <FontAwesomeIcon
    className="icon-fa icon-orange"
    size="lg"
    icon={["fas", "user-check"]}
  />
);

export default {
  items: [
    {
      id: "home",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: "dashboard",
    },
    {
      id: "crm-level",
      title: "CRM",
      permissions: ["admin", "agent", "broker"],
      type: "group",
      icon: "appstore",
      children: [
        {
          id: "contacts",
          title: "Contacts",
          icon: "contacts",
          /*  permissions: ["admin", "broker"], */
          type: "item",
          url: "/dashboard/contacts",
        },
        {
          id: "leads",
          title: "Leads",
          icon: "team",
          /*  permissions: ["admin", "broker"], */
          type: "item",
          url: "/dashboard/leads",
        },

        {
          id: "accounts",
          title: "Accounts",
          icon: "account-book",
          /*  permissions: ["admin", "broker"], */
          type: "item",
          url: "/dashboard/accounts",
        },
        {
          id: "campaigns",
          title: "Campaigns",
          icon: "mail",
          /*  permissions: ["admin", "broker"], */
          type: "item",
          url: "/dashboard/campaigns",
        },
        {
          id: "histories",
          title: "Notes History",
          icon: "message",
          /*  permissions: ["admin", "broker"], */
          type: "item",
          url: "/dashboard/histories",
        },
      ],
    },
    {
      id: "boards",
      title: "Boards",
      icon: "layout",
      /*  permissions: ["admin", "broker"], */
      type: "item",
      url: "/dashboard/boards",
    },
    {
      id: "tasks",
      title: "My Tasks",
      icon: "calendar",
      /*  permissions: ["admin", "broker"], */
      type: "item",
      url: "/dashboard/tasks",
    },
    {
      id: "teams",
      title: "Teams",
      icon: "team",
      type: "group",
      type: "item",
      url: `/dashboard/teams`,
    },
    {
      id: "socials-level",
      title: "Socials",
      permissions: ["admin", "agent", "broker"],
      type: "group",
      /* type: "item", */
      icon: "api",
      url: "/dashboard/social",
      children: [
        {
          id: "social",
          title: "Social Posts",
          type: "item",
          permissions: ["admin", "agent", "broker"],
          url: "/dashboard/social",
          icon: "api",
        },
        {
          id: "social-facebook",
          title: "Facebook",
          type: "item",
          permissions: ["admin", "agent", "broker"],
          url: "/dashboard/social/facebook",
          icon: "facebook",
        },
        {
          id: "social-instagram",
          title: "Instagram",
          type: "item",
          permissions: ["admin", "agent", "broker"],
          url: "/dashboard/social/instagram",
          icon: "instagram",
        },
        {
          id: "social-twitter",
          title: "Twitter",
          type: "item",
          permissions: ["admin", "agent", "broker"],
          url: "/dashboard/social/twitter",
          icon: "twitter",
        },
        {
          id: "social-linkedin",
          title: "LinkedIn",
          type: "item",
          permissions: ["admin", "agent", "broker"],
          url: "/dashboard/social/linkedin",
          icon: "linkedin",
        },
      ],
    },
    {
      id: "mylistings",
      title: "Listings",
      type: "group",
      children: [
        {
          id: "add-listing",
          title: "Add Listings",
          icon: "plus",
          type: "item",
          url: "/dashboard/mylistings/create",
        },
        {
          id: "list-listings",
          title: "My Listings",
          icon: "unordered-list",
          type: "item",
          url: "/dashboard/mylistings",
        },
      ],
      icon: "build",
    },
    {
      id: "favorites",
      title: "My Favorites",
      type: "item",
      url: "/dashboard/my-favorites",
      icon: "heart",
    },
    {
      id: "savedsearch",
      title: "Saved Search",
      type: "item",
      url: "/dashboard/saved-search",
      icon: "link",
    },
    {
      id: "broker",
      title: "Broker",
      permissions: ["admin", "broker"],
      type: "group",
      icon: "project",
      /* children: [
        {
          id: "loan-officers",
          title: "Loan Officers",
          icon: "bank",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/loan-officers",
        },
        {
          id: "sales",
          title: "Office Sales",
          type: "item",
          permissions: ["admin", "broker"],
          url: "/dashboard/sales",
          icon: "dollar",
        },
        {
          id: "agents-contacts",
          title: "Florida Agents",
          icon: "team",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/agents-contacts",
        },
      ], */
    },
    {
      id: "list-level",
      title: "Lists",
      permissions: ["admin", "agent", "broker"],
      type: "group",
      icon: "project",
      children: [
        {
          id: "agents",
          title: "My Agents",
          icon: "idcard",
          permissions: ["broker"],
          type: "item",
          url: "/dashboard/agents",
        },
        {
          id: "agents",
          title: "All Agents",
          icon: "idcard",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/agents",
        },
        /* {
          id: "loan-officers",
          title: "Loan Officers",
          icon: "bank",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/loan-officers",
        }, */
        /*  {
          id: "agents-contacts",
          title: "Florida Agents",
          icon: "team",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/agents-contacts",
        }, */
        {
          id: "expired-listings",
          title: "Expired Listings",
          icon: "disconnect",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/expired-listings",
        },
        {
          id: "owners",
          title: "Owners",
          icon: "user",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/owners",
        },
        {
          id: "members",
          title: "MLS Members",
          icon: "user",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/members",
        },
        {
          id: "approvelistings",
          title: "Approve Listings",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/approve-listings",
          icon: "check",
        },
        /* {
          id: "sales",
          title: "Office Sales",
          type: "item",
          permissions: ["admin", "broker"],
          url: "/dashboard/sales",
          icon: "dollar",
        }, */
        /* {
          id: "sales",
          title: "My Sales",
          type: "item",
          permissions: ["agent"],
          url: "/dashboard/sales",
          icon: "dollar",
        }, */
      ],
    },
    {
      id: "mail-marketing-level",
      title: "Marketing",
      permissions: ["admin", "agent", "broker"],
      validator: marketingValidator,
      type: "group",
      icon: "notification",
      children: [
        {
          id: "plans",
          title: "Plans",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/plans",
          icon: "team",
        },
        {
          id: "campaigns",
          title: "Campaigns",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/campaigns",
          icon: "team",
        },
        {
          id: "email-lists",
          title: "Lists",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/lists",
          icon: "team",
        },
        {
          id: "mail-templates",
          title: "Templates",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/mail-templates",
          icon: "team",
        },
        {
          id: "statistics",
          title: "Statistics",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/statistics",
          icon: "pie-chart",
        },

        {
          id: "subscribers",
          title: "Subscribers List",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/subscribers",
          icon: "team",
        },
      ],
    },
    /*  {
       id: "Business",
       title: "Business",
       permissions: ["admin", "agent", "broker"],
       type: "group",
       icon: "setting",
       children: [
        
       ]
     }, */
    {
      id: "Settings-level",
      title: "Settings",
      permissions: ["admin"],
      type: "group",
      icon: "setting",
      children: [
        {
          id: "users",
          title: "System Users",
          type: "item",
          permissions: ["admin"],
          url: "/dashboard/users",
          icon: "team",
        },
        {
          id: "company-request",
          title: "Requests",
          type: "item",
          permissions: ["admin", "agent", "broker"],
          url: "/dashboard/company-request",
          icon: "team",
        },
        {
          id: "log-actions",
          title: "Log Actions",
          icon: "notification",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/log-actions",
        },
        {
          id: "log-posts",
          title: "Log Posts",
          icon: "notification",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/log-posts",
        },
        {
          id: "subscriptions",
          title: "Subscription",
          icon: "solution",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/subcriptions",
        },
        {
          id: "modules",
          title: "Modules",
          icon: "apartment",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/modules",
        },
        {
          id: "business-plans",
          title: "Business Plans",
          icon: "sound",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/business-plans",
        },
        {
          id: "tags",
          title: "Tags",
          icon: "tag",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/tags",
        },
        {
          id: "plugins",
          title: "Plugins",
          icon: "tag",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/plugins",
        },
        {
          id: "plugin-plans",
          title: "Plugins Plans",
          icon: "tag",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/plugin-plans",
        },
        /* {
          id: "modules-plans",
          title: "Modules Plans",
          icon: "cluster",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/modules-plans",
        }, */
        {
          id: "customer",
          title: "Customer",
          icon: "profile",
          permissions: ["admin"],
          type: "item",
          url: "/dashboard/customer",
        },
      ],
    },
  ],
};
/* */
