import React from "react";
const SiteBuilderContext = React.createContext({});
/* {
  current: 0,
  prev: 0,
  setPrev: () => {},
  setCurrent: () => {},
  setView: () => {},
} */
export default SiteBuilderContext;
