import React, { useState, useEffect } from "react";
import { Select, Button, Row, Col, Tag, Icon } from "antd";
import { TagWrapper, StatusWrapper } from "./Styles";
const { Option } = Select;
const StatusField = ({
  editable = false,
  input = false,
  className,
  classNameTag,
  refresh,
  initial,
  name,
  choices,
  source,
  optionValue = "id",
  optionText = "name",
  ...props
}) => {
  const [dataSource, setDataSource] = useState(choices || []);
  const [value, setValue] = useState();
  const [current_value, setCurrentValue] = useState();
  const [edit, setEdit] = useState(false);
  const [record, setRecord] = useState();
  const [item, setItem] = useState();
  const getInitialValue = () => {
    /* if (record && source) {
            initial = record[source];
        } */
    let initialValue = choices.find((item) => {
      return (
        item[optionValue] === initial ||
        (item[optionValue] === record && record[name || source])
      );
    });
    setItem(initialValue);
    initialValue = initialValue ? initialValue[optionText] : "";
    setValue(initialValue);
    setCurrentValue(initialValue);
  };
  const handleEdit = () => {
    setEdit((edit) => !edit);
  };
  const handleSelect = (value) => {
    if (source) {
      setCurrentValue(value);
      let item = choices.find((item) => item[optionValue] === value);
      if (item) {
        setValue(item[optionText]);
        setItem(item);
      }

      setEdit(false);
      if (props.onItemSelect && record)
        props.onItemSelect(source, value, record);
    }
  };
  useEffect(() => {
    //Falta hacer carga desde servicio
    // console.log(":::::initialValue::::", dataSource);
    if (props.record) {
      setRecord(props.record);
      getInitialValue();
    }
  }, [props.record]);
  if (!input) {
    if (!value || !item) return null;
    return (
      <StatusWrapper className="status-wrapper">
        <div className={`status-field flex-box centered ${classNameTag || ""}`}>
          <div>
            {item[name || source]}
            {!edit ? (
              <TagWrapper
                onClick={handleEdit}
                className={`option-item ${
                  className
                    ? className
                    : value.toLowerCase().replace(/\s/g, "-")
                }`}
              >
                <Tag
                  className={`flex-box`}
                  style={{
                    background: item.background,
                    color: item.color,
                  }}
                >
                  <span>{value}</span>{" "}
                  {editable && (
                    <Icon type={!edit ? "caret-down" : "caret-up"} />
                  )}
                </Tag>
              </TagWrapper>
            ) : (
              <Select
                dataSource={dataSource}
                defaultValue={current_value}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onSelect={handleSelect}
                style={{
                  minWidth: "fit-content",
                }}
                dropdownStyle={{
                  minWidth: 150,
                }}
                {...props}
              >
                {choices &&
                  choices.map((item) => (
                    <Option key={item[optionValue]} value={item[optionValue]}>
                      {typeof optionText == "function"
                        ? optionText(name, item)
                        : item[optionText]}
                    </Option>
                  ))}
              </Select>
            )}
          </div>
          {/* <div className="action">
            {editable && (
              <Button
                type="link"
                icon={!edit ? "edit" : "close"}
                onClick={handleEdit}
              />
            )}
          </div> */}
        </div>
      </StatusWrapper>
    );
  }
  /* return (
    <StatusWrapper>
      <Select
        dataSource={dataSource}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        {...props}
      >
        {choices &&
          choices.map((item) => (
            <Option
              className={`item-option ${item[optionValue] || ""}`}
              key={item[optionValue]}
              value={item[optionValue]}
            >
              {typeof optionText == "function"
                ? optionText(name, item)
                : item[optionText]}
            </Option>
          ))}
      </Select>
    </StatusWrapper>
  ); */
};
export default StatusField;
