import React, { useEffect, useState } from "react";
import { WrapperForm } from "./Styles";
import { getService } from "../../services/";
import { Button, Col, Row, message, Form, Avatar } from "antd";
import { SimpleForm } from "../com/form/";
import * as fields_types from "../com/fields/";
import { URL_S3 } from "../../constants";
import ReactPlayer from "react-player";
const FieldSet = ({ fields, title, onChangeFieldSet, form, ...props }) => {
  const [show_labels, setShowLabels] = useState(true);
  const [show_sections, setShowSections] = useState(true);
  const [options, setOptions] = useState();
  const handleOnChange = (field, e) => {
    let value = e;
    if (e && e.target) value = e.target.value;
    if (e && e.checked) value = e.target.checked;
    if (onChangeFieldSet) onChangeFieldSet(field, value);
  };
  useEffect(() => {
    if (options) {
      let { show_labels, show_sections } = options;
      setShowLabels(show_labels);
      setShowSections(show_sections);
    }
  }, [options]);
  useEffect(() => {
    if (props.options) setOptions(props.options);
  }, [props.options]);
  // if (fields.length === 0) return null;
  return (
    <fieldset flex={1}>
      {show_sections && (
        <legend>
          <h3 className="fieldset-title">{title}</h3>
        </legend>
      )}
      <Row type="flex" justify="start" align="top" gutter={4}>
        {fields.map((field, index) => {
          let { xtype, options, flex, name, validations, label } = field;
          let formItemLabel = {};
          if (show_labels) {
            formItemLabel["label"] = label;
          }
          switch (xtype) {
            case "selectfield":
              let { choices, optionText, optionValue } = options;
              field["choices"] = choices;
              field["optionText"] = optionText;
              field["optionValue"] = optionValue;
              break;
            default:
              break;
          }
          if (!form) return null;
          let { getFieldDecorator } = form;
          return (
            <Col span={Math.round(flex * 24)} className="col-field">
              <Form.Item {...formItemLabel}>
                {getFieldDecorator(name || `field-${index}`, {
                  //   initialValue: initialValue || initial,
                  validateTrigger: "onChange",
                  valuePropName: "value",
                  rules: validations,
                 /*  onChange: (key, value) =>
                    console.log(":::fort item:::", key, value), */
                })(
                  React.createElement(
                    fields_types[xtype] || fields_types["textfield"],
                    {
                      ...field,
                      onChange: (e) => handleOnChange(name, e),
                    }
                  )
                )}
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </fieldset>
  );
};
const FormPage = ({ slug, ...props }) => {
  const [form, setForm] = useState();
  const [form_page, setFormPage] = useState();
  const [sections, setSections] = useState();
  const [fields, setFields] = useState();
  const [form_type, setFormType] = useState("form");
  const [item_layout, setItemLayout] = useState("form");
  const [mounted, setMounted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [payloads, setPayloads] = useState();
  const [sent, setSent] = useState(false);
  const [view, setView] = useState("form");
  const [options, setOptions] = useState({});
  const handleOnChange = (field, value) => {
    console.log("onChangeFieldSet::::::::", field, value);
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const save = async (values) => {
    console.log("values ,:::::", values);
    try {
      let { source } = form;
      const service = getService(source);
      setSubmitting(true);
      await service.create({ ...values, ...payloads });
      setSubmitting(false);
      setSent(true);
    } catch (error) {
      message.error(error.message);
      setSubmitting(false);
    }
  };
  const handleOnSubmit = async () => {
    let { source } = form;
    try {
      if (source) {
        if (form_page) {
          form_page.validateFields((errors, values) => {
            if (!errors) {
              save(values);
            }
          });
        }
      }
    } catch (error) {
      message.error(error.message);
      setSubmitting(false);
      setView("message");
    }
    console.log("que es handleOnSubmit", payloads);
  };
  useEffect(() => {
    if (props.form) setForm(props.form);
  }, [props.form]);
  useEffect(() => {
    if (form) {
      let { fields, sections, item_layout, form_type, options } = form;
      item_layout = item_layout || "sections";
      // if (item_layout === "sections")
      setSections(sections);
      if (item_layout === "form") setFields(fields);
      if (form_type) setFormType(form_type);
      if (item_layout) setItemLayout(item_layout);
      setOptions(options);
      setMounted(true);
    }
  }, [form]);
  if (!mounted) return null;
  return (
    <WrapperForm {...form} {...options}>
      {view === "message" && (
        <div>
          <div>
            <h3>{form && form.text_sent}</h3>
          </div>
          <div>
            <Button onClick={() => setView("form")} type="primary">
              Accept
            </Button>
          </div>
        </div>
      )}
      {view === "form" && (
        <div>
          <SimpleForm
            className={`section-form ${options.border}`}
            {...form}
            title={
              <div className="container-form">
                {form && form.logo && form.logo.length > 0 && (
                  <div className="logo-form">
                    <div className="section-logo">
                      <img
                        src={`${URL_S3}/${form && form.logo}`}
                        size="large"
                        alt="logo company"
                      />
                    </div>
                  </div>
                )}
                <div className="title-form">
                  <h3>{form && form.title}</h3>
                </div>
              </div>
            }
            fields={fields}
            autoSubmit={false}
            onMount={(form) => setFormPage(form)}
            onChange={handleOnChange}
            footer={
              <Row type="flex" justify="center" align="middle" gutter={8}>
                <Col>
                  <Button
                    className={options.button_style || "btn-primary"}
                    size={options.button_size || "large"}
                    loading={submitting}
                    onClick={handleOnSubmit}
                    type="primary"
                  >
                    {(form && form.button_text) || "Send"}
                  </Button>
                </Col>
              </Row>
            }
          >
            {sections &&
              sections.map((section) => {
                let { fields, title } = section;
                return (
                  <FieldSet
                    xtype="fieldset"
                    flex={1}
                    fields={fields}
                    title={title}
                    name="fieldset"
                    form={form_page}
                    options={form.options}
                    onChangeFieldSet={handleOnChange}
                  />
                );
              })}
          </SimpleForm>
        </div>
      )}
    </WrapperForm>
  );
};
export default FormPage;
