import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, Button, Icon, Input, InputNumber, Select } from "antd";
import ReactCountryFlag from "react-country-flag";
import { WrapperPhoneFiel } from "./Styles";
// const { Option } = Select;

const { Option } = AutoComplete;
const data = [
  { text: "", countryCode: "", value: "0" },
  { text: "Afghanistan", countryCode: "AF", value: "+93" },
  { text: "Albania", countryCode: "AL", value: "+355" },
  { text: "Argentina", countryCode: "AR", value: "+54" },
  { text: "Australia", countryCode: "AU", value: "+61" },
  { text: "Brazil", countryCode: "BR", value: "+55" },
  { text: "Chile", countryCode: "CL", value: "+56" },
  { text: "China", countryCode: "CN", value: "+86" },
  { text: "Colombia", countryCode: "CO", value: "+57" },
  { text: "Costa Rica", countryCode: "CR", value: "+506" },
  { text: "Ecuador", countryCode: "EC", value: "+593" },
  { text: "Egypt", countryCode: "EG", value: "+20" },
  { text: "France", countryCode: "FR", value: "+33" },
  { text: "Germany", countryCode: "DE", value: "+49" },
  { text: "Guatemala", countryCode: "GT", value: "+502" },
  { text: "Honduras", countryCode: "HN", value: "+504" },
  { text: "India", countryCode: "IN", value: "+91" },
  { text: "Italy", countryCode: "IT", value: "+39" },
  { text: "Japan", countryCode: "JP", value: "+81" },
  { text: "Mexico", countryCode: "MX", value: "+52" },
  { text: "Nicaragua", countryCode: "NI", value: "+505" },
  { text: "Panama", countryCode: "PA", value: "+507" },
  { text: "Paraguay", countryCode: "PY", value: "+595" },
  { text: "Peru", countryCode: "PE", value: "+51" },
  { text: "Russia", countryCode: "RU", value: "+7" },
  { text: "Saudi Arabia", countryCode: "SA", value: "+966" },
  { text: "South Africa", countryCode: "ZA", value: "+27" },
  { text: "South Korea", countryCode: "KR", value: "+82" },
  { text: "Spain", countryCode: "ES", value: "+34" },
  { text: "United Kingdom", countryCode: "GB", value: "+44" },
  { text: "United States", countryCode: "US", value: "+1" },
  { text: "Uruguay", countryCode: "UY", value: "+598" },
  { text: "Venezuela", countryCode: "VE", value: "+58" },
];

const PhoneField = ({
  optionText = "text",
  optionValue = "value",
  input = false,
  onSelectAutocomplete,
  record,
  onChange,
  name,
  ...props
}) => {
  let MyRef = useRef(null);
  const [show, setShow] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [code_phone, setCodePhone] = useState("+1");
  const [countrys, setCountry] = useState();
  const [phone, setPhone] = useState();
  function renderOption(item) {
    return (
      <Option key={item[optionValue]} text={item[optionText]}>
        <div className="global-search-item">
          <span className="global-search-item-desc">{item[optionText]}</span>
          {/* <span className="global-search-item-count">{item.count} results</span> */}
        </div>
      </Option>
    );
  }

  const handleOnSelect = (value) => {
    let country = data.find((it) => it[optionValue] === value);
    setShow(false);
    setCountry(country);
    setCodePhone(value);
  };
  const handleOnChange = (value) => {
    if (props.onChange) onChange(value);

    setPhone(value);
    console.log("handleOnChange", phone);
  };
  function valideKey(evt) {
    // code is the decimal ASCII representation of the pressed key.
    if (evt.which < 48 || evt.which > 57 || phone.length === 14) {
      return false;
    }
    var code = evt.which ? evt.which : evt.keyCode;
    if (code === 8) {
      // backspace.
      return true;
    } else if (code >= 48 && code <= 57) {
      // is a number.
      return true;
    } else {
      // other keys.
      return false;
    }
  }
  const formatter = (value) => {
    var cleaned = ("" + value).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };
  useEffect(() => {
    if (record) {
      let value = record[name];
      // let countryCode = data.map((it) => it.countryCode);
      if (value) {
        let phone = value;
        let code_phone = "";
        let code_phones = phone.slice(0, phone.length === 12 ? 3 : 4);
        if (code_phones === "+51" && phone.length === 12) {
          // console.log(":::phone.length:0:", code_phones);
          // console.log("::::countryCoded: ddd", phone.length);
          code_phone = phone.slice(0, phone.length === 12 ? 3 : 4);
          phone = phone.slice(phone.length === 12 ? 3 : 4, phone.length);
        }
        if (phone.length === 12) {
          // console.log(":::::phone.length:1::", phone.length);
          code_phone = phone.slice(0, phone.length === 12 ? 2 : 3);
          phone = phone.slice(phone.length === 12 ? 2 : 3, phone.length);
        }
        if (phone.length === 13) {
          //  console.log(":::::phone.length:2::", phone.length);
          code_phone = phone.slice(0, phone.length === 13 ? 3 : 4);
          phone = phone.slice(phone.length === 13 ? 3 : 4, phone.length);
        }
        if (phone.length === 14) {
          // console.log(":::::phone.length:3::", phone.length);
          code_phone = phone.slice(0, phone.length === 14 ? 4 : 5);
          phone = phone.slice(phone.length === 14 ? 4 : 5, phone.length);
        }
        let country = data.find((it) => it[optionValue] === code_phone);
        setCountry(country);
        setPhone(
          String(phone)
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "")
        );
        setCodePhone(code_phone);
        setInitialized(true);
      }
    }
  }, [record]);
  useEffect(() => {
    if (onChange || (phone && initialized)) {
      let { form } = props;
      let value = `${code_phone || "+1"}${phone || ""}`;
      if (form && String(phone).length > 12) {
        console.log(":::::que es phone:::::", value, phone);
        form.setFields({
          [name]: {
            value: phone,
            errors: [new Error("Max value")],
          },
        });
      } else if (typeof phone !== "undefined") {
        form.setFields({
          [name]: {
            value: String(value)
              .replace("(", "")
              .replace(")", "")
              .replace("-", "")
              .replace(" ", ""),
            errors: null,
          },
        });
      }
      if (initialized) onChange(value);
    }
  }, [phone, code_phone]);

  if (!input) {
    return <>{record && formatter(record[name])}</>;
  }
  return (
    <WrapperPhoneFiel>
      {show && (
        <AutoComplete
          className="container-autocomplete"
          allowClear
          backfill
          dataSource={data.map(renderOption)}
          style={{ width: 200 }}
          onSelect={handleOnSelect}
          optionLabelProp={optionText}
          filterOption={(inputValue, option) => {
            let { children } = option.props;
            return (
              children.props &&
              children.props.children &&
              children.props.children.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            );
          }}
        >
          <Input
            className="inputComplete"
            addonBefore={
              <ReactCountryFlag
                svg
                cdnSuffix="svg"
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                countryCode={countrys && countrys.countryCode}
              />
            }
            placeholder="Country Code"
          />
        </AutoComplete>
      )}
      {!show && (
        <div className="container-code-input">
          <Select
            className="container-select"
            value={code_phone || "+1"}
            onFocus={() => setShow((show) => !show)}
            style={{ width: 120 }}
            onSelect={(e) => setShow(false)}
            defaultValue={code_phone || "+1"}
            suffixIcon={
              <div className="section-ReactCountryFlag">
                <ReactCountryFlag
                  countryCode={(countrys && countrys.countryCode) || "US"}
                  svg
                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  cdnSuffix="svg"
                />
              </div>
            }
          >
            <Option value={code_phone}>{code_phone}</Option>
          </Select>
          <InputNumber
            className="section-inputnumber"
            style={{ width: "100%" }}
            ref={(el) => (MyRef = el)}
            parser={(value) =>
              value
                .replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace(" ", "")
            }
            formatter={(value) => {
              var cleaned = ("" + value).replace(/\D/g, "");
              if (value.length === 9) {
                var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
                if (match) {
                  return "(" + match[1] + ") " + match[2] + "-" + match[3];
                }
              } else {
                // var cleaned = ("" + value).replace(/\D/g, "");
                var matchthen = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (matchthen) {
                  return (
                    "(" +
                    matchthen[1] +
                    ") " +
                    matchthen[2] +
                    "-" +
                    matchthen[3]
                  );
                }
              }
              return null;
            }}
            {...props}
            value={phone}
            onkeypress={(e) => valideKey(e)}
            onChange={handleOnChange}
          />
        </div>
      )}
    </WrapperPhoneFiel>
  );
};
export default PhoneField;
