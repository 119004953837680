import React from "react";
import ColumnLayout from "./ColumnLayout";

const LeftLayout = ({ children, ...props }) => {
  return (
    <>
      <ColumnLayout {...props} layout="leftlayout">
        {children}
      </ColumnLayout>
    </>
  );
};
export default LeftLayout;
