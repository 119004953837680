import styled from "styled-components";

export const Wrapper = styled.div`
  & .ant-card-bordered {
    min-height: 130px;
  }
  & .section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  }
  & .ant-card-head-title {
    padding: 1px 4px !important;
  }

  & .sections-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .sections-title strong {
    font-size: 13px;
  }
  & .section-description {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 13px;
    padding: 2px;
  }

  & .ant-avatar.ant-avatar-icon {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-avatar.ant-avatar-icon {
    color: var(--color-primary);
  }
  & .ant-avatar {
    background: #cccccc5e !important;
  }

  & .section-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  }
  & .ant-card-meta {
    min-height: 64px;
  }
  /* & .ant-card.ant-card-bordered:hover {
    background: linear-gradient(90deg, rgb(2, 16, 73) 0%, rgb(29, 55, 162) 100%)
      0% 0% no-repeat padding-box padding-box transparent !important;
  } */

  & .section-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.ant-btn.section-button.ant-btn-primary {
    border-color: var(--primary-button) !important;
    background: var(--primary-button) !important;
  }
`;
