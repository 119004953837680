import React, { useState, useEffect, useRef } from 'react';
import { Wrapper } from "./Styles";
import { List, Card, Skeleton, Row, Col, Icon, Button, Carousel, message } from "antd";
import { getService } from '../../services';
import { money } from '../../utils/Helper';
const { Meta } = Card
const SampleNextArrow = ({ ...props }) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = ({ ...props }) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
            onClick={onClick}
        />
    );
}
let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow className="next-arrow" />,
    prevArrow: <SamplePrevArrow className="prev-arrow" />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 968,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 868,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 670,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
const PlanAcelleMail = ({ filterDefaultValues, optionValue = "id", reference, ...props }) => {
    const myRef = useRef(null);
    const [index, setIndex] = useState(props.index || 0);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState();
    const [refresh, setRefresh] = useState()
    const getData = () => {
        const service = getService(reference);
        setLoading(true);
        service.find({
            query: {
                ...filterDefaultValues
            }
        })
            .then(({ data }) => {
                setDataSource(data);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            })
    }
    const handleclick = (item) => {
        let id = item[optionValue];
        setSelected(id);
        if (props.onSelect) props.onSelect(id)
    }

    const defaultRenderitem = (item) => {
        return (
            // <List.Item>
            <Card
                loading={loading}
                className={`item-card ${selected == item.id ? "item-selected" : ""}`}
                hoverable
            >
                <Meta
                    title={
                        <div className="container-section-title">
                            <div className="section-price">
                                <strong>{money(item.price)}</strong>
                                <span> Usd</span>
                            </div>
                            <div className="section-quantity">
                                <Icon type="clock-circle" />
                                <span>
                                    {item.frequency_unit}
                                </span>
                            </div>
                            <div className="section-plan">
                                <strong>{item.name}</strong>
                            </div>
                        </div>
                    }
                    description={
                        <div className="container-section-description">
                            <div className="section-description">
                                <span>{item.description}</span>
                            </div>
                            <div className="section-sending">
                                <div className="section-sending-contact">
                                    <div className="sending">
                                        <span>
                                            {item.quantity_sending} Sending credits
                                        </span>
                                    </div>
                                    <div className="sending">
                                        <span>
                                            {item.quantity_contact} Contacts
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="section-footer">
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => handleclick(item)}
                                >
                                    Select
                                </Button>
                            </div>
                        </div>
                    }
                />
            </Card>
        )
    }
    useEffect(() => {
        setIndex(index);
    }, [props.index])
    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        if (props.selected) setSelected(props.selected);
    }, [props.selected])
    return (
        <Wrapper>
            <div className="carousel-container">
                {loading ? <Skeleton loading={loading} active></Skeleton> :
                    <Carousel ref={myRef}  {...settings} autoplay={props.autoplay || false} >
                        {
                            dataSource.map(props.renderItem || defaultRenderitem)}
                    </Carousel>
                }
                {!loading && <div className="arrow-container">
                    {<Button disabled={(index == 0)} className="arrow-left" onClick={() => {
                        if (myRef.current)
                            myRef.current.prev();
                        if (index > 0)
                            setIndex(index => index - 1)
                    }} type="link" size="large" icon="left-circle" />}
                    {<Button disabled={(index >= dataSource.length - 1)} className="arrow-right" onClick={() => {
                        if (myRef.current)
                            myRef.current.next();
                        if (index < dataSource.length - 1)
                            setIndex(index => index + 1)
                    }} type="link" size="large" icon="right-circle" />}
                </div>}
            </div>
        </Wrapper>
    )
}
export default PlanAcelleMail;