import styled from "styled-components";
import { URL_S3 } from "../../constants";

export const WrapperColumnLayout = styled.div`
  & .leftlayout {
    height: 100vh;
    overflow-y: scroll;
  }
  & .rightlayout {
    height: 100vh;
    overflow-y: scroll;
  }
  &.centerlayout {
    background: ${({ background }) =>
      background ? background : "red"} !important;
    background-image: ${({ image }) =>
      image && `url(${URL_S3}/${image})`} !important;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-attachment: fixed;
  }

  & .column-container {
    min-height: 100vh;
  }
  /*  & .ant-col {
    height: 100vh;
    overflow: scroll;
  } */
`;
export const WrapperColumnBackground = styled.div`
  min-height: 100vh;
  background: ${({ background }) =>
    background ? background : "red"} !important;
`;
