import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField } from "../com/form/";
import { Input, Row, InputNumber, Divider } from "antd";
import { Switch } from "antd";
import {
  special_conditions,
  property_information,
  waterFrontFeatures,
  construction_type,
  architectural_style,
  ParkingFeatures,
  LotFeatures,
  PoolFeatures,
  View,
  PetsAllowed,
  InteriorFeatures,
  SecurityInformation,
  ShowingInformation,
  roof_description,
  exterior_features,
  flooring
} from ".";
/* 
ParkingTotal
WaterfrontFeatures
ConstructionMaterials
ArchitecturalStyle
ParkingFeatures
LotFeatures
PoolFeatures
View
PetsAllowed
InteriorFeatures
SecurityInformation
AssociationFee
ShowingInformation
Roof
PoolPrivateYN
SpaYN
ExteriorFeatures
Flooring

*/
const AditionalInformation = ({ id, onReset, onChange, ...props }) => {
  const [PropertyType, setPropertyType] = useState("Residential");
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const setInitialValues = () => {
    let { record } = props;
    if (record) {
      setRecord(record);
      setPropertyType(record["PropertyType"]);
    }
  };
  useEffect(() => {
    let { loading } = props;
    setLoading(loading);
    return () => {};
  }, [props.loading]);
  useEffect(() => {
    setInitialValues();
    return () => {};
  }, [props.record]);
  return (
    <>
      {!loading && (
        <SimpleForm
          source="my-listings"
          onChange={onChange}
          initialValues={record}
          autoSubmit={false}
        >
          <Switch name="PoolPrivateYN" label={"Pool Private?"} />
          <Switch name="SpaYN" label={"Spa?"} />
          <SelectField
            name="ParkingTotal"
            label="Parking Total"
            /* placeholder="Parking Total" */
            choices={Array.apply(null, { length: 11 }).map((it, index) => ({
              _id: index,
              name: index
            }))}
          />
          <InputNumber
            name="AssociationFee"
            /* placeholder="Association Fee" */
            label="Association Fee"
          />
          <Divider
            style={{
              margin: 0
            }}
            flex={1}
            type="horizontal"
            dashed
          />
          {
            <SelectField
              name="WaterfrontFeatures"
              label="Waterfront Features"
              mode="multiple"
              /* placeholder="Waterfront Features" */
              optionText="text"
              optionValue="value"
              choices={waterFrontFeatures.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {PropertyType == "Residential" && (
            <SelectField
              name="ConstructionMaterials"
              label="Construction Materials"
              mode="multiple"
              /* placeholder="Construction Materials" */
              optionText="text"
              optionValue="value"
              choices={construction_type.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          )}
          {PropertyType == "Residential" && (
            <SelectField
              name="ArchitecturalStyle"
              label="Architectural Style"
              mode="multiple"
              /* placeholder="Architectural Style" */
              optionText="text"
              optionValue="value"
              choices={architectural_style}
            />
          )}
          {PropertyType == "Residential" && (
            <SelectField
              name="ParkingFeatures"
              label="Parking Features"
              mode="multiple"
              /* placeholder="Parking Features" */
              optionText="text"
              optionValue="value"
              choices={ParkingFeatures.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          )}
          {
            <SelectField
              name="LotFeatures"
              label="Lot Features"
              /* placeholder="Lot Features" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={LotFeatures.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="PoolFeatures"
              label="Pool Features"
              /* placeholder="Pool Features" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={PoolFeatures.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          <Divider
            style={{
              margin: 0
            }}
            flex={1}
            type="horizontal"
            dashed
          />
          {
            <SelectField
              name="View"
              label="View"
              /* placeholder="View" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={View.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="PetsAllowed"
              label="Pets Allowed"
              /* placeholder="Pets Allowed" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={PetsAllowed.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="InteriorFeatures"
              label="Interior Features"
              /* placeholder="Interior Features" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={InteriorFeatures.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="SecurityInformation"
              label="Security Information"
              /* placeholder="Security Information" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={SecurityInformation.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="ShowingInformation"
              label="Showing Information"
              /* placeholder="Showing Information" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={ShowingInformation.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {PropertyType == "Residential" && (
            <SelectField
              name="Roof"
              label="Roof"
              /* placeholder="Roof" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={roof_description.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          )}
          {
            <SelectField
              name="ExteriorFeatures"
              label="Exterior Features"
              /* placeholder="Exterior Features" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={exterior_features.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
          {
            <SelectField
              name="Flooring"
              label="Floor Description"
              /* placeholder="Floor Description" */
              mode="multiple"
              optionText="text"
              optionValue="value"
              choices={flooring.map((it, index) => ({
                text: it,
                value: it
              }))}
            />
          }
        </SimpleForm>
      )}
    </>
  );
};
export default AditionalInformation;
