import React, { useState } from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from "react-stripe-elements";
import { SimpleForm } from "../com/form/";
import { getService } from "../../services";
import { WrapperPaymentForm } from "./styles";
import { Input, message } from "antd";
import StripeLabel from "./StripLabel";
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      border: "1px solid red",
      ":-webkit-autofill": { color: "black" },
      "::placeholder": { color: "black" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "black",
    },
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
  },
};

const PaymentForm = ({
  stripe,
  elements,
  onSubmit,
  onChange,
  plan,
  onMount,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (err, data) => {
    if (err) return;
    try {
      setSubmitting(true);
      const cardElement = elements.getElement("cardNumber");
      let {
        first_name,
        last_name,
        email,
        address,
        zip_code,
        state,
        phone,
      } = data;
      let payloads = {
        name: `${first_name || ""} ${last_name || ""}`,
        email,
      };
      const { token, error } = await stripe.createToken({
        type: "card",
        ...payloads,
      });
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: payloads,
      });

      if (error) {
        setErrorMessage(error.message);
        setSubmitting(false);
      } else {
        // Send the token to your server for further processing
        // e.g., make an API call to create a payment intent
        console.log("-----> ", err, data);
        const paymentService = getService("payment");
        /* 
        "email":"fvargas.fontalvo@gmail.com",
        "name":"Fabian Vargas",
        "payment_method": "pm_1NNSeDB7UvpvYbdyRi8gEggp",
        "plan_id": "649a4f20c5468e3260419b8c"
      */
        let resp = await paymentService.create({
          payment_method: paymentMethod.id,
          plan_id: plan._id,
          token: token.id,
          email,
          address,
          zip_code,
          state,
          phone,
          hostname:
            window.location.hostname === "localhost"
              ? window.location.hostname
              : window.location.hostname,
          origin:
            window.location.hostname === "localhost"
              ? window.location.hostname
              : window.location.origin,
          amount: parseFloat(plan.prices || plan.price), // Replace with the desired amount in cents
          currency: "usd", // Replace with the desired currency,
          ...payloads,
        });
        console.log("----->>>> Response: ", resp);
        /* 
        token: token.id,
        amount: 1000, // Replace with the desired amount in cents
        currency: 'usd', // Replace with the desired currency
      */
        if (onSubmit) onSubmit(resp);
        setSubmitting(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setSubmitting(false);
    }
  };

  return (
    <WrapperPaymentForm>
      <SimpleForm
        submitting={submitting}
        onChange={onChange}
        onMount={onMount}
        submitButtonText={"Buy"}
        onSubmit={handleSubmit}
      >
        <Input
          name="first_name"
          placeholder="First Name"
          validations={[
            {
              required: true,
              message: "First Name is required",
            },
          ]}
        />
        <Input
          name="last_name"
          placeholder="Last Name"
          validations={[
            {
              required: true,
              message: "Last Name is required",
            },
          ]}
        />
        <Input name="address" placeholder="Address" />
        <Input name="zip_code" placeholder="Zip Code" />
        <Input name="state" placeholder="State" />
        <Input name="phone" placeholder="Phone" type="number" />
        <Input
          flex={1}
          name="email"
          placeholder="Email"
          type="email"
          validations={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        />
        <div flex={1} className="flex justify-between gap-md align-center">
          <div>
            <h3>Credit Card Detail</h3>
          </div>
          <div>
            <StripeLabel />
          </div>
        </div>
        <CardNumberElement
          flex={0.6}
          onReady={(el) => el.focus()}
          className="stripe-field"
          style={CARD_OPTIONS}
        />
        <CardExpiryElement
          flex={0.2}
          className="stripe-field"
          style={CARD_OPTIONS}
        />
        <CardCVCElement
          flex={0.2}
          className="stripe-field"
          style={CARD_OPTIONS}
        />
        {/* <CardElement name="payment" flex={1} options={CARD_OPTIONS} /> */}
        {errorMessage && <p>{errorMessage}</p>}
      </SimpleForm>
    </WrapperPaymentForm>
  );
};
export default injectStripe(PaymentForm);
