import React from "react";
import styled from "styled-components";
export const Wrapper = styled.div`
  & .ant-list {
    & .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 0px !important;
    }
    & .ant-skeleton-content .ant-skeleton-title {
      margin-bottom: 8px !important;
    }
    & .ant-skeleton-paragraph {
      margin-bottom: 0px !important;
    }
    & .ant-list-item {
      cursor: pointer;
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
      background: white;
      gap: 4px;
      padding: 10px 8px;
      min-height: 110px;
      border: 1px solid #efefef;
      border-radius: 4px !important;
      box-shadow: 2px 2px 3px #efefef;
      padding: 10px;
      & .ant-list-item-meta {
        padding: 8px !important;
      }
      & .ant-list-item-meta-title {
        font-family: "NoirPro", sans-serif !important;
        text-transform: uppercase !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-bottom: 0px !important;
      }
      & .ant-list-item-meta-description h2 {
        font-family: "NoirPro", sans-serif !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        margin-bottom: 0px !important;
        line-height: 1 !important;
      }
      & .description-plan {
        /* color: #fff !important; */
      }
    }
    & .primary-plan {
      background-color: #038fde;
      & .ant-list-item-meta-title {
        color: #fff !important;
      }

      & .ant-list-item-meta-description h2 {
        color: #fff !important;
      }
      & .description-plan {
        color: #fff !important;
      }
    }
  }
`;
