import React, { useEffect, useState } from "react";
import { Menu, Icon, Button, message } from "antd";
import styled from "styled-components";
import { navigate, Link } from "@reach/router";
import { connect, useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../store/actions";
import menu from "../../menu-items";
import { allowAccess } from "../../utils/Helper";
import { getService } from "../../services";
import _ from "lodash";
const { SubMenu } = Menu;

const MenuStyled = styled(Menu)`
  &.ant-menu {
    margin-top: 10px !important;
  }

  & .ant-menu-submenu .ant-menu-item {
    padding-left: 30px !important;
  }
  & .ant-menu-submenu-selected {
    color: var(--color-orange) !important;
  }
  & .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    color: var(--orange) !important;
  }
  .ant-menu-item {
    height: 50px !important;
    line-height: 50px !important;
    border-radius: 0.25rem;
    padding: 4px 10px !important;
    margin: 0px 10px !important;
    display: flex;
    align-items: center;
    margin-bottom: 4px !important;
    gap: 4px;
    font-size: 16px !important;
  }
  .ant-menu-item-selected {
    background: var(--primary) !important;
    color: var(--color-orange) !important;
  }

  & .ant-menu-item-selected i {
    font-size: var(--font-size-medium);
  }
  & .icon-item svg {
    padding: 0px 4px;
  }
  & .icon-menus {
    font-size: 20px !important;
  }
  & .ant-menu-submenu-title {
    height: 50px !important;
    line-height: 50px !important;
    span {
      gap: 4px !important;
    }
  }
  & .ant-menu-submenu .ant-menu-item {
    padding-left: 16px !important;
  }
  & .submenu-item .ant-menu-sub {
    background: rgb(0 0 0 / 5%) !important;
  }
  & .ant-menu-submenu-open .ant-menu-submenu-title {
    background: rgb(0 0 0 / 5%) !important;
    margin-bottom: 0 !important;
    border-bottom: 3px px solid rgb(0 0 0 / 15%) !important;
  }
  & .item-btn {
    background-color: rgba(0, 0, 0, 0.3) !important;
    font-size: 16px !important;
    height: 28px !important;
    padding: 4px !important;
    margin: 0px 10px !important;
    color: #d9d9d9 !important;
  }
  & .ant-menu-submenu-title {
    padding-left: 22px !important;
    padding-right: 0px !important;
  }
  .ant-menu-submenu-title span {
    font-size: 16px;
  }
`;
const SideMenu = (props) => {
  const [current, setCurrent] = useState();
  const [initialized, setInitialized] = useState(false);
  const [teams, setTeams] = useState([]);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const collapsedMenu = useSelector(({ collapsedMenu }) => collapsedMenu);
  const handleClick = ({ key, item }) => {
    /* setCurrent(key); */
    props.onChangePath(key);
    navigate(key);
  };

  const getCurrentItem = (items) => {
    let { pathname } = window.location;
    let find = null;
    items.forEach((item) => {
      if (pathname === item.url) {
        find = item;
      }
      if (!find && item.children) {
        find = getCurrentItem(item.children);
      }
    });
    return find;
  };
  const handleSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    props.onChangePage({
      id: item.props.id,
      title: item.props.title,
      url: item.props.url,
    });
  };

  const renderItems = (item, index) => {
    let children = item.children
      ? item.children.filter(
          (it) => allowAccess(it.permissions) || it.permissions.length === 0
        )
      : undefined;
    if (item.node_type === "group" && children && children.length === 0)
      return null;
    if (children && children.length) {
      children = _.orderBy(children, ["order"]);
    }
    if (item.node_type === "item") delete item.children;
    if (item.name === "contacts")
      if (item.name === "marketing") {
        if (!user.customer) return null;
      }
    return item.node_type === "item" ? (
      <Menu.Item {...item} key={item.url}>
        {typeof item.icon === "object" ? (
          <span className="icon-item">{item.icon}</span>
        ) : (
          <Icon className="icon-menus" type={item.icon} />
        )}
        <span>{item.title || item.name}</span>
      </Menu.Item>
    ) : item.node_type === "group" ? (
      <SubMenu
        className="submenu-item"
        key={index}
        title={
          <span>
            {typeof item.icon === "object" ? (
              <span className="icon-item">{item.icon}</span>
            ) : (
              <Icon type={item.icon} />
            )}
            <span>{item.title || item.name}</span>
          </span>
        }
      >
        {children && children.map((item, index) => renderItems(item, index))}
      </SubMenu>
    ) : null;
  };

  const getOptions = (json) => {
    try {
      return JSON.parse(json);
    } catch (err) {
      return {};
    }
  };
  useEffect(() => {
    if (user && user.modules)
      setItems(
        user.modules.map((it) => {
          let { module, children } = it;
          let options = getOptions(
            (it && it.options) || (module && module.options)
          );
          children = children.map((it) => {
            let options = getOptions(it.options);
            return {
              ...it,
              ...options,
            };
          });
          if (module) {
            return {
              title: module.title || module.name,
              ...options,
              ...module,
              children,
            };
          }
          return { ...it, ...options, title: it.title || it.name, children };
        })
      );
  }, [user]);
  useEffect(() => {
    let { pathname, current } = props;
    if (pathname) {
      setCurrent(pathname);
    } else {
      props.onChangePath(window.location.pathname);
      setCurrent(window.location.pathname);
    }
    const item = getCurrentItem(menu.items);
    props.onChangePage(item);
    if (!initialized && user) {
      /* getTeams(); */
      setInitialized(true);
    }
    return () => {};
  }, [props]);
  return (
    <MenuStyled
      className="side-menu"
      theme="light"
      mode="inline"
      /* style={{ width: 280 }} */
      onClick={handleClick}
      onSelect={handleSelect}
      selectedKeys={[current]}
      defaultSelectedKeys={["/dashboard"]}
    >
      {items
        .filter((it) => {
          return (
            it && (allowAccess(it.permissions) || it.permissions.length == 0)
          );
        })
        .filter((it) =>
          it.node_type === "group" ? it.children.length > 0 : true
        )
        .map((item, index) => renderItems(item, index))}
    </MenuStyled>
  );
};

const mapStateToProps = (state) => {
  return {
    pathname: state.pathname,
    current: state.current,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChangePath: (pathname) =>
      dispatch({ type: actionTypes.CHAGE_PATH, pathname }),
    onChangePage: (current) =>
      dispatch({ type: actionTypes.CHAGE_PAGE, current }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
