import styled from "styled-components";
/* 
 --font-size-xlarge: 32px;
  --font-size-large: 28px;
  --font-size-medium: 20px;
  --font-size-small: 18px;
  --font-size-tiny: 16px;
*/
export const ToolBar = styled.div``;
export const Wrapper = styled.div`
  & .CodeMirror {
    position: relative;
    overflow: hidden;
    background: var(--gray-light);
    padding: 10px 4px;
    height: auto !important;
    min-height: 100px !important;
    border-radius: 12px !important;
  }
  & span.cm-header.cm-header-1,
  h1 {
    font-size: var(--font-size-medium);
  }
  & .ant-form-item.head-input:hover {
    background: white !important;
  }
  & span.cm-header.cm-header-2,
  h2 {
    font-size: var(--font-size-small);
    margin-bottom: 8px !important;
  }
  & span.cm-header.cm-header-3,
  h3 {
    font-size: var(--font-size-tiny);
  }
  & .cm-string.cm-url {
    color: var(--primary) !important;
  }
  & .ant-comment-content-detail p {
    white-space: pre-wrap;
    margin-bottom: 0.2em;
  }
  & .section-moments {
    display: flex;
    justify-content: space-between;
    padding: 1px 2px;
    font-size: 12px;
    align-items: center;
    span {
      color: #9f9f9fe0 !important;
    }
  }
  & .container-btn-comments {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    gap: 8px;
    align-items: center;
    text-align: center;
  }
  & .section-btn .ant-btn-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
  }
  .section-btn {
    display: flex;
  }
  & .section-update {
    margin-left: 16px;
    .ant-btn-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;
    }
  }
  & .ant-form .ant-form-item-children .ant-input {
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  button.ant-btn.btn-removed-red.ant-btn-link.ant-btn-sm.ant-btn-icon-only {
    color: red !important;
  }
  & .section-edit-buttton {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .btn-edit {
      color: var(--color-primary-blue) !important;
      font-size: 18px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & .btn-action-task-comments {
    display: flex;
    justify-content: start;
    align-item: center;
  }
`;

export const MarkdownWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: #eeeeee8c;
  padding: 10px 4px;
  height: auto !important;
  min-height: 36px !important;
  border-radius: 6px !important;

  & .ant-form-item {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  ${({ box }) =>
    box &&
    `
            // background: #fff;
            padding: 4px 10px 4px 10px;
            border-radius: 6px;
            // box-shadow: 2px 2px 2px #d3d3d347;
            // border: 1px solid #d3d3d35e;
            & p,h2,h1 {
                margin-bottom: 0.2em!important;
            }
        `}
`;
