import React, { useState, useEffect, useContext } from "react";
import { WrapperJSONField } from "./Styles";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import _ from "lodash";

import { Button, Dropdown, Input, Icon, Menu, Tooltip } from "antd";
import { SelectField } from "./";
import { v4 as uuid } from 'uuid';
import { SimpleForm } from "../form/";
import * as fields from "./";
const JSONContext = React.createContext(null);
const types = [
  { name: "textfield", text: "Text" },
  { name: "numberfield", text: "Number" },
  { name: "list", text: "List" },
  { name: "booleanfield", text: "Boolean" },
  { name: "datefield", text: "Date" },
];
const DragHandle = sortableHandle(() => (
  <div
    className="handle-drag"
    onClick={() => {
      alert("press");
    }}
  >
    <Tooltip placement="bottom" title={"Drag and drop"}>
      <span>::</span>
    </Tooltip>
  </div>
));
const MenuItems = (props) => (
  <Menu className={props.className} onClick={props.onMenuClick}>
    <Menu.Item key="edit">
      <Icon type="edit" />
      Edit
    </Menu.Item>
    <Menu.Item key="delete">
      <Icon type="delete" />
      Delete
    </Menu.Item>
    <Menu.Item key="copy">
      <Icon type="copy" />
      Copy
    </Menu.Item>
  </Menu>
);
const ItemForm = ({ onChange, onChangeType, ...props }) => {
  const [record, setRecord] = useState();
  const [xtype, setXtype] = useState();
  const [count, setCount] = useState(0);
  const [form, setForm] = useState();
  const handleOnChange = (field, value) => {
    console.log("Field: ", field, value);
    if (onChange) onChange(field, value);
  };
  const handleChangeType = (field, value) => {
    if (onChangeType) onChangeType(value);
    setXtype(value);
  };
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  useEffect(() => {
    if (record) setXtype(record.xtype);
  }, [record]);
  useEffect(() => {
    const count = React.Children.count(props.children);
    setCount(count);
  }, [form]);

  return (
    <SimpleForm
      onChange={handleOnChange}
      autoSubmit={false}
      onMount={(form) => setForm(form)}
      initialValues={record}
      layout="vertical"
    >
      {JSON.stringify(record)}
      <Input
        name={"name"}
        source={"name"}
        flex={0.333}
        initial={record && record["name"]}
      />
      {
        <SelectField
          flex={0.333}
          onChange={handleChangeType}
          optionValue="name"
          initial={record && record["xtype"] ? record["xtype"] : "text"}
          optionText="text"
          source="xtype"
          name="xtype"
          record={record}
          choices={types}
        />
      }
      {React.createElement(fields[xtype || "textfield"], {
        name: "value",
        source: "value",
        flex: 0.333,
        initial: record && record["value"],
      })}
      {/* <Input
        name="value"
        source="value"
        flex={0.333}
        initial={record && record["value"]}
      /> */}
    </SimpleForm>
  );
};
const SortItem = (props) => {
  const [record, setRecord] = useState();
  useEffect(() => {
    if (_.isEqual(props.record, record)) setRecord(props.record);
  }, [props.record]);
  const SortableItem = SortableElement(
    ({ value, record, items, xtype, onAdd, sortIndex }) => {
      if (xtype == "list")
        return (
          <li className="drag-and-drop-item item-list">
            <div>
              <div className="sub-list">
                {/* JSON.stringify(record) */}
                <SortList
                  xtype={xtype}
                  onAdd={onAdd}
                  record={record}
                  title={value}
                  items={items}
                />
              </div>
            </div>
          </li>
        );

      return (
        <li className="drag-and-drop-item item-list">
          <DragHandle />
          <div className="field-container">
            <ItemForm
              onChangeType={(xtype) => {
                alert(xtype);
                setRecord({
                  ...record,
                  xtype,
                });
              }}
              record={record}
            />
          </div>
          <div className="field-tools">
            <Dropdown
              overlay={
                <MenuItems
                  className="menu-dropdown"
                  /* onMenuClick={(e) => onMenuClick(e, record)} */
                />
              }
            >
              <Button type="link" icon="more" />
            </Dropdown>
          </div>
        </li>
      );
    }
  );
  return (
    <SortableItem {...props} record={record} xtype={record && record.xtype} />
  );
};
const SortList = ({ title, xtype, ...props }) => {
  const [items, setItems] = useState([]);
  const [record, setRecord] = useState();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems((items) => arrayMove(items, oldIndex, newIndex));
  };
  const onMenuClick = ({ key }, record) => {
    switch (key) {
      case "delete":
        /* alert(record._id); */
        if (record._id) {
          let new_items = items.filter((it) => it._id != record._id);
          setItems(new_items);
        }
        break;
      default:
        break;
    }
  };
  const SortableList = SortableContainer(({ onAdd, record, title, items }) => {
    const handleAddSection = () => {
      if (onAdd) onAdd(record);
    };
    return (
      <div className="list-container">
        {!record && (
          <div className="list-head">
            <div className="list-title">{title || "Section"}</div>
            <div className="list-tools">
              <Tooltip placement="bottom" title={"Add Section"}>
                <Button
                  onClick={() => handleAddSection()}
                  shape="circle"
                  type="link"
                  icon="plus"
                />
              </Tooltip>
            </div>
          </div>
        )}
        <ul className="list-container">
          {items &&
            items.map((record, index) => {
              let { xtype, value } = record;
              return (
                <SortItem
                  key={`item-${index}`}
                  index={index}
                  onAdd={handleAddSection}
                  xtype={xtype}
                  sortIndex={index}
                  value={value}
                  items={items}
                  record={record}
                />
              );
            })}
        </ul>
      </div>
    );
  });
  useEffect(() => {
    setItems(props.items);
  }, [props.items]);
  useEffect(() => {
    if (items && items.length > 0) console.log("items", items);
  }, [items]);
  useEffect(() => {
    if (props.record && !_.isEqual(props.record, record)) {
      setRecord(props.record);
    }
  }, [props.record]);
  useEffect(() => {
    if (record && record.items) {
      setItems(record.items);
    }
  }, [record]);
  return (
    <>
      {/* record && JSON.stringify(record) */}
      {record && (
        <div className="list-head">
          <div className="list-title">
            <DragHandle />
            <h3>{title || "Section"}</h3>
          </div>
          <div className="list-tools">
            <Tooltip placement="bottom" title={"Add Section"}>
              <Button
                /* onClick={() => {
                  setItems([...items, { _id: uuid(), value: "" }]);
                }} */
                shape="circle"
                type="link"
                icon="plus"
              />
            </Tooltip>
          </div>
        </div>
      )}
      {record && (
        <div className="list-title">
          <div className="field-container">
            <ItemForm
              onChangeType={(xtype) => {
                alert("xtype:: " + xtype);
                setRecord({
                  ...record,
                  xtype,
                });
              }}
              record={record}
            />
          </div>
          <div className="field-tools">
            <Dropdown
              overlay={
                <MenuItems
                  className="menu-dropdown"
                  onMenuClick={(e) => onMenuClick(e, record)}
                />
              }
            >
              <Button type="link" icon="more" />
            </Dropdown>
          </div>
        </div>
      )}
      {
        <SortableList
          onAdd={(record) => {
            let new_items = [...items, { _id: uuid(), value: "" }];
            alert(typeof record);
            if (record) {
              setRecord({
                ...record,
                items: new_items,
              });
            }
            /* setItems(new_items); */
          }}
          record={record}
          title={title}
          items={items}
          lockAxis="y"
          onSortEnd={onSortEnd}
          useDragHandle
        />
      }
    </>
  );
};
const JSONField = (props) => {
  const Context = useContext(JSONContext);
  const [dataSource, setDataSource] = useState([
    {
      _id: uuid(),
      value: "a",
      name: "price",
      xtype: "numberfield",
    },
    {
      _id: "333",
      value: "Seccion A",
      name: "members",
      xtype: "list",
      items: [
        { _id: uuid(), name: "name", value: "c" },
        { _id: uuid(), name: "name", value: "cadasd" },
      ],
    },
  ]);
  useEffect(() => {
    if (props.dataSource && !_.isEqual(props.dataSource, dataSource))
      setDataSource(props.dataSource);
  }, [props.dataSource]);
  return (
    <JSONContext.Provider value={{ items: dataSource }}>
      <WrapperJSONField className="sortable-list-container">
        <SortList
          /* onAdd={() => setDataSource([...dataSource, { _id: uuid(), value: "" }])} */
          items={dataSource}
        />
      </WrapperJSONField>
    </JSONContext.Provider>
  );
};
export default JSONField;
