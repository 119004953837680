import React, { useState, useEffect } from "react";
import DocumentItem from "./DocumentItem";
import {
  List,
  ConfigProvider,
  Icon,
  Avatar,
  Divider,
  Tooltip,
  message,
  Button,
} from "antd";
import moment from "moment";
import { FileUpload } from "../com/form/";
import { DocumentListWrapper } from "./Styles";
import { Empty } from "../com/loader";
import Uploader from "../../components/com/Uploader";
import { getService } from "../../services";
const formats = [
  // Images
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/svg+xml",
  "image/webp",

  // Documents
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/plain",
  "text/txt",

  // Audio
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",

  // Video
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/x-ms-wmv",
];
const { Meta, Item } = List;
const defaultActions = {
  delete: true,
  download: true,
};
const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <Empty
      image={Empty.PRESENTED_IMAGE_DEFAULT}
      description={"There are no files yet, start by adding one."}
    />
  </div>
);
const DocumentList = ({
  onRemove,
  actions = defaultActions,
  extra,
  title,
  icon,
  showEmpty = false,
  onUpload,
  onSubmit,
  onChange,
  onSave,
  section_id,
  onSaveSubmit,
  ...props
}) => {
  const [documents, setDocuments] = useState([]);
  const [default_documents, setDefaultDocuments] = useState([]);
  const [media, setMedia] = useState([]);
  const [record, setRecord] = useState();
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [index, setIndex] = useState();

  const renderItem = (record, index) => {
    setIndex(index);
    return (
      <DocumentItem
        index={index}
        record={record}
        actions={actions}
        extra={extra}
        key={record._id}
        onRemove={onRemove}
        documents={documents}
        {...record}
      />
    );
  };
  const save = async (file) => {
    if (onSaveSubmit) return onSaveSubmit(file);
    const service = getService("documents");
    let payload = {
      name: file.name,
      path: file.key,
      [section_id === record._id ? "section_id" : "task_id"]: record._id,
    };
    setSubmitting(true);
    try {
      let resp = await service.create(payload);
      if (onSubmit) onSubmit(resp);
      console.log("resp", resp);
      if (resp) setSubmitting(false);
    } catch (error) {
      message.error(error.message);
      setSubmitting(false);
    }
  };
  const handleOnChange = ({ key, ...file }) => {
    delete file.url;
    let files = media;
    file["uid"] = `${files.length}`;
    file["key"] = key;
    file["url"] = file.location;
    file["status"] = file.status === 204 ? "done" : "error";
    files.push(file);
    save(file);
    setMedia((prevFiles) => {
      return [...files];
    });
  };
  const handleOnDelete = ({ key, ...file }) => {
    let files = media.filter((it) => it.status !== "deleted");
    save(files);
    setMedia((prevFiles) => {
      return [...files];
    });
  };
  const handleUpload = (file, name, item) => {
    if (onUpload) onUpload(file, !item.name ? file.name : name);
  };
  useEffect(() => {
    setDocuments(props.documents || []);
  }, [props.documents]);
  useEffect(() => {
    setDefaultDocuments(props.default_documents || []);
  }, [props.default_documents]);

  useEffect(() => {
    if (props.record) {
      setRecord(props.record);
    }
  }, [props.record]);
  useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props.open]);
  if (!showEmpty) return null;
  return (
    <DocumentListWrapper className="documments-wrapper">
      {title && documents.length > 0 && (
        <div className="section-title-attachments">
          <h3>
            {icon && <Icon size="small" type={icon} />} {title}
          </h3>
          {/*  <Button
            onClick={() => setOpen(true)}
            className="btn-dragandrop-cancel"
            icon={"paper-clip"}
          >
            Attachments
          </Button> */}
        </div>
      )}
      {record && open && (
        <div>
          <Uploader
            onChange={handleOnChange}
            onDelete={handleOnDelete}
            path={`documents/${record._id}`}
            fileList={media}
            formats={formats}
            autoIncrement={false}
            showSupportFormat={false}
            showUploadList={false}
            submitting={submitting}
          />
          <div className="flex justify-end item-center drag-btn">
            <Button
              className="btn-dragandrop-cancel"
              onClick={() => setOpen((open) => !open)}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {default_documents.length > 0 && (
        <div className="documents">
          <div className="item-container">
            {default_documents.map((item, index) => {
              let record = documents.find((it) => it.name === item.title);
              return (
                <div
                  className={`item-upload ${index === 0 ? "item-first" : ""}`}
                >
                  <FileUpload
                    /* disabled={it.disabled} */
                    size="large"
                    idComponent="btn-upload"
                    onChange={(file) => handleUpload(file, item.title, item)}
                    path="tasks"
                    allowAll={true}
                    finalPath="attachments"
                    id={item._id}
                  >
                    <span className="item-container">
                      {index > 0 ? (
                        <span>
                          <Icon type="paper-clip" />
                          <span>{item.title}</span>
                        </span>
                      ) : (
                        <span>
                          <Avatar src="/images/paper.svg" />
                          <span>{item.title}</span>
                        </span>
                      )}
                      {documents.some((it) => it.name === item.title) && (
                        <Tooltip
                          title={`${
                            record && record.status !== "approved"
                              ? "Pending"
                              : "Aproved"
                          }`}
                        >
                          <Icon
                            theme="twoTone"
                            twoToneColor={
                              record && record.status === "approved"
                                ? "#52c41a"
                                : "#eb2f96"
                            }
                            type={
                              record && record.status === "approved"
                                ? "check-circle"
                                : record && record.status === "pending"
                                ? "exclamation-circle"
                                : ""
                            }
                          />
                        </Tooltip>
                      )}
                    </span>
                  </FileUpload>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {documents.length > 0 && (
        <Empty>
          <List dataSource={documents} renderItem={renderItem} />
        </Empty>
      )}
    </DocumentListWrapper>
  );
};
export default DocumentList;
