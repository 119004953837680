import React, { useState, useEffect, useContext } from "react";
import { Avatar, Button, Image, message, Space, Spin, Upload } from "antd";
import { URL_S3_SERVER } from "../../../constants";
import axios from "axios";
const FileInput = ({
  xtype,
  rules,
  fullWidth,
  flex,
  label,
  name,
  source,
  reference = "upload",
  buttonText = "Change Photo",
  onChange,
  buttonStyle,
  type = "avatar",
  path = "uploads",
  accept = ["image/jpeg", "image/png"],
  children,
  customRequest,
  onUpload,
  ...props
}) => {
  const [uploading, setUploading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [value, setValue] = useState();
  const defaultRequest = async ({ onError, onSuccess, ...params }) => {
    let file = params.file;
    let { name, type } = file;
    let query = {
      objectName: name,
      contentType: type,
      path: path || "resources/",
    };
    return new Promise(async (resolve, reject) => {
      try {
        setUploading(true);
        let { data } = await axios({
          method: "GET",
          url: `${URL_S3_SERVER}/s3Client/sign`,
          //data: bodyFormData,
          params: query,
          /* headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          }, */
        });
        let { signedUrl } = data;
        await axios.put(signedUrl);
        console.log("---> Response: ", data);
        setUploading(false);
        resolve({ ...data, name, path: data.filename });
      } catch (error) {
        onError(error);
        setUploading(false);
        reject(error);
      }
    });
  };
  const handleOnUpload = async ({ onError, onSuccess, ...params }) => {
    if (customRequest) {
      try {
        setUploading(true);
        let res = await customRequest({
          onError,
          onSuccess,
          setUploading,
          ...params,
        });
        if (onUpload) onUpload(res);
        setUploading(false);
        console.log("File Uploaded: ", res);
      } catch (err) {
        setUploading(false);
        message.error(err.message);
      }
    } else {
      let res = await defaultRequest({
        onError,
        onSuccess,
        setUploading,
        ...params,
      });
      if (onUpload) onUpload(res);
      setUploading(false);
      console.log("File Uploaded: ", res);
    }
  };
  const handleOnChange = (e) => {
    console.log("CHANGED:: ", e);
  };
  const handleOnStart = (file) => {
    console.log("onStart", file.name);
  };
  const handleOnSuccess = (data, file) => {
    setUploading(false);
    setValue(data.path);
    console.log("success: ", data.path);
    if (onChange) onChange(data.path);
  };
  const handleOnError = (err) => {
    message.error(err.message);
    setUploading(false);
  };
  const handleOnBeforeUpload = (file) => {
    const allowType = accept.includes(file.type);
    let type = file.type;
    type = type.includes("image")
      ? "image"
      : type.substring(type.indexOf("/") + 1, type.length);
    if (!allowType) {
      message.error(
        `You can only upload ${accept
          .map((type) => type.substring(type.indexOf("/") + 1, type.length))
          .join(",")} file!`
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return allowType && isLt2M;
  };
  const _handleOnBeforeUpload = (file) => {
    const fileTypes = file.type === "application/zip";
    if (!fileTypes) {
      message.error("You can only upload .zip file!");
    }
    /* const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return fileTypes && isLt2M; */
    return fileTypes;
  };
  useEffect(() => {
    if (props.record) {
      if (props.record[source || name]) setValue(props.record[source || name]);
    }
    if (!initialized) setInitialized(true);
  }, [props.record]);
  if (!initialized) return null;
  return (
    <Upload
      listType="picture-card"
      showUploadList={false}
      {...props}
      disabled={uploading}
      thumbUrl={value}
      isImageUrl={(file) => JSON.stringify(file)}
      onStart={handleOnStart}
      onSuccess={handleOnSuccess}
      onError={handleOnError}
      onChange={handleOnChange}
      customRequest={handleOnUpload}
      beforeUpload={handleOnBeforeUpload}
      accept={accept}
    >
      {children}
      {!children && (
        <Button
          style={{
            height: 50,
            fontSize: 30,
            ...props.buttonStyle,
          }}
          size="large"
          loading={uploading}
          type="link"
          icon={"upload"}
        >
          {buttonText}
        </Button>
      )}
    </Upload>
  );
};
FileInput.defaultProps = {
  xtype: "fileinput",
};
export default FileInput;
