import React, { useState, useEffect } from "react";
import { List, Avatar, message, Icon, Mentions, Button } from "antd";
import { MemberListWrapper, ModalBody } from "./Styles";
import { getService } from "../../services";
import { URL_S3, URL_DEFAULT_AVATAR } from "../../constants";
import { ToolBar } from "../markdown/Styles";
import Mention from "./Mention";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Empty } from "../../components/com/loader/";
import { tasks } from "../../services/services";
const { Item } = List;
const { Meta } = Item;
const { Option } = Mentions;
const ItemList = ({
  _id,
  task_id,
  onChange,
  selectes,
  onMenuClick,
  ...props
}) => {
  const [selected, setSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const user = useSelector(({ user }) => user);
  const handleOnChange = () => {
    if (onChange && !submitting) onChange(_id, props);
    setSubmitting(true);
  };
  useEffect(() => {
    tasks.on("patched", (task) => {
      if (task._id == task_id) {
        setSubmitting(false);
      }
    });
  }, []);
  useEffect(() => {
    if (selectes) {
      setSelected(selectes.includes(_id));
    }
  }, [selectes]);
  return (
    <Item
      onClick={() => handleOnChange()}
      className={submitting && "item-submitting"}
    >
      <Meta
        avatar={
          <Avatar
            src={
              props.picture ? `${URL_S3}/${props.picture}` : URL_DEFAULT_AVATAR
            }
          />
        }
        title={
          <div>
            <ToolBar>
              <span>
                {props.first_name || ""} {props.last_name || ""}
              </span>
              {false && (
                <Icon
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "calc(70%)",
                  }}
                  type={submitting ? "loading" : "check"}
                />
              )}
            </ToolBar>
          </div>
        }
        description={
          <ToolBar>
            <div className="item-list-description">
              {props.email && (
                <span>
                  @{props.email.substring(0, props.email.indexOf("@"))}
                </span>
              )}
              {submitting && <Icon type={submitting ? "loading" : "check"} />}
              {/* (
                <div>
                  <Button
                    loading={submitting}
                    onClick={() => {
                      handleOnChange();
                    }}
                    type="link"
                    icon="close"
                  />
                </div>
              ) */}
            </div>
          </ToolBar>
        }
      />
    </Item>
  );
};
const MemberList = ({
  board = {},
  onChange,
  title,
  add,
  tooltip,
  defaultFilterValues,
  ...props
}) => {
  //members_ids
  //team_id
  const user = useSelector(({ user }) => user);
  const [members_ids, setMemberIds] = useState([]);
  const [task_id, setTaskId] = useState();
  const [selectes, setSelectes] = useState();
  const [members, setMembers] = useState([]);
  const [searching, setSearching] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState([]);
  const handleOnChange = (id, record) => {
    if (onChange) onChange(id, record);
  };
  const getTeamMembers = (query) => {
    const service = getService("get-all-users");
    if (query) {
      setSearching(true);
      service
        .find({
          query,
        })
        .then(({ data }) => {
          console.log("DATA: ", data);
          setSearching(false);
          setMembers(data);

          if (!initialized) {
            setMemberIds(data.map((it) => it._id));
            setInitialized(true);
          }
        })
        .catch((err) => {
          setSearching(false);
          message.error(err.message);
        });
    }
  };
  const renderItem = (record) => {
    return (
      <ItemList
        task_id={props._id}
        selected={members_ids && members_ids.includes(record._id)}
        onChange={handleOnChange}
        selectes={members_ids}
        {...record}
      />
    );
  };
  const search = (value) => {
    if (!value)
      return getTeamMembers({
        ...defaultFilterValues,
      });
    if (value && value != "@")
      getTeamMembers({
        search: value,
        ...defaultFilterValues,
      });
  };
  useEffect(() => {
    setMemberIds(props.members_ids);
  }, [props.members_ids]);
  useEffect(() => {
    if (props.members) {
      setMembers(props.members);
    }
  }, [props.members]);
  useEffect(() => {
    if (props._id) setTaskId(props._id);
  }, [props._id]);
  useEffect(() => {
    tasks.on("patched", (task) => {
      if (task._id == props._id) {
        let { members, members_ids } = task;
        setMembers(members);
        setMemberIds(members_ids);
      }
    });
  }, []);
  if (!user || !task_id) return null;
  return (
    <ModalBody>
      <MemberListWrapper>
        {title && <h3>{title}</h3>}
        <Empty image="/images/team-members.svg">
          <List
            bordered={false}
            style={{
              minHeight: 200,
            }}
            grid={{
              gutter: 2,
            }}
            loading={searching}
            className="list-members"
            itemLayout={props.itemLayout || "horizontal"}
            dataSource={members}
            renderItem={renderItem}
          />
        </Empty>
        {props.placeholder && (
          <label
            style={{
              marginBottom: 0,
              fontSize: 16,
            }}
          >
            {props.placeholder}
          </label>
        )}
        {
          <Mention
            defaultFilterValues={{
              company_id: user.company_id,
            }}
            onSearch={search}
            placeholder={"Search"}
            optionText={(record) =>
              `${record.first_name || ""} ${record.last_name || ""}`
            }
            members_ids={members_ids}
            source="get-all-users"
          />
        }
      </MemberListWrapper>
    </ModalBody>
  );
};
export default MemberList;
