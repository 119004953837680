import React, { useState } from "react";
import {
  message,
  List,
  Avatar,
  InputNumber,
  Card,
  Button,
  Divider,
  Popconfirm,
  Row,
  Col,
} from "antd";
import DragAndDropUploader from "./DragAndDropUploader";
import { getService } from "../../../services/";
import { URL_S3, s3PathImageHandrer } from "../../../constants/";
import { Wrapper } from "./Styles";
import _ from "lodash";
import { useEffect } from "react";
import Carousel from "./Carousel";
import uuid from "react-uuid";
const { Meta } = Card;

const serviceGalleryMedia = getService("gallery-media");
const serviceGallery = getService("gallery");

const GalleryUploader = ({
  dataSource,
  onChange,
  onUpload,
  source,
  name,
  optionValue,
  path = "galleries",
  filterDefaultValues = {},
  onAdd,
  _id,
  ...props
}) => {
  const [media, setMedia] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [record, setRecord] = useState();
  const [reference, setReference] = useState();
  const update = (file) => {
    let { reference, onChange } = props;
    /*  if (!record && onUpload) return onUpload(source, file); */
    if (reference && record && source) {
      const service = getService(reference);
      let value = file.key;
      if (record && record[source || name]) {
        value = record[source || name];
        if (Array.isArray(value)) {
          value.push(file.key);
        }
      }
      return alert(JSON.stringify(record));
      /* console.log(value, record, record[source || name]); */
      if (_id)
        service
          .patch(_id, {
            [source]: [...record[source || name], value],
          })
          .then((res) => {
            setImage(file.key);
            setShow(true);
            if (onChange) onChange(source, value);
            if (onUpload) onUpload(source, file);
          })
          .catch((err) => {
            message.error(err.message);
          });
    }
  };
  const handleOnChange = ({ key, type, ...file }) => {
    delete file.url;
    let files = media;
    file["uid"] = `${files.length}`;
    file["key"] = key;
    file["type"] = type;
    file["url"] = file.location;
    file["path"] = file.location;
    file["status"] = file.status === 204 ? "done" : "error";
    files.push(file);
    let value = key;
    if (record && record[source || name]) {
      value = record[source || name];
      if (Array.isArray(value)) {
        value.push(key);
      }
    }
    /* if (props.form && source)
      props.form.setFieldsValue({
        [source]: value,
      }); */
    /* setMedia((media) => [...media, key]); */
    if (onUpload) onUpload(source, file, media);
    /* update(file); */
  };
  const create = () => {
    if (reference) {
      const service = getService(reference);
      let payloads = {};
      if (props.form) payloads = props.form.getFieldsValue();
      return service
        .create(payloads)
        .then((record) => {
          if (onAdd) return onAdd(source, record);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  const handleOnBeforeUpload = () => {
    if (record && record._id) return;
    if (onAdd) return onAdd(source, record);
    /* return create(); */
  };
  const handlePriority = (value, record) => {
    /*
    setMedia([]);
     if (record.id)
      serviceGalleryMedia
        .patch(record.id, {
          priority: value,
        })
        .then((response) => {
          if (onChange) onChange(response);
          getMedia();
        })
        .catch((err) => message.error(err.message)); */
  };
  useEffect(() => {
    if (props.reference) {
      setReference(props.reference);
    }
  }, [props.reference]);
  useEffect(() => {
    if (props.record) {
      setRecord(props.record);
      if (source && props.record[source]) {
        setShow(typeof props.record[source] !== "undefined");
        let value = props.record[source];
        if (!Array.isArray(value)) {
          setImage(value);
        }
      }
    }
  }, [props.record]);
  useEffect(() => {
    if (media) console.log(`Media:`, media);
  }, [media]);
  if (!record) return <>Record not found</>;
  return (
    <Wrapper>
      <Row>
        {image && show && (
          <Col>
            <div className="image-container">
              {image && (
                <img src={`${URL_S3}/${image}`} width="100%" height="300px" />
              )}
              <div className="image-actions">
                <Button
                  onClick={() => {
                    if (props.form && source)
                      props.form.setFieldsValue({
                        [source]: null,
                      });
                    setShow(false);
                  }}
                  icon="close"
                  size="small"
                  shape="circle"
                  type="primary"
                />
              </div>
            </div>
          </Col>
        )}
        {!image && (
          <Col>
            <DragAndDropUploader
              dataSource={media}
              record={record}
              source={source}
              name={name}
              optionValue={optionValue}
              onChange={handleOnChange}
              onBeforeUpload={handleOnBeforeUpload}
              /*onDelete={handleOnDelete} */
              _id={_id}
              path={path}
            />
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};
export default GalleryUploader;
