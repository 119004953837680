import {
  Button,
  Dropdown,
  Icon,
  Input,
  Menu,
  message,
  Skeleton,
  Tooltip,
  Alert,
  Modal,
  Row,
  Col,
  Popover,
  Affix,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import { TaskFormWrapper, TaskToolBarWrapper } from "./Styles";
import { FileUpload } from "../com/form/";
import { TaskContext } from "./context";
import { useContext } from "react";
import { getService } from "../../services";
import { useRef } from "react";
import { SimpleForm } from "../com/form/";
import { MainContext } from "../../context";
import _ from "lodash";
import { useSelector } from "react-redux";
import { SelectGroupField } from "../com/fields";
import DocumentList from "./DocumentList";
import Comments from "../comments/Comments";
import { CommentInput } from "../comments/";
import DescriptionText from "./DescriptionText";
import DateTaskForm from "../../views/tasks/DateTaskForm";
import TaskCheckList from "./TaskCheckList";
import CheckTaskList from "./CheckTaskList";
import TagTasks from "./TagTasks";
import ListTags from "./ListTags";
import { MarkDown } from "..";
import AddMembers from "./AddMembers";
const { confirm } = Modal;

const MenuAttachments = ({ id, onUpload, ...props }) => {
  return (
    <Menu /* onClick={handleMenuClick} */>
      <Menu.Item disabled>Attach a file</Menu.Item>
      <Menu.Item key="attacht" className="menu-item-attach">
        <FileUpload
          size="large"
          idComponent="btn-upload"
          onChange={onUpload}
          path={"tasks/" + id + "/attachments/"}
          allowAll={true}
          finalPath="attachments"
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon type="paper-clip" />
            <span
              style={{
                fontSize: 18,
              }}
            >
              Attach from pc
            </span>
          </span>
        </FileUpload>
      </Menu.Item>
    </Menu>
  );
};
const TaskToolBar = ({ id, onClose, closable, ...props }) => {
  const { onToolClick, loading } = useContext(TaskContext);
  const handleMenuClick = (e) => {
    let key = e.key;
    if (key === "archived") {
      const service = getService("tasks");
      return confirm({
        title: "Do you want to delete this task?",
        onOk() {
          if (key === "archived") {
            return service
              .remove(id)
              .then((response) => {
                message.success("task removed!");
                if (onClose) onClose();
              })
              .catch((err) => {
                message.error(err.message);
              });
          }
        },
        onCancel() {},
      });
    }
  };

  const handleonToolClick = (key) => {
    switch (key) {
      case "file":
        break;
      default:
        if (onToolClick) onToolClick(key);
        break;
    }
  };
  const MenuActions = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">
        <Icon type={"edit"} />
        {"Rename Task"}
      </Menu.Item>
      {/*  <Menu.Item key="show">
        <Icon type="eye" />
        Show Detail
      </Menu.Item> */}
      <Menu.Item className="text-red" key="archived">
        <Icon type="delete" />
        Remove Task
      </Menu.Item>
    </Menu>
  );
  return (
    <TaskToolBarWrapper className="task-toolbar">
      {!loading && (
        <div className="action-tools">
          <Tooltip placement="bottom" title={"Share Link Task"}>
            <Button
              className="flex item-centered justify-center gap-sm m-0"
              onClick={() => handleonToolClick("copy")}
            >
              Copy Link <Icon type="share-alt" />
            </Button>
          </Tooltip>
          <Tooltip placement="bottom" title={"More actions"}>
            <Dropdown
              overlayClassName="menu-actions"
              trigger={["click"]}
              overlay={MenuActions}
            >
              <Button
                className="flex item-center justify-center"
                type="link"
                icon="ellipsis"
              />
            </Dropdown>
          </Tooltip>
        </div>
      )}
      <Skeleton active loading={loading} paragraph={false} />
      <div className="drawer-actions">
        {closable && (
          <Button onClick={onClose} icon="close" size="large" type="link" />
        )}
      </div>
    </TaskToolBarWrapper>
  );
};

const TaskForm = ({
  onClose,
  onCloseTask,
  view,
  closable = false,
  foreclosure,
  ...props
}) => {
  let MyInput = useRef(null);
  const user = useSelector(({ user }) => user);
  const service = getService("tasks");
  const commentService = getService("comments");
  const [views, setViews] = useState([]);
  const [members_ids, setMembersIds] = useState([]);
  const [task, setTask] = useState();
  const [documents, setDocuments] = useState([]);
  const [comments, setComments] = useState([]);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading_subtask, setLoadingSubtask] = useState(true);
  const [id, setId] = useState();
  const [initialized, setInitialized] = useState(false);
  const [loading_comments, setLoadingComments] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [display, setDisplay] = useState({});
  const [copying, setCopying] = useState(false);
  const [subtasks, setSubTasks] = useState([]);
  const [payloads, setPayloads] = useState({});
  const [memberfilters, setMemberFilters] = useState({});
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [on_input, setOnInput] = useState(false);
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  let documentService = getService("documents");
  const handleVisibleChange = (name) => {
    setDisplay((display) => ({ ...display, [name]: !display[name] }));
  };
  const autoSubmitTask = () => {
    getTask();
  };
  const onSubmitTaskMembers = (resp) => {
    setTask(resp);
    setFilterDefaultValues({
      status: "active",
      company_id: user && user.company_id,
    });
    // getTask();
  };
  const handleonToolClick = (key) => {
    switch (key) {
      case "file":
        break;
      default:
        if (onToolClick) onToolClick(key);
        break;
    }
  };
  const onSubmit = (resp) => {
    if (resp._id) getTask();
    setDisplay((display) => ({
      ...display,
      checktasklist: !display["checktasklist"],
    }));
  };
  const list = [
    {
      name: "copy",
      text: "Copy Link",
      icon: <Icon type="share-alt" />,
    },
    {
      name: "member",
      text: "Members",
      render: (record) =>
        task && task._id ? (
          <Popover
            className="popover-container"
            content={
              // <>hola</>
              <AddMembers
                checkView={true}
                task={task}
                id={task && task._id}
                user={user}
                refresh={refresh}
                onSubmit={onSubmitTaskMembers}
                height={300}
                reference="users"
                referencePatch="tasks"
                filterDefaultValues={{
                  ...filterDefaultValues,
                  status: "active",
                  company_id: user && user.company_id,
                }}
              />
            }
            title={"Add Members"}
            trigger="click"
            placement="leftTop"
            // onBlur={() => alert("blur")}
            overlayClassName="menu-popover"
            visible={display["memberstasklist"]}
            // onVisibleChange={() => handleVisibleChange(record.name)}
            onVisibleChange={() => {
              setDisplay((display) => ({
                ...display,
                memberstasklist: !display["memberstasklist"],
              }));
              handleVisibleChange(record.name);
            }}
          >
            <Button icon="user" type="primary">
              Members
            </Button>
          </Popover>
        ) : (
          <Button icon="user" type="primary" onClick={handleDefault}>
            Members
          </Button>
        ),
    },
    {
      name: "tag",
      text: "Tags",
      // icon: <Icon type="tag" />,
      render: (record) =>
        task && task._id ? (
          <Popover
            className="popover-container"
            content={
              <TagTasks
                task={task}
                // title="List Tags"
                user={user}
                autoSubmitTask={autoSubmitTask}
              />
            }
            title="Tag Lists"
            trigger="click"
            placement="leftTop"
            overlayClassName="menu-popover"
            visible={display[record.name]}
            onVisibleChange={() => handleVisibleChange(record.name)}
          >
            <Button icon="tag" type="primary">
              Tags
            </Button>
          </Popover>
        ) : (
          <Button icon="tag" type="primary" onClick={handleDefault}>
            Tags
          </Button>
        ),
    },
    {
      name: "date",
      text: "Date",
      // icon: <Icon type="clock-circle" />,
      render: (record) =>
        task && task._id ? (
          <Popover
            content={
              <DateTaskForm
                id={id}
                record={task}
                reference="tasks"
                onSubmit={() => getTask()}
              />
            }
            title="Task Reminders"
            trigger="click"
            placement="leftTop"
            overlayClassName="menu-popover"
            visible={display[record.name]}
            onVisibleChange={() => handleVisibleChange(record.name)}
          >
            <Button icon="clock-circle" type="primary">
              Reminders
            </Button>
          </Popover>
        ) : (
          <Button icon="clock-circle" type="primary" onClick={handleDefault}>
            Reminders
          </Button>
        ),
    },
    {
      name: "check",
      text: "Checklist",
      // icon: <Icon type="check-square" />,
      render: (record) =>
        task && task._id ? (
          <Popover
            content={
              <CheckTaskList
                reference="tasks"
                task={task}
                onSubmit={onSubmit}
              />
            }
            title="Add Subtask"
            trigger="click"
            placement="leftTop"
            overlayClassName="menu-popover"
            visible={display["checktasklist"]}
            onVisibleChange={() => {
              setDisplay((display) => ({
                ...display,
                checktasklist: !display["checktasklist"],
              }));
              handleVisibleChange(record.name);
            }}
          >
            <Button icon="check-square" type="primary">
              SubTasks
            </Button>
          </Popover>
        ) : (
          <Button icon="check-square" type="primary" onClick={handleDefault}>
            SubTasks
          </Button>
        ),
    },
    {
      name: "attach",
      text: "Attachments",
      icon: <Icon type="paper-clip" />,
      /*  render: (record) => (
        <Button type="primary">
          <Icon type="paper-clip" />
          Attachments
        </Button>
      ), */
    },
    {
      name: "removed",
      text: "Remove Task",
      icon: <Icon type="delete" />,
    },
  ];
  let {
    changed,
    withoutsave,
    setWithOutSave,
    setChanged,
    setCurrentTask,
  } = useContext(MainContext);
  //let { setCurrentTask } = useContext(BoardContext);
  const handleOnClose = () => {
    if (onClose) onClose();
  };
  const getComments = () => {
    if (id) {
      setLoadingComments(true);
      commentService
        .find({
          query: {
            task_id: id,
            $sort: {
              createdAt: -1,
            },
          },
        })
        .then(({ data }) => {
          setComments(data);
          setLoadingComments(false);
        })
        .catch((err) => {
          setLoadingComments(false);
          message.error(err.message);
        });
    }
  };
  const handleRemoveComment = (id) => {
    const service = getService("comments");
    if (id)
      return service
        .remove(id)
        .then((response) => {
          // getComments();
          message.success("Comment removed!");
        })
        .catch((err) => {
          message.error(err.message);
        });
  };
  const handleOnMentions = (mentions_ids) => {
    setPayloads({
      ...payloads,
      mentions_ids,
    });
  };
  const handleSubmitComments = async (name, value, _id) => {
    const service = getService("comments");
    let task_id = id;
    if (_id)
      return service
        .patch(_id, {
          [name]: value,
          ...payloads,
        })
        .then((response) => {
          // getComments();
          message.success("Comment updated!");
        })
        .catch((err) => {
          message.error(err.message);
        });

    if (!task_id) {
      const taskService = getService("tasks");
      if (!payloads.subject) return message.error(`Subject isrequired!`);
      const response = await taskService.create(payloads);
      setTask(response);
      task_id = response._id;
    }
    service
      .create({
        task_id,
        [name]: value,
      })
      .then((response) => {
        // getComments();
        message.success("Commend Added!");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const getTask = async () => {
    try {
      setLoading(true);
      let task = await service.get(id);
      setTask(task);
      getComments();
      setLoading(false);
      setInitialized(true);
      setMemberFilters({
        _id: {
          $in: [...task.members_ids],
        },
      });
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };
  const save = async () => {
    console.log("id, payloads", id, payloads);
    try {
      let task;
      setSubmitting(true);
      if (id) {
        task = await service.patch(id, payloads);
      } else {
        task = await service.create(payloads);
        message.success("Task Created");
      }
      // setCurrentTask(task);
      setTask(task);
      setId(task._id);
      setWithOutSave(false);
      setChanged(false);
      setEditTitle(false);
      setSubmitting(false);
      setOnInput(false);
    } catch (err) {
      setSubmitting(false);
      message.error(err.message);
    }
  };
  const copyCodeToClipboard = () => {
    setCopying(true);
    const el = MyInput;
    el.select();
    document.execCommand("copy");
    setCopying(false);
    message.info("Lik copied to clipboard");
  };
  const copyingText = ({ text, ...config }) => {
    navigator.clipboard.writeText(text);
    message.info(config.message || `Text copied to clipboard`);
  };
  const onToolClick = (key) => {
    switch (key) {
      case "copy":
        copyingText({
          text: window.location.href,
          message: `Lik copied to clipboard`,
        });
        break;
      default:
        let display_views = Array.from(views);
        if (!display_views.includes(key)) display_views.push(key);
        else display_views = display_views.filter((view) => view !== key);
        setViews(display_views);
        break;
    }
  };
  const handleOnChange = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
    setChanged(true);
  };
  const getDocuments = () => {
    if (id)
      documentService
        .find({
          query: {
            task_id: id,
            $sort: {
              createdAt: -1,
            },
          },
        })
        .then(({ data }) => {
          setDocuments(data);
        })
        .catch((err) => message.error(err.message));
  };
  const handleOnDocumentSubmit = (documents) => {
    getDocuments();
  };
  const onUpload = async (file, name) => {
    let { contact_id } = form.getFieldsValue();
    const service = getService("documents");
    try {
      const doc = await service
        .find({
          query: {
            name,
            task_id: id,
          },
        })
        .then(({ data }) => data[0]);
      if (doc) {
        return service
          .patch(doc._id, {
            ...file,
            name: name || file.name,
            task_id: id,
            contact_id,
          })
          .then((response) => {
            message.info("File Updated!");
            getDocuments();
          })
          .catch((err) => message.error(err.message));
      }
      if (!doc)
        await service
          .create({
            ...file,
            name: file.name,
            task_id: id,
            contact_id,
          })
          .then((response) => {
            getDocuments();
            message.info("File Uploaded!");
          })
          .catch((err) => message.error(err.message));
    } catch (err) {
      message.error(err.message);
    }
  };
  const onRemoveDocument = async (id) => {
    documentService
      .remove(id)
      .then((response) => {
        message.info("Record removed!");
        getDocuments();
      })
      .catch((err) => message.error(err.message));
  };

  const handleOnclick = (name) => {
    if (task && task._id) {
      switch (name) {
        case "attach":
          setOpen((open) => !open);
          break;
        case "date":
          setDisplay((display) => ({ ...display, [name]: !display[name] }));
          break;
        case "removed":
          return confirm({
            content: (
              <div>
                This file will be permanently removed if you delete it. Please
                confirm if you wish to proceed.
              </div>
            ),
            onOk() {
              if (name === "removed") {
                return service
                  .remove(id)
                  .then((resp) => {
                    message.success("Task removed!");
                    if (onClose) onClose();
                  })
                  .catch((err) => {
                    message.error(err.message);
                  });
              }
            },
            onCancel() {},
          });
          break;
        case "copy":
          copyingText({
            text: window.location.href,
            message: `Link copied to clipboard`,
          });
          break;
        default:
          break;
      }
    } else if (!task) {
      handleDefault();
    }
  };
  const handleDefault = () => {
    message.warning("Tasks need a title to get started.");
  };
  const getSubTasks = () => {
    setLoadingSubtask(true);
    const service = getService("tasks");
    service
      .find({
        query: {
          task_id: task._id,
          status: { $ne: "archived" },
        },
      })
      .then(({ data }) => {
        setSubTasks(data);
        setLoadingSubtask(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoadingSubtask(false);
      });
  };

  useEffect(() => {
    if (id) getTask();
  }, [id]);
  useEffect(() => {
    return () => {
      setChanged(false);
    };
  }, []);
  useEffect(() => {
    if (!_.isEqual(payloads.members_ids, members_ids))
      setMembersIds(payloads.members_ids);
  }, [payloads]);
  useEffect(() => {
    if (members_ids && initialized) save();
  }, [members_ids]);
  useEffect(() => {
    if (task) {
      getSubTasks();
      setDocuments(task.documents);
      //setComments(task.comments);
    }
  }, [task]);
  useEffect(() => {
    if (documents && task) {
      let current_task = task;
      if (current_task) {
        current_task["documents"] = documents;
        setCurrentTask(current_task);
      }
    }
  }, [documents]);
  useEffect(() => {
    if (typeof props.contact_id != "undefined") {
      setPayloads({
        ...payloads,
        [foreclosure ? "foreclosure_id" : "contact_id"]: props.contact_id,
      });
      setLoading(false);
      setInitialized(true);
    }
  }, [props.contact_id]);
  useEffect(() => {
    if (props.id) setId(props.id);
  }, [props.id]);
  return (
    <TaskContext.Provider
      value={{
        views,
        loading,
        task,
        setTask,
        onUpload,
        submitting,
        getComments,
        getDocuments,
        setSubmitting,
        onToolClick,
      }}
    >
      <TaskFormWrapper className="wrapper-task">
        <Row type="flex" justify="space-between" align="top" gutter={6}>
          <Col
            className="col-section-form"
            xxl={16}
            xl={18}
            lg={16}
            md={16}
            sm={22}
            xs={24}
          >
            <div className="task-container">
              {
                <div className="task-content">
                  <SimpleForm
                    onChange={handleOnChange}
                    initialValues={task}
                    autoSubmit={false}
                    onMount={(form) => setForm(form)}
                    footer={
                      <div className="container-comment">
                        <CommentInput flex={1} id={id} />
                      </div>
                    }
                  >
                    {on_input ? (
                      <Input
                        className="input-title-task"
                        size="large"
                        flex={1}
                        onKeyDown={(e) => {
                          if (e.key === "Escape") {
                            save();
                          }
                        }}
                        onPressEnter={() => {
                          save();
                        }}
                        name="subject"
                        placeholder="Add Title First"
                      />
                    ) : (
                      <div
                        flex={1}
                        onClick={() => setOnInput((on_input) => !on_input)}
                        className="task-sugject-input"
                      >
                        <Icon type="edit" />
                        {task && task.subject ? (
                          <span>{task && task.subject}</span>
                        ) : (
                          <span className="placeholder-title">
                            Add Title First{" "}
                          </span>
                        )}
                      </div>
                    )}
                    {task && task.members_ids && task.members_ids.length > 0 && (
                      <SelectGroupField
                        id={task && task._id}
                        user={user}
                        title="Members"
                        record={task}
                        flex={1}
                        reference="users"
                        source="members_ids"
                        name="members_ids"
                        onSubmit={onSubmitTaskMembers}
                        selectes={
                          payloads.members_ids || (task && task.members_ids)
                        }
                        filterDefaultValues={{
                          company_id: user && user.company_id,
                          ...memberfilters,
                        }}
                      />
                    )}
                    {task && task.tags && task.tags.length > 0 && (
                      <div flex={1}>
                        <ListTags title="Tags" task={task} />
                      </div>
                    )}
                    <DescriptionText
                      task_id={id}
                      on_description={true}
                      task={task}
                      flex={1}
                      reference={"tasks"}
                    />
                    {
                      <DocumentList
                        title={
                          <>
                            {
                              <div className="flex justify-start gap-xs items-centered">
                                <span className="inline">
                                  <Icon type="paper-clip" /> Attachments
                                </span>
                              </div>
                            }
                          </>
                        }
                        showEmpty
                        open={open}
                        record={task}
                        onRemove={onRemoveDocument}
                        documents={documents || []}
                        //  default_documents={default_documents}
                        onUpload={onUpload}
                        onSubmit={handleOnDocumentSubmit}
                        flex={1}
                      />
                    }
                    {subtasks.map((task) => (
                      <TaskCheckList
                        reference="tasks"
                        flex={1}
                        task={task}
                        onRemove={getSubTasks}
                      />
                    ))}

                    {comments && comments.length > 0 && (
                      <div flex={1}>
                        <Comments
                          title={
                            <span className="section-comments-form">
                              <Icon type="message" /> Tasks Comments
                            </span>
                          }
                          id={id}
                          loading={loading_comments}
                          onSubmit={handleSubmitComments}
                          onRemove={handleRemoveComment}
                          onMentions={handleOnMentions}
                          comments={comments}
                        />
                      </div>
                    )}
                    {/* <div className="container-comment">
                        <CommentInput id={id} />
                      </div> */}
                  </SimpleForm>
                </div>
              }

              {loading && (
                <div className="task-loader">
                  <Skeleton
                    active
                    loading={loading}
                    avatar
                    paragraph={{ rows: 8 }}
                  />
                </div>
              )}
            </div>
          </Col>
          {
            <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24}>
              <div className="container-tools">
                <Affix offsetTop={110}>
                  <div className="section-tools">
                    {list.map((item, i) => {
                      return (
                        <div className="section-list-button">
                          {typeof item.render === "function" ? (
                            item.render(item)
                          ) : (
                            <Button
                              // disabled={!task}
                              onClick={() => handleOnclick(item.name)}
                            >
                              {item.icon}
                              {item.text}
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Affix>
              </div>
            </Col>
          }
        </Row>
      </TaskFormWrapper>
    </TaskContext.Provider>
  );
};

export default TaskForm;
