import React from "react";
import { WrapperSkeleton } from "./Styles";

const SkeletonTable = ({ count = 1, ...props }) => {
  return (
    <WrapperSkeleton>
      <div>
        {/*  <div className="skeleton">
          <div className="skeleton-loading" />
          <div className="container-select">
            <div className="skeleton-select" />
            <div className="skeleton-select-rounded" />
            <div className="skeleton-select-rounded" />
            <div className="skeleton-select-rounded" />
            <div className="skeleton-select-rounded" />
          </div>
        </div> */}
        <div className="container-table">
          <div className="skeleton-table">
            <div className="skeleton-select" />
            <div className="skeleton-select" />
            <div className="skeleton-select" />
            <div className="skeleton-select" />
            <div className="skeleton-select" />
            <div className="skeleton-select" />
          </div>
          <div className="skeleton-table-body">
            {Array.from({ length: count }).map((_, index) => (
              <div key={index} className="avatar-table">
                <div className="skeleton-avatar-rounded" />
                <div className="skeleton-row" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </WrapperSkeleton>
  );
};
export default SkeletonTable;
