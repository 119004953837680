import React, { useState, useEffect } from "react";
import { Input } from "antd";
const TextAreaField = (props) => {
  return (
    <>
      <Input.TextArea {...props}/>
    </>
  );
};

export default TextAreaField;