import React, { useEffect, useState } from "react";
import { DateField } from "../form/";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";
const time_format = "HH:mm";

const DateTimeField = ({
  form,
  name,
  format = "MM-DD-YYYY",
  onChange,
  onSelect,
  ...props
}) => {
  const [record, setRecord] = useState();
  const [value, setValue] = useState();
  const [time, setTime] = useState();
  const [date_time, setDateTime] = useState({});
  const handleOnChange = (field, value) => {
    let date = moment(
      `${moment(value).format(format)} ${
        time ? time.format(time_format) : value.format(time_format)
      }`,
      `${format} ${time_format}`
    );
    if (!time) setTime(moment());
    if (form)
      form.setFieldsValue({
        [name]: value,
      });
    // if (onChange) onChange(date);
  };
  const handleOnSelectTime = (field, time) => {
    setTime(time);
    if (value && onSelect) {
      let date = moment(
        `${moment(value).format(format)} ${moment(time).format(time_format)}`
      ).format(`${format} ${time_format}`);
      /*  if (form)
        form.setFieldsValue({
          [name]: date,
        }); */
      onSelect(name, date);
    }
  };
  useEffect(() => {
    let { time } = date_time;
    if (typeof date_time[name] == "object") {
      setValue(date_time[name]);
    }
    if (date_time[name] && time) {
      let date = date_time[name].format(format);
      if (time) {
        setTime(time);
        time = time.format(time_format);
      }
      /*  setValue(
        moment(`${date} ${time || "00:00"}`, `${format} ${time_format}`)
      ); */
    }
  }, [date_time]);
  useEffect(() => {
    /* if (onChange) onChange(value);
    if (form)
      form.setFieldsValue({
        [name]: value,
      }); */
  }, [value]);
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);

  useEffect(() => {
    if (time && value && onChange) {
      let date = `${value} ${time.format(time_format)}`;
      /* onChange(
        moment(
          `${date} ${time.format(time_format)}`,
          `${format} ${time_format}`,
          `${format} ${time_format}`
        )
      ); */
      /* if (form)
        form.setFieldsValue({
          [name]: moment(
            `${date} ${time.format(time_format)}`,
            `${format} ${time_format}`
          ).format(format),
        }); */
    }
  }, [time, value]);
  useEffect(() => {
    if (props.value) {
      setDateTime({
        [name]: props.value,
      });
      setValue(moment(props.value));
      if (onSelect) {
        let date = moment(
          `${moment(props.value).format(format)} ${
            time ? time.format(time_format) : props.value.format(time_format)
          }`,
          `${format} ${time_format}`
        ).format(`${format} ${time_format}`);
        if (!time) setTime(moment());
        onSelect(name, date);
      }
      if (!value) {
      }
    }
  }, [props.value]);
  return (
    <div className="flex justify-start items-centered gap-xs">
      <DatePicker
        className="datepiker-ant"
        size="large"
        value={value}
        format={format}
        onChange={(value) => handleOnChange(name, value)}
      />
      <TimePicker
        size="large"
        dropdownClassName="time-picker"
        onChange={(value) => handleOnSelectTime("time", value)}
        placeholder="Hour"
        value={time}
        format={time_format}
      />
    </div>
  );
};

export default DateTimeField;
