import styled from "styled-components";
import { Row } from "antd";
const background_colors = ["#FDFEE5", "#EDE0FF"];
export const WraperDate = styled.div``;
export const WrapperDocuments = styled.div`
  & .section-title-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px 0px 2px;
    .section-buttons-actions {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      text-align: center;
      .icon-footer {
        font-size: 16px !important;
      }
      .btn-removed-red {
        font-size: 16px !important;
      }
    }
  }
  & .avatar-view {
    width: 54px !important;
    height: 54px !important;
    h3 {
      font-size: 20px !important;
      margin-bottom: 0px !important;
    }
    span {
      color: #000000c2 !important;
    }
  }
  & .date-upload {
    span {
      font-size: 12px !important;
      margin: 0px 2px !important;
      font-weight: 500;
    }
  }
  & .ant-list-item-meta-title {
    margin-bottom: -4px !important;
  }
  & .ant-list-item-meta-description {
    font-size: 14px;
    line-height: 13px !important;
  }
`;
export const WrapperMemberBoard = styled.div`
  & .avatar-check {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  & .ant-list-item-meta-title {
    margin-bottom: 0px !important;
    padding: 4px 0px;
  }
`;
export const WrapperFilterContainer = styled.div`
  & .container-search {
    width: 258px !important;
  }
  & .container {
    height: 440px;
    width: 310px;
    overflow: scroll;
  }
  .conatiner-list {
    width: 330px;
    padding: 0px !important;
    margin: 4px !important;
  }
  & .tags-title,
  .members-title {
    padding: 10px 2px;
    span {
      color: var(--color-text);
      font-size: 12px;
      font-weight: 500;
    }
  }
  & .container-list {
    padding: 0px 0px 0px 0px !important;
  }
  & .ant-row-flex-center {
    justify-content: flex-start !important;
  }
  & .avatar-check {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & .ant-list-grid .ant-col > .ant-list-item {
    max-width: 86% !important;
  }
  .ant-list-item {
    margin-bottom: 8px !important;
  }
  & .ant-list-item-meta-title {
    margin-bottom: 0px !important;
  }
`;
export const WrapperFilter = styled.div`
  padding: 2px;

  & .ant-list-item-action {
    margin-left: 0px !important;
  }
  & .ant-list-item-meta-content {
    -ms-flex: 1 0;
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    text-align: center;
  }
  & .ant-list-item {
    display: flex !important;
    margin-bottom: 2px !important;
    max-width: 92% !important;
    cursor: pointer;
    :hover {
      background: #e7e7e7f2;
      -webkit-filter: brightness(108%);
    }
  }
  & .ant-list-item-meta-description {
    color: ${({ background }) =>
      background === "transparent" ? "#000000" : "#FFFFFF"};
    font-size: 14px;
    line-height: 22px;
    background: ${({ background }) => background}!important;
    width: 224px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
  }
  & .section-name {
    text-transform: capitalize;
  }
  & .btn-edit-item,
  .btn-delete-item {
    font-size: 12px !important;
    width: 20px !important;
  }
  & .ant-list-item-action > li {
    padding: 0 2px !important;
  }
  & .btn-void-task {
    width: 6px;
  }
`;
export const WrapperListMember = styled.div`
  & .ant-list-item.list-item-member {
    margin-bottom: 8px !important;
  }
`;
export const WrapperAddMembers = styled.div`
  & .container-autocomplete {
    padding: 10px 26px;
  }
  & .container-list-member {
    width: 298px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin: 8px;
  }
  & .ant-list-item {
    cursor: pointer;
  }
  & .ant-list {
    width: 100%;
  }
  & .ant-skeleton-content .ant-skeleton-paragraph {
    padding: 10px 2px !important;
    margin-bottom: 0px !important;
  }
  & .ant-skeleton-paragraph li {
    width: 100% !important;
  }
`;
export const WrapperListTags = styled.div`
  .section-title {
    padding: 6px 10px;
    span {
      font-size: 12px;
      font-weight: 700;
      color: var(--color-text);
      text-overflow: ellipsis;
    }
  }
  .container-list {
    display: flex !important;
    align-items: center !important;
    gap: 4px;
    padding: 1px 8px;
    .ant-tag {
      margin-right: 0px !important;
      padding: 2px 8px 4px;
      border-radius: 2px !important;
      border-radius: 18px !important;
      min-width: 70px;
      display: flex;
      justify-content: center;
      height: 22px;
      align-items: center;
    }
  }
`;
export const WrapperContainerTag = styled.div`
  & .container-list {
    padding: 8px 4px 6px 16px;
  }
  & .container-autocomplete {
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select-auto-complete.ant-select .ant-input {
      border-radius: 4px !important;
      border: 1px solid #007bffd9 !important;
    }
    .container-search {
      width: 94% !important;
    }
  }
  & .title-list {
    padding: 9px 2px 4px;
    font-weight: 600;
  }
  & .color-background {
    padding: 8px;
    .ant-btn {
      height: 24px !important;
    }
  }
  & .btn-new-tags,
  .btn-add-footer {
    text-transform: capitalize;
    border-radius: 18px !important;
    width: 136px !important;
    height: 32px !important;
    background: #fff !important;
    color: var(--primary-button) !important;
    :hover {
      background: var(--primary-button) !important;
      color: #fff !important;
    }
  }
  & .btn-backgroun-record,
  .btn-backgroun-payloads {
    :hover {
      border-color: ${({ background }) =>
        background === "transparent" ? "#ccc" : background}} !important;
    }
  }
  & .ant-form-item input {
    border-radius: 6px !important;
  }
  & .container-footer-new {
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .container-list-color {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .btn-go-back {
    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 12px !important;
      }
    }
  }
  & .form-new-task {
    width: 260px;
    overflow: scroll;
    height: 366px;
    .ant-form {
      // min-width: 84% !important;
    }
  }
  & .ant-spin-nested-loading {
    height: 254px;
    overflow-y: scroll;
  }
`;
export const WrapperTagList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  & .ant-list-item-action {
    margin-left: 0px !important;
  }
  & .ant-list-item-meta-content {
    -ms-flex: 1 0;
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
  }
  & .ant-list-item {
    display: flex !important;
    margin-bottom: 2px !important;
    max-width: 80% !important;
    cursor: pointer;
    :hover {
      background: #e7e7e7f2;
      -webkit-filter: brightness(108%);
    }
  }
  & .ant-list-item-meta-description {
    color: ${({ background }) =>
      background === "transparent" ? "#000000" : "#FFFFFF"};
    font-size: 14px;
    line-height: 22px;
    background: ${({ background }) => background}!important;
    width: 200px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
  }
  & .section-name {
    text-transform: capitalize;
  }
  & .btn-edit-item,
  .btn-delete-item {
    font-size: 12px !important;
    width: 20px !important;
  }
  & .ant-list-item-action > li {
    padding: 0 2px !important;
  }
  & .btn-void-task {
    width: 6px;
  }
`;
export const WrapperCheckTask = styled.div`
  width: 100%;
  & .ant-form-item input {
    border-radius: 8px !important;
    border: 1px solid #007bff !important;
  }
  & .container-footer {
    .btn-add {
      width: 100px;
      height: 34px !important;
      border-radius: 18px !important;
      background: #fff !important;
      border-color: var(--primary-button) !important;
      color: var(--primary-button) !important;
      :hover {
        background: var(--primary-button) !important;
        border-color: var(--primary-button) !important;
        color: #fff !important;
      }
    }
  }
`;
export const WrapperDescriptionTemplate = styled.div`
  & .section-descrition-template {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px;
    span{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      color: #3c57b0;
    }
  }
  & .section-name-template{
    h3{
      text-transform: capitalize;
      font-size: 22px!important;
    }
  } 
  & .section-descrition-template {
    .ant-btn-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & .container-descriptiom-template {
    padding: 12px;
    font-zise: 18px;
  }
  & .action-button-description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px;
  }
  & .container-check{
    padding: 11px 10px;
  } 
    & .section-checkbox{
    font-weight: 500;
  } 
  & .container-img-description {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    width: 100% !important;
    border-radius: 14px;
    height: 300px;
    margin-bottom: 0px;
    .image-cover{
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
    }
    .image-void{
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: contain;
      padding: 30px;
    }
  }
  & .button-template {
    padding: 10px 0px 10px;
  }
  & .btn-template-designed {
    border-radius: 18px;
    width: 288px;
    height: 40px;
    font-size: 16px;
    background: rgb(29, 55, 162);
    color: #fff;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    :hover{
      background: rgb(29 55 162 / 72%)!important;
      color: #fff!important;
  }
    }
  }
  & .ant-btn.btn-template-blank {
    border-radius: 18px;
    width: 288px;
    height: 40px;
    font-size: 16px;
    border-color: rgb(29, 55, 162);
    color: rgb(29, 55, 162);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    :hover {
      background: rgb(29, 55, 162);
      color: #fff;
    }
  }
  & .section-company{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .company-name h4{
      font-size: 18px;
      margin-bottom: 0px!important;
    }
  } 
  & .company-description{
    padding: 12px 20px;
  }
  & .container-section{
    padding: 44px 1px 30px;
  }
  & .section-title-template{
    h3{
      font-size: 20px;
    }
  }
  & .container-company-description{
    padding: 24px 6px 4px;
  }
  span.ant-tag {
    width: 106px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .section-tag-list{
  button.ant-btn {
    border-radius: 40px;
    width: 128px;
    text-transform: capitalize;
    font-weight: 500;
  }
 }
 & .container-card-image {
    height: 470px;
    overflow: scroll;
 }
 & .section-tag-list {
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    gap:8px;
 }
 & .col-section{
   overflow-x: scroll;
   height: 470px;
 }
 & .ant-divider-horizontal {
    margin: 4px !important;
 }
`;
export const WrapperTagCategory = styled.div`
  & .button-tag {
    border-radius: 20px;
    text-transform: capitalize;
    color: #3c57b0 !important;
    border-color: #3c57b0 !important;
    :hover {
      text-decoration: none;
      background: #3c57b0;
      color: #fff !important;
    }
  }
`;
export const WrapperBoardForm = styled.div`
  & .container-new-board {
    display: flex;
    justify-content: space-between;
    padding: 24px 8px;
    align-items: center;
  }
  & .section-title {
    h4 {
      margin-bottom: 0px;
      padding: 4px 14px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  & .ant-card {
    border-radius: 12px;
    height: 198px;
    position: relative;
    overflow: hidden;
  }
  & .ant-card-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  & .section-name-board {
    padding: 4px;
    span {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
      color: #000000cf!important;
    }
  }
  & .ant-card-hoverable:hover {
    background: #f9f6f6;
  }

  & .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    width: 100% !important;
  }
  & .btn-preview {
    border-radius: 18px !important;
    width: 160px;
    height: 40px;
    border-color: rgb(29, 55, 162);
    color: #fff
    font-weight: 500;
    background: rgb(29, 55, 162);
  }
  & .btn-template {
    border-radius: 18px !important;
    width: 160px;
    height: 38px;
    border-color: rgb(29, 55, 162);
    color: rgb(29, 55, 162);
    font-weight: 500;
  }
  & .btn-preview,
  .btn-template {
    :hover {
      color: #fff !important;
      border-color: rgb(29, 55, 162);
      background: rgb(29, 55, 162);
    }
  }
 
  & .ant-card {
    .container-actions {
      display: none;
    }
  }
  & .ant-card:hover {
    .container-actions {
      display: block !important;
    }
  }
  & .container-actions {
    padding: 52px 14px;
    position: absolute;
    z-index: 999;
    left: 0px;
    top: 0px;
  }
  & .card-avatar-company {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    padding-left: 3px;
    .card-company-name {
      h4 {
        font-size: 13px;
        margin-bottom: 0px;
        color: #000000b3;
      }
    }
  }
  & .avatar-company{
    width: 22px!important;
    height: 22px!important;
  } 
  & .ant-card-cover {
    .container-image-cover {
      border-radius: 2px 2px 0 0;
      height: 200px;
      object-fit: cover;
    }
    .container-cover-void {
      border-radius: 2px 2px 0 0;
      height: 200px;
      object-fit: contain;
      padding: 20px;
  
    }
 }
& .ant-card:hover .container-actions {
  display: block !important;
  width: 100%;
}
& .ant-card:hover .ant-card-body::before {
  display: table;
  content: '';
  background:#0a090912;
  width: 100%;
  height: 100%;
}

& .ant-card-body{
  padding: 0px!important;
}
`;
export const WrapperMark = styled.div`
  ${({ edit }) =>
    edit &&
    `
    box-shadow: 0px 0px 1px 1px #0abaf37d;
    border-radius: 6px;
        `}

  & .CodeMirror {
    position: relative;
    overflow: hidden;
    background: #ebebeb7d;
    padding: 10px 10px;
    height: auto !important;
    min-height: 100px !important;
    border-radius: 4px !important;
  }
  & span.cm-header.cm-header-1,
  h1 {
    font-size: var(--font-size-medium);
  }
  & .ant-form-item.head-input:hover {
    background: white !important;
  }
  & span.cm-header.cm-header-2,
  h2 {
    font-size: var(--font-size-small);
    margin-bottom: 8px !important;
  }
  & span.cm-header.cm-header-3,
  h3 {
    font-size: var(--font-size-tiny);
  }
  & .cm-string.cm-url {
    color: var(--primary) !important;
  }
  & .ant-comment-content-detail p {
    white-space: pre-wrap;
    margin-bottom: 0.2em;
  }
  & .section-moments {
    display: flex;
    justify-content: space-between;
    padding: 1px 2px;
    font-size: 12px;
    align-items: center;
    span {
      color: #9f9f9fe0 !important;
    }
  }
  & .section-btn .ant-btn-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
  }
  .section-btn {
    display: flex;
  }
  & .section-update {
    margin-left: 16px;
    .ant-btn-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;
    }
  }
  & .ant-form .ant-form-item-children .ant-input {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
`;
export const MarkdownWrapper = styled.div`
  position: relative;
  overflow: hidden;
  // border: 1px solid #ccc;
  padding: 10px 10px;
  height: auto !important;
  min-height: 50px !important;
  border-radius: 4px !important;
  // background: #ededed82;
  & .ant-form-item {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  ${({ box }) =>
    box &&
    `
            background: #fff;
            padding: 4px 10px 4px 10px;
            border-radius: 12px;
            box-shadow: 2px 2px 2px #d3d3d347;
            border: 1px solid #d3d3d35e;
            & p,h2,h1 {
                margin-bottom: 0.2em!important;
            }
        `}

  & .section-placeholder {
    text-transform: capitalize;
    span {
      color: #c5c0c0;
      font-size: 14px;
    }
  }
`;

export const WrapperDescription = styled.div`
  & .container-description {
    padding: 4px 8px;
  }
  & .section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    margin-bottom: 12px;
    h3 {
      margin-bottom: 0px !important;
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }
  & .section-text-area {
    width: 362px !important;
    textarea {
      padding: 10px;
      max-width: 100%;
      line-height: 1.5;
      border-radius: 5px;
      border: 1px solid #ccc;
      width: 100%;
      height: 120px !important;
    }
    textarea::focus {
      border: 1px solid #ccc !important;
    }
  }
  & .footer-buttons-mark {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    margin: 2px 0px;
  }
  & .icon-description {
    font-size: 18px !important;
  }
  .MarkDownEditor-descrition {
    width: 100%;
  }
`;
export const SelectGroupWrapper = styled.div`
  & .items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;

    & .item-conatiner {
      position: relative;
      margin: 0px -4px;
      cursor: pointer;
      & .ant-avatar {
        width: 34px !important;
        height: 34px !important;
        line-height: 34px !important;
      }
      .item-remove {
        display: none;
        position: absolute;
        right: -2px;
        top: 0px;
        z-index: 9999;
        .ant-btn:hover {
          color: #000;
          background: #fff;
        }
        .ant-btn {
          border-radius: 50%;
          background: #000;
          color: #fff;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15px;
          height: 15px;
          font-size: 10px;
        }
      }
    }
    & .item-conatiner:hover {
      .item-remove {
        display: block !important;
      }
    }
  }
`;
export const WrapperCommentInput = styled.div`
  width: 100%;
  & .ant-comment {
    border-top: 1px solid #e8e8e8;
  }
  & .ant-comment-inner {
    padding-bottom: 0px !important;
  }
`;
export const SectionWrapper = styled.div``;
export const AddStageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 240px;
  margin: 0px 10px;
  & .button-stage {
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;
  }
  &:hover {
    background-color: #37171708 !important;
    color: var(--text-color-blue-dark) !important;
  }
`;
export const TabPanelWrapper = styled.div`
  min-width: 100% !important;
  background: #fff;
  border-radius: 8px;

  & .ant-tabs {
    padding-right: 16px !important;
    background: ${({ background }) =>
      background
        ? background !== "transparent"
          ? background
          : "transparent"
        : "transparent"};
  }
  & .ant-tabs-tab {
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;
  }
  & .button-start-now {
    background: var(--primary-button) !important;
    color: #ffffff !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-empty-footer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 12px;
  }
  & .description-board-empty {
    span {
      padding: 4px;
    }
  }
`;
export const MemberListWrapper = styled.div`
  & .item-submitting {
    cursor: loading !important;
  }
`;
export const WrapperMenuItem = styled.div`
  & .ant-menu {
    .ant-menu-item {
      display: flex;
      gap: 4px;
    }
  }
`;
export const WrapperCheckDrop = styled.div`
  & .subject-title {
    font-size: 14px !important;
    width: 100%;
  }
  & .ant-list-item {
    min-height: 40px;
  }
  & .ant-list-item:hover .ant-list-item-action {
    display: block;
  }
  .ant-list-item-action {
    display: none;
  }
  .ant-list-item:hover {
    background: #dee2e666 !important;
    border-radius: 8px;
  }
  .btn-more-link {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    background: #bfbfbf7a !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-menu-root.ant-menu-vertical {
    border-radius: 10px !important;
  }
  .ant-list-item-meta {
    align-items: center !important;
    padding: 0px 10px;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0px !important;
  }
`;
export const WrapperBoards = styled.div`
  & .ant-tabs {
    background: #fff;
    border-radius: 8px;
    .ant-tabs-bar {
      margin: 0px !important;
    }
  }
  & .ant-tabs-ink-bar {
    background-color: red important;
  }
  & .tab-title-board {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
`;
export const WrapperEditor = styled.div`
  width: 100%;
  .add-task-section .btn-add-task {
    height: 30px !important;
    background: #091e420f;
    color: var(--color-text);
    border-color: #091e420f;
    font-size: 14px !important;
  }
  & .section-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .ant-btn {
      padding: 4px !important;
      font-size: 16px !important;
    }
  }
  .add-task-section .btn-add-task:hover {
    background: #ccc;
  }
  & .add-task-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 6px !important;
  }
  & .ant-progress {
    margin: 4px 6px !important;
  }
  & .actions-buttons {
    display: flex;
    justify-content: flex-end !important;
    margin: 8px 4px;
    width: 410px !important;
  }
  & .head-check-list,
  & .head-container,
  & .actions-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .section-input-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .input-editor {
      height: 30px !important;
      border-radius: 6px !important;
      border: 1px solid transparent !important;
      // color: #546067 !important;
      color: var(--text-dark) !important;
      padding: 2px 10px;
      font-size: 14px !important;
      width: 100% !important;
      :focus-visible {
        outline: #007bff !important;
      }
      :hover {
        box-shadow: 0px 0px 2px 0px #007bff !important;
      }
    }
  }
  & .ant-form .ant-form-item-children .ant-input {
    text-transform: capitalize;
  }

  & .task-editor {
    padding: 8px;
  }
  & .ant-list-item-meta {
    align-items: center !important;
    font-size: 0;
  }
  & .sub-task-completed {
    // text-decoration: overline;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    text-decoration-color: #e90f23;
    font-size: 14px !important;
  }
  & .sub-task-pending {
    font-size: 14px !important;
  }
  & .date-task {
    // line-height: 0.2;
    margin-left: 2px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    line-height: 0.8 !important;
    span {
      font-size: 10px !important;
      color: #c1c0c0 !important;
    }
  }
  /* & .section-date-update {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: baseline;
  } */
`;
export const WrapperTaskCheckList = styled.div`
  margin: 0px 6px !important;
  & .ant-list-split .ant-list-item {
    border-bottom: 0px !important;
  }
  & .progress-incompleted .ant-progress-bg {
    background: var(--primary-button);
  }
  & .progress-completed .ant-progress-bg {
    background-color: #52c41a !important;
  }

  & .ant-progress-line {
    padding: 2px 6px !important;
  }
  & .head-check-list {
    margin: 8px 0px !important;
  }
  & .ant-list-item {
    // background: #ededed !important;
    margin: 0px !important;
    padding: 0px 4px !important;
  }
  & .ant-list-item-action {
    margin-left: 0px !important;
  }
  & .head-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f3f3f391;
    border-radius: 8px;
  }

  & .title {
    flex: 1;
    display: flex;
    color: var(--color-blue-dark) !important;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0px 4px;
  }
  & .title > i {
    margin-right: 4px;
    font-size: 18px;
  }
  & .title h3 {
    margin-bottom: 4px !important;
  }
  & .ant-list-header h3,
  .task-subject {
    font-size: 16px !important;
    margin-bottom: 0px !important;
    cursor: pointer;
    width: 100%;
  }
  & .ant-list {
    padding: 4px !important;
    border-radius: 8px !important;
    margin: 10px 0px;
  }

  & .task-editor {
    width: 100% !important;
  }
  & .footer-list.new-task {
    display: block;
  }
  & .footer-list {
    margin: 0px !important;
  }

  & .action-lists .btn-delete-checklist {
    background: #091e420f !important;
    color: var(--color-text) !important;
    font-weight: 400;
  }
  & .action-lists .btn-delete-checklist:hover {
    background: #ccc !important;
    border-color: #ccc !important;
  }
  ${({ edit }) => {
    return edit
      ? `& .footer-list{
      
    }`
      : `
     /*  & .footer-list.edit-task {
        display: flex;
      } */
    `;
  }}
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;
export const TaskToolBarWrapper = styled.div`
  & .item-centered {
    display: flex;
  }
  &.task-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding-left: 28px;
    .ant-btn.ant-btn-link {
      i,
      svg {
        color: var(--color-text-weak) !important;
      }
    }
    .ant-btn.ant-btn-link:hover {
      background: var(--color-background-hover);
    }
  }
  & .action-tools,
  .task-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
  & .ant-modal-confirm-btns {
    display: flex;
    justify-content: center !important;
    width: 100%;
    align-items: center !important;
    gap: 10px;
  }
  .task-head {
  }
`;
export const TaskFormWrapper = styled.div`
  background:#fff
  min-height: 400px;
 & .section-title-edit{   
    padding: 4px 10px;
    h3{
      margin-bottom: 0px!important;
      font-size: 18px!important;
    }
}
 & .col-section-form{
  padding-left:2px!important;

 }
 .ant-form-item{
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
 }
 .documments-wrapper{
  padding: 0px 6px!important;
 }
  & .section-comments-form{
    display: flex!important;
    justify-content: flex-start!important;
    align-items: center!important;
    gap: 6px!important;
    font-size: 14px!important;
    padding: 2px 10px!important;
  }
  & .section-tools {
    display: grid;
    gap: 8px;
  }
  .task-loader {
    padding: 10px;
  }
  & .task-content {
    & .ant-form {
      padding: 10px;
    }
    & .item-group {
      margin-left: 12px;
    }
    & .documments-wrapper {
      h3 {
        font-size: 14px;
        span {
          justify-content: flex-start;
          gap: 4px;
        }
      }
    }
    
      .ant-list-split .ant-list-header {
        border-bottom: 0px;
      }
      .ant-list-items .ant-comment:first-child {
        border-top: 0px !important;
      }
    }
  }
  & .ant-comment {
    width: 100%;
    padding: 4px 0px;
  }
  .task-head {
    position: absolute;
    top: 0;
    width: 100%;
    background:#d9d6d6!important;
    z-index: 1;

    display: block;
    min-height: 50px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 58px;
    padding: 4px;
  }
  .ant-form {
    .ant-form-item-children {
      .ant-input:hover {
        border: 1px solid #e5ecee !important;
      }
      .ant-input {
        border-radius: 6px !important;
        background: transparent !important;
        border: 1px solid transparent !important;
        color: #546067 !important;

        color: var(--text-dark) !important;
        font-size: 14px!important;
        font-weight: 600;
      }
      .ant-input:focus {
        border-color: #3c57b0;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgb(29 55 162 / 20%);
        box-shadow: 0 0 0 2px rgb(29 55 162 / 20%);
      }
    }
  }
  & .ant-row-flex-center {
    justify-content: flex-start !important;
  }
  & .section-list-button{
    .ant-btn{
      background: #e8e8e88f;
      border-color: #e8e8e88f
      width: 140px;
      color: #0000009c !important
      font-weight: 400!important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0px;
      text-align: center;
    }
    .ant-btn:hover{
      background: #bdbbbb3b !important;
      border-color: #bdbbbb3b !important
    }

  }
  & .container-tools {
    padding: 0px;
    margin-top: 50px;
  }
 & .section-add-to-card {
  display: flex;
  padding: 10px 0px;
  h3{
    font-size: 12px!important;
  }
 }
 label.wrapper.file-upload {
  display: flex;
  justify-content: flex-start!important;
  border-radius: 4px;
  width: 140px;
  margin: 0px!important;
  gap: 8px;
  height: 34px!important;
  background:  #E4E6EA!important;
  color: #000000ba!important;
  font-weight: 500!important;
  font-size: 14px;
  padding: 4px 15px;
  border: 1px solid #ccc9!important;
  :hover{
    background: #bdbbbbc7!important;
    border-color: #bdbbbbc7!important
  }

 }
 & .ant-mentions{
  textarea{
    height: 66px!important;
  }
 }
 & .container-comment {
  width: 100%;
 }
 form.ant-form.ant-form-vertical {
  // background: #ededed!important;
 }
 .task-sugject-input{
  padding-left: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 700;
  .placeholder-title{
    color: #ccc!important;
    font-size: 14px!important;
  }
 }
`;
export const TaskWrapper = styled.div`
  & .list-tools .item-list span:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .subject {
    margin-bottom: 8px;
    span {
      font-size: 14px;
      line-height: 22px;
    }
  }
  & .list-tools .item-list i {
    margin: 0px 4px;
  }
  & .list-tools .item-list .tag {
  }
  & .group-container {
    margin-top: 0px !important;
  }
  & .tag-expired {
    padding: 2px 4px;
    background-color: #ec9488;
    border-radius: 3px;
    color: #fff;
  }
  &.task-container:hover .task-menu,
  .task-menu-open {
    display: block !important;
  }
  & .ant-input {
    border: 0 !important;
  }
  & .ant-input:focus {
    box-shadow: none !important;
  }
  & .status-container {
    padding: 2px 4px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 0px;
  }
  & .ant-tag {
    text-transform: capitalize !important;
    font-weight: 100 !important;
  }

  &.task-container {
    position: relative;
    padding: 20px 10px !important;
    .task-content {
    }
    .task-menu {
      display: none;
      position: absolute;
      right: 4px;
      top: 4px;
      z-index: 4;
    }
  }
  & .container-list {
    display: flex;
    justify-content: flex-start;
    gap: 2px;
    align-items: center;
    overflow: hidden;
    margin-bottom: 6px;
    span {
      font-size: 10px;
      text-transform: capitalize;
    }
    .ant-tag {
      height: 14px !important;
      display: flex !important;
      align-items: center !important;
      width: max-content !important;
      margin-right: 0px !important;
    }
  }
  & .task-subject {
    span {
      text-transform: capitalize;
      color: var(--color-text) !important;
      font-weight: 500;
    }
  }
  & .container-list .list-tags .ant-spin-container .ant-row {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
  }
  & .container-members {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  & .section-date-task {
    span {
      font-size: 11px !important;
    }
  }
`;
export const WrapperBoardList = styled.div`
  & .board-list {
    background: #fbfbfb;
    box-shadow: 2px 2px 2px lightgrey;
    border-radius: 4px;
    padding: 4px !important;
    min-height: 100vh;
  }
  &.new-project {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 60px !important;
    & .new-project-container {
      max-width: 400px;
      margin-top: 40px auto;
      .new-project-head {
        text-align: center;
        h2 {
          margin-bottom: 0px;
          strong {
            color: var(--color-primary);
            text-transform: capitalize;
          }
        }
        p {
        }
      }
      .ant-list {
        padding-top: 20px;
        .ant-list-footer {
          .btn-cancel {
            transition-property: all;
            transition-duration: 0.15s;
            padding: 10px;
            max-width: 32%;
            height: 40px;
            color: rgb(0 0 0 / 60%);
          }
          .btn-cancel:hover {
            transform: scale(1.2);
            color: var(--color-primary);
          }
        }
        .new-item {
          .ant-card {
            border-style: dashed !important;
          }
        }
        .new-item {
          .ant-card {
            .ant-card-meta {
              .ant-card-meta-avatar {
                i {
                  color: #afabac !important;
                }
              }
            }
          }
        }
        .item:hover {
          background: #37171708;
          border-radius: 10px;
        }
        .item:hover .ant-card {
          transform: translateY(-10px);
        }
        .item {
          padding: 8px;
          cursor: pointer;
          min-height: 180px;
          display: flex;
          justify-content: center;
          align-items: center;
          .ant-card {
            transition-property: all;
            transition-duration: 0.2s;
            border-radius: 8px;
            border-width: 2px;
            padding: 20px 10px;
            height: 140px;
            width: 100%;
            .ant-card-body {
              padding: 16px;
            }
            .ant-card-meta {
              flex-direction: column;
              display: flex;
              gap: 8px;
              justify-content: center;
              align-items: center;
              .ant-card-meta-avatar {
                i {
                  font-size: 30px;
                  color: var(--color-primary);
                }
              }
              .ant-card-meta-detail {
                text-align: center;
                .ant-card-meta-title {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.default {
    & .ant-card {
      display: block !important;
      border-radius: 8px;
      .ant-card-meta-detail {
        .ant-card-meta-title {
          margin-bottom: 0px !important;
        }
      }
    }
    & .tools {
      margin: 0px !important;
    }
    & .ant-list {
      padding: 20px;
    }
    & .ant-card:hover {
      border-left-width: 4px !important;
    }
    & .ant-card-hoverable {
      box-shadow: 0 0 3px 3px rgb(0 0 0 / 3%) !important;
      height: 170px !important;
    }

    & .ant-card.create .ant-card-meta-title {
      font-size: 16px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px !important;
    }
    & .ant-card.create {
      display: flex !important;
      .ant-card-body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-card-meta {
          min-height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    & .ant-card-head {
      min-height: 24px !important;
      padding: 6px !important;
      border-bottom: 0px !important;
    }

    & .ant-card-body {
      padding: 4px 10px !important;
    }
    & .board-card.template {
      .ant-card-body {
        margin: 4px;
        .ant-card-meta {
          display: flex;
          flex-direction: column !important;
          .ant-card-meta-avatar {
            text-align: center;
            margin: 0px;
            svg {
              font-size: 20px !important;
            }
          }
          .ant-card-meta-detail {
            text-align: center;
            .ant-card-meta-title {
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
  }
  & .ant-card-meta-avatar {
    padding-right: 0px !important;
  }
  & .ant-card-meta-title {
    text-transform: capitalize !important;
  }

  & .button-boards {
    .ant-btn {
      border-color: var(--primary-button) !important;
      background: #fff !important;
      border-radius: 20px !important;
      color: var(--primary-button) !important;
      :hover {
        border-color: var(--primary-button) !important;
        background: var(--primary-button) !important;
        color: #ffff !important;
      }
    }
  }
  & .button-cancel:hover {
    // background: #3c57b0;
    border-color: var(--primary-button) !important;
    background: var(--primary-button) !important;
    color: #ffff !important;
  }
  & .btn-archived {
    border-radius: 18px !important;
    border-color: var(--primary-button) !important;
    background: #fff !important;
    color: var(--primary-button) !important;
    :hover {
      border-color: var(--primary-button) !important;
      background: var(--primary-button) !important;
      color: #ffff !important;
    }
  }
  & .section-list-board {
    overflow-y: auto;
    height: 730px;
  }
`;
export const BoardWrapper = styled.div`
  margin: 8px !important;
  padding: 8px !important;
  min-width: 100% !important;
  display: flex;
  background: ${({ background }) =>
    background
      ? background !== "transparent"
        ? background
        : "transparent"
      : "transparent"};
  box-shadow: ${(background) =>
    (background || background === "transparent") && "2px 2px 2px lightgrey"
      ? (background || background !== "transparent") &&
        `2px 2px 2px${background}`
      : ""};
  border-radius: 4px;
  height: auto;
  min-height: 100vh;
  overflow-x: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar-button:increment,
  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #f1f2f4 !important;
    border-radius: 20px;
    border: 2px solid #f1f2f4 !important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;
export const FooterTask = styled.div`
  padding: 4px 8px;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  & .ant-btn:hover {
    background-color: #37171708 !important;
    color: var(--text-color-blue-dark) !important;
  }
  & .ant-btn i {
    margin: 0px 4px !important;
  }
  & .ant-btn span {
    margin-left: 0px !important;
  }
  & .ant-btn {
    color: var(--text-color-grey) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;
export const DocumentListWrapper = styled.div`
  & .ant-layout-header h1 {
    color: #fff !important;
  }
  & .ant-layout-header {
    background: var(--primary) !important;
  }
  & .container {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px auto;
  }
  & .ant-card-type-inner {
    border: 1px dashed #ccc !important;
  }
  & .documents > h2 {
    border-bottom: 1px solid #cccccc63 !important;
    padding-bottom: 8px !important;
  }
  & .documents .ant-avatar > img {
    object-fit: contain !important;
  }
  & .documents .ant-avatar {
    width: 40px !important;
    height: 40px !important;
  }
  & .item-container .ant-avatar {
    width: 40px !important;
    height: 40px !important;
    overflow: visible !important;
    margin: 0px 8px 0px 0px !important;
  }

  & .item-container .item-upload:first-child label {
    height: 0px !important;
    background: #dadde0 !important;
    color: #43326b !important;
    font-weight: 600 !important;
  }
  & .item-upload.item-first .mr-2 .content label {
    height: 40px !important;
  }
  & label.s3Button {
    height: 0px !important;
  }
  & .documents .content label i {
    margin-right: 8px !important;
  }
  & .documents .content label {
    max-width: 100% !important;
    background: #f2f2f3 !important;
    border-radius: 0px !important;
    color: #6c757d !important;
    margin: 4px 0px !important;

    display: flex !important;
    justify-content: start !important;
    border-radius: 8px !important;
  }
  & span.item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .ant-list-items {
    max-height: 440px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin-bottom: 8px !important;
  }
  & .ant-list-items::-webkit-scrollbar {
    width: 8px !important;
    background: transparent !important;
  }
  & .ant-list-item-meta-description {
    font-size: 12px !important;
  }
  & .ant-list-item-meta-description .ant-btn {
    margin: 0px !important;
    height: 25px !important;
  }
  & .ant-list-split .ant-list-item:last-child {
    height: 50px !important;
  }
  & .ant-list-item-meta-description div:first-child {
    margin-bottom: 0 !important;
  }
  & .ant-list-items::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: lightgray !important;
  }

  /* Empty */
  &.empty-file .ant-empty-image {
    height: auto !important;
  }
  &.empty-file .btn-empty {
    margin: 10px 4px;
    border-radius: 30px;
  }
  &.empty-file .ant-empty-description {
    font-size: 24px !important;
    font-weight: 600;
  }
  &.empty-file .ant-empty-image img {
    height: 300px !important;
  }
  @media (max-width: 576px) {
    & .ant-layout-header h1 {
      font-size: 24px;
    }
    & .documents > h2 {
      font-size: 18px;
    }
    & .ant-card-type-inner .ant-card-body {
      padding: 16px 4px;
    }
    & .container {
      border-radius: 0px;
      margin: 0px auto;
    }
    & .ant-card-body {
      padding: 4px;
    }
    & .content label {
      max-width: 100% !important;
      border-radius: 8px !important;
    }
    & .ant-list-item-meta .ant-avatar {
      height: 70px !important;
      width: 60px !important;
    }
    & .ant-list-item-meta-title {
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }
    & .ant-list-item-meta {
      margin: 0px 4px !important;
    }
    & h2 {
      font-size: 24px;
      margin: 4px;
    }
  }
  & .ant-spin-container {
    padding: 0px 7px !important;
  }
  /* & .ant-upload {
    background: #e0e1e3 !important;
  } */
  & .ant-upload-drag-container .anticon svg {
    display: inline-block;
    // color: #c7c6c6 !important;
  }
  & .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: rgb(103 99 99 / 85%);
    font-size: 16px;
  }
  & .btn-dragandrop-cancel {
    background: #091e420f !important;
    border-color: #091e420f !important;
    color: var(--color-text) !important;
    font-weight: 400;
  }
  & .btn-dragandrop-cancel:hover {
    background: #ccc !important;
    border-color: #091e420f !important;
  }
  & .section-title-attachments {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin-bottom: 0px !important;
    }
  }
`;
export const AddTaskForm = styled.div`
  background-color: white;
  padding: 0px 10px !important;
  border-radius: 8px;
  margin-bottom: 0px;
  min-height: 70px;
  &.item-task {
    border: 1px solid #edeae9;
  }
  & .btn-menu {
    display: none;
    border-radius: 4px;
    background-color: #37171708 !important;
  }
  .ant-dropdown-trigger {
    background-color: #37171708 !important;
  }
  & .ant-input {
    border: 0 !important;
  }
  & .ant-input:focus {
    box-shadow: none !important;
  }
  &:hover .btn-menu {
    display: block !important;
  }
  .task-subject {
    padding: 20px 0px;
  }
`;
export const Tools = styled.div`
    display: flex;
    margin-bottom:0px !important; 
    gap:0px;
    & .ant-dropdown-trigger {
      background: transparent;
      border: 0px !important;
    }
    & h3 {
      flex: 1;
      padding: 0px 8px;
    }
    & .ant-btn {
      margin: 0px 4px;
      display: flex !important;
      align-items: center !important;
    }
    & span {
      margin: 0px 4px;
    }

    & .ant-btn-danger,
    .ant-btn-danger:hover {
        color: #ff4d4f!important;
        background-color: transparent!important;
        border-color: transparent!important;
        box-shadow: none!important;
      }
    }
    &.toolbar .ant-btn-primary:hover {
      background-color: ${(props) =>
        props.background
          ? "hsla(0, 0%, 100%, 0.4)"
          : "var(--primary)"}!important;
    }
    &.toolbar .ant-btn-primary {
      background-color: ${(props) =>
        props.background ? "hsla(0,0%,100%,.2)" : "var(--primary)"}!important;
      border-color: ${(props) =>
        props.background ? "hsla(0,0%,100%,.2)" : "var(--primary)"}!important;
      color: ${(props) =>
        props.background ? "#FFF" : "var(--primary)"}!important;
    }
    & .ant-btn > span i,
    .ant-btn a > span i {
      margin: 0px 4px !important;
    }
    & .ant-btn a > span {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
    &.toolbar .ant-btn-default {
      background-color: ${(props) => props.background && "hsla(0,0%,100%,.2)"};
      color: ${(props) => props.background && "#FFF"};
      border-color: ${(props) => props.background && "hsla(0,0%,100%,.2)"};
    }
    &.toolbar .ant-btn-default:hover {
      background-color: ${(props) => props.background && "hsla(0,0%,100%,.4)"};
    }
    &.toolbar .ant-btn-primary {
      color: #fff !important;
    }
`;
export const Title = styled.div`
  padding: 4px 8px;
  overflow: hidden;
  & .title-container {
    padding: 16px 8px !important;
    text-transform: capitalize;
  }
  & h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    white-space: nowrap;
  }
`;
export const HeadLine = styled.div`
  padding: 4px 0px;
  margin-bottom: 4px;
  height: 40px;
  display: flex;
  justify-content: start;
  cursor: pointer;
  border-radius: 8px;
  background: ${({ background }) =>
    background
      ? background !== "transparent"
        ? "#16161624"
        : "transparent"
      : "transparent"};
  & h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    /* max-width: calc(100% - 20px); */
    min-width: 200px;
    overflow: hidden;
    margin: 0px 4px;
    padding: 4px;

    text-align: left;
    font-size: var(--text-md);
    line-height: var(--text-md);
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    .anticon {
      margin: 0px 4px;
    }
  }

  & .ant-btn {
    margin: 0px !important;
  }
  .ant-btn::before {
    background: transparent !important;
  }
  & .ant-btn.ant-btn-primary,
  .ant-btn.ant-btn-primary:hover {
    background: var(--board-color-green-light) !important;
    color: #fff !important;
    border-color: transparent !important;
  }
  & .ant-btn.ant-btn-default .anticon {
    color: var(--color-blue-dark);
  }
  & .ant-btn.ant-btn-default,
  .ant-btn.ant-btn-default:hover {
    background: transparent;
    border: 0px;
  }
  & .ant-btn.ant-btn-link,
  .ant-btn.ant-btn-link:hover {
    border-color: transparent !important;
  }
  & .ant-input,
  .ant-input:hover,
  .ant-input:focus {
    box-shadow: none !important;
    border-color: transparent !important;
    margin: 4px;
    /* box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25) !important; */
    font-size: var(--text-md);
    line-height: var(--text-md);
    font-weight: 500;
  }

  & .ant-input,
  h3 {
    margin: 0px !important;
  }
  width: fit-content !important;
  & .input-field i {
    margin: 4px 8px !important;
  }
  /* &:hover {
    background: #00000005;
    border-radius: 8px;
  } */
  /* &:hover h3 {
    color: ${({ background }) => background || "var(--primary)"};
  } */
  &:hover .btn-edit {
    display: block !important;
  }
  & .btn-edit {
    display: none !important;
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;

  }
  & .section-name-board {
    text-transform: capitalize !important;
  }
  & .btn-edit,
  .btn-close {
    margin: 4px !important;
  }
  & .input-field {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  /* .board-title svg {
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;
  } */
  & .board-title {
    border-radius: 6px !important;
    padding: 8px;
    
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;
        svg {
          color: ${({ background }) =>
            background
              ? background !== "transparent"
                ? "#fff"
                : "rgb(0 0 0 / 65%)"
              : "rgb(0 0 0 / 65%)"} !important;
  }
  ${({ isEdit }) => {
    return (
      isEdit &&
      `
                /* background: #ebecf0;
                border-radius: 10px;  */


                /* & .ant-input{
                    width: fit-content!important;
                } */
                /* width:100%!important; */
                & .ant-input, h3{
                    margin: 0px!important;
                    /* width:100%!important; */
                }
                

            `
    );
  }}
`;
export const TaskList = styled.div`
  padding: 10px 4px;

  flex-grow: 1;
  /* background-color: #f9f8f8; */
  /* background: linear-gradient(180deg,#FAFAFA , #f9f8f8, 0) 100%); */
  background: linear-gradient(180deg, #f9f8f8, rgba(249, 248, 248, 0) 100%);
  /* min-height: 270px !important; */
  position: relative;
`;
export const Container = styled.div`
  /* margin: 10px;
  padding: 10px; */

  max-width: 270px;

  & .task-container {
    transition: background-color 0.2s ease;
    border-radius: 8px;
    /* box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25); */
    margin: 10px;
    padding: 10px;
    transform: rotate(${(props) => (props.isDragging ? "-1.5deg" : "0deg")});
    ${({ isDragging }) =>
      isDragging &&
      `
      box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
      border: 1px solid transparent;
      border-color: var( --board-color-blue)!important;
    `}
    background-color: ${(props) =>
      props.isDragDisabled
        ? "#FDFEE5"
        : props.isDragging
        ? "#FAFAFA"
        : "white"};
    border: 1px solid #edeae9;
    /* padding: 20px 10px !important; */
    cursor: pointer !important;
    &:hover {
      border: 1px solid #6d6e6f8f;
    }
  }

  /* border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25); */

  & span {
    word-break: break-all;
  }
`;
export const BoardContainer = styled(Row)`
  min-width: 100% !important;

  & .property-link {
    background: #e8e8e8;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 4px 16px;
    border-radius: 30px;
    color: #433281;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 2px 1px 2px #dfdfdf;
  }
  & .button-personalize {
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;
  }
  & .head-title {
    gap: 4px !important;
  }
  & .head-title i,
  .head-title svg {
    color: ${(props) =>
      props.background && !props.color ? "#FFF" : props.color || "#ffffff"};
  }
  & .head-title h3 {
    color: rgb(0 0 0 / 65%);
    font-weight: 600;
    font-size: 18px;
  }
  /* & .head-title:hover i,
  .head-title:hover h3 {
    color: var(--primary) !important;
  } */
  & .ant-empty .ant-btn-primary:hover {
    background-color: ${(props) =>
      props.background ? "hsla(0, 0%, 100%, 0.4)" : "var(--primary)"}!important;
  }
  & .ant-empty .ant-empty-description {
    color: ${(props) =>
      props.background ? props.color || "#FFF" : "#292b2"}!important;
  }
  & .ant-empty .ant-empty-description a {
    color: ${(props) =>
      props.background
        ? props.color || "rgba(0, 0, 0, 0.65)"
        : "var(--primary)"}!important;
  }
  & .ant-empty .ant-empty-description {
    color: ${(props) =>
      props.background ? props.color || "#FFF" : "#292b2"}!important;
  }
  & .ant-empty .ant-btn-primary {
    background-color: ${(props) =>
      props.background ? "hsla(0,0%,100%,.2)" : "var(--primary)"}!important;
    border-color: ${(props) =>
      props.background ? "hsla(0,0%,100%,.2)" : "var(--primary)"}!important;
    color: #fff !important;
  }
`;
export const WrapperColumn = styled.div`
  margin: 0px 10px;
  padding: 0px;
  min-width: 270px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;

  background: #f1f2f4 !important
  box-shadow: 1px 1px 2px #cccccc42 !important;
  border-radius: 10px;
  background-color: #fff;
  min-height: 100px;
  height: 100% !important;
  
  & .column-actions:hover {
  }
  & .column-actions {
    .ant-btn i {
      color: var(--color-text-weak) !important;
    }
  }

  &:hover {
    border: 1px solid #dbdbde;
  }
  & .section-columns-5{
    height: 450px!important;
    overflow-y: scroll;
  }
  & .section-columns-maxtask{
    height: 620px!important;
    overflow-y: scroll;
  }
 
`;
export const WrapperTaskLog = styled.div`
  & .ant-list-item-meta-description p {
    margin-bottom: 0px !important;
  }
  & .ant-list-item {
    padding: 4px 0 !important;
  }
  & .ant-list-item-meta-title {
    margin-bottom: 0px !important;
  }
  & .ant-timeline {
    margin: 20px !important;
  }
`;
export const WrapperForm = styled.div`
  background: #f4f5f7 !important;
  & h3 {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
  }
  & h2 {
    font-size: 16px !important;
  }
  & h2 span {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
  }
  & .head-input {
    font-size: 16px !important;
    background: transparent !important;
  }
  & .ant-form {
    background: transparent !important;
  }
  & .save-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px !important;
    padding: 0px !important;
  }
  & .save-container button {
    padding: 4px;
    height: 40px;
    text-align: center;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  & .CodeMirror {
    min-width: 100% !important;
  }
  & .ant-form-item {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
  & .ant-form label {
    display: flex !important;
    align-items: center !important;
  }
  & textarea#search_form_subject {
    font-size: 16px !important;
  }
  & .ant-comment-avatar {
    margin-right: 0px !important;
  }
  /* Item Label Field */

  /* 
 & .item-field .ant-form-item {
   display: flex;
   justify-content: start;
   align-items: center;

   min-width: 100% !important;
 } 
  
  & .item-field {
    height: 50px !important;
    margin-bottom: 4px !important;
  }
  & .item-field .item-label i,
  & .flex i {
    margin: 0px 4px 0px 0px;
  }
 
  & .item-field.ant-col.ant-form-item-label {
    padding: 8px 0px !important;
  }
  & .item-field .ant-form-item label {
    padding: 8px 4px !important;
    margin-bottom: 0px!important;
  }
  & .item-field .ant-form-item-control-wrapper {
    width: 70% !important;
  }
  
  & .item-field .item-label {
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
    min-height: 34px;
    padding: 0 8px;
    min-width: 148px !important;
  }
  & .item-field .item-form {
    padding: 0px 8px 4px !important;
  }
  & .item-list-description {
    width: 100%!important;
    display: flex!important;
    justify-content: space-between!important;
  }
  & .footer-advanced-form{
    margin-bottom: 0px!important;
  }
  & h3,
  h2 {
    font-size: 16px !important;
  }
  textarea {
    font-size: 14px !important;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  & .ant-menu-inline {
    border-right: 0px !important;
    padding: 0px !important;
    margin: 0px 4px;
  }
  & li.ant-menu-item:hover {
    background: rgba(0, 0, 0, 0.15) !important;
    color: rgba(0, 0, 0, 0.65) !important;
    border-radius: 4px !important;
  }
  & li.ant-menu-item.ant-menu-item-disabled:hover {
    background: transparent !important;
  }
 
  & .btn-save {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
  } */
  & .content .wrapper {
    border-radius: 4px;
    height: 140px !important;
    width: 290px !important;
  }
  & .content:hover .wrapper::before {
    border-radius: 0% !important;
  }
  & .content {
    width: 348px;
    height: 118px;
  }
  i.anticon.anticon-edit.section-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    // background: red;
    height: 100%;
    border-radius: 0px;
    padding: 0px !important;
    top: 0px;
    left: 0px;
  }
  .action-container {
    position: absolute;
    display: none !important;
    top: 63px !important;
    left: 138px !important;
    .section-delete {
      display: none !important;
    }
  }
  & .ant-col.ant-form-item-label {
    padding: 0px !important;
  }
  & .item-form {
    padding: 4px 4px !important;
  }
  & .section-add-stage {
    padding: 10px;
  }
`;
export const WrapperCategory = styled.div``;
export const WrapperCardBoard = styled.div`
  & .section-title-board {
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    span {
      font-weight: 500;
      font-size: 16px;
      color: ${({ background }) =>
        background
          ? background !== "transparent"
            ? "#fff"
            : "rgb(0 0 0 / 65%)"
          : "rgb(0 0 0 / 65%)"} !important;
      text-decoration: underline;
    }
  }
  & .author-name-board {
    text-transform: capitalize;
    span {
      font-weight: 500;
      font-size: 12px;
      color: ${({ background }) =>
        background
          ? background !== "transparent"
            ? "#fff"
            : "rgb(0 0 0 / 65%)"
          : "rgb(0 0 0 / 65%)"} !important;
    }
  }
  & .date-container {
    line-height: 8px;
    span {
      font-size: 10px !important;
      color: #00000066;
      font-weight: 500;
      color: ${({ background }) =>
        background
          ? background !== "transparent"
            ? "#fff"
            : "rgb(0 0 0 / 65%)"
          : "rgb(0 0 0 / 65%)"} !important;
    }
  }

  & .icon-card-board {
    color: ${({ background }) =>
      background
        ? background !== "transparent"
          ? "#fff"
          : "rgb(0 0 0 / 65%)"
        : "rgb(0 0 0 / 65%)"} !important;
  }
  & .author-container,
  .date-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-card-extra {
    padding: 0px !important;
    margin: 0px !important;
    width: 100%;
    .section-extra {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      padding: 4px;
      i {
        color: ${({ background }) =>
          background
            ? background !== "transparent"
              ? "#fff"
              : "rgb(0 0 0 / 65%)"
            : "rgb(0 0 0 / 65%)"} !important;
        font-size: 18px !important;
      }
      .btn-loading {
        padding: 0px !important;
        // background: ${({ background }) => background} !important;
      }
    }
  }
  & .title-add{
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-button);
  }
`;
export const ListItemContainer = styled.div`
  & .head-container {
    margin: 20px;
    text-align: center;
  }
  & .ant-btn {
    display: flex;
  }
  & .board-card.create .ant-card-meta-title {
    text-align: center;
  }

  & .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0px;
  }
  & .title-line h3 {
    font-size: 18px !important;
  }
  & .title-line > * {
    margin: 0px 4px !important;
  }
  & .board-card.create .ant-card-body {
    width: 100% !important;
  }
  & .board-card.create {
    border-style: dashed !important;
    background-color: rgba(9, 30, 66, 0.02) !important;
  }
  & .ant-card-meta-avatar {
    padding-right: 8px !important;
  }
  & .ant-card {
    min-height: 107px;
    display: flex;
    align-items: center;

    transition-property: all;
    transition-duration: 0.05s;
    transform-origin: left top;
  }

  & .create:hover {
    border-left-width: 1px !important;
    transform: rotateZ(0eg);
  }
  & .ant-card-body {
    width: 100%;
  }

  & .ant-spin-container {
    margin: 10px;
  }

  & .description-text {
    font-size: 12px !important;
  }
  & .current-item .ant-card-meta-title,
  .current-item .ant-card-meta-description {
    color: #fff !important;
  }
  & .current-item {
    background: ${({ background }) =>
      background || "var(--board-color-blue)"} !important;
  }
  & .current-item .ant-card-meta-avatar {
    svg {
      color: #fff !important;
    }
  }
  & .ant-card {
    border-radius: 8px;
  }
  &.board-list.default {
    & .ant-card-meta-title {
      color: var(--gray-dark) !important;
      font-size: 16px !important;
      margin-bottom: 4px !important;
    }
    & .ant-card-meta-title {
      margin-bottom: 0px !important;
      font-size: 16px !important;
      line-height: 18px !important;
    }

    & .ant-card-hoverable {
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
      border-radius: 10px !important;
    }
  }
  & .list-container {
    margin: 0px 8px 8px 0px !important;
  }
  & .list-container h3 i {
    margin: 0px 8px 0px 8px;
  }
  & .list-container h3 {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  @media (max-width: 930px) {
    & .list-container {
      display: block !important;
    }
  }
  @media (max-width: 767px) {
    display: block !important;
    & .list-container {
      display: block !important;
    }
  }
`;
export const DropDownMenu = styled.div`
  & .ant-menu-item {
    background: #fff !important;
  }
`;
export const WrapperSahereLinkForm = styled.div`
  & .form-actions {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;
export const MenuWrapper = styled.div`
  min-height: 400px !important;
  min-height: 200px !important;
  max-width: calc(100% - 10px) !important;
  & .item-green {
  }
  & li.ant-menu-item.ant-menu-item-disabled h3 {
    font-size: 18px !important;
    margin: 4px 8px !important;
  }
  background: transparent !important;
  & .ant-list {
    max-height: 200px !important;
  }
  & .ant-menu-inline {
    border-right: 0px !important;
    padding: 0px 16px;
  }
  & .ant-menu-item {
    display: flex;
  }
  & .ant-menu-item:hover a,
  .ant-menu-submenu .ant-menu-item:hover a {
    color: #0079bf !important;
  }
  & .ant-menu-submenu-title,
  .ant-menu-item a {
    color: rgba(0, 0, 0, 0.65);
  }
  & .ant-menu-submenu-title,
  .ant-menu-item,
  .file-upload {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px 10px !important;
    background: #fff !important;
    color: rgba(0, 0, 0, 0.65) !important;
    text-align: left !important;
  }
  & .file-upload {
    padding: 0px !important;
    margin: 0px !important;

    background: transparent !important;
    color: rgba(0, 0, 0, 0.65) !important;
    text-align: left !important;
    border-radius: 0px !important;
  }
  & .ant-menu-item-selected a {
    color: #0079bf !important;
  }
  & .ant-menu-item-selected {
    background-color: #e4f0f6 !important;
    color: #0079bf !important;
    border-radius: 4px !important;
  }
  & .ant-menu-sub .ant-menu-item:not(:last-child) {
    margin-bottom: 4px !important;
  }
  & .ant-menu-sub.ant-menu-inline {
    padding: 0px 16px !important;
    font-size: 14px !important;
  }

  & .ant-menu-inline .ant-menu-item {
    font-size: 14px !important;
    background-color: rgba(9, 30, 66, 0.04) !important;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px !important;
  }
  & .ant-menu-item.ant-menu-item-disabled {
    background: transparent !important;
  }

  & .ant-btn.btn-add i {
    margin: 0px !important;
  }
  & .ant-btn.btn-add {
    /*  background-color: rgba(9,30,66,.08)!important;
        color: var(--text-color-blue-dark)!important; */
  }
  & .ant-btn.btn-add:hover {
    /* color: var(--text-color-blue-dark)!important;
        background-color: rgba(9,30,66,.08)!important; */
  }
  & .ant-menu-submenu-arrow {
    position: absolute;
    left: 0;
    padding: 0px;
  }
  & li.ant-menu-item.ant-menu-item-disabled {
    cursor: default !important;
    color: var(--text-color-blue-dark) !important;
  }

  & .ant-dropdown-trigger {
    display: block !important;
  }
  &.menu-modal {
    box-shadow: -1px 3px 3px lightgrey !important;
    border-radius: 4px !important;
    overflow: hidden !important;
  }
  &.menu-modal .ant-menu-item {
    height: fit-content !important;
    display: block !important;
    color: rgba(0, 0, 0, 0.6) !important;
    background: transparent !important;
    cursor: default !important;
  }
  & .ant-progress {
    position: absolute;
    left: 0;
    bottom: 5px;
    justify-content: center;
    display: block;
    z-index: 999;
  }

  & .item-action.inactive {
    background: #cf513d !important;
    color: #fff !important;
    border-radius: 4px !important;
  }
  & li.ant-menu-item .ant-dropdown-trigger {
    display: flex !important;
    justify-content: start;
    align-items: center !important;
  }
  & .wrapper .file-upload {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
  }
`;
export const ModalBody = styled.div`
  margin: 10px;
  min-height: 200px;
  display: block;

  & .ant-list {
    max-height: 400px;
    overflow-y: auto;
  }
  & .list-members .ant-list-item {
    background-color: rgba(9, 30, 66, 0.04);
    padding: 4px 8px;

    cursor: pointer;
  }
`;
export const MemberGroupWrapper = styled.div`
  display: flex;
  & .ant-popover-inner-content {
    margin: 0px !important;
    padding: 0px !important;
  }
  & .ant-avatar {
    cursor: pointer;
  }
  & .member-container {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  & .ant-avatar.ant-dropdown-trigger {
    color: #212529 !important;
    background-color: #e4e4e4 !important;
  }
  & .ant-avatar.ant-avatar-icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
`;
export const MemberCardWrapper = styled.div`
  & .ant-card-body {
    padding: 10px !important;
  }
  & .ant-card-meta-title {
    margin-bottom: 0px !important;
  }
  & .ant-card-actions li {
    margin: 0px !important;
  }
`;
export const ItemWrapper = styled.div`
  padding: 4px 0px;
  margin-bottom: 0px;

  & .ant-avatar {
    height: 54px;
    width: 54px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: rgb(204 222 253 / 70%) !important
    cursor: pointer;
  }
  & .ant-list-item-meta-avatar {
    margin-right: 8px !important;
  }
  & .ant-list-item-meta-content {
  }
  & .ant-list-item-meta-title {
    margin-bottom: 0px !important;
  }
  & .ant-avatar > img {
    object-fit: contain !important;
    background-color: rgba(9, 30, 66, 0.04) !important;
  }
  & .ant-avatar h3 {
    color: var(--color-blue-dark-1);
    font-weight: 500;
    padding: 0px;
    margin: 10px;
    margin-bottom: 0px !important;
  }
  &:hover {
    background-color: rgba(9, 30, 66, 0.06) !important;
  }
  & .ant-list-item {
    padding: 0px !important;
  }
  & .ant-list-item {
    padding: 4px;
  }
  & .ant-btn {
    padding: 0px !important;
  }
  & .ant-btn a {
    color: var(--gray-dark) !important;
  }
  & .ant-btn-link,
  .ant-btn-link:hover {
    color: var(--color-blue-dark-1);
  }
  & span.ant-avatar-string {
    line-height: 1.5 !important;
  }
  & .quick-preview {
    color: #5f6975 !important;
    font-size: 12px !important;
    padding: 0px !important;
    margin: 0px !important;
  }
`;
export const ExpirationWrapper = styled.div`
  & form .ant-select,
  form .ant-cascader-picker {
    width: auto !important;
    min-width: 80px !important;
  }
  & form .item-form {
    padding: 5px 4px !important;
  }
  & form .ant-select-lg {
    width: 100% !important;
  }
  & form > div {
    margin: 4px !important;
  }
`;
export const WrapperMembers = styled.div`
  width: 100%;
  & .ant-col {
    color: #433281;
    font-size: 14px;
    font-weight: 500;

    border-radius: 3px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 15%);
    padding: 20px 0px 0px 0px;
    margin: 20px 0px !important;
    background: #e8e8e840 !important;
    // background: #e8e8e869 !important;
  }
  & .head-list {
    text-align: center;
    margin: 8px 4px;
    padding: 16px 16px 4px 16px;
  }
  & .ant-list-items {
    margin: 4px 0px 0px 0px !important;
    background: #fff;
    padding: 4px 10px;
    border-radius: 0px 0px 8px 8px !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  & li.ant-list-item {
    min-width: 400px !important;
    padding: 10px 16px !important;
  }
  & .head-list h2 {
    color: var(--color-blue-dark) !important;
    font-size: 24px;
    font-weight: 600;
  }
  & .group-avatar {
    width: 60px !important;
    height: 60px !important;
    line-height: 60px !important;
    border-radius: 0px !important;
    overflow: visible !important;
  }
  & .btn-submit {
    max-width: 40% !important;
    display: block !important;
    text-align: center !important;
    margin: 20px auto 4px auto !important;
  }
`;
