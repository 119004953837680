import React, { useEffect, useState } from "react";
import { Input, Button, message } from "antd";
import { WrapperEvents } from "./Styles";
import { SimpleForm, SelectField } from "../form/";
import { DateTimeField } from "../fields";
import { getService } from "../../../services";
const data = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "5 minutes before",
    label: "5 Minutes Before",
  },
  {
    value: "10 minutes before",
    label: "10 minutes Before",
  },
  {
    value: "15 minutes before",
    label: "15 Minutes Before",
  },
  {
    value: "1 hour before",
    label: "1 Hour Before",
  },
  {
    value: "2 hour before",
    label: "2 Hours Before",
  },
  {
    value: "1 days before",
    label: "1 Day Before",
  },
  {
    value: "2 days before",
    label: "2 Days Before",
  },
];

const NewEvent = ({ reference, user, onSubmit, ...props }) => {
  const [payloads, setPayloads] = useState();
  const [form, setForm] = useState();
  const [record, setRecord] = useState();
  const handleOnSubmit = (err, data, form) => {
    // console.log("err, data, form", err, data, form);
  };
  const handleOnChange = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const handleOnSelect = (field, value) => {
    setPayloads({
      ...payloads,
      [field]: value,
    });
  };
  const save = (values) => {
    const service = getService(reference);
    if (!record) {
      service
        .create(values)
        .then((resp) => {
          message.success("Record Created");
          if (onSubmit) onSubmit();
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else if (record._id) {
      service
        .patch(record._id, values)
        .then((resp) => {
          message.success("Record Update");
          if (onSubmit) onSubmit();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  const handleOnCLick = () => {
    if (form) {
      form.validateFields((errors, values) => {
        if (!errors) {
          save(values);
        }
      });
    }
  };
  useEffect(() => {
    if (props.event) setRecord(props.event);
  }, [props.event]);
  return (
    <WrapperEvents>
      <SimpleForm
        {...props}
        autoSubmit={false}
        onMount={(form) => {
          setForm(form);
        }}
        onSubmit={handleOnSubmit}
        onChange={handleOnChange}
        footer={
          <div className="flex justify-center align-center section-footer">
            <Button onClick={handleOnCLick}>
              {record && record._id ? "Update Event" : "Create Event"}
            </Button>
          </div>
        }
      >
        <Input
          flex={1}
          name="company_id"
          initial={user && user.company_id}
          type="hidden"
        />
        <Input
          flex={1}
          name="user_id"
          initial={user && user._id}
          type="hidden"
        />
        <Input flex={1} name="type" initial="event" type="hidden" />
        <Input
          flex={1}
          label="Title"
          name="subject"
          placeholder="Title"
          autoComplete="off"
          initial={record && record.subject}
          validations={[{ required: true, message: "Title is Required" }]}
        />
        <DateTimeField
          className="datetimefield"
          name="end_date"
          flex={1}
          onSelect={handleOnSelect}
          placeholder="Due Date"
          label="Due Date"
          initial={record && record.end_date}
        />
        <SelectField
          placeholder="Reminder"
          name="reminder"
          label="Reminder"
          optionText="label"
          optionValue="value"
          flex={1}
          choices={data}
          // validations={[{ required: true, message: "Reminder is Required" }]}
        />
      </SimpleForm>
    </WrapperEvents>
  );
};
export default NewEvent;
