import styled from "styled-components";

export const Wrapper = styled.div``;
export const WrapperPostMultiples = styled.div`
  padding: 8px 0px;
`;
export const WrapperGallery = styled.div``;
export const WrapperDragger = styled.div`
  .ant-upload.ant-upload-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 0px;
  }
  .ant-list-item-meta-content {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & .content-upload {
    .ant-btn {
      color: var(--primary-button)!important;
      background: #fff!important;
      border-radius: 18px!important;
     :hover{
       background: var(--primary-button)!important;
       color: #fff!important;
      }
  }
`;
