import styled from "styled-components";

export const Wrapper = styled.div``;
export const WrapperForm = styled.div`
  & .container-form {
    padding: 20px 8px 4px;
  }
  & .ant-row.footer-advanced-form div:last-child {
    margin-bottom: 6px !important;
  }
  & .ant-form legend {
    margin-bottom: 8px !important;
  }
  & .footer-advanced-form {
    padding: 16px !important;
  }
  & .col-field {
    margin-bottom: 14px;
  }
  & .container-form {
    .logo-form {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .section-logo {
        width: 100px;
        height: 80px;
        img {
          margin-bottom: 0 !important;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  & .head-title {
    background: #ffff;
    border-radius: 4px !important;
  }
  & .title-form {
    h3 {
      font-size: 20px;
      padding: 16px;
      color: var(--color-text);
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 4px !important;
    }
  }
  fieldset {
    legend {
      border-bottom: 1px solid
        ${({ background }) => background || "var(--color-primary)"}!important;

      .fieldset-title {
        color: ${({ color }) => color || "var(--color-primary)"};
        font-size: 18px;
        padding: 8px 4px;
        text-transform: uppercase;
        margin-bottom: 0px !important;
      }
    }
  }
  .ant-form {
    input,
    input:hover {
      border-radius: 28px !important;
      background: none !important;
      border: 1px solid #ccc !important;
      color: #546067 !important;
    }
    input:disabled {
      cursor: default !important;
    }
    textarea,
    textarea:hover,
    textarea:focus {
      background: none !important;
      border-radius: 28px !important;
      border: 1px solid #ccc !important;
      color: #546067 !important;
    }
    textarea:disabled {
      cursor: default !important;
    }

    .ant-select-selection {
      background: none !important;
      border-radius: 28px !important;
      border: 1px solid #ccc !important;
      color: #546067 !important;
    }
  }
  & .line {
    .ant-form-item {
      input,
      input:hover {
        background: transparent !important;
        border-radius: 0px !important;
        border-bottom: 1px solid
          ${({ background }) => background || "var(--color-primary)"}!important;
      }
      input:disabled {
        cursor: default !important;
      }
      textarea,
      textarea:hover,
      textarea:focus {
        background: transparent !important;
        border-radius: 0px !important;
        border-bottom: 1px solid
          ${({ background }) => background || "var(--color-primary)"}!important;
      }
      textarea:disabled {
        cursor: default !important;
      }

      .ant-select-selection {
        background: transparent !important;
        border-radius: 0px !important;
        border-bottom: 1px solid
          ${({ background }) => background || "var(--color-primary)"}!important;
      }
    }
  }

  & .rounded {
    .ant-form-item {
      input,
      input:hover {
        border-radius: 28px !important;
        background: none !important;
        border: 1px solid #ccc !important;
        color: #546067 !important;
      }
      input:disabled {
        cursor: default !important;
      }
      textarea,
      textarea:hover,
      textarea:focus {
        background: none !important;
        border-radius: 28px !important;
        border: 1px solid #ccc !important;
        color: #546067 !important;
      }
      textarea:disabled {
        cursor: default !important;
      }

      .ant-select-selection {
        background: none !important;
        border-radius: 28px !important;
        border: 1px solid #ccc !important;
        color: #546067 !important;
      }
    }
  }
  & .square {
    .ant-form-item {
      input,
      input:hover {
        border-radius: 0px !important;
        background: #e5ecee47 !important;
        transition: all 0.25s ease !important;
        border: 1px solid #cccccc78 !important;
        color: #546067 !important;
      }
      input:disabled {
        cursor: default !important;
      }
      textarea,
      textarea:hover {
        border-radius: 0px !important;
        background: #e5ecee47 !important;
        transition: all 0.25s ease !important;
        border: 1px solid #cccccc78 !important;
        color: #546067 !important;
      }
      textarea:disabled {
        cursor: default !important;
      }

      .ant-select-selection {
        border-radius: 0px !important;
        background: #e5ecee47 !important;
        transition: all 0.25s ease !important;
        border: 1px solid #cccccc78 !important;
        color: #546067 !important;
      }
    }
  }

  & .field-item {
    min-height: 40px;
  }
  & .field-label {
    padding: 0px 4px !important;
  }
  & .field-placeholder {
    input {
      margin-left: 8px;
    }
    textarea {
      margin-left: 8px;
    }
  }
  & .ant-form {
    margin-bottom: ${({ margin_botton }) => margin_botton || 4}px!important;
    margin-left: ${({ margin_left }) => margin_left || 4}px!important;
    margin-right: ${({ margin_right }) => margin_right || 4}px!important;
    margin-top: ${({ margin_top }) => margin_top || 4}px!important;
    border-radius: ${({ border_radius }) => border_radius || 4}px!important;
    .head-title {
      border-radius: ${({ border_radius }) => border_radius || 4}px!important;
    }
    .ant-form-item-label > label {
      color: ${({ color }) => color || "var(--color-primary)"};
    }
  }
  & .simple-footer {
    .ant-btn-primary.rounded,
    .square {
      background: ${({ button_background }) =>
        button_background || "var(--color-primary)"}!important;
      color: ${({ button_color }) => button_color || "#fff"}!important;
      border: 1px !important;
    }
    .ant-btn.rounded {
      border-radius: 18px !important;
    }
    .ant-btn.flat {
      background: transparent !important;
      border: 0px !important;
      color: ${({ button_color }) => button_color || "#fff"}!important;
      box-shadow: none !important;
    }
    .ant-btn.square {
      border-radius: 0px !important;
    }
  }
`;
