import styled from "styled-components";

export const WrapperCheck = styled.div`
  label.ant-checkbox-group-item.ant-checkbox-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
  }
`;
export const ActivityContainer = styled.div``;
export const AboutWrapper = styled.div`
  & .about-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .about-title {
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      margin-bottom: 0px !important;
      font-size: 27px !important;
      font-weight: 700 !important;
    }
  }
  & .about-container {
    padding: 8px;
  }
  & .about-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px;
  }
  & .about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px;
  }
`;
export const WrapperForm = styled.div`
  & .ant-form {
    background: transparent !important;
  }
  & h3 {
    font-size: 16px !important;
    font-weight: bold;
    color: #707070;
    margin-bottom: 0px !important;
  }
`;
export const HeadContainer = styled.div`
  padding: 10px 20px;

  & h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0px;
    line-height: 24px !important;

    color: ${(props) =>
      props.background
        ? props.color || "#FFF"
        : "rgba(0, 0, 0, 0.85)"}!important;
  }
  & .ant-breadcrumb-link,
  .ant-breadcrumb-separator,
  .ant-breadcrumb a {
    color: ${(props) =>
      props.background ? props.color || "#FFF" : "var(--gray)"}!important;
  }
`;
