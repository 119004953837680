import React, { useState, useEffect, useRef } from "react";
import { HeadLine } from "./Styles";
import { Button, Icon, Input, Tooltip } from "antd";
import { GoProject } from "react-icons/go";
import { useSelector } from "react-redux";
const InputText = React.forwardRef(({ multiple = false, ...props }) => {
  if (!multiple) return <Input {...props} />;
  return <Input.TextArea {...props} />;
});
const TitleBoard = ({ name, loading, onSubmit, ...props }) => {
  const theme = useSelector(({ theme }) => theme || {});
  const [show, setShow] = useState(true);
  const [edit, setEdit] = useState(false);
  const [background, setBackground] = useState(props.background);
  const [initialized, setInitialized] = useState(false);
  const [changed, setChanged] = useState(false);
  const [value, setValue] = useState();
  const observed = useRef(null);
  const handleSubmit = (e) => {
    setEdit(false);
    if (onSubmit && value != name) setEdit(false);
    if (onSubmit) onSubmit(name, value);
    setChanged(false);
  };
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  useEffect(() => {
    if (!initialized) {
      setValue(props.initialValue);
      setInitialized(true);
    }
  }, [props.initialValue]);
  useEffect(() => {
    return () => {
      setChanged(false);
    };
  }, []);
  useEffect(() => {
    if (theme && theme.background) setBackground(theme.background);
  }, [theme]);
  return (
    <HeadLine
      className="head-title flex item-centered justify-spacebetween gap-md"
      isEdit={edit}
      color={background}
      onClick={() => {
        if (!edit && !loading) setEdit(true);
      }}
      background={background}
    >
      {!edit && (
        <Tooltip title={value || name}>
          <h3 className="board-title">
            <GoProject color={background} />{" "}
            <span className="section-name-board">{value || name}</span>
          </h3>
        </Tooltip>
      )}
      {edit && (
        <div className="input">
          {props.icon && <Icon type={props.icon} />}
          <Input
            ref={(input) => {
              if (input) input.focus();
            }}
            name={name}
            type="text"
            onBlur={() => {
              setEdit(false);
              if (changed) return handleSubmit();
              setValue(name);
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                handleSubmit();
              }
            }}
            onPressEnter={() => {
              handleSubmit();
            }}
            size="large"
            onChange={(e) => {
              setChanged(true);
              if (props.onChange) {
                props.onChange(name, e.target.value);
              }
              setValue(e.target.value);
            }}
            defaultValue={value || name}
            placeholder={props.placeholder || "Add board title"}
            multiple={props.multiple}
            {...props}
            maxLength={15}
          />
        </div>
      )}
      {(!edit || loading) && (
        <Button
          loading={loading}
          className={!loading && "btn-edit"}
          size="large"
          type={"link"}
          icon={!edit ? "edit" : "save"}
        />
      )}
    </HeadLine>
  );
};
export default TitleBoard;
