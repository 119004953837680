import React, { useEffect, useState } from "react";
import { WrapperBackground } from "./Styles";
import S3Button from "../form/S3Button";
import { IoCameraOutline } from "react-icons/io5";
const defaultAllowTypes = [".mp4", ".mov", ".avi", ".mkv", ".wmv"];

const VideoField = ({
  id,
  onChange,
  submithing,
  source = "video",
  name,
  label,
  allowTypes = defaultAllowTypes,
  ...props
}) => {
  const [record, setRecord] = useState();
  const [value, setValue] = useState();

  const handleOnChange = (value) => {
    console.log("image,::::::::::::", value);
    setValue(value);
    if (onChange) onChange(value);
  };
  useEffect(() => {
    if (record) setValue(record[name]);
  }, [record]);
  useEffect(() => {
    if (props.record) setRecord(props.record);
  }, [props.record]);
  return (
    <WrapperBackground className="video">
      <S3Button
        itemType="video"
        controls={false}
        className="s3-image"
        flex={0.8}
        source={source}
        name={name}
        label={label}
        size="large"
        allowTypes={allowTypes}
        title={
          <div className="container-title-s3">
            <div className="s3-icon">
              <IoCameraOutline className="icon" />
              <div className="title">
                <span>Add Video</span>
              </div>
            </div>
          </div>
        }
        remove
        record={record}
        value={value}
        idComponent="btn-send"
        onChange={handleOnChange}
        // onRemove={() => handleOnChange}
        path="forms"
        finalPath="forms"
        id={id}
      />
    </WrapperBackground>
  );
};
export default VideoField;
