import React from 'react';
import styled from 'styled-components';

export const HeadLine = styled.div`
  display: flex;
  justify-content: start;
  align-items: baseline;
  flex-wrap: wrap;

  width:100%!important;

  background-color:var(--gray-dark-1)!important;
  color:#fff!important;

  & .ant-checkbox-wrapper{
    margin:0px 10px;
  }
  & h2{
    color: #fff!important;
    text-transform: uppercase!important;
    padding: 4px 10px!important;
    font-size: 16px!important;
    vertical-align: middle!important;
    margin-bottom: 0px!important;
  }
`;
export const AvatarWrapper = styled.div`

    position:relative;
   
    display: flex;
    justify-content: start;
    align-items: center;

    & .member-container{
      width: 34px;
      position:relative;
    }
    & .member-container:hover .ant-btn{
      display:inline-block;
    }
    & .ant-btn,.ant-btn:hover{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
      height: 34px!important;
      display: none;
      width: 34px!important;
      background: #0010214f!important;
      color: #FFF!important;
      font-weight: bold;
      display:none;
    }
`;

export const Tools = styled.div`
    display:flex;
    justify-content: space-between;
`