import styled from "styled-components";
export const WrapperSkeletonSocial = styled.div`
  & .ant-card {
    width: 100%;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #cccccc26;
    .ant-card-body {
      padding: 44px 20px !important;
    }
  }
  & .ant-avatar {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }
  .section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }

  & .section-descriptions {
    padding: 4px;
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 14px;
  }
  & .social-text {
    height: 12px;
    width: 60px;
    margin: 4px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }
  & .section-item-key {
    height: 12px;
    width: 16px;
    margin: 0px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }
  & .name-social {
    height: 16px;
    width: 114px;
    margin: 24px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }
  & .title-description {
    height: 16px;
    width: 140px;
    margin: 11px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }
  @keyframes ant-avatar {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    40% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
`;
export const WrapperSocialWidget = styled.div`
  & .ant-card {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0.75rem 1.5rem #12263f14;
    border: 1px solid #e8e8e8;
    // height: 316px;
    .ant-card-body {
      padding: 44px 20px !important;
    }
    .section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 18px;
      .name-social {
        padding: 4px;
        h3 {
          font-size: 18px;
          text-transform: capitalize;
          margin-bottom: 6px;
          margin-top: 14px;
        }
      }
    }
    .title-description {
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
    .ant-card-meta-description {
      margin-top: 4px;
      padding: 4px;
    }
    .section-description {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 12px;
    }
  }
  & .container-social {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .social-text {
      color: var(--text-color-blue-dark);
      font-weight: 500;
      font-size: 12px;
    }
    .section-item-key {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-list {
    .ant-row {
      display: flex;
    }
    .container-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const WrapperProfile = styled.div`
  padding: 11px 0px;

  & .ant-card {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0.75rem 1.5rem #12263f14;
    border: 1px solid #e8e8e8;
    .ant-card-body {
      padding: 0px !important;
    }
    .section-title {
      border-bottom: 1px solid #ccc;
      height: 120px;
      background: #d3dbfa;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .title-start {
        padding: 18px 24px;
        h4 {
          font-size: 16px;
          margin-bottom: 0px !important;
          color: var(--primary-button);
          font-weight: 600;
        }
        .name-company {
          display: flex;
          justify-content: space-between;
          span {
            color: var(--primary-button);
            font-size: 14px;
          }
        }
        .section-img {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          img {
            width: 110px;
            height: 70px;
          }
        }
      }
    }

    .section-avatar {
      position: absolute;
      top: 74px;
      left: 20px;
      .avatar-void {
        background: #fff;
        width: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 68px;
        border: 1px solid #d3dbfa6b;
      }
      .ant-avatar {
        width: 60px !important;
        height: 60px !important;
      }
    }
    .ant-card-meta-description {
      min-height: 100px;
      margin-top: 20px;
      padding: 2px 20px 28px;
      .name-profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .section-info {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        padding: 17px 0px;
        .info {
          .role,
          .status {
            span {
              text-transform: capitalize;
            }
          }
          .role-title,
          .status-title {
            h4 {
              font-size: 14px;
            }
          }
        }
      }
      .container-user {
        .ant-btn {
          border-radius: 18px !important;
          background: #fff;
          color: var(--primary-button);
          :hover {
            background: var(--primary-button);
            color: #fff;
          }
        }
      }
      .section-name {
        h3 {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          text-transform: capitalize;
          margin-bottom: 0px;
        }
        .job-name {
          font-size: 12px !important;
        }
      }
    }
  }
`;
export const WrapperSkeletonWiget = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px;
  margin-bottom: 4px;
  & .ant-skeleton-content {
    width: 100%;
    height: 97px !important;
    // margin-top: 2px;
    border-radius: 8px;
    background: #fff;
    padding: 0px 10px;
    .ant-skeleton-title {
      margin-bottom: 0px !important;
      width: 80% !important;
    }
    .ant-skeleton-paragraph {
      margin-top: 4px !important;
      margin-bottom: 0px !important;
      li:first-child {
        width: 50px !important;
      }
      li:nth-child(2) {
        width: 70px !important;
      }
      li {
        margin: 10px 0px !important;
      }
    }
  }
`;
export const HeadLine = styled.h2`
  & span {
    margin: 0px 4px;
  }
  ${({ color, style }) => {
    return `
                display:flex;
                align-content:center;
                font-size:24px;
                color:${color || "#fff"}!important;
        `;
  }}
`;
export const WrapperCard = styled.div`
  & .ant-card {
    font-family: "NoirPro", sans-serif !important;
   box-shadow: 0 0.75rem 1.5rem #12263f14;
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    border-radius: 10px !important;
    border-color: ${({ borderColor }) => borderColor || "#FFF"};
    .description{
      white-space: nowrap;
    }
  }
  & .ant-card-body {
    padding: 15px 20px !important;
  }
  & .ant-card-meta-title {
    font-family: "NoirPro", sans-serif !important;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px !important;
    // color: ${({ color }) => color || "#FFF"};
    h2{
      margin-bottom: 4px !important;
    }
  }
  & .ant-card-meta-description h2 {
    font-family: "NoirPro", sans-serif !important;
    font-weight: 500 !important;
    font-size: 28px !important;
    margin-bottom: 0px !important;
    line-height: 1 !important;
    // color: ${({ color }) => color || "#FFF"};
  }
  & .ant-card-meta-description span.description {
    // color: #fff !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
  }
  & .ant-spin {
    color: ${({ color }) => color || "#FFF"}!important;
  }
`;
export const Wrapper = styled.div`
  & .item-list {
    height: 200px;
    overflow-y: auto;
  }
  & .item-list.item-100 {
    height: 120px;
  }
  & .item-list.item-250 {
    height: 250px;
  }
  & .item-list.item-200 {
    height: 200px;
  }
  & .item-list.item-100 {
    margin-bottom: 0px !important;
  }
  & .item-list .item-container:first-child {
    box-shadow: 0px 2px 0px #00000045;
    background: rgb(255 255 255 / 21%);
  }
  & .ant-card-body {
    padding: 24px 4px !important;
  }

  ${({ showAll }) => {
    return `
                & .item-list{
                    margin-bottom: ${showAll ? "30px" : "0px"};
                }
            `;
  }}
  & .item-container {
    margin: 4px 10px;
    background: rgb(255 255 255 / 21%);
    border-radius: 4px;
    padding: 4px;
  }
  & .item .item-icon {
  }
  /* & .item .item-text{
        margin:0px 4px;
    } */
  & .item {
    display: flex;
    align-items: center;
    line-height: 0.5;
    justify-content: space-between;
  }
  & .item:first-child {
    justify-content: start;
    line-height: 1;
  }
  & .item-tools i {
    margin: 0px 8px;
  }
  & .item-tools a {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  & .list-widget .item-tools,
  .footer-items {
    display: flex;
    width: 100% !important;
  }
  & .list-widget span.item-text {
    display: flex;
    align-items: center;
  }
  & .footer-items {
    justify-content: flex-end;
    align-items: center;
    padding: 0px 14px;

    /* position: absolute;
        bottom: 10px; */
    width: 100%;
    right: 10px;

    width: 100% !important;
  }
  & .item-date {
    font-size: 12px;
    padding: 4px 0px;
    vertical-align: middle;
    padding: 8px 0px 8px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .item-tools a {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }
  & .item-date i,
  .item-list .anticon {
    margin: 0px 4px !important;
  }
  & .item-tools .ant-btn-link,
  & .footer-items .ant-btn-link,
  & .item-tools a,
  & .footer-items a,
  & .footer-items .ant-btn-link {
    color: #fff !important;
  }

  & .rbc-btn-group {
    display: none !important;
  }
  & .item-footer a,
  & .item-footer button {
    font-size: 16px !important;
    padding: 0 10px !important;
  }
`;
export const EventWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  & .anticon {
    margin: 0px 4px;
  }
`;
export const CalendarWrapper = styled.div`
  background: #fff !important;
  padding: 10px 20px;
`;
export const TaskWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  & .anticon {
    margin: 0px 4px;
  }
`;
export const WrapperLogItem = styled.div`
  background: #0000005e;
  margin: 4px 0px;
  padding: 4px;
  border-radius: 4px;
  color: #fff;

  & .item-type {
    margin: 0px 4px;
    padding: 2px 4px;
    background: #32485c;
    border-radius: 30px;
    color: rgba(255, 255, 255, 0.85);
  }
`;
export const CountWrapper = styled.div`
  display: flex;
  justify-content: start;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;

  & .count-circle {
    margin: 0px 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;

    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const WrapperSkeleton = styled.div`
  & .content {
    width: 100%;
    // height: 28px;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      background-size: 400% 100%;
      margin: 4px 2px 20px;
      border-radius: 4px;
      animation: tag 1s infinite ease-in-out;
    }

    .name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      height: 24px;
      background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      background-size: 400% 100%;
      margin: 4px;
      border-radius: 2px;
      animation: tag 1s infinite ease-in-out;
    }
    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 24px;
      background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      background-size: 400% 100%;
      margin: 4px;
      border-radius: 2px;
      animation: tag 1s infinite ease-in-out;
    }
  }
  @keyframes tag {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
`;
export const WrapperFunnel = styled.div`
  & .section-funnel {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1366px) {
  }
  @media (min-width: 992px) and (max-width: 1365px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 512px) and (max-width: 767px) {
  }
  @media (min-width: 412px) and (max-width: 511px) {
  }
`;
export const WrapperStatisctics = styled.div`
  background: #fff;
  min-height: 464px;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 0.75rem 1.5rem #12263f14;
  border: 1px solid #e8e8e8;
  margin: 2px 0px;
  & .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 8px 4px;
    h3 {
      margin-bottom: 0px !important;
      font-size: 12px;
      color: #595959;
    }
  }
  & .funnel {
    padding: 18px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      min-width: 110px;
      max-width: 530px;
    }
  }
`;
export const WrapperActivities = styled.div`
  & .ant-list-split .ant-list-header {
    border-bottom: 0px !important;
    margin-bottom: 15px;
    font-size: 12px;
    color: #595959;
  }
  & .activity-past {
    margin-top: 10px;
  }
`;
export const WrapperTaskList = styled.div`
  &.task-list {
    max-height: 100% !important;
  }
  &.task-list-default {
    max-height: 466px !important;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0.75rem 1.5rem #12263f14;
    border: 1px solid #e8e8e8;
  }
  &.task-list-default .ant-list-items {
    margin-bottom: 20px;
    max-height: 300px !important;
  }
  &.task-list .ant-list-items {
    margin-bottom: 20px;
    max-height: 100% !important;
  }
  & .ant-table-tbody > tr > td {
    padding: 8px 16px !important;
    overflow-wrap: break-word !important;
    border-bottom: 0px solid !important;
  }

  & .title-task {
    h2 {
      font-size: 12px !important;
      color: #545454 !important;
      margin-bottom: 0px !important;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .contact-name {
      :hover {
        color: var(--primary-button) !important;
      }
    }
  }
  & .title-task.completed h2 {
    text-decoration: line-through !important;
    color: #888888 !important;
  }
  & .group-container {
    margin-top: 0px !important;
  }
  & .ant-tag {
    height: 21px;
    font-size: 12px !important;
    font-weight: 500;
    .ant-tag-green {
      color: #52c41a;
      background: #f6ffed;
      border-color: #b7eb8f;
    }
  }
  .ant-table-body {
    max-height: 319px !important;
  }
`;
export const WrapperItem = styled.div``;
