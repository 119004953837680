import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Input,
  List,
  Row,
  Skeleton,
  Modal,
  message,
} from "antd";
import { WrapperTagList, WrapperContainerTag } from "./Styles";
import { getService } from "../../services";
import { Save } from "@material-ui/icons";
import { SearchField, SimpleForm } from "../com/form/";
import { ColorPicker } from "../com";
import { TiArrowBack } from "react-icons/ti";
const Item = List;
const { confirm } = Modal;
const data = [
  {
    id: 1,
    name: "New",
    color: "#7EE2B8",
  },
  {
    id: 2,
    name: "close",
    color: "#F5CD47",
  },
  {
    id: 3,
    name: "asigned",
    color: "#F87168",
  },
  {
    id: 4,
    name: "pending",
    color: "#C9372C",
  },
  {
    id: 5,
    name: "Good",
    color: "#9F8FEF",
  },
];
const TagTasks = ({
  title,
  searchVisible = true,
  button_new_tag = true,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("default");
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const [dataSource, setDataSource] = useState();
  const [payloads, setPayloads] = useState();
  const [record, setRecord] = useState({});
  const [user, setUser] = useState({});
  const [task, setTask] = useState({});
  const [search_value, setSearchValue] = useState();
  const [selected_items, setSelectedItems] = useState([]);
  // const [background, setBackground] = useState();

  const onChange = (item) => {
    let tags_ids = selected_items;
    if (!tags_ids.includes(item._id)) {
      tags_ids = [...tags_ids, item._id];
    } else {
      tags_ids = tags_ids.filter((_id) => _id !== item._id);
    }
    setSelectedItems(tags_ids);
    if (task && task._id) {
      const service = getService("tasks");
      service
        .patch(task._id, {
          tags_ids,
        })
        .then((resp) => {
          message.success("Update Task ");
          if (props.autoSubmitTask) props.autoSubmitTask();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  const handleSearch = (search) => {
    setSearchValue(search);
    let params = {};
    if (search && search !== null) params["search"] = search;
    if (user.company_id) params["company_id"] = user.company_id;
    setFilterDefaultValues({
      ...params,
    });
  };
  const handleBack = () => {
    setView("default");
    // getData();
  };
  const handleOnRemove = (id) => {
    const service = getService("task-tags");
    service
      .remove(id)
      .then((result) => {
        message.success("Record remove");
        getData();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const handleRemoved = (id) => {
    confirm({
      content: (
        <div>
          This file will be permanently removed if you delete it. Please confirm
          if you wish to proceed.
        </div>
      ),
      onOk() {
        handleOnRemove(id);
      },
      onCancel() {},
    });
  };
  const handleColor = (field, background) => {
    setPayloads({
      ...payloads,
      background,
    });
    setRecord({
      ...record,
      background,
    });
  };
  const handleOnSelected = (item) => {
    setView("new");
    setRecord(item);
  };
  const handleOnTaskTag = () => {
    const service = getService("task-tags");
    if (record._id) {
      setLoading(true);
      service
        .patch(record._id, payloads)
        .then((res) => {
          message.success("updated tag");
          setView("default");
          setLoading(false);
          getData();
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    } else if (!record._id) {
      setLoading(true);
      service
        .create(payloads)
        .then((resp) => {
          message.success("Tag created");
          setView("default");
          setLoading(false);
          setFilterDefaultValues({
            ...filterDefaultValues,
            search: undefined,
          });
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    }
  };
  const handleOnChange = (field, value, form) => {
    // setChanged(true);
    if (form) {
      form.validateFields((errors, values) => {
        if (!errors) {
          setPayloads(values);
        }
      });
    }
  };
  const handleOnChangeTag = () => {
    setRecord({});
    setPayloads({});
    setView("new");
  };
  const getData = () => {
    const service = getService("task-tags");
    setLoading(true);
    service
      .find({
        query: {
          ...filterDefaultValues,
        },
      })
      .then(({ data }) => {
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };
  const renderItem = (item) => {
    let { background, color, user_id, company_id } = item;
    let isChecked = selected_items.includes(item._id);
    return (
      <WrapperTagList background={background} color={color}>
        <List.Item
          actions={[
            user.company_id === company_id ? (
              <div>
                <Button
                  className="btn-edit-item"
                  onClick={() => handleOnSelected(item)}
                  type="link"
                  icon="edit"
                />

                <Button
                  className="btn-delete-item btn-removed-red"
                  onClick={() => handleRemoved(item._id)}
                  type="link"
                  icon="delete"
                />
              </div>
            ) : (
              <div className="btn-void-task"></div>
            ),
          ]}
        >
          <List.Item.Meta
            title={
              <div>
                <Checkbox checked={isChecked} onChange={() => onChange(item)} />
              </div>
            }
            description={
              <div className="section-name" onClick={() => onChange(item)}>
                <span>{item.title}</span>
              </div>
            }
          />
        </List.Item>
      </WrapperTagList>
    );
  };
  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  useEffect(() => {
    if (props.user && typeof props.user.company_id)
      setFilterDefaultValues({
        company_id: props.user.company_id,
      });
  }, [props.user]);
  useEffect(() => {
    if (props.user) setUser(props.user);
  }, [props.user]);
  useEffect(() => {
    if (task && task["tags_ids"]) {
      setSelectedItems(task["tags_ids"]);
    }
  }, [task]);
  useEffect(() => {
    if (props.task) setTask(props.task);
  }, [props.task]);
  useEffect(() => {
    if (props.filterDefaultValues)
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  return (
    <WrapperContainerTag background={record && record.background}>
      <Row type="flex" justify="center" align="center" gutter={8}>
        <Col span={22}>
          {view === "default" ? (
            <div>
              {searchVisible && (
                <div className="container-autocomplete">
                  <SearchField
                    allowClear
                    placeholder="Search Tags"
                    onSearch={handleSearch}
                    source="task-tags"
                  />
                </div>
              )}
              <div>
                {title && (
                  <div className="title-list">
                    <span>{title}</span>
                  </div>
                )}
                <List
                  className="container-list"
                  grid={{ gutter: 4, xs: 1, md: 1, xl: 1, lg: 1, xxl: 1 }}
                  loading={loading}
                  itemLayout="horizontal"
                  renderItem={renderItem}
                  dataSource={dataSource}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="btn-go-back">
                <Button type="link" onClick={handleBack}>
                  <TiArrowBack />
                  Return
                </Button>
              </div>
              <div className="flex justify-center">
                {record && record.background ? (
                  <div className="color-background">
                    <Button
                      className="btn-backgroun-record"
                      size="large"
                      style={{ background: record.background, width: "100%" }}
                    />
                  </div>
                ) : (
                  payloads &&
                  payloads.background && (
                    <div className="color-background">
                      <Button
                        className="btn-backgroun-payloads"
                        size="large"
                        style={{
                          background: payloads && payloads.background,
                          width: "100%",
                        }}
                      />
                    </div>
                  )
                )}
                <div className="form-new-task">
                  <SimpleForm
                    source="task-tags"
                    id={record._id}
                    autoSubmit={false}
                    onChange={handleOnChange}
                    onLoad={(record) => setRecord(record)}
                    footer={
                      <div className="container-footer">
                        <Button
                          loading={loading}
                          type="primary"
                          className="btn-add-footer"
                          onClick={handleOnTaskTag}
                          size="large"
                        >
                          {record._id ? "Edit" : "Save"}
                        </Button>
                      </div>
                    }
                  >
                    <Input
                      flex={1}
                      // label="Title"
                      placeholder="Title"
                      initial={search_value}
                      name="title"
                      validations={[
                        { required: true, message: "Title is required" },
                      ]}
                    />
                    <ColorPicker
                      flex={1}
                      validations={[
                        { required: true, message: "Color is required" },
                      ]}
                      // label="Color"
                      name="background"
                      onChange={handleColor}
                    />
                  </SimpleForm>
                </div>
              </div>
            </div>
          )}
          {view === "default" && button_new_tag && (
            <div className="container-footer-new">
              <Button
                className="btn-new-tags"
                type="primary"
                onClick={handleOnChangeTag}
              >
                Create
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </WrapperContainerTag>
  );
};
export default TagTasks;
