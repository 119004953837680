import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { Button, Icon, message } from "antd";
import { useSelector } from "react-redux";
import { Logout } from "../services";

const Image = styled.div`
  background-image: url("/images/404_Page.png");
  max-width: 750px;
  width: 100%;
  height: 394px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Wrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  // background: #fff;

  & .section-footer {
    width: 100%;
    padding: 14px;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      border-radius: 18px;
      // width: 136px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent !important;
      color: #1d37a2 !important;
      :hover {
        background: #1d37a2 !important;
        color: #fff !important;
      }
    }
  }
`;

const NotFound = ({ ...props }) => {
  const user = useSelector(({ user }) => user);
  const handleOnNavigate = () => {
    if (user) {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    if (!user) {
      Logout()
        .then((response) => {
          localStorage.clear();
          navigate("/");
          message.info("Your session expired");
        })
        .catch((err) => message.error(err.message));
    }
  }, [!user]);
  return (
    <Wrapper className="d-flex flex-column justify-content-center align-items-center">
      <Image />
      <div className="section-footer">
        <Button
          className="btn"
          type="primary"
          size="large"
          onClick={handleOnNavigate}
          // onClick={() => navigate(-1)}
        >
          <Icon type="arrow-left" /> Go Back
        </Button>
      </div>
    </Wrapper>
  );
};

export default NotFound;
