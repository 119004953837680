import styled from "styled-components";

export const WraperDate = styled.div``;
export const WrapperCalendar = styled.div``;
export const Tools = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin: 8px 0px !important;
`;
export const TaskCRUDWrapper = styled.div`
  & .grid-tools-container {
    margin: 0px !important;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 4px;
    background: #d5d4d71f;
    /* box-shadow: 0px 3px 2px #cccccc85;
    */
  }
  & .wrapper-task {
    background: #fff !important;
  }
  & .ant-table-row-cell-break-word {
    p {
      margin-bottom: 0px !important;
      padding: 2px !important;
    }
  }
`;
export const Wrapper = styled.div`
  background: #fff !important;
  box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 5%);
  border-radius: 8px !important;
  padding: 8px;
  min-height: 100vh !important;
`;
export const WrapperForm = styled.div`
  height: 528px;
  overflow-y: scroll;
  form {
    max-width: 300px !important;
  }
  & .datetimefield {
    color: #1d37a2 !important;
    font-weight: 600 !important;
  }
  & .item-form {
    padding: 0px 4px !important;
  }
  & .ant-form label {
    font-size: 14px;
    color: blue !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
  }
  & .ant-col.ant-form-item-label {
    padding: 6px 0px !important;
  }
  & .ant-form-item input,
  .ant-select-selection,
  textarea.ant-input {
    .ant-btn-default,
    .ant-btn-default:hover {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & .ant-form-item-children {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-fullcalendar {
    border-top: 0px solid #d9d9d9 !important;
  }
  & .ant-fullcalendar table {
    height: 194px !important;
  }
  & .footer-advanced-form .ant-btn {
    border-radius: 18px !important;
    background: #fff !important;
    color: var(--primary-button) !important;
    min-width: 104px;
    :hover {
      background: var(--primary-button) !important;
      color: #fff !important;
    }
  }
`;
