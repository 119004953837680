import BoardForm from "./BoardForm";
import Board from "./Board";
import BoardList from "./BoardList";
import SideMenu from "./SideMenu";
import TitleBoard from "./TitleBoard";
import BoardMembers from "./BoardMembers";

export const types = [
    {
        value: "task",
        text: "Task"
    },
    {
        value: "opportunity",
        text: "Opportunity"
    },
    {
        value: "lead",
        text: "Lead"
    },
    {
        value: "project",
        text: "Project"
    },
    {
        value: "target",
        text: "Target"
    },
    {
        value: "contact",
        text: "Contact"
    },
    {
        value: "quote",
        text: "Quote"
    },
    {
        value: "property",
        text: "Property"
    },

    {
        value: "email",
        text: "Email",
    },
    {
        value: "bug",
        text: "Bug",
    },
    {
        value: "project_task",
        text: "Project Task",
    },
    {
        value: "target",
        text: "Target",
    },
    {
        value: "case",
        text: "Case",
    },
    {
        value: "meeting",
        text: "Meeting",
    },
    {
        value: "online_meeting",
        text: "Online Meeting",
    },
    {
        value: "call",
        text: "Make Call",
    },

    {
        value: "contract",
        text: "Contract",
    },
    {
        value: "invoice",
        text: "Invoice",
    },
    {
        value: "quote",
        text: "Quote",
    },
    {
        value: "product",
        text: "Product",
    },
    {
        value: "property",
        text: "Property",
    },
];
export {
    BoardForm,
    Board,
    BoardList,
    SideMenu,
    TitleBoard,
    BoardMembers
}


