import styled from "styled-components";

export const WrapperSkeleton = styled.div`
  min-height: 360px;
  background: #d7d0d026;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 6px;
  padding: 18px;
  margin-bottom: 6px;
  & .container-avatar {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  & .ant-avatar {
    width: 42px !important;
    height: 34px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-avatar 1s infinite ease-in-out;
  }

  & .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 20px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    margin: 4px 2px 20px;
    border-radius: 2px;
    animation: title 1s infinite ease-in-out; /* Animación */
  }
  & .description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    margin: 0px;
    border-radius: 2px;
    margin: 8px;
    animation: description 1s infinite ease-in-out; /* Animación */
  }

  @keyframes ant-avatar {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    40% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes title {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes description {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
  @keyframes number {
    0% {
      opacity: 0.5; /* Transparencia inicial */
    }
    70% {
      opacity: 1; /* Transparencia media */
    }
    100% {
      opacity: 0.5; /* Transparencia final */
    }
  }
`;
export const Wrapper = styled.div`
    background: #fff;
    padding: 2px 18px;
    border-radius: 6px;
    min-height: 462px
    padding-bottom: 22px;
    box-shadow: 0 0.75rem 1.5rem #12263f14;
    border: 1px solid #e8e8e8;
  & .ant-list-items {
    max-height: 382px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  & .ant-card-body{
    padding:24px 4px !important;
  }
  & .ant-list-item {
    padding: 4px 0 !important;
    cursor: pointer!important;
  }
  & ::-webkit-scrollbar {
    width: 6px !important;
    background: transparent !important;
  }
  & ::-webkit-scrollbar-thumb,
  .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: lightgray !important;
  }
  & .item{
    border-left:4px solid transparent;
    padding-left: 4px!important;
  }
  & .item-selected{
    border-left:4px solid var(--primary);
  }
  & li.ant-list-item.item.item-not-viewed {
    background: #038fde14;
  }
  & li.ant-list-item.item.item-viewed {
    background: #fff;
  }
  & .ant-list-header h3{
    font-size: 14px;
    padding: 4px 8px;
    margin-bottom:0px!important;
  }
}
`;
export const WrapperItem = styled.div`
  & .ant-card-body {
    padding: 24px 4px !important;
  }
  & .ant-list-item-meta-description p a {
    color: var(--color-primary) !important;
    text-decoration: underline !important;
  }
  & .ant-list-item-meta-description p {
    margin-bottom: 0px !important;
    font-size: 12px !important;
  }
  & .ant-list-item-meta-title {
    span {
      strong {
        font-weight: 500 !important;
      }
    }
    .moment-date {
      font-size: 10px !important;
      color: #ccc;
      font-weight: 200 !important;
    }
  }
  & .ant-list-item-meta-description {
    /* word-break: break-all !important;
    white-space: initial !important; */
    margin-right: 8px !important;
    span {
      strong {
        font-weight: 500 !important;
      }
    }
    p {
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
    }
  }
  & .ant-list-item-meta-avatar {
    margin-right: 8px !important;
  }
`;
