import React from "react";
import * as plugins from "./index";

const Plugin = ({
  record,
  xtype,
  name,
  source,
  label,
  type,
  defaultValue,
  ...props
}) => {
  return (
    <>
      {React.createElement(plugins[xtype || "notfound"], {
        name: name || source,
        label,
        type,
        defaultValue,
        record,
        className: "plugin-form",
        ...props,
        onChange: (value) => {
          if (props.onChange) props.onChange(name || source, value);
        },
      })}
    </>
  );
};
export default Plugin;
