import React, { useState, useEffect } from "react";
import { DinamicForm } from "../com/form/";
import { Link, navigate } from "@reach/router";
import { useSelector } from "react-redux";
import { Col, Row, Button, Icon, Input, Radio, message } from "antd";
import styled from "styled-components";
import S3Button from "../com/form/S3Button";
import { getService } from "../../services";
const Form = styled(DinamicForm)`
  margin: 20px 10px !important;
  padding: 10px !important;
  border-radius: 10px;
  & .ant-btn {
    width: 50% !important;
  }
  & .ant-form-item-children h3,
  .ant-form-item-children span {
    font-size: 20px !important;
    margin-bottom: 0px !important;
  }
  & .footer-advanced-form {
    padding: 10px !important;
  }
  & h3 {
    font-size: 20px !important;
    font-style: normal !important;
  }
`;

export const ProfileForm = ({ id, ...props }) => {
  const [record, setRecord] = useState({});
  const [image, setImage] = useState();
  const handleOnChange = (url) => {
    const service = getService("companies");
    setImage(url);
    service
      .patch(record.company_id, {
        logo: url,
      })
      .then((response) => {
        message.info("Logo updted!");
      })
      .catch((err) => message.error(err.message));
  };

  const fields = [
    {
      xtype: "hidden",
      name: "hostApp",
      initial: "GOW",
    },
    {
      xtype: "headline",
      text: "Personal information",
    },
    {
      name: "first_name",
      label: "First Name",
      placeholder: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
      placeholder: "Last Name",
    },
    {
      name: "email",
      flex: 1,
      label: "Email",
      placeholder: "Email",
    },
    {
      name: "secundary_email",
      flex: 1,
      label: "Secundary Email",
      placeholder: "Secundary Email",
    },
    {
      name: "phone",
      label: "Phone",
      placeholder: "Phone",
    },
    {
      xtype: "divider",
    },
    {
      xtype: "divider",
      text: (
        <Row type="flex" justify="start" align="top">
          <Col span={12}>
            <h3>Company Informaction</h3>
            {/* <span>Update your company data</span> */}
          </Col>
          <Col className="company-profile" span={12}>
            <S3Button
              size="large"
              record={props}
              value={image}
              idComponent="btn-send"
              onChange={handleOnChange}
              path="users"
              finalPath="picture"
              source="picture"
              id={id}
            />
          </Col>
        </Row>
      ),
    },
    {
      xtype: "hidden",
      name: "company_id",
    },
    /* {
      xtype:"referenceinput",
      name: "company_id",
      source: "company.companies",
      references: "companies",
      placeholder: "Company Name"
    }, */
    {
      name: "company_name",
      label: "Company Name",
      placeholder: "Company Name",
    },
    {
      xtype: "textarea",
      name: "company_description",
      label: "About Company",
      placeholder: "Enter sort description",
    },
    {
      xtype: "divider",
    },
    {
      xtype: "divider",
      text: (
        <div>
          <h3>Change Password</h3>
          {/* <span>You can change your password whenever you want!</span> */}
        </div>
      ),
    },

    {
      name: "current_password",
      label: "Enter Current Password",
      placeholder: "●●●●●●●●",
      type: "password",
    },
    {
      name: "new_password",
      label: "Enter New Password",
      placeholder: "●●●●●●●●",
      type: "password",
    },
  ];
  return (
    <Form
      {...props}
      source="users"
      id={id}
      fields={fields}
      onLoad={setRecord}
    />
  );
};
