import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { URL_S3 } from "../constants";
import moment from "moment";
moment.locale("es");

const MAX_HEIGHT = 600;
const SuperTitle = styled.h1`
  font-size: 65px;
  font-weight: bold;
  color: var(--color-gray);
  line-height: 55px;
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 20px !important;
`;
const Author = styled.h3`
  font-size: var(--font-size-small);
  color: var(--color-secundary);
`;
const Content = styled.div`
  display: block;
  height: ${MAX_HEIGHT}px;

  ::before {
    content: " ";
    position: absolute;


    width: 100%;
    height: 100%;

    background-size: 100% 100%;
    max-height: 250px;
    background-repeat: no-repeat !important;
    left: -80px !important;
    bottom: 0px !important;
    max-width: 500px;
    min-width: 200px;
    min-height: 150px;
  }
`;
const Image = styled.div`
  background-repeat: no-repeat !important;
  height: ${MAX_HEIGHT}px;
  width: 100%;
  background-size: cover !important;
  background-position: center !important;
`;
const Cover = props => {
  let { cover_picture, title, createdAt, author = {} } = props;
  return (
    <Row
      type="flex"
      justify="space-between"
      align="top"
      style={{
        position: "relative"
      }}
    >
      <Col className="cover-blog-title" xs={24} md={24} lg={8} xl={8}>
        <Row type="flex" justify="space-around" align="top">
          <Col>
            <Content className="cover-blog-content">
              <div
                style={{
                  padding: "150px 0px"
                }}
              >
                <SuperTitle>{title}</SuperTitle>
                <Author>
                  Por: {author ? `${author.first_name} ${author.last_name}` : "Julio Flores"}
                  {moment(createdAt).format("LLLL")}
                </Author>
              </div>
            </Content>
          </Col>
        </Row>
      </Col>
      <Col className="cover-blog" xs={24} md={24} lg={16} xl={16}>
        <Image
          style={{
            background: `url(${URL_S3}${cover_picture})`
          }}
        />
      </Col>
    </Row>
  );
};
export default Cover;
