import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { WrapperColumnBackground, WrapperColumnLayout } from "./Styles";
import { URL_S3 } from "../../constants";
const ColumnBackground = ({
  background,
  className,
  color,
  image,
  ...props
}) => {
  return (
    <WrapperColumnBackground
      className={className}
      background={background}
      image={image}
      color={color}
    >
      <div className="column-background">
        {image && (
          <div
            style={{
              background: `url(${URL_S3}/${image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100vh",
              width: "100%",
            }}
          />
        )}
      </div>
    </WrapperColumnBackground>
  );
};
const ColumnLayout = ({
  layout,
  color,
  background,
  image,
  logo,
  children,
  ...props
}) => {
  const [options, setOptions] = useState({});
  const [form_size, setFormSize] = useState(8);
  useEffect(() => {
    if (props.options) setOptions(props.options);
  }, [props.options]);
  useEffect(() => {
    if (options) {
      let { form_size } = options;
      setFormSize(form_size);
    }
  }, [options]);
  switch (layout) {
    case "centerlayout":
      return (
        <WrapperColumnLayout
          color={color}
          background={background}
          image={image}
          className={layout}
        >
          <Row
            type="flex"
            justify="center"
            align="middle"
            gutter={8}
            className="column-container"
          >
            <Col xl={form_size} className="col-content">
              {React.cloneElement(children, {
                color,
                background,
                image,
                logo,
              })}
            </Col>
          </Row>
        </WrapperColumnLayout>
      );
      break;

    default:
      return (
        <WrapperColumnLayout
          className={layout}
          color={color}
          background={background}
          image={image}
        >
          <Row type="flex" justify="center" align="top" gutter={12}>
            <Col
              xl={layout === "leftlayout" ? form_size : 24 - form_size}
              className={layout}
            >
              {layout === "leftlayout" &&
                React.cloneElement(children, {
                  color,
                  background,
                  image,
                  logo,
                })}
              {layout !== "leftlayout" && (
                <ColumnBackground
                  className="column-background"
                  color={color}
                  background={background}
                  image={image}
                />
              )}
            </Col>
            <Col
              xl={layout === "leftlayout" ? 24 - form_size : form_size}
              className={layout}
            >
              {layout !== "leftlayout" &&
                React.cloneElement(children, {
                  color,
                  background,
                  image,
                  logo,
                })}
              {layout === "leftlayout" && (
                <ColumnBackground
                  className="column-background"
                  color={color}
                  background={background}
                  image={image}
                />
              )}
            </Col>
          </Row>
        </WrapperColumnLayout>
      );
      break;
  }
};
export default ColumnLayout;
